
const titleFormExposicaoDescargasEletricas="EXPOSIÇÃO A DESCARGAS ELÉTRICAS, IMPACTOS MECÂNICOS E FLUIDOS PRESSURIZADOS NO CORPO DO MERGULHADOR (SUBMERSOS/EMERSOS)"
const titleHazardExposicaoDescargasEletricas="Produtos Tóxicos";
const SecondIdExposicaoDescargasEletricas="ExposicaoDescargasEletricas";
const listAMCOExposicaoDescargasEletricas=['AM-1 - Variação brusca de swell/correnteza provocando encontro do mergulhador contra obstáculos','CO-1 - Lesão com potencial de invalidez e fatalidade'];
const limitationForSubmitExposicaoDescargasEletricas={
  startDate:new Date(2023,5,20),
  stopDate:new Date(2023,6,3)
}
let TitlesAndQuestionsExposicaoDescargasEletricas = {
    'AM-1 - Variação brusca de swell/correnteza provocando encontro do mergulhador contra obstáculos': {
      '1-1 a 1': {
        Question:
          'Os supervisores de mergulho são treinados e têm competência para planejar operações de mergulho com emissão de APR com a inclusão das condições meteoceanográficas?',
        Answer: 'white',
      },
      '1-1 b 2': {
        Question:
          'A APR contempla as condições meteoceanográficas da localização e dia da realização do mergulho?',
        Answer: 'white',
      },
      '1-1 c 3': {
        Question:
          ' É considerado no planejamento da atividade de mergulho os dados meteoceanográficos como fator importante na execução das tarefas?',
        Answer: 'white',
      },
      '1-1 d 4': {
        Question:
          'É citado em DDS as condições meteoceanográficas previstas por boletim para cada dia efetivo de trabalho incluindo a variação brusca de swell/correnteza?',
        Answer: 'white',
      },
      '1-2 a 5': {
        Question:
          'É prática usual na frente/embarcação de mergulho a consulta diária dos boletins de medição metoceanográficos?',
        Answer: 'white',
      },
      '1-2 b 6': {
        Question:
          'O supervisor de mergulho e o comandante da embarcação avaliam as condições meteoceanográficas diariamente antes de liberar ou não as operações de mergulho?',
        Answer: 'white',
      },
      '1-2 c 7': {
        Question:
          'São definidos e respeitados limites operacionais em função das condições meteocenográficas e das características do local de trabalho (profundidade, tipo de plataforma, área de sombra ou de vórtex, etc)?',
        Answer: 'white',
      },
      '1-2 d 8': {
        Question: 'No caso de a força da correnteza indicar ultrapassar 1,5 nós, é lançado o correntômetro antes de realizar o mergulho?',
        Answer: 'white',
      },
      '1-2 e 9': {
        Question:
          'No caso de variação de maré na baixa-mar são evitados os mergulhos próximo a ZVM?',
        Answer: 'white',
      },
      '1-3 a 10': {
        Question:
          'Em situações de difícil deslocamento do mergulhador na água, é instalado cabo guia entre a embarcação e o local de trabalho a fim de dar mais estabilidade ao mergulhador durante a tarefa?',
        Answer: 'white',
      },
      '1-3 b 11': {
        Question:
          'Os cabos guias utilizados são inspecionados visualmente periodicamente para verificar a sua integridade?',
        Answer: 'white',
      },
      '1-3 c 12': {
        Question:
          'Os fornecedores de cabos guias para acesso dos mergulhadores estão contemplados no processo de fornecedores críticos?',
        Answer: 'white',
      },
      '1-3 d 13': {
        Question:
          'Caso os cabos guias não sejam considerados equipamentos críticos existe alguma orientação em relação ao seu processo de aquisição?',
        Answer: 'white',
      },
      '1-3 e 14': {
        Question:
          'É previsto no procedimento e no cheklist pré-mergulho que a equipe de mergulho só deve utilizar o cabo guia em boas condições de uso?',
        Answer: 'white',
      },
      '1-4 a 15': {
        Question:
          'Os mergulhadores são treinados e capacitados para a utilização do cinto de lastro de maneira a garantir uma flutuabilidade neutra durante o mergulho?',
        Answer: 'white',
      },
      '1-4 b 16': {
        Question:
          'No DDS pré-operações de mergulho é salientada a importância de se garantir a flutuabilidade neutra para o bom andamento das operações de mergulho?',
        Answer: 'white',
      },
      '1-4 c 17': {
        Question:
          'Os novos mergulhadores são treinados e acompanhados durante as operações que necessitem de ajustes da flutuabilidade na execução da operação?',
        Answer: 'white',
      },
      '1-5 a 18': {
        Question:
          'Os supervisores de mergulho são orientados para manter comunicação constante com os mergulhadores durante as operações de mergulho?',
        Answer: 'white',
      },

      '1-5 b 19': {
        Question:
          'Os equipamentos que fazem parte do sistema de comunicação e monitoramento do mergulhador são testados antes e durante as operações de mergulho periodicamente? ',
        Answer: 'white',
      },
      '1-5 c 20': {
        Question:
          'Os equipamentos que compõem o sistema de comunicação e monitoramento do mergulhador estão inseridos no plano de manutenção dos equipamentos de mergulho?',
        Answer: 'white',
      },
      '1-5 d 21': {
        Question:
          'Durante as operações de mergulho raso o supervisor de mergulho monitora o mergulhador no local de trabalho através do sistema de comunicação com a ajuda do bellman?',
        Answer: 'white',
      },
      '1-6 a 22': {
        Question:
          'São realizados simulados com ênfase no procedimento de resposta a emergências subaquáticas, no controle emocional dos mergulhadores e supervisores envolvidos nas operações de mergulho?',
        Answer: 'white',
      },
      '1-6 b 23': {
        Question:
          'Os supervisores e mergulhadores são capacitados e avaliados periodicamente em treinamentos para lidar com intercorrências durante as operações de mergulho através de simulados e demais exercícios práticos prevenindo que se desesperem caso ocorra uma variação brusca de swell/correnteza? ',
        Answer: 'white',
      },
      '1-6 c 24': {
        Question:
          'Nos simulados de mergulho, os supervisores são orientados a focar na calma e no controle emocional dos mergulhadores para evitar que os mesmos entrem em desespero caso ocorra uma variação brusca de swell/correnteza?',
        Answer: 'white',
      },
      '1-6 d 25': {
        Question:
          'De forma geral, os supervisores têm o conhecimento do perfil dos membros de suas respectivas equipes, para em caso de uma intercorrência saber transmitir as orientações necessárias e manter a estabilidade emocional do mergulhador para que o mesmo não entre em desespero e acabe se chocando contra obstáculos? ',
        Answer: 'white',
      },

    },
    'AM-2 - Movimentação/ Manuseio inadequado ou falha de ferramentas e acessórios de trabalho': {
      '2-1 a 26': {
        Question:
          'O planejamento das operações de mergulho é orientado pelo MD, PO, IT, PT e CLM para as tarejas a serem executadas?',
        Answer: 'white',
      },
      '2-1 b 27': {
        Question:
          'É procedimentado em função das tarefas que são recebidas do cliente as etapas de orientar previamente os envolvidos nos detalhes e na sequência de execução conforme a documentação recebida?',
        Answer: 'white',
      },
      '2-1 c 28': {
        Question:
          'No DDS de operações de mergulho noturno, os mergulhadores são orientados quanto aos cuidados na movimentação / manuseio de ferramentas e acessórios?',
        Answer: 'white',
      },
      '2-2 a 29': {
        Question:
          'Existe plano de inspeção e manutenção preventiva das ferramentas e acessórios?',
        Answer: 'white',
      },
      '2-2 b 30': {
        Question:
          'O pessoal responsável pelo plano de inspeção e manutenção preventiva das ferramentas e acessórios é qualificado para executá-lo?',
        Answer: 'white',
      },
      '2-2 c 31': {
        Question:
          'Existe um acompanhamento em terra do plano de inspeção e manutenção preventiva das ferramentas e acessórios por um setor responsável?',
        Answer: 'white',
      },
      '2-2 d 32': {
        Question:
          'As ferramentas e acessórios de trabalho a serem utilizadas para executar as tarefas submersas são previamente verificadas e testadas na superfície?',
        Answer: 'white',
      },
      '2-3 a 33': {
        Question:
          'Há um acompanhamento seguindo as recomendações do fabricante em relação a manutenção das ferramentas e acessórios?',
        Answer: 'white',
      },
      '2-3 b 34': {
        Question:
          'Existe procedimento operacional orientando o uso de cada ferramenta e acessório de acordo com a necessidade de utilização?',
        Answer: 'white',
      },
      '2-3 c 35': {
        Question:
          'O pessoal de manutenção é familiarizado com as recomendações do fabricante com relação a manutenção e operação das diversas ferramentas e acessórios que serão utilizados na frente/embarcação de mergulho?',
        Answer: 'white',
      },
      '2-4 a 36': {
        Question:
          'É contemplado no planejamento da tarefa a utilização de ferramentas e acessórios em especial a familiarização da equipe com a nova ferramenta?',
        Answer: 'white',
      },
      '2-4 b 37': {
        Question:
          'É garantido que todos os envolvidos na utilização de ferramentas e acessórios entenderam as orientações que são passadas pelo supervisor de mergulho?',
        Answer: 'white',
      },
      '2-4 c 38': {
        Question:
          'Na utilização de ferramentas e acessórios em mergulhos noturnos é reforçada a orientação sobre os cuidados a serem tomados?',
        Answer: 'white',
      },
      '2-5 a 39': {
        Question:
          'Os supervisores de mergulho são orientados para manter comunicação constante com os mergulhadores durante as operações de mergulho?',
        Answer: 'white',
      },
      '2-5 b 40': {
        Question:
          'Os equipamentos que fazem parte do sistema de comunicação e monitoramento do mergulhador são testados antes e durante as operações de mergulho periodicamente? ',
        Answer: 'white',
      },
      '2-5 c 41': {
        Question:
          'Os equipamentos que compõem o sistema de comunicação e monitoramento do mergulhador estão inseridos no plano de manutenção dos equipamentos de mergulho?',
        Answer: 'white',
      },
      '2-5 d 42': {
        Question:
          'Durante as operações de mergulho raso o supervisor de mergulho monitora o mergulhador no local de trabalho através do sistema de comunicação com a ajuda do bellman?',
        Answer: 'white',
      },
      '2-6 a 43': {
        Question:
          'A subida e descida das ferramentas e acessórios segue uma sequência pré-estabelecida? ',
        Answer: 'white',
      },
      '2-6 b 44': {
        Question:
          'Existe algum procedimento para executar a descida e subida das ferramentas e acessórios? ',
        Answer: 'white',
      },
      '2-7 a 47': {
        Question:
          'As equipes de mergulho são treinadas periodicamente a fim de garantir a sua capacitação nos procedimentos operacionais?',
        Answer: 'white',
      },
      '2-7 b 48': {
        Question:
          'Os supervisores de mergulho são avaliados a fim de garantir a sua atualização nos procedimentos operacionais?',
        Answer: 'white',
      },
      '2-7 c 49': {
        Question:
          'Existe instrução de trabalho orientando o uso de cada ferramenta e acessório de acordo com a necessidade de utilização?',
        Answer: 'white',
      },
      '2-7 d 50': {
        Question:
          'Existe algum treinamento de como executar a descida / subida das ferramentas e acessórios para todos os membros da equipe, inclusive o supervisor?',
        Answer: 'white',
      },
      '2-7 e 51': {
        Question:
          'Em treinamentos realizados nos procedimentos operacionais de como executar a descida / subida das ferramentas e acessórios há algum sistema de avaliação dos resultados?',
        Answer: 'white',
      },


    },
    'AM-3 - Falha de operação de pull in e pull out (ruptura de cabo sob tensão)': {
      '3-1 a 52': {
        Question:
          'Na análise pré-tarefa de operação de pull in e pull out é contemplada a possível ruptura de algum cabo sob tensão?',
        Answer: 'white',
      },
      '3-1 b 53': {
        Question:
          'Na reunião pré tarefa para operação de pull in e pull out todos os riscos são contemplados com o uso de (PT, CLM, APR, DDS)?',
        Answer: 'white',
      },
      '3-1 c 54': {
        Question:
          'Na reunião pré-tarefa para operação de pull in e pull out é obrigatória a participação de todos os envolvidos direta ou indiretamente?',
        Answer: 'white',
      },
      '3-1 d 55': {
        Question:
          'Há sempre a presença de um fiscal de pull in e pull out na reunião de análises de pré-tarefa?',
        Answer: 'white',
      },
      '3-2 a 56': {
        Question:
          'Os boletins meteoceanográficos são fornecidos diariamente para todos os envolvidos na operação de pull in e pull out?',
        Answer: 'white',
      },
      '3-2 b 57': {
        Question:
          'A APR contempla as condições meteoceanográficas da localização e dia da realização do pull in e pull out?',
        Answer: 'white',
      },
      '3-3 a 58': {
        Question:
          'Na ATA de reunião pré-operações de pull in e pull out são cobrados do PLSV todos os certificados dos cabos de aço e massames que serão utilizados nas operações?',
        Answer: 'white',
      },
      '3-3 b 59': {
        Question:
          'Após a ata de reunião são enviados para o supervisor de mergulho pelo PLSV todos os certificados dos cabos de aço e massames que serão utilizados nas operações?',
        Answer: 'white',
      },
      '3-3 c 60': {
        Question:
          'É garantido que no plano de inspeção e manutenção dos equipamentos de pull in e pull out que são de responsabilidade da equipe de mergulho são realizados por pessoal especializado?',
        Answer: 'white',
      },
      '3-3 d 61': {
        Question:
          'Todos equipamentos utilizados em operações de pull in e pull out estão com os seus certificados válidos?',
        Answer: 'white',
      },
      '3-3 e 62': {
        Question:
          'As talhas de corrente utilizadas em operações de pull in e pull out são manutenidas e testadas após a conclusão de cada operação?',
        Answer: 'white',
      },
      '3-3 f 63': {
        Question:
          'O pessoal reaponsável nas operações de pull in e pull out tem conhecimento das diretrizes do IMCA D028 - Diretriz sobre o Uso de Talhas de Alavanca de Corrente no Ambiente Submarino Offshore?',
        Answer: 'white',
      },
      '3-4 a 64': {
        Question:
          'É garantido o acompanhamento da atividade com um ROV?',
        Answer: 'white',
      },
      '3-4 b 65': {
        Question:
          'No caso de falha / indisponibilidade de ROV (perda da telemetria) existe um 2º ROV para atender a premissa de acompanhar a atividade de pull in e pull out?',
        Answer: 'white',
      },
      '3-5 a 66': {
        Question:
          'Antes da execução do mergulho é realizada inspeção no sitema de comunicação e nas câmeras dos capacetes?',
        Answer: 'white',
      },
      '3-5 b 67': {
        Question:
          'Existe check list pré e pós mergulho para verificação e confirmação da operacionalidade dos sistemas de fonia e câmeras dos capacetes de mergulho? ',
        Answer: 'white',
      },
      '3-6 a 68': {
        Question:
          'Na operação de pull in e pull out é contemplada a realização de inspeção visual da BS (boca de sino), limpeza, destravamento dos dogs, etc?',
        Answer: 'white',
      },
      '3-6 b 69': {
        Question:
          'Na operação de pull in e pull out existe um plano pré-estabelecido de como realizar inspeção visual da BS (boca de sino), limpeza, destravamento dos dogs, etc?',
        Answer: 'white',
      },
      '3-6 c 70': {
        Question:
          'Os mergulhadores envolvidos nas operações de pull in e pull out são treinados e tem competência para executar os planos de inspeção e manutenção referentes a tarefa?',
        Answer: 'white',
      },
      '3-7 a 71': {
        Question:
          'Durante o DDS de operações de pull in e pull out os supervisores orientam os mergulhadores para se manterem em posicionamento seguro durante a subida ou descida do duto?',
        Answer: 'white',
      },
      '3-7 b 72': {
        Question:
          'A equipe de mergulho é treinada e tem competência para avaliar o posicionamento seguro durante a operação de pull in e pull out?',
        Answer: 'white',
      },
      '3-7 c 73': {
        Question:
          'Está contemplado no procedimento de operações de pull in e pull out a orientação sobre o posicionamento seguro dos mergulhadores?',
        Answer: 'white',
      },
      '3-7 d 74': {
        Question:
          'É monitorado pelo supervisor de mergulho através de vídeo o posicionamento seguro do mergulhador durante operação de pull in e pull out?',
        Answer: 'white',
      },




    },
    'AM-4 - Rompimento do cabo do sino/sinete/cesta (LARS)': {
      '4-1 a 75': {
        Question:
          'Um teste de descida e subida da cesta/sinete não tripulado antes do início diário dos mergulhos está contemplado na pauta das reuniões pré-tarefa (PT, CLM, APR, DDS)?',
        Answer: 'white',
      },
      '4-1 c 76': {
        Question:
          'É verificado o prazo de validade do certificado dos cabos de aço do sino / sinete /cesta de mergulho e lastro?',
        Answer: 'white',
      },
      '4-1 c 77': {
        Question:
          'É realizada uma inspeção prévia diária em todos os acessórios do sino / sinete / cesta de mergulho, tais como cabo de aço, guias dos cabos de aço, soquete, manilhas, patescas, guinchos, alimentação principal e secundária dos guinchos?',
        Answer: 'white',
      },
      '4-1 d 78': {
        Question:
          'Existe um procedimento específico contemplando todas as ações acima citadas sobre a inspeção prévia diária?',
        Answer: 'white',
      },
      '4-2 a 79': {
        Question:
          'São realizados testes de comprovação de capacitação técnica dos técnicos (elétrica, mecânico, eletroeletrônica) para serem admitidos? ',
        Answer: 'white',
      },
      '4-2 b 80': {
        Question:
          'O pessoal técnico que realiza o plano de inspeção e manutenção do LARS possui qualificação técnica comprovada documentalmente e conhecem todas as partes dos componentes que compõem o sistema LARS, tal como cabos de aço, guincho hidráulico/elétrico/pneumático, polias, cilindros hidráulicos e etc?',
        Answer: 'white',
      },
      '4-3 a 81': {
        Question:
          'Nos planos de inspeção e manutenção são contempladas as recomendações dos fabricantes dos guinchos, cabos, roldanas, válvulas de contrabalanço, cilindros hidráulicos e mangueiras que fazem parte do sistema de mergulho?',
        Answer: 'white',
      },
      '4-3 b 82': {
        Question:
          'O processo de aquisição de suprimentos está devidamente orientado a priorizar o atendimento das requisições dos itens (guinchos, cabos, roldanas, válvulas de contrabalanço, cilindros hidráulicos e mangueiras) considerados como críticos para o sistema de mergulho?',
        Answer: 'white',
      },
      '4-3 c 83': {
        Question:
          'O Plano de Manutenção Programada do LARS é realizado periodicamente conforme o cronograma?',
        Answer: 'white',
      },
      '4-3 d 84': {
        Question:
          'O software de manutenção que monitora o sistema de mergulho e sinaliza a periodicidade sistemática é individual da inspeção em cada equipamento?',
        Answer: 'white',
      },
      '4-3 e 85': {
        Question:
          'As pendências geradas no software pelas inspeções são acompanhadas por indicadores?',
        Answer: 'white',
      },
      '4-3 f 86': {
        Question:
          'É realizada inspeção no LARS quando se dá a troca de turma pelo pessoal que está embarcando e fica documentado?',
        Answer: 'white',
      },
      '4-3 g 87': {
        Question:
          'Existe um plano de inspeção e manutenção do LARS e o mesmo é realizado por pessoal qualificado? ',
        Answer: 'white',
      },
      '4-3 h 88': {
        Question:
          'O plano de manutenção e recuperação do LARS tem intervalo definido e é cumprido rigorosamente? ',
        Answer: 'white',
      },
      '4-4 a 89': {
        Question:
          'É contemplado no projeto original do LARS a existência de mecanismos limitador de final do curso da haste de acionamento dos cilindros hidráulicos do LARS?',
        Answer: 'white',
      },
      '4-4 b 90': {
        Question:
          'Existe mecanismo limitador de final do curso da haste de acionamento nos cilindros de acionamento do A-FRAME do LARS?',
        Answer: 'white',
      },
      '4-4 c 91': {
        Question:
          'É realizada a abertura do ângulo máximo do pórtico durante os testes pré-mobilização?',
        Answer: 'white',
      },
      '4-5 a 92': {
        Question:
          'As certificações dos equipamentos do LARS, CSSM (Certificado de Segurança de Sistema de Mergulho) são realizadas de acordo com a legislação vigente (NORMAM-15)?',
        Answer: 'white',
      },
      '4-5 b 93': {
        Question:
          'Há um setor responsável e habilitado na empresa que faz o acompanhamento do prazo de validade de cada LARS certificado nas diversas frentes operacionais de mergulho?',
        Answer: 'white',
      },
      '4-5 c 94': {
        Question:
          'É antecipado o prazo das recertificações que estão a vencer?',
        Answer: 'white',
      },
      '4-6 a 95': {
        Question:
          'São realizados testes de abertura máxima dos cilindros de acionamento ou de abertura de A-FRAME durante os testes de certificação? ',
        Answer: 'white',
      },
      '4-6 b 96': {
        Question:
          'São realizados testes de abertura máxima dos cilindros de acionamento ou de abertura de A-FRAME durante os testes pré-operacionais? ',
        Answer: 'white',
      },
      '4-6 c 97': {
        Question:
          'Existe inspeção técnica pós instalação do LARS no local de operação de mergulho feita por um responsável técnico da empresa e está procedimentado?',
        Answer: 'white',
      },
      '4-6 d 98': {
        Question:
          'Os técnicos (mecânicos, eletrônicos, elétricos) recebem treinamento nos equipamentos que compõem o LARS?',
        Answer: 'white',
      },
      '4-6 e 99': {
        Question:
          'As equipes de manutenção recebem treinamento de percepção de risco em equipamentos e materiais? ',
        Answer: 'white',
      },
      '4-6 f 100': {
        Question:
          'Durante o processo que antecede a aceitação do LARS é realizado inspeção dos equipamentos por empresa idônea devidamente credenciada pela Marinha?',
        Answer: 'white',
      },


    },
    'AM-5 - Queda de objeto no mar': {
        '5-1 a 101': {
          Question:
            'O CLM é validado diariamente garantindo as barreiras contra “Queda de objeto no mar”?',
          Answer: 'white',
        },
        '5-1 b 102': {
          Question:
            'Está citado na APR a utilização do CLM como ação mitigadora para queda de objeto no mar?',
          Answer: 'white',
        },
        '5-1 c 103': {
          Question:
            'Os supervisores de mergulho são treinados de como é o processso de um CLM emitido pela UEP?',
          Answer: 'white',
        },
        '5-1 d 104': {
          Question:
            'Os supervisores são familiarizados com os tópicos do CLM que são aplicados para evitar queda de objeto no mar?',
          Answer: 'white',
        },
        '5-1 e 105': {
          Question:
            'É informado nos brienfings de segurança da frente/embarcação de mergulho, sobre a realização de atividades de mergulho, solicitando cuidado para não haver queda de objetos no mar ou descarte de fluidos líquidos ou gasosos sem aviso prévio?',
          Answer: 'white',
        },
        '5-1 f 106': {
          Question:
            'É proibida a liberação de serviços que possam ocasionar quedas de objetos no mar (ex. montagem de andaimes, montagem de estruturas, operações com escaladores, outros) ou comprometer a seguranças das operações de merguho e é avisado com antecedência à embarcação de mergulho, sobre quaisquer manobras imprescindíveis?',
          Answer: 'white',
        },
        '5-1 g 107': {
          Question:
            'É proibida a movimentação de cargas sobre o mar no bordo em que estiver ocorrendo a operação de mergulho?',
          Answer: 'white',
        },
        '5-2 a 108': {
          Question:
            'É considerado uma distância horizontal mínima entre as atividades de 1,3 vezes a profundidade em que o mergulhador estiver trabalhando, caso haja atividade simultânea que envolva mergulho e andaimes?',
          Answer: 'white',
        },
        '5-2 b 109': {
          Question:
            'Na análise pré- tarefa é garantido um canal de comunicação direto com a UEP?',
          Answer: 'white',
        },
        '5-2 c 110': {
          Question:
            'É salientado em DDS a importância de se ter um acompanhamento visual constante em direção a UEP a fim de identificar uma possível queda de objeto no mar?',
          Answer: 'white',
        },
        '5-2 d 111': {
          Question:
            'É garantido durante a emissão de PT que as atividades conflitantes com as operações de mergulho não sejam liberadas pela UEP? ',
          Answer: 'white',
        },

      },
      'AM-6 - Falha na utilização da bolsa de içamento/paraquedas': {
        '6-1 a 112': {
          Question:
            'Na utilização da bolsa de içamento/paraquedas são considerados os riscos associados de sua utilização dentro de APR e DDS?',
          Answer: 'white',
        },
        '6-1 b 113': {
          Question:
            'Na análise pré-tarefa é demonstrado como e onde a bolsa de içamento/paraquedas será utilizada?',
          Answer: 'white',
        },
        '6-1 c 114': {
          Question:
            'É avaliado se a capacidade de içamento do paraquedas está compatível com a carga a ser movimentada?',
          Answer: 'white',
        },
        '6-1 d 115': {
          Question:
            'É levado em consideração utilizar mergulhadores que já tenham certa experiência com a utilização da bolsa de içamento/paraquedas em prol dos menos experientes?',
          Answer: 'white',
        },
        '6-2 a 116': {
          Question:
            'Os supervisores de mergulho são capacitados e treinados nos requisitos de segurança em trabalhos com a necessidade de utilização de bolsas de içamento/paraquedas?',
          Answer: 'white',
        },
        '6-2 b 117': {
          Question:
            'As equipes de mergulho são orientadas e recebem treinamentos dos requisitos de segurança pelos supervisores de mergulho quando da necessidade de se utilizar bolsas de içamento/paraquedas?',
          Answer: 'white',
        },
        '6-2 c 118': {
          Question:
            'Existe treinamento especifico com os mergulhadores para a utilização das bolsas de içamento/paraquedas? ',
          Answer: 'white',
        },
        '6-3 a 119': {
          Question:
            'Os supervisores de mergulho são familiarizados com o conteúdo do IMCA D016 - Diretrizes sobre Paraquedas do tipo Bolsas de Içamento Subaquático?',
          Answer: 'white',
        },
        '6-3 b 120': {
          Question:
            'As bolsas de içamento/paraquedas são testadas na superfície antes de sua utilização dentro da água?',
          Answer: 'white',
        },
        '6-3 c 121': {
          Question:
            'As bolsas de içamento/paraquedas são testadas na superfície após a sua utilização dentro da água?',
          Answer: 'white',
        },
        '6-3 d 122': {
          Question:
            'As bolsas de içamento/paraquedas são armazenadas de acordo com as recomendações do fabricante?',
          Answer: 'white',
        },
        '6-3 e 123': {
          Question:
            'Quando uma bolsa de içamento/paraquedas se torna inoperante, seu reparo é feito em terra?',
          Answer: 'white',
        },
        '6-4 a 124': {
          Question:
            'São realizados simulados com ênfase no procedimento de resposta a emergências subaquáticas, no controle emocional dos mergulhadores e supervisores envolvidos nas operações de mergulho?',
          Answer: 'white',
        },
        '6-4 b 125': {
          Question:
            'Os supervisores e mergulhadores são capacitados e avaliados periodicamente em treinamentos para lidar com intercorrências durante as operações de mergulho através de simulados e demais exercícios práticos prevenindo que se desesperem podendo ocasionar uma falha na utilização de bolsa de içamento/paraquedas? ',
          Answer: 'white',
        },
        '6-4 c 126': {
          Question:
            'Nos simulados de mergulho, os supervisores são orientados a focar na calma e no controle emocional dos mergulhadores para evitar que os mesmos entrem em desespero podendo ocasionar uma falha na utilização de bolsa de içamento/paraquedas?',
          Answer: 'white',
        },
        '6-4 d 127': {
          Question:
            'De forma geral, os supervisores têm o conhecimento do perfil dos membros de suas respectivas equipes, para em caso de uma intercorrência saber transmitir as orientações necessárias e manter a estabilidade emocional do mergulhador para que o mesmo não entre em desespero e acabe se chocando contra obstáculos devido a uma falha na utilização de bolsa de içamento/paraquedas?',
          Answer: 'white',
        },
        '6-5 a 128': {
          Question:
            'Existe um plano de treinamento para capacitar os mergulhadores na utilização da bolsa de içamento/paraquedas?',
          Answer: 'white',
        },
        '6-5 b 129': {
          Question:
            'O treinamento para capacitar os mergulhadores na utilização da bolsa de içamento/paraquedas é realizado em terra?',
          Answer: 'white',
        },
        '6-5 c 130': {
          Question:
            'O treinamento para capacitar os mergulhadores na utilização da bolsa de içamento/paraquedas é realizado em tanque próprio para simulados?',
          Answer: 'white',
        },
        '6-5 d 131': {
          Question:
            'Existe algum manual didático utilizado antes do treinamento prático de capacitação dos mergulhadores na utilização da bolsa de içamento/paraquedas?',
          Answer: 'white',
        },
        '6-5 e 132': {
          Question:
            'Existe um controle de aproveitamento do treinamento para capacitar os mergulhadores na utilização da bolsa de içamento/paraquedas? ',
          Answer: 'white',
        },
        '6-5 f 133': {
          Question:
            'Existe treinamento de reciclagem do treinamento para capacitar os mergulhadores na utilização da bolsa de içamento/paraquedas?',
          Answer: 'white',
        },
        '6-6 a 134': {
          Question:
            'Durante o DDS de operações que será utilizado bolsa de içamento/paraquedas os supervisores orientam os mergulhadores para se manterem em posicionamento seguro e seu umbilical?',
          Answer: 'white',
        },
        '6-6 b 135': {
          Question:
            'A equipe de mergulho é treinada e tem competência para avaliar o seu posicionamento seguro e do seu umbilical durante a operação com bolsa de içamento/ paraquedas?',
          Answer: 'white',
        },
        '6-6 c 136': {
          Question:
            'Está contemplado no procedimento de operações com bolsa de içamento/ paraquedas a orientação sobre o posicionamento seguro dos mergulhadores?',
          Answer: 'white',
        },
        '6-6 d 137': {
          Question:
            'É monitorado pelo supervisor de mergulho através de vídeo o posicionamento seguro do mergulhador e seu umbilical durante operação com bolsa de içamento/ paraquedas?',
          Answer: 'white',
        },
        '6-6 f 138': {
          Question:
            'É de conhecimento dos supervisores de mergulho o IMCA D016 - Diretrizes sobre Paraquedas do tipo Bolsas de Içamento Subaquático?',
          Answer: 'white',
        },
        '6-6 g 139': {
            Question:
              'É levado em consideração uma visibilidade limite em operações que envolvam bolsa de içamento/ paraquedas?',
            Answer: 'white',
          },

      },
      'AM-7 - Rompimento de amarras e dutos flexíveis': {
        '7-1 a 140': {
          Question:
            'O detalhamento técnico dos serviços em dutos e amarras leva em consideração a existência de RTI emitida, a presença na lista de amarras com baixo fator de segurança e a presença na lista de dutos suscetíveis ao SCC-CO2 (ou dutos adjacentes a ele)?',
          Answer: 'white',
        },
        '7-1 b 141': {
          Question:
            'Caso o duto ou amarra se enquadre nas condições citadas na pergunta acima, é feito um planejamento diferenciado com a emissão de uma APR específica para o serviço?',
          Answer: 'white',
        },
        '7-1 c 142': {
          Question:
            'Na fase da APR, são reunidas as informações disponíveis sobre os dutos e amarras, inclusive simulações de queda do duto ou da amarra elaboradas pelo Cenpes, caso disponíveis?',
          Answer: 'white',
        },
        '7-2 a 143': {
          Question:
            'Em tarefas de abertura de flanges e conectores de dutos é solicitado ao setor responsável a emissão do CLL contemplando o risco de rompimento de amarras e dutos flexiveis?',
          Answer: 'white',
        },
        '7-2 b 144': {
          Question:
            'É elaborada uma APR contemplando o risco de rompimento de amarras e dutos flexíveis, a mesma é divulgada em DDS?',
          Answer: 'white',
        },
        '7-2 c 145': {
          Question:
            'É garantida a revalidação diária do CLM em conjunto com a PT contemplando os riscos associados ao rompimento de amarras e dutos flexíveis?',
          Answer: 'white',
        },
        '7-2 d 146': {
          Question:
            'Os supervisores de mergulho são familiarizados com as PT e CLM emitidos no dia?',
          Answer: 'white',
        },
        '7-3 a 147': {
          Question:
            'O planejamento de mergulhos realizados em amarras e dutos flexíveis leva em consideração a consulta sistemática aos boletins meteoceanográficos?',
          Answer: 'white',
        },
        '7-3 b 148': {
          Question:
            'O supervisor de mergulho é treinado para interromper a operação de mergulho em função de alguma dificuldade de posicionamento que o mergulhador esteja passando na água, mesmo com a força da correnteza abaixo do limite operacional?',
          Answer: 'white',
        },
        '7-3 c 149': {
          Question:
            'O bellman é treinado a reconhecer quando existe correnteza e que precisa estar atento para não liberar excesso de umbilical para o mergulhador? ',
          Answer: 'white',
        },
        '7-3 d 150': {
          Question:
            'São avaliadas as condições de visibilidade para executar o mergulho em amarras e dutos flexíveis?',
          Answer: 'white',
        },
        '7-3 e 151': {
          Question:
            'É considerado na equipe de mergulho, pelo supervisor, quais são os mergulhadores com mais destreza em águas com visibilidade restrita?',
          Answer: 'white',
        },
        '7-3 f 152': {
          Question:
            'É respeitada a boa prática de se interromper o mergulho, avaliando o aumento da intensidade da correnteza e de sua direção?',
          Answer: 'white',
        },
        '7-3 g 153': {
          Question:
            'É analisada a possibilidade de obstáculos que possam prender o umbilical entre a cesta/sinete e o local de trabalho?',
          Answer: 'white',
        },
        '7-3 h 154': {
          Question:
            'É ressaltado em DDS os cuidados de posicionamento seguro de mergulhos realizados em amarras e dutos flexíveis?',
          Answer: 'white',
        },
        '7-3 i 155': {
          Question:
            'O planejamento da atividade é realizado com todos os envolvidos, supervisor de mergulho, técnico de planejamento, merguhadores e todos aqueles com participação direta ou indireta em mergulhos realizados em amarras e dutos flexíveis?',
          Answer: 'white',
        },


      },
      'AM-8 – Deslocamento inadvertido da embarcação durante as operações de mergulho': {
        '8-1 a 156': {
          Question:
            'É garantida a realização de teste de deriva antes do início do mergulho em todas as operações?',
          Answer: 'white',
        },
        '8-1 b 157': {
          Question:
            'É disponibilizado tempo suficiente para a realização do check list do posicionamento dinâmico (DP)?',
          Answer: 'white',
        },
        '8-1 c 158': {
          Question:
            'É contemplado no plano de manutenção a verificação periódica dos periféricos eletrônicos que compõem o posicionamento dinâmico (DP)? ',
          Answer: 'white',
        },
        '8-1 d 159': {
          Question:
            'Existe controle de treinamentos dos operadores de posicionamento dinâmico (DP)?',
          Answer: 'white',
        },
        '8-2 a 160': {
          Question:
            'O supervisor de mergulho em comum acordo com o comandante da embarcação estão atentos aos limites operacionais meteoceonográficos?',
          Answer: 'white',
        },
        '8-2 b 161': {
          Question:
            'Existe check list que contemple as condições perigosas e limites operacionais de acordo com as condições meteoceonográficas para o mergulho?',
          Answer: 'white',
        },


      },
      'AM-9 - Fuga de corrente de equipamentos e acessórios energizados': {
        '9-1 a 162': {
          Question:
            'Em mergulhos com risco de corrente de equipamentos e acessórios energizados é realizada a análise pré-tarefa consultando toda a documentação requisitada para executar o mergulho?',
          Answer: 'white',
        },
        '9-1 b 163': {
          Question:
            'Existe técnico de planejamento a bordo para analisar previamente toda a documentação recebida (MD, PE, PO, IT, PT, CLM) referente a tarefa a ser executada?',
          Answer: 'white',
        },
        '9-2 a 164': {
          Question:
            'São seguidos os requisitos que devem ser observados no uso de eletricidade subaquática por mergulhadores descritos no PE-1PBR-00221 quanto a tensão segura?',
          Answer: 'white',
        },
        '9-2 a 165': {
          Question:
            'Em equipamentos que não possam ser desenergizados é seguida a Tabela 4 (Tensão segura para uso de eletricidade subaquática) do PE-1PBR-00221?',
          Answer: 'white',
        },
        '9-3 a 166': {
          Question:
            'A APR contempla uma avaliação da distância mínima segura para o mergulhador que deverá ser calculada em equipamentos que a tensão envolvida esteja acima do limite de segurança? ',
          Answer: 'white',
        },
        '9-3 b 167': {
          Question:
            'São seguidos os requisitos que devem ser observados no uso de eletricidade subaquática por mergulhadores descritos no PE-1PBR-00221 quanto a distância segura?',
          Answer: 'white',
        },
        '9-4 a 168': {
          Question:
            'O planejamento da atividade em equipamentos e acessórios energizados é realizado considerando a desenergização da fonte primária?',
          Answer: 'white',
        },
        '9-4 b 169': {
          Question:
            'É confirmado que todas as premissas necessárias da desenergização da fonte primária foram contempladas no CLM?',
          Answer: 'white',
        },
        '9-4 c 170': {
          Question:
            'É confirmado que todas as premissas necessárias da desenergização da fonte primária foram contempladas na PT?',
          Answer: 'white',
        },
        '9-4 d 171': {
          Question:
            'O equipamento, duto ou cabo, no caso de alta potência elétrica, que sofrerá intervenção por mergulho é desenergizado, bloqueado e/ etiquetado conforme exigido no CLM? ',
          Answer: 'white',
        },
        '9-4 b 172': {
          Question:
            'São seguidos os requisitos que devem ser observados no uso de eletricidade subaquática por mergulhadores descritos no PE-1PBR-00221 quanto a desenergização?',
          Answer: 'white',
        },
        '9-5 a 173': {
          Question:
            'Existe um plano de inspeção e manutenção dos componentes elétricos dos equipamentos que compõem o sistema de mergulho?',
          Answer: 'white',
        },
        '9-5 a 174': {
          Question:
            'O plano de inspeção e manutenção dos componentes elétricos dos equipamentos que compõem o sistema de mergulho seguem um monitoramento sistemático?',
          Answer: 'white',
        },
        '9-5 b 175': {
          Question:
            'O pessoal que atende ao plano de inspeção e manutenção dos componentes elétricos dos equipamentos que compõem o sistema de mergulho é qualificado?',
          Answer: 'white',
        },


      },
      'AM-10 – Rompimento de Mangueiras hidráulicas e outros acessórios dos sistemas de mergulho': {
        '10-1 a 176': {
          Question:
            'No caso de mangueiras hidráulicas, existe um plano de inspeção e manutenção específico para acompanhamento periódico?',
          Answer: 'white',
        },
        '10-1 b 177': {
          Question:
            'O plano de inspeção e manutenção atende as recomendações do fabricante de mangueiras hidráulicas de acordo com a periodicidade correspondente?',
          Answer: 'white',
        },
        '10-1 c 178': {
          Question:
            'O pessoal responsável pelo plano de inspeção e manutenção de mangueiras hidráulicas é qualificado?',
          Answer: 'white',
        },
        '10-2 a 179': {
          Question:
            'Os testes hidrostáticos que avaliam a integridade das mangueiras são realizados por empresa acreditada pelo INMETRO?',
          Answer: 'white',
        },
        '10-2 b 180': {
          Question:
            'Existe um monitoramento sistemático da validade dos certificados das mangueiras hidráulicas da frente/embarcação de mergulho?',
          Answer: 'white',
        },
        '10-3 a 181': {
          Question:
            'Existe barreira física contra projeção dos componentes dos manômetros indicadores de pressão nas garrafas de ar comprimido e oxigênio em caso de falha?',
          Answer: 'white',
        },
        '10-3 b 182': {
          Question:
            'No procedimento de operações de mergulho consta a orientação para a abertura lenta dos cilindros de alta pressão (ar comprimido e O2)?',
          Answer: 'white',
        },
        '10-3 c 183': {
          Question:
            'No check list pré-operações consta a verificação do estado das barreiras dos manômetros dos cilindros de alta pressão?',
          Answer: 'white',
        },
        '10-4 a 184': {
          Question:
            'São instalados em todas as mangueiras de alta e baixa pressão sistema de anti-chicoteamento junto aos terminais?',
          Answer: 'white',
        },
        '10-4 b 185': {
          Question:
            'São verficados durante o check list pré-operações o estado de fixação e conservação dos sistemas anti-chicoteamento junto aos terminais das mangueiras?',
          Answer: 'white',
        },



      },
      'AM-11 – Impacto durante o lançamento e recolhimento do LDB': {
        '11-1 a 186': {
          Question:
            'Na avaliação para lançamento do LDB são verificadas as condições meteoceanográficas adequadas?',
          Answer: 'white',
        },
        '11-1 b 187': {
          Question:
            'Durante as operações do LDB na água são monitoradas as condições meteoceanográficas para garantir o retorno seguro do LDB a embarcação principal?',
          Answer: 'white',
        },
        '11-2 a 188': {
          Question:
            'No procedimento de lançamento e recolhimento do LDB está previsto o posicionamento adequado da embarcação principal?',
          Answer: 'white',
        },
        '11-2 b 189': {
          Question:
            'A equipe responsável é treinada para garantir o melhor posicionamento da embarcação principal durante o lançamento e recolhimento do LDB para evitar impacto do LDB com a embarcação?',
          Answer: 'white',
        },
        '11-3 a 190': {
          Question:
            'Existe instalado sistema de bloqueio contra acionamento involuntário da manete do latch de proa do LDB (towing boom)?',
          Answer: 'white',
        },
        '11-3 b 191': {
          Question:
            'No procedimento pré-lançamento do LDB consta a verificação do bloqueio contra acionamento involuntário da manete do latch de proa do LDB (towing boom)?',
          Answer: 'white',
        },
        '11-4 a 192': {
          Question:
            'Existe sensor instalado para identificar a abertura e fechamento do gancho do cabo que conecta o Davit ao LDB?',
          Answer: 'white',
        },
        '11-4 b 193': {
          Question:
            'No procedimento pré-lançamento do LDB consta a verificação do sensor de identificação de abertura e fechamento do gancho do cabo que conecta o Davit ao LDB?',
          Answer: 'white',
        },



      },
      'CO-1 - Lesão com potencial de invalidez e fatalidade': {
        '12-1 a 194': {
          Question:
            'Durante as operações de mergulho raso o supervisor de mergulho monitora o mergulhador no local de trabalho através do sistema de comunicação com a ajuda do bellman?',
          Answer: 'white',
        },
        '12-1 b 195': {
          Question:
            'Os equipamentos de comunicação são checados antes e durante as operações de mergulho?',
          Answer: 'white',
        },
        '12-1 c 196': {
          Question:
            'Os equipamentos que fazem parte do sistema de monitoramento do mergulhador estão contemplados no plano de manutenção do sistema de mergulho?',
          Answer: 'white',
        },
        '12-1 d 197': {
          Question:
            'A Black box do sistema de monitoramento do mergulhador está de acordo com as normas vigentes com capacidade suficiente de armazenamento?',
          Answer: 'white',
        },
        '12-2 a 198': {
          Question:
            'A equipe de mergulho é orientada na boa pratica do uso adequado dos EPIs fornecidos?',
          Answer: 'white',
        },
        '12-2 b 199': {
          Question:
            'Os supervisores avaliam o uso adequado dos EPIs pela equipe de mergulho?',
          Answer: 'white',
        },
        '12-2 c 200': {
          Question:
            'São disponibilizados EPIs de acordo com a atividade a ser executada?',
          Answer: 'white',
        },
        '12-2 d 201': {
          Question:
            'É contemplado no planejamento da atividade de mergulho todos os EPIs que devem ser disponibilizados conforme a operação de mergulho na frente/embarcação de mergulho?',
          Answer: 'white',
        },
        '12-2 e 202': {
          Question:
            'O setor de segurança da empresa de mergulho fornece EPIs necessários para a atividade e tem controle dos mesmos?',
          Answer: 'white',
        },
        '12-2 f 203': {
          Question:
            'A utilização adequada do cinto de segurança antes do lançamento e recolhimento do LDB é obrigatória?',
          Answer: 'white',
        },
        '12-3 a 204': {
          Question:
            'Os supervisores de mergulho são capacitados e treinados para manter o controle mental e orientar os mergulhadores na água durante uma emergência na frente/embarcação de mergulho (ex. fraturas, choques elétricos e etc.)?',
          Answer: 'white',
        },
        '12-3 b 205': {
          Question:
            'Os mergulhadores são orientados pelos supervisores para em caso de alguma emergência a bordo da frente/embarcação de mergulho, os mergulhadores retornarem o mais breve a unidade mantendo o controle físico e mental?',
          Answer: 'white',
        },
        '12-3 c 206': {
          Question:
            'A equipe de mergulho participa dos treinamentos de emergência da frente/embarcação de mergulho que embarcam frequentemente?',
          Answer: 'white',
        },
        '12-3 d 207': {
          Question:
            'São realizados simulados com a equipe de mergulho em cenário de contingência?',
          Answer: 'white',
        },
        '12-4 a 208': {
          Question:
            'Os supervisores de mergulho são capacitados e treinados no plano de resposta a emergência, abortar o mergulho em andamento, retornar a cesta, sinete ou a superfície com o auxílio de Bellman ou mergulhador de emergência? ',
          Answer: 'white',
        },
        '12-4 b 209': {
          Question:
            'O plano de contingência prevê o passo a passo para abortar o mergulho e retornar a cesta, sinete ou ao a superfície com a ajuda do Bellman ou o mergulhador de emergência? ',
          Answer: 'white',
        },
        '12-5 a 210': {
          Question:
            'Os supervisores são capacitados e treinados para orientar a realização do resgate do mergulhador pelo mergulhador de emergência?',
          Answer: 'white',
        },
        '12-5 b 211': {
          Question:
            'O mergulhador de emergência na superfície, permanece equipado e pronto para intervir no menor tempo possível sendo num simulado ou durante uma intercorrência real?',
          Answer: 'white',
        },
        '12-5 c 212': {
          Question:
            'Existe periodicidade para realização de simulado de resgate do mergulhador pelo mergulhador de emergência?',
          Answer: 'white',
        },
        '12-5 d 213': {
          Question:
            'A empresa controla através do seu departamento de segurança o treinamento frequente envolvendo toda a equipe no procedimento de resgate do mergulhador através do mergulhador de emergência?',
          Answer: 'white',
        },
        '12-6 a 214': {
          Question:
            'Existem, ao menos, dois membros na equipe de mergulho capacitados e treinados em suporte básico a vida, no atendimento inicial a emergências subaquáticas, atendendo assim as normas vigentes (Normam 15)?',
          Answer: 'white',
        },
        '12-6 b 215': {
          Question:
            'É cobrado eficácia nos simulados dos planos de resposta a emergência com ênfase na comunicação adequada?',
          Answer: 'white',
        },
        '12-6 c 216': {
          Question:
            'O treinamento de reciclagem em atendimento a emergências de primeiros socorros atende as orientações de acordo com as normas vigentes?',
          Answer: 'white',
        },
        '12-6 d 217': {
          Question:
            'São realizados simulados de primeiros socorros com as equipes de mergulho com ênfase na saúde dos mergulhadores?',
          Answer: 'white',
        },
        '12-7 a 218': {
          Question:
            'Há profissional de saúde de nível superior devidamente habilitado na frente/embarcação d emergulho?',
          Answer: 'white',
        },
        '12-7 b 219': {
          Question:
            'O profissional de saúde é capacitado e treinado em suporte avançado de vida e atendimento a emergência subaquática?',
          Answer: 'white',
        },
        '12-8 a 220': {
          Question:
            'A embarcação possui enfermaria que atenda aos requisitos da Normam-01, da NR-30 e demais legislações pertinentes? ',
          Answer: 'white',
        },
        '12-8 b 221': {
          Question:
            'A enfermaria possui quantidade de equipamentos e medicamentos que atende a lista de itens mínimos de enfermaria padronizada pela Petrobras para embarcações de mergulho? ',
          Answer: 'white',
        },
        '12-9 a 222': {
          Question:
            'Os meios de comunicação com o suporte médico em terra são adequados e tem backup?',
          Answer: 'white',
        },
        '12-9 b 223': {
          Question:
            'No procedimento de contingência está previsto o plano de resposta a emergência médica com os recursos de contato telefônico ou por vídeo?',
          Answer: 'white',
        },
        '12-9 c 224': {
          Question:
            'Existe plano de atendimento médico emergencial na empresa 24 horas para atender as equipes embarcadas no offshore?',
          Answer: 'white',
        },
        '12-9 d 225': {
          Question:
            'É previsto no procedimento de emergência da empresa, antes de uma operação de mergulho, que haja a verificação de uma unidade marítima mais próxima das operações adequada a receber o mergulhador vitimado com disponibilidade de resgate aeromédico?',
          Answer: 'white',
        },
        '12-10 a 226': {
          Question:
            'Existe procedimento específico para em caso de acidente com necessidade de remoção e transporte para hospital ou clínica em terra?',
          Answer: 'white',
        },
        '12-10 b 227': {
          Question:
            'Os supervisores estão aptos a orientar os responsáveis pelo transporte de acidentados via aérea, de que após a realização de mergulho, o voo deve cumprir um limite de altitude durante o voo em cumprimento da NORMAM-15?',
          Answer: 'white',
        },
        '12-10 c 228': {
          Question:
            'O Plano de contingência garante acesso a rede hospitalar especializada e a disponibilidade de recursos para um tratamento específico até o pronto reestabelecimento do acidentado?',
          Answer: 'white',
        },
        '12-10 d 229': {
          Question:
            'O RH da empresa está capacitado a proporcionar todo o acompanhamento ao acidentado desde a internação até o seu retorno as atividades?',
          Answer: 'white',
        },
        '12-11 a 230': {
          Question:
            'A empresa através do seu RH tem como procedimento a avaliação médica criteriosa para liberação das atividades laborais do acidentado?',
          Answer: 'white',
        },
        '12-11 b 231': {
          Question:
            'O colaborador acidentado bem como sua família são acompanhados pelo RH no caso de um afastamento prolongado?',
          Answer: 'white',
        },


      },
  };

  let listKeysQuestionExposicaoDescargasEletricas=[
    {
      'AM-1 - Variação brusca de swell/correnteza provocando encontro do mergulhador contra obstáculos': [
        { '1-1': { Check: [], Answer: 'white' } },
        { '1-2': { Check: [], Answer: 'white' } },
        { '1-3': { Check: [], Answer: 'white' } },
        { '1-4': { Check: [], Answer: 'white' } },
        { '1-5': { Check: [], Answer: 'white' } },
        { '1-6': { Check: [], Answer: 'white' } },

      ].reverse(),
      'AM-2 - Movimentação/ Manuseio inadequado ou falha de ferramentas e acessórios de trabalho': [
        { '2-1': { Check: [], Answer: 'white' } },
        { '2-2': { Check: [], Answer: 'white' } },
        { '2-3': { Check: [], Answer: 'white' } },
        { '2-4': { Check: [], Answer: 'white' } },
        { '2-5': { Check: [], Answer: 'white' } },
        { '2-6': { Check: [], Answer: 'white' } },
        { '2-7': { Check: [], Answer: 'white' } },


      ].reverse(),
      'AM-3 - Falha de operação de pull in e pull out (ruptura de cabo sob tensão)': [
        { '3-1': { Check: [], Answer: 'white' } },
        { '3-2': { Check: [], Answer: 'white' } },
        { '3-3': { Check: [], Answer: 'white' } },
        { '3-4': { Check: [], Answer: 'white' } },
        { '3-5': { Check: [], Answer: 'white' } },
        { '3-6': { Check: [], Answer: 'white' } },
        { '3-7': { Check: [], Answer: 'white' } },

      ].reverse(),
      'AM-4 - Rompimento do cabo do sino/sinete/cesta (LARS)': [
        { '4-1': { Check: [], Answer: 'white' } },
        { '4-2': { Check: [], Answer: 'white' } },
        { '4-3': { Check: [], Answer: 'white' } },
        { '4-4': { Check: [], Answer: 'white' } },
        { '4-5': { Check: [], Answer: 'white' } },
        { '4-6': { Check: [], Answer: 'white' } },


      ].reverse(),
      'AM-5 - Queda de objeto no mar': [
        { '5-1': { Check: [], Answer: 'white' } },
        { '5-2': { Check: [], Answer: 'white' } },



      ].reverse(),
      'AM-6 - Falha na utilização da bolsa de içamento/paraquedas': [
        { '6-1': { Check: [], Answer: 'white' } },
        { '6-2': { Check: [], Answer: 'white' } },
        { '6-3': { Check: [], Answer: 'white' } },
        { '6-4': { Check: [], Answer: 'white' } },
        { '6-5': { Check: [], Answer: 'white' } },
        { '6-6': { Check: [], Answer: 'white' } },


      ].reverse(),
      'AM-7 - Rompimento de amarras e dutos flexíveis': [
        { '7-1': { Check: [], Answer: 'white' } },
        { '7-2': { Check: [], Answer: 'white' } },
        { '7-3': { Check: [], Answer: 'white' } },



      ].reverse(),
      'AM-8 – Deslocamento inadvertido da embarcação durante as operações de mergulho': [
        { '8-1': { Check: [], Answer: 'white' } },
        { '8-2': { Check: [], Answer: 'white' } },



      ].reverse(),
      'AM-9 - Fuga de corrente de equipamentos e acessórios energizados': [
        { '9-1': { Check: [], Answer: 'white' } },
        { '9-2': { Check: [], Answer: 'white' } },
        { '9-3': { Check: [], Answer: 'white' } },
        { '9-4': { Check: [], Answer: 'white' } },
        { '9-5': { Check: [], Answer: 'white' } },



      ].reverse(),
      'AM-10 – Rompimento de Mangueiras hidráulicas e outros acessórios dos sistemas de mergulho': [
        { '10-1': { Check: [], Answer: 'white' } },
        { '10-2': { Check: [], Answer: 'white' } },
        { '10-3': { Check: [], Answer: 'white' } },
        { '10-4': { Check: [], Answer: 'white' } },



      ].reverse(),
      'AM-11 – Impacto durante o lançamento e recolhimento do LDB': [
        { '11-1': { Check: [], Answer: 'white' } },
        { '11-2': { Check: [], Answer: 'white' } },
        { '11-3': { Check: [], Answer: 'white' } },
        { '11-4': { Check: [], Answer: 'white' } },

      ].reverse(),
      'CO-1 - Lesão com potencial de invalidez e fatalidade': [
        { '12-1': { Check: [], Answer: 'white' } },
        { '12-2': { Check: [], Answer: 'white' } },
        { '12-3': { Check: [], Answer: 'white' } },
        { '12-4': { Check: [], Answer: 'white' } },
        { '12-5': { Check: [], Answer: 'white' } },
        { '12-6': { Check: [], Answer: 'white' } },
        { '12-7': { Check: [], Answer: 'white' } },
        { '12-8': { Check: [], Answer: 'white' } },
        { '12-9': { Check: [], Answer: 'white' } },
        { '12-10': { Check: [], Answer: 'white' } },
        { '12-11': { Check: [], Answer: 'white' } },

      ],


    }]


  export {TitlesAndQuestionsExposicaoDescargasEletricas,listKeysQuestionExposicaoDescargasEletricas,titleFormExposicaoDescargasEletricas,titleHazardExposicaoDescargasEletricas,SecondIdExposicaoDescargasEletricas,listAMCOExposicaoDescargasEletricas,limitationForSubmitExposicaoDescargasEletricas}

import styled from 'styled-components';

export const Main = styled.div`
  text-align: center;

  iframe{
    margin: 0 auto;
    display: block;
  }
`;

import api from '../../../services/api';
import React, { useEffect, useState } from 'react';
import Xarrow from 'react-xarrows';
import XLSX from 'sheetjs-style';

import {
  listKeysQuestionFaltaRespiratorio,
  titleHazardFaltaRespiratorio,
  TitlesAndQuestionsFaltaRespiratorio,
} from '../../bowtie/checklist/formsModel/formFaltaRespiratorioModelNew';
import {
  listKeysQuestionAcidentesHiperbaricos,
  titleHazardAcidentesHiperbaricos,
  TitlesAndQuestionsAcidentesHiperbaricos,
} from '../../bowtie/checklist/formsModel/formAcidentesHiperbaricosModel';
import {
  listKeysQuestionFalhaSistemaLancamento,
  titleHazardFalhaSistemaLancamento,
  TitlesAndQuestionsFalhaSistemaLancamento,
} from '../../bowtie/checklist/formsModel/formFalhaSistemaLancamento';
import {
  listKeysQuestionAprisionamentoMergulhador,
  titleHazardAprisionamentoMergulhador,
  TitlesAndQuestionsAprisionamentoMergulhador,
} from '../checklist/formsModel/formAprisionamentoMergulhador';
import {
  listKeysQuestionExposicaoInadequadaMergulhador,
  titleHazardExposicaoInadequadaMergulhador,
  TitlesAndQuestionsExposicaoInadequadaMergulhador,
} from '../checklist/formsModel/formExposicaoInadequadaMergulhador';
import {
  listKeysQuestionIncendioExplosaoCamara,
  titleHazardIncendioExplosaoCamara,
  TitlesAndQuestionsIncendioExplosaoCamara,
} from '../checklist/formsModel/formIncendioExplosaoCamara';
import {
  listKeysQuestionFalhaEquipamentoIndividual,
  titleHazardFalhaEquipamentoIndividual,
  TitlesAndQuestionsFalhaEquipamentoIndividual,
} from '../checklist/formsModel/formFalhaEquipamentoIndividual';
import {
  listKeysQuestionContatoAcidentalMergulhador,
  titleHazardContatoAcidentalMergulhador,
  TitlesAndQuestionsContatoAcidentalMergulhador,
} from '../checklist/formsModel/formContatoAcidentalMergulhador';
import {
  listKeysQuestionExposicaoMergulhadoresContaminantes,
  titleHazardExposicaoMergulhadoresContaminantes,
  TitlesAndQuestionsExposicaoMergulhadoresContaminantes,
} from '../checklist/formsModel/formExposicaoMergulhadoresContaminantes';
import {
  listKeysQuestionExposicaoDescargasEletricas,
  titleHazardExposicaoDescargasEletricas,
  TitlesAndQuestionsExposicaoDescargasEletricas,
} from '../checklist/formsModel/formExposicaoDescargasEletricas';
import {
  listKeysQuestionExposicaoMergulhadorVidaMarinha,
  titleHazardExposicaoMergulhadorVidaMarinha,
  TitlesAndQuestionsExposicaoMergulhadorVidaMarinha,
} from '../checklist/formsModel/formExposicaoMergulhadorVidaMarinha';
import {
  listKeysQuestionEmergenciasMedicasHiberbaricas,
  titleHazardEmergenciasMedicasHiberbaricas,
  TitlesAndQuestionsEmergenciasMedicasHiberbaricas,
} from '../checklist/formsModel/formEmergenciasMedicasHiberbaricas';
import './style.css';

import EventoAAM1 from './legendas/EventoA/legenda-AM1-evento-A.png';
import EventoAAM2 from './legendas/EventoA/legenda-AM2-evento-A.png';
import EventoAAM3 from './legendas/EventoA/legenda-AM3-evento-A.png';
import EventoAAM4 from './legendas/EventoA/legenda-AM4-evento-A.png';
import EventoAAM5 from './legendas/EventoA/legenda-AM5-evento-A.png';
import EventoAAM6 from './legendas/EventoA/legenda-AM6-evento-A.png';
import EventoAAM7 from './legendas/EventoA/legenda-AM7-evento-A.png';
import EventoAAM8 from './legendas/EventoA/legenda-AM8-evento-A.png';
import EventoAAM9 from './legendas/EventoA/legenda-AM9-evento-A.png';
import EventoACO1 from "./legendas/EventoA/Legenda-CO1-evento-A.png"


import EventoBAM1 from './legendas/EventoB/legenda AM1.png';
import EventoBAM2 from './legendas/EventoB/legenda AM2.png';
import EventoBAM3 from './legendas/EventoB/legenda AM3.png';
import EventoBAM4 from './legendas/EventoB/legenda AM4.png';
import EventoBAM5 from './legendas/EventoB/legenda AM5.png';
import EventoBAM6 from './legendas/EventoB/legenda AM6.png';
import EventoBCO1 from './legendas/EventoB/legenda CO1.png';
import EventoBCO2 from './legendas/EventoB/legenda CO2.png';

import EventoDAM1 from './legendas/EventoD/legenda AM1.png';
import EventoDAM2 from './legendas/EventoD/legenda AM2.png';
import EventoDAM3 from './legendas/EventoD/legenda AM3.png';
import EventoDAM4 from './legendas/EventoD/legenda AM4.png';
import EventoDAM5 from './legendas/EventoD/legenda AM5.png';
import EventoDAM6 from './legendas/EventoD/legenda AM6.png';
import EventoDAM7 from './legendas/EventoD/legenda AM7.png';
import EventoDCO1 from './legendas/EventoD/legenda CO1.png';

import EventoEAM1 from './legendas/EventoE/legenda AM1.png';
import EventoEAM2 from './legendas/EventoE/legenda AM2.png';
import EventoEAM3 from './legendas/EventoE/legenda AM3.png';
import EventoEAM4 from './legendas/EventoE/legenda AM4.png';
import EventoECO1 from './legendas/EventoE/legenda CO1.png';

import EventoGAM1 from './legendas/EventoG/legenda AM1.png';
import EventoGAM2 from './legendas/EventoG/legenda AM2.png';
import EventoGAM3 from './legendas/EventoG/legenda AM3.png';
import EventoGAM4 from './legendas/EventoG/legenda AM4.png';
import EventoGAM5 from './legendas/EventoG/legenda AM5.png';
import EventoGAM6 from './legendas/EventoG/legenda AM6.png';
import EventoGCO1 from './legendas/EventoG/legenda CO1.png';
import EventoGCO2 from './legendas/EventoG/legenda CO2.png';
import EventoGCO3 from './legendas/EventoG/legenda CO3.png';

import EventoHAM1 from './legendas/EventoH/legenda AM1.png';
import EventoHAM2 from './legendas/EventoH/legenda AM2.png';
import EventoHAM3 from './legendas/EventoH/legenda AM3.png';
import EventoHAM4 from './legendas/EventoH/legenda AM4.png';
import EventoHAM5 from './legendas/EventoH/legenda AM5.png';
import EventoHCO1 from './legendas/EventoH/legenda CO1.png';
import EventoHCO2 from './legendas/EventoH/legenda CO2.png';
import EventoHCO3 from './legendas/EventoH/legenda CO3.png';
import EventoHCO4 from './legendas/EventoH/legenda CO4.png';

import EventoJAM1 from './legendas/EventoJ/legenda AM1.png';
import EventoJAM2 from './legendas/EventoJ/legenda AM2.png';
import EventoJAM3 from './legendas/EventoJ/legenda AM3.png';
import EventoJAM4 from './legendas/EventoJ/legenda AM4.png';
import EventoJAM5 from './legendas/EventoJ/legenda AM5.png';
import EventoJCO1 from './legendas/EventoJ/legenda CO1.png';

import EventoKAM1 from './legendas/EventoK/legenda AM1.png';
import EventoKAM2 from './legendas/EventoK/legenda AM2.png';
import EventoKAM3 from './legendas/EventoK/legenda AM3.png';
import EventoKCO1 from './legendas/EventoK/legenda CO1.png';
import EventoKCO2 from './legendas/EventoK/legenda CO2.png';
import EventoKCO3 from './legendas/EventoK/legenda CO3.png';

import Legend from './LEGENDA.png';
import BasicDocument from './pdfViewer';

export default function Diagrama1() {
  let [companyName, setCompanyName] = useState('');

  let [IsPDFReady, setIsPDFReady] = useState(false);
  let [chosenForm, setChosenForm] = useState([]);
  let [chosenAnswerForm, setChosenAnswerForm] = useState([]);
  let [titlesAndQuestionsSelected, setTitlesAndQuestionsSelected] = useState(
    []
  );
  let [IsFormSelected, setIsFormSelected] = useState(false);
  let [exportExcelData, setExportExcelData] = useState([]);
  let [exportAnswerFormExcel, setExportAnswerFormExcel] = useState([]);

  let [selectedEvent, setSelectedEvent] = useState(
    'Falta de Suprimento Respiratório'
  );

  let [selectedHazard, setSelectedHazard] = useState(
    titleHazardFaltaRespiratorio
  );
  /*Form Falta do Sistema Respiratorio*/
  let [listKeysQuestion, setListKeys] = useState(
    listKeysQuestionFaltaRespiratorio
  );

  /*Form Acidentes Hiperbaricos*/
  let [
    listKeysQuestionDiagramAcidentesHiperbaricos,
    setlistKeysQuestionDiagramAcidentesHiperbaricos,
  ] = useState(listKeysQuestionAcidentesHiperbaricos);

  /*Form Falha do Sistema Lançamento*/
  let [
    listKeysQuestionDiagramFalhaSistemaLancamento,
    setlistKeysQuestionDiagramFalhaSistemaLancamento,
  ] = useState(listKeysQuestionFalhaSistemaLancamento);

  /*Form Aprisionamento Mergulhador*/
  let [
    listKeysQuestionDiagramAprisionamentoMergulhadorForm,
    setlistKeysQuestionAprisionamentoMergulhador,
  ] = useState(listKeysQuestionAprisionamentoMergulhador);

  /*Form Exposição Inadequada Mergulhador*/

  let [
    listKeysQuestionDiagramExposicaoInadequadaMergulhadorForm,
    setlistKeysQuestionExposicaoInadequadaMergulhador,
  ] = useState(listKeysQuestionExposicaoInadequadaMergulhador);

  /*Form Incendio Explosao Camara*/

  let [
    listKeysQuestionDiagramIncendioExplosaoCamaraForm,
    setlistKeysQuestionIncendioExplosaoCamara,
  ] = useState(listKeysQuestionIncendioExplosaoCamara);

  /*Form Falha EquipamentoIndividual*/

  let [
    listKeysQuestionDiagramFalhaEquipamentoIndividualForm,
    setlistKeysQuestionFalhaEquipamentoIndividual,
  ] = useState(listKeysQuestionFalhaEquipamentoIndividual);

  /*Form Contato Acidental Mergulhador*/

  let [
    listKeysQuestionDiagramContatoAcidentalMergulhadorForm,
    setlistKeysQuestionContatoAcidentalMergulhador,
  ] = useState(listKeysQuestionContatoAcidentalMergulhador);

  /*Form Exposição Mergulhadores Contaminantes*/

  let [
    listKeysQuestionDiagramExposicaoMergulhadoresContaminantesForm,
    setlistKeysQuestionExposicaoMergulhadoresContaminantes,
  ] = useState(listKeysQuestionExposicaoMergulhadoresContaminantes);

  /*Form Exposição Descargas Eletricas*/

  let [
    listKeysQuestionDiagramExposicaoDescargasEletricasForm,
    setlistKeysQuestionExposicaoDescargasEletricas,
  ] = useState(listKeysQuestionExposicaoDescargasEletricas);

  /*Form Exposição Mergulhador Vida Marinha*/

  let [
    listKeysQuestionDiagramExposicaoMergulhadorVidaMarinhaForm,
    setlistKeysQuestionExposicaoMergulhadorVidaMarinha,
  ] = useState(listKeysQuestionExposicaoMergulhadorVidaMarinha);

  /*Form EmergenciasMedicasHiberbaricas*/

  let [
    listKeysQuestionDiagramEmergenciasMedicasHiberbaricasForm,
    setlistKeysQuestionEmergenciasMedicasHiberbaricas,
  ] = useState(listKeysQuestionEmergenciasMedicasHiberbaricas);

  let [DateUser, setDateUser] = useState('');
  let [InformationUser, setInformationUser] = useState({
    name: '',
    email: '',
    telefone: '',
    empresa: '',
  });

  let [ListDateAndForm, setListDateAndForm] = useState([]);

  let [SelectedEmpresa, setSelectedEmpresa] = useState(false);

  const GetFormAnswer = event => {
    event.preventDefault();

    if (event.target.value === 'Falta de Suprimento Respiratório') {
      setSelectedEvent('Falta de Suprimento Respiratório');
      setSelectedHazard(titleHazardFaltaRespiratorio);

      setListDateAndForm([]);
    } else if (event.target.value === 'Acidentes Hiperbaricos') {
      setSelectedEvent('Acidentes Hiperbaricos');
      setSelectedHazard(titleHazardAcidentesHiperbaricos);

      setListDateAndForm([]);
    } else if (
      event.target.value === 'Falha no Sistema de Lançamento e Recuperação'
    ) {
      setSelectedEvent('Falha no Sistema de Lançamento e Recuperação');
      setSelectedHazard(titleHazardFalhaSistemaLancamento);
      setListDateAndForm([]);
    } else if (
      event.target.value === 'Aprisionamento de Mergulhador no Fundo'
    ) {
      setSelectedEvent('Aprisionamento de Mergulhador no Fundo');
      setSelectedHazard(titleHazardAprisionamentoMergulhador);
      setListDateAndForm([]);
    } else if (
      event.target.value ===
      'Exposição Inadequada do Mergulhador na Interface de Sistemas Com Diferencial de Pressão'
    ) {
      setSelectedEvent(
        'Exposição Inadequada do Mergulhador na Interface de Sistemas Com Diferencial de Pressão'
      );
      setSelectedHazard(titleHazardExposicaoInadequadaMergulhador);
      setListDateAndForm([]);
    } else if (
      event.target.value ===
      'INCÊNDIO E/OU EXPLOSÃO EM UMA CÂMARA OU NAS PROXIMIDADES DO SISTEMA DE MERGULHO'
    ) {
      setSelectedEvent(
        'INCÊNDIO E/OU EXPLOSÃO EM UMA CÂMARA OU NAS PROXIMIDADES DO SISTEMA DE MERGULHO'
      );
      setSelectedHazard(titleHazardIncendioExplosaoCamara);
      setListDateAndForm([]);
    } else if (
      event.target.value === 'Falha do Equipamento Individual do Mergulhador'
    ) {
      setSelectedEvent('Falha do Equipamento Individual do Mergulhador');
      setSelectedHazard(titleHazardFalhaEquipamentoIndividual);
      setListDateAndForm([]);
    } else if (
      event.target.value ===
      'Contato Acidental do Mergulhador com os Propulsores'
    ) {
      setSelectedEvent('Contato Acidental do Mergulhador com os Propulsores');
      setSelectedHazard(titleHazardContatoAcidentalMergulhador);
      setListDateAndForm([]);
    } else if (
      event.target.value === 'Exposição dos Mergulhadores aos Contaminantes'
    ) {
      setSelectedEvent('Exposição dos Mergulhadores aos Contaminantes');
      setSelectedHazard(titleHazardExposicaoMergulhadoresContaminantes);
      setListDateAndForm([]);
    } else if (
      event.target.value ===
      'EXPOSIÇÃO A DESCARGAS ELÉTRICAS, IMPACTOS MECÂNICOS E FLUIDOS PRESSURIZADOS NO CORPO DO MERGULHADOR (SUBMERSOS/EMERSOS)'
    ) {
      setSelectedEvent(
        'EXPOSIÇÃO A DESCARGAS ELÉTRICAS, IMPACTOS MECÂNICOS E FLUIDOS PRESSURIZADOS NO CORPO DO MERGULHADOR (SUBMERSOS/EMERSOS)'
      );
      setSelectedHazard(titleHazardExposicaoDescargasEletricas);
      setListDateAndForm([]);
    } else if (
      event.target.value === 'Exposição do Mergulhador A Vida Marinha Nociva'
    ) {
      setSelectedEvent('Exposição do Mergulhador A Vida Marinha Nociva');
      setSelectedHazard(titleHazardExposicaoMergulhadorVidaMarinha);
      setListDateAndForm([]);
    } else if (
      event.target.value ===
      'Emergências não hiberbáricas (alteração clínica do mergulhador)'
    ) {
      setSelectedEvent(
        'Emergências não hiberbáricas (alteração clínica do mergulhador)'
      );
      setSelectedHazard(titleHazardEmergenciasMedicasHiberbaricas);
      setListDateAndForm([]);
    }
  };

  const handleChange = event => {
    setListDateAndForm([]);
    let Empresa = event.target.value;

    api
      .get('formchecklist', { params: { empresa: Empresa } })
      .then(res => {
        let data = res.data;

        let list = [];

        data.forEach(el => {
          if (selectedEvent === el.DataUser.evento) {
            list.push({
              Formulario: el.AnswerDiagram,
              AnswerForm: el.AnswerForm,
              AnswerQuestionForm: el.AnswerQuestionForm
                ? el.AnswerQuestionForm
                : [],
              Data: el.DataUser.data,
              name: el.DataUser.name,
              email: el.DataUser.email,
              telefone: el.DataUser.telefone,
              empresa: el.DataUser.empresa,
            });
          }
        });

        setListDateAndForm(list);

        setSelectedEmpresa(true);
      })
      .catch(error => {
        window.alert('Dados não encontrados');
        setSelectedEmpresa(false);
      });
  };

  const equalsCheck = (arrayOne, arrayTwo) => {
    return JSON.stringify(arrayOne) === JSON.stringify(arrayTwo);
  };

  const DiagramResult = event => {
    try {
      if (selectedEvent === 'Falta de Suprimento Respiratório') {
        let keys = Object.keys(
          ListDateAndForm[event.target.value].Formulario[0]
        );
        let keysFaltaSuprimento = Object.keys(
          listKeysQuestionFaltaRespiratorio[0]
        );

        if (!equalsCheck(keys, keysFaltaSuprimento)) {
          alert(
            'Não foi possivel extrair dados do Bowtie para esse formulário'
          );
        } else {
          setListKeys(ListDateAndForm[event.target.value].Formulario);
          chosenForm = ListDateAndForm[event.target.value].Formulario[0];
          setChosenForm(chosenForm);
          chosenAnswerForm = ListDateAndForm[event.target.value];
          setChosenAnswerForm(chosenAnswerForm);
          titlesAndQuestionsSelected = TitlesAndQuestionsFaltaRespiratorio;
          setTitlesAndQuestionsSelected(titlesAndQuestionsSelected);
          setIsFormSelected(true);
        }
      } else if (selectedEvent === 'Acidentes Hiperbaricos') {
        setlistKeysQuestionDiagramAcidentesHiperbaricos(
          ListDateAndForm[event.target.value].Formulario
        );
        chosenForm = ListDateAndForm[event.target.value].Formulario[0];
        setChosenForm(chosenForm);
        chosenAnswerForm = ListDateAndForm[event.target.value];
        setChosenAnswerForm(chosenAnswerForm);
        titlesAndQuestionsSelected = TitlesAndQuestionsAcidentesHiperbaricos;
        setTitlesAndQuestionsSelected(titlesAndQuestionsSelected);
        setIsFormSelected(true);
      } else if (
        selectedEvent === 'Falha no Sistema de Lançamento e Recuperação'
      ) {
        setlistKeysQuestionDiagramFalhaSistemaLancamento(
          ListDateAndForm[event.target.value].Formulario
        );
        chosenForm = ListDateAndForm[event.target.value].Formulario[0];
        setChosenForm(chosenForm);
        chosenAnswerForm = ListDateAndForm[event.target.value];
        setChosenAnswerForm(chosenAnswerForm);
        titlesAndQuestionsSelected = TitlesAndQuestionsFalhaSistemaLancamento;
        setTitlesAndQuestionsSelected(titlesAndQuestionsSelected);
        setIsFormSelected(true);
      } else if (selectedEvent === 'Aprisionamento de Mergulhador no Fundo') {
        setlistKeysQuestionAprisionamentoMergulhador(
          ListDateAndForm[event.target.value].Formulario
        );
        chosenForm = ListDateAndForm[event.target.value].Formulario[0];
        setChosenForm(chosenForm);
        chosenAnswerForm = ListDateAndForm[event.target.value];
        setChosenAnswerForm(chosenAnswerForm);
        titlesAndQuestionsSelected = TitlesAndQuestionsAprisionamentoMergulhador;
        setTitlesAndQuestionsSelected(titlesAndQuestionsSelected);
        setIsFormSelected(true);
      } else if (
        selectedEvent ===
        'Exposição Inadequada do Mergulhador na Interface de Sistemas Com Diferencial de Pressão'
      ) {
        setlistKeysQuestionExposicaoInadequadaMergulhador(
          ListDateAndForm[event.target.value].Formulario
        );
        chosenForm = ListDateAndForm[event.target.value].Formulario[0];
        setChosenForm(chosenForm);
        chosenAnswerForm = ListDateAndForm[event.target.value];
        setChosenAnswerForm(chosenAnswerForm);
        titlesAndQuestionsSelected = TitlesAndQuestionsExposicaoInadequadaMergulhador;
        setTitlesAndQuestionsSelected(titlesAndQuestionsSelected);
        setIsFormSelected(true);
      } else if (
        selectedEvent ===
        'INCÊNDIO E/OU EXPLOSÃO EM UMA CÂMARA OU NAS PROXIMIDADES DO SISTEMA DE MERGULHO'
      ) {
        setlistKeysQuestionIncendioExplosaoCamara(
          ListDateAndForm[event.target.value].Formulario
        );
        chosenForm = ListDateAndForm[event.target.value].Formulario[0];
        setChosenForm(chosenForm);
        chosenAnswerForm = ListDateAndForm[event.target.value];
        setChosenAnswerForm(chosenAnswerForm);
        titlesAndQuestionsSelected = TitlesAndQuestionsIncendioExplosaoCamara;
        setTitlesAndQuestionsSelected(titlesAndQuestionsSelected);
        setIsFormSelected(true);
      } else if (
        selectedEvent === 'Falha do Equipamento Individual do Mergulhador'
      ) {
        setlistKeysQuestionFalhaEquipamentoIndividual(
          ListDateAndForm[event.target.value].Formulario
        );
        chosenForm = ListDateAndForm[event.target.value].Formulario[0];
        setChosenForm(chosenForm);
        chosenAnswerForm = ListDateAndForm[event.target.value];
        setChosenAnswerForm(chosenAnswerForm);
        titlesAndQuestionsSelected = TitlesAndQuestionsFalhaEquipamentoIndividual;
        setTitlesAndQuestionsSelected(titlesAndQuestionsSelected);
        setIsFormSelected(true);
      } else if (
        selectedEvent === 'Contato Acidental do Mergulhador com os Propulsores'
      ) {
        setlistKeysQuestionContatoAcidentalMergulhador(
          ListDateAndForm[event.target.value].Formulario
        );
        chosenForm = ListDateAndForm[event.target.value].Formulario[0];
        setChosenForm(chosenForm);
        chosenAnswerForm = ListDateAndForm[event.target.value];
        setChosenAnswerForm(chosenAnswerForm);
        titlesAndQuestionsSelected = TitlesAndQuestionsContatoAcidentalMergulhador;
        setTitlesAndQuestionsSelected(titlesAndQuestionsSelected);
        setIsFormSelected(true);
      } else if (
        selectedEvent === 'Exposição dos Mergulhadores aos Contaminantes'
      ) {
        setlistKeysQuestionExposicaoMergulhadoresContaminantes(
          ListDateAndForm[event.target.value].Formulario
        );
        chosenForm = ListDateAndForm[event.target.value].Formulario[0];
        setChosenForm(chosenForm);
        chosenAnswerForm = ListDateAndForm[event.target.value];
        setChosenAnswerForm(chosenAnswerForm);
        titlesAndQuestionsSelected = TitlesAndQuestionsExposicaoMergulhadoresContaminantes;
        setTitlesAndQuestionsSelected(titlesAndQuestionsSelected);
        setIsFormSelected(true);
      } else if (
        selectedEvent ===
        'EXPOSIÇÃO A DESCARGAS ELÉTRICAS, IMPACTOS MECÂNICOS E FLUIDOS PRESSURIZADOS NO CORPO DO MERGULHADOR (SUBMERSOS/EMERSOS)'
      ) {
        setlistKeysQuestionExposicaoDescargasEletricas(
          ListDateAndForm[event.target.value].Formulario
        );
        chosenForm = ListDateAndForm[event.target.value].Formulario[0];
        setChosenForm(chosenForm);
        chosenAnswerForm = ListDateAndForm[event.target.value];
        setChosenAnswerForm(chosenAnswerForm);
        titlesAndQuestionsSelected = TitlesAndQuestionsExposicaoDescargasEletricas;
        setTitlesAndQuestionsSelected(titlesAndQuestionsSelected);
        setIsFormSelected(true);
      } else if (
        selectedEvent === 'Exposição do Mergulhador A Vida Marinha Nociva'
      ) {
        setlistKeysQuestionExposicaoMergulhadorVidaMarinha(
          ListDateAndForm[event.target.value].Formulario
        );
        chosenForm = ListDateAndForm[event.target.value].Formulario[0];
        setChosenForm(chosenForm);
        chosenAnswerForm = ListDateAndForm[event.target.value];
        setChosenAnswerForm(chosenAnswerForm);
        titlesAndQuestionsSelected = TitlesAndQuestionsExposicaoMergulhadorVidaMarinha;
        setTitlesAndQuestionsSelected(titlesAndQuestionsSelected);
        setIsFormSelected(true);
      } else if (
        selectedEvent ===
        'Emergências não hiberbáricas (alteração clínica do mergulhador)'
      ) {
        setlistKeysQuestionEmergenciasMedicasHiberbaricas(
          ListDateAndForm[event.target.value].Formulario
        );
        chosenForm = ListDateAndForm[event.target.value].Formulario[0];
        setChosenForm(chosenForm);
        chosenAnswerForm = ListDateAndForm[event.target.value];
        setChosenAnswerForm(chosenAnswerForm);
        titlesAndQuestionsSelected = TitlesAndQuestionsEmergenciasMedicasHiberbaricas;
        setTitlesAndQuestionsSelected(titlesAndQuestionsSelected);
        setIsFormSelected(true);
      }

      setDateUser(ListDateAndForm[event.target.value].Data);
      setInformationUser({
        name: ListDateAndForm[event.target.value].name,
        email: ListDateAndForm[event.target.value].email,
        telefone: ListDateAndForm[event.target.value].telefone,
        empresa: ListDateAndForm[event.target.value].empresa,
      });
    } catch (error) {
      alert('Não foi possivel extrair os dados desse formulário');
    }
  };

  const ExportExcel = async () => {
    try {
      let barriers = Object.keys(chosenForm);
      let AnswerForm = chosenAnswerForm.AnswerForm;
      let AnswerQuestionForm = chosenAnswerForm.AnswerQuestionForm;
      let excelData = [];
      let answerFormDataExcel = [];

      barriers.map(key => {
        chosenForm[key].map(element => {
          let group = Object.keys(element)[0];
          let answer = element[group].Answer;

          excelData.push({ group, answer });

          return 0;
        });
        return 0;
      });

      if (AnswerForm.length > 0 && AnswerQuestionForm.length > 0) {
        AnswerForm.map(answer => {
          let question = Object.keys(answer)[0];
          let userAnswer = answer[question];
          let questionComplete =
            AnswerQuestionForm.filter(
              questionForm => questionForm.tagQuestion === question
            ).length > 0
              ? AnswerQuestionForm.filter(
                  questionForm => questionForm.tagQuestion === question
                )[0].question
              : '';

          if (questionComplete === 'Pergunta não encontrada no modelo atual') {
            questionComplete = GetQuestionCheckList(question);
          }

          if (userAnswer === 'No' || userAnswer === '') {
            answerFormDataExcel.push({
              numero: question,
              pergunta: questionComplete,
              resposta:
                userAnswer === 'Yes'
                  ? 'Sim'
                  : userAnswer === 'No'
                  ? 'Não'
                  : 'Não Aplicável',
            });
          }

          return 0;
        });
        exportAnswerFormExcel = answerFormDataExcel;
        setExportAnswerFormExcel(exportAnswerFormExcel);
      } else {
        AnswerForm.map(answer => {
          let question = Object.keys(answer)[0];
          let userAnswer = answer[question];

          if (userAnswer === 'No' || userAnswer === '') {
            answerFormDataExcel.push({
              numero: question,
              pergunta: GetQuestionCheckList(question),
              resposta:
                userAnswer === 'Yes'
                  ? 'Sim'
                  : userAnswer === 'No'
                  ? 'Não'
                  : 'Não Aplicável',
            });
          }

          return 0;
        });
        exportAnswerFormExcel = answerFormDataExcel;
        setExportAnswerFormExcel(exportAnswerFormExcel);
      }

      let totalGreen = excelData.filter(element => element.answer === 'green')
        .length;
      let totalYellow = excelData.filter(element => element.answer === 'yellow')
        .length;
      let totalRed = excelData.filter(element => element.answer === 'red')
        .length;
      let totalWhite = excelData.filter(element => element.answer === 'white')
        .length;

      let dataReport = [];

      dataReport.push({
        'Total de Grupos': excelData.length,
        'Total de BARREIRA ÍNTEGRA': totalGreen,
        'Total de BARREIRA NAO IMPLEMENTADA': totalRed,
        'Total de BARREIRA DEGRADADA': totalYellow,
        'Total de NĀO APLICÁVEL': totalWhite,
        '% BARREIRA ÍNTEGRA':
          ((totalGreen / excelData.length) * 100).toFixed(1) + ' %',
        '% BARREIRA NAO IMPLEMENTADA':
          ((totalRed / excelData.length) * 100).toFixed(1) + ' %',
        '% BARREIRA DEGRADADA':
          ((totalYellow / excelData.length) * 100).toFixed(1) + ' %',
        '% NĀO APLICÁVEL':
          ((totalWhite / excelData.length) * 100).toFixed(1) + ' %',
      });

      exportExcelData = dataReport;
      setExportExcelData(exportExcelData);

      const worksheetReport = XLSX.utils.json_to_sheet(exportExcelData);
      const worksheetAnswerForm = XLSX.utils.json_to_sheet(
        exportAnswerFormExcel
      );
      const workbook = XLSX.utils.book_new();

      setIsPDFReady(true);

      XLSX.utils.book_append_sheet(
        workbook,
        worksheetReport,
        'Quantidade dos Grupos'
      );
      XLSX.utils.book_append_sheet(
        workbook,
        worksheetAnswerForm,
        'Resposta do CheckList'
      );

      XLSX.writeFile(workbook, 'Relatório.xlsx');
    } catch (error) {
      alert('Erro ao exportar o excel.');
    }
  };

  const GetQuestionCheckList = numberQuestionSelected => {
    try {
      let foremostBarrier = Object.keys(titlesAndQuestionsSelected);
      let selectedQuestion = 'Pergunta não encontrada no modelo atual';

      foremostBarrier.map(barrier => {
        let questionsBarrier = Object.keys(titlesAndQuestionsSelected[barrier]);

        questionsBarrier.map(numberQuestion => {
          let numberQuestionSplit = numberQuestion.split(' ');
          let newNumberQuestion = `${numberQuestionSplit[0]} ${numberQuestionSplit[1]}`;
          if (numberQuestionSelected === newNumberQuestion) {
            selectedQuestion =
              titlesAndQuestionsSelected[barrier][numberQuestion].Question;
          }
          return selectedQuestion;
        });

        return selectedQuestion;
      });

      return selectedQuestion;
    } catch {
      return 'Pergunta não encontrada';
    }
  };

  const GetCompanyName = () => {
    let name = localStorage.getItem('name');

    name ? setCompanyName(name) : setCompanyName('');
  };

  const RefreshPage = () => {
    window.location.reload();
  };

  useEffect(() => {
    GetCompanyName();
  }, []);

  return (
    <div>
      {IsPDFReady ? (
        <BasicDocument
          exportExcelData={exportExcelData}
          selectedEvent={selectedEvent}
          InformationUser={InformationUser}
          exportAnswerFormExcel={exportAnswerFormExcel}
          DateUser={DateUser}
        />
      ) : (
        ''
      )}

      <form id="form-embarcacao">
        <div class="d-flex">
          <select
            id="Select-Embarcacao"
            class="form-select"
            aria-label="Default select example"
            onChange={GetFormAnswer}
          >
            <option selected>Selecione o Evento</option>
            <option value="Acidentes Hiperbaricos">
              TOPO A - ACIDENTES HIPERBARICOS
            </option>
            <option value="Falta de Suprimento Respiratório">
              TOPO B - FALTA DE SUPRIMENTO RESPIRATÓRIO
            </option>
            <option value="Falha no Sistema de Lançamento e Recuperação">
              TOPO C - FALHA NO SISTEMA DE LANÇAMENTO E RECUPERAÇÃO
            </option>
            <option value="Aprisionamento de Mergulhador no Fundo">
              TOPO D - APRISIONAMENTO DE MERGULHADOR NO FUNDO
            </option>
            <option value="Exposição Inadequada do Mergulhador na Interface de Sistemas Com Diferencial de Pressão">
              TOPO E - EXPOSIÇÃO INADEQUADA DO MERGULHADOR NA INTERFACE DE
              SISTEMAS COM DIFERENCIAL DE PRESSÃO{' '}
            </option>
            <option value="INCÊNDIO E/OU EXPLOSÃO EM UMA CÂMARA OU NAS PROXIMIDADES DO SISTEMA DE MERGULHO">
              TOPO G - INCÊNDIO E/OU EXPLOSÃO EM UMA CÂMARA OU NAS PROXIMIDADES
              DO SISTEMA DE MERGULHO{' '}
            </option>
            <option value="Falha do Equipamento Individual do Mergulhador">
              TOPO H - FALHA DO EQUIPAMENTO INDIVIDUAL DO MERGULHADOR
            </option>
            <option value="Contato Acidental do Mergulhador com os Propulsores">
              TOPO J - CONTATO ACIDENTAL DO MERGULHADOR COM OS PROPULSORES{' '}
            </option>
            <option value="Exposição dos Mergulhadores aos Contaminantes">
              TOPO K - EXPOSIÇÃO DOS MERGULHADORES AOS CONTAMINANTES{' '}
            </option>
            <option value="EXPOSIÇÃO A DESCARGAS ELÉTRICAS, IMPACTOS MECÂNICOS E FLUIDOS PRESSURIZADOS NO CORPO DO MERGULHADOR (SUBMERSOS/EMERSOS)">
              TOPO L - EXPOSIÇÃO A DESCARGAS ELÉTRICAS, IMPACTOS MECÂNICOS E
              FLUIDOS PRESSURIZADOS NO CORPO DO MERGULHADOR (SUBMERSOS/EMERSOS){' '}
            </option>
            <option value="Exposição do Mergulhador A Vida Marinha Nociva">
              TOPO M - EXPOSIÇÃO DO MERGULHADOR A VIDA MARINHA NOCIVA{' '}
            </option>
            <option value="Emergências não hiberbáricas (alteração clínica do mergulhador)">
              TOPO N - EMERGÊNCIAS NÃO HIBERBÁRICAS (ALTERAÇÃO CLÍNICA DO
              MERGULHADOR){' '}
            </option>
          </select>

          {companyName === 'OCEANICAeyJSb2xlIjoiQWRtaW4iLCJJc' ? (
            <select
              id="Select-Embarcacao"
              class="form-select"
              aria-label="Default select example"
              onChange={handleChange}
            >
              <option selected>Selecione a Embarcação</option>
              <option value="Oceânica Sub IV">SDSV Oceânica Sub IV</option>
              <option value="Oceânica Sub V">SDSV Oceânica Sub V</option>
              <option value="Parcel de Manuel Luis">
                Parcel de Manuel Luis
              </option>
            </select>
          ) : companyName === 'SISTACCJJc3N1ZXIiOiJJc3N1Z' ? (
            <select
              id="Select-Embarcacao"
              class="form-select"
              aria-label="Default select example"
              onChange={handleChange}
            >
              <option selected>Selecione a Embarcação</option>
              <option value="Sistac P-18">Sistac P-18</option>
              <option value="Sistac P-26">Sistac P-26</option>
              <option value="Sistac P-52">Sistac P-52</option>
              <option value="Sistac P-48">Sistac P-48</option>
              <option value="Sistac Vitória">Sistac Vitória</option>
            </select>
          ) : companyName === 'BELOVVybmFtZSI6IkphdmFJblVzZ' ? (
            <select
              id="Select-Embarcacao"
              class="form-select"
              aria-label="Default select example"
              onChange={handleChange}
            >
              <option selected>Selecione a Embarcação</option>
              <option value="Asso 27">Asso 27 </option>
              <option value="Humaitá">Humaitá </option>
              <option value="Amaralina">Amaralina</option>
              <option value="Cidade de Ouro Preto">Cidade de Ouro Preto</option>
            </select>
          ) : companyName === 'CONTINENTALZSIsImV4cCI6MTY0OTIw' ? (
            <select
              id="Select-Embarcacao"
              class="form-select"
              aria-label="Default select example"
              onChange={handleChange}
            >
              <option selected>Selecione a Embarcação</option>
              <option value="Santos Scout">Santos Scout</option>
            </select>
          ) : (
            <select
              id="Select-Embarcacao"
              class="form-select"
              aria-label="Default select example"
              onChange={handleChange}
            >
              <option selected>Selecione a Embarcação</option>
              <option value="Oceânica Sub IV">SDSV Oceânica Sub IV</option>
              <option value="Oceânica Sub V">SDSV Oceânica Sub V</option>
              <option value="Parcel de Manuel Luis">
                Parcel de Manuel Luis
              </option>
              <option value="Sistac P-18">Sistac P-18</option>
              <option value="Sistac P-26">Sistac P-26</option>
              <option value="Sistac P-52">Sistac P-52</option>
              <option value="Sistac P-48">Sistac P-48</option>
              <option value="Sistac Vitória">Sistac Vitória</option>
              <option value="Asso 27">Asso 27 </option>
              <option value="Humaitá">SDSV Humaitá</option>
              <option value="Amaralina">SDSV Amaralina</option>
              <option value="Cidade de Ouro Preto">
                SDSV Cidade de Ouro Preto
              </option>
              <option value="Santos Scout">SDSV Santos Scout</option>
            </select>
          )}

          {SelectedEmpresa ? (
            <select
              id="Select-Embarcacao"
              class="form-select"
              aria-label="Default select example"
              onChange={DiagramResult}
            >
              <option selected>Selecione o Formulário</option>
              {ListDateAndForm.map((el, index) => {
                return (
                  <option value={index}>
                    Formulário {index} | Data:{el.Data}{' '}
                  </option>
                );
              })}
            </select>
          ) : (
            <select
              id="Select-Embarcacao"
              class="form-select"
              aria-label="Default select example"
            >
              <option selected>Selecione o Formulário</option>
            </select>
          )}

          <button type="button" class="btn btn-warning" onClick={RefreshPage}>
            Limpar
          </button>
          {IsFormSelected ? (
            <button
              type="button"
              style={{ marginLeft: 10 }}
              class="btn btn-success"
              onClick={ExportExcel}
            >
              Relatório
            </button>
          ) : (
            <></>
          )}
        </div>
      </form>

      <div id="name-user">
        <h3>Data:{DateUser}</h3>
        <h3>Nome:{InformationUser.name}</h3>
        <h3>Email:{InformationUser.email}</h3>
        <h3>Telefone:{InformationUser.telefone}</h3>
        <h3>Empresa:{InformationUser.empresa}</h3>
      </div>

      <div
        style={{
          display:
            selectedEvent === 'Falta de Suprimento Respiratório' ? '' : 'none ',
        }}
      >
        <div class="d-flex Block-Diagram">
          <div class="AM">
            <div class="d-flex" id="AM1">
              <p id="FirstBox1">AM1-Erro Humano</p>

              {listKeysQuestion[0]['AM 1-Erro Humano'].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box1 answer1"
                      id={Object.keys(key)[0]}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BP.' + Object.keys(key)[0]}
                    </div>
                  </div>
                );
              })}
              <Xarrow
                start="1-1"
                end="Event"
                startAnchor={'right'}
                endAnchor={'left'}
                showTail={true}
                showHead={false}
                color="#343194"
              />
            </div>

            <div class="d-flex" id="AM2">
              <p id="FirstBox2">
                AM2-Falha de equipamento de mergulho (compressor, quadro de
                cilindros, mangueira, capacete, bail out)
              </p>

              {listKeysQuestion[0][
                'AM2-Falha de equipamento de mergulho (compressor, quadro de cilindros, mangueira, capacete, bail out)'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box1 answer2"
                      id={Object.keys(key)[0]}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BP.' + Object.keys(key)[0]}
                    </div>
                  </div>
                );
              })}

              <Xarrow
                start="2-1"
                end="Event"
                startAnchor={'right'}
                endAnchor={'left'}
                showHead={false}
                showTail={true}
                color="#343194"
              />
            </div>

            <div class="d-flex" id="AM3">
              <p id="FirstBox3">
                AM3-Esmagamento, torção, tensão e ruptura do umbilical
              </p>
              {listKeysQuestion[0][
                'AM 3-Esmagamento, torção, tensão e ruptura do umbilical'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box1 answer3"
                      id={Object.keys(key)[0]}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BP.' + Object.keys(key)[0]}
                    </div>
                  </div>
                );
              })}
              <Xarrow
                start="3-1"
                end="Event"
                startAnchor={'right'}
                endAnchor={'left'}
                showHead={false}
                showTail={true}
                color="#343194"
              />
            </div>

            <div class="d-flex" id="AM4">
              <p id="FirstBox4">
                AM 4-Danos mecânicos (choque) à estrutura do capacete
                (sideblock, visor, estrutura, etc)
              </p>
              {listKeysQuestion[0][
                'AM 4-Danos mecânicos (choque) à estrutura do capacete (sideblock, visor, estrutura, etc)'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box1 answer4"
                      id={Object.keys(key)[0]}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BP.' + Object.keys(key)[0]}
                    </div>
                  </div>
                );
              })}
            </div>

            <Xarrow
              start="4-1"
              end="Event"
              startAnchor={'right'}
              endAnchor={'left'}
              showHead={false}
              showTail={true}
              color="#343194"
            />

            <div class="d-flex" id="AM5">
              <p id="FirstBox5">
                AM 5- Falha no sistema de captação e bombeio de água do mar
              </p>
              {listKeysQuestion[0][
                'AM 5- Falha no sistema de captação e bombeio de água do mar'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box1 answer5"
                      id={Object.keys(key)[0]}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BP.' + Object.keys(key)[0]}
                    </div>
                  </div>
                );
              })}
            </div>

            <div class="d-flex" id="AM6">
              <p id="FirstBox6">
                AM 6-Falha no sistema de aquecimento(Mergulho Profundo)
              </p>
              {listKeysQuestion[0][
                'AM 6-Falha no sistema de aquecimento(Mergulho Profundo)'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box1 answer6"
                      id={Object.keys(key)[0]}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BP.' + Object.keys(key)[0]}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div id="Event-Block">
            <div id="Hazard">
              <p id="Title-Hazard">{selectedHazard}</p>
            </div>
            <Xarrow start="Hazard" end="Event" color="black" />
            <div id="Event">{selectedEvent}</div>
          </div>

          <div class="CO">
            <div class="d-flex">
              {listKeysQuestion[0]['CO 1-Doença descompressiva'].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box2"
                      id={Object.keys(key)[0]}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BM.' + Object.keys(key)[0].replace('7-', '1-')}
                    </div>
                  </div>
                );
              })}
              <p id="SecondBox1">CO 1 Doença Descompressiva</p>

              <Xarrow
                start="7-1"
                end="Event"
                color="#b8291a"
                showTail={true}
                showHead={false}
                endAnchor={'right'}
              />
            </div>
            <div class="d-flex">
              {listKeysQuestion[0]['CO 2-Afogamento'].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box2"
                      id={Object.keys(key)[0]}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BM.' + Object.keys(key)[0].replace('8-', '2-')}
                    </div>
                  </div>
                );
              })}
              <p id="SecondBox2">CO 2-Afogamento</p>

              <Xarrow
                start="8-1"
                end="Event"
                color="#b8291a"
                showTail={true}
                showHead={false}
                endAnchor={'right'}
              />
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          display: selectedEvent === 'Acidentes Hiperbaricos' ? '' : 'none',
        }}
      >
        <div class="d-flex Block-Diagram">
          <div class="AM">
            <div class="d-flex" id="AM1Form2">
              <p id="FirstBox1">
                AM 1- Variações de pressão não programadas (subida ou descida
                não programada)
              </p>

              {listKeysQuestionDiagramAcidentesHiperbaricos[0][
                'AM 1- Variações de pressão não programadas (subida ou descida não programada)'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box1 answer1Form2"
                      id={Object.keys(key)[0] + 'Form2'}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BP.' + Object.keys(key)[0]}
                    </div>
                  </div>
                );
              })}
              <Xarrow
                start="1-1Form2"
                end="EventForm2"
                startAnchor={'right'}
                endAnchor={'left'}
                showTail={true}
                showHead={false}
                color="#343194"
              />
            </div>

            <div class="d-flex" id="AM2Form2">
              <p id="FirstBox2">
                AM2-Falha na aplicação da tabela de descompressão
              </p>

              {listKeysQuestionDiagramAcidentesHiperbaricos[0][
                'AM2-Falha na aplicação da tabela de descompressão'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box1 answer2Form2"
                      id={Object.keys(key)[0] + 'Form2'}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BP.' + Object.keys(key)[0]}
                    </div>
                  </div>
                );
              })}

              <Xarrow
                start="2-1Form2"
                end="EventForm2"
                startAnchor={'right'}
                endAnchor={'left'}
                showHead={false}
                showTail={true}
                color="#343194"
              />
            </div>

            <div class="d-flex" id="AM3Form2">
              <p id="FirstBox3">AM 3-Falha na operação da câmara hiperbárica</p>
              {listKeysQuestionDiagramAcidentesHiperbaricos[0][
                'AM 3-Falha na operação da câmara hiperbárica'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box1 answer3Form2"
                      id={Object.keys(key)[0] + 'Form2'}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BP.' + Object.keys(key)[0]}
                    </div>
                  </div>
                );
              })}
              <Xarrow
                start="3-1Form2"
                end="EventForm2"
                startAnchor={'right'}
                endAnchor={'left'}
                showHead={false}
                showTail={true}
                color="#343194"
              />
            </div>

            <div class="d-flex" id="AM4Form2">
              <p id="FirstBox4">
                AM 4-Efeitos da pressão no organismo do mergulhador
              </p>
              {listKeysQuestionDiagramAcidentesHiperbaricos[0][
                'AM 4-Efeitos da pressão no organismo do mergulhador'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box1 answer4Form2"
                      id={Object.keys(key)[0] + 'Form2'}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BP.' + Object.keys(key)[0]}
                    </div>
                  </div>
                );
              })}
              <Xarrow
                start="4-1Form2"
                end="EventForm2"
                startAnchor={'right'}
                endAnchor={'left'}
                showHead={false}
                showTail={true}
                color="#343194"
              />
            </div>

            <div class="d-flex" id="AM5Form2">
              <p id="FirstBox4">
                AM 5-Suscetibilidade individual do mergulhador
              </p>
              {listKeysQuestionDiagramAcidentesHiperbaricos[0][
                'AM 5-Suscetibilidade individual do mergulhador'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box1 answer5Form2"
                      id={Object.keys(key)[0] + 'Form2'}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BP.' + Object.keys(key)[0]}
                    </div>
                  </div>
                );
              })}

              <Xarrow
                start="5-1Form2"
                end="EventForm2"
                startAnchor={'right'}
                endAnchor={'left'}
                showHead={false}
                showTail={true}
                color="#343194"
              />
            </div>

            <div class="d-flex" id="AM6Form2">
              <p id="FirstBox4">
                AM 6-Não cumprimento das restrições pós mergulho
              </p>
              {listKeysQuestionDiagramAcidentesHiperbaricos[0][
                'AM 6-Não cumprimento das restrições pós mergulho'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box1 answer6Form2"
                      id={Object.keys(key)[0] + 'Form2'}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BP.' + Object.keys(key)[0]}
                    </div>
                  </div>
                );
              })}

              <Xarrow
                start="6-1Form2"
                end="EventForm2"
                startAnchor={'right'}
                endAnchor={'left'}
                showHead={false}
                showTail={true}
                color="#343194"
              />
            </div>

            <div class="d-flex" id="AM7Form2">
              <p id="FirstBox4">AM 7-Subida involuntária do mergulhador</p>
              {listKeysQuestionDiagramAcidentesHiperbaricos[0][
                'AM 7-Subida involuntária do mergulhador'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box1 answer7Form2"
                      id={Object.keys(key)[0] + 'Form2'}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BP.' + Object.keys(key)[0]}
                    </div>
                  </div>
                );
              })}

              <Xarrow
                start="7-1Form2"
                end="EventForm2"
                startAnchor={'right'}
                endAnchor={'left'}
                showHead={false}
                showTail={true}
                color="#343194"
              />
            </div>

            <div class="d-flex" id="AM8Form2">
              <p id="FirstBox4">
                AM 8-Variação significativa de temperatura não programadas
                (aplicável somente para mergulho saturado)
              </p>
              {listKeysQuestionDiagramAcidentesHiperbaricos[0][
                'AM 8-Variação significativa de temperatura não programadas (aplicável somente para mergulho saturado)'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box1 answer8Form2"
                      id={Object.keys(key)[0] + 'Form2'}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BP.' + Object.keys(key)[0]}
                    </div>
                  </div>
                );
              })}

              <Xarrow
                start="8-1Form2"
                end="EventForm2"
                startAnchor={'right'}
                endAnchor={'left'}
                showHead={false}
                showTail={true}
                color="#343194"
              />
            </div>

            <div class="d-flex" id="AM9Form2">
              <p id="FirstBox4">AM 9-Intoxicação por oxigênio</p>
              {listKeysQuestionDiagramAcidentesHiperbaricos[0][
                'AM 9-Intoxicação por oxigênio'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box1 answer9Form2"
                      id={Object.keys(key)[0] + 'Form2'}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BP.' + Object.keys(key)[0]}
                    </div>
                  </div>
                );
              })}

              <Xarrow
                start="9-1Form2"
                end="EventForm2"
                startAnchor={'right'}
                endAnchor={'left'}
                showHead={false}
                showTail={true}
                color="#343194"
              />
            </div>
          </div>

          <div id="Event-Block">
            <div id="HazardForm2">
              <p id="Title-Hazard">{selectedHazard}</p>
            </div>
            <Xarrow start="HazardForm2" end="EventForm2" color="black" />
            <div id="EventForm2">{selectedEvent}</div>
          </div>

          <div class="CO COForm2">
            <div class="d-flex">
              {listKeysQuestionDiagramAcidentesHiperbaricos[0][
                'CO 1- Lesão ou alteração funcional'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box2 answer-box2Form2"
                      id={Object.keys(key)[0] + 'Form2'}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BM.' + Object.keys(key)[0].replace('10', '1')}
                    </div>
                  </div>
                );
              })}
              <p id="SecondBox1" class="SecondBox1Form2">
                CO 1- Lesão ou alteração funcional
              </p>

              <Xarrow
                start="10-1Form2"
                end="EventForm2"
                color="#b8291a"
                showTail={true}
                showHead={false}
                endAnchor={'right'}
              />
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          display:
            selectedEvent === 'Falha no Sistema de Lançamento e Recuperação'
              ? ''
              : 'none ',
        }}
      >
        <div class="d-flex Block-Diagram">
          <div class="AM">
            <div class="d-flex" id="AM1Form3">
              <p id="FirstBox1">
                AM 1- Falha mecânica no sistema de lançamento (ex: sistema de
                acoplamento, guinchos, cabos, guideframe, roldanas, orings)
              </p>

              {listKeysQuestionDiagramFalhaSistemaLancamento[0][
                'AM 1- Falha mecânica no sistema de lançamento (ex: sistema de acoplamento, guinchos, cabos, guideframe, roldanas, orings)'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box1 answer1Form3"
                      id={Object.keys(key)[0] + 'Form3'}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BP.' + Object.keys(key)[0]}
                    </div>
                  </div>
                );
              })}
              <Xarrow
                start="1-1Form3"
                end="EventForm3"
                startAnchor={'right'}
                endAnchor={'left'}
                showTail={true}
                showHead={false}
                color="#343194"
              />
            </div>

            <div class="d-flex" id="AM2Form3">
              <p id="FirstBox2">
                AM2-Falha do suprimento de alimentação: elétrica, hidráulica e
                pneumática
              </p>

              {listKeysQuestionDiagramFalhaSistemaLancamento[0][
                'AM2-Falha do suprimento de alimentação: elétrica, hidráulica e pneumática'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box1 answer2Form3"
                      id={Object.keys(key)[0] + 'Form3'}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BP.' + Object.keys(key)[0]}
                    </div>
                  </div>
                );
              })}

              <Xarrow
                start="2-1Form3"
                end="EventForm3"
                startAnchor={'right'}
                endAnchor={'left'}
                showHead={false}
                showTail={true}
                color="#343194"
              />
            </div>

            <div class="d-flex" id="AM3Form3">
              <p id="FirstBox3">AM 3-Falha de projeto</p>
              {listKeysQuestionDiagramFalhaSistemaLancamento[0][
                'AM 3-Falha de projeto'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box1 answer3Form3"
                      id={Object.keys(key)[0] + 'Form3'}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BP.' + Object.keys(key)[0]}
                    </div>
                  </div>
                );
              })}
              <Xarrow
                start="3-1Form3"
                end="EventForm3"
                startAnchor={'right'}
                endAnchor={'left'}
                showHead={false}
                showTail={true}
                color="#343194"
              />
            </div>

            <div class="d-flex" id="AM4Form3">
              <p id="FirstBox4">
                AM 4-Erro operacional no lançamento / recuperação
              </p>
              {listKeysQuestionDiagramFalhaSistemaLancamento[0][
                'AM 4-Erro operacional no lançamento / recuperação'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box1 answer4Form3"
                      id={Object.keys(key)[0] + 'Form3'}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BP.' + Object.keys(key)[0]}
                    </div>
                  </div>
                );
              })}
            </div>

            <Xarrow
              start="4-1Form3"
              end="EventForm3"
              startAnchor={'right'}
              endAnchor={'left'}
              showHead={false}
              showTail={true}
              color="#343194"
            />
          </div>

          <div id="Event-Block">
            <div id="HazardForm3">
              <p id="Title-Hazard">{selectedHazard}</p>
            </div>
            <Xarrow start="HazardForm3" end="EventForm3" color="black" />
            <div id="EventForm3">{selectedEvent}</div>
          </div>

          <div class="CO">
            <div class="d-flex">
              {listKeysQuestionDiagramFalhaSistemaLancamento[0][
                'CO 1- Descida involuntária e queda de mergulhador ao mar, com lesões e alterações funcionais'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box2"
                      id={Object.keys(key)[0] + 'Form3'}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BM.' + Object.keys(key)[0].replace('5-', '1-')}
                    </div>
                  </div>
                );
              })}
              <p id="SecondBox1">
                CO 1- Descida involuntária e queda de mergulhador ao mar, com
                lesões e alterações funcionais
              </p>

              <Xarrow
                start="5-1Form3"
                end="EventForm3"
                color="#b8291a"
                showTail={true}
                showHead={false}
                endAnchor={'right'}
              />
            </div>
            <div class="d-flex">
              {listKeysQuestionDiagramFalhaSistemaLancamento[0][
                'CO 2-Ruptura do cabo e queda de mergulhador ao mar, com lesões e alterações funcionais (FUEPS)'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box2"
                      id={Object.keys(key)[0] + 'Form3'}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BM.' + Object.keys(key)[0].replace('6-', '2-')}
                    </div>
                  </div>
                );
              })}
              <p id="SecondBox2">
                CO 2-Ruptura do cabo e queda de mergulhador ao mar, com lesões e
                alterações funcionais (FUEPS)
              </p>

              <Xarrow
                start="6-1Form3"
                end="EventForm3"
                color="#b8291a"
                showTail={true}
                showHead={false}
                endAnchor={'right'}
              />
            </div>
            <div class="d-flex">
              {listKeysQuestionDiagramFalhaSistemaLancamento[0][
                'CO 3-Travamento do Sistema (FUEP)'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box2"
                      id={Object.keys(key)[0] + 'Form3'}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BM.' + Object.keys(key)[0].replace('7-', '3-')}
                    </div>
                  </div>
                );
              })}
              <p id="SecondBox3">CO 3-Travamento do Sistema (FUEP)</p>

              <Xarrow
                start="7-1Form3"
                end="EventForm3"
                color="#b8291a"
                showTail={true}
                showHead={false}
                endAnchor={'right'}
              />
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          display:
            selectedEvent === 'Aprisionamento de Mergulhador no Fundo'
              ? ''
              : 'none ',
        }}
      >
        <div class="d-flex Block-Diagram">
          <div class="AM">
            <div class="d-flex" id="AM1Form4">
              <p id="FirstBox1">AM.1 - Umbilical preso em obstáculo</p>

              {listKeysQuestionDiagramAprisionamentoMergulhadorForm[0][
                'AM-1 - Umbilical preso em obstáculo'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box1 answer1Form4"
                      id={Object.keys(key)[0] + 'Form4'}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BP.' + Object.keys(key)[0]}
                    </div>
                  </div>
                );
              })}
              <Xarrow
                start="1-2Form4"
                end="EventForm4"
                startAnchor={'right'}
                endAnchor={'left'}
                showTail={true}
                showHead={false}
                color="#343194"
              />
            </div>

            <div class="d-flex" id="AM2Form4">
              <p id="FirstBox2">AM.2 - Carga suspensa sobre área de mergulho</p>

              {listKeysQuestionDiagramAprisionamentoMergulhadorForm[0][
                'AM-2 - Carga suspensa sobre área de mergulho'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box1 answer2Form4"
                      id={Object.keys(key)[0] + 'Form4'}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BP.' + Object.keys(key)[0]}
                    </div>
                  </div>
                );
              })}

              <Xarrow
                start="2-1Form4"
                end="EventForm4"
                startAnchor={'right'}
                endAnchor={'left'}
                showHead={false}
                showTail={true}
                color="#343194"
              />
            </div>

            <div class="d-flex" id="AM3Form4">
              <p id="FirstBox3">
                AM.3 - Alteração de aproamento (heading) da unidade (FPSO que
                sejam turret)
              </p>
              {listKeysQuestionDiagramAprisionamentoMergulhadorForm[0][
                'AM-3 - Alteração de aproamento (heading) da unidade (FPSO que sejam turret)'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box1 answer3Form4"
                      id={Object.keys(key)[0] + 'Form4'}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BP.' + Object.keys(key)[0]}
                    </div>
                  </div>
                );
              })}
              <Xarrow
                start="3-1Form4"
                end="EventForm4"
                startAnchor={'right'}
                endAnchor={'left'}
                showHead={false}
                showTail={true}
                color="#343194"
              />
            </div>

            <div class="d-flex" id="AM4Form4">
              <p id="FirstBox4">AM.4 - Presença de linha e rede de pesca</p>
              {listKeysQuestionDiagramAprisionamentoMergulhadorForm[0][
                'AM-4 - Presença de linha e rede de pesca'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box1 answer4Form4"
                      id={Object.keys(key)[0] + 'Form4'}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BP.' + Object.keys(key)[0]}
                    </div>
                  </div>
                );
              })}
            </div>

            <Xarrow
              start="4-1Form4"
              end="EventForm4"
              startAnchor={'right'}
              endAnchor={'left'}
              showHead={false}
              showTail={true}
              color="#343194"
            />

            <div class="d-flex" id="AM5Form4">
              <p id="FirstBox5">
                AM.5 – Deslocamento inadvertido da embarcação durante as
                operações de mergulho.
              </p>
              {listKeysQuestionDiagramAprisionamentoMergulhadorForm[0][
                'AM-5 – Deslocamento inadvertido da embarcação durante as operações de mergulho'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box1 answer5Form4"
                      id={Object.keys(key)[0] + 'Form4'}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BP.' + Object.keys(key)[0]}
                    </div>
                  </div>
                );
              })}
            </div>

            <Xarrow
              start="5-1Form4"
              end="EventForm4"
              startAnchor={'right'}
              endAnchor={'left'}
              showHead={false}
              showTail={true}
              color="#343194"
            />

            <div class="d-flex" id="AM6Form4">
              <p id="FirstBox6">
                AM.6 -Erro operacional no isolamento da caixa de mar / captação
              </p>
              {listKeysQuestionDiagramAprisionamentoMergulhadorForm[0][
                'AM-6 -Erro operacional no isolamento da caixa de mar / captação'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box1 answer6Form4"
                      id={Object.keys(key)[0] + 'Form4'}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BP.' + Object.keys(key)[0]}
                    </div>
                  </div>
                );
              })}
            </div>

            <Xarrow
              start="6-1Form4"
              end="EventForm4"
              startAnchor={'right'}
              endAnchor={'left'}
              showHead={false}
              showTail={true}
              color="#343194"
            />

            <div class="d-flex" id="AM7Form4">
              <p id="FirstBox7">
                AM.7 - Falha no isolamento de linhas de vácuo (pressão negativa)
                / pressurizadas (pressão positiva) - ex. dutos, poços e
                acessórios overboard
              </p>
              {listKeysQuestionDiagramAprisionamentoMergulhadorForm[0][
                'AM-7 - Falha no isolamento de linhas de vácuo (pressão negativa) / pressurizadas (pressão positiva) - ex dutos, poços e acessórios overboard'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box1 answer7Form4"
                      id={Object.keys(key)[0] + 'Form4'}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BP.' + Object.keys(key)[0]}
                    </div>
                  </div>
                );
              })}
            </div>

            <Xarrow
              start="7-1Form4"
              end="EventForm4"
              startAnchor={'right'}
              endAnchor={'left'}
              showHead={false}
              showTail={true}
              color="#343194"
            />
          </div>

          <div id="Event-Block">
            <div id="HazardForm4">
              <p id="Title-Hazard">{selectedHazard}</p>
            </div>
            <Xarrow start="HazardForm4" end="EventForm4" color="black" />
            <div id="EventForm4">{selectedEvent}</div>
          </div>

          <div class="CO">
            <div class="d-flex">
              {listKeysQuestionDiagramAprisionamentoMergulhadorForm[0][
                'CO-1 - Lesão ou alteração funcional'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box2 answer-box2Form4"
                      id={Object.keys(key)[0] + 'Form4'}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BM.' + Object.keys(key)[0].replace('8-', '1-')}
                    </div>
                  </div>
                );
              })}
              <p id="SecondBox1" class="SecondBox1Form4">
                CO.1 - Lesão ou alteração funcional
              </p>

              <Xarrow
                start="8-1Form4"
                end="EventForm4"
                color="#b8291a"
                showTail={true}
                showHead={false}
                endAnchor={'right'}
              />
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          display:
            selectedEvent ===
            'Exposição Inadequada do Mergulhador na Interface de Sistemas Com Diferencial de Pressão'
              ? ''
              : 'none ',
        }}
      >
        <div class="d-flex Block-Diagram">
          <div class="AM">
            <div class="d-flex" id="AM1Form5">
              <p id="FirstBox1">
                AM-1-Erro humano (Falha do procedimento de mergulho)
              </p>

              {listKeysQuestionDiagramExposicaoInadequadaMergulhadorForm[0][
                'AM-1-Erro humano (Falha do procedimento de mergulho)'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box1 answer1Form5"
                      id={Object.keys(key)[0] + 'Form5'}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BP.' + Object.keys(key)[0]}
                    </div>
                  </div>
                );
              })}
              <Xarrow
                start="1-1Form5"
                end="EventForm5"
                startAnchor={'right'}
                endAnchor={'left'}
                showTail={true}
                showHead={false}
                color="#343194"
              />
            </div>

            <div class="d-flex" id="AM2Form5">
              <p id="FirstBox2">
                AM-2-Falha no isolamento de linhas de vácuo (pressão negativa) /
                pressurizadas (pressão positiva) - ex- dutos, poços e acessórios
                overboard
              </p>

              {listKeysQuestionDiagramExposicaoInadequadaMergulhadorForm[0][
                'AM-2-Falha no isolamento de linhas de vácuo (pressão negativa) / pressurizadas (pressão positiva) - ex- dutos, poços e acessórios overboard'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box1 answer2Form5"
                      id={Object.keys(key)[0] + 'Form5'}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BP.' + Object.keys(key)[0]}
                    </div>
                  </div>
                );
              })}

              <Xarrow
                start="2-1Form5"
                end="EventForm5"
                startAnchor={'right'}
                endAnchor={'left'}
                showHead={false}
                showTail={true}
                color="#343194"
              />
            </div>

            <div class="d-flex" id="AM4Form5">
              <p id="FirstBox4">
                AM-4-Mudança abrupta de diferencial de pressão entre
                compartimentos hiperbáricos
              </p>
              {listKeysQuestionDiagramExposicaoInadequadaMergulhadorForm[0][
                'AM-4-Mudança abrupta de diferencial de pressão entre compartimentos hiperbáricos'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box1 answer4Form5"
                      id={Object.keys(key)[0] + 'Form5'}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BP.' + Object.keys(key)[0]}
                    </div>
                  </div>
                );
              })}
            </div>

            <Xarrow
              start="4-1Form5"
              end="EventForm5"
              startAnchor={'right'}
              endAnchor={'left'}
              showHead={false}
              showTail={true}
              color="#343194"
            />
          </div>

          <div id="Event-Block">
            <div id="HazardForm5">
              <p id="Title-Hazard">{selectedHazard}</p>
            </div>
            <Xarrow start="HazardForm5" end="EventForm5" color="black" />
            <div id="EventForm5">{selectedEvent}</div>
          </div>

          <div class="CO">
            <div class="d-flex">
              {listKeysQuestionDiagramExposicaoInadequadaMergulhadorForm[0][
                'CO-1- Lesão ou alteração funcional temporária'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box2"
                      id={Object.keys(key)[0] + 'Form5'}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BM.' + Object.keys(key)[0].replace('5-', '1-')}
                    </div>
                  </div>
                );
              })}
              <p id="SecondBox1">
                CO-1- Lesão ou alteração funcional temporária
              </p>

              <Xarrow
                start="5-1Form5"
                end="EventForm5"
                color="#b8291a"
                showTail={true}
                showHead={false}
                endAnchor={'right'}
              />
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          display:
            selectedEvent ===
            'INCÊNDIO E/OU EXPLOSÃO EM UMA CÂMARA OU NAS PROXIMIDADES DO SISTEMA DE MERGULHO'
              ? ''
              : 'none ',
        }}
      >
        <div class="d-flex Block-Diagram">
          <div class="AM">
            <div class="d-flex" id="AM1Form6">
              <p id="FirstBox1">
                AM-1 - Elevado percentual de O2 na câmara (25%)
              </p>

              {listKeysQuestionDiagramIncendioExplosaoCamaraForm[0][
                'AM-1 - Elevado percentual de O2 na câmara (>25%)'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box1 answer1Form6"
                      id={Object.keys(key)[0] + 'Form6'}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BP.' + Object.keys(key)[0]}
                    </div>
                  </div>
                );
              })}
              <Xarrow
                start="1-1Form6"
                end="EventForm6"
                startAnchor={'right'}
                endAnchor={'left'}
                showTail={true}
                showHead={false}
                color="#343194"
              />
            </div>

            <div class="d-flex" id="AM2Form6">
              <p id="FirstBox2">
                AM-2 – Materiais ou substâncias inflamáveis dentro da câmara
                hiperbárica
              </p>

              {listKeysQuestionDiagramIncendioExplosaoCamaraForm[0][
                'AM-2 – Materiais ou substâncias inflamáveis dentro da câmara hiperbárica'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box1 answer2Form6"
                      id={Object.keys(key)[0] + 'Form6'}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BP.' + Object.keys(key)[0]}
                    </div>
                  </div>
                );
              })}

              <Xarrow
                start="2-1Form6"
                end="EventForm6"
                startAnchor={'right'}
                endAnchor={'left'}
                showHead={false}
                showTail={true}
                color="#343194"
              />
            </div>

            <div class="d-flex" id="AM3Form6">
              <p id="FirstBox3">
                AM-3 - Energia elétrica estática dentro da câmara hiperbárica
              </p>
              {listKeysQuestionDiagramIncendioExplosaoCamaraForm[0][
                'AM-3 - Energia elétrica estática dentro da câmara hiperbárica'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box1 answer3Form6"
                      id={Object.keys(key)[0] + 'Form6'}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BP.' + Object.keys(key)[0]}
                    </div>
                  </div>
                );
              })}
              <Xarrow
                start="3-1Form6"
                end="EventForm6"
                startAnchor={'right'}
                endAnchor={'left'}
                showHead={false}
                showTail={true}
                color="#343194"
              />
            </div>

            <div class="d-flex" id="AM4Form6">
              <p id="FirstBox4">AM-4-Incêndio/explosão de origem externa</p>
              {listKeysQuestionDiagramIncendioExplosaoCamaraForm[0][
                'AM-4-Incêndio/explosão de origem externa'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box1 answer4Form6"
                      id={Object.keys(key)[0] + 'Form6'}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BP.' + Object.keys(key)[0]}
                    </div>
                  </div>
                );
              })}
            </div>

            <Xarrow
              start="4-1Form6"
              end="EventForm6"
              startAnchor={'right'}
              endAnchor={'left'}
              showHead={false}
              showTail={true}
              color="#343194"
            />

            <div class="d-flex" id="AM5Form6">
              <p id="FirstBox5">AM-5-Vazamento de Cilindro de O2</p>
              {listKeysQuestionDiagramIncendioExplosaoCamaraForm[0][
                'AM-5-Vazamento de Cilindro de O2'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box1 answer5Form6"
                      id={Object.keys(key)[0] + 'Form6'}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BP.' + Object.keys(key)[0]}
                    </div>
                  </div>
                );
              })}
            </div>

            <Xarrow
              start="5-1Form6"
              end="EventForm6"
              startAnchor={'right'}
              endAnchor={'left'}
              showHead={false}
              showTail={true}
              color="#343194"
            />

            <div class="d-flex" id="AM6Form6">
              <p id="FirstBox6">AM-6-Vazamento de tanque de volume</p>
              {listKeysQuestionDiagramIncendioExplosaoCamaraForm[0][
                'AM-6-Vazamento de tanque de volume'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box1 answer6Form6"
                      id={Object.keys(key)[0] + 'Form6'}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BP.' + Object.keys(key)[0]}
                    </div>
                  </div>
                );
              })}
            </div>

            <Xarrow
              start="6-1Form6"
              end="EventForm6"
              startAnchor={'right'}
              endAnchor={'left'}
              showHead={false}
              showTail={true}
              color="#343194"
            />
          </div>

          <div id="Event-Block">
            <div id="HazardForm6">
              <p id="Title-Hazard">{selectedHazard}</p>
            </div>
            <Xarrow start="HazardForm6" end="EventForm6" color="black" />
            <div id="EventForm6">{selectedEvent}</div>
          </div>

          <div class="CO">
            <div class="d-flex">
              {listKeysQuestionDiagramIncendioExplosaoCamaraForm[0][
                'CO-1-Intoxicação com lesão, alteração funcional e potencial de fatalidade'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box2"
                      id={Object.keys(key)[0] + 'Form6'}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BM.' + Object.keys(key)[0].replace('7-', '1-')}
                    </div>
                  </div>
                );
              })}
              <p id="SecondBox1">
                CO-1-Intoxicação com lesão, alteração funcional e potencial de
                fatalidade
              </p>

              <Xarrow
                start="7-1Form6"
                end="EventForm6"
                color="#b8291a"
                showTail={true}
                showHead={false}
                endAnchor={'right'}
              />
            </div>
            <div class="d-flex">
              {listKeysQuestionDiagramIncendioExplosaoCamaraForm[0][
                'CO-2-Queimadura com lesão, alteração funcional, potencial de fatalidade e dano ao patrimônio'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box2"
                      id={Object.keys(key)[0] + 'Form6'}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BM.' + Object.keys(key)[0].replace('8-', '2-')}
                    </div>
                  </div>
                );
              })}
              <p id="SecondBox2">
                CO-2-Queimadura com lesão, alteração funcional, potencial de
                fatalidade e dano ao patrimônio
              </p>

              <Xarrow
                start="8-1Form6"
                end="EventForm6"
                color="#b8291a"
                showTail={true}
                showHead={false}
                endAnchor={'right'}
              />
            </div>
            <div class="d-flex">
              {listKeysQuestionDiagramIncendioExplosaoCamaraForm[0][
                'CO-3-Doença descompressiva'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box2"
                      id={Object.keys(key)[0] + 'Form6'}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BM.' + Object.keys(key)[0].replace('9-', '3-')}
                    </div>
                  </div>
                );
              })}
              <p id="SecondBox3">CO-3-Doença descompressiva</p>

              <Xarrow
                start="9-1Form6"
                end="EventForm6"
                color="#b8291a"
                showTail={true}
                showHead={false}
                endAnchor={'right'}
              />
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          display:
            selectedEvent === 'Falha do Equipamento Individual do Mergulhador'
              ? ''
              : 'none ',
        }}
      >
        <div class="d-flex Block-Diagram">
          <div class="AM">
            <div class="d-flex" id="AM1Form7">
              <p id="FirstBox1">
                AM-1 - Armazenamento e transporte inadequado dos equipamentos
              </p>

              {listKeysQuestionDiagramFalhaEquipamentoIndividualForm[0][
                'AM-1 - Armazenamento e transporte inadequado dos equipamentos'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box1 answer1Form7"
                      id={Object.keys(key)[0] + 'Form7'}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BP.' + Object.keys(key)[0]}
                    </div>
                  </div>
                );
              })}
              <Xarrow
                start="1-1Form7"
                end="EventForm7"
                startAnchor={'right'}
                endAnchor={'left'}
                showTail={true}
                showHead={false}
                color="#343194"
              />
            </div>

            <div class="d-flex" id="AM2Form7">
              <p id="FirstBox2">AM-2- Manuseio inadequado do equipamento</p>

              {listKeysQuestionDiagramFalhaEquipamentoIndividualForm[0][
                'AM-2- Manuseio inadequado do equipamento'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box1 answer2Form7"
                      id={Object.keys(key)[0] + 'Form7'}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BP.' + Object.keys(key)[0]}
                    </div>
                  </div>
                );
              })}

              <Xarrow
                start="2-1Form7"
                end="EventForm7"
                startAnchor={'right'}
                endAnchor={'left'}
                showHead={false}
                showTail={true}
                color="#343194"
              />
            </div>

            <div class="d-flex" id="AM3Form7">
              <p id="FirstBox3">
                AM-3 - Inspeção e manutenção inadequada do equipamento
              </p>
              {listKeysQuestionDiagramFalhaEquipamentoIndividualForm[0][
                'AM-3 - Inspeção e manutenção inadequada do equipamento'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box1 answer3Form7"
                      id={Object.keys(key)[0] + 'Form7'}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BP.' + Object.keys(key)[0]}
                    </div>
                  </div>
                );
              })}
              <Xarrow
                start="3-1Form7"
                end="EventForm7"
                startAnchor={'right'}
                endAnchor={'left'}
                showHead={false}
                showTail={true}
                color="#343194"
              />
            </div>

            <div class="d-flex" id="AM4Form7">
              <p id="FirstBox4">
                AM-4 - Equipamento fora de especificação técnica
              </p>
              {listKeysQuestionDiagramFalhaEquipamentoIndividualForm[0][
                'AM-4 - Equipamento fora de especificação técnica'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box1 answer4Form7"
                      id={Object.keys(key)[0] + 'Form7'}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BP.' + Object.keys(key)[0]}
                    </div>
                  </div>
                );
              })}
            </div>

            <Xarrow
              start="4-1Form7"
              end="EventForm7"
              startAnchor={'right'}
              endAnchor={'left'}
              showHead={false}
              showTail={true}
              color="#343194"
            />

            <div class="d-flex" id="AM5Form7">
              <p id="FirstBox5">AM5 - Falha de equipagem</p>
              {listKeysQuestionDiagramFalhaEquipamentoIndividualForm[0][
                'AM5 - Falha de equipagem'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box1 answer5Form7"
                      id={Object.keys(key)[0] + 'Form7'}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BP.' + Object.keys(key)[0]}
                    </div>
                  </div>
                );
              })}
            </div>

            <Xarrow
              start="5-1Form7"
              end="EventForm7"
              startAnchor={'right'}
              endAnchor={'left'}
              showHead={false}
              showTail={true}
              color="#343194"
            />
          </div>

          <div id="Event-Block">
            <div id="HazardForm7">
              <p id="Title-Hazard">{selectedHazard}</p>
            </div>
            <Xarrow start="HazardForm7" end="EventForm7" color="black" />
            <div id="EventForm7">{selectedEvent}</div>
          </div>

          <div class="CO">
            <div class="d-flex">
              {listKeysQuestionDiagramFalhaEquipamentoIndividualForm[0][
                'CO-1 - Doença descompressiva'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box2"
                      id={Object.keys(key)[0] + 'Form7'}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BM.' + Object.keys(key)[0].replace('6-', '1-')}
                    </div>
                  </div>
                );
              })}
              <p id="SecondBox1">CO-1 - Doença descompressiva</p>

              <Xarrow
                start="6-1Form7"
                end="EventForm7"
                color="#b8291a"
                showTail={true}
                showHead={false}
                endAnchor={'right'}
              />
            </div>
            <div class="d-flex">
              {listKeysQuestionDiagramFalhaEquipamentoIndividualForm[0][
                'CO-2 – Afogamento'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box2"
                      id={Object.keys(key)[0] + 'Form7'}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BM.' + Object.keys(key)[0].replace('7-', '2-')}
                    </div>
                  </div>
                );
              })}
              <p id="SecondBox2">CO-2 – Afogamento</p>

              <Xarrow
                start="7-1Form7"
                end="EventForm7"
                color="#b8291a"
                showTail={true}
                showHead={false}
                endAnchor={'right'}
              />
            </div>
            <div class="d-flex">
              {listKeysQuestionDiagramFalhaEquipamentoIndividualForm[0][
                'CO-3 – Asfixia'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box2"
                      id={Object.keys(key)[0] + 'Form7'}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BM.' + Object.keys(key)[0].replace('8-', '3-')}
                    </div>
                  </div>
                );
              })}
              <p id="SecondBox3">CO-3 – Asfixia</p>

              <Xarrow
                start="8-1Form7"
                end="EventForm7"
                color="#b8291a"
                showTail={true}
                showHead={false}
                endAnchor={'right'}
              />
            </div>

            <div class="d-flex">
              {listKeysQuestionDiagramFalhaEquipamentoIndividualForm[0][
                'CO-4 – Queimadura'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box2 answer-box2CO4Form7"
                      id={Object.keys(key)[0] + 'Form7'}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BM.' + Object.keys(key)[0].replace('9-', '4-')}
                    </div>
                  </div>
                );
              })}
              <p id="SecondBox3">CO-4 – Queimadura</p>

              <Xarrow
                start="9-1Form7"
                end="EventForm7"
                color="#b8291a"
                showTail={true}
                showHead={false}
                endAnchor={'right'}
              />
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          display:
            selectedEvent ===
            'Contato Acidental do Mergulhador com os Propulsores'
              ? ''
              : 'none ',
        }}
      >
        <div class="d-flex Block-Diagram">
          <div class="AM">
            <div class="d-flex" id="AM1Form8">
              <p id="FirstBox1">AM-1 - Aproximação indevida de embarcações</p>

              {listKeysQuestionDiagramContatoAcidentalMergulhadorForm[0][
                'AM-1 - Aproximação indevida de embarcações'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box1 answer1Form8"
                      id={Object.keys(key)[0] + 'Form8'}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BP.' + Object.keys(key)[0]}
                    </div>
                  </div>
                );
              })}
              <Xarrow
                start="1-1Form8"
                end="EventForm8"
                startAnchor={'right'}
                endAnchor={'left'}
                showTail={true}
                showHead={false}
                color="#343194"
              />
            </div>

            <div class="d-flex" id="AM2Form8">
              <p id="FirstBox2">AM-2 - Perda de posicionamento da embarcação</p>

              {listKeysQuestionDiagramContatoAcidentalMergulhadorForm[0][
                'AM-2 - Perda de posicionamento da embarcação'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box1 answer2Form8"
                      id={Object.keys(key)[0] + 'Form8'}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BP.' + Object.keys(key)[0]}
                    </div>
                  </div>
                );
              })}

              <Xarrow
                start="2-1Form8"
                end="EventForm8"
                startAnchor={'right'}
                endAnchor={'left'}
                showHead={false}
                showTail={true}
                color="#343194"
              />
            </div>

            <div class="d-flex" id="AM3Form8">
              <p id="FirstBox3">AM-3 - Excesso de umbilical na água</p>
              {listKeysQuestionDiagramContatoAcidentalMergulhadorForm[0][
                'AM-3 - Excesso de umbilical na água'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box1 answer3Form8"
                      id={Object.keys(key)[0] + 'Form8'}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BP.' + Object.keys(key)[0]}
                    </div>
                  </div>
                );
              })}
              <Xarrow
                start="3-1Form8"
                end="EventForm8"
                startAnchor={'right'}
                endAnchor={'left'}
                showHead={false}
                showTail={true}
                color="#343194"
              />
            </div>

            <div class="d-flex" id="AM4Form8">
              <p id="FirstBox4">
                AM-4 - Pane mecânica no sistema de comando da embarcação durante
                a operação de mergulho
              </p>
              {listKeysQuestionDiagramContatoAcidentalMergulhadorForm[0][
                'AM-4 - Pane mecânica no sistema de comando da embarcação durante a operação de mergulho'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box1 answer4Form8"
                      id={Object.keys(key)[0] + 'Form8'}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BP.' + Object.keys(key)[0]}
                    </div>
                  </div>
                );
              })}
            </div>

            <Xarrow
              start="4-1Form8"
              end="EventForm8"
              startAnchor={'right'}
              endAnchor={'left'}
              showHead={false}
              showTail={true}
              color="#343194"
            />

            <div class="d-flex" id="AM5Form8">
              <p id="FirstBox5">AM-5 - Condições meteoceanográficas adversas</p>
              {listKeysQuestionDiagramContatoAcidentalMergulhadorForm[0][
                'AM-5 - Condições meteoceanográficas adversas'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box1 answer5Form8"
                      id={Object.keys(key)[0] + 'Form8'}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BP.' + Object.keys(key)[0]}
                    </div>
                  </div>
                );
              })}
            </div>

            <Xarrow
              start="5-1Form8"
              end="EventForm8"
              startAnchor={'right'}
              endAnchor={'left'}
              showHead={false}
              showTail={true}
              color="#343194"
            />
          </div>

          <div id="Event-Block">
            <div id="HazardForm8">
              <p id="Title-Hazard">{selectedHazard}</p>
            </div>
            <Xarrow start="HazardForm8" end="EventForm8" color="black" />
            <div id="EventForm8">{selectedEvent}</div>
          </div>

          <div class="CO">
            <div class="d-flex">
              {listKeysQuestionDiagramContatoAcidentalMergulhadorForm[0][
                'CO-1 - Lesão com potencial de sequela permanente ou fatalidade'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box2 answer-box2Form8"
                      id={Object.keys(key)[0] + 'Form8'}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BM.' + Object.keys(key)[0].replace('6-', '1-')}
                    </div>
                  </div>
                );
              })}
              <p id="SecondBox1" class="SecondBox1Form8">
                CO-1 - Lesão com potencial de sequela permanente ou fatalidade
              </p>

              <Xarrow
                start="6-1Form8"
                end="EventForm8"
                color="#b8291a"
                showTail={true}
                showHead={false}
                endAnchor={'right'}
              />
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          display:
            selectedEvent === 'Exposição dos Mergulhadores aos Contaminantes'
              ? ''
              : 'none ',
        }}
      >
        <div class="d-flex Block-Diagram">
          <div class="AM">
            <div class="d-flex" id="AM1Form9">
              <p id="FirstBox1">
                AM-1 - Presença de gases ou substâncias inadequadas em área de
                captação de ar atmosférico para suprimento respiratório
              </p>

              {listKeysQuestionDiagramExposicaoMergulhadoresContaminantesForm[0][
                'AM-1 - Presença de gases ou substâncias inadequadas em área de captação de ar atmosférico para suprimento respiratório'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box1 answer1Form9"
                      id={Object.keys(key)[0] + 'Form9'}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BP.' + Object.keys(key)[0]}
                    </div>
                  </div>
                );
              })}
              <Xarrow
                start="1-1Form9"
                end="EventForm9"
                startAnchor={'right'}
                endAnchor={'left'}
                showTail={true}
                showHead={false}
                color="#343194"
              />
            </div>

            <div class="d-flex" id="AM2Form9">
              <p id="FirstBox2">
                AM-2 - Descarte de gases tóxicos como hidrocarbonetos, CO e H2S
                nas proximidades das unidades atendidas (emerso)
              </p>

              {listKeysQuestionDiagramExposicaoMergulhadoresContaminantesForm[0][
                'AM-2 - Descarte de gases tóxicos como hidrocarbonetos, CO e H2S nas proximidades das unidades atendidas (emerso)'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box1 answer2Form9"
                      id={Object.keys(key)[0] + 'Form9'}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BP.' + Object.keys(key)[0]}
                    </div>
                  </div>
                );
              })}

              <Xarrow
                start="2-1Form9"
                end="EventForm9"
                startAnchor={'right'}
                endAnchor={'left'}
                showHead={false}
                showTail={true}
                color="#343194"
              />
            </div>

            <div class="d-flex" id="AM3Form9">
              <p id="FirstBox3">
                AM-3 – Presença de hidrocarbonetos e outros produtos tóxicos
                (submerso) em abertura de flanges e conectores de dutos e
                inspeção de casco de navio
              </p>
              {listKeysQuestionDiagramExposicaoMergulhadoresContaminantesForm[0][
                'AM-3 – Presença de hidrocarbonetos e outros produtos tóxicos (submerso) em abertura de flanges e conectores de dutos e inspeção de casco de navio'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box1 answer3Form9"
                      id={Object.keys(key)[0] + 'Form9'}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BP.' + Object.keys(key)[0]}
                    </div>
                  </div>
                );
              })}
              <Xarrow
                start="3-1Form9"
                end="EventForm9"
                startAnchor={'right'}
                endAnchor={'left'}
                showHead={false}
                showTail={true}
                color="#343194"
              />
            </div>
          </div>

          <div id="Event-Block">
            <div id="HazardForm9">
              <p id="Title-Hazard">{selectedHazard}</p>
            </div>
            <Xarrow start="HazardForm9" end="EventForm9" color="black" />
            <div id="EventForm9">{selectedEvent}</div>
          </div>

          <div class="CO">
            <div class="d-flex">
              {listKeysQuestionDiagramExposicaoMergulhadoresContaminantesForm[0][
                'CO-1 - Contaminação do mergulhador submerso com alteração funcional'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box2"
                      id={Object.keys(key)[0] + 'Form9'}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BM.' + Object.keys(key)[0].replace('4-', '1-')}
                    </div>
                  </div>
                );
              })}
              <p id="SecondBox1">
                CO-1 - Contaminação do mergulhador submerso com alteração
                funcional
              </p>

              <Xarrow
                start="4-1Form9"
                end="EventForm9"
                color="#b8291a"
                showTail={true}
                showHead={false}
                endAnchor={'right'}
              />
            </div>

            <div class="d-flex">
              {listKeysQuestionDiagramExposicaoMergulhadoresContaminantesForm[0][
                'CO-2 - Contaminação do mergulhador ainda emerso com alteração funcional'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box2"
                      id={Object.keys(key)[0] + 'Form9'}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BM.' + Object.keys(key)[0].replace('5-', '2-')}
                    </div>
                  </div>
                );
              })}
              <p id="SecondBox2">
                CO-2 - Contaminação do mergulhador ainda emerso com alteração
                funcional
              </p>

              <Xarrow
                start="5-1Form9"
                end="EventForm9"
                color="#b8291a"
                showTail={true}
                showHead={false}
                endAnchor={'right'}
              />
            </div>

            <div class="d-flex">
              {listKeysQuestionDiagramExposicaoMergulhadoresContaminantesForm[0][
                'CO-3 - Fatalidade'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box2"
                      id={Object.keys(key)[0] + 'Form9'}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BM.' + Object.keys(key)[0].replace('6-', '3-')}
                    </div>
                  </div>
                );
              })}
              <p id="SecondBox3">CO-3 - Fatalidade</p>

              <Xarrow
                start="6-1Form9"
                end="EventForm9"
                color="#b8291a"
                showTail={true}
                showHead={false}
                endAnchor={'right'}
              />
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          display:
            selectedEvent ===
            'EXPOSIÇÃO A DESCARGAS ELÉTRICAS, IMPACTOS MECÂNICOS E FLUIDOS PRESSURIZADOS NO CORPO DO MERGULHADOR (SUBMERSOS/EMERSOS)'
              ? ''
              : 'none ',
        }}
      >
        <div class="d-flex Block-Diagram">
          <div class="AM">
            <div class="d-flex" id="AM1Form10">
              <p id="FirstBox1">
                AM-1 - Variação brusca de swell/correnteza provocando encontro
                do mergulhador contra obstáculos
              </p>

              {listKeysQuestionDiagramExposicaoDescargasEletricasForm[0][
                'AM-1 - Variação brusca de swell/correnteza provocando encontro do mergulhador contra obstáculos'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box1 answer1Form10"
                      id={Object.keys(key)[0] + 'Form10'}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BP.' + Object.keys(key)[0]}
                    </div>
                  </div>
                );
              })}
              <Xarrow
                start="1-1Form10"
                end="EventForm10"
                startAnchor={'right'}
                endAnchor={'left'}
                showTail={true}
                showHead={false}
                color="#343194"
              />
            </div>

            <div class="d-flex" id="AM2Form10">
              <p id="FirstBox2">
                AM-2 - Movimentação/ Manuseio inadequado ou falha de ferramentas
                e acessórios de trabalho
              </p>

              {listKeysQuestionDiagramExposicaoDescargasEletricasForm[0][
                'AM-2 - Movimentação/ Manuseio inadequado ou falha de ferramentas e acessórios de trabalho'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box1 answer2Form10"
                      id={Object.keys(key)[0] + 'Form10'}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BP.' + Object.keys(key)[0]}
                    </div>
                  </div>
                );
              })}

              <Xarrow
                start="2-1Form10"
                end="EventForm10"
                startAnchor={'right'}
                endAnchor={'left'}
                showHead={false}
                showTail={true}
                color="#343194"
              />
            </div>

            <div class="d-flex" id="AM3Form10">
              <p id="FirstBox3">
                AM-3 - Falha de operação de pull in e pull out (ruptura de cabo
                sob tensão)
              </p>
              {listKeysQuestionDiagramExposicaoDescargasEletricasForm[0][
                'AM-3 - Falha de operação de pull in e pull out (ruptura de cabo sob tensão)'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box1 answer3Form10"
                      id={Object.keys(key)[0] + 'Form10'}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BP.' + Object.keys(key)[0]}
                    </div>
                  </div>
                );
              })}
              <Xarrow
                start="3-1Form10"
                end="EventForm10"
                startAnchor={'right'}
                endAnchor={'left'}
                showHead={false}
                showTail={true}
                color="#343194"
              />
            </div>

            <div class="d-flex" id="AM4Form10">
              <p id="FirstBox4">
                AM-4 - Rompimento do cabo do sino/sinete/cesta (LARS)
              </p>
              {listKeysQuestionDiagramExposicaoDescargasEletricasForm[0][
                'AM-4 - Rompimento do cabo do sino/sinete/cesta (LARS)'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box1 answer4Form10"
                      id={Object.keys(key)[0] + 'Form10'}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BP.' + Object.keys(key)[0]}
                    </div>
                  </div>
                );
              })}
              <Xarrow
                start="4-1Form10"
                end="EventForm10"
                startAnchor={'right'}
                endAnchor={'left'}
                showHead={false}
                showTail={true}
                color="#343194"
              />
            </div>

            <div class="d-flex" id="AM5Form10">
              <p id="FirstBox5">AM-5 - Queda de objeto no mar</p>
              {listKeysQuestionDiagramExposicaoDescargasEletricasForm[0][
                'AM-5 - Queda de objeto no mar'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box1 answer5Form10"
                      id={Object.keys(key)[0] + 'Form10'}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BP.' + Object.keys(key)[0]}
                    </div>
                  </div>
                );
              })}
              <Xarrow
                start="5-1Form10"
                end="EventForm10"
                startAnchor={'right'}
                endAnchor={'left'}
                showHead={false}
                showTail={true}
                color="#343194"
              />
            </div>

            <div class="d-flex" id="AM6Form10">
              <p id="FirstBox6">
                AM-6 - Falha na utilização da bolsa de içamento/paraquedas
              </p>
              {listKeysQuestionDiagramExposicaoDescargasEletricasForm[0][
                'AM-6 - Falha na utilização da bolsa de içamento/paraquedas'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box1 answer6Form10"
                      id={Object.keys(key)[0] + 'Form10'}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BP.' + Object.keys(key)[0]}
                    </div>
                  </div>
                );
              })}
              <Xarrow
                start="6-1Form10"
                end="EventForm10"
                startAnchor={'right'}
                endAnchor={'left'}
                showHead={false}
                showTail={true}
                color="#343194"
              />
            </div>

            <div class="d-flex" id="AM7Form10">
              <p id="FirstBox7">
                AM-7 - Rompimento de amarras e dutos flexíveis
              </p>
              {listKeysQuestionDiagramExposicaoDescargasEletricasForm[0][
                'AM-7 - Rompimento de amarras e dutos flexíveis'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box1 answer7Form10"
                      id={Object.keys(key)[0] + 'Form10'}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BP.' + Object.keys(key)[0]}
                    </div>
                  </div>
                );
              })}
              <Xarrow
                start="7-1Form10"
                end="EventForm10"
                startAnchor={'right'}
                endAnchor={'left'}
                showHead={false}
                showTail={true}
                color="#343194"
              />
            </div>

            <div class="d-flex" id="AM8Form10">
              <p id="FirstBox8">
                AM-8 – Deslocamento inadvertido da embarcação durante as
                operações de mergulho
              </p>
              {listKeysQuestionDiagramExposicaoDescargasEletricasForm[0][
                'AM-8 – Deslocamento inadvertido da embarcação durante as operações de mergulho'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box1 answer8Form10"
                      id={Object.keys(key)[0] + 'Form10'}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BP.' + Object.keys(key)[0]}
                    </div>
                  </div>
                );
              })}
              <Xarrow
                start="8-1Form10"
                end="EventForm10"
                startAnchor={'right'}
                endAnchor={'left'}
                showHead={false}
                showTail={true}
                color="#343194"
              />
            </div>

            <div class="d-flex" id="AM9Form10">
              <p id="FirstBox9">
                AM-9 - Fuga de corrente de equipamentos e acessórios energizados
              </p>
              {listKeysQuestionDiagramExposicaoDescargasEletricasForm[0][
                'AM-9 - Fuga de corrente de equipamentos e acessórios energizados'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box1 answer9Form10"
                      id={Object.keys(key)[0] + 'Form10'}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BP.' + Object.keys(key)[0]}
                    </div>
                  </div>
                );
              })}
              <Xarrow
                start="9-1Form10"
                end="EventForm10"
                startAnchor={'right'}
                endAnchor={'left'}
                showHead={false}
                showTail={true}
                color="#343194"
              />
            </div>

            <div class="d-flex" id="AM10Form10">
              <p id="FirstBox10">
                AM-10 – Rompimento de Mangueiras hidráulicas e outros acessórios
                dos sistemas de mergulho
              </p>
              {listKeysQuestionDiagramExposicaoDescargasEletricasForm[0][
                'AM-10 – Rompimento de Mangueiras hidráulicas e outros acessórios dos sistemas de mergulho'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box1 answer10Form10"
                      id={Object.keys(key)[0] + 'Form10'}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BP.' + Object.keys(key)[0]}
                    </div>
                  </div>
                );
              })}
              <Xarrow
                start="10-1Form10"
                end="EventForm10"
                startAnchor={'right'}
                endAnchor={'left'}
                showHead={false}
                showTail={true}
                color="#343194"
              />
            </div>

            <div class="d-flex" id="AM11Form10">
              <p id="FirstBox11">
                AM-11 – Impacto durante o lançamento e recolhimento do LDB
              </p>
              {listKeysQuestionDiagramExposicaoDescargasEletricasForm[0][
                'AM-11 – Impacto durante o lançamento e recolhimento do LDB'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box1 answer11Form10"
                      id={Object.keys(key)[0] + 'Form10'}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BP.' + Object.keys(key)[0]}
                    </div>
                  </div>
                );
              })}
              <Xarrow
                start="11-1Form10"
                end="EventForm10"
                startAnchor={'right'}
                endAnchor={'left'}
                showHead={false}
                showTail={true}
                color="#343194"
              />
            </div>
          </div>

          <div id="Event-Block">
            <div id="HazardForm10">
              <p id="Title-Hazard">{selectedHazard}</p>
            </div>
            <Xarrow start="HazardForm10" end="EventForm10" color="black" />
            <div id="EventForm10">{selectedEvent}</div>
          </div>

          <div class="CO">
            <div class="d-flex">
              {listKeysQuestionDiagramExposicaoDescargasEletricasForm[0][
                'CO-1 - Lesão com potencial de invalidez e fatalidade'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box2 answer-box2Form10"
                      id={Object.keys(key)[0] + 'Form10'}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BM.' + Object.keys(key)[0].replace('12-', '1-')}
                    </div>
                  </div>
                );
              })}
              <p id="SecondBox1" class="SecondBox1Form10">
                CO-1 - Lesão com potencial de invalidez e fatalidade
              </p>

              <Xarrow
                start="12-1Form10"
                end="EventForm10"
                color="#b8291a"
                showTail={true}
                showHead={false}
                endAnchor={'right'}
              />
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          display:
            selectedEvent === 'Exposição do Mergulhador A Vida Marinha Nociva'
              ? ''
              : 'none ',
        }}
      >
        <div class="d-flex Block-Diagram">
          <div class="AM">
            <div class="d-flex" id="AM1Form11">
              <p id="FirstBox1">
                AM-1 - Presença de vida marinha passiva (Ex: águas vivas,
                corais, ostras, mariscos, ouriços, etc)
              </p>

              {listKeysQuestionDiagramExposicaoMergulhadorVidaMarinhaForm[0][
                'AM-1 -  Presença de vida marinha passiva (Ex: águas vivas, corais, ostras, mariscos, ouriços, etc)'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box1 answer1Form11"
                      id={Object.keys(key)[0] + 'Form11'}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BP.' + Object.keys(key)[0]}
                    </div>
                  </div>
                );
              })}
              <Xarrow
                start="1-1Form11"
                end="EventForm11"
                startAnchor={'right'}
                endAnchor={'left'}
                showTail={true}
                showHead={false}
                color="#343194"
              />
            </div>

            <div class="d-flex" id="AM2Form11">
              <p id="FirstBox2">
                AM-2 - Presença de peixes (Ex: Marlim, Barracuda, Tubarão, Peixe
                Pedra, Moreia, Raia, etc)
              </p>

              {listKeysQuestionDiagramExposicaoMergulhadorVidaMarinhaForm[0][
                'AM-2 - Presença de peixes (Ex: Marlim, Barracuda, Tubarão, Peixe Pedra, Moreia, Raia, etc)'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box1 answer2Form11"
                      id={Object.keys(key)[0] + 'Form11'}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BP.' + Object.keys(key)[0]}
                    </div>
                  </div>
                );
              })}

              <Xarrow
                start="2-1Form11"
                end="EventForm11"
                startAnchor={'right'}
                endAnchor={'left'}
                showHead={false}
                showTail={true}
                color="#343194"
              />
            </div>
          </div>

          <div id="Event-Block">
            <div id="HazardForm11">
              <p id="Title-Hazard">{selectedHazard}</p>
            </div>
            <Xarrow start="HazardForm11" end="EventForm11" color="black" />
            <div id="EventForm11">{selectedEvent}</div>
          </div>

          <div class="CO">
            <div class="d-flex">
              {listKeysQuestionDiagramExposicaoMergulhadorVidaMarinhaForm[0][
                'CO-1 - Lesão ou alteração funcional, com potencial de sequela incapacitante e fatalidade'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box2 answer-box2Form11"
                      id={Object.keys(key)[0] + 'Form11'}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BM.' + Object.keys(key)[0].replace('3-', '1-')}
                    </div>
                  </div>
                );
              })}
              <p id="SecondBox1" class="SecondBox1Form11">
                CO-1 - Lesão ou alteração funcional, com potencial de sequela
                incapacitante e fatalidade
              </p>

              <Xarrow
                start="3-1Form11"
                end="EventForm11"
                color="#b8291a"
                showTail={true}
                showHead={false}
                endAnchor={'right'}
              />
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          display:
            selectedEvent ===
            'Emergências não hiberbáricas (alteração clínica do mergulhador)'
              ? ''
              : 'none ',
        }}
      >
        <div class="d-flex Block-Diagram">
          <div class="AM">
            <div class="d-flex" id="AM1Form12">
              <p id="FirstBox1">
                AM1 - Afecção/Condição cardiovasculares, respiratórios e
                auditivas
              </p>

              {listKeysQuestionDiagramEmergenciasMedicasHiberbaricasForm[0][
                'AM1 -  Afecção/Condição cardiovasculares, respiratórios e auditivas'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box1 answer1Form12"
                      id={Object.keys(key)[0] + 'Form12'}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BP.' + Object.keys(key)[0]}
                    </div>
                  </div>
                );
              })}
              <Xarrow
                start="1-1Form12"
                end="EventForm12"
                startAnchor={'right'}
                endAnchor={'left'}
                showTail={true}
                showHead={false}
                color="#343194"
              />
            </div>

            <div class="d-flex" id="AM2Form12">
              <p id="FirstBox2">AM 2 - Afecção/Condição osteomusculares</p>

              {listKeysQuestionDiagramEmergenciasMedicasHiberbaricasForm[0][
                'AM 2 -  Afecção/Condição osteomusculares'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box1 answer2Form12"
                      id={Object.keys(key)[0] + 'Form12'}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BP.' + Object.keys(key)[0]}
                    </div>
                  </div>
                );
              })}

              <Xarrow
                start="2-1Form12"
                end="EventForm12"
                startAnchor={'right'}
                endAnchor={'left'}
                showHead={false}
                showTail={true}
                color="#343194"
              />
            </div>

            <div class="d-flex" id="AM3Form12">
              <p id="FirstBox3">AM3 - Afecção/Condição gastrointestinais</p>

              {listKeysQuestionDiagramEmergenciasMedicasHiberbaricasForm[0][
                'AM3 - Afecção/Condição gastrointestinais'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box1 answer3Form12"
                      id={Object.keys(key)[0] + 'Form12'}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BP.' + Object.keys(key)[0]}
                    </div>
                  </div>
                );
              })}

              <Xarrow
                start="3-1Form12"
                end="EventForm12"
                startAnchor={'right'}
                endAnchor={'left'}
                showHead={false}
                showTail={true}
                color="#343194"
              />
            </div>

            <div class="d-flex" id="AM4Form12">
              <p id="FirstBox4">AM-4 - Afecção/Condição neuropsicológicos</p>

              {listKeysQuestionDiagramEmergenciasMedicasHiberbaricasForm[0][
                'AM-4 - Afecção/Condição neuropsicológicos'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box1 answer4Form12"
                      id={Object.keys(key)[0] + 'Form12'}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BP.' + Object.keys(key)[0]}
                    </div>
                  </div>
                );
              })}

              <Xarrow
                start="4-1Form12"
                end="EventForm12"
                startAnchor={'right'}
                endAnchor={'left'}
                showHead={false}
                showTail={true}
                color="#343194"
              />
            </div>
          </div>

          <div id="Event-Block">
            <div id="HazardForm12">
              <p id="Title-Hazard">{selectedHazard}</p>
            </div>
            <Xarrow start="HazardForm12" end="EventForm12" color="black" />
            <div id="EventForm12">{selectedEvent}</div>
          </div>

          <div class="CO">
            <div class="d-flex">
              {listKeysQuestionDiagramEmergenciasMedicasHiberbaricasForm[0][
                'CO1 – Lesão ou perturbação funcional, com potencial de sequela e fatalidade'
              ].map(key => {
                return (
                  <div>
                    <div
                      class="answer-box2 answer-box2Form12"
                      id={Object.keys(key)[0] + 'Form12'}
                      style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                    >
                      {'BM.' + Object.keys(key)[0].replace('5-', '1-')}
                    </div>
                  </div>
                );
              })}
              <p id="SecondBox1" class="SecondBox1Form12">
                CO1 – Lesão ou perturbação funcional, com potencial de sequela e
                fatalidade
              </p>

              <Xarrow
                start="5-1Form12"
                end="EventForm12"
                color="#b8291a"
                showTail={true}
                showHead={false}
                endAnchor={'right'}
              />
            </div>
          </div>
        </div>
      </div>

      <img id="legend" src={Legend} alt="Legenda-Bowtie-1" border="0" />

      <div
        style={{
          display:
            selectedEvent === 'Falta de Suprimento Respiratório' ? '' : 'none ',
        }}
      >
        <div id="Table-Legend-Block">
          <div class="d-flex">
            <img
              id="table-legend-AM"
              src={EventoBAM1}
              alt="legenda-AM1-new"
              border="0"
            />

            <img
              id="table-legend-AM"
              src={EventoBAM2}
              alt="legenda-AM2-new"
              border="0"
            />

            <img
              id="table-legend-AM"
              src={EventoBAM3}
              alt="legenda-AM3-new"
              border="0"
            />

            <img
              id="table-legend-AM"
              src={EventoBAM4}
              alt="legenda-AM4-new"
              border="0"
            />

            <img
              id="table-legend-AM"
              src={EventoBAM5}
              alt="legenda-AM4-new"
              border="0"
            />

            <img
              id="table-legend-AM"
              src={EventoBAM6}
              alt="legenda-AM4-new"
              border="0"
            />
          </div>

          <div class="d-flex">
            <a id="CO-link" href='https://www.peodive.com.br'>
              <img
                id="table-legend"
                src={EventoBCO1}
                alt="Legenda-CO1"
                border="0"
              />
            </a>
            <a id="CO-link" href='https://www.peodive.com.br'>
              <img
                id="table-legend"
                src={EventoBCO2}
                alt="LEGENDA-CO2"
                border="0"
              />
            </a>
          </div>
        </div>
      </div>

      <div
        style={{
          display: selectedEvent === 'Acidentes Hiperbaricos' ? '' : 'none',
        }}
      >
        <div id="Table-Legend-Block-Acidentes-Hiperbaricos">
          <div class="d-flex">
            <img
              id="table-legend-AM"
              src={EventoAAM1}
              alt="legenda-AM1-evento-A"
              border="0"
            />

            <img
              id="table-legend-AM"
              src={EventoAAM2}
              alt="legenda-AM2-evento-A"
              border="0"
            />

            <img
              id="table-legend-AM"
              src={EventoAAM3}
              alt="legenda-AM3-evento-A"
              border="0"
            />

            <img
              id="table-legend-AM"
              src={EventoAAM4}
              alt="legenda-AM4-evento-A"
              border="0"
            />

            <img
              id="table-legend-AM"
              src={EventoAAM5}
              alt="legenda-AM5-evento-A"
              border="0"
            />

            <img
              id="table-legend-AM"
              src={EventoAAM6}
              alt="legenda-AM6-evento-A"
              border="0"
            />

            <img
              id="table-legend-AM"
              src={EventoAAM7}
              alt="legenda-AM7-evento-A"
              border="0"
            />

            <img
              id="table-legend-AM"
              src={EventoAAM8}
              alt="legenda-AM8-evento-A"
              border="0"
            />

            <img
              id="table-legend-AM"
              src={EventoAAM9}
              alt="legenda-AM9-evento-A"
              border="0"
            />
          </div>

          <div class="d-flex">
            <img
              id="table-legend"
              src={EventoACO1}
              alt="Legenda-CO1-evento-A"
              border="0"
            />
          </div>
        </div>
      </div>

      <div
        style={{
          display:
            selectedEvent === 'Aprisionamento de Mergulhador no Fundo'
              ? ''
              : 'none',
        }}
      >
        <div id="Table-Legend-Block-Acidentes-Hiperbaricos">
          <div class="d-flex">
            <img
              id="table-legend-AM"
              src={EventoDAM1}
              alt="legenda-AM1-evento-A"
              border="0"
            />

            <img
              id="table-legend-AM"
              src={EventoDAM2}
              alt="legenda-AM2-evento-A"
              border="0"
            />

            <img
              id="table-legend-AM"
              src={EventoDAM3}
              alt="legenda-AM3-evento-A"
              border="0"
            />

            <img
              id="table-legend-AM"
              src={EventoDAM4}
              alt="legenda-AM4-evento-A"
              border="0"
            />

            <img
              id="table-legend-AM"
              src={EventoDAM5}
              alt="legenda-AM5-evento-A"
              border="0"
            />

            <img
              id="table-legend-AM"
              src={EventoDAM6}
              alt="legenda-AM6-evento-A"
              border="0"
            />

            <img
              id="table-legend-AM"
              src={EventoDAM7}
              alt="legenda-AM7-evento-A"
              border="0"
            />
          </div>

          <div class="d-flex">
            <img
              id="table-legend"
              src={EventoDCO1}
              alt="Legenda-CO1-evento-A"
              border="0"
            />
          </div>
        </div>
      </div>

      <div
        style={{
          display:
            selectedEvent ===
            'Exposição Inadequada do Mergulhador na Interface de Sistemas Com Diferencial de Pressão'
              ? ''
              : 'none',
        }}
      >
        <div id="Table-Legend-Block-Acidentes-Hiperbaricos">
          <div class="d-flex">
            <img
              id="table-legend-AM"
              src={EventoEAM1}
              alt="legenda-AM1-evento-A"
              border="0"
            />

            <img
              id="table-legend-AM"
              src={EventoEAM2}
              alt="legenda-AM2-evento-A"
              border="0"
            />

            <img
              id="table-legend-AM"
              src={EventoEAM3}
              alt="legenda-AM3-evento-A"
              border="0"
            />

            <img
              id="table-legend-AM"
              src={EventoEAM4}
              alt="legenda-AM4-evento-A"
              border="0"
            />
          </div>

          <div class="d-flex">
            <img
              id="table-legend"
              src={EventoECO1}
              alt="Legenda-CO1-evento-A"
              border="0"
            />
          </div>
        </div>
      </div>

      <div
        style={{
          display:
            selectedEvent ===
            'INCÊNDIO E/OU EXPLOSÃO EM UMA CÂMARA OU NAS PROXIMIDADES DO SISTEMA DE MERGULHO'
              ? ''
              : 'none',
        }}
      >
        <div id="Table-Legend-Block-Acidentes-Hiperbaricos">
          <div class="d-flex">
            <img
              id="table-legend-AM"
              src={EventoGAM1}
              alt="legenda-AM1-evento-A"
              border="0"
            />

            <img
              id="table-legend-AM"
              src={EventoGAM2}
              alt="legenda-AM2-evento-A"
              border="0"
            />

            <img
              id="table-legend-AM"
              src={EventoGAM3}
              alt="legenda-AM3-evento-A"
              border="0"
            />

            <img
              id="table-legend-AM"
              src={EventoGAM4}
              alt="legenda-AM4-evento-A"
              border="0"
            />

            <img
              id="table-legend-AM"
              src={EventoGAM5}
              alt="legenda-AM4-evento-A"
              border="0"
            />

            <img
              id="table-legend-AM"
              src={EventoGAM6}
              alt="legenda-AM4-evento-A"
              border="0"
            />
          </div>

          <div class="d-flex">
            <img
              id="table-legend"
              src={EventoGCO1}
              alt="Legenda-CO1-evento-A"
              border="0"
            />

            <img
              id="table-legend"
              src={EventoGCO2}
              alt="Legenda-CO1-evento-A"
              border="0"
            />

            <img
              id="table-legend"
              src={EventoGCO3}
              alt="Legenda-CO1-evento-A"
              border="0"
            />
          </div>
        </div>
      </div>

      <div
        style={{
          display:
            selectedEvent === 'Falha do Equipamento Individual do Mergulhador'
              ? ''
              : 'none',
        }}
      >
        <div id="Table-Legend-Block-Acidentes-Hiperbaricos">
          <div class="d-flex">
            <img
              id="table-legend-AM"
              src={EventoHAM1}
              alt="legenda-AM1-evento-A"
              border="0"
            />

            <img
              id="table-legend-AM"
              src={EventoHAM2}
              alt="legenda-AM2-evento-A"
              border="0"
            />

            <img
              id="table-legend-AM"
              src={EventoHAM3}
              alt="legenda-AM3-evento-A"
              border="0"
            />

            <img
              id="table-legend-AM"
              src={EventoHAM4}
              alt="legenda-AM4-evento-A"
              border="0"
            />

            <img
              id="table-legend-AM"
              src={EventoHAM5}
              alt="legenda-AM4-evento-A"
              border="0"
            />
          </div>

          <div class="d-flex">
            <img
              id="table-legend"
              src={EventoHCO1}
              alt="Legenda-CO1-evento-A"
              border="0"
            />

            <img
              id="table-legend"
              src={EventoHCO2}
              alt="Legenda-CO1-evento-A"
              border="0"
            />

            <img
              id="table-legend"
              src={EventoHCO3}
              alt="Legenda-CO1-evento-A"
              border="0"
            />

            <img
              id="table-legend"
              src={EventoHCO4}
              alt="Legenda-CO1-evento-A"
              border="0"
            />
          </div>
        </div>
      </div>

      <div
        style={{
          display:
            selectedEvent ===
            'Contato Acidental do Mergulhador com os Propulsores'
              ? ''
              : 'none',
        }}
      >
        <div id="Table-Legend-Block-Acidentes-Hiperbaricos">
          <div class="d-flex">
            <img
              id="table-legend-AM"
              src={EventoJAM1}
              alt="legenda-AM1-evento-A"
              border="0"
            />

            <img
              id="table-legend-AM"
              src={EventoJAM2}
              alt="legenda-AM2-evento-A"
              border="0"
            />

            <img
              id="table-legend-AM"
              src={EventoJAM3}
              alt="legenda-AM3-evento-A"
              border="0"
            />

            <img
              id="table-legend-AM"
              src={EventoJAM4}
              alt="legenda-AM4-evento-A"
              border="0"
            />

            <img
              id="table-legend-AM"
              src={EventoJAM5}
              alt="legenda-AM4-evento-A"
              border="0"
            />
          </div>

          <div class="d-flex">
            <img
              id="table-legend"
              src={EventoJCO1}
              alt="Legenda-CO1-evento-A"
              border="0"
            />
          </div>
        </div>
      </div>

      <div
        style={{
          display:
            selectedEvent === 'Exposição dos Mergulhadores aos Contaminantes'
              ? ''
              : 'none',
        }}
      >
        <div id="Table-Legend-Block-Acidentes-Hiperbaricos">
          <div class="d-flex">
            <img
              id="table-legend-AM"
              src={EventoKAM1}
              alt="legenda-AM1-evento-A"
              border="0"
            />

            <img
              id="table-legend-AM"
              src={EventoKAM2}
              alt="legenda-AM2-evento-A"
              border="0"
            />

            <img
              id="table-legend-AM"
              src={EventoKAM3}
              alt="legenda-AM3-evento-A"
              border="0"
            />
          </div>

          <div class="d-flex">
            <img
              id="table-legend"
              src={EventoKCO1}
              alt="Legenda-CO1-evento-A"
              border="0"
            />

            <img
              id="table-legend"
              src={EventoKCO2}
              alt="Legenda-CO1-evento-A"
              border="0"
            />

            <img
              id="table-legend"
              src={EventoKCO3}
              alt="Legenda-CO1-evento-A"
              border="0"
            />
          </div>
        </div>
      </div>
    </div>
  );
}


const titleFormExposicaoMergulhadorVidaMarinha="Exposição do Mergulhador A Vida Marinha Nociva"
const titleHazardExposicaoMergulhadorVidaMarinha="Ambiente Marinho";
const SecondIdExposicaoMergulhadorVidaMarinha="ExposicaoMergulhadorVidaMarinha";
const listAMCOExposicaoMergulhadorVidaMarinha=['AM-1 -  Presença de vida marinha passiva (Ex: águas vivas, corais, ostras, mariscos, ouriços, etc)','CO-1 - Lesão ou alteração funcional, com potencial de sequela incapacitante e fatalidade'];
let TitlesAndQuestionsExposicaoMergulhadorVidaMarinha = {
    'AM-1 -  Presença de vida marinha passiva (Ex: águas vivas, corais, ostras, mariscos, ouriços, etc)': {
      '1-1 a 1': {
        Question:
          'Os mergulhadores têm conhecimento da vida Marinha típica (ex: águas vivas, corais, ostras, mariscos, ouriços, etc) das principais bacias onde mergulham?',
        Answer: 'white',
      },
      '1-1 b 2': {
        Question:
          'Quando os mergulhadores vão operar em locais diferentes, que normalmente não mergulham, eles são informados sobre a vida Marinha típica (ex: águas vivas, corais, ostras, mariscos, ouriços, etc) desse local?',
        Answer: 'white',
      },
      '1-1 c 3': {
        Question:
          'Os supervisores são treinados e conscientizados dos riscos de contato do mergulhador na água com seres marinhos nocivos (ex. águas vivas, corais, ostras, mariscos, ouriços, etc)?',
        Answer: 'white',
      },
      '1-1 d 4': {
        Question:
          'No procedimento de operações de mergulho é orientado quanto aos cuidados necessários para proteger o mergulhador de exposição a seres marinhos nocivos (ex. águas vivas, corais, ostras, mariscos, ouriços, etc)?',
        Answer: 'white',
      },
      '1-2 a 5': {
        Question:
          'Os supervisores são treinados e conscientizados dos riscos de contato do mergulhador na água com seres marinhos nocivos (Ex: águas vivas, corais, ostras, mariscos, ouriços, etc)?',
        Answer: 'white',
      },
      '1-2 b 6': {
        Question:
          ' No check list pré mergulho os mergulhadores são orientados para evitar contato físico com fauna e seres marinhos nocivos (Ex: águas vivas, corais, ostras, mariscos, ouriços, etc)? ',
        Answer: 'white',
      },
      '1-2 c 7': {
        Question:
          'Os mergulhadores são orientados para nunca deixarem nenhuma parte do corpo exposta a contato com fauna e seres marinhos nocivos (Ex: águas vivas, corais, ostras, mariscos, ouriços, etc)?',
        Answer: 'white',
      },
      '1-2 d 8': {
        Question: 'Os mergulhadores são treinados e conscientizados para os riscos do contato dos membros superiores e inferiores com a fauna e seres marinhos nocivos (Ex: águas vivas, corais, ostras, mariscos, ouriços, etc)? ',
        Answer: 'white',
      },
      '1-2 e 9': {
        Question:
          'É levado em consideração que os mergulhos próximos a ZVM oferecem um risco maior de contato com corais, ostras, mariscos, ouriços devido a ação de swell?',
        Answer: 'white',
      },
      '1-3 a 10': {
        Question:
          'São realizados mergulhos com ROV para reconhecimento de fauna e seres marinhos nocivos antes das operações de mergulho? (Aplicável apenas para mergulho saturado)',
        Answer: 'white',
      },
      '1-3 b 11': {
        Question:
          'Os pilotos de ROV são orientados quanto a verificação de fauna e seres marinhos nocivos antes das operações de mergulho? (Aplicável apenas para mergulho saturado)',
        Answer: 'white',
      },
      '1-4 a 12': {
        Question:
          'As operações de mergulho são liberadas somente com a aprovação do comantante/imediato e supervisor de mergulho que aprovam as condições meteoceanográficas adequadas as operações de mergulho?',
        Answer: 'white',
      },
      '1-4 b 13': {
        Question:
          'É consultado antes e durante as operações de mergulho os boletins meteoceanográficos quanto as variações de maré e correnteza?',
        Answer: 'white',
      },
      '1-5 a 14': {
        Question:
          'Os mergulhadores utilizam EPIs adequados nos mergulhos para evitar contato com a fauna e seres marinhos nocivos (Ex: águas vivas, ostras, mariscos, ouriços, etc)?',
        Answer: 'white',
      },
      '1-5 b 15': {
        Question:
          'É verificado antes do mergulho se o mergulhador está completamente protegido do contato com a fauna e seres marinhos ex. luva sem expor parte do punho ou gola do neckdam protegendo o pescoço?',
        Answer: 'white',
      },
      '1-5 c 16': {
        Question:
          'As luvas fornecidas são de material apropriado para proteger as mãos do mergulhador evitando cortes caso em contato com corais, ostras, mariscos, ouriços?',
        Answer: 'white',
      },
      
      
      
    },
    'AM-2 - Presença de peixes (Ex: Marlim, Barracuda, Tubarão, Peixe Pedra, Moreia, Raia, etc)': {
      '2-1 a 17': {
        Question:
          'Os mergulhadores têm conhecimento da vida Marinha típica (Ex: Marlim, Barracuda, Tubarão, Peixe Pedra, Moreia, Raia, etc) das principais bacias onde mergulham?',
        Answer: 'white',
      },
      '2-1 b 18': {
        Question:
          'Quando os mergulhadores vão operar em locais diferentes, que normalmente não mergulham, eles são informados sobre a vida Marinha típica (Ex: Marlim, Barracuda, Tubarão, Peixe Pedra, Moreia, Raia, etc) desse local?',
        Answer: 'white',
      },
      '2-1 c 19': {
        Question:
          'Os supervisores são treinados e conscientizados dos riscos de contato do mergulhador na água com peixes (Ex: Marlim, Barracuda, Tubarão, Peixe Pedra, Moreia, Raia, etc)?',
        Answer: 'white',
      },
      '2-1 d 20': {
        Question:
          'No procedimento de operações de mergulho é orientado quanto aos cuidados necessários para proteger o mergulhador de exposição a seres marinhos nocivos (Ex: Marlim, Barracuda, Tubarão, Peixe Pedra, Moreia, Raia, etc)?',
        Answer: 'white',
      },
      '2-2 a 21': {
        Question:
          'Os supervisores são treinados e conscientizados dos riscos de contato do mergulhador na água com peixes (Ex: Marlim, Barracuda, Tubarão, Peixe Pedra, Moreia, Raia, etc)?',
        Answer: 'white',
      },
      '2-2 b 22': {
        Question:
          'No check list pré mergulho os mergulhadores são orientados para evitar contato físico com fauna e seres marinhos nocivos (Ex: Marlim, Barracuda, Tubarão, Peixe Pedra, Moreia, Raia, etc)?',
        Answer: 'white',
      },
      '2-2 c 23': {
        Question:
          'Os mergulhadores são orientados para nunca deixarem nenhuma parte do corpo exposta a contato com fauna e seres marinhos nocivos (Ex: Marlim, Barracuda, Tubarão, Peixe Pedra, Moreia, Raia, etc)?',
        Answer: 'white',
      },
      '2-2 d 24': {
        Question:
          'Os mergulhadores são treinados e conscientizados para os riscos do contato dos membros superiores e inferiores com a fauna e seres marinhos nocivos (Ex: Marlim, Barracuda, Tubarão, Peixe Pedra, Moreia, Raia, etc)?',
        Answer: 'white',
      },
      '2-3 a 25': {
        Question:
          'São realizados mergulhos com ROV para reconhecimento de fauna e seres marinhos nocivos antes das operações de mergulho? (Aplicável apenas para mergulho saturado)',
        Answer: 'white',
      },
      '2-3 b 26': {
        Question:
          'Os pilotos de ROV são orientados quanto a verificação de fauna e seres marinhos nocivos antes das operações de mergulho? (Aplicável apenas para mergulho saturado)',
        Answer: 'white',
      },
      '2-4 a 27': {
        Question:
          'Os mergulhadores utilizam EPIs adequados nos mergulhos para evitar contato com peixes (Ex: Peixe Pedra, Moreia, Raia)?',
        Answer: 'white',
      },
      '2-4 b 28': {
        Question:
          'É verificado antes do mergulho se o mergulhador está completamente protegido do contato com a fauna e seres marinhos ex. luva sem expor parte do punho ou gola do neckdam protegendo o pescoço?',
        Answer: 'white',
      },
      '2-4 c 29': {
        Question:
          'As luvas fornecidas são de material apropriado para proteger as mãos do mergulhador evitando cortes caso em contato com peixes?',
        Answer: 'white',
      },
      '2-5 a 30': {
        Question:
          'Os supervisores e equipe de mergulho são treinados e conscientizados para abortar o mergulho e retornar para local seguro (sino/sinete/cesta ou superfície) sempre que avistar um peixe que possa por em risco a integridade física do mergulhador?',
        Answer: 'white',
      },
      '2-5 b 31': {
        Question:
          'No check list pré operações de mergulho são testados os equipamentos de comunicação entre o supervisor de mergulho e os mergulhadores para em caso de avistamento de um peixe que possa por em risco a sua integridade física (Ex: Marlim, Barracuda, Tubarão, Peixe Pedra, Moreia, Raia, etc) retornar para local seguro (sino/sinete/cesta ou superfície)?',
        Answer: 'white',
      },
      
    
    },
    'CO-1 - Lesão ou alteração funcional, com potencial de sequela incapacitante e fatalidade': {
      '3-1 a 32': {
        Question:
          'Durante as operações de mergulho raso o supervisor de mergulho monitora o mergulhador no local de trabalho através do sistema de comunicação com a ajuda do Bellman?',
        Answer: 'white',
      },
      '3-1 b 33': {
        Question:
          'Os equipamentos de comunicação são checados antes e durante as operações de mergulho?',
        Answer: 'white',
      },
      '3-1 c 34': {
        Question:
          'Os equipamentos que fazem parte do sistema de monitoramento do mergulhador estão contemplados no plano de manutenção do sistema de mergulho?',
        Answer: 'white',
      },
      '3-1 d 35': {
        Question:
          'A Black box do sistema de monitoramento do mergulhador está de acordo com as normas vigentes com capacidade suficiente de armazenamento?',
        Answer: 'white',
      },
      '3-2 a 36': {
        Question:
          'Os supervisores de mergulho são capacitados e treinados no plano de resposta a emergência, abortar o mergulho em andamento, retornar a cesta, sinete ou a superfície com o auxílio de Bellman ou mergulhador de emergência?',
        Answer: 'white',
      },
      '3-2 b 37': {
        Question:
          'O plano de contingência prevê o passo a passo para abortar o mergulho e retornar a cesta, sinete ou a superfície com a ajuda do Bellman ou o mergulhador de emergência?',
        Answer: 'white',
      },
      '3-2 c 38': {
        Question:
          'A equipe de mergulho é conscientizada nos riscos inerentes a atividade que executam quando embarcados na frente/embarcação de mergulho?',
        Answer: 'white',
      },
      '3-2 d 39': {
        Question:
          'É realizado treinamento simulando um retorno emergencial em um tempo menor do que o habitual nas operações rotineiras? ',
        Answer: 'white',
      },
      '3-2 e 40': {
        Question:
          'É desenvolvida a importância e cultura de percepção de risco a fim de identificar riscos não contemplados em APR?',
        Answer: 'white',
      },
      '3-3 a 41': {
        Question:
          'São realizados simulados com ênfase no procedimento de resposta a emergências subaquáticas, no controle emocional dos mergulhadores e supervisores envolvidos nas operações de mergulho?',
        Answer: 'white',
      },
      '3-3 b 42': {
        Question:
          'Os supervisores e mergulhadores são capacitados e avaliados periodicamente em treinamentos para lidar com intercorrências durante as operações de mergulho através de simulados e demais exercícios práticos prevenindo que se desesperem ao avistarem peixes (Ex: Marlim, Barracuda, Tubarão, Peixe Pedra, Moreia, Raia, etc) evitando assim um contato direto com eles?',
        Answer: 'white',
      },
      '3-3 c 43': {
        Question:
          'Nos simulados de mergulho, os supervisores são orientados a focar na calma e no controle emocional dos mergulhadores para evitar que os mesmos entrem em desespero ao avistarem peixes (Ex: Marlim, Barracuda, Tubarão, Peixe Pedra, Moreia, Raia, etc) evitando assim um contato direto com eles?',
        Answer: 'white',
      },
      '3-3 d 44': {
        Question:
          'De forma geral, os supervisores têm o conhecimento do perfil dos membros de suas respectivas equipes, para em caso de uma intercorrência saber transmitir as orientações necessárias e manter a estabilidade emocional do mergulhador para que o mesmo não entre em desespero ao avistar peixes (Ex: Marlim, Barracuda, Tubarão, Peixe Pedra, Moreia, Raia, etc) evitando assim um contato direto com eles?',
        Answer: 'white',
      },
      '3-4 a 45': {
        Question:
          'Os supervisores são capacitados e treinados para orientar a realização do resgate do mergulhador pelo mergulhador de emergência?',
        Answer: 'white',
      },
      '3-4 b 46': {
        Question:
          'O mergulhador de emergência na superfície, permanece equipado e pronto para intervir no menor tempo possível sendo num simulado ou durante uma intercorrência real?',
        Answer: 'white',
      },
      '3-4 c 47': {
        Question:
          'Existe periodicidade para realização de simulado de resgate do mergulhador pelo mergulhador de emergência?',
        Answer: 'white',
      },

      '3-4 d 48': {
        Question:
          'A empresa controla através do seu departamento de segurança o treinamento frequente envolvendo toda a equipe no procedimento de resgate do mergulhador através do mergulhador de emergência?',
        Answer: 'white',
      },
      '3-4 e 49': {
        Question:
          'O resultado dos treinamentos de resgate e emergência da equipe de mergulho tem sido satisfatório?',
        Answer: 'white',
      },
      '3-5 a 50': {
        Question:
          'Existem, ao menos, dois membros na equipe de mergulho capacitados e treinados em suporte básico a vida no atendimento inicial a emergência subaquática atendendo as normas vigentes?',
        Answer: 'white',
      },
      '3-5 b 51': {
        Question:
          'É cobrado eficácia nos simulados dos planos de resposta a emergência com ênfase na comunicação adequada?',
        Answer: 'white',
      },
      '3-5 c 52': {
        Question:
          'O treinamento de reciclagem em atendimento a emergências subaquáticas de primeiros socorros contempla orientações de acordo com as normas vigentes? ',
        Answer: 'white',
      },
      '3-5 d 53': {
        Question:
          'São realizados simulados de primeiros socorros com as equipes de mergulho com ênfase na saúde dos mergulhadores?',
        Answer: 'white',
      },
      '3-6 a 54': {
        Question:
          'Há profissional de saúde de nível superior devidamente habilitado na frente/embarcação de mergulho?',
        Answer: 'white',
      },
      '3-6 b 55': {
        Question:
          'O profissional de saúde é capacitado e treinado em suporte avançado a vida e atendimento a emergências subaquática?',
        Answer: 'white',
      },
      '3-7 a 56': {
        Question:
          'A embarcação possui enfermaria que atenda aos requisitos da Normam-01, da NR-30 e demais legislações pertinentes? ',
        Answer: 'white',
      },
      '3-7 b 57': {
        Question:
          'A enfermaria possui quantidade de equipamentos e medicamentos que atende a lista de itens mínimos de enfermaria padronizada pela Petrobras para embarcações de mergulho? ',
        Answer: 'white',
      },
      '3-8 a 58': {
        Question:
          'Os meios de comunicação com o suporte médico em terra são adequados e tem backup?',
        Answer: 'white',
      },
      '3-8 b 59': {
        Question:
          'No procedimento de contingência está previsto o plano de resposta a emergência médica com os recursos de contato telefônico ou por vídeo?',
        Answer: 'white',
      },
      '3-8 c 60': {
        Question:
          'Existe plano de atendimento médico emergencial na empresa 24 horas para atender as equipes embarcadas no offshore?',
        Answer: 'white',
      },
      '3-9 a 61': {
        Question:
          'Existe procedimento específico para em caso de acidente com necessidade de remoção e transporte para hospital ou clínica em terra?',
        Answer: 'white',
      },
      '3-9 b 62': {
        Question:
          'Os supervisores estão aptos a orientar os responsáveis pelo transporte de acidentados via aérea, de que após a realização de mergulho, o voo deve cumprir um limite de altitude durante o voo em cumprimento da NORMAM-15?',
        Answer: 'white',
      },
      '3-9 c 63': {
        Question:
          'O Plano de contingência garante acesso a rede hospitalar especializada e a disponibilidade de recursos para um tratamento específico até o pronto reestabelecimento do acidentado?',
        Answer: 'white',
      },
      '3-9 d 64': {
        Question:
          'Existe procedimento da empresa que propicie a remoção do acidentado embarcado até a rede hospitalar?',
        Answer: 'white',
      },
      '3-9 e 65': {
        Question:
          'O RH da empresa presta suporte ao colaborador em caso de afastamento?',
        Answer: 'white',
      },
      '3-10 a 66': {
        Question:
          'O mergulhador acidentado tem suporte pelo RH no caso de afastamento e de retorno as atividades laborais?',
        Answer: 'white',
      },
      '3-10 b 67': {
        Question:
          'A empresa através do seu RH tem como procedimento a avaliação médica criteriosa da saúde do mergulhador para liberação das atividades laborais do acidentado?',
        Answer: 'white',
      },
    
    
      
    },

  };

  let listKeysQuestionExposicaoMergulhadorVidaMarinha=[
    {
      'AM-1 -  Presença de vida marinha passiva (Ex: águas vivas, corais, ostras, mariscos, ouriços, etc)': [
        { '1-1': { Check: [], Answer: 'white' } },
        { '1-2': { Check: [], Answer: 'white' } },
        { '1-3': { Check: [], Answer: 'white' } },
        { '1-4': { Check: [], Answer: 'white' } },
        { '1-5': { Check: [], Answer: 'white' } },
       
      ].reverse(),
      'AM-2 - Presença de peixes (Ex: Marlim, Barracuda, Tubarão, Peixe Pedra, Moreia, Raia, etc)': [
        { '2-1': { Check: [], Answer: 'white' } },
        { '2-2': { Check: [], Answer: 'white' } },
        { '2-3': { Check: [], Answer: 'white' } },
        { '2-4': { Check: [], Answer: 'white' } },
        { '2-5': { Check: [], Answer: 'white' } },
      
        
      ].reverse(),
      'CO-1 - Lesão ou alteração funcional, com potencial de sequela incapacitante e fatalidade': [
        { '3-1': { Check: [], Answer: 'white' } },
        { '3-2': { Check: [], Answer: 'white' } },
        { '3-3': { Check: [], Answer: 'white' } },
        { '3-4': { Check: [], Answer: 'white' } },
        { '3-5': { Check: [], Answer: 'white' } },
        { '3-6': { Check: [], Answer: 'white' } },
        { '3-7': { Check: [], Answer: 'white' } },
        { '3-8': { Check: [], Answer: 'white' } },
        { '3-9': { Check: [], Answer: 'white' } },
        { '3-10': { Check: [], Answer: 'white' } },
      ],
      
     

    }]
  
  
  export {TitlesAndQuestionsExposicaoMergulhadorVidaMarinha,listKeysQuestionExposicaoMergulhadorVidaMarinha,titleFormExposicaoMergulhadorVidaMarinha,titleHazardExposicaoMergulhadorVidaMarinha,SecondIdExposicaoMergulhadorVidaMarinha,listAMCOExposicaoMergulhadorVidaMarinha}
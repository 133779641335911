
const titleFormExposicaoInadequadaMergulhador="Exposição Inadequada do Mergulhador na Interface de Sistemas Com Diferencial de Pressão "
const titleHazardExposicaoInadequadaMergulhador="Diferencial de Pressão";
const SecondIdExposicaoInadequadaMergulhador="ExposicaoInadequadaMergulhador";
const listAMCOExposicaoInadequadaMergulhador=['AM-1-Erro humano (Falha do procedimento de mergulho)','CO-1- Lesão ou alteração funcional temporária'];
let TitlesAndQuestionsExposicaoInadequadaMergulhador = {
    'AM-1-Erro humano (Falha do procedimento de mergulho)': {
      '1-1 a 1': {
        Question:
          'Os supervisores e equipe são treinados e tem competência para planejar atividades tendo como base a PT, CLM, APR e DDS?',
        Answer: 'white',
      },
      '1-1 b 2': {
        Question:
          ' No planejamento das operações de mergulho em caixa de mar de uma unidade marítima, o isolamento do comando de abertura das válvulas é solicitado na PT ou CLM com evidências fotográficas?',
        Answer: 'white',
      },
      '1-1 c 3': {
        Question:
          ' É divulgado para toda a equipe a PT ou CLM no DDS do dia da operação em caixa de mar?',
        Answer: 'white',
      },
      '1-1 d 4': {
        Question:
          ' A equipe é familiarizada no processo de geração dos documentos que antecedem a qualquer operação de mergulho que contenha risco de diferencial de pressão?',
        Answer: 'white',
      },
      '1-1 e 5': {
        Question:
          ' Os supervisores estão treinados e atualizados de modo a orientar a sua equipe na formulação dos documentos (PT, CLM, APR, DDS) inerentes ao início das atividades de mergulho?',
        Answer: 'white',
      },
      '1-1 f 6': {
        Question:
          ' Os supervisores e a equipe de mergulho têm conhecimento dos riscos de sucção durante a operação de mergulho em caixa de mar?',
        Answer: 'white',
      },
      '1-1 g 7': {
        Question:
          ' Nas atividades envolvendo sucção e bombeamento o supervisor de mergulho participa do planejamento das operações através da elaboração de PT, CLM e DDS?',
        Answer: 'white',
      },
      '1-2 a 8': {
        Question: ' Os supervisores e equipe participam da elaboração de APR específica de caixa de mar de cada unidade, tendo em vista as peculiaridades de cada unidade marítima?',
        Answer: 'white',
      },
      '1-2 b 9': {
        Question:
          ' É divulgado no DDS do dia da operação para toda a equipe a APR específica de trabalho em caixa de mar, que engloba atividades que necessitem de alinhamento de válvulas para atividades simultâneas que estejam planejadas?',
        Answer: 'white',
      },
      '1-2 c 10': {
        Question:
          ' Na APR é especificado o risco de operação simultânea em caixa de mar (abertura e fechamento de válvula para inspeção e televisionamento de acionamento da válvula no interior da caixa de mar?',
        Answer: 'white',
      },
      '1-2 d 11': {
        Question:
          'A equipe de mergulho é treinada e capacitada a identificar riscos não contemplados em uma APR e participar de uma análise preliminar de risco referente a operações simultâneas? ',
        Answer: 'white',
      },
      '1-2 e 12': {
        Question:
          'A equipe de mergulho é treinada a identificar riscos diversos que possam pôr em perigo o mergulhador quando atuando em caixa de mar com operações simultâneas?',
        Answer: 'white',
      },
      '1-2 f 13': {
        Question:
          'O supervisor e a equipe de mergulho são treinados e qualificados para elaboração de APR?',
        Answer: 'white',
      },
      '1-2 g 14': {
        Question:
          'Os supervisores são treinados e têm competência para a elaboração de APR com diferencial de pressão e alinhamento de válvulas manuais e automáticas de sistemas de sucção/bombeamento?',
        Answer: 'white',
      },
      '1-3 a 15': {
        Question:
          'Os supervisores e a equipe de mergulho são treinados e orientados a confirmar na CLM, a presença das fotografias das válvulas da caixa de mar (fechadas) a ser trabalhada?',
        Answer: 'white',
      },
      '1-3 b 16': {
        Question:
          'É divulgado para a equipe em DDS a CLM com as fotos das válvulas (fechada) da caixa de mar a ser trabalhada?',
        Answer: 'white',
      },
      '1-3 c 17': {
        Question:
          'A equipe de mergulho tem conhecimento da importância do CLM quando do planejamento de uma atividade de mergulho?',
        Answer: 'white',
      },
      '1-3 d 18': {
        Question:
          'O supervisor e a equipe de mergulho são competentes para identificar falhas no planejamento das atividades através de PT, CLM e DDS?',
        Answer: 'white',
      },

      '1-3 e 19': {
        Question:
          'Existe treinamento para o supervisor de mergulho a fim de desenvolver a percepção de falhas no planejamento das atividades de mergulho durante a confecção de PT, CLM e DDS? ',
        Answer: 'white',
      },
      '1-3 f 20': {
        Question:
          'Os supervisores são treinados e tem competência para planejar as atividades e garantir que o procedimento de alinhamento das válvulas para atividade simultânea está contido na PT e CLM?',
        Answer: 'white',
      },
      '1-3 g 21': {
        Question:
          'É divulgado para toda a equipe a PT ou CLM no DDS do dia da operação em atividades que necessitem de alinhamento de válvulas para atividades simultâneas?',
        Answer: 'white',
      },
      '1-4 a 22': {
        Question:
          'Na CLM estão especificados os ramais telefônicos e o canal VHF que serão utilizados durante operações de mergulho em caixa de mar e são testados antes das operações entre o controle de mergulho e a UEP?',
        Answer: 'white',
      },
      '1-4 b 23': {
        Question:
          'Os supervisores são treinados e capacitados para garantir uma comunicação clara e objetiva entre o controle de mergulho e a UEP durante operação em caixa de mar?',
        Answer: 'white',
      },
      '1-4 c 24': {
        Question:
          'Existe plano de comunicação integrado entre a equipe de mergulho e a equipe de bordo da UEP?',
        Answer: 'white',
      },
      '1-4 d 25': {
        Question:
          'A equipe de mergulho é familiarizada com o plano de comunicação de bordo da UEP?',
        Answer: 'white',
      },
      '1-5 a 26': {
        Question:
          'Os supervisores são treinados e capacitados para garantir a cobrança na CLM ou na PT do desligamento e isolamento das válvulas de acionamento da caixa de mar em croqui ou fotografias?',
        Answer: 'white',
      },
      '1-5 b 27': {
        Question:
          'Os supervisores divulgam em DDS a CLM ou a PT com a confirmação em croqui ou fotografia das válvulas de acionamento (fechada) da caixa de mar?',
        Answer: 'white',
      },
      '1-6 a 28': {
        Question:
          'A CLM/PT de caixa de mar, é realizada com todos os envolvidos com as operações da UEP e da equipe de mergulho?',
        Answer: 'white',
      },
      '1-6 b 29': {
        Question:
          'A CLM/PT de operações de mergulho em caixa de mar é diária para garantir que todos os que participaram e assinaram a CLM/PT estejam cientes da operação?',
        Answer: 'white',
      },
      '1-6 c 30': {
        Question:
          'A equipe de mergulho é treinada e capacitada para identificar a validação de um documento pré-operação?',
        Answer: 'white',
      },
      '1-6 d 31': {
        Question:
          'São realizados simulados de primeiros socorros com as equipes de mergulho com ênfase nas atividades em caixa de mar das unidades marítimas visando a saúde dos mergulhadores? ',
        Answer: 'white',
      },
      '1-6 e 32': {
        Question:
          'A equipe de mergulho é treinada e orientada a participar assiduamente de todas as reuniões pré-operação?',
        Answer: 'white',
      },
      '1-6 f 33': {
        Question:
          'Os supervisores realizam DDS específico com toda a equipe no dia em que estão planejadas operações de mergulho que necessitem do alinhamento de válvulas manuais e automáticas de sistemas de sucção/bombeamento para a realização das operações de mergulho?',
        Answer: 'white',
      },
    },
    'AM-2-Falha no isolamento de linhas de vácuo (pressão negativa) / pressurizadas (pressão positiva) - ex- dutos, poços e acessórios overboard': {
      '2-1 a 34': {
        Question:
          'As equipes são orientadas no DDS sobre o isolamento das linhas com pressão negativa ou positiva na PT, CLL ou CLM antes de operações de mergulho?',
        Answer: 'white',
      },
      '2-1 b 35': {
        Question:
          'O supervisor e a equipe de mergulho são treinados e habilitados no planejamento de atividades utilizando as ferramentas PT, CLL, CLM, DDS? ',
        Answer: 'white',
      },
      '2-1 c 36': {
        Question:
          'A equipe de mergulho tem o conhecimento da importância do isolamento das linhas pressurizadas e com pressão negativas para a execução das tarefas submersas?',
        Answer: 'white',
      },
      '2-2 a 37': {
        Question:
          'Os supervisores e a equipe de mergulho são treinados e capacitados para planejar as operações de mergulho através da APR?',
        Answer: 'white',
      },
      '2-2 b 38': {
        Question:
          'As equipes são orientadas no DDS e na reunião pré-operação o planejamento das operações de mergulho quando em trabalhos em linhas com pressão negativa ou positiva?',
        Answer: 'white',
      },
      '2-2 c 39': {
        Question:
          'A equipe de mergulho está preparada para identificar um risco durante a atividade que não foi mapeada devidamente?',
        Answer: 'white',
      },
      '2-3 a 40': {
        Question:
          'Antes do início das operações de mergulho em overboard os supervisores checam e confirmam a equalização das pressões das linhas?',
        Answer: 'white',
      },
      '2-3 b 41': {
        Question:
          'O supervisor e sua equipe de mergulho são treinados e capacitados para o planejamento, têm familiarização com a atividade em dutos, linhas de alta pressão e domínio da ferramenta CLL?',
        Answer: 'white',
      },
      '2-4 a 42': {
        Question:
          'Na CLM/PT estão especificados os ramais telefônicos e o canal VHF que serão utilizados durante operações de mergulho com isolamento de linhas e são testados antes das operações entre o controle de mergulho e a UEP?',
        Answer: 'white',
      },
      '2-4 b 43': {
        Question:
          'Os supervisores são treinados e capacitados para garantir uma comunicação clara e objetiva entre o controle de mergulho e a UEP durante operações com linhas pressurizadas ou despressurizadas?',
        Answer: 'white',
      },
      '2-5 a 44': {
        Question:
          'Os supervisores têm competência para orientar os mergulhadores no posicionamento seguro e analisar através de recurso (extensão com fita na extremidade e ou cabo de nylon) nas linhas de vácuo (pressão negativa) / pressurizadas (pressão positiva) - ex- dutos, poços e acessórios overboard?',
        Answer: 'white',
      },
      '2-5 b 45': {
        Question:
          'O procedimento operacional de mergulho contempla o tipo de recurso material que o mergulhador deve utilizar para avaliar diferencial de pressão nas linhas de vácuo (pressão negativa) / pressurizadas (pressão positiva) - ex- dutos, poços e acessórios overboard?',
        Answer: 'white',
      },
      '2-6 a 46': {
        Question:
          'A CLM/PT de caixa de mar ou overboard, é realizada com todos os envolvidos nas operações da UEP e mergulho?',
        Answer: 'white',
      },
      '2-6 b 47': {
        Question:
          'A CLM/PT de operações de mergulho em caixa de mar é diária para garantir que todos os que participaram e assinaram a CLM/PT estejam cientes da operação?',
        Answer: 'white',
      },
      '2-6 c 48': {
        Question:
          'É procedimento da empresa que a equipe de mergulho tenha o compromisso de participar das reuniões pré-operação?',
        Answer: 'white',
      },
    },
    'AM-4-Mudança abrupta de diferencial de pressão entre compartimentos hiperbáricos': {
      '4-1 a 49': {
        Question:
          'Os supervisores são treinados e capacitados nos procedimentos da NORMAM-15 para pressurização e controle dos compartimentos da câmara hiperbárica?',
        Answer: 'white',
      },
      '4-1 b 50': {
        Question:
          'Os operadores de câmara estão habilitados para operar a câmara hiperbárica e treinados no procedimento da NORMAM-15 para manter um rigoroso controle das pressões nos compartimentos da câmara durante uma descompressão ou tratamento? ',
        Answer: 'white',
      },
      '4-2 a 51': {
        Question:
          'Os supervisores de mergulho orientam os mergulhadores para inspecionar, lubrificar e substituir os orings das escotilhas da câmara hiperbárica de acordo com o procedimento?',
        Answer: 'white',
      },
      '4-2 b 52': {
        Question:
          'O plano de manutenção da câmara hiperbárica contempla a substituição dos orings regularmente?',
        Answer: 'white',
      },
      '4-2 c 53': {
        Question:
          'No interior da câmara hiperbárica existem orings sobressalentes para uma necessidade imediata?',
        Answer: 'white',
      },
      '4-2 d 54': {
        Question: 'As equipes de mergulho são orientadas pelo suspervisor de mergulho de como utilizar as válvulas manuais no interiore da câmara hiperbárica quando necessário de acordo com o procedimento/ IT (instrução de trabalho)?',
        Answer: 'white',
      },
      '4-3 a 55': {
        Question: 'Todos os supervisores de mergulho e operadores de câmara hiperbárica têm conhecimento necessário para realizar o cheque list pré-pressurização?',
        Answer: 'white',
      },
      '4-3 b 56': {
        Question: 'O check list pré-pressurização é realizado de acordo com o procedimento adequado de pressurização das câmaras hiperbáricas?',
        Answer: 'white',
      },

    },
    'CO-1- Lesão ou alteração funcional temporária': {
      '5-1 a 57': {
        Question:
          'Existe plano de resposta a emergências da frente/embarcação de mergulho?',
        Answer: 'white',
      },
      '5-1 b 58': {
        Question:
          'Os supervisores de mergulho são capacitados e treinados para manter o controle mental e orientar os mergulhadores na água durante uma emergência na unidade ?',
        Answer: 'white',
      },
      '5-1 c 59': {
        Question:
          'Os mergulhadores são orientados pelos supervisores para em caso de alguma emergência a bordo da unidade, os mergulhadores retornarem o mais breve a unidade mantendo o controle físico e mental?',
        Answer: 'white',
      },

      '5-1 d 60': {
        Question:
          'A equipe de mergulho participa dos treinamentos de emergência das unidades que embarcam frequentemente?',
        Answer: 'white',
      },
      '5-1 e 61': {
        Question:
          'São realizados simulados com a equipe de mergulho em cenário de contingência?',
        Answer: 'white',
      },

      '5-2 a 62': {
        Question:
          'Durante as operações em caixa de mar o supervisor de mergulho monitora o mergulhador no local de trabalho através dos sistemas de TV e comunicação com a ajuda do Bellman?',
        Answer: 'white',
      },
      '5-2 b 63': {
        Question:
          'Os equipamentos de comunicação, áudio e TV são checados antes e durante as operações de mergulho? ',
        Answer: 'white',
      },
      '5-2 c 64': {
        Question:
          'Os equipamentos que fazem parte do sistema de monitoramento do mergulhador estão contemplados no plano de manutenção do sistema de mergulho?',
        Answer: 'white',
      },
      '5-2 d 65': {
        Question:
          'A Black box do sistema de monitoramento do mergulhador está de acordo com as normas vigentes e com capacidade suficiente de armazenamento?',
        Answer: 'white',
      },
      '5-2 e 66': {
        Question:
          'Os manômetros de profundidade são calibrados periodicamente conforme o requisito legal?',
        Answer: 'white',
      },
      '5-2 f 67': {
        Question:
          'Os mergulhadores são equipados com profundimetro de pulso?',
        Answer: 'white',
      },
      '5-3 a 68': {
        Question:
          'Os supervisores são capacitados e treinados para orientar a realização do resgate do mergulhador pelo mergulhador de emergência?',
        Answer: 'white',
      },


      '5-3 b 69': {
        Question:
          'O mergulhador de emergência na superfície, permanece equipado e pronto para intervir no menor tempo possível sendo num simulado ou durante uma intercorrência real?',
        Answer: 'white',
      },
      '5-3 c 70': {
        Question:
          'Existe periodicidade para realização de simulado de resgate do mergulhador pelo mergulhador de emergência?',
        Answer: 'white',
      },
      '5-3 d 71': {
        Question:
          'A empresa controla através do seu departamento de segurança o treinamento frequente envolvendo toda a equipe no procedimento de resgate do mergulhador através do mergulhador de emergência?',
        Answer: 'white',
      },
      '5-4 a 72': {
        Question:
          'Os supervisores de mergulho são capacitados e treinados no plano de resposta a emergência, abortar o mergulho em andamento, retornar a cesta, sinete ou a superfície com o auxílio de Bellman ou mergulhador de emergência?',
        Answer: 'white',
      },
      '5-4 b 73': {
        Question:
          'O plano de contingência prevê o passo a passo para abortar o mergulho e retornar a cesta, sinete ou ao a superfície com a ajuda do Bellman ou o mergulhador de emergência?',
        Answer: 'white',
      },
      '5-4 c 74': {
        Question:
          'A equipe de mergulho é conscientizada nos riscos inerentes a atividades que tenham perigo de sucção ou pressurização nas unidades marítimas? ',
        Answer: 'white',
      },
      '5-5 a 75': {
        Question:
          'Existem, ao menos, dois membros na equipe de mergulho capacitados e treinados suporte básico a vida no atendimento inicial a emergência subaquática atendendo as normas vigentes?',
        Answer: 'white',
      },
      '5-5 b 76': {
        Question:
          'É cobrado eficácia nos simulados dos planos de resposta a emergência com ênfase na comunicação adequada?',
        Answer: 'white',
      },
      '5-5 c 77': {
        Question:
          'O treinamento de reciclagem em atendimento a emergências de primeiros socorros atende as orientações de acordo com as normas vigentes?',
        Answer: 'white',
      },
      '5-5 d 78': {
        Question:
          'São realizados simulados de primeiros socorros com as equipes de mergulho com ênfase nas atividades em caixa de mar das unidades marítimas visando a saúde dos mergulhadores?',
        Answer: 'white',
      },
      '5-6 a 79': {
        Question:
          'Há profissional de saúde de nível superior devidamente habilitado na frente/embarcação de mergulho?',
        Answer: 'white',
      },
      '5-6 b 80': {
        Question:
          'O profissional de saúde é capacitado e treinado em suporte avançado de vida e atendimento a emergência subaquática?',
        Answer: 'white',
      },
      '5-7 a 81': {
        Question:
          'A frente/embarcação de mergulho possui enfermaria que atenda aos requisitos da NORMAM-01, da NR-30 e demais legislações pertinentes?',
        Answer: 'white',
      },
      '5-7 b 82': {
        Question:
          'A enfermaria possui quantidade de equipamentos e medicamentos que atende a lista de itens mínimos de enfermaria padronizada pela Petrobras para frente/embarcação de mergulho?',
        Answer: 'white',
      },
      '5-8 a 83': {
        Question:
          'Os meios de comunicação com o suporte médico em terra são adequados e tem backup?',
        Answer: 'white',
      },
      '5-8 b 84': {
        Question:
          'No procedimento de contingência está previsto o plano de resposta a emergência médica com os recursos de contato telefônico ou por vídeo?',
        Answer: 'white',
      },
      '5-8 c 85': {
        Question:
          'Existe plano de atendimento médico emergencial na empresa 24 horas para atender as equipes embarcadas no offshore?',
        Answer: 'white',
      },
      '5-9 a 86': {
        Question:
          'Existe procedimento específico para em caso de acidente com necessidade de remoção e transporte para hospital ou clínica em terra?',
        Answer: 'white',
      },
      '5-9 b 87': {
        Question:
          'Os supervisores estão aptos a orientar os responsáveis pelo transporte de acidentados via aérea, de que após a realização de mergulho, o voo deve cumprir um limite de altitude durante o voo em cumprimento da NORMAM-15?',
        Answer: 'white',
      },
      '5-9 c 88': {
        Question:
          'O Plano de contingência garante acesso a rede hospitalar especializada e a disponibilidade de recursos para um tratamento específico até o pronto reestabelecimento do acidentado?',
        Answer: 'white',
      },
      '5-9 d 89': {
        Question:
          'Existe procedimento da empresa que propicie a remoção do acidentado embarcado até a rede hospitalar?',
        Answer: 'white',
      },
      '5-9 e 90': {
        Question:
          'O RH da empresa está capacitado a proporcionar todo o acompanhamento ao acidentado desde a internação até o seu retorno as atividades?',
        Answer: 'white',
      },
      '5-10 a 91': {
        Question:
          'É realizada uma criteriosa avaliação médica especializada antes do retorno do mergulhador ao trabalho?',
        Answer: 'white',
      },
      '5-10 b 92': {
        Question:
          'O RH da empresa acompanha o processo de retorno do acidentado as suas atividades laborais após finalizado o tratamento?',
        Answer: 'white',
      },

    },

  };

  let listKeysQuestionExposicaoInadequadaMergulhador=[
    {
      'AM-1-Erro humano (Falha do procedimento de mergulho)': [
        { '1-1': { Check: [], Answer: 'white' } },
        { '1-2': { Check: [], Answer: 'white' } },
        { '1-3': { Check: [], Answer: 'white' } },
        { '1-4': { Check: [], Answer: 'white' } },
        { '1-5': { Check: [], Answer: 'white' } },
        { '1-6': { Check: [], Answer: 'white' } },
      ].reverse(),
      'AM-2-Falha no isolamento de linhas de vácuo (pressão negativa) / pressurizadas (pressão positiva) - ex- dutos, poços e acessórios overboard': [
        { '2-1': { Check: [], Answer: 'white' } },
        { '2-2': { Check: [], Answer: 'white' } },
        { '2-3': { Check: [], Answer: 'white' } },
        { '2-4': { Check: [], Answer: 'white' } },
        { '2-5': { Check: [], Answer: 'white' } },
        { '2-6': { Check: [], Answer: 'white' } },
      ].reverse(),
      'AM-4-Mudança abrupta de diferencial de pressão entre compartimentos hiperbáricos': [
        { '4-1': { Check: [], Answer: 'white' } },
        { '4-2': { Check: [], Answer: 'white' } },
        { '4-3': { Check: [], Answer: 'white' } },

      ].reverse(),
      'CO-1- Lesão ou alteração funcional temporária': [
        { '5-1': { Check: [], Answer: 'white' } },
        { '5-2': { Check: [], Answer: 'white' } },
        { '5-3': { Check: [], Answer: 'white' } },
        { '5-4': { Check: [], Answer: 'white' } },
        { '5-5': { Check: [], Answer: 'white' } },
        { '5-6': { Check: [], Answer: 'white' } },
        { '5-7': { Check: [], Answer: 'white' } },
        { '5-8': { Check: [], Answer: 'white' } },
        { '5-9': { Check: [], Answer: 'white' } },
        { '5-10': { Check: [], Answer: 'white' } },
      ],

    }]


  export {TitlesAndQuestionsExposicaoInadequadaMergulhador,listKeysQuestionExposicaoInadequadaMergulhador,titleFormExposicaoInadequadaMergulhador,titleHazardExposicaoInadequadaMergulhador,SecondIdExposicaoInadequadaMergulhador,listAMCOExposicaoInadequadaMergulhador}

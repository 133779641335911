import React, { useState, useEffect, useRef } from 'react';

import { useDispatch } from 'react-redux';
import Menu, { Item as MenuItem } from 'rc-menu';
/*
import { FaUserPlus, FaUsers } from 'react-icons/fa';
import { TiDocumentText } from 'react-icons/all';
*/
import { IoIosLogOut, IoIosKey } from 'react-icons/io';
/*
import { AiOutlineAudit } from 'react-icons/ai';
import { GoSearch } from 'react-icons/go';
*/
import { signOut } from '../../store/modules/auth/actions';
import { toggleMenu } from '../../store/modules/menu/actions';

import history from '../../services/history';

import { MenuBar } from './styles';

export default function MenuComponent() {
  // const { groupLevel } = useSelector(state => state.auth);
  const dispatch = useDispatch();

  const [menu, setMenu] = useState(false);
  /*
  const [isAdmin, setIsAdmin] = useState(false);
  const [isSudo, setIsSudo] = useState(false);
  const [isCompany, setIsCompany] = useState(false);
  const [isAuditor, setIsAuditor] = useState(false);
  const [isPetrobras, setIsPetrobras] = useState(false);

  useEffect(() => {
    const { admin, sudo, company, auditor, petrobras } = groupLevel;

    setIsSudo(sudo);
    setIsAdmin(admin);
    setIsCompany(company);
    setIsAuditor(auditor);
    setIsPetrobras(petrobras);
  }, [groupLevel]);
*/
  useEffect(() => {
    const menuFunctions = {
      /* RETIRADO POR VINÍCIUS NETO NO DIA 31/03/2020

      userAdd() {
        dispatch(toggleMenu());
        return history.push('/usuario/cadastrar');
      },

      userIndexAll() {
        dispatch(toggleMenu());
        return history.push('/usuarios');
      },

      alertAdd() {
        dispatch(toggleMenu());
        return history.push('/alertas/cadastrar');
      },

      lvChecklist() {
        dispatch(toggleMenu());
        return history.push('/auditoria/checklist');
      },

      companyHistoric() {
        dispatch(toggleMenu());
        return history.push('/empresa');
      },

      auditoria() {
        dispatch(toggleMenu());
        return history.push('/auditoria');
      },

      docPetrobras() {
        dispatch(toggleMenu());
        return history.push('/petrobras/doc');
      },

      docPetrobrasView() {
        dispatch(toggleMenu());
        return history.push('/petrobras');
      },
      */
      changePassword() {
        dispatch(toggleMenu());
        history.push('/recuperar-senha');
      },

      signOutAction() {
        dispatch(toggleMenu());
        dispatch(signOut());
      },
    };

    setMenu(menuFunctions);
  }, [dispatch]);

  function useOutsideAlerter(ref) {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        dispatch(toggleMenu());
      }
    }

    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    });
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <MenuBar ref={wrapperRef}>
      <Menu mode="inline">
        {/* RETIRADO POR VINÍCIUS NETO NO DIA 31/03/2020

        {isSudo || isAdmin ? (
          <SubMenu key="1" title="Usuários">
            <MenuItem key="1-1" onClick={() => menu.userAdd()}>
              <FaUserPlus />
              &emsp;Cadastrar
            </MenuItem>
            <MenuItem key="1-2" onClick={() => menu.userIndexAll()}>
              <FaUsers />
              &emsp;Ver todos
            </MenuItem>
          </SubMenu>
        ) : null}
        {isAdmin ? (
          <SubMenu key="2" title="Alertas">
            <MenuItem key="2-1" onClick={() => menu.alertAdd()}>
              <TiDocumentText />
              &emsp;Cadastrar
            </MenuItem>
          </SubMenu>
        ) : null}
        {isAdmin || isPetrobras || isSudo ? (
          <SubMenu key="3" title="Petrobras">
            {isAdmin ? (
              <MenuItem key="3-1" onClick={() => menu.docPetrobras()}>
                <TiDocumentText />
                &emsp;Cadastrar
              </MenuItem>
            ) : null}
            {isAdmin || isPetrobras || isSudo ? (
              <MenuItem key="3-2" onClick={() => menu.docPetrobrasView()}>
                <GoSearch />
                &emsp;Documentos
              </MenuItem>
            ) : null}
          </SubMenu>
        ) : null}
        {isAdmin || isCompany || isSudo ? (
          <MenuItem key="4" onClick={() => menu.lvChecklist()}>
            <TiDocumentText />
            &emsp;LV - Checklist
          </MenuItem>
        ) : null}
        {isCompany ? (
          <MenuItem key="5" onClick={() => menu.companyHistoric()}>
            <AiOutlineAudit />
            &emsp;Auditorias
          </MenuItem>
        ) : null}
        {isAuditor || isAdmin || isSudo ? (
          <MenuItem key="6" onClick={() => menu.auditoria()}>
            <AiOutlineAudit />
            &emsp;Auditoria
          </MenuItem>
        ) : null}
        */}

        <MenuItem key="7" onClick={() => menu.changePassword()}>
          <IoIosKey />
          &emsp;Alterar senha
        </MenuItem>
        <MenuItem key="8" onClick={() => menu.signOutAction()}>
          <IoIosLogOut />
          &emsp;Sair
        </MenuItem>
      </Menu>
    </MenuBar>
  );
}

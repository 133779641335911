/* eslint-disable prefer-const */
import { toast } from 'react-toastify';

export function validateFields(alertModel) {
  let {
    _id,
    title,
    description,
    accidentOrigin,
    accidentType,
    accidentDegree,
    local,
  } = alertModel;

  title = title.trim();
  description = description.trim();

  if (_id === '') {
    toast.error('Solicite um novo ID');
    return false;
  }

  if (
    title === '' ||
    description === '' ||
    local === '' ||
    accidentDegree === '' ||
    accidentType === '' ||
    accidentOrigin === ''
  ) {
    toast.error('Por favor, verifique os campos.');
    return false;
  }

  return true;
}

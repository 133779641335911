/* eslint-disable no-nested-ternary   */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AiOutlineForm } from 'react-icons/ai';
import { ContainerComponent } from '../../../components/Container';
import history from '../../../services/history';
import { Container, Content, DashboardItem } from '../../Dashboard/styles';

export default function Dashboard() {
  const { groupLevel } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const [dashboard, setDashboard] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isSudo, setIsSudo] = useState(false);
  const [isCompany, setIsCompany] = useState(false);
  const [isAuditor, setIsAuditor] = useState(false);
  const [isPetrobras, setIsPetrobras] = useState(false);
 
  
  
  useEffect(() => {
    const { admin, sudo, company, auditor,petrobras } = groupLevel;
    
    setIsSudo(sudo);
    setIsAdmin(admin);
    setIsCompany(company);
    setIsAuditor(auditor);
    setIsPetrobras(petrobras);
  }, [groupLevel]);

  useEffect(() => {
    const dashboardFunctions = {
      checklist1() {
        return history.push('/bowtie/checklist/botoes/checklist1');
      },
      checklist2() {
        return history.push('/bowtie/checklist/botoes/checklist2');
      },
      checklist3() {
        return history.push('/bowtie/checklist/botoes/checklist3');
      },
      checklist4() {
        return history.push('/bowtie/checklist/botoes/checklist4');
      }
    };

    setDashboard(dashboardFunctions);
  }, [dispatch]);

  
  
 
  

  return (
    // MENU CARDS
    <ContainerComponent>
      <Container>
        <Content>
          <ul>        
             {/* MENU CARD DIAGRAMA */}
            {(isAdmin || isCompany || isSudo || isAuditor || isPetrobras )   ? (
            <DashboardItem style={{display:localStorage.getItem('name')==='secret@key' || localStorage.getItem('name')==='auditortrue' ||  localStorage.getItem('name')==='petrobrastrue' || localStorage.getItem('name')==='OCEANICAeyJSb2xlIjoiQWRtaW4iLCJJc' ? '' :'none'}}>
              <button type="button" onClick={dashboard.checklist1}>
                <AiOutlineForm size={56} color="#fff" />
              </button>
              <span>OCEANICA</span>
            </DashboardItem>
             ) : null}
              {/* MENU CARD CHECKLIST */}
            {(isAdmin || isCompany || isSudo || isAuditor || isPetrobras  )   ? (
            <DashboardItem style={{display: localStorage.getItem('name')==='secret@key' || localStorage.getItem('name')==='auditortrue' ||  localStorage.getItem('name')==='petrobrastrue'|| localStorage.getItem('name')==='SISTACCJJc3N1ZXIiOiJJc3N1Z' ? '': 'none'}}>
              <button type="button" onClick={dashboard.checklist2}>
                <AiOutlineForm size={56} color="#fff" />
              </button>
              <span>SISTAC</span>
            </DashboardItem>
             ) : null}
              {/* MENU CARD CHECKLIST */}
            {(isAdmin || isCompany || isSudo || isAuditor || isPetrobras  )  ? (
            <DashboardItem style={{display: localStorage.getItem('name')==='secret@key' || localStorage.getItem('name')==='auditortrue' ||  localStorage.getItem('name')==='petrobrastrue' || localStorage.getItem('name')==='BELOVVybmFtZSI6IkphdmFJblVzZ' ? '':'none'}}>
              <button type="button" onClick={dashboard.checklist3}>
                <AiOutlineForm size={56} color="#fff" />
              </button>
              <span>BELOV</span>
            </DashboardItem>
             ) : null}
              {/* MENU CARD CHECKLIST */}
            {(isAdmin || isCompany || isSudo || isAuditor || isPetrobras )  ? (
            <DashboardItem style={{display:localStorage.getItem('name')==='secret@key' || localStorage.getItem('name')==='auditortrue' ||  localStorage.getItem('name')==='petrobrastrue' || localStorage.getItem('name')==='CONTINENTALZSIsImV4cCI6MTY0OTIw' ? '':'none'}}>
              <button type="button" onClick={dashboard.checklist4}>
                <AiOutlineForm size={56} color="#fff" />
              </button>
              <span>CONTINENTAL</span>
            </DashboardItem>
             ) : null}
              
          </ul>
        </Content>
      </Container>
    </ContainerComponent>
  );
}

import styled from 'styled-components';
import { lighten, darken } from 'polished';

export const DivGrid = styled.div`
  height: 1384px;

`;

export const MainPicture = styled.div`
  display: flex;
  width: 1109px;
  height: 512px;
  margin: 32px auto;
  position: absolute;
  z-index: 0;
  padding: 12px;
  background: #fbfbfb;
  border-radius: 5px;

  div {
    border-radius: 5px;
  }

  img {
    border-radius: 5px;
    width: 1085px;
    height: 488px;
  }

  @media(max-width:768px){
    width:100%;
    display:flex;
    flex-direction:column;

    .carousel-root{
      display:none;
    }
  }


`;

export const DivTitleSlide = styled.div`
  width: 335px;
  height: 488px;
  margin-right: 12px;
  background: #fbfbfb;
 /*  background: ${lighten(0.6, '#00529b')}; */
  opacity: 0.85;
  position: absolute;
  z-index: 1;
  right: 0;

  h2 {
    display: flex;

    font-size: 3.6rem;
    margin: 72px 96px 32px ;
    color: ${darken(0.04, '#00529b')};
    font-weight: bold;
  }

  h3 {
    font-size: 2.4rem;
    margin: 12px 24px 32px;
    font-weight: bold;
    color: ${darken(0.04, '#b56a1e')};

  }

  p {
    font-size: 1.67rem;
    line-height: 25px;
    font-weight: bold;
    margin: 0 24px 64px 24px;
    color: ${darken(0.04, '#00529b')};
    text-align: left;

  }
`;

export const SecondPosts = styled.div`
  width: 1109px;
  height: 550px;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 800px;

  @media (max-width: 768px) {
    margin-bottom:50px;
    flex-direction:column;
  }
`;

export const PostCard = styled.div`
  width: 360px;
  height: 550px;
  display: flex;
  flex-direction: column;
  background: #fbfbfb;
  border-radius: 5px;
  position: relative;

  &:hover {
    opacity: 0.95;
  }

  img {
    border-radius: 5px;
    width: 349px;
    height: 222px;
    padding: 12px 0 0 12px;

    &:hover{
      -webkit-transform: scale(1.1);
	transform: scale(1.1);
  transition:0.4s;
  cursor:pointer;
    }
  }

  h3 {
    font-size: 2.4rem;
    color: #00529b;
    margin: 16px 0 4px 0;
    padding: 0 12px 0 12px;
    text-align: center;
  }

  p {
    margin-top: 12px;
    font-size: 1.5rem;
    line-height: 23px;
    text-align: justify;
    padding: 0 12px 0 12px;
  }

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 96px;
    height: 32px;
    margin-top: 8px;
    font-size: 1.5rem;
    color: #051367;
    font-weight:bold;
    text-decoration:none;
    border-radius: 10px;
    position: absolute;
    bottom: 16px;
    left: 132px;

    &:hover{
      font-size:17px;
      transition:0.4s;
    }
  }


  @media (max-width: 768px) {
    margin-bottom:52px;
    padding-left:12px;
    a{
      padding-top:100px;
      bottom:-10px;
    }

    img{
      padding:0px;
    }

  }
`;

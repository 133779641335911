/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-restricted-syntax */
import React, { useState } from 'react';

import { toast } from 'react-toastify';
import { FaPlus, FaFilePdf, FaFileImage, FaShare } from 'react-icons/fa';
import { BubbleLoader } from 'react-css-loaders';

import api from '../../services/api';

import { ContainerComponent } from '../../components/Container';

import {
  DivColumn1,
  DivColumn2,
  DivNewAlert,
  DivGrid,
  DivBox1,
  DivBox2,
  DivBox3,
  PrivateInput,
  DivOutput,
  UploadButton,
  Register,
  LoadingSearch,
} from './styles';
import { validateFields } from './util';

export default function AddAlert() {
  document.title = 'Adicionar Alerta';

  const [alertId, setAlertId] = useState('');

  // TextFields States------------------------------------
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [date, setDate] = useState(new Date());
  const [local, setLocal] = useState('');
  const [ref, setRef] = useState('');
  const [keyWord, setKeyWord] = useState('');
  const [accidentOrigin, setAccidentOrigin] = useState('');
  const [accidentType, setAccidentType] = useState('');
  const [accidentDegree, setAccidentDegree] = useState('');
  const [isPrivate, setIsPrivate] = useState(false);

  // TextArea States ----------------------------------
  const [causes, setCauses] = useState([]);
  const [causeField, setCauseField] = useState('');

  const [lessonsLearned, setLessonsLearned] = useState([]);
  const [lessonsLearnedField, setLessonsLearnedField] = useState('');

  const [loading, setLoading] = useState(false);

  // **** Handle Functions
  function handleGetAlertId() {
    api.get('/alert/new').then(
      response => {
        setAlertId(response.data.id);
      },
      () => {
        toast.error('Erro ao obter ID');
      }
    );
  }

  // Handle TextArea Functions --------------------------
  function handleAddCause() {
    setCauses([...causes, causeField]);
    setCauseField('');
  }

  function handleAddLessonsLearned() {
    setLessonsLearned([...lessonsLearned, lessonsLearnedField]);
    setLessonsLearnedField('');
  }

  // Handle Upload Functions
  const handleUploadPdf = event => {
    const data = new FormData();
    data.append('file', event.target.files[0]);

    api.post(`/alert/files/pdf/${alertId}`, data).then(
      () => {
        toast.success('Sucesso');
      },
      () => {
        toast.error('Erro ao fazer upload do PDF');
      }
    );
  };

  const handleUploadImages = event => {
    const data = new FormData();

    for (const img of event.target.files) {
      data.append('file', img);
    }

    api.post(`/alert/files/image/${alertId}`, data).then(
      () => {
        toast.success('Sucesso');
      },
      () => {
        toast.error('Erro ao fazer upload de imagem');
      }
    );
  };

  function handleSubmit() {
    const alertModel = {
      _id: alertId,
      title,
      description,
      date,
      local,
      ref,
      keyWord,
      accidentOrigin,
      causes,
      causeField,
      lessonsLearned,
      lessonsLearnedField,
      accidentType,
      accidentDegree,
      isPrivate,
    };

    const formValid = validateFields(alertModel);

    try {
      setLoading(true);
      if (formValid) {
        api.post('/alert/add', alertModel).then(
          () => {
            setLoading(false);
            toast.success('Sucesso');
            window.location.reload();
          },
          () => {
            setLoading(false);
            toast.error('Erro ao cadastrar alerta');
          }
        );
      }
    } catch (error) {
      setLoading(false);
      toast.error('Erro ao cadastrar alerta');
    }
  }

  return (
    <ContainerComponent>
      <DivGrid>
        <DivColumn1>
          <DivNewAlert>
            <button type="button" onClick={handleGetAlertId}>
              Novo Cadastro
            </button>

            <div>
              <label htmlFor="alertId">ID</label>
              <input type="text" value={alertId} id="alertId" />
            </div>
          </DivNewAlert>

          <DivBox1>
            <label htmlFor="alertTitle">Titulo</label>
            <input
              name="alertTitle"
              type="text"
              id="alertTitle"
              onChange={e => setTitle(e.target.value)}
            />
          </DivBox1>

          <DivBox3>
            <label htmlFor="alertDescription">Descrição</label>
            <textarea
              name="alertDescription"
              type="text"
              id="alertDescription"
              onChange={e => setDescription(e.target.value)}
              rows="8"
            />
          </DivBox3>

          <DivBox2>
            <label htmlFor="alertCause">Causas</label>
            <textarea
              id="alertCause"
              type="text"
              value={causeField}
              onChange={e => setCauseField(e.target.value)}
              rows="4"
            />

            <button type="button" onClick={handleAddCause}>
              <FaPlus />
            </button>
          </DivBox2>

          <DivBox2>
            <label htmlFor="alertLessonsLearned">Lições</label>
            <textarea
              id="alertLessonsLearned"
              type="text"
              value={lessonsLearnedField}
              onChange={e => setLessonsLearnedField(e.target.value)}
              rows="4"
            />

            <button type="button" onClick={handleAddLessonsLearned}>
              <FaPlus />
            </button>
          </DivBox2>

          <DivBox1>
            <label htmlFor="alertKeyWord">Palavras-Chave</label>
            <input
              name="alertKeyWord"
              type="text"
              id="alertKeyWord"
              placeholder="Separe por ;"
              size="30"
              onChange={e => setKeyWord(e.target.value)}
            />
          </DivBox1>
        </DivColumn1>

        <DivColumn2>
          <DivBox1>
            <label htmlFor="alertDate">Data</label>
            <input
              name="alertDate"
              type="date"
              id="alertDate"
              size="30"
              onChange={e => setDate(e.target.value)}
            />
          </DivBox1>

          <DivBox1>
            <label htmlFor="alertRef">Referencia</label>
            <input
              name="alertRef"
              type="text"
              id="alertRef"
              size="30"
              onChange={e => setRef(e.target.value)}
            />
          </DivBox1>

          <DivBox1>
            <label htmlFor="alertLocal">Local</label>
            <select
              name="alertLocal"
              id="alertLocal"
              onChange={e => setLocal(e.target.value)}
            >
              <option value=" "> </option>
              <option value="NIF">Não Informado</option>
              <option value="AFR">África</option>
              <option value="ATN">Atlântico Norte</option>
              <option value="ATS">Atlântico Sul</option>
              <option value="BDC">Bacia de Campos</option>
              <option value="USA">Estados Unidos</option>
              <option value="GFM">Golfo do México</option>
              <option value="SAS">Sudeste Asiático</option>
              <option value="ORM">Oriente Médio</option>
              <option value="PAT">Patagônia</option>
              <option value="MDN">Mar do Norte</option>
              <option value="NOB">Nordeste Brasileiro</option>
              <option value="BRA">Demais regiões Brasileiras</option>
            </select>
          </DivBox1>

          <DivBox1>
            <label htmlFor="accidentDegree">Grau do Acidente</label>
            <select
              name="accidentDegree"
              id="accidentDegree"
              onChange={e => setAccidentDegree(e.target.value)}
            >
              <option value=" "> </option>
              <option value="I">Incidente</option>
              <option value="AF">Acidente com Ferimento</option>
              <option value="F">Acidente Fatal</option>
            </select>
          </DivBox1>

          <DivBox1>
            <label htmlFor="accidentType">Tipo do Acidente</label>
            <select
              name="accidentType"
              id="accidentType"
              onChange={e => setAccidentType(e.target.value)}
            >
              <option value=" "> </option>
              <option value="FH">Falha Humana</option>
              <option value="FE">Falha de Equipamento</option>
              <option value="FH-FE">FH / FE</option>
            </select>
          </DivBox1>

          <DivBox1>
            <label htmlFor="accidentOrigin">Origem do Acidente</label>
            <select
              name="accidentOrigin"
              id="accidentOrigin"
              onChange={e => setAccidentOrigin(e.target.value)}
            >
              <option value=" "> </option>
              <option value="N">Nacional</option>
              <option value="I">Internacional</option>
            </select>
          </DivBox1>

          <DivBox1>
            <PrivateInput>
              <label htmlFor="alertRadioPrivate">Privado</label>
              <input
                name="alertRadioPrivate"
                type="checkbox"
                id="alertRadioPrivate"
                onChange={e => setIsPrivate(e.target.value)}
                value="privateAlert"
              />
            </PrivateInput>
          </DivBox1>

          <DivOutput>
            <UploadButton>
              <label htmlFor="file">
                <FaFilePdf />
                Anexar PDF
                <input
                  type="file"
                  accept=".pdf"
                  onChange={handleUploadPdf}
                  id="file"
                />
              </label>

              <label htmlFor="files">
                <FaFileImage />
                Anexar Imagens
                <input
                  type="file"
                  multiple
                  onChange={handleUploadImages}
                  id="files"
                />
              </label>
            </UploadButton>

            <Register>
              {!loading ? (
                <button
                  type="button"
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  <FaShare />
                  Cadastrar
                </button>
              ) : (
                <LoadingSearch>
                  <BubbleLoader size={3} />
                </LoadingSearch>
              )}
            </Register>
          </DivOutput>
        </DivColumn2>
      </DivGrid>
    </ContainerComponent>
  );
}

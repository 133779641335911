import React from 'react';
import { Main } from './style';

export default function DashboardExcel() {
  return (
    <Main>
      <iframe title='planilha' width="100%" height="700" frameborder="0" scrolling="no" src="https://petrobrasbr.sharepoint.com/teams/PEODIVE/_layouts/15/Doc.aspx?sourcedoc={12a9e8ab-8651-4cf4-9be3-11341f0c96fe}&action=embedview&wdHideGridlines=True&wdHideHeaders=True&wdInConfigurator=True&wdInConfigurator=True"></iframe>
    </Main>
  );
}

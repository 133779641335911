import styled from 'styled-components';
import { lighten } from 'polished';

export const DivRegister = styled.div`
  width: 602px;
  height: 659px;
  border: solid 2px #00529b;
  margin: 0 auto;
  border-radius: 5px;
  background: #e8eaef;
  display: flex;
  position: relative;

  svg {
    width: 48px;
    height: 48px;
    position: absolute;
    margin-top: 32px;
    margin-left: 256px;
    color: #00529b;

    &:hover {
      color: ${lighten(0.05, '#00529b')};
    }
  }

  h2 {
    font-size: 3.2rem;
    color: #00529b;
    margin-top: 86px;
    margin-left: 186px;
    height: 33px;
    position: relative;

    &:hover {
      color: ${lighten(0.05, '#00529b')};
    }
  }
`;

export const DivInputs = styled.div`
  width: 335px;
  height: 500px;
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 126px;
  top: 172px;

  label {
    color: #00529b;
    font-weight: bold;
    margin-top: 32px;
    margin-bottom: 4px;

    &:hover {
      color: ${lighten(0.075, '#00529b')};
    }
  }

  input {
    width: 254px;
    height: 33px;
    border-radius: 5px;
    border: solid 1px #eee;
    margin-left: 12px;
    padding: 3px 0 0 8px;
  }

  select {
    width: 254px;
    height: 33px;
    border-radius: 5px;
    border: solid 1px #eee;
    margin-right: 36px;
    margin-top: 36px;
    background: #fff;
  }
`;

export const DivLabels = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SubmitButton = styled.button.attrs(() => ({}))`
  width: 254px;
  height: 33px;
  background: #00529b;
  font-weight: bold;
  color: #fff;
  border: 0;
  border-radius: 4px;
  font-size: 16px;
  transition: background 0.2s;
  margin-top: 32px;
  margin-left: 51px;

  &:hover {
    background: ${lighten(0.03, '#00529b')};
  }

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.6;
  }
`;

/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { FaUserAlt, FaUserCircle } from 'react-icons/fa';

import { toggleMenu } from '../../store/modules/menu/actions';

import MenuComponent from '../Menu';
import Notifications from '../Notifications';

import { Header, Content, DivMenuAccess, DivMenu, UserArea } from './styles';
import Logo from '../../assets/peodive-logo.svg';

export function HeaderComponentUnsigned() {
  return (
    <Header>
      <Content>
        <Link to="/">
          <img src={Logo} alt="#" />
        </Link>
        <nav>
          <Link to="/">Início</Link>
          <span>|</span>
          <Link to="/quem-somos">Quem Somos</Link>
          <span>|</span>
          <Link to="/nossas-atividades">Nossas Atividades</Link>
          <span>|</span>
          <Link to="/fale-conosco">Fale Conosco</Link>
        </nav>

        <UserArea>
          <Link to="/login">
            <FaUserAlt />
            <span>Login</span>
          </Link>
        </UserArea>
      </Content>
    </Header>
  );
}

export function HeaderComponentSigned() {
  const dispatch = useDispatch();
  const { name, groupLevel } = useSelector(state => state.auth);
  const { show } = useSelector(state => state.menu);

  // const [toggleMenu, setToggleMenu] = useState(false);
  const [formattedName, setFormattedName] = useState(false);

  useEffect(() => {
    if (name) {
      const nameSplited = name.split(' ');
      setFormattedName(nameSplited[0]);
    }
  }, [name]);

  function showHideMenu() {
    dispatch(toggleMenu());
    // toggleMenu ? setToggleMenu(false) : setToggleMenu(true);
  }
  return (
    <Header>
      <Content>
        <Link to="/">
          <img src={Logo} alt="#" />
        </Link>

        {groupLevel.admin ? <Notifications /> : null}

        <nav>
          <Link to="/">Início</Link>
          <span>|</span>
          <Link to="/quem-somos">Quem Somos</Link>
          <span>|</span>
          <Link to="/nossas-atividades">Nossas Atividades</Link>
          <span>|</span>
          <Link to="/fale-conosco">Fale Conosco</Link>
          {/* MODIFICADO POR VINÍCIUS NETO NO DIA 31/03/2020 */}
          <span>|</span>
          <Link to="/dashboard">Serviços</Link>
        </nav>

        {show ? (
          <DivMenu>
            <MenuComponent />
          </DivMenu>
        ) : null}

        <DivMenuAccess>
          <UserArea>
            <Link to="#" onClick={showHideMenu}>
              <FaUserCircle />
              {formattedName}
            </Link>
          </UserArea>
        </DivMenuAccess>
      </Content>
    </Header>
  );
}


const titleFormFaltaRespiratorio="Falta de Suprimento Respiratório ou de Água Quente"
const titleHazardFaltaRespiratorio="Ambiente Subaquático";
const SecondIdFaltaRespiratorio="Respiratorio";
const listAMCOFaltaRespiratorio=['AM 1-Erro Humano','CO 1-Doença descompressiva'];
let TitlesAndQuestionsFaltaRespiratorio = {
    'AM 1-Erro Humano': {
      '1-1 a 1': {
        Question:
          'Existe sinalização clara e inequívoca da posição das válvulas (aberta ou fechada)?',
        Answer: 'white',
      },
      '1-1 b 2': {
        Question:
          'Existe mecanismo de proteção que evite o acionamento acidental dos manetes das válvulas em painéis de operação de mergulho e equipamentos?',
        Answer: 'white',
      },
      '1-2 a 3': {
        Question: 'O painel de controle de mergulho diferencia o suprimento de cada mergulhador por cores (uma cor para cada mergulhador)?',
        Answer: 'white',
      },
      '1-2 b 4': {
        Question:
          'A instrução de trabalho contempla a identificação por cores do painel de controle de mergulho?',
        Answer: 'white',
      },
      '1-2 c 5': {
        Question:
          'Os supervisores possuem treinamento em instrução de trabalho (IT) para operar o painel de controle e manifolds?',
        Answer: 'white',
      },
      '1-2 d 6': {
        Question:
          'Existe evidência de treinamento em instrução de trabalho (IT) para operar o painel de controle e manifolds? ',
        Answer: 'white',
      },
      '1-2 e 7': {
        Question:
          'Os supervisores de mergulho são treinados e tem competência para operar o painel de controle de mergulho baseado nos procedimentos e instruções de trabalho?',
        Answer: 'white',
      },
      '1-3 a 8': {
        Question:
          'Existe aviso fixado na entrada da porta do controle de mergulho informando “ acesso restrito, somente pessoas autorizadas”?',
        Answer: 'white',
      },
      '1-3 b 9': {
        Question:
          'Caso o controle de mergulho seja portátil, este está localizado em área que não seja de livre passagem e movimentação de pessoas (área restrita)?',
        Answer: 'white',
      },
      '1-3 c 10': {
        Question:
          'É realizado treinamentos regulares e comunicado em DDS, a proibição de acesso ao controle de mergulho por pessoa não autorizada?',
        Answer: 'white',
      },
      '1-4 a 11': {
        Question:
          'O check list pré mergulho contempla todos os equipamentos relacionados ao suprimento de ar (teste do suprimento de ar de emergência, teste de suprimento principal, teste da válvula de não retorno do capacete)?',
        Answer: 'white',
      },

      '1-4 b 12': {
        Question:
          'O check list pré mergulho está sendo realizado pelo supervisor de mergulho? ',
        Answer: 'white',
      },
      '1-4 c 13': {
        Question:
          'São realizados treinamentos regulares para os supervisores de mergulho visando a importância da realização do check list pré mergulho de forma integral?',
        Answer: 'white',
      },
      '1-5 a 14': {
        Question:
          'Na matriz de competência/responsabilidades, contempla a operação do sistema de suprimento de ar do mergulhador pelo supervisor?',
        Answer: 'white',
      },
      '1-5 b 15': {
        Question:
          'É avaliada a liderança do supervisor na matriz na de competência/responsabilidade?',
        Answer: 'white',
      },
      '1-6 a 16': {
        Question:
          'É respeitada a escala de turno para garantir o repouso da equipe?',
        Answer: 'white',
      },
      '1-6 b 17': {
        Question:
          'É realizado check list pré mergulho para verificação da condição física e psicológica do mergulhador?',
        Answer: 'white',
      },
      '1-6 c 18': {
        Question:
          'As acomodações possuem estrutura adequada para o pleno descanso da equipe (isolamento acústico)?',
        Answer: 'white',
      },
      '1-6 d 19': {
        Question:
          'Quando da existência de trabalhos noturnos, é considerado a suscetibilidade dos mergulhadores na divisão da equipe que vai trabalhar no período noturno?',
        Answer: 'white',
      },
      '1-6 e 20': {
        Question:
          'Nas reuniões ou DDS, é divulgado para as equipes de mergulho, a importância de um repouso suficiente para restaurar as energias?',
        Answer: 'white',
      },
      '1-6 f 21': {
        Question:
          'Os intervalos entre mergulhos, são respeitados, seguindo as normas (NORMAM 15) e procedimentos da empresa?',
        Answer: 'white',
      },
      '1-6 g 22': {
        Question:
          'O estilo de vida saudável (atividades físicas e alimentação) consta em boas praticas e é divulgado em DDS?',
        Answer: 'white',
      },
      '1-6 h 23': {
        Question:
          'O programa de promoção a saúde mental é divulgado para a força de trabalho?',
        Answer: 'white',
      },
      '1-6 i 24': {
        Question:
          'São realizados exames antidoping para substâncias ilícitas nas equipes de mergulho? ',
        Answer: 'white',
      },
    },
    'AM2-Falha de equipamento de mergulho (compressor, quadro de cilindros, mangueira, capacete, bail out)': {
      '2-1 a 25': {
        Question:
          'A equipe técnica possui treinamento, qualificação e atualização em relação as regras normativas para utilização dos backups do sistema de fornecimento de ar do sistema de mergulho?',
        Answer: 'white',
      },
      '2-1 b 26': {
        Question:
          'Existe matriz de responsabilidade aplicada ao descumprimento das regras operacionais de mergulho?',
        Answer: 'white',
      },
      '2-2 a 27': {
        Question:
          'Existe um processo/procedimento para a qualificação de seus fornecedores de equipamentos críticos implementado com critérios bem definidos?',
        Answer: 'white',
      },
      '2-2 b 28': {
        Question:
          'Os fornecedores de compressores, quadros de cilindros, mangueiras, capacetes, bail out estão contemplados?',
        Answer: 'white',
      },
      '2-3 a 29': {
        Question:
          'Existe procedimento para o monitoramento sistemático de cumprimento do plano de inspeção e manutenção?',
        Answer: 'white',
      },
      '2-3 b 30': {
        Question:
          'O plano de manutenção contempla todos os equipamentos do sistema de suprimento de ar (compressores, quadros de cilindros, mangueiras, capacetes, bail out)?',
        Answer: 'white',
      },
      '2-3 c 31': {
        Question:
          'O plano de manutenção está sendo cumprido em relação a sua periodicidade?',
        Answer: 'white',
      },
      '2-3 d 32': {
        Question:
          'As manutenções estão sendo realizadas por um técnico de manutenção capacitado?',
        Answer: 'white',
      },
      '2-3 e 33': {
        Question:
          'Existe controle de sobressalentes para os equipamentos do sistema de suprimento de ar (compressores, quadros de cilindros, mangueiras, capacetes, bail out) para garantir a realização das manutenções dentro dos prazos estabelecidos?',
        Answer: 'white',
      },
      '2-4 a 34': {
        Question:
          'É realizado anualmente testes do sistema de suprimento de ar junto a certifcadora?',
        Answer: 'white',
      },
      '2-4 b 35': {
        Question:
          'O procedimento de manutenção contempla apenas a utilização de peças recomendadas pelo fabricante?',
        Answer: 'white',
      },
      '2-4 c 37': {
        Question:
          'É verificado o serial number dos spare para garantir a originalidade das peças?',
        Answer: 'white',
      },
      '2-5 a 38': {
        Question:
          'O sistema de mergulho possui caixa metálica ou container para armazenamento dos equipamentos de mergulho?',
        Answer: 'white',
      },
      '2-5 b 39': {
        Question:
          'Durante a visita técnica foi definido local seguro para o posicionamento dos equipamentos?',
        Answer: 'white',
      },
      '2-5 c 40': {
        Question:
          'É realizada visita técnica periódica para avaliar a operacionalidade dos equipamentos que permaneceram a bordo fora de operação por tempo indeterminado?',
        Answer: 'white',
      },
      '2-6 a 41': {
        Question:
          'O check list pré mergulho contempla a funcionalidade dos compressores, quadros de cilindros, mangueiras, capacetes, bail out?',
        Answer: 'white',
      },
      '2-6 b 42': {
        Question:
          'O check list pré mergulho está sendo realizado pelo supervisor de mergulho?',
        Answer: 'white',
      },
      '2-7 a 43': {
        Question:
          'A planilha de controle de neckdan contempla toda a equipe de mergulho com seus respectivos tamanhos de neckdan?',
        Answer: 'white',
      },
      '2-7 b 44': {
        Question:
          'É realizado o treinamento periódico da equipe de mergulho visando a correta equipagem?',
        Answer: 'white',
      },
    },
    'AM 3-Esmagamento, torção, tensão e ruptura do umbilical': {
      '3-1 a 45': {
        Question:
          'Existe a orientação no plano de manutenção da inspeção visual durante o lançamento e recolhimento do umbilical quando em operação?',
        Answer: 'white',
      },
      '3-1 b 46': {
        Question:
          'É documentado em checklist antes e pós mergulho o estado físico do umbilical?',
        Answer: 'white',
      },
      '3-2 a 47': {
        Question:
          'Existe a orientação referente a avaliação das condições meteorológicas para a área de trabalho incluindo a avaliação da direção e intensidade da correnteza?',
        Answer: 'white',
      },
      '3-2 b 48': {
        Question:
          'Existe a orientação para o monitoramento dos umbilicais, mangueiras de suprimento e cabos sendo avaliados em relação ao local, de acordo com as condições meteorológicas no momento da execução da atividade?',
        Answer: 'white',
      },
      '3-3 a 49': {
        Question:
          'Existe reunião pré - operacional com toda a equipe inclusive orientando sobre a atenção no manuseio do umbilical do mergulhador a fim de não deixar o mesmo muito folgado?',
        Answer: 'white',
      },
      '3-3 b 50': {
        Question:
          'A equipe é orientada no comprometimento do manuseio dos umbilicais a fim de evitar folga em demasia?',
        Answer: 'white',
      },
      '3-3 c 51': {
        Question:
          'Os procedimentos operacionais e instruções de trabalho, contemplam os treinamentos e competências no manuseio de umbilicais?',
        Answer: 'white',
      },
      '3-4 a 52': {
        Question:
          'Existe meios que garantam a visualização adequada do acesso do mergulhador até o local do trabalho para que seja realizada a análise de risco adequada?',
        Answer: 'white',
      },
      '3-4 b 53': {
        Question:
          'Existe meios que garantam a visualização adequada do acesso do mergulhador até o local do trabalho?',
        Answer: 'white',
      },
      '3-4 c 54': {
        Question:
          'Durante a atividade de mergulho o supervisor monitora periodicamente o posicionamento seguro do umbilical através do mergulhador? ',
        Answer: 'white',
      },
      '3-5 a 55': {
        Question:
          'Durante o mergulho é verificado o comportamento do umbilical do mergulhador visando manter o mesmo o mais próximo possível da neutralidade?',
      },
      '3-5 b 56': {
        Question:
          'Consta em procedimento de operação de mergulho e no check list a verificação e ajuste da neutralidade do umbilical do mergulhador afim de evitar fique preso?',
      },
      '3-5 c 57': {
        Question:
          'A composição do umbilical do mergulhador obedece ao que esta estabelecido na Normam-15?',
      },
      '3-6 a 58': {
        Question:
          'Existe no sistema de mergulho alarmes luminosos e sonoros que indique falhas no sistema de posicionamento dinâmico?',
        Answer: 'white',
      },
      '3-6 b 59': {
        Question: 'Os supervisores são treinados e capacitados na interpretação dos sinais de alarmes do sistema de emergência do Dynamic positivo (DP)? ',
        Answer: 'white',
      },
      '3-6 c 60': {
        Question: 'Os supervisores e mergulhadores são treinados no plano de resposta a emergências quando os alarmes do Dynamic positivo (DP) são acionados?',
        Answer: 'white',
      },
      '3-7 a 61': {
        Question:
          'Existe orientação quanto a interrupção do mergulho no caso de má visibilidade durante as operações?',
        Answer: 'white',
      },
      '3-7 b 62': {
        Question:
          'Existe a orientação para utilização do ROV e ou correntometro para garantir a segurança do mergulhador em condições adversas.',
        Answer: 'white',
      },
      '3-7 c 63': {
        Question:
          'Os responsáveis pelo monitoramento do umbilical do mergulhador na água são orientados durante a reunião pré operação da necessidade de manter o umbilical sempre tencionado?',
        Answer: 'white',
      },
      '3-8 a 64': {
        Question:
          'A tabela de comprimento de umbilical é utilizada para garantir que o comprimento do umbilical não alcance os propulsores da embarcação?',
        Answer: 'white',
      },
      '3-8 b 65': {
        Question:
          'Existe a comunicação (rádio) entre o supervisor e o Belman tanto na água quanto na superfície a fim de manter a atenção constante no umbilical do mergulhador a fim de não deixa-lo muito folgado?',
        Answer: 'white',
      },
      '3-9 a 66': {
        Question:
          'Antes da realização das atividades de mergulho são instaladas barreiras de acesso restrito ao local das operações de mergulho?',
        Answer: 'white',
      },
      '3-9 b 67': {
        Question:
          'Existe a comunicação geral em toda a unidade informando sobre o inicio das atividades de mergulho e sobre a área de acesso restrito?',
        Answer: 'white',
      },
      '3-10 a 68': {
        Question:
          'Existe um controle de manutenção da vida útil do mosquetão?',
        Answer: 'white',
      },
      '3-10 b 69': {
        Question:
          'Existe controle da qualidade do mosquetão adquirido no fornecedor autorizado visando garantir a qualidade e os limites operacionais?',
        Answer: 'white',
      },
      '3-10 c 70': {
        Question:
          'Existe treinamento para toda equipe de mergulho no manuseio do mosquetão durante a equipagem do mergulhador? ',
        Answer: 'white',
      },
    },
    'AM 4-Danos mecânicos (choque) à estrutura do capacete (sideblock, visor, estrutura, etc)': {
      '4-1 a 71': {
        Question:
          'A equipe tem treinamento para saber avaliar e prever os limites operacionais aceitáveis para a realização das atividades de mergulho?',
        Answer: 'white',
      },
      '4-1 b 72': {
        Question:
          'Existe comunicação direta entre o controle da unidade e o supervisor de mergulho para que em caso de mudança súbita das condições meteoceonográficas o mesmo seja informado para suspender o mergulho?',
        Answer: 'white',
      },
      '4-1 c 73': {
        Question:
          'É utilizada tabela para avaliação das condições para mergulho em profundidades menores do que 15 metros?',
        Answer: 'white',
      },
      '4-2 a 74': {
        Question:
          'O profissional responsável pela manutenção dos capacetes, tem treinamento e o certificado da realização do curso por empresa idônea',
        Answer: 'white',
      },
      '4-2 b 75': {
        Question:
          'Existe processo de controle de aquisição de peças para o capacete de mergulho somente junto a fornecedores autorizados pelo fabricante?',
        Answer: 'white',
      },
      '4-3 a 76': {
        Question: 'Estão contemplados na matriz de treinamento de mergulho os treinamentos de Permissão de trabalho (PT) e Análise Preliminar de risco (APR) para garantir a eficácia do planejamento das operações de mergulho?',
        Answer: 'white',
      },
      '4-3 b 77': {
        Question: 'Durante o DDS é apresentado o planejamento das operações de mergulho e orientado sobre capacete do mergulhador (cuidados, higienização, armazenamento do capacete e danos mecânicos (choque à estrutura do capacete (sideblock, visor, estrutura, etc.))?',
        Answer: 'white',
      },
      '4-3 c 78': {
        Question: 'É contemplado na Análise preliminar de risco (APR) o risco sobre danos mecânicos (choque) à estrutura do capacete (sideblock, visor, estrutura, etc.)?',
        Answer: 'white',
      },
      '4-4 a 79': {
        Question:
          'Existe sistemática de inspeção pré e pós mergulho do capacete?',
        Answer: 'white',
      },
      '4-4 b 80': {
        Question:
          'A capacitação do responsável pela inspeção do capacete é avaliada periodicamente?',
        Answer: 'white',
      },
      '4-4 c 81': {
        Question:
          'No check list pré mergulho é registrado a inspeção visual dos capacetes? ',
        Answer: 'white',
      },
      '4-4 d 82': {
        Question:
          'Os capacetes utilizados nas frentes de trabalho estão em conformidade com as normas e os padrões Petrobras?',
        Answer: 'white',
      },
    },'AM 5- Falha no sistema de captação e bombeio de água do mar':{

    },
    'AM 6-Falha no sistema de aquecimento(Mergulho Profundo)':{

    }
    ,
    'CO 1-Doença descompressiva': {
      '7-1 a 113': {
        Question:
          'Os equipamentos que fazem parte do sistema de comunicação e monitoramento do mergulhador são testados periodicamente?',
        Answer: 'white',
      },
      '7-1 b 114': {
        Question:
          'Os equipamentos que compõe o sistema de comunicação e monitoramento do mergulhador estão inseridos no plano de manutenção do equipamento de mergulho? ',
        Answer: 'white',
      },
      '7-2 a 115': {
        Question:
          'São realizados testes periódicos de funcionalidade do sistema secundário de suprimento de ar? ',
        Answer: 'white',
      },
      '7-2 b 116': {
        Question:
          'O mergulhador testa o sistema secundário de suprimento de ar a cada mergulho? ',
        Answer: 'white',
      },
      '7-2 c 117': {
        Question:
          'Os mergulhadores são treinados na utilização do sistema secundário de suprimento de ar?  ',
        Answer: 'white',
      },
      '7-3 a 118': {
        Question:
          'Os supervisores são capacitados e treinados para orientar a realização do resgate do mergulhador pelo mergulhador de emergência? ',
        Answer: 'white',
      },
      '7-3 b 119': {
        Question:
          'O mergulhador de emergência na superfície, permanece equipado e pronto para intervir no menor tempo possível sendo num simulado ou durante uma intercorrência real? ',
        Answer: 'white',
      },
      '7-3 c 120': {
        Question:
          'Existe periodicidade para realização de simulado de resgate do mergulhador pelo mergulhador de emergência? ',
        Answer: 'white',
      },
      '7-3 d 121': {
        Question:
          'A empresa controla através do seu departamento de segurança o treinamento frequente envolvendo toda a equipe no procedimento de resgate do mergulhador através do mergulhador de emergência? ',
        Answer: 'white',
      },
      '7-4 a 121': {
        Question:
          'Os supervisores de mergulho são capacitados e treinados no plano de resposta a emergência, abortar o mergulho em andamento, retornar a cesta, sinete ou a superfície com o auxílio de Bellman ou mergulhador de emergência? ',
        Answer: 'white',
      },
      '7-4 b 122': {
        Question:
          'O plano de contingência prevê o passo a passo para abortar o mergulho e retornar a cesta, sinete ou ao a superfície com a ajuda do Bellman ou o mergulhador de emergência? ',
        Answer: 'white',
      },
      '7-5 a 124': {
        Question:
          'No local de trabalho existe uma câmara hiperbárica dedicada e operacional para utilização imediata para casos de emergência com acesso totalmente desobstruído? ',
        Answer: 'white',
      },
      '7-5 b 125': {
        Question:
          'O plano de inspeção e manutenção da câmara hiperbárica é realizado por pessoal qualificado e treinado para garantir a sua operacionalidade em caso de mergulho programado com descompressão ou emergência hiperbárica? ',
        Answer: 'white',
      },
      '7-5 c 126': {
        Question:
          'Caso haja uma segunda câmara hiperbárica na frente/embarcação de mergulho a mesma é testada periodicamente e é registrado em logbook? ',
        Answer: 'white',
      },
      '7-6 a 127': {
        Question:
          'Existem, ao menos, dois membros na equipe de mergulho capacitados e treinados em suporte básico a vida, no atendimento inicial a emergências subaquáticas, atendendo assim as normas vigentes (Normam 15)? ',
        Answer: 'white',
      },
      '7-6 b 128': {
        Question:
          'É cobrado eficácia nos simulados dos planos de resposta a emergência com ênfase na comunicação adequada? ',
        Answer: 'white',
      },
      '7-6 c 129': {
        Question:
          'O treinamento de reciclagem em atendimento a emergências de primeiros socorros atende as orientações de acordo com as normas vigentes? ',
        Answer: 'white',
      },
      '7-6 d 130': {
        Question:
          'São realizados simulados de primeiros socorros com as equipes de mergulho com ênfase na falta do suprimento de água quente e/ou falta de suprimento respiratório visando a saúde dos mergulhadores? ',
        Answer: 'white',
      },
      '7-7 a 131': {
        Question:
          'Há profissional de saúde de nível superior devidamente habilitado na frente/embarcação de mergulho? ',
        Answer: 'white',
      },
      '7-7 b 132': {
        Question:
          'O profissional de saúde é capacitado e treinado em suporte avançado a vida e atendimento a emergências subaquática? ',
        Answer: 'white',
      },
      '7-8 a 133': {
        Question:
          'As estruturas para primeiro atendimento no suporte avançado a vida são adequadas? ',
        Answer: 'white',
      },
      '7-8 b 134': {
        Question:
          'As estruturas para primeiro atendimento no suporte avançado a vida, seguem os padrões mínimos de manutenção e seguem as normas vigentes? ',
        Answer: 'white',
      },
      '7-8 c 135': {
        Question:
          'A frente/embarcação de mergulho possui enfermaria que atenda aos requisitos da Normam-01, da NR-30 e demais legislações pertinentes? ',
        Answer: 'white',
      },
      '7-8 d 136': {
        Question:
          'A enfermaria possui quantidade de equipamentos e medicamentos que atende a lista de itens mínimos de enfermaria padronizada pela Petrobras para frente/embarcação de mergulho? ',
        Answer: 'white',
      },
      '7-9 a 137': {
        Question:
          'Os meios de comunicação com o suporte médico em terra são adequados e tem backup? ',
        Answer: 'white',
      },
      '7-9 b 138': {
        Question:
          'No procedimento de contingência está previsto o plano de resposta a emergência médica com os recursos de contato telefônico ou por vídeo? ',
        Answer: 'white',
      },
      '7-9 c 139': {
        Question:
          'Existe plano de atendimento médico emergencial na empresa 24 horas para atender as equipes embarcadas no offshore? ',
        Answer: 'white',
      },
      '7-10 a 140': {
        Question:
          'Existe procedimento específico para em caso de acidente com necessidade de remoção e transporte para hospital ou clínica em terra? ',
        Answer: 'white',
      },
      '7-10 b 141': {
        Question:
          'Os supervisores estão aptos a orientar os responsáveis pelo transporte de acidentados via aérea, de que após a realização de mergulho, o voo deve cumprir um limite de altitude durante o voo em cumprimento da NORMAM-15? ',
        Answer: 'white',
      },
      '7-10 c 142': {
        Question:
          'O Plano de contingência garante acesso a rede hospitalar especializada e a disponibilidade de recursos para um tratamento específico até o pronto reestabelecimento do acidentado? ',
        Answer: 'white',
      },
      '7-10 d 143': {
        Question:
          'Existe procedimento da empresa que propicie a remoção do acidentado embarcado até a rede hospitalar? ',
        Answer: 'white',
      },
      '7-10 e 144': {
        Question:
          'O RH da empresa está capacitado a proporcionar todo o acompanhamento ao acidentado desde a internação até o seu retorno as atividades? ',
        Answer: 'white',
      },
      '7-11 a 145': {
        Question:
          'É realizada uma criteriosa avaliação médica especializada antes do retorno do mergulhador ao trabalho? ',
        Answer: 'white',
      },
      '7-11 b 146': {
        Question:
          'O RH da empresa acompanha o processo de retorno do acidentado as suas atividades laborais após finalizado o tratamento? ',
        Answer: 'white',
      },
    },
    'CO 2-Afogamento': {
      '8-1 a 147': {
        Question:
          'Os equipamentos que fazem parte do sistema de comunicação e monitoramento do mergulhador são testados periodicamente? ',
        Answer: 'white',
      },
      '8-1 b 148': {
        Question:
          'Os equipamentos que compõe o sistema de comunicação e monitoramento do mergulhador estão inseridos no plano de manutenção do equipamento de mergulho? ',
        Answer: 'white',
      },
      '8-2 a 149': {
        Question:
          'São realizados testes periódicos de funcionalidade do sistema secundário de suprimento de ar? ',
        Answer: 'white',
      },
      '8-2 b 150': {
        Question:
          'O mergulhador testa o sistema secundário de suprimento de ar a cada mergulho? ',
        Answer: 'white',
      },
      '8-2 c 151': {
        Question:
          'Os mergulhadores são treinados na utilização do sistema secundário de suprimento de ar?  ',
        Answer: 'white',
      },
      '8-3 a 152': {
        Question:
          'Os supervisores são capacitados e treinados para orientar a realização do resgate do mergulhador pelo mergulhador de emergência? ',
        Answer: 'white',
      },
      '8-3 b 153': {
        Question:
          'O mergulhador de emergência na superfície, permanece equipado e pronto para intervir no menor tempo possível sendo num simulado ou durante uma intercorrência real? ',
        Answer: 'white',
      },
      '8-3 c 154': {
        Question:
          'Existe periodicidade para realização de simulado de resgate do mergulhador pelo mergulhador de emergência? ',
        Answer: 'white',
      },
      '8-3 d 155': {
        Question:
          'A empresa controla através do seu departamento de segurança o treinamento frequente envolvendo toda a equipe no procedimento de resgate do mergulhador através do mergulhador de emergência? ',
        Answer: 'white',
      },
      '8-4 a 156': {
        Question:
          'Os supervisores de mergulho são capacitados e treinados no plano de resposta a emergência, abortar o mergulho em andamento, retornar a cesta, sinete ou a superfície com o auxílio de Bellman ou mergulhador de emergência? ',
        Answer: 'white',
      },
      '8-4 b 157': {
        Question:
          'O plano de contingência prevê o passo a passo para abortar o mergulho e retornar a cesta, sinete ou ao a superfície com a ajuda do Bellman ou o mergulhador de emergência? ',
        Answer: 'white',
      },
      '8-4 c 158': {
        Question:
          'A equipe de mergulho é conscientizada nos riscos inerentes a atividades que tenham perigo de sucção ou pressurização na frente/embarcação de mergulho? (Aplicável apenas para mergulho saturado)  ',
        Answer: 'white',
      },
      '8-5 a 159': {
        Question:
          'No local de trabalho existe uma câmara hiperbárica dedicada e operacional para utilização imediata para casos de emergência com acesso totalmente desobstruído? ',
        Answer: 'white',
      },
      '8-5 b 160': {
        Question:
          'O plano de inspeção e manutenção da câmara hiperbárica é realizado por pessoal qualificado e treinado para garantir a sua operacionalidade em caso de mergulho programado com descompressão ou emergência hiperbárica? ',
        Answer: 'white',
      },
      '8-5 c 161': {
        Question:
          'Caso haja uma segunda câmara hiperbárica na frente/embarcação de mergulho a mesma é testada periodicamente e é registrado em logbook? ',
        Answer: 'white',
      },
      '8-6 a 162': {
        Question:
          'Existem, ao menos, dois membros na equipe de mergulho capacitados e treinados em suporte básico a vida, no atendimento inicial a emergências subaquáticas, atendendo assim as normas vigentes (Normam 15)? ',
        Answer: 'white',
      },
      '8-6 b 163': {
        Question:
          'É cobrado eficácia nos simulados dos planos de resposta a emergência com ênfase na comunicação adequada? ',
        Answer: 'white',
      },
      '8-6 c 164': {
        Question:
          'O treinamento de reciclagem em atendimento a emergências de primeiros socorros atende as orientações de acordo com as normas vigentes? ',
        Answer: 'white',
      },
      '8-6 d 165': {
        Question:
          'São realizados simulados de primeiros socorros com as equipes de mergulho com ênfase nas atividades em caixa de mar da frente/embarcação de mergulho visando a saúde dos mergulhadores? ',
        Answer: 'white',
      },
      '8-7 a 166': {
        Question:
          'Há profissional de saúde de nível superior devidamente habilitado na frente/embarcação de mergulho? ',
        Answer: 'white',
      },
      '8-7 b 167': {
        Question:
          'O profissional de saúde é capacitado e treinado em suporte avançado a vida e atendimento a emergências subaquática? ',
        Answer: 'white',
      },
      '8-8 a 168': {
        Question:
          'As estruturas para primeiro atendimento no suporte avançado a vida são adequadas? ',
        Answer: 'white',
      },
      '8-8 b 169': {
        Question:
          'As estruturas para primeiro atendimento no suporte avançado a vida, seguem os padrões mínimos de manutenção e seguem as normas vigentes? ',
        Answer: 'white',
      },
      '8-8 c 170': {
        Question:
          'A frente/embarcação de mergulho possui enfermaria que atenda aos requisitos da Normam-01, da NR-30 e demais legislações pertinentes? ',
        Answer: 'white',
      },
      '8-8 d 171': {
        Question:
          'A enfermaria possui quantidade de equipamentos e medicamentos que atende a lista de itens mínimos de enfermaria padronizada pela Petrobras para frente/embarcação de mergulho? ',
        Answer: 'white',
      },
      '8-9 a 172': {
        Question:
          'Os meios de comunicação com o suporte médico em terra são adequados e tem backup? ',
        Answer: 'white',
      },
      '8-9 b 173': {
        Question:
          'No procedimento de contingência está previsto o plano de resposta a emergência médica com os recursos de contato telefônico ou por vídeo? ',
        Answer: 'white',
      },
      '8-9 c 174': {
        Question:
          'Existe plano de atendimento médico emergencial na empresa 24 horas para atender as equipes embarcadas no offshore? ',
        Answer: 'white',
      },
      '8-10 a 175': {
        Question:
          'Existe procedimento específico para em caso de acidente com necessidade de remoção e transporte para hospital ou clínica em terra? ',
        Answer: 'white',
      },
      '8-10 b 176': {
        Question:
          'Os supervisores estão aptos a orientar os responsáveis pelo transporte de acidentados via aérea, de que após a realização de mergulho, o voo deve cumprir um limite de altitude durante o voo em cumprimento da NORMAM-15? ',
        Answer: 'white',
      },
      '8-10 c 177': {
        Question:
          'O Plano de contingência garante acesso a rede hospitalar especializada e a disponibilidade de recursos para um tratamento específico até o pronto reestabelecimento do acidentado? ',
        Answer: 'white',
      },
      '8-10 d 178': {
        Question:
          'Existe procedimento da empresa que propicie a remoção do acidentado embarcado até a rede hospitalar? ',
        Answer: 'white',
      },
      '8-10 e 179': {
        Question:
          'O RH da empresa está capacitado a proporcionar todo o acompanhamento ao acidentado desde a internação até o seu retorno as atividades? ',
        Answer: 'white',
      },
      '8-11 a 180': {
        Question:
          ' É realizada uma criteriosa avaliação médica especializada antes do retorno do mergulhador ao trabalho? ',
        Answer: 'white',
      },
      '8-11 b 181': {
        Question:
          '  O RH da empresa acompanha o processo de retorno do acidentado as suas atividades laborais após finalizado o tratamento? ',
        Answer: 'white',
      },
    },

  };

  let listKeysQuestionFaltaRespiratorio=[
    {
      'AM 1-Erro Humano': [
        { '1-1': { Check: [], Answer: 'white' } },
        { '1-2': { Check: [], Answer: 'white' } },
        { '1-3': { Check: [], Answer: 'white' } },
        { '1-4': { Check: [], Answer: 'white' } },
        { '1-5': { Check: [], Answer: 'white' } },
        { '1-6': { Check: [], Answer: 'white' } },
      ].reverse(),
      'AM2-Falha de equipamento de mergulho (compressor, quadro de cilindros, mangueira, capacete, bail out)': [
        { '2-1': { Check: [], Answer: 'white' } },
        { '2-2': { Check: [], Answer: 'white' } },
        { '2-3': { Check: [], Answer: 'white' } },
        { '2-4': { Check: [], Answer: 'white' } },
        { '2-5': { Check: [], Answer: 'white' } },
        { '2-6': { Check: [], Answer: 'white' } },
        { '2-7': { Check: [], Answer: 'white' } },
      ].reverse(),
      'AM 3-Esmagamento, torção, tensão e ruptura do umbilical': [
        { '3-1': { Check: [], Answer: 'white' } },
        { '3-2': { Check: [], Answer: 'white' } },
        { '3-3': { Check: [], Answer: 'white' } },
        { '3-4': { Check: [], Answer: 'white' } },
        { '3-5': { Check: [], Answer: 'white' } },
        { '3-6': { Check: [], Answer: 'white' } },
        { '3-7': { Check: [], Answer: 'white' } },
        { '3-8': { Check: [], Answer: 'white' } },
        { '3-9': { Check: [], Answer: 'white' } },
        { '3-10': { Check: [], Answer: 'white' } },
      ].reverse(),
      'AM 4-Danos mecânicos (choque) à estrutura do capacete (sideblock, visor, estrutura, etc)': [
        { '4-1': { Check: [], Answer: 'white' } },
        { '4-2': { Check: [], Answer: 'white' } },
        { '4-3': { Check: [], Answer: 'white' } },
        { '4-4': { Check: [], Answer: 'white' } },

      ].reverse(),
      'AM 5- Falha no sistema de captação e bombeio de água do mar': [
        { '5-1': { Check: [], Answer: 'white' } },
        { '5-2': { Check: [], Answer: 'white' } },
        { '5-3': { Check: [], Answer: 'white' } },
        { '5-4': { Check: [], Answer: 'white' } },
        { '5-5': { Check: [], Answer: 'white' } },

      ].reverse(),
      'AM 6-Falha no sistema de aquecimento(Mergulho Profundo)': [
        { '6-1': { Check: [], Answer: 'white' } },
        { '6-2': { Check: [], Answer: 'white' } },
        { '6-3': { Check: [], Answer: 'white' } },
        { '6-4': { Check: [], Answer: 'white' } },
      ].reverse(),
      'CO 1-Doença descompressiva': [
        { '7-1': { Check: [], Answer: 'white' } },
        { '7-2': { Check: [], Answer: 'white' } },
        { '7-3': { Check: [], Answer: 'white' } },
        { '7-4': { Check: [], Answer: 'white' } },
        { '7-5': { Check: [], Answer: 'white' } },
        { '7-6': { Check: [], Answer: 'white' } },
        { '7-7': { Check: [], Answer: 'white' } },
        { '7-8': { Check: [], Answer: 'white' } },
        { '7-9': { Check: [], Answer: 'white' } },
        { '7-10': { Check: [], Answer: 'white' } },
        { '7-11': { Check: [], Answer: 'white' } },
      ],
      'CO 2-Afogamento': [
        { '8-1': { Check: [], Answer: 'white' } },
        { '8-2': { Check: [], Answer: 'white' } },
        { '8-3': { Check: [], Answer: 'white' } },
        { '8-4': { Check: [], Answer: 'white' } },
        { '8-5': { Check: [], Answer: 'white' } },
        { '8-6': { Check: [], Answer: 'white' } },
        { '8-7': { Check: [], Answer: 'white' } },
        { '8-8': { Check: [], Answer: 'white' } },
        { '8-9': { Check: [], Answer: 'white' } },
        { '8-10': { Check: [], Answer: 'white' } },
        { '8-11': { Check: [], Answer: 'white' } },
      ],

    }];



  export {TitlesAndQuestionsFaltaRespiratorio,listKeysQuestionFaltaRespiratorio,titleFormFaltaRespiratorio,titleHazardFaltaRespiratorio,SecondIdFaltaRespiratorio,listAMCOFaltaRespiratorio};

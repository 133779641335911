
const titleFormFalhaEquipamentoIndividual="Falha do Equipamento Individual do Mergulhador"
const titleHazardFalhaEquipamentoIndividual="Ambiente Subaquático";
const SecondIdFalhaEquipamentoIndividual="FalhaEquipamentoIndividual";
const listAMCOFalhaEquipamentoIndividual=['AM-1 - Armazenamento e transporte inadequado dos equipamentos','CO-1 - Doença descompressiva'];
let TitlesAndQuestionsFalhaEquipamentoIndividual = {
    'AM-1 - Armazenamento e transporte inadequado dos equipamentos': {
      '1-1 a 1': {
        Question:
          'Os equipamentos individuais dos mergulhadores são devidamente acondicionados em local adequado (temperatura, umidade), conforme especificação técnica do fabricante?',
        Answer: 'white',
      },
      '1-1 b 2': {
        Question:
          ' É realizada uma verificação programada a fim de confirmar que os equipamentos individuais dos mergulhadores estocados estão se mantendo íntegros?',
        Answer: 'white',
      },
      '1-1 c 3': {
        Question:
          ' É realizado um controle ou separação dos equipamentos individuais dos mergulhadores que possam ter um prazo de validade para a sua utilização?',
        Answer: 'white',
      },
      '1-2 a 4': {
        Question:
          ' O transporte dos equipamentos individuais dos mergulhadores é realizado conforme especificação técnica do fabricante?',
        Answer: 'white',
      },
      '1-2 b 5': {
        Question:
          'As equipes de mergulho são treinadas e capacitadas para garantir o adequado transporte dos equipamentos individuais dos mergulhadores para evitar queda, impacto ou danos? ',
        Answer: 'white',
      },
      '1-2 c 6': {
        Question:
          'É avaliado se há possíveis riscos de danos durante o transporte terrestre aos equipamentos de mergulho pelo pessoal responsável pela logística de materiais?',
        Answer: 'white',
      },
      '1-3 a 7': {
        Question:
          'O check list pré-mergulho é revisado periodicamente com atenção ao armazenamento dos equipamentos individuais dos mergulhadores (ex. capacetes, aqualung e roupas de mergulho), para garantir a integridade dos equipamentos?',
        Answer: 'white',
      },
      '1-3 b 8': {
        Question: 'As equipes de mergulho são treinadas para acompanhar as mudanças do check list revisado?',
        Answer: 'white',
      },
      '1-3 c 9': {
        Question:
          'O check list pré-mergulho leva em consideração a inclusão de novos equipamentos individuais dos mergulhadores?',
        Answer: 'white',
      },
      '1-4 a 10': {
        Question:
          'As equipes de mergulho são treinadas e tem competência para realizar os testes de estanqueidade nos capacetes de mergulho (movimentos para frente e para trás com a cabeça ao entrar na água)?',
        Answer: 'white',
      },
      '1-4 b 11': {
        Question:
          'São realizados pelos mergulhadores testes nos capacetes de mergulho (movimentos para frente e para trás com a cabeça ao entrar na água) para garantir a estanqueidade dos mesmos por orientação do supervisor de mergulho? ',
        Answer: 'white',
      },
      '1-4 c 12': {
        Question:
          'É realizado o teste de estanqueidade durante o mergulho em intervalos regulares? ',
        Answer: 'white',
      },
      '1-4 d 13': {
        Question:
          'É realizado treinamento do ajuste da sintonia fina da válvula de segundo estágio do capacete? ',
        Answer: 'white',
      },
      '1-4 e 14': {
        Question:
          'A empresa utiliza os capacetes com válvula de segundo estágio balanceada?',
        Answer: 'white',
      },


    },
    'AM-2- Manuseio inadequado do equipamento': {
      '2-1 a 15': {
        Question:
          'As equipes de mergulho são treinadas e tem competência para manusear os equipamentos individuais dos mergulhadores de forma a evitar danos?',
        Answer: 'white',
      },
      '2-1 b 16': {
        Question:
          'Existe um programa de reciclagem para avaliar e atualizar os profissionais envolvidos no manuseio dos equipamentos individuais dos mergulhadores (onshore e offshore)?',
        Answer: 'white',
      },
      '2-2 a 17': {
        Question:
          'O check list pré-mergulho é revisado periodicamente com atenção ao manuseio dos equipamentos individuais dos mergulhadores (ex. capacetes, aqualung e roupas de mergulho), para garantir a integridade dos equipamentos?',
        Answer: 'white',
      },
      '2-2 b 18': {
        Question:
          'As equipes de mergulho são treinadas para acompanhar as mudanças do check list revisado?',
        Answer: 'white',
      },
      '2-2 c 19': {
        Question:
          'O check list pré-mergulho leva em consideração a inclusão de novos equipamentos individuais dos mergulhadores?',
        Answer: 'white',
      },
      '2-3 a 20': {
        Question:
          'As equipes de mergulho são treinadas e tem competência para realizar os testes de estanqueidade nos capacetes de mergulho (movimentos para frente e para trás com a cabeça ao entrar na água)?',
        Answer: 'white',
      },
      '2-3 b 21': {
        Question:
          'São realizados pelos mergulhadores testes nos capacetes de mergulho (movimentos para frente e para trás com a cabeça ao entrar na água) para garantir a estanqueidade dos mesmos por orientação do supervisor de mergulho?',
        Answer: 'white',
      },
      '2-3 c 22': {
        Question:
          'É realizado o teste de estanqueidade durante o mergulho em intervalos regulares?',
        Answer: 'white',
      },
      '2-3 d 23': {
        Question:
          'É realizado treinamento do ajuste da sintonia fina da válvula de segundo estágio do capacete? ',
        Answer: 'white',
      },
      '2-3 e 24': {
        Question:
          'A empresa utiliza os capacetes com válvula de segundo estágio balanceada?',
        Answer: 'white',
      },

    },
    'AM-3 - Inspeção e manutenção inadequada do equipamento': {
      '3-1 a 25': {
        Question:
          'O check list pré-mergulho é revisado periodicamente com atenção aos ajustes das válvulas e a integridade das mangueiras?',
        Answer: 'white',
      },
      '3-1 b 26': {
        Question:
          'As equipes de mergulho são treinadas para acompanhar as mudanças do check list revisado?',
        Answer: 'white',
      },
      '3-2 a 27': {
        Question:
          'O plano de inspeção e manutenção dos equipamentos individuais dos mergulhadores segue as recomendações dos fabricantes?',
        Answer: 'white',
      },
      '3-2 b 28': {
        Question:
          'É realizado treinamento do pessoal qualificado em manutenção para garantir o cumprimento do plano de inspeção e manutenção dos equipamentos individuais dos mergulhadores conforme recomendação do fabricante?',
        Answer: 'white',
      },
      '3-2 c 29': {
        Question:
          'As peças de reposição dos equipamentos individuais dos mergulhadores são originais do fabricante? ',
        Answer: 'white',
      },
      '3-2 d 30': {
        Question:
          'Todos os colaboradores têm conhecimento que existe um plano de inspeção e manutenção dos equipamentos individuais dos mergulhadores?',
        Answer: 'white',
      },
      '3-2 e 31': {
        Question:
          'Há um controle que garanta que as peças de reposição dos equipamentos individuais dos mergulhadores sejam originais do fabricante?',
        Answer: 'white',
      },
      '3-2 f 32': {
        Question:
          'É realizada auditoria interna para avaliar a aplicação dos controles conforme plano de inspeção e manutenção dos equipamentos individuais dos mergulhadores, competência e treinamento?',
        Answer: 'white',
      },
      '3-2 g 33': {
        Question:
          'Existe um controle do número de utilizações dos neck dam a bordo?',
        Answer: 'white',
      },
      '3-2 h 34': {
        Question:
          'É verificado se o neck dam possui mais da metade da espessura nominal indicada pelo fabricante?',
        Answer: 'white',
      },
      '3-2 e 35': {
        Question:
          'Caso o neck dam não possua mais da metade da espessura nominal indicada pelo fabricante, ele é imediatamente segregado para descarte?',
        Answer: 'white',
      },
      '3-3 a 36': {
        Question:
          'As equipes de mergulho são treinadas e tem competência para realizar os testes de estanqueidade nos capacetes de mergulho (movimentos para frente e para trás com a cabeça ao entrar na água)?',
        Answer: 'white',
      },
      '3-3 b 37': {
        Question:
          'São realizados pelos mergulhadores testes nos capacetes de mergulho (movimentos para frente e para trás com a cabeça ao entrar na água) para garantir a estanqueidade dos mesmos por orientação do supervisor de mergulho?',
        Answer: 'white',
      },
      '3-3 c 38': {
        Question:
          'É realizado o teste de estanqueidade durante o mergulho em intervalos regulares? ',
        Answer: 'white',
      },
      '3-3 d 39': {
        Question:
          'É realizado treinamento do ajuste da sintonia fina da válvula de segundo estágio do capacete? ',
        Answer: 'white',
      },
      '3-3 e 40': {
        Question:
          'A empresa utiliza os capacetes com válvula de segundo estágio balanceada?',
        Answer: 'white',
      },

    },
    'AM-4 - Equipamento fora de especificação técnica': {
      '4-1 a 41': {
        Question:
          'As equipes de mergulho são treinadas e tem competência para realizar os testes de estanqueidade nos capacetes de mergulho (movimentos para frente e para trás com a cabeça ao entrar na água)?',
        Answer: 'white',
      },
      '4-1 b 42': {
        Question:
          'São realizados pelos mergulhadores testes nos capacetes de mergulho (movimentos para frente e para trás com a cabeça ao entrar na água) para garantir a estanqueidade dos mesmos por orientação do supervisor de mergulho?',
        Answer: 'white',
      },
      '4-1 c 43': {
        Question:
          'É realizado o teste de estanqueidade durante o mergulho em intervalos regulares? ',
        Answer: 'white',
      },
      '4-1 d 44': {
        Question:
          'É realizado treinamento do ajuste da sintonia fina da válvula de segundo estágio do capacete? ',
        Answer: 'white',
      },
      '4-1 e 45': {
        Question:
          'A empresa utiliza os capacetes com válvula de segundo estágio balanceada?',
        Answer: 'white',
      },
      '4-2 a 46': {
        Question: 'A liderança é treinada e capacitada para assegurar que as exigências contratuais das especificações técnicas dos equipamentos de mergulho estão sendo cumpridas?',
        Answer: 'white',
      },
      '4-2 b 47': {
        Question: 'São realizadas reuniões (RAC) contemplando possíveis atualizações sobre exigências de equipamentos individuais dos mergulhadores ao final de cada embarque com a fiscalização da frente/embarcação de mergulho?',
        Answer: 'white',
      },
      '4-2 c 48': {
        Question: 'Existe algum canal de informação que atualize mudanças no contrato e/ou nas especificações técnicas dos equipamentos individuais dos mergulhadores?',
        Answer: 'white',
      },
      '4-3 a 49': {
        Question: 'As equipes de mergulho são treinadas para identificar os equipamentos individuais dos mergulhadores fora das especificações técnicas?',
        Answer: 'white',
      },
      '4-3 b 50': {
        Question: 'Existe check list pré-embarque de material de mergulho que leva em consideração a inclusão de novos equipamentos e suas especificações técnicas?',
        Answer: 'white',
      },
      '4-3 c 51': {
        Question: 'Existe um responsável por receber os equipamentos de mergulho a bordo?',
        Answer: 'white',
      },
      '4-3 d 52': {
        Question: 'As pastilhas utilizadas no sistema de comunicação do capacete (alto falantes, microfone) são fornecidas encapsuladas para evitar fuga de corrente elétrica?',
        Answer: 'white',
      },
      '4-3 e 53': {
        Question: 'Na equipe de mergulho há um integrante responsável que realiza a verificação dos componentes do capacete e confere a existência das pastilhas encapsuladas do sistema de fonia?',
        Answer: 'white',
      },
      '4-4 a 54': {
        Question: 'Existe processo implementado de qualificação dos fornecedores críticos contemplando os equipamentos individuais dos mergulhadores com critérios bem definidos?',
        Answer: 'white',
      },
      '4-4 b 55': {
        Question: 'O processo de gestão garante a qualificação dos fornecedores de equipamentos individuais dos mergulhadores dentro das especificações técnicas dos equipamentos?',
        Answer: 'white',
      },
    },
    'AM5 - Falha de equipagem': {
      '5-1 a 56': {
        Question:
          'O check list pré-mergulho é revisado periodicamente com atenção as falhas na equipagem dos mergulhadores?',
        Answer: 'white',
      },
      '5-1 b 57': {
        Question:
          'As equipes de mergulho são treinadas para identificar falhas na equipagem dos mergulhadores e garantir as mudanças do check list?',
        Answer: 'white',
      },
      '5-1 c 58': {
        Question:
          'É disponibilizado as gravações e áudios do procedimento de check list pré-mergulho da equipagem do mergulhador?',
        Answer: 'white',
      },
      '5-2 a 59': {
        Question:
          'As equipes de mergulho são treinadas e tem competência para realizar os testes de estanqueidade nos capacetes de mergulho (movimentos para frente e para trás com a cabeça ao entrar na água)?',
        Answer: 'white',
      },
      '5-2 b 60': {
        Question:
          'São realizados pelos mergulhadores testes nos capacetes de mergulho (movimentos para frente e para trás com a cabeça ao entrar na água) para garantir a estanqueidade dos mesmos por orientação do supervisor de mergulho?',
        Answer: 'white',
      },
      '5-2 c 61': {
        Question:
          'É realizado o teste de estanqueidade durante o mergulho em intervalos regulares? ',
        Answer: 'white',
      },
      '5-2 d 62': {
        Question:
          'É realizado treinamento do ajuste da sintonia fina da válvula de segundo estágio do capacete? ',
        Answer: 'white',
      },
      '5-2 e 63': {
        Question:
          'A empresa utiliza os capacetes com válvula de segundo estágio balanceada?',
        Answer: 'white',
      },
      '5-3 a 64': {
        Question:
          'É realizada inspeção visual antes e após o mergulho em todos os componentes do capacete e no Neckdam?',
        Answer: 'white',
      },
      '5-3 b 65': {
        Question:
          'A inspeção visual do capacete e dos seus componentes consta do check list pré-mergulho?',
        Answer: 'white',
      },
      '5-3 c 66': {
        Question:
          'A empresa de mergulho disponibiliza todos os tamanhos de neck dam para os mergulhadores a bordo da frente/embarcação de mergulho?',
        Answer: 'white',
      },
      '5-3 d 67': {
        Question:
          'Cada mergulhador utiliza neck dam de acordo com o tamanho adequado ao seu pescoço?',
        Answer: 'white',
      },
      '5-3 e 68': {
        Question:
          'Todos os itens da equipagem do capacete são testados e verificados (ex. fonia, iluminação, câmara, oral nasal, compensador, válvulas de free flow e de emergência, válvula não retorno, o-ring de vedação do neck dam, pinos de travamento do capacete, tira posterior de soltura do neck dam, integridade do neoprene do neck dam)?',
        Answer: 'white',
      },
      '5-4 a 69': {
        Question:
          'Existe plano de treinamento periódico para todas as equipes de mergulho contemplando a atividade de equipagem do mergulhador?',
        Answer: 'white',
      },
      '5-4 b 70': {
        Question:
          'As equipes de mergulho recebem treinamento periódico de equipagem dos mergulhadores?',
        Answer: 'white',
      },
      '5-4 c 71': {
        Question:
          'No plano de treinamentos consta a periodicidade destes treinamentos?',
        Answer: 'white',
      },
      '5-4 d 72': {
        Question:
          'O mergulhador é orientado pelo supervisor de mergulho a realizar o ajuste da sintonia fina da válvula de segundo estágio do capacete assim que ele chega na profundidade de trabalho?',
        Answer: 'white',
      },

    },
    'CO-1 - Doença descompressiva': {
      '6-1 a 73': {
        Question:
          'Os equipamentos que fazem parte do sistema de comunicação e monitoramento do mergulhador são testados periodicamente? ',
        Answer: 'white',
      },
      '6-1 b 74': {
        Question:
          'Os equipamentos que compõe o sistema de comunicação e monitoramento do mergulhador estão inseridos no plano de manutenção do equipamento de mergulho?',
        Answer: 'white',
      },
      '6-2 a 75': {
        Question:
          'No plano de contingência está incluído a perda de comunicação entre o controle de mergulho e os mergulhadores com o retorno a superfície sendo orientado pelas boias de sinalização do sinete/cesta?',
        Answer: 'white',
      },
      '6-2 b 76': {
        Question:
          'São realizados treinamentos simulados de perda total de fonia com a liberação das boias de sinalização? ',
        Answer: 'white',
      },
      '6-2 c 77': {
        Question:
          'É verificado no check list pré-mergulho se as condições das boias de aviso da perda de comunicação estão de acordo com a necessidade de utilização?',
        Answer: 'white',
      },
      '6-2 d 78': {
        Question:
          'Existe procedimento especifico no caso de perda de fonia que garanta o retorno dos mergulhadores a superfície em segurança? ',
        Answer: 'white',
      },
      '6-3 a 79': {
        Question:
          'Os supervisores de mergulho são capacitados e treinados no plano de resposta a emergência, abortar o mergulho em andamento, retornar a cesta, sinete ou a superfície com o auxílio de Bellman ou mergulhador de emergência no caso de uma doença descompressiva causada pela falha do equipamento individual do mergulhador?',
        Answer: 'white',
      },
      '6-3 b 80': {
        Question:
          'O plano de contingência prevê o passo a passo para abortar o mergulho e retornar a cesta, sinete ou a superfície com a ajuda do Bellman ou o mergulhador de emergência caso ocorra uma doença descompressiva com o mergulhador?',
        Answer: 'white',
      },
      '6-3 b 81': {
        Question:
          'A equipe de mergulho é conscientizada quanto ao risco de uma possível doença descompressiva causada durante a atividade que executam quando embarcados na frente/embarcação de mergulho?',
        Answer: 'white',
      },
      '6-4 a 82': {
        Question:
          'Os supervisores são capacitados e treinados para orientar a realização do resgate do mergulhador pelo mergulhador de emergência? ',
        Answer: 'white',
      },
      '6-4 b 83': {
        Question:
          'As equipes de mergulho são treinadas e capacitadas para realizar o resgate do mergulhador pelo mergulhador de emergência (bellman) em caso de uma doença descompressiva?',
        Answer: 'white',
      },
      '6-4 c 84': {
        Question:
          'O mergulhador de emergência na superfície, permanece equipado e pronto para intervir no menor tempo possível sendo num simulado ou durante uma intercorrência real? ',
        Answer: 'white',
      },
      '6-4 d 85': {
        Question:
          'Existe periodicidade para realização de simulado de resgate do mergulhador pelo mergulhador de emergência? ',
        Answer: 'white',
      },
      '6-4 e 86': {
        Question:
          'A empresa controla através do seu departamento de segurança o treinamento frequente envolvendo toda a equipe de mergulho no procedimento de resgate do mergulhador através do mergulhador de emergência? ',
        Answer: 'white',
      },
      '6-4 f 87': {
        Question:
          'Quando identificado no simulado uma falha no procedimento ou um tempo excessivo para executá-lo, é reforçada a necessidade de intensificar o treinamento com os envolvidos?',
        Answer: 'white',
      },
      '6-4 g 88': {
        Question:
          'Há alguma planilha de acompanhamento de desempenho dos simulados?',
        Answer: 'white',
      },
      '6-5 a 89': {
        Question:
          'Existem, ao menos, dois membros na equipe de mergulho capacitados e treinados em suporte básico a vida, no atendimento inicial a emergências subaquáticas, atendendo assim as normas vigentes (Normam 15)? ',
        Answer: 'white',
      },
      '6-5 b 90': {
        Question:
          'É cobrado eficácia nos simulados dos planos de resposta a emergência com ênfase na comunicação adequada?',
        Answer: 'white',
      },
      '6-5 c 91': {
        Question:
          'O treinamento de reciclagem em atendimento a emergências de primeiros socorros atende as orientações de acordo com as normas vigentes?',
        Answer: 'white',
      },
      '6-5 d 92': {
        Question:
          'São realizados simulados de primeiros socorros com as equipes de mergulho com ênfase no tratamento de uma doença descompressiva?',
        Answer: 'white',
      },
      '6-6 a 93': {
        Question:
          'Há profissional de saúde de nível superior devidamente habilitado na frente /embarcação de mergulho?',
        Answer: 'white',
      },
      '6-6 b 94': {
        Question:
          'O profissional de saúde é capacitado e treinado em suporte avançado a vida e atendimento de emergência subaquática conforme NORMAM-15 (capitulo 1, item 0138)?',
        Answer: 'white',
      },
      '6-7 a 95': {
        Question:
          'A embarcação possui enfermaria que atenda aos requisitos da Normam-01, da NR-30 e demais legislações pertinentes?',
        Answer: 'white',
      },
      '6-7 b 96': {
        Question:
          'A enfermaria possui quantidade de equipamentos e medicamentos que atende a lista de itens mínimos de enfermaria padronizada pela Petrobras para embarcações de mergulho?',
        Answer: 'white',
      },
      '6-8 a 97': {
        Question:
          'Os meios de comunicação com o suporte médico (médico hiperbarista) em terra são adequados e tem backup?',
        Answer: 'white',
      },
      '6-8 b 98': {
        Question:
          'No procedimento de contingência está previsto o plano de resposta a emergência médica com os recursos de contato telefônico ou por vídeo com o médico hiperbarista?',
        Answer: 'white',
      },
      '6-8 c 99': {
        Question:
          'Existe plano de atendimento médico emergencial na empresa 24 horas para atender as equipes embarcadas no offshore?',
        Answer: 'white',
      },
      '6-8 d 100': {
        Question:
          'É previsto no procedimento de emergência da empresa, antes de uma operação de mergulho, que haja a verificação de uma unidade marítima mais próxima das operações adequada a receber o mergulhador vitimado com disponibilidade de resgate aeromédico?',
        Answer: 'white',
      },
      '6-9 a 101': {
        Question:
          'Existe procedimento específico para em caso de acidente com necessidade de remoção e transporte para hospital ou clínica em terra?',
        Answer: 'white',
      },
      '6-9 b 102': {
        Question:
          'Os supervisores estão aptos a orientar os responsáveis pelo transporte de acidentados via aérea, de que após a realização de mergulho, o voo deve cumprir um limite de altitude em cumprimento da NORMAM-15?',
        Answer: 'white',
      },
      '6-9 c 103': {
        Question:
          'O Plano de contingência garante acesso a rede hospitalar especializada e a disponibilidade de recursos para um tratamento específico até o pronto reestabelecimento do acidentado?',
        Answer: 'white',
      },
      '6-9 d 104': {
        Question:
          'O RH da empresa está capacitado a proporcionar todo o acompanhamento ao acidentado desde a internação até o seu retorno as atividades? ',
        Answer: 'white',
      },
      '6-10 a 105': {
        Question:
          'É realizada uma criteriosa avaliação médica especializada antes do retorno do mergulhador ao trabalho?',
        Answer: 'white',
      },
      '6-10 b 106': {
        Question:
          'O RH da empresa acompanha o processo de retorno do acidentado as suas atividades laborais após finalizado o tratamento?',
        Answer: 'white',
      },


    },
    'CO-2 – Afogamento': {
      '7-1 a 107': {
        Question:
          'Existe um plano de treinamento de simulados de emergências contemplando o cenário de alagamento do capacete de mergulho?',
        Answer: 'white',
      },
      '7-1 b 108': {
        Question:
          'São realizados simulados de emergência de alagamento do capacete de mergulho e seus resultados obtidos são avaliados?',
        Answer: 'white',
      },
      '7-1 c 109': {
        Question:
          'São realizados simulados de emergência de alagamento do capacete de mergulho em terra, com os novos profissionais contratados?',
        Answer: 'white',
      },
      '7-2 a 110': {
        Question:
          'Os equipamentos que fazem parte do sistema de comunicação e monitoramento do mergulhador são testados periodicamente? ',
        Answer: 'white',
      },
      '7-2 b 111': {
        Question:
          'Os equipamentos que compõe o sistema de comunicação e monitoramento do mergulhador estão inseridos no plano de manutenção do equipamento de mergulho? ',
        Answer: 'white',
      },
      '7-2 c 112': {
        Question:
          'Durante uma emergência de mergulho (afogamento do mergulhador), o controle de mergulho mantém comunicação com o Bellman?',
        Answer: 'white',
      },
      '7-3 a 113': {
        Question:
          'Os supervisores de mergulho são capacitados e treinados no plano de resposta a emergência, abortar o mergulho em andamento, retornar a cesta, sinete ou a superfície com o auxílio de Bellman ou mergulhador de emergência em caso de afogamento causado pela falha do equipamento individual do mergulhador?',
        Answer: 'white',
      },
      '7-3 b 114': {
        Question:
          'O plano de contingência prevê o passo a passo para abortar o mergulho em andamento, retornar a cesta, sinete ou ao a superfície com a ajuda do Bellman ou o mergulhador de emergência em caso de afogamento?',
        Answer: 'white',
      },
      '7-3 c 115': {
        Question:
          'A equipe de mergulho é conscientizada quanto ao possível risco de afogamento durante a atividade que executam quando embarcados na frente/embarcação de mergulho?',
        Answer: 'white',
      },
      '7-4 a 116': {
        Question:
          'Os supervisores são capacitados e treinados para orientar a realização do resgate do mergulhador pelo mergulhador de emergência? ',
        Answer: 'white',
      },
      '7-4 b 117': {
        Question:
          'As equipes de mergulho são treinadas e capacitadas para realizar o resgate do mergulhador pelo mergulhador de emergência (bellman) em caso de afogamento?',
        Answer: 'white',
      },
      '7-4 c 118': {
        Question:
          'Existe periodicidade para realização de simulado de resgate do mergulhador pelo mergulhador de emergência? ',
        Answer: 'white',
      },
      '7-4 d 119': {
        Question:
          'O mergulhador de emergência na superfície, permanece equipado e pronto para intervir no menor tempo possível sendo num simulado ou durante uma intercorrência real? ',
        Answer: 'white',
      },
      '7-4 e 120': {
        Question:
          'A empresa controla através do seu departamento de segurança o treinamento frequente envolvendo toda a equipe de mergulho no procedimento de resgate do mergulhador através do mergulhador de emergência? ',
        Answer: 'white',
      },
      '7-4 f 121': {
        Question:
          'Quando identificado no simulado uma falha no procedimento ou um tempo excessivo para executá-lo, é reforçada a necessidade de intensificar o treinamento com os envolvidos?',
        Answer: 'white',
      },
      '7-4 g 122': {
        Question:
          'Há alguma planilha de acompanhamento de desempenho dos simulados? ',
        Answer: 'white',
      },
      '7-5 a 123': {
        Question:
          'No local de trabalho existe uma câmara hiperbárica dedicada e operacional para utilização imediata para casos de emergência com acesso totalmente desobstruído? ',
        Answer: 'white',
      },
      '7-5 b 124': {
        Question:
          'O plano de inspeção e manutenção da câmara hiperbárica é realizado por pessoal qualificado e treinado para garantir a sua operacionalidade em caso de mergulho programado com descompressão ou emergência hiperbárica? ',
        Answer: 'white',
      },
      '7-5 c 125': {
        Question:
          'Caso haja uma segunda câmara hiperbárica na frente/embarcação de mergulho a mesma é testada periodicamente e é registrado em logbook? ',
        Answer: 'white',
      },
      '7-5 d 126': {
        Question:
          'As equipes de mergulho são monitoradas quanto as aptidões físicas e mentais e tem competência para a utilização imediata da câmara hiperbárica em caso de necessidade?',
        Answer: 'white',
      },
      '7-5 e 127': {
        Question:
          'A câmara hiperbárica (CH) é testada e pressurizada no início do embarque, para confirmar a sua operacionalidade?',
        Answer: 'white',
      },
      '7-5 f 128': {
        Question:
          'No início da quinzena é realizado o simulado do mergulhador inconsciente com a necessidade de uso da câmara hiperbárica (CH)?',
        Answer: 'white',
      },
      '7-6 a 129': {
        Question:
          'Existem, ao menos, dois membros na equipe de mergulho capacitados e treinados em suporte básico a vida, no atendimento inicial a emergências subaquáticas, atendendo assim as normas vigentes (Normam 15)? ',
        Answer: 'white',
      },
      '7-6 b 130': {
        Question:
          'É cobrado eficácia nos simulados dos planos de resposta a emergência com ênfase na comunicação adequada?',
        Answer: 'white',
      },
      '7-6 c 131': {
        Question:
          'O treinamento de reciclagem em atendimento a emergências de primeiros socorros atende as orientações de acordo com as normas vigentes? ',
        Answer: 'white',
      },
      '7-6 d 132': {
        Question:
          'São realizados simulados de primeiros socorros com as equipes de mergulho com ênfase no atendimento do mergulhador afogado na frente/embarcação de mergulho?',
        Answer: 'white',
      },
      '7-7 a 133': {
        Question:
          'Há profissional de saúde de nível superior devidamente habilitado na frente /embarcação de mergulho?',
        Answer: 'white',
      },
      '7-7 b 134': {
        Question:
          'O profissional de saúde é capacitado e treinado em suporte avançado a vida e atendimento de emergência subaquática conforme NORMAM-15 (capitulo 1, item 0138)?',
        Answer: 'white',
      },
      '7-8 a 135': {
        Question:
          'A embarcação possui enfermaria que atenda aos requisitos da Normam-01, da NR-30 e demais legislações pertinentes?',
        Answer: 'white',
      },
      '7-8 b 136': {
        Question:
          'A enfermaria possui quantidade de equipamentos e medicamentos que atende a lista de itens mínimos de enfermaria padronizada pela Petrobras para frente/embarcação de mergulho?',
        Answer: 'white',
      },
      '7-9 a 137': {
        Question:
          'Os meios de comunicação com o suporte médico (médico hiperbárista) em terra são adequados e tem backup?',
        Answer: 'white',
      },
      '7-9 b 138': {
        Question:
          'No procedimento de contingência está previsto o plano de resposta a emergência médica com os recursos de contato telefônico ou por vídeo com o médico hiperbarista?',
        Answer: 'white',
      },
      '7-9 c 139': {
        Question:
          'Existe plano de atendimento médico emergencial na empresa 24 horas para atender as equipes embarcadas no offshore?',
        Answer: 'white',
      },
      '7-9 d 140': {
        Question:
          'É previsto no procedimento de emergência da empresa, antes de uma operação de mergulho, que haja a verificação de uma unidade marítima mais próxima das operações adequada a receber o mergulhador vitimado com disponibilidade de resgate aeromédico?',
        Answer: 'white',
      },

      '7-10 a 141': {
        Question:
          'Existe procedimento específico para em caso de acidente com necessidade de remoção e transporte para hospital ou clínica em terra?',
        Answer: 'white',
      },
      '7-10 b 142': {
        Question:
          'Os supervisores estão aptos a orientar os responsáveis pelo transporte de acidentados via aérea, de que após a realização de mergulho, o voo deve cumprir um limite de altitude em cumprimento da NORMAM-15?',
        Answer: 'white',
      },
      '7-10 c 143': {
        Question:
          'O Plano de contingência garante acesso a rede hospitalar especializada e a disponibilidade de recursos para um tratamento específico até o pronto reestabelecimento do acidentado?',
        Answer: 'white',
      },
      '7-10 d 144': {
        Question:
          'Existe procedimento da empresa que propicie a remoção do acidentado embarcado até a rede hospitalar?',
        Answer: 'white',
      },
      '7-10 e 145': {
        Question:
          'O RH da empresa está capacitado a proporcionar todo o acompanhamento ao acidentado desde a internação até o seu retorno as atividades? ',
        Answer: 'white',
      },
      '7-11 a 146': {
        Question:
          'É realizada uma criteriosa avaliação médica especializada antes do retorno do mergulhador ao trabalho?',
        Answer: 'white',
      },
      '7-11 b 147': {
        Question:
          'O RH da empresa acompanha o processo de retorno do acidentado as suas atividades laborais após finalizado o tratamento?',
        Answer: 'white',
      },

    },
    'CO-3 – Asfixia': {
      '8-1 a 148': {
        Question:
          'Os equipamentos que fazem parte do sistema de comunicação e monitoramento do mergulhador são testados periodicamente?',
        Answer: 'white',
      },
      '8-1 b 149': {
        Question:
          'Os equipamentos que compõe o sistema de comunicação e monitoramento do mergulhador estão inseridos no plano de manutenção do equipamento de mergulho? ',
        Answer: 'white',
      },
      '8-1 c 150': {
        Question:
          'Durante uma emergência de mergulho (asfixia do mergulhador), o controle de mergulho mantém comunicação com o Bellman?',
        Answer: 'white',
      },
      '8-2 a 151': {
        Question:
          'Os supervisores de mergulho são capacitados e treinados no plano de resposta a emergência, abortar o mergulho em andamento, retornar a cesta, sinete ou a superfície com o auxílio de Bellman ou mergulhador de emergência em caso de afogamento causado pela falha do equipamento individual do mergulhador?',
        Answer: 'white',
      },
      '8-2 b 152': {
        Question:
          'O plano de contingência prevê o passo a passo para abortar o mergulho em andamento, retornar a cesta, sinete ou ao a superfície com a ajuda do Bellman ou o mergulhador de emergência em caso de afogamento?',
        Answer: 'white',
      },
      '8-2 c 153': {
        Question:
          'A equipe de mergulho é conscientizada quanto ao possível risco de afogamento durante a atividade que executam quando embarcados na frente/embarcação de mergulho?',
        Answer: 'white',
      },
      '8-3 a 154': {
        Question:
          'Os supervisores são capacitados e treinados para orientar a realização do resgate do mergulhador pelo mergulhador de emergência? ',
        Answer: 'white',
      },
      '8-3 b 155': {
        Question:
          'As equipes de mergulho são treinadas e capacitadas para realizar o resgate do mergulhador pelo mergulhador de emergência (bellman) em caso de asfixia?',
        Answer: 'white',
      },
      '8-3 c 156': {
        Question:
          'Existe periodicidade para realização de simulado de resgate do mergulhador pelo mergulhador de emergência? ',
        Answer: 'white',
      },
      '8-3 d 157': {
        Question:
          'O mergulhador de emergência na superfície, permanece equipado e pronto para intervir no menor tempo possível sendo num simulado ou durante uma intercorrência real? ',
        Answer: 'white',
      },
      '8-3 e 158': {
        Question:
          'A empresa controla através do seu departamento de segurança o treinamento frequente envolvendo toda a equipe de mergulho no procedimento de resgate do mergulhador através do mergulhador de emergência? ',
        Answer: 'white',
      },
      '8-3 f 159': {
        Question:
          'Quando identificado no simulado uma falha no procedimento ou um tempo excessivo para executá-lo, é reforçada a necessidade de intensificar o treinamento com os envolvidos?',
        Answer: 'white',
      },
      '8-3 h 160': {
        Question:
          'Há alguma planilha de acompanhamento de desempenho dos simulados?',
        Answer: 'white',
      },
      '8-4 a 161': {
        Question:
          'No local de trabalho existe uma câmara hiperbárica dedicada e operacional para utilização imediata para casos de emergência com acesso totalmente desobstruído? ',
        Answer: 'white',
      },
      '8-4 b 162': {
        Question:
          'O plano de inspeção e manutenção da câmara hiperbárica é realizado por pessoal qualificado e treinado para garantir a sua operacionalidade em caso de mergulho programado com descompressão ou emergência hiperbárica? ',
        Answer: 'white',
      },
      '8-4 c 163': {
        Question:
          'Caso haja uma segunda câmara hiperbárica na frente/embarcação de mergulho a mesma é testada periodicamente e é registrado em logbook?  ',
        Answer: 'white',
      },
      '8-4 d 164': {
        Question:
          'As equipes de mergulho são monitoradas quanto as aptidões físicas e mentais e tem competência para a utilização imediata da câmara hiperbárica em caso de necessidade? ',
        Answer: 'white',
      },
      '8-4 e 165': {
        Question:
          'A câmara hiperbárica (CH) é testada e pressurizada no início do embarque, para confirmar a sua operacionalidade?',
        Answer: 'white',
      },
      '8-4 f 166': {
        Question:
          'No início da quinzena é realizado o simulado do mergulhador inconsciente com a necessidade de uso da câmara hiperbárica (CH)?',
        Answer: 'white',
      },
      '8-5 a 167': {
        Question:
          'Existem, ao menos, dois membros na equipe de mergulho capacitados e treinados em suporte básico a vida, no atendimento inicial a emergências subaquáticas, atendendo assim as normas vigentes (Normam 15)? ',
        Answer: 'white',
      },
      '8-5 b 168': {
        Question:
          'É cobrado eficácia nos simulados dos planos de resposta a emergência com ênfase na comunicação adequada?',
        Answer: 'white',
      },
      '8-5 c 169': {
        Question:
          'O treinamento de reciclagem em atendimento a emergências de primeiros socorros atende as orientações de acordo com as normas vigentes?',
        Answer: 'white',
      },
      '8-5 d 170': {
        Question:
          'São realizados simulados de primeiros socorros com as equipes de mergulho com ênfase no atendimento do mergulhador que sofreu uma asfixia, na frente/embarcação de mergulho?',
        Answer: 'white',
      },
      '8-6 a 171': {
        Question:
          'Há profissional de saúde de nível superior devidamente habilitado na frente /embarcação de mergulho?',
        Answer: 'white',
      },
      '8-6 b 172': {
        Question:
          'O profissional de saúde é capacitado e treinado em suporte avançado a vida e atendimento de emergência subaquática conforme NORMAM-15 (capitulo 1, item 0138)?',
        Answer: 'white',
      },
      '8-7 a 173': {
        Question:
          'A embarcação possui enfermaria que atenda aos requisitos da Normam-01, da NR-30 e demais legislações pertinentes?',
        Answer: 'white',
      },
      '8-7 b 174': {
        Question:
          'A enfermaria possui quantidade de equipamentos e medicamentos que atende a lista de itens mínimos de enfermaria padronizada pela Petrobras para frente/embarcação de mergulho?',
        Answer: 'white',
      },
      '8-8 a 175': {
        Question:
          'Os meios de comunicação com o suporte médico (médico hiperbarista) em terra são adequados e tem backup?',
        Answer: 'white',
      },
      '8-8 b 176': {
        Question:
          'No procedimento de contingência está previsto o plano de resposta a emergência médica com os recursos de contato telefônico ou por vídeo com o hiperbarista?',
        Answer: 'white',
      },
      '8-8 c 177': {
        Question:
          'Existe plano de atendimento médico emergencial na empresa 24 horas para atender as equipes embarcadas no offshore?',
        Answer: 'white',
      },
      '8-8 d 178': {
        Question:
          'É previsto no procedimento de emergência da empresa, antes de uma operação de mergulho, que haja a verificação de uma unidade marítima mais próxima das operações adequada a receber o mergulhador vitimado com disponibilidade de resgate aeromédico?',
        Answer: 'white',
      },
      '8-9 a 179': {
        Question:
          'Existe procedimento específico para em caso de acidente com necessidade de remoção e transporte para hospital ou clínica em terra?',
        Answer: 'white',
      },
      '8-9 b 180': {
        Question:
          'Os supervisores estão aptos a orientar os responsáveis pelo transporte de acidentados via aérea, de que após a realização de mergulho, o voo deve cumprir um limite de altitude em cumprimento da NORMAM-15?',
        Answer: 'white',
      },
      '8-9 c 181': {
        Question:
          'O Plano de contingência garante acesso a rede hospitalar especializada e a disponibilidade de recursos para um tratamento específico até o pronto reestabelecimento do acidentado?',
        Answer: 'white',
      },
      '8-9 d 182': {
        Question:
          'Existe procedimento da empresa que propicie a remoção do acidentado embarcado até a rede hospitalar?',
        Answer: 'white',
      },
      '8-9 e 183': {
        Question:
          'O RH da empresa está capacitado a proporcionar todo o acompanhamento ao acidentado desde a internação até o seu retorno as atividades? ',
        Answer: 'white',
      },
      '8-10 a 184': {
        Question:
          'É realizada uma criteriosa avaliação médica especializada antes do retorno do mergulhador ao trabalho?',
        Answer: 'white',
      },
      '8-10 b 185': {
        Question:
          'O RH da empresa acompanha o processo de retorno do acidentado as suas atividades laborais após finalizado o tratamento?',
        Answer: 'white',
      },


    },

    'CO-4 – Queimadura': {
      '9-1 a 186': {
        Question:
          'Os equipamentos que fazem parte do sistema de comunicação e monitoramento do mergulhador são testados periodicamente? ',
        Answer: 'white',
      },
      '9-1 b 187': {
        Question:
          'Os equipamentos que compõe o sistema de comunicação e monitoramento do mergulhador estão inseridos no plano de manutenção do equipamento de mergulho? ',
        Answer: 'white',
      },
      '9-1 c 188': {
        Question:
          'Durante uma emergência de mergulho por queimadura do mergulhador, o controle de mergulho mantém comunicação com o Bellman?',
        Answer: 'white',
      },
      '9-2 a 189': {
        Question:
          'Os supervisores de mergulho são capacitados e treinados no plano de resposta a emergência, abortar o mergulho em andamento, retornar a cesta ou sinete em caso de queimadura no mergulhador causada pela falha do equipamento individual do mergulhador?',
        Answer: 'white',
      },
      '9-2 b 190': {
        Question:
          'O plano de contingência prevê o passo a passo para abortar o mergulho em caso de queimadura no mergulhador?',
        Answer: 'white',
      },
      '9-2 c 191': {
        Question:
          'A equipe de mergulho é conscientizada quanto ao possível risco de queimadura no mergulhador durante a atividade que executam quando embarcados na frente/embarcação de mergulho?',
        Answer: 'white',
      },
      '9-3 a 192': {
        Question:
          'Existem, ao menos, dois membros na equipe de mergulho capacitados e treinados em suporte básico a vida, no atendimento inicial a emergências subaquáticas, atendendo assim as normas vigentes (Normam 15)? ',
        Answer: 'white',
      },
      '9-3 b 193': {
        Question:
          'É cobrado eficácia nos simulados dos planos de resposta a emergência com ênfase na comunicação adequada?',
        Answer: 'white',
      },
      '9-3 c 194': {
        Question:
          'O treinamento de reciclagem em atendimento a emergências de primeiros socorros atende as orientações de acordo com as normas vigentes?',
        Answer: 'white',
      },
      '9-3 d 195': {
        Question:
          'São realizados simulados de primeiros socorros com as equipes de mergulho com ênfase no atendimento do mergulhador queimado, na frente/embarcação de mergulho?',
        Answer: 'white',
      },
      '9-3 e 196': {
        Question:
          'A câmara hiperbárica (CH) é testada e pressurizada no início do embarque, para confirmar a sua operacionalidade? ',
        Answer: 'white',
      },
      '9-3 f 197': {
        Question:
          'No início da quinzena é realizado o simulado do mergulhador inconsciente com a necessidade de uso da câmara hiperbárica (CH)?',
        Answer: 'white',
      },
      '9-4 a 198': {
        Question:
          'Há profissional de saúde de nível superior devidamente habilitado na frente /embarcação de mergulho?',
        Answer: 'white',
      },
      '9-4 b 199': {
        Question:
          'O profissional de saúde é capacitado e treinado em suporte avançado a vida e atendimento de emergência subaquática conforme NORMAM-15 (capitulo 1, item 0138)?',
        Answer: 'white',
      },
      '9-5 a 200': {
        Question:
          'A embarcação possui enfermaria que atenda aos requisitos da Normam-01, da NR-30 e demais legislações pertinentes?',
        Answer: 'white',
      },
      '9-5 b 201': {
        Question:
          'A enfermaria possui quantidade de equipamentos e medicamentos que atende a lista de itens mínimos de enfermaria padronizada pela Petrobras para frente/embarcação de mergulho?  ',
        Answer: 'white',
      },
      '9-6 a 202': {
        Question:
          'Os meios de comunicação com o suporte médico (médico hiperbárista) em terra são adequados e tem backup? ',
        Answer: 'white',
      },
      '9-6 b 203': {
        Question:
          'No procedimento de contingência está previsto o plano de resposta a emergência médica com os recursos de contato telefônico ou por vídeo com o médico hiperbarista?',
        Answer: 'white',
      },
      '9-6 c 204': {
        Question:
          'Existe plano de atendimento médico emergencial na empresa 24 horas para atender as equipes embarcadas no offshore?',
        Answer: 'white',
      },
      '9-7 a 205': {
        Question:
          'Existe procedimento específico para em caso de acidente com necessidade de remoção e transporte para hospital ou clínica em terra?',
        Answer: 'white',
      },
      '9-7 b 206': {
        Question:
          'Os supervisores estão aptos a orientar os responsáveis pelo transporte de acidentados via aérea, de que após a realização de mergulho, o voo deve cumprir um limite de altitude em cumprimento da NORMAM-15?',
        Answer: 'white',
      },
      '9-7 c 207': {
        Question:
          'O Plano de contingência garante acesso a rede hospitalar especializada e a disponibilidade de recursos para um tratamento específico até o pronto reestabelecimento do acidentado?',
        Answer: 'white',
      },
      '9-7 d 208': {
        Question:
          'Existe procedimento da empresa que propicie a remoção do acidentado embarcado até a rede hospitalar?',
        Answer: 'white',
      },
      '9-7 e 209': {
        Question:
          'O RH da empresa está capacitado a proporcionar todo o acompanhamento ao acidentado desde a internação até o seu retorno as atividades? ',
        Answer: 'white',
      },
      '9-8 a 210': {
        Question:
          'É realizada uma criteriosa avaliação médica especializada antes do retorno do mergulhador ao trabalho?',
        Answer: 'white',
      },
      '9-8 b 211': {
        Question:
          'O RH da empresa acompanha o processo de retorno do acidentado as suas atividades laborais após finalizado o tratamento?',
        Answer: 'white',
      },





    },
  };

  let listKeysQuestionFalhaEquipamentoIndividual=[
    {
      'AM-1 - Armazenamento e transporte inadequado dos equipamentos': [
        { '1-1': { Check: [], Answer: 'white' } },
        { '1-2': { Check: [], Answer: 'white' } },
        { '1-3': { Check: [], Answer: 'white' } },
        { '1-4': { Check: [], Answer: 'white' } },
      ].reverse(),
      'AM-2- Manuseio inadequado do equipamento': [
        { '2-1': { Check: [], Answer: 'white' } },
        { '2-2': { Check: [], Answer: 'white' } },
        { '2-3': { Check: [], Answer: 'white' } },

      ].reverse(),
      'AM-3 - Inspeção e manutenção inadequada do equipamento': [
        { '3-1': { Check: [], Answer: 'white' } },
        { '3-2': { Check: [], Answer: 'white' } },
        { '3-3': { Check: [], Answer: 'white' } },

      ].reverse(),
      'AM-4 - Equipamento fora de especificação técnica': [
        { '4-1': { Check: [], Answer: 'white' } },
        { '4-2': { Check: [], Answer: 'white' } },
        { '4-3': { Check: [], Answer: 'white' } },
        { '4-4': { Check: [], Answer: 'white' } },

      ].reverse(),
      'AM5 - Falha de equipagem': [
        { '5-1': { Check: [], Answer: 'white' } },
        { '5-2': { Check: [], Answer: 'white' } },
        { '5-3': { Check: [], Answer: 'white' } },
        { '5-4': { Check: [], Answer: 'white' } },

      ].reverse(),
      'CO-1 - Doença descompressiva': [
        { '6-1': { Check: [], Answer: 'white' } },
        { '6-2': { Check: [], Answer: 'white' } },
        { '6-3': { Check: [], Answer: 'white' } },
        { '6-4': { Check: [], Answer: 'white' } },
        { '6-5': { Check: [], Answer: 'white' } },
        { '6-6': { Check: [], Answer: 'white' } },
        { '6-7': { Check: [], Answer: 'white' } },
        { '6-8': { Check: [], Answer: 'white' } },
        { '6-9': { Check: [], Answer: 'white' } },
        { '6-10': { Check: [], Answer: 'white' } },

      ],
      'CO-2 – Afogamento': [
        { '7-1': { Check: [], Answer: 'white' } },
        { '7-2': { Check: [], Answer: 'white' } },
        { '7-3': { Check: [], Answer: 'white' } },
        { '7-4': { Check: [], Answer: 'white' } },
        { '7-5': { Check: [], Answer: 'white' } },
        { '7-6': { Check: [], Answer: 'white' } },
        { '7-7': { Check: [], Answer: 'white' } },
        { '7-8': { Check: [], Answer: 'white' } },
        { '7-9': { Check: [], Answer: 'white' } },
        { '7-10': { Check: [], Answer: 'white' } },
        { '7-11': { Check: [], Answer: 'white' } },


      ],

      'CO-3 – Asfixia': [
        { '8-1': { Check: [], Answer: 'white' } },
        { '8-2': { Check: [], Answer: 'white' } },
        { '8-3': { Check: [], Answer: 'white' } },
        { '8-4': { Check: [], Answer: 'white' } },
        { '8-5': { Check: [], Answer: 'white' } },
        { '8-6': { Check: [], Answer: 'white' } },
        { '8-7': { Check: [], Answer: 'white' } },
        { '8-8': { Check: [], Answer: 'white' } },
        { '8-9': { Check: [], Answer: 'white' } },
        { '8-10': { Check: [], Answer: 'white' } },



      ],
      'CO-4 – Queimadura': [
        { '9-1': { Check: [], Answer: 'white' } },
        { '9-2': { Check: [], Answer: 'white' } },
        { '9-3': { Check: [], Answer: 'white' } },
        { '9-4': { Check: [], Answer: 'white' } },
        { '9-5': { Check: [], Answer: 'white' } },
        { '9-6': { Check: [], Answer: 'white' } },
        { '9-7': { Check: [], Answer: 'white' } },
        { '9-8': { Check: [], Answer: 'white' } },

      ],

    }]


  export {TitlesAndQuestionsFalhaEquipamentoIndividual,listKeysQuestionFalhaEquipamentoIndividual,titleFormFalhaEquipamentoIndividual,titleHazardFalhaEquipamentoIndividual,SecondIdFalhaEquipamentoIndividual,listAMCOFalhaEquipamentoIndividual}

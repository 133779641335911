/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AiOutlineFileDone, AiOutlineForm} from 'react-icons/ai';
import { ContainerComponent } from '../../components/Container';
import history from '../../services/history';
import { Container, Content, DashboardItem } from '../Dashboard/styles';

export default function Dashboard() {
  const { groupLevel } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const [dashboard, setDashboard] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isSudo, setIsSudo] = useState(false);
  const [isCompany, setIsCompany] = useState(false);
  const [isAuditor, setIsAuditor] = useState(false);
  const [isPetrobras, setIsPetrobras] = useState(false);

  useEffect(() => {
    const { admin, sudo, company, auditor,petrobras } = groupLevel;
    setIsSudo(sudo);
    setIsAdmin(admin);
    setIsCompany(company);
    setIsAuditor(auditor);
    setIsPetrobras(petrobras);
  }, [groupLevel]);

  useEffect(() => {
    const dashboardFunctions = {
      diagrama() {
        return history.push('/bowtie/diagrama');
      },
      checklist() {
        return history.push('/bowtie/checklist');
      }
    };

    setDashboard(dashboardFunctions);
  }, [dispatch]);

  return (
    // MENU CARDS
    <ContainerComponent>
      <Container>
        <Content>
          <ul>
             {/* MENU CARD DIAGRAMA */}
            {isAdmin || isCompany || isSudo || isAuditor || isPetrobras ? (
            <DashboardItem >
              <button type="button" onClick={dashboard.diagrama}>
                <AiOutlineFileDone size={56} color="#fff" />
              </button>
              <span>DIAGRAMA</span>
            </DashboardItem>
             ) : null}
              {/* MENU CARD CHECKLIST */}
            {isAdmin || isCompany || isSudo || isAuditor || isPetrobras ? (
            <DashboardItem>
              <button type="button" onClick={dashboard.checklist}>
                <AiOutlineForm size={56} color="#fff" />
              </button>
              <span>CHECKLIST</span>
            </DashboardItem>
             ) : null}
          </ul>
        </Content>
      </Container>
    </ContainerComponent>
  );
}

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { MdPersonAdd } from 'react-icons/md';
import { toast } from 'react-toastify';
import { checkForm, addUser } from './util';

import { ContainerComponent } from '../../components/Container';
import { DivRegister, DivInputs, SubmitButton, DivLabels } from './styles';

export default function AddUser() {
  document.title = 'Adicionar Usuário';

  const { groupLevel } = useSelector(state => state.auth);
  const [isAdmin, setIsAdmin] = useState(false);

  const [login, setLogin] = useState('');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [empresa,setEmpresa]=useState('')
  const [userLevel, setUserLevel] = useState(false);

  useEffect(() => {
    const { admin } = groupLevel;

    setIsAdmin(admin);
  }, [groupLevel]);

  async function handleSubmit(event) {
    event.preventDefault();
    try {
      const userForm = { login, name, password, email,empresa ,userLevel };

      const isValidForm = checkForm(userForm);

      if (isValidForm !== true) {
        toast.error(isValidForm);
      } else {
        const isCreated = await addUser(userForm);

        if (isCreated === true) {
          toast.success('Usuário cadastrado com sucesso');
          setLogin('');
          setName('');
          setPassword('');
          setEmail('');
          setEmpresa('');
          setUserLevel(false);
        } else {
          toast.error(isCreated);
        }
      }
    } catch (error) {}
  }

  return (
    <ContainerComponent>
      <DivRegister>
        <MdPersonAdd />

        <h2>Novo usuário</h2>

        <form>
          <DivInputs>
            <DivLabels>
              <label htmlFor="login">
                Login:
                <input
                  name="login"
                  type="text"
                  id="login"
                  value={login}
                  placeholder="Login do usuário"
                  onChange={e => setLogin(e.target.value)}
                />
                &emsp;*
              </label>
            </DivLabels>

            <DivLabels>
              <label htmlFor="name">
                Nome:
                <input
                  name="name"
                  type="text"
                  id="name"
                  value={name}
                  placeholder="Nome do usuário"
                  onChange={e => setName(e.target.value)}
                />
                &emsp;*
              </label>
            </DivLabels>

            <DivLabels>
              <label htmlFor="password">
                Senha:
                <input
                  name="password"
                  type="text"
                  id="password"
                  value={password}
                  placeholder="Mínimo de 6 dígitos"
                  onChange={e => setPassword(e.target.value)}
                />
                &emsp;*
              </label>
            </DivLabels>

            <DivLabels>
              <label htmlFor="email">
                Email:
                <input
                  name="email"
                  type="email"
                  id="email"
                  value={email}
                  placeholder="Utilizado para reenvio de senha"
                  onChange={e => setEmail(e.target.value)}
                />
                &emsp;*
              </label>
            </DivLabels>

            <DivLabels>
                <label htmlFor="empresa">Empresa</label>
                <select
                  name="empresa"
                  id="empresa"
                  onChange={e => setEmpresa(e.target.value)}
                >
                  <option value=""></option>
                  <option value="OCEANICA">Oceanica</option>
                  <option value="SISTAC">Sistac</option>
                  <option value="BELOV">Belov</option>
                  <option value="CONTINENTAL">Continental</option>
                </select>
              </DivLabels>

            {isAdmin ? (
              <DivLabels>
                <label htmlFor="level">Tipo do Usuário</label>
                <select
                  name="level"
                  id="level"
                  onChange={e => setUserLevel(e.target.value)}
                >
                  <option value=" "> </option>
                  <option value="sudo">Gerencial</option>
                  <option value="normal">Comum</option>
                  <option value="company">Empresarial</option>
                  <option value="auditor">Auditor</option>
                  <option value="petrobras">Petrobras</option>
                </select>
              </DivLabels>
            ) : null}
            <SubmitButton type="submit" onClick={event => handleSubmit(event)}>
              Cadastrar
            </SubmitButton>
          </DivInputs>
        </form>
      </DivRegister>
    </ContainerComponent>
  );
}

import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { SpinLoader } from 'react-css-loaders';
import { FaSearch } from 'react-icons/fa';
import api from '../../services/api';

import {
  Chart1,
  Chart2,
  Chart3,
  Chart4,
  Chart5,
  Chart6,
} from '../../components/Charts';

import {
  Container,
  Content,
  DivGrid,
  Title,
  Scroll,
  Alert,
  DivStatistc,
  ReturnLink,
} from './styles';
import {
  DivCharts,
  LargeColumn,
  SmallColumn,
  LargeChart,
  SmallChart,
} from '../SearchAlert/styles';

export default function ShowResults() {
  document.title = 'Resultados';

  const { alertsFound } = useSelector(state => state.search);

  const { groupLevel } = useSelector(state => state.auth);

  const [chartData, setChartData] = useState(false);

  const [isAdmin, setIsAdmin] = useState(false);
  const [isSudo, setIsSudo] = useState(false);
  const [isAuditor, setIsAuditor] = useState(false);

  const apiCall = useCallback(async () => {
    const response = await api.get('chart/userSearch');

    if (response.status === 200) {
      setChartData(response.data);
    }
  }, []);

  useEffect(() => {
    apiCall();
  }, [apiCall]);

  useEffect(() => {
    const { admin, sudo, auditor } = groupLevel;

    setIsSudo(sudo);
    setIsAdmin(admin);
    setIsAuditor(auditor);
  }, [groupLevel]);

  return (
    <Container>
      <Content>
        <DivGrid>
          <Title>
            <h2>Resultados encontrados</h2>
          </Title>

          <Scroll>
            <Alert>
              {alertsFound && alertsFound.length > 4 ? (
                <ReturnLink>
                  <Link to="/pesquisa">
                    <FaSearch /> Pesquisar novamente
                  </Link>
                </ReturnLink>
              ) : null}
              {alertsFound.map(alert => (
                <li key={String(alert._id)}>
                  <Link to={`/alerta/${alert._id}`}>
                    <strong>{`${alert.title}: `}</strong>
                    <p>&emsp;&emsp;&emsp;&emsp;{alert.description}</p>
                  </Link>
                </li>
              ))}
              <ReturnLink>
                <Link to="/pesquisa">
                  <FaSearch /> Pesquisar novamente
                </Link>
              </ReturnLink>
            </Alert>
          </Scroll>
          {(isAdmin || isSudo || isAuditor) && (
            <DivStatistc>
              <Title>
                <h2>Estatísticas</h2>
              </Title>
              <DivCharts>
                <SmallColumn>
                  <SmallChart>
                    {chartData ? (
                      <Chart1 chart={chartData.chart1} />
                    ) : (
                      <SpinLoader />
                    )}
                  </SmallChart>
                  <SmallChart>
                    {chartData ? (
                      <Chart2 chart={chartData.chart2} />
                    ) : (
                      <SpinLoader />
                    )}
                  </SmallChart>
                </SmallColumn>

                <LargeColumn>
                  <LargeChart>
                    {chartData ? (
                      <Chart3 chart={chartData.chart3} />
                    ) : (
                      <SpinLoader />
                    )}
                  </LargeChart>
                  <LargeChart>
                    {chartData ? (
                      <Chart4 chart={chartData.chart4} />
                    ) : (
                      <SpinLoader />
                    )}
                  </LargeChart>
                </LargeColumn>

                <SmallColumn>
                  <SmallChart>
                    {chartData ? (
                      <Chart5 chart={chartData.chart5} />
                    ) : (
                      <SpinLoader />
                    )}
                  </SmallChart>
                  <SmallChart>
                    {chartData ? (
                      <Chart6 chart={chartData.chart6} />
                    ) : (
                      <SpinLoader />
                    )}
                  </SmallChart>
                </SmallColumn>
              </DivCharts>
            </DivStatistc>
          )}
        </DivGrid>
      </Content>
    </Container>
  );
}

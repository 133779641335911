import styled from 'styled-components';
import { lighten } from 'polished';

export const MenuBar = styled.div`
  width: 196px;
  height: 300px;
  margin-top: 57px;

  ul {
    background: #fbfbfb;
  }
  li {
    background-color: #fbfbfb;
    color: #00529b;

    &:hover {
      cursor: pointer;
      background-color: ${lighten(0.37, '#44e5e7')};
    }
  }
`;

/* eslint-disable prefer-const */
import { toast } from 'react-toastify';

function dateRangeCheck(minYear, maxYear) {
  if (maxYear === '') {
    if (minYear === 1960) {
      return false;
    }

    toast.error('Por favor, complete o período desejado');
    return 'error';
  }

  if (minYear < 1960) {
    toast.error('O período mínimo é a partir de 1960');
    return 'error';
  }

  if (maxYear > new Date().getFullYear) {
    toast.error('O período máximo é até o ano vigente');
    return 'error';
  }

  if (minYear > maxYear) {
    toast.error('O período inicial deve ser inferior ao período final');
    return 'error';
  }

  const date = { minYear, maxYear };

  return date;
}

export function prepareSearchModel(filters) {
  
  let {
    keyWords,
    location,
    causedBy,
    accidentDegree,
    minYear,
    maxYear,
  } = filters;

  keyWords = keyWords !== '' ? keyWords : null;

  location = location !== '' ? location : null;

  causedBy = causedBy !== '' ? causedBy : null;

  accidentDegree = accidentDegree !== '' ? accidentDegree : null;

  const date = dateRangeCheck(minYear, maxYear);

  if (date === 'error') {
    return false;
  }

  if (!keyWords && !location && !causedBy && !accidentDegree && !date) {
    toast.error('Por favor, preencha algum filtro');
    return false;
  }

  const formattedSearchModel = {
    keyWords,
    location,
    causedBy,
    accidentDegree,
    date,
  };

  return formattedSearchModel;
}

/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { Document, Page, pdfjs } from 'react-pdf';
import { toast } from 'react-toastify';

import { SpinLoader } from 'react-css-loaders';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';

import { ContainerComponent } from '../../components/Container';

import {
  DivSelectDocument,
  ComboCompanyName,
  PdfContent,
  DivChangePage,
  DivAwaitSelect,
  InputPage,
  DivSelectPage,
} from './styles';
import api from '../../services/api';

export default function ShowPetrobrasDocument() {
  document.title = 'Petrobras';

  useEffect(() => {
    // Only to lib
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  });

  const { admin, petrobras, sudo, auditor } = useSelector(
    state => state.auth.groupLevel
  );

  const [loading, setLoading] = useState(false);

  const [title, setTitle] = useState(false);
  const [documentBr, setDocumentBR] = useState(false);

  const [numPages, setNumPages] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);

  const [handlePage, setHandlePage] = useState(false);

  useEffect(() => {
    async function getDocumentTitles() {
      if ((admin || petrobras || sudo || auditor) && !title) {
        const response = await api.get('petrobras/all');

        if (response.status === 200) {

          setTitle(response.data);
        }
      }
    }

    getDocumentTitles();
  });

  const changePage = {
    getPreviousPage() {
      if (pageNumber > 1) {
        setPageNumber(pageNumber - 1);
      }
    },

    getNextPage() {
      if (pageNumber < numPages) {
        setPageNumber(pageNumber + 1);
      }
    },

    jumpToPage(selectedPage) {
      if (selectedPage && selectedPage > 0 && selectedPage < numPages + 1) {
        setPageNumber(selectedPage);
      }
    },
  };

  const handleChangePage = event => {
    const selectedPage = event.target.value;
    if (selectedPage && selectedPage > 0 && selectedPage < numPages + 1) {
      setPageNumber(selectedPage);
    }
  };

  const getDocument = useCallback(async value => {
    setLoading(true);
    api
      .get(`/petrobras/files/${value}`, { responseType: 'blob' })
      .then(response => {
        setLoading(false);
        setPageNumber(1);
        setDocumentBR(response.data);
      })
      .catch(error => {
        setLoading(false);
        toast.error('Erro ao exibir documento');
      });
  }, []);

  const handleChoiceDocument = {
    async getDocumentData(comboDocumentTitle) {
      if (comboDocumentTitle && comboDocumentTitle !== ' ') {
        getDocument(comboDocumentTitle);
      }
    },
  };

  document.addEventListener('contextmenu', event => event.preventDefault());

  return (
    <ContainerComponent>
      <DivSelectDocument>
        {loading ? (
          <SpinLoader size="3" />
        ) : (
          <ComboCompanyName>
            <h2>Selecione o documento</h2>

            {title && (
              <select
                name="title"
                id="title"
                onChange={e => {
                  handleChoiceDocument.getDocumentData(e.target.value);
                }}
              >
                <option value=" "> </option>
                {title.map(docc => (

                  <option key={docc.file} value={docc.file}>
                    {docc.title}
                  </option>
                ))}
              </select>
            )}
          </ComboCompanyName>
        )}
      </DivSelectDocument>

      {documentBr ? (
        <>
          <DivSelectPage>
            <DivChangePage>
              <button
                type="button"
                onClick={() => changePage.getPreviousPage()}
              >
                <MdKeyboardArrowLeft />
              </button>

              <span>
                Página {pageNumber} de {numPages}
              </span>

              <button type="button" onClick={() => changePage.getNextPage()}>
                <MdKeyboardArrowRight />
              </button>
            </DivChangePage>

            <InputPage>
              <label htmlFor="jumpToPage">Ir para página: </label>
              <input
                name="jumpToPage"
                type="number"
                id="jumpToPage"
                min="1"
                max={numPages.toString()}
                onChange={e => changePage.jumpToPage(e.target.valueAsNumber)}
              />
            </InputPage>
          </DivSelectPage>

          <PdfContent id="pdfContent">
            <Document
              file={documentBr}
              onLoadSuccess={pdf => setNumPages(pdf.numPages)}
              onLoadError={error => alert('Por favor, tente novamente')}
            >
              <Page pageNumber={pageNumber || 1} width={1109} />
            </Document>
          </PdfContent>

          <DivChangePage>
            <button type="button" onClick={() => changePage.getPreviousPage()}>
              <MdKeyboardArrowLeft />
            </button>

            <span>
              Página {pageNumber} de {numPages}
            </span>

            <button type="button" onClick={() => changePage.getNextPage()}>
              <MdKeyboardArrowRight />
            </button>
          </DivChangePage>
        </>
      ) : (
        <DivAwaitSelect>
          <h1>Selecione um documento acima</h1>
        </DivAwaitSelect>
      )}
    </ContainerComponent>
  );
}

/* eslint-disable no-nested-ternary   */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AiOutlineForm } from 'react-icons/ai';
import { ContainerComponent } from '../../../../components/Container';
import history from '../../../../services/history';
import { Container, Content, DashboardItem } from '../../../Dashboard/styles';

export default function Dashboard() {
  const { groupLevel } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const [dashboard, setDashboard] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isSudo, setIsSudo] = useState(false);
  const [isCompany, setIsCompany] = useState(false);
  const [isAuditor, setIsAuditor] = useState(false);
  

  useEffect(() => {
    const { admin, sudo, company, auditor } = groupLevel;
    setIsSudo(sudo);
    setIsAdmin(admin);
    setIsCompany(company);
    setIsAuditor(auditor);
  }, [groupLevel]);

  useEffect(() => {
    const dashboardFunctions = {
      event1() {
        return history.push('/bowtie/diagrama/checklist1/Event1');
      },
      event2() {
        return history.push('/bowtie/diagrama/checklist1/Event2');
      },
      event3() {
        return history.push('/bowtie/diagrama/checklist1/Event3');
      }
    };

    setDashboard(dashboardFunctions);
  }, [dispatch]);

  /*Pegar o usuário que logou*/
  
 

  return (
    // MENU CARDS
    <ContainerComponent>
      <Container>
        <Content>
          <ul>        
             {/* MENU CARD DIAGRAMA */}
            {(isAdmin || isCompany || isSudo || isAuditor)   ? (
            <DashboardItem>
              <button type="button" onClick={dashboard.event1}>
                <AiOutlineForm size={56} color="#fff" />
              </button>
              <span>Falta de Suprimento Respiratório</span>
            </DashboardItem>
             ) : null}
              {/* MENU CARD CHECKLIST */}
            {(isAdmin || isCompany || isSudo || isAuditor)   ? (
            <DashboardItem>
              <button type="button" disabled onClick={dashboard.event2}>
                <AiOutlineForm size={56} color="#fff" />
              </button>
              <span>Evento 2 (Em Breve)</span>
            </DashboardItem>
             ) : null}
              {/* MENU CARD CHECKLIST */}
            {(isAdmin || isCompany || isSudo || isAuditor)  ? (
            <DashboardItem>
              <button type="button" disabled onClick={dashboard.checklist3}>
                <AiOutlineForm size={56} color="#fff" />
              </button>
              <span>Evento 3 (Em Breve)</span>
            </DashboardItem>
             ) : null}
            
              
          </ul>
        </Content>
      </Container>
    </ContainerComponent>
  );
}

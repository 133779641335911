import styled from 'styled-components';

export const DivLogo = styled.div`
  position: absolute;
  right: 32px;
  top: 64px;

  img {
    width: 196px;
  }
`;

export const StaticView = styled.div`
  display: flex;
  flex-direction: column;
  width: 1109px;
  margin-top: 64px;
  border: solid 2px #00529b;
  border-radius: 5px;
  background: #fbfbfb;
  position: relative;

  h1 {
    margin: 64px auto 0 356px;
    color: #00529b;
    font-size: 32px;
  }

  h2 {
    margin-top: 48px;
    color: #b56a1e;
    padding-left: 64px;
    font-size: 21px;
    font-weight: bold;
  }

  h3 {
    margin-top: 12px;
    color: #b56a1e;
    padding-left: 64px;
    font-size: 18px;
    font-weight: bold;
  }

  p {
    margin-top: 24px;
    color: #00529b;
    text-align: justify;
    padding-left: 32px;
    font-size: 16px;
    width: 824px;
    line-height: 32px;
  }

  span {
    margin-left: 48px;
    margin-top: 16px;
    color: #b56a1e;
    text-align: justify;
    padding-left: 32px;
    font-size: 14px;
    width: 770px;
    line-height: 20px;
  }

  p + span {
    margin-top: 48px;
  }

  img {
    max-width: 412px;
    margin-left: 380px;
  }
`;

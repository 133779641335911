import { TitlesAndQuestionsEmergenciasMedicasHiberbaricas } from '../formsModel/formEmergenciasMedicasHiberbaricas';

const myArrayVerification = (
  myArray,
  setArray,
  groupName,
  AnswerCheck,
  listKeysQuestion,
  setListKeys,
  questionsSelected,
  setquestionsSelected,
  AnswerForm,
  setAnswerForm,
  AnswerQuestionForm,
  setAnswerQuestionForm
) => {
  let groupNameSplit = groupName.split(' ');
  myArray.forEach(el => {
    for (let FirstKey of Object.keys(el)) {
      for (let SecondKey of Object.keys(el[FirstKey])) {
        let KeySplit = SecondKey.split(' ');
        if (groupNameSplit[0] === KeySplit[0]) {
          if (!questionsSelected.includes(groupName)) {
            for (let el of listKeysQuestion[0][FirstKey]) {
              if (Object.keys(el)[0] === groupNameSplit[0]) {
                let dict = {};
                dict[groupName] = AnswerCheck;
                AnswerForm.push(dict);
                setAnswerForm([...AnswerForm]);
                AnswerQuestionForm.push({
                  tagQuestion: groupName,
                  question:
                    TitlesAndQuestionsEmergenciasMedicasHiberbaricas[FirstKey][
                      groupName
                    ].Question,
                });
                setAnswerQuestionForm([...AnswerQuestionForm]);

                el[groupNameSplit[0]]['Check'].push(AnswerCheck);
                questionsSelected.push(groupName);
                setListKeys([...listKeysQuestion]);
                setquestionsSelected([...questionsSelected]);
                break;
              }
            }
          } else {
            for (let el of listKeysQuestion[0][FirstKey]) {
              if (Object.keys(el)[0] === groupNameSplit[0]) {
                let dict = {};
                dict[groupName] = AnswerCheck;
                AnswerForm[questionsSelected.indexOf(groupName)] = dict;
                setAnswerForm([...AnswerForm]);
                el[groupNameSplit[0]]['Check'][
                  el[groupNameSplit[0]]['Check'].length - 1
                ] = AnswerCheck;
                setListKeys([...listKeysQuestion]);
                break;
              }
            }
          }

          break;
        }
      }

      for (let el of listKeysQuestion[0][FirstKey]) {
        if (Object.keys(el)[0] === groupNameSplit[0]) {
          if (
            el[groupNameSplit[0]]['Check'].includes('No') &&
            el[groupNameSplit[0]]['Check'].includes('Yes')
          ) {
            el[groupNameSplit[0]]['Answer'] = 'yellow';
            setListKeys([...listKeysQuestion]);
          } else if (
            el[groupNameSplit[0]]['Check'].includes('Yes') &&
            !el[groupNameSplit[0]]['Check'].includes('No')
          ) {
            el[groupNameSplit[0]]['Answer'] = 'green';
            setListKeys([...listKeysQuestion]);
          } else if (
            !el[groupNameSplit[0]]['Check'].includes('Yes') &&
            el[groupNameSplit[0]]['Check'].includes('No')
          ) {
            el[groupNameSplit[0]]['Answer'] = 'red';
            setListKeys([...listKeysQuestion]);
          } else {
            el[groupNameSplit[0]]['Answer'] = 'white';
            setListKeys([...listKeysQuestion]);
          }
        }
      }
    }
  });

  setArray([...myArray]);
};

const VerificationEmergenciasMedicasHiberbaricas = (
  listKeysQuestion,
  setListKeys,
  groupName,
  AnswerCheck,
  myArray,
  setArray,
  questionsSelected,
  setquestionsSelected,
  AnswerForm,
  setAnswerForm,
  AnswerQuestionForm,
  setAnswerQuestionForm
) => {
  if (groupName === '1-1 a 1') {
    myArrayVerification(
      myArray,
      setArray,
      '2-1 a 61',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
    myArrayVerification(
      myArray,
      setArray,
      '3-1 a 107',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
  } else if (groupName === '1-1 b 2') {
    myArrayVerification(
      myArray,
      setArray,
      '2-1 b 62',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
    myArrayVerification(
      myArray,
      setArray,
      '3-1 b 108',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
  } else if (groupName === '1-1 c 3') {
    myArrayVerification(
      myArray,
      setArray,
      '2-1 c 63',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
    myArrayVerification(
      myArray,
      setArray,
      '3-1 c 109',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
  } else if (groupName === '1-1 e 5') {
    myArrayVerification(
      myArray,
      setArray,
      '2-1 f 66',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
    myArrayVerification(
      myArray,
      setArray,
      '3-1 e 111',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
  } else if (groupName === '1-2 c 8') {
    myArrayVerification(
      myArray,
      setArray,
      '4-7 a 180',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
  } else if (groupName === '1-2 e 10') {
    myArrayVerification(
      myArray,
      setArray,
      '1-8 g 45',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
    myArrayVerification(
      myArray,
      setArray,
      '2-9 g 102',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
    myArrayVerification(
      myArray,
      setArray,
      '3-6 h 141',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
    myArrayVerification(
      myArray,
      setArray,
      '4-7 e 184',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
  } else if (groupName === '1-3 a 11') {
    myArrayVerification(
      myArray,
      setArray,
      '2-2 a 67',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
  } else if (groupName === '1-3 c 13') {
    myArrayVerification(
      myArray,
      setArray,
      '2-2 b 68',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
  } else if (groupName === '1-3 d 14') {
    myArrayVerification(
      myArray,
      setArray,
      '2-2 c 69',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
  } else if (groupName === '1-3 g 17') {
    myArrayVerification(
      myArray,
      setArray,
      '2-2 e 71',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
  } else if (groupName === '1-3 h 18') {
    myArrayVerification(
      myArray,
      setArray,
      '2-2 f 72',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
  } else if (groupName === '1-4 a 19') {
    myArrayVerification(
      myArray,
      setArray,
      '2-5 a 82',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
    myArrayVerification(
      myArray,
      setArray,
      '3-2 a 113',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
  } else if (groupName === '1-4 b 20') {
    myArrayVerification(
      myArray,
      setArray,
      '2-5 b 83',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
    myArrayVerification(
      myArray,
      setArray,
      '3-2 b 114',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
  } else if (groupName === '1-4 c 21') {
    myArrayVerification(
      myArray,
      setArray,
      '2-5 c 84',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
    myArrayVerification(
      myArray,
      setArray,
      '3-2 c 115',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
    myArrayVerification(
      myArray,
      setArray,
      '4-3 b 165',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
  }else if (groupName === '1-4 e 23') {
    myArrayVerification(
      myArray,
      setArray,
      '2-5 d 85',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
    myArrayVerification(
      myArray,
      setArray,
      '3-2 e 117',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
    myArrayVerification(
      myArray,
      setArray,
      '4-3 c 166',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
  } else if (groupName === '1-5 a 25') {
    myArrayVerification(
      myArray,
      setArray,
      '2-3 a 73',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
    myArrayVerification(
      myArray,
      setArray,
      '3-4 a 124',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
  } else if (groupName === '1-5 c 27') {
    myArrayVerification(
      myArray,
      setArray,
      '2-3 c 75',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
    myArrayVerification(
      myArray,
      setArray,
      '3-4 c 126',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
  } else if (groupName === '1-5 d 28') {
    myArrayVerification(
      myArray,
      setArray,
      '2-3 d 76',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
    myArrayVerification(
      myArray,
      setArray,
      '3-4 d 127',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
  } else if (groupName === '1-5 e 29') {
    myArrayVerification(
      myArray,
      setArray,
      '3-4 e 128',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
  } else if (groupName === '1-6 a 30') {
    myArrayVerification(
      myArray,
      setArray,
      '2-4 a 77',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
    myArrayVerification(
      myArray,
      setArray,
      '3-5 a 129',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
  } else if (groupName === '1-6 c 32') {
    myArrayVerification(
      myArray,
      setArray,
      '2-4 c 79',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
    myArrayVerification(
      myArray,
      setArray,
      '3-5 c 131',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
  } else if (groupName === '1-6 d 33') {
    myArrayVerification(
      myArray,
      setArray,
      '2-4 d 80',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
    myArrayVerification(
      myArray,
      setArray,
      '3-5 d 132',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
  } else if (groupName === '1-6 e 34') {
    myArrayVerification(
      myArray,
      setArray,
      '2-4 e 81',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
    myArrayVerification(
      myArray,
      setArray,
      '3-5 e 133',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
  } else if (groupName === '1-8 b 40') {
    myArrayVerification(
      myArray,
      setArray,
      '2-9 b 97',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
    myArrayVerification(
      myArray,
      setArray,
      '3-6 c 136',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
  } else if (groupName === '1-8 c 41') {
    myArrayVerification(
      myArray,
      setArray,
      '2-9 c 98',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
    myArrayVerification(
      myArray,
      setArray,
      '3-6 d 137',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
  } else if (groupName === '1-8 d 42') {
    myArrayVerification(
      myArray,
      setArray,
      '2-9 d 99',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
    myArrayVerification(
      myArray,
      setArray,
      '3-6 e 138',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
  } else if (groupName === '1-8 e 43') {
    myArrayVerification(
      myArray,
      setArray,
      '2-9 e 100',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
    myArrayVerification(
      myArray,
      setArray,
      '3-6 f 139',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
  } else if (groupName === '1-10 c 50') {
    myArrayVerification(
      myArray,
      setArray,
      '4-8 d 191',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
  } else if (groupName === '1-10 d 51') {
    myArrayVerification(
      myArray,
      setArray,
      '4-8 e 192',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
  } else if (groupName === '1-11 a 52') {
    myArrayVerification(
      myArray,
      setArray,
      '2-6 a 87',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
    myArrayVerification(
      myArray,
      setArray,
      '3-8 a 147',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
    myArrayVerification(
      myArray,
      setArray,
      '4-5 a 173',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
  } else if (groupName === '1-11 b 53') {
    myArrayVerification(
      myArray,
      setArray,
      '2-6 b 88',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
    myArrayVerification(
      myArray,
      setArray,
      '3-8 b 148',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
    myArrayVerification(
      myArray,
      setArray,
      '4-5 b 174',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
  } else if (groupName === '1-11 c 54') {
    myArrayVerification(
      myArray,
      setArray,
      '2-6 c 89',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
    myArrayVerification(
      myArray,
      setArray,
      '3-8 c 149',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
    myArrayVerification(
      myArray,
      setArray,
      '4-5 c 175',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
  } else if (groupName === '1-11 d 55') {
    myArrayVerification(
      myArray,
      setArray,
      '2-6 d 90',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
  } else if (groupName === '1-12 a 57') {
    myArrayVerification(
      myArray,
      setArray,
      '2-10 a 103',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
    myArrayVerification(
      myArray,
      setArray,
      '3-9 a 151',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
    myArrayVerification(
      myArray,
      setArray,
      '4-9 a 193',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
  } else if (groupName === '1-12 b 58') {
    myArrayVerification(
      myArray,
      setArray,
      '2-10 b 104',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
    myArrayVerification(
      myArray,
      setArray,
      '3-9 b 152',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
    myArrayVerification(
      myArray,
      setArray,
      '4-9 b 194',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
  } else if (groupName === '1-12 c 59') {
    myArrayVerification(
      myArray,
      setArray,
      '2-10 c 105',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
    myArrayVerification(
      myArray,
      setArray,
      '3-9 c 153',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
    myArrayVerification(
      myArray,
      setArray,
      '4-9 c 195',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
  } else if (groupName === '1-12 d 60') {
    myArrayVerification(
      myArray,
      setArray,
      '3-9 d 154',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
    myArrayVerification(
      myArray,
      setArray,
      '4-9 d 196',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
  } else if (groupName === '3-8 d 150') {
    myArrayVerification(
      myArray,
      setArray,
      '4-5 d 176',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
  } else if (groupName === '2-9 a 96') {
    myArrayVerification(
      myArray,
      setArray,
      '3-6 b 135',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
  }else if (groupName === '4-2 b 162') {
    myArrayVerification(
      myArray,
      setArray,
      '4-6 c 179',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
  } else if (groupName === '4-8 b 189') {
    myArrayVerification(
      myArray,
      setArray,
      '4-8 c 190',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
  }
};

export default VerificationEmergenciasMedicasHiberbaricas;

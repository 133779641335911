


const myArrayVerification=(myArray,setArray,groupName,AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm)=>{
  let groupNameSplit = groupName.split(' ');
  myArray.forEach(el => {
    for(let FirstKey of Object.keys(el) ){
      for (let SecondKey of Object.keys(el[FirstKey])){
        let KeySplit = SecondKey.split(' ');
        if (groupNameSplit[0] === KeySplit[0]) {
          if(!questionsSelected.includes(groupName)){
            for(let el of listKeysQuestion[0][FirstKey]){
              if (Object.keys(el)[0] === groupNameSplit[0]) {
                let dict = {};
                dict[groupName] = AnswerCheck;
                AnswerForm.push(dict);
                setAnswerForm([...AnswerForm]);

                el[groupNameSplit[0]]['Check'].push(AnswerCheck);
                questionsSelected.push(groupName);
                setListKeys([...listKeysQuestion]);
                setquestionsSelected([...questionsSelected]);
                break;
              }
            }
          }else{
            for(let el of listKeysQuestion[0][FirstKey] ){
              if (Object.keys(el)[0] === groupNameSplit[0]) {
                let dict = {};
                dict[groupName] = AnswerCheck;
                AnswerForm[questionsSelected.indexOf(groupName)]=dict;
                setAnswerForm([...AnswerForm]);
               el[groupNameSplit[0]]['Check'][el[groupNameSplit[0]]['Check'].length-1]=AnswerCheck
                setListKeys([...listKeysQuestion]);
                break;
              }
            }
          }

            break;
        }


      }

      for(let el of listKeysQuestion[0][FirstKey] ){
        if (Object.keys(el)[0] === groupNameSplit[0]) {
          if (el[groupNameSplit[0]]['Check'].includes('No') && el[groupNameSplit[0]]['Check'].includes('Yes')) {
            el[groupNameSplit[0]]['Answer'] = 'yellow';
            setListKeys([...listKeysQuestion]);
          } else if(el[groupNameSplit[0]]['Check'].includes('Yes') && !el[groupNameSplit[0]]['Check'].includes('No') ){
            el[groupNameSplit[0]]['Answer'] = 'green';
            setListKeys([...listKeysQuestion]);
          }else if(!el[groupNameSplit[0]]['Check'].includes('Yes') && el[groupNameSplit[0]]['Check'].includes('No') ){
            el[groupNameSplit[0]]['Answer'] = 'red';
            setListKeys([...listKeysQuestion]);
          } else {
            el[groupNameSplit[0]]['Answer'] = 'white';
            setListKeys([...listKeysQuestion]);
          }
        }
      }


    }

  });

      setArray([...myArray]);
}

const VerificationIncendioExplosaoCamara= (listKeysQuestion, setListKeys,groupName,AnswerCheck,myArray,setArray,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm)=>{

    if(groupName==="7-1 a 72"){
        myArrayVerification(myArray,setArray,'8-1 b',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
        myArrayVerification(myArray,setArray,'9-1 a',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
    }else if(groupName==="7-1 b 73"){
        myArrayVerification(myArray,setArray,'8-1 c',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
        myArrayVerification(myArray,setArray,'9-1 b',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
    }else if(groupName==="7-1 c 74"){
        myArrayVerification(myArray,setArray,'8-1 d',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
        myArrayVerification(myArray,setArray,'9-1 c',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
    }else if(groupName==="7-3 a 77"){
        myArrayVerification(myArray,setArray,'8-2 a',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);

    }else if(groupName==="7-3 b 78"){
        myArrayVerification(myArray,setArray,'8-2 b',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);

    }else if(groupName==="7-3 c 79"){
        myArrayVerification(myArray,setArray,'8-2 c',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);

    }else if(groupName==="7-8 a 94"){
        myArrayVerification(myArray,setArray,'8-3 a',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);

    }else if(groupName==="7-8 b 95"){
        myArrayVerification(myArray,setArray,'8-3 b',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);

    }else if(groupName==="7-9 a 96"){
        myArrayVerification(myArray,setArray,'8-4 a',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
        myArrayVerification(myArray,setArray,'9-2 a',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
    }else if(groupName==="7-9 b 97"){
        myArrayVerification(myArray,setArray,'8-4 b',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
        myArrayVerification(myArray,setArray,'9-2 b',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
    }else if(groupName==="7-9 c 98"){
        myArrayVerification(myArray,setArray,'8-4 c',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
        myArrayVerification(myArray,setArray,'9-2 c',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
    }else if(groupName==="7-10 a 99"){
        myArrayVerification(myArray,setArray,'8-5 a',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
        myArrayVerification(myArray,setArray,'9-3 a',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
    }else if(groupName==="7-10 b 100"){
        myArrayVerification(myArray,setArray,'8-5 b',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
        myArrayVerification(myArray,setArray,'9-3 b',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
    }else if(groupName==="7-10 c 101"){
        myArrayVerification(myArray,setArray,'8-5 c',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
        myArrayVerification(myArray,setArray,'9-3 c',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
    }else if(groupName==="7-10 d 102"){
        myArrayVerification(myArray,setArray,'8-5 d',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
        myArrayVerification(myArray,setArray,'9-3 d',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
    }else if(groupName==="7-11 a 103"){
        myArrayVerification(myArray,setArray,'8-6 a',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
        myArrayVerification(myArray,setArray,'9-4 a',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
    }else if(groupName==="7-11 b 104"){
        myArrayVerification(myArray,setArray,'8-6 b',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
        myArrayVerification(myArray,setArray,'9-4 b',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
    }else if(groupName==="7-11 c 105"){
        myArrayVerification(myArray,setArray,'8-6 c',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
        myArrayVerification(myArray,setArray,'9-4 c',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
    }else if(groupName==="7-11 d 106"){
        myArrayVerification(myArray,setArray,'8-6 d',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
        myArrayVerification(myArray,setArray,'9-4 d',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
    }else if(groupName==="7-12 a 107"){
        myArrayVerification(myArray,setArray,'8-7 a',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
        myArrayVerification(myArray,setArray,'9-5 a',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
    }else if(groupName==="7-12 b 108"){
        myArrayVerification(myArray,setArray,'8-7 b',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
        myArrayVerification(myArray,setArray,'9-5 b',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
    }else if(groupName==="7-13 a 109"){
        myArrayVerification(myArray,setArray,'8-8 a',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
        myArrayVerification(myArray,setArray,'9-6 a',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
    }else if(groupName==="7-13 b 110"){
        myArrayVerification(myArray,setArray,'8-8 b',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
        myArrayVerification(myArray,setArray,'9-6 b',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
    }else if(groupName==="7-14 a 111"){
        myArrayVerification(myArray,setArray,'8-9 a',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
        myArrayVerification(myArray,setArray,'9-7 a',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
    }else if(groupName==="7-14 b 112"){
        myArrayVerification(myArray,setArray,'8-9 b',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
        myArrayVerification(myArray,setArray,'9-7 b',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
    }else if(groupName==="7-14 c 113"){
        myArrayVerification(myArray,setArray,'8-9 c',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
        myArrayVerification(myArray,setArray,'9-7 c',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
    }else if(groupName==="7-15 a 114"){
        myArrayVerification(myArray,setArray,'8-10 a',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
        myArrayVerification(myArray,setArray,'9-10 a',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
    }else if(groupName==="7-15 b 115"){
        myArrayVerification(myArray,setArray,'8-10 b',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
        myArrayVerification(myArray,setArray,'9-10 b',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
    }else if(groupName==="7-15 c 116"){
        myArrayVerification(myArray,setArray,'8-10 c',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
        myArrayVerification(myArray,setArray,'9-10 c',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
    }else if(groupName==="7-15 d 117"){
        myArrayVerification(myArray,setArray,'8-10 d',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
        myArrayVerification(myArray,setArray,'9-10 d',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
    }else if(groupName==="7-16 a 118"){
        myArrayVerification(myArray,setArray,'8-11 a',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
        myArrayVerification(myArray,setArray,'9-11 a',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
    }else if(groupName==="7-16 b 119"){
        myArrayVerification(myArray,setArray,'8-11 b',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
        myArrayVerification(myArray,setArray,'9-11 b',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
    }


}

export default VerificationIncendioExplosaoCamara;

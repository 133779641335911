import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { signInSuccess, signFailure } from './actions';

import api from '../../../services/api';
import history from '../../../services/history';

export function* signIn({ payload }) {
  try {
    const { login, password } = payload;

    const response = yield call(api.post, 'sessions', { login, password });

    const { err } = response.data;

    if (!err) {
      const { user, token } = response.data;


      const { createdBy, groupLevel, empresa,name} = user;


      const nameBusiness=empresa;

      let PassCompany;

      let TokenCompany={
        'OCEANICA':'eyJSb2xlIjoiQWRtaW4iLCJJc',
        'SISTAC':'CJJc3N1ZXIiOiJJc3N1Z',
        'BELOV':'VybmFtZSI6IkphdmFJblVzZ',
        'CONTINENTAL':'ZSIsImV4cCI6MTY0OTIw',
      }

      if( groupLevel.company){
        PassCompany=nameBusiness+TokenCompany[nameBusiness];
      }else if(groupLevel.auditor){
        PassCompany="auditortrue";
      }else if(groupLevel.petrobras){
        PassCompany="petrobrastrue";
      }else{
        PassCompany='secret@key';
      }


      if (token) {
        api.defaults.headers.Authorization = `Bearer ${token}`;
        yield put(signInSuccess(token, login, createdBy, groupLevel,name));

        localStorage.setItem('name',PassCompany);


        history.push('/dashboard');
      }
    } else {
      toast.error(err);
      yield put(signFailure());
    }
  } catch (error) {
    toast.error('Erro ao fazer login');
    yield put(signFailure());
  }
}

export function setToken({ payload }) {
  if (!payload) return;

  const { token } = payload.auth;

  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`;
  }
}

export function signOut() {
  history.push('/');
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest('@auth/SIGN_IN_REQUEST', signIn),
  takeLatest('@auth/SIGN_OUT', signOut),
]);

import styled from 'styled-components';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { lighten } from 'polished';

export const Container = styled.div``;

export const Content = styled.div`
  width: 100%;
  max-width: 1109px;

  margin: 32px auto;
`;

export const DivGrid = styled.div`
  width: 90%;

  display: flex;
  flex-direction: column;

  margin: 0 auto;

  padding: 8px 16px;

  border: solid 2px #00529b;

  border-radius: 5px;
  background: #fbfbfb;
`;

export const Title = styled.div`
  margin: 16px 0;
  font-size: 24px;
  color: #00529b;
`;

export const Scroll = styled(PerfectScrollbar)`
  margin: 24px 12px 12px 12px;
  list-style: none;
  text-align: justify;

  background: #fff;
`;

export const Alert = styled.ul`
  width: 100%;
  max-height: 50vh;

  margin-top: 12px;
  position: relative;

  li {
    padding: 24px;

    strong {
      font-size: 19px;
      color: #b56a1e;
      font-weight: bold;

      &:hover {
        color: ${lighten(0.1, '#b56a1e')};
      }
    }

    p {
      margin-top: 4px;
      font-size: 16px;
    }

    & + li {
      border-top: solid 1px #eee;
      margin-top: 24px;
    }
  }
`;

export const ReturnLink = styled.div`
  position: absolute;
  right: 20px;
  margin-bottom: 8px;

  a {
    font-size: 12px;
    color: #00529b;
    font-weight: bold;

    &:hover {
      color: ${lighten(0.2, '#00529b')};
    }

    svg {
      margin-right: 4px;
    }
  }
`;

export const DivStatistc = styled.div`
  width: 100%;

  border-top: solid 1px #00529b;
  margin: 32px auto;

  padding: 0 16px;
`;

import styled from 'styled-components';
import { darken, lighten } from 'polished';

export const DivGrid = styled.div`
  width: 1108px;
  height: 924px;
  display: flex;
  flex-direction: row;
  border: solid 2px #00529b;
  border-radius: 5px;
  background: #fbfbfb;
`;

export const DivColumn1 = styled.div`
  width: 554px;
  height: 924px;
  display: flex;
  flex-direction: column;

  padding: 32px 64px 32px 63px;
`;

export const DivColumn2 = styled.div`
  width: 554px;
  height: 922px;
  display: flex;
  flex-direction: column;
  border-left: solid 1px #00529b;
  padding: 0 64px 32px 63px;
`;

export const DivNewAlert = styled.div`
  width: 427px;
  height: 40px;
  display: flex;
  justify-content: space-between;

  button {
    width: 196px;
    height: 36px;
    background: #e8eaef;
    border: solid 1px #00529b;
    background: #00529b;
    color: #fff;
    font-size: 2rem;
    border-radius: 5px;

    &:hover {
      transition: 0.5s;
      background: ${lighten(0.13, '#00529b')};
    }
  }

  label {
    position: relative;
    right: 10px;
    color: #00529b;
    font-weight: bold;
  }

  input {
    width: 106px;
    height: 36px;
    border: solid 1px #00529b;
    border-radius: 5px;
    padding-left: 45px;
  }
`;

export const DivBox1 = styled.div`
  width: 427px;
  height: 72px;
  display: flex;
  flex-direction: column;
  margin-top: 37px;

  label {
    color: #00529b;
    font-size: 2.4rem;
    margin-bottom: 8px;
  }

  input {
    width: 427px;
    height: 96px;
    border: solid 1px #00529b;
    border-radius: 5px;
    padding: 3px 8px 3px 8px;
  }

  select {
    width: 213px;
    height: 96px;
    background: #e8eaef;
    border-radius: 5px;
  }
`;

export const DivBox2 = styled.div`
  width: 427px;
  height: 134px;
  display: flex;
  flex-direction: column;
  margin-top: 37px;
  position: relative;

  label {
    font-size: 2.4rem;
    color: #00529b;
    margin-bottom: 8px;
  }

  textarea {
    border: solid 1px #00529b;
    border-radius: 5px;
    padding: 8px 8px 3px 8px;
  }

  button {
    width: 32px;
    height: 32px;
    border: none;
    background: #00529b;
    margin-top: 8px;
    border-radius: 5px;

    position: absolute;
    bottom: -12px;
    right: 0;

    svg {
      align-items: center;
      color: #fff;
    }
  }
`;

export const DivBox3 = styled.div`
  width: 427px;
  height: 150px;
  display: flex;
  flex-direction: column;
  margin-top: 37px;

  label {
    font-size: 2.4rem;
    color: #00529b;
    margin-bottom: 8px;
  }

  textarea {
    border: solid 1px #00529b;
    border-radius: 5px;
    padding: 3px 8px 3px 8px;
  }

  input {
    width: 427px;
    height: 108px;
    border-radius: 5px;
    padding: 3px 0 0 8px;
  }
`;

export const PrivateInput = styled.div`
  display: flex;
  flex-direction: column;

  input {
    width: 20px;
    height: 20px;
  }
`;

export const DivOutput = styled.div`
  width: 430px;
  height: 88px;
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
`;

export const UploadButton = styled.div`
  width: 196px;
  height: 88px;
  display: flex;
  flex-direction: column;

  label {
    width: 196px;
    height: 36px;
    border-radius: 5px;
    border: solid 1px #00529b;
    background: #e8eaef;
    text-align: center;
    font-size: 2rem;
    color: #00529b;

    svg {
      margin-top: 5px;
      margin-right: 8px;
      color: #00529b;
    }

    &:hover {
      transition: 0.5s;
      background: ${darken(-0.03, '#e8eaef')};
    }

    & + label {
      margin-top: 16px;
    }
  }

  input {
    display: none;
  }
`;

export const Register = styled.div`
  width: 196px;
  height: 88px;

  button {
    width: 196px;
    height: 36px;
    position: absolute;
    bottom: 0;
    background: #e8eaef;
    border: solid 1px #00529b;
    background: #00529b;
    color: #fff;
    font-size: 2rem;
    border-radius: 5px;

    svg {
      margin-right: 8px;
      color: #fff;
    }

    &:hover {
      transition: 0.5s;
      background: ${lighten(0.13, '#00529b')};
    }
  }
`;

export const LoadingSearch = styled.div`
  width: 82px;
  height: 64px;
  margin-top: 96px;
  border: 0;
  position: absolute;
  right: 16px;
  bottom: 64px;
  border: 0;
  border-radius: 5px;
`;

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { Form, Input } from '@rocketseat/unform';

import {
  requestNewCode,
  changePassword,
} from '../../store/modules/user/actions';

import {
  Container,
  DivRequestCode,
  DivChangePassword,
  HasCode,
} from './styles';

import Logo from '../../assets/peodive-logo.svg';

const schemaRequestCode = Yup.object().shape({
  username: Yup.string().required('Campo obriagatório'),
  email: Yup.string().required('Campo de email é obrigatório'),
});

const schemaChangePassword = Yup.object().shape({
  username: Yup.string().required('Campo obriagatório'),
  email: Yup.string().required('Campo de email é obrigatório'),
  newPassword: Yup.string()
    .min(6, 'Mínimo de 6 caracteres')
    .required('Campo de senha é obrigatório'),
  code: Yup.number()
    .typeError('Este campo é obrigatório')
    .positive('O código precisa ser um valor positivo')
    .min(1000, 'Código inválido')
    .max(9999, 'Código inválido')
    .required('Por favor, preencha este campo'),
});

export default function ResetPassword() {
  const dispatch = useDispatch();

  const [hasCode, setHasCode] = useState(false);
  const [hasRequestCode, setHasRequestCode] = useState(false);

  function handleRequestCode({ username, email }) {
    dispatch(requestNewCode({ username, email }));
    setHasRequestCode(true);
  }

  function handleChangePassword({ username, email, newPassword, code }) {
    dispatch(changePassword({ username, email, newPassword, code }));
  }

  if (!hasCode) {
    return (
      <Container>
        <DivRequestCode>
          <img src={Logo} alt="whatsapp-logo" />

          <h2>Para iniciar, insira seus dados abaixo</h2>

          <HasCode>
            <input
              name="hasCode"
              type="radio"
              id="hasCode"
              onChange={() => setHasCode(true)}
              value="true"
            />
            <label htmlFor="hasCode">Já tenho o código</label>
          </HasCode>

          <Form schema={schemaRequestCode} onSubmit={handleRequestCode}>
            <Input name="username" type="string" placeholder="Seu usuário" />
            <Input name="email" type="email" placeholder="Seu e-mail" />

            {!hasRequestCode ? (
              <button type="submit">Solicitar novo código</button>
            ) : (
              <button type="submit" disabled>
                Solicitar novo código
              </button>
            )}
          </Form>
        </DivRequestCode>
      </Container>
    );
  }
  return (
    <Container>
      <DivChangePassword>
        <img src={Logo} alt="whatsapp-logo" />

        <h2>Entre com uma nova senha segura</h2>

        <Form schema={schemaChangePassword} onSubmit={handleChangePassword}>
          <Input name="username" type="string" placeholder="Seu usuário" />
          <Input name="email" type="email" placeholder="Seu e-mail" />
          <Input name="newPassword" type="password" placeholder="Nova senha" />
          <Input
            name="code"
            type="number"
            placeholder="Código de segurança enviado por e-mail"
          />

          <button type="submit">Alterar senha</button>
        </Form>
      </DivChangePassword>
    </Container>
  );
}

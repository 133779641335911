
const titleFormContatoAcidentalMergulhador="Contato Acidental do Mergulhador com os Propulsores"
const titleHazardContatoAcidentalMergulhador="Atmosfera Explosiva";
const SecondIdContatoAcidentalMergulhador="ContatoAcidentalMergulhador";
const listAMCOContatoAcidentalMergulhador=['AM-1 - Aproximação indevida de embarcações','CO-1 - Lesão com potencial de sequela permanente ou fatalidade'];
let TitlesAndQuestionsContatoAcidentalMergulhador = {
    'AM-1 - Aproximação indevida de embarcações': {
      '1-1 a 1': {
        Question:
          'Os supervisores e a equipe de mergulho são treinados e tem competência para planejar atividades tendo como base a PT, CLM, APR e DDS?',
        Answer: 'white',
      },
      '1-1 b 2': {
        Question:
          ' No DDS é levado em consideração o tópico “Barcos de Pesca” alertando a todos os envolvidos da participação ativa neste monitoramento?',
        Answer: 'white',
      },
      '1-1 c 3': {
        Question:
          ' Durante o planejamento da atividade e emissão de PT, CLM, APR e DDS é contemplado o risco de aproximação indevida de embarcações e as providências a serem tomadas caso ocorra?',
        Answer: 'white',
      },
      '1-1 d 4': {
        Question:
          ' Durante este planejamento da atividade fica claro de quem é a responsabilidade do monitoramento de uma possível aproximação indevida de embarcação?',
        Answer: 'white',
      },
      '1-2 a 5': {
        Question:
          ' Os supervisores de mergulho têm conhecimento das Normas de navegação e do protocolo de aproximação de embarcações na zona de 500 m?',
        Answer: 'white',
      },
      '1-2 b 6': {
        Question:
          ' Os supervisores de mergulho seguem o protocolo de aproximação da zona de 500 m?',
        Answer: 'white',
      },
      '1-2 c 7': {
        Question:
          ' Os responsáveis no passadiço da embarcação se mantém atentos a uma possível aproximação de outras embarcações durante as operações de mergulho?',
        Answer: 'white',
      },
      '1-2 d 8': {
        Question: 'Caso haja o não cumprimento de respeitar o protocolo de aproximação na zona dos 500 metros por alguma embarcação isto é registrado no livro de bordo com todos os dados pertinentes?',
        Answer: 'white',
      },
      '1-2 e 9': {
        Question:
          'Casos em que o item acima venha ocorrer é levado ao conhecimento da Capitania dos Portos?',
        Answer: 'white',
      },
      '1-3 a 10': {
        Question:
          ' As equipes de mergulho são treinadas e conscientizadas para hastear as bandeiras ALFA em locais estratégicos e no mastro da frente/embarcação de mergulho?',
        Answer: 'white',
      },
      '1-3 b 11': {
        Question:
          'É realizada a prática contínua para todas as operações de mergulho do hasteamento da bandeira ALFA?',
        Answer: 'white',
      },
      '1-3 c 12': {
        Question:
          'O supervisor de mergulho confirma diariamente que a bandeira ALFA está hasteada e visível para outras embarcações?',
        Answer: 'white',
      },
      '1-3 d 13': {
        Question:
          'No check list de posicionamento dinâmico do SDSV é citado o tópico “Bandeira ALFA instalada”?',
        Answer: 'white',
      },
      '1-3 e 14': {
        Question:
          'As bandeiras ALFA utilizadas no balizamento da frente/embarcação de mergulho são trocadas sempre que necessário para garantir uma boa visibilidade das embarcações?',
        Answer: 'white',
      },
      '1-3 f 15': {
        Question:
          'Há bandeiras ALFA sobressalentes na frente/embarcação de mergulho para substituir a utilizada em caso de desgaste?',
        Answer: 'white',
      },
      '1-4 a 16': {
        Question:
          'Os meios de comunicação (Rádio VHF) são testados antes do início das operações de mergulho?',
        Answer: 'white',
      },
      '1-4 b 17': {
        Question:
          'Os supervisores de mergulho são treinados e têm capacidade de utilizar de forma responsável o canal 16 VHF?',
        Answer: 'white',
      },
      '1-4 c 18': {
        Question:
          ' São disponibilizados Rádios de comunicação secundários para garantir comunicação segura de operações de mergulho utilizando o canal 16 VHF?',
        Answer: 'white',
      },

      '1-4 d 19': {
        Question:
          'É informado pelo canal 16 VHF o início e o final das operações de mergulho?',
        Answer: 'white',
      },
      '1-4 e 20': {
        Question:
          'É sempre o mesmo responsável que emite o comunicado?',
        Answer: 'white',
      },
      '1-4 f 21': {
        Question:
          'O supervisor de mergulho tem essa atribuição de emitir o comunicado via canal 16 VHF?',
        Answer: 'white',
      },
      '1-4 g 22': {
        Question:
          'No procedimento operacional existe a informação de se utilizar a técnica ECO durante a comunicação?',
        Answer: 'white',
      },
      '1-5 a 23': {
        Question:
          'Os operadores da ponte de controle da embarcação são treinados e são conscientizados da importância do acionamento dos alarmes sonoro e visual?',
        Answer: 'white',
      },
      '1-5 b 24': {
        Question:
          'Os alarmes sonoros e visual são testados antes do início das operações de mergulho quanto a sua operacionalidade?',
        Answer: 'white',
      },
      '1-5 c 25': {
        Question:
          'Os pilotos de DP são treinados quanto a importância em monitorar a possível aproximação de embarcações no raio de 500 metros da operação de mergulho?',
        Answer: 'white',
      },
      '1-5 d 26': {
        Question:
          'É realizada alguma VCP a fim de verificar a eficácia dos procedimentos do passadiço?',
        Answer: 'white',
      },
      '1-5 e 27': {
        Question:
          'A comunicação entre controle de mergulho / passadiço é contínua durante o mergulho?',
        Answer: 'white',
      },
      '1-6 a 28': {
        Question:
          'Os equipamentos de comunicação (Rádio UHF) são testados entre o supervisor e a equipe de mergulho antes do início das operações de mergulho?',
        Answer: 'white',
      },
      '1-6 b 29': {
        Question:
          'São realizados treinamentos dos supervisores e a equipe de mergulho para a utilização do Rádio UHF?',
        Answer: 'white',
      },
      '1-6 c 30': {
        Question:
          'As baterias dos rádios UHF são verificadas antes do início das operações de mergulho?',
        Answer: 'white',
      },
      '1-6 d 31': {
        Question:
          'Existe bateria sobressalente do rádio UHF na frente/embarcação de mergulho?',
        Answer: 'white',
      },
      '1-6 e 32': {
        Question:
          'São disponibilizados Rádios de comunicação secundários para garantir comunicação segura entre o supervisor e a equipe de mergulho utilizando a frequência UHF?',
        Answer: 'white',
      },
      '1-6 f 33': {
        Question:
          'É utilizado algum outro sistema interno de comunicação além da frequência UHF para garantir a comunicação eficaz entre a equipe de mergulho e a de bordo?',
        Answer: 'white',
      },
      '1-7 a 34': {
        Question:
          'É realizado balizamento/sinalização para demarcação do perímetro de segurança das operações de mergulho em águas abrigadas?',
        Answer: 'white',
      },
      '1-7 b 35': {
        Question:
          'O balizamento/sinalização da área de segurança para mergulho em águas abrigadas está contido no procedimento de operações?',
        Answer: 'white',
      },
      '1-7 c 36': {
        Question:
          'É hasteada a bandeira ALFA em local visível e destacado no local de mergulho para todas as possíveis aproximações de embarcações em águas abrigadas?',
        Answer: 'white',
      },
      '1-7 d 37': {
        Question:
          'Há bandeiras ALFA sobressalentes na frente/ embarcação de mergulho em águas abrigadas para substituir a utilizada em caso de desgaste?',
        Answer: 'white',
      },


    },
    'AM-2 - Perda de posicionamento da embarcação': {
      '2-1 a 38': {
        Question:
          'Os supervisores e equipe são treinados e tem competência para planejar atividades tendo como base a PT, CLM, APR e DDS?',
        Answer: 'white',
      },
      '2-1 b 39': {
        Question:
          'Durante o planejamento da atividade e emissão de PT, CLM, APR e DDS é contemplado o risco de perda de DP da embarcação e as providências a serem tomadas caso ocorra?',
        Answer: 'white',
      },
      '2-1 c 40': {
        Question:
          'Na elaboração da APR é levado em consideração a possível perda de posicionamento da embarcação?',
        Answer: 'white',
      },
      '2-1 d 41': {
        Question:
          'É ressaltado no DDS qual o procedimento a ser adotado em caso de perda de posicionamento da embarcação?',
        Answer: 'white',
      },
      '2-1 e 42': {
        Question:
          'A CLM aberta para realizar a tarefa costuma contemplar a possível perda de posicionamento da embarcação?',
        Answer: 'white',
      },
      '2-1 f 43': {
        Question:
          'Existe procedimento de emergência para o cenário de perda de posicionamento da embarcação?',
        Answer: 'white',
      },
      '2-2 a 44': {
        Question:
          'No plano de inspeção e manutenção da frente/embarcação de mergulho os propulsores são monitorados quanto a integridade da sua proteção?  ',
        Answer: 'white',
      },
      '2-2 b 45': {
        Question:
          'O estoque de peças de reposição dos itens críticos para as operações dos propulsores é mantido na frente/embarcação de mergulho?  ',
        Answer: 'white',
      },
      '2-2 c 46': {
        Question:
          'Existe um plano de inspeção e manutenção para monitorar e garantir o bom funcionamento da proteção dos thrusters? ',
        Answer: 'white',
      },
      '2-3 a 47': {
        Question:
          'Os equipamentos de comunicação (Rádio UHF) são testados entre o supervisor e a equipe de mergulho antes do início das operações de mergulho?',
        Answer: 'white',
      },
      '2-3 b 48': {
        Question:
          'São realizados treinamentos dos supervisores e a equipe de mergulho para a utilização do Rádio UHF?',
        Answer: 'white',
      },
      '2-3 c 49': {
        Question:
          'As baterias dos rádios UHF são verificadas antes do início das operações de mergulho? ',
        Answer: 'white',
      },
      '2-3 d 50': {
        Question:
          'Existe bateria sobressalente do rádio UHF na frente/embarcação de mergulho?',
        Answer: 'white',
      },
      '2-3 e 51': {
        Question:
          'São disponibilizados Rádios de comunicação secundários para garantir comunicação segura entre o supervisor e a equipe de mergulho utilizando a frequência UHF?',
        Answer: 'white',
      },
      '2-3 f 52': {
        Question:
          'É utilizado algum outro sistema interno de comunicação além da frequência UHF para garantir a comunicação eficaz entre a equipe de mergulho e a de bordo?',
        Answer: 'white',
      },
      '2-3 g 53': {
        Question:
          'No procedimento operacional existe a informação de se utilizar a técnica ECO durante a comunicação?',
        Answer: 'white',
      },
      '2-4 a 54': {
        Question:
          'Existe um plano de inspeção e manutenção para monitorar e garantir o bom funcionamento do sistema de luzes e alarmes do posicionamento dinâmico?',
        Answer: 'white',
      },
      '2-4 b 55': {
        Question:
          'É realizado o teste de luzes e alarmes de posicionamento dinâmico pré-mergulho?',
        Answer: 'white',
      },
      '2-4 c 56': {
        Question:
          'Existe o controle de sobressalentes das luzes e dos alarmes de posicionamento dinâmico utilizados na frente/embarcação de mergulho? ',
        Answer: 'white',
      },
      '2-5 a 57': {
        Question:
          'Existe procedimento especifico para recuperação do posicionamento da embarcação em caso de perda de um propulsor?',
        Answer: 'white',
      },
      '2-5 b 58': {
        Question:
          'A equipe de controle da embarcação é treinada e têm competência no procedimento de recuperação do posicionamento na perda de um propulsor?',
        Answer: 'white',
      },
      '2-5 c 59': {
        Question:
          'Há um procedimento específico contemplando o caso de perda súbita de um dos propulsores?',
        Answer: 'white',
      },
      '2-5 d 60': {
        Question:
          'Toda a equipe tem o conhecimento deste procedimento de perda súbita de um dos propulsores?',
        Answer: 'white',
      },

    },
    'AM-3 - Excesso de umbilical na água': {
      '3-1 a 61': {
        Question:
          'Os supervisores e equipe de mergulho são treinados e têm competência para planejar as atividades, evitando o excesso de umbilical na água?',
        Answer: 'white',
      },
      '3-1 b 62': {
        Question:
          'Na APR e DDS é contemplado o risco de excesso de umbilical na água e as providências a serem tomadas caso ocorra?',
        Answer: 'white',
      },
      '3-1 c 63': {
        Question:
          'No planejamento das operações de mergulho é considerado o excesso de umbilical na água com risco de contato do mergulhador com o propulsor?',
        Answer: 'white',
      },
      '3-1 d 64': {
        Question:
          'Na APR o diagrama de umbilical é contemplado?',
        Answer: 'white',
      },
      '3-1 e 65': {
        Question:
          'No DDS é mencionado o cuidado que deve ser tomado quanto ao cumprimento do diagrama de umbilical para a realização da atividade?',
        Answer: 'white',
      },
      '3-2 a 66': {
        Question:
          'Os meios de comunicação, áudio e vídeo, são testados antes do início das operações de mergulho através do check list?',
        Answer: 'white',
      },
      '3-2 b 67': {
        Question:
          'No caso da perda de comunicação (áudio e vídeo) os supervisores e equipe de mergulho são treinados e têm competência para monitorar por códigos os umbilicais dos mergulhadores durante as atividades de mergulho?',
        Answer: 'white',
      },
      '3-4 a 72': {
        Question:
          'Existe um diagrama de umbilical com a relação dos limites de Distância X Profundidade?  ',
        Answer: 'white',
      },
      '3-4 b 73': {
        Question:
          'Os supervisores de mergulho são treinados e têm competência para utilizar o diagrama de umbilical em mergulhos realizados na frente/embarcação de mergulho?',
        Answer: 'white',
      },
      '3-4 c 74': {
        Question:
          'Os mergulhadores são orientados pelos supervisores de mergulho para cumprir fielmente o cumprimento do diagrama de umbilical?',
        Answer: 'white',
      },
      '3-4 d 75': {
        Question:
          'Todos os membros da equipe sabem interpretar os limites de distância de umbilical apresentado no diagrama?',
        Answer: 'white',
      },
      '3-4 e 76': {
        Question:
          'O comprimento do umbilical adequado a profundidade do sinete/cesta durante o mergulho é registrado no check list de mergulho?',
        Answer: 'white',
      },

    },
    'AM-4 - Pane mecânica no sistema de comando da embarcação durante a operação de mergulho': {
      '4-1 a 77': {
        Question:
          'Existe plano de manutenção preventiva e preditiva do sistema de comando da embarcação?',
        Answer: 'white',
      },
      '4-1 b 78': {
        Question:
          'O plano de manutenção preventiva e preditiva do sistema de comando da embarcação é monitorado de forma sistemática?',
        Answer: 'white',
      },
      '4-2 a 79': {
        Question:
          'É realizado teste de posicionamento dinâmico (DP) com a confirmação da operacionalidade dos comandos da embarcação antes de liberar o início das operações de mergulho conforme descrito na CLM?',
        Answer: 'white',
      },
      '4-2 b 80': {
        Question:
          'Os testes do funcionamento dos comandos e propulsores fazem parte de procedimento especifico e são rastreáveis?',
        Answer: 'white',
      },
      '4-2 c 81': {
        Question:
          'É realizado o teste de deriva sempre antes do posicionamento final da embarcação?',
        Answer: 'white',
      },
      '4-2 d 82': {
        Question: 'Os limites operacionais são seguidos de acordo com os procedimentos?',
        Answer: 'white',
      },
      '4-3 a 83': {
        Question: 'Os equipamentos de comunicação (Rádio UHF) são testados entre o supervisor e a equipe de mergulho antes do início das operações de mergulho?',
        Answer: 'white',
      },
      '4-3 b 84': {
        Question: 'São realizados treinamentos dos supervisores e a equipe de mergulho para a utilização do Rádio UHF?',
        Answer: 'white',
      },
      '4-3 c 85': {
        Question: 'As baterias dos rádios UHF são verificadas antes do início das operações de mergulho?',
        Answer: 'white',
      },
      '4-3 d 86': {
        Question: 'Existe bateria sobressalente do rádio UHF na frente/embarcação de mergulho?',
        Answer: 'white',
      },
      '4-3 e 87': {
        Question: 'São disponibilizados Rádios de comunicação secundários para garantir comunicação segura entre o supervisor e a equipe de mergulho utilizando a frequência UHF?',
        Answer: 'white',
      },
      '4-3 f 88': {
        Question: 'É utilizado algum outro sistema interno de comunicação além da frequência UHF para garantir a comunicação eficaz entre a equipe de mergulho e a de bordo?',
        Answer: 'white',
      },
      '4-4 a 89': {
        Question: 'Existe um diagrama de umbilical com a relação dos limites de Distância x Profundidade?  ',
        Answer: 'white',
      },
      '4-4 b 90': {
        Question: 'Os supervisores de mergulho são treinados e têm competência para utilizar o diagrama de umbilical em mergulhos realizados na frente/embarcação de mergulho?',
        Answer: 'white',
      },
      '4-4 c 91': {
        Question: 'Os mergulhadores são orientados pelos supervisores de mergulho para cumprir fielmente o cumprimento do diagrama de umbilical?',
        Answer: 'white',
      },
      '4-4 d 92': {
        Question: 'Todos os membros da equipe sabem interpretar os limites de distância de umbilical apresentado no diagrama?',
        Answer: 'white',
      },
      '4-4 e 93': {
        Question: 'O comprimento do umbilical adequado a profundidade do sinete/cesta durante o mergulho é registrado no check list de mergulho?',
        Answer: 'white',
      },


    },
    'AM-5 - Condições meteoceanográficas adversas': {
      '5-1 a 94': {
        Question:
          'Os supervisores e equipe de mergulho são treinados e têm competência para planejar operações de mergulho com emissão de APR com a inclusão das condições meteoceanográficas?',
        Answer: 'white',
      },
      '5-1 b 95': {
        Question:
          'A APR contempla as condições meteoceanográficas da localização e dia da realização do mergulho?',
        Answer: 'white',
      },
      '5-1 c 96': {
        Question:
          'É citado em DDS as condições meteoceanográficas previstas por boletim para cada dia efetivo de trabalho?',
        Answer: 'white',
      },
      '5-1 d 97': {
        Question:
          'É considerado no planejamento da atividade de mergulho os dados meteoceanográficos como fator importante na execução das tarefas?',
        Answer: 'white',
      },
      '5-2 a 98': {
        Question:
          'Os equipamentos de comunicação (Rádio UHF) são testados entre o supervisor e a equipe de mergulho antes do início das operações de mergulho?',
        Answer: 'white',
      },
      '5-2 b 99': {
        Question:
          'As equipes de mergulho são treinadas em comunicação com o controle da embarcação utilizando Rádio UHF em caso de mudanças nas condições meteoceanográficas?',
        Answer: 'white',
      },
      '5-2 c 100': {
        Question:
          'As baterias dos rádios UHF são verificadas antes do início das operações de mergulho?',
        Answer: 'white',
      },
      '5-2 d 101': {
        Question:
          'Existe bateria sobressalente do rádio UHF na frente/embarcação de mergulho?',
        Answer: 'white',
      },
      '5-2 e 102': {
        Question:
          'São disponibilizados Rádios de comunicação secundários para garantir comunicação segura entre o supervisor e a equipe de mergulho utilizando a frequência UHF?',
        Answer: 'white',
      },
      '5-2 f 103': {
        Question:
          'É utilizado algum outro sistema interno de comunicação além da frequência UHF para garantir a comunicação eficaz entre a equipe de mergulho e a de bordo?',
        Answer: 'white',
      },
      '5-3 a 104': {
        Question:
          'As condições meteoceanográficas são monitoradas em boletins periódicos diariamente?',
        Answer: 'white',
      },
      '5-3 b 105': {
        Question:
          'Os meios de comunicação para recebimento de boletins meteorológicos são testados antes e durante as operações de mergulho? ',
        Answer: 'white',
      },
      '5-3 c 106': {
        Question:
          'Os boletins meteoceanográficos são recebidos e verificados diariamente conforme o procedimento operacional de mergulho?',
        Answer: 'white',
      },
      '5-3 d 107': {
        Question:
          'Há algum responsável a bordo que é designado em receber os boletins meteoceanográficos diários?',
        Answer: 'white',
      },

    },
    'CO-1 - Lesão com potencial de sequela permanente ou fatalidade': {
      '6-1 a 108': {
        Question:
          'No plano de resposta a emergência da frente/embarcação de mergulho, está contido o cenário de acidente com os mergulhadores em caso de contato com propulsores da embarcação?',
        Answer: 'white',
      },
      '6-1 b 109': {
        Question:
          'A equipe de comando da frente/embarcação de mergulho é treinada e capacitada para acionamento do shutdown de emergência dos propulsores? ',
        Answer: 'white',
      },
      '6-2 a 110': {
        Question:
          'Os supervisores de mergulho são capacitados e treinados no plano de resposta a emergência, abortar o mergulho em andamento, retornar a cesta, sinete ou a superfície com o auxílio de Bellman ou mergulhador de emergência?',
        Answer: 'white',
      },
      '6-2 b 111': {
        Question:
          'O plano de contingência prevê o passo a passo para abortar o mergulho e retornar a cesta, sinete ou ao a superfície com a ajuda do Bellman ou o mergulhador de emergência?',
        Answer: 'white',
      },
      '6-2 c 112': {
        Question:
          'A equipe de mergulho é conscientizada nos riscos inerentes a atividade que executam quando embarcados na frente/embarcação de mergulho?',
        Answer: 'white',
      },
      '6-3 a 113': {
        Question:
          'Os supervisores são capacitados e treinados para orientar a realização do resgate do mergulhador pelo mergulhador de emergência? ',
        Answer: 'white',
      },
      '6-3 b 114': {
        Question:
          'O mergulhador de emergência na superfície, permanece equipado e pronto para intervir no menor tempo possível sendo num simulado ou durante uma intercorrência real? ',
        Answer: 'white',
      },
      '6-3 c 115': {
        Question:
          'Existe periodicidade para realização de simulado de resgate do mergulhador pelo mergulhador de emergência? ',
        Answer: 'white',
      },
      '6-3 d 116': {
        Question:
          ' A empresa controla através do seu departamento de segurança o treinamento frequente envolvendo toda a equipe de mergulho no procedimento de resgate do mergulhador através do mergulhador de emergência? ',
        Answer: 'white',
      },
      '6-4 a 117': {
        Question:
          'Existem, ao menos, dois membros na equipe de mergulho capacitados e treinados em suporte básico a vida, no atendimento inicial a emergências subaquáticas, atendendo assim as normas vigentes (Normam 15)? ',
        Answer: 'white',
      },
      '6-4 b 118': {
        Question:
          'É cobrado eficácia nos simulados dos planos de resposta a emergência com ênfase na comunicação adequada? ',
        Answer: 'white',
      },
      '6-4 c 119': {
        Question:
          'O treinamento de reciclagem em atendimento a emergências de primeiros socorros atende as orientações de acordo com as normas vigentes? ',
        Answer: 'white',
      },
      '6-5 a 120': {
        Question:
          'Há profissional de saúde de nível superior devidamente habilitado na frente/embarcação de mergulho? ',
        Answer: 'white',
      },
      '6-5 b 121': {
        Question:
          'O profissional de saúde é capacitado e treinado em suporte avançado a vida e atendimento a emergência subaquática? ',
        Answer: 'white',
      },
      '6-6 a 122': {
        Question:
          'A embarcação possui enfermaria que atenda aos requisitos da Normam-01, da NR-30 e demais legislações pertinentes? ',
        Answer: 'white',
      },
      '6-6 b 123': {
        Question:
          'A enfermaria possui quantidade de equipamentos e medicamentos que atende a lista de itens mínimos de enfermaria padronizada pela Petrobras para embarcações de mergulho? ',
        Answer: 'white',
      },
      '6-7 a 124': {
        Question:
          'Os meios de comunicação com o suporte médico em terra são adequados e tem backup? ',
        Answer: 'white',
      },
      '6-7 b 125': {
        Question:
          'No procedimento de contingência está previsto o plano de resposta a emergência médica com os recursos de contato telefônico ou por vídeo? ',
        Answer: 'white',
      },
      '6-7 c 126': {
        Question:
          'Existe plano de atendimento médico emergencial na empresa 24 horas para atender as equipes embarcadas no offshore? ',
        Answer: 'white',
      },
      '6-7 d 127': {
        Question:
          'É previsto no procedimento de emergência da empresa, antes de uma operação de mergulho, que haja a verificação de uma unidade marítima mais próxima das operações adequada a receber o mergulhador vitimado com disponibilidade de resgate aeromédico?',
        Answer: 'white',
      },
      '6-8 a 128': {
        Question:
          'Existe procedimento específico para em caso de acidente com necessidade de remoção e transporte para hospital ou clínica em terra? ',
        Answer: 'white',
      },
      '6-8 b 129': {
        Question:
          'Os supervisores estão aptos a orientar os responsáveis pelo transporte de acidentados via aérea, de que após a realização de mergulho, o voo deve cumprir um limite de altitude durante o voo em cumprimento da NORMAM-15? ',
        Answer: 'white',
      },
      '6-8 c 130': {
        Question:
          'O Plano de contingência garante acesso a rede hospitalar especializada e a disponibilidade de recursos para um tratamento específico até o pronto reestabelecimento do acidentado? ',
        Answer: 'white',
      },
      '6-8 d 131': {
        Question:
          'Existe procedimento da empresa que propicie a remoção do acidentado embarcado até a rede hospitalar? ',
        Answer: 'white',
      },
      '6-8 e 132': {
        Question:
          'O RH da empresa está capacitado a proporcionar todo o acompanhamento ao acidentado desde a internação até o seu retorno as atividades? ',
        Answer: 'white',
      },
      '6-9 a 133': {
        Question:
          'É realizada uma criteriosa avaliação médica especializada antes do retorno do mergulhador ao trabalho? ',
        Answer: 'white',
      },
      '6-9 b 134': {
        Question:
          'O RH da empresa acompanha o processo de retorno do acidentado as suas atividades laborais após finalizado o tratamento? ',
        Answer: 'white',
      },



    },

  };

  let listKeysQuestionContatoAcidentalMergulhador=[
    {
      'AM-1 - Aproximação indevida de embarcações': [
        { '1-1': { Check: [], Answer: 'white' } },
        { '1-2': { Check: [], Answer: 'white' } },
        { '1-3': { Check: [], Answer: 'white' } },
        { '1-4': { Check: [], Answer: 'white' } },
        { '1-5': { Check: [], Answer: 'white' } },
        { '1-6': { Check: [], Answer: 'white' } },
        { '1-7': { Check: [], Answer: 'white' } },
      ].reverse(),
      'AM-2 - Perda de posicionamento da embarcação': [
        { '2-1': { Check: [], Answer: 'white' } },
        { '2-2': { Check: [], Answer: 'white' } },
        { '2-3': { Check: [], Answer: 'white' } },
        { '2-4': { Check: [], Answer: 'white' } },
        { '2-5': { Check: [], Answer: 'white' } },

      ].reverse(),
      'AM-3 - Excesso de umbilical na água': [
        { '3-1': { Check: [], Answer: 'white' } },
        { '3-2': { Check: [], Answer: 'white' } },
        { '3-3': { Check: [], Answer: 'white' } },
        { '3-4': { Check: [], Answer: 'white' } },
      ].reverse(),
      'AM-4 - Pane mecânica no sistema de comando da embarcação durante a operação de mergulho': [
        { '4-1': { Check: [], Answer: 'white' } },
        { '4-2': { Check: [], Answer: 'white' } },
        { '4-3': { Check: [], Answer: 'white' } },
        { '4-4': { Check: [], Answer: 'white' } },

      ].reverse(),
      'AM-5 - Condições meteoceanográficas adversas': [
        { '5-1': { Check: [], Answer: 'white' } },
        { '5-2': { Check: [], Answer: 'white' } },
        { '5-3': { Check: [], Answer: 'white' } },


      ].reverse(),
      'CO-1 - Lesão com potencial de sequela permanente ou fatalidade': [
        { '6-1': { Check: [], Answer: 'white' } },
        { '6-2': { Check: [], Answer: 'white' } },
        { '6-3': { Check: [], Answer: 'white' } },
        { '6-4': { Check: [], Answer: 'white' } },
        { '6-5': { Check: [], Answer: 'white' } },
        { '6-6': { Check: [], Answer: 'white' } },
        { '6-7': { Check: [], Answer: 'white' } },
        { '6-8': { Check: [], Answer: 'white' } },
        { '6-9': { Check: [], Answer: 'white' } },

      ],


    }]


  export {TitlesAndQuestionsContatoAcidentalMergulhador,listKeysQuestionContatoAcidentalMergulhador,titleFormContatoAcidentalMergulhador,titleHazardContatoAcidentalMergulhador,SecondIdContatoAcidentalMergulhador,listAMCOContatoAcidentalMergulhador}

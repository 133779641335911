import styled from 'styled-components';
import { darken, lighten } from 'polished';

export const DivAuditoriaReport = styled.div`
  width: 602px;
  height: 632px;
  border: solid 2px #00529b;
  margin: 0 auto;
  border-radius: 5px;
  background: #fff;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 64px;

  button {
    height: 42px;
    background: #00529b;
    font-size: 14px;
  }
`;

export const DivContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: 48px auto;

  label {
    font-size: 20px;
  }

  textarea {
    background: #fff;
    width: 480px;
    font-size: 14px;
  }

  div + button {
    margin-top: 82px;
  }
`;

export const DivTitle = styled.div`
  display: flex;
  flex-direction: column;
  margin: 48px auto;

  h2 {
    text-align: center;
    color: #00529b;
    font-size: 32px;
  }

  h3 {
    margin-top: 64px;
    text-align: center;
    color: #b56a1e;
    font-size: 16px;
  }
`;

export const DivHead = styled.div`
  max-width: 427px;
  height: 48px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  div {
    width: 156px;

    label {
      color: #00529b;
      font-size: 1.6rem;
      margin-bottom: 8px;
    }

    input {
      margin-top: 8px;
      width: 156px;
      height: 32px;
      border: solid 1px #00529b;
      border-radius: 5px;
      padding: 3px 8px 3px 8px;
    }
  }
`;

export const DivBox1 = styled.div`
  width: 427px;
  height: 72px;
  display: flex;
  flex-direction: column;
  margin-top: 37px;

  label {
    color: #00529b;
    font-size: 1.8rem;
    margin-bottom: 8px;
  }

  input {
    width: 427px;
    height: 96px;
    border: solid 1px #00529b;
    border-radius: 5px;
    padding: 3px 8px 3px 8px;
  }
`;
export const DivUpload = styled.div`
  display: flex;
  justify-content: space-between;
  width: 427px;
  height: 36px;
  margin-top: 64px;
`;

export const UploadButton = styled.div`
  width: 196px;
  height: 36px;
  display: flex;
  flex-direction: column;

  label {
    width: 196px;
    height: 36px;
    border-radius: 5px;
    border: solid 1px #00529b;
    background: #e8eaef;
    text-align: center;
    font-size: 1.8rem;
    color: #00529b;

    svg {
      margin-top: 5px;
      margin-right: 8px;
      color: #00529b;
    }

    &:hover {
      transition: 0.5s;
      background: ${darken(-0.03, '#e8eaef')};
    }

    & + label {
      margin-top: 16px;
    }
  }

  input {
    display: none;
  }

  h3 {
    color: #4cd86c;
  }
`;

export const DivRegister = styled.div`
  button {
    width: 196px;
    height: 36px;
    background: #e8eaef;
    border: solid 1px #00529b;
    background: #00529b;
    color: #fff;
    font-size: 1.8rem;
    border-radius: 5px;

    svg {
      margin-right: 8px;
      color: #fff;
    }

    &:hover {
      transition: 0.5s;
      background: ${lighten(0.13, '#00529b')};
    }
  }
`;

export const LoadingSearch = styled.div`
  width: 82px;
  height: 32px;
  border-radius: 5px;
  position: absolute;
  right: 128px;
  bottom: 156px;
`;

const titleFormAprisionamentoMergulhador="Aprisionamento de Mergulhador no Fundo"
const titleHazardAprisionamentoMergulhador="Ambiente Subaquático";
const SecondIdAprisionamentoMergulhador="AprisonamentoMergulhador";
const listAMCOAprisionamentoMergulhador=['AM-1 - Umbilical preso em obstáculo','CO-1 - Lesão ou alteração funcional'];
let TitlesAndQuestionsAprisionamentoMergulhador = {
    'AM-1 - Umbilical preso em obstáculo': {
      '1-2 a 8': {
        Question:
          'Existe programa de atualização das equipes de mergulho orientando sobre a importância do monitoramento constante do umbilical do mergulhador?',
        Answer: 'white',
      },
      '1-2 b 9': {
        Question:
          'É realizado o briefing de toda a equipe de mergulho antes das operações para terem atenção com o umbilical do mergulhador?',
        Answer: 'white',
      },
      '1-2 c 10': {
        Question:
          'O supervisor de mergulho tem o hábito de periodicamente estar perguntando ao mergulhador e ao bellman sobre as condições do umbilical do mergulhador?',
        Answer: 'white',
      },
      '1-2 d 11': {
        Question:
          'Nos DDS é enfatizado a importância que todos os envolvidos devem ter durante o mergulho conforme a sua respectiva função (mergulhador, supervisor e bellman)?',
        Answer: 'white',
      },

      '1-3 a 12': {
        Question:
          'Os supervisores mantem o bellman sempre atento as condições do umbilical do mergulhador?',
        Answer: 'white',
      },
      '1-3 b 13': {
        Question:
          'É mantida comunicação entre o mergulhador e o bellman sobre as condições da tensão aplicada na manipulação do umbilical?',
        Answer: 'white',
      },
      '1-3 c 14': {
        Question:
          'O bellman é sabedor que deve se manter atento em direcionar sua câmera na direção ao umbilical para confirmar que o tensionamento está de acordo com o padrão pré-determinado sem que o supervisor tenha que solicitar tal ação?',
        Answer: 'white',
      },
      '1-3 d 15': {
        Question:
          'O supervisor pergunta durante o mergulho ao bellman se o umbilical se mantém tensionado conforme padrão pré-estabelecido?',
        Answer: 'white',
      },
      '1-4 a 16': {
        Question:
          'O supervisor e os mergulhadores são orientados a interromperem a operação em função de má visibilidade?',
        Answer: 'white',
      },
      '1-4 b 17': {
        Question:
          'O supervisor de mergulho é treinado para interromper a operação de mergulho em função de dificuldade de posicionamento que o mergulhador esteja passando na água mesmo com a força da correnteza abaixo do limite operacional?',
        Answer: 'white',
      },
      '1-4 c 18': {
        Question:
          'O Bellman é treinado a reconhecer quando existe correnteza e que precisa estar atento para não liberar excesso de umbilical para o mergulhador? ',
        Answer: 'white',
      },
      '1-4 d 19': {
        Question:
          'É considerado um limite de visibilidade que determine a interrupção do mergulho?',
        Answer: 'white',
      },
      '1-4 e 20': {
        Question:
          'É considerado na equipe de mergulho, pelo supervisor, quais são os mergulhadores com mais destreza em águas com visibilidade restrita?',
        Answer: 'white',
      },
      '1-4 f 21': {
        Question:
          'É respeitada a boa prática de se interromper o mergulho, avaliando o aumento da intensidade da correnteza e de sua direção?',
        Answer: 'white',
      },
      '1-4 g 22': {
        Question:
          'É analisada a possibilidade de obstáculos que possam prender o umbilical entre a cesta/sinete e o local de trabalho?',
        Answer: 'white',
      },
      '1-5 a 23': {
        Question:
          'São realizados simulados com ênfase no procedimento de resposta a emergências subaquáticas, no controle emocional dos mergulhadores e supervisores envolvidos nas operações de mergulho?',
        Answer: 'white',
      },
      '1-5 b 24': {
        Question:
          'Os supervisores e mergulhadores são capacitados e avaliados periodicamente em treinamentos para lidar com intercorrências durante as operações de mergulho através de simulados e demais exercícios práticos prevenindo que se desesperem e possam vir a ficar aprisionados no fundo?',
        Answer: 'white',
      },
      '1-5 c 25': {
        Question:
          'Nos simulados de mergulho, os supervisores são orientados a focar na calma e no controle emocional dos mergulhadores para evitar que os mesmos entrem em desespero durante uma intercorrência, podendo prender seu umbilical em algum obstáculo?',
        Answer: 'white',
      },
      '1-5 d 26': {
        Question:
          'De forma geral, os supervisores têm o conhecimento do perfil dos membros de suas respectivas equipes, para em caso de uma intercorrência saber transmitir as orientações necessárias e manter a estabilidade emocional do mergulhador para que o mesmo não entre em desespero e acabe ficando aprisionado no fundo? ',
        Answer: 'white',
      },
      '1-6 a 27': {
        Question:
          'Os umbilicais do mergulhador e do Bellman são identificados individualmente de tal forma não haver dúvidas durante uma emergência?',
        Answer: 'white',
      },
      '1-6 b 28': {
        Question:
          'São utilizadas fitas refletivas em toda a extensão dos umbilicais?',
        Answer: 'white',
      },
      '1-6 c 29': {
        Question:
          'Consta no plano de manutenção a inspeção periódica com a substituição das fitas refletivas danificadas dos umbilicais dos mergulhadores?',
        Answer: 'white',
      },
      '1-6 d 30': {
        Question:
          'A sinalização refletiva utilizada é claramente identificada em mergulhos noturnos?',
        Answer: 'white',
      },


    },
    'AM-2 - Carga suspensa sobre área de mergulho': {
      '2-1 a 31': {
        Question:
          'A equipe de mergulho tem consciência da importância de manter atualizadas as ferramentas PT, CLM, DDS?',
        Answer: 'white',
      },
      '2-1 b 32': {
        Question:
          'No planejamento pré-mergulho é sempre verificado a possibilidade de alguma tarefa simultânea com carga suspensa no raio da operação e está contemplado em (PT, CLM, DDS)?',
        Answer: 'white',
      },
      '2-1 c 33': {
        Question:
          'É assegurada a condição de não haver movimentação de carga sobre a área da operação de mergulho conforme listado em PT e CLM?',
        Answer: 'white',
      },
      '2-2 a 34': {
        Question:
          'O supervisor e os mergulhadores são orientados a interromperem a operação em função de má visibilidade?',
        Answer: 'white',
      },
      '2-2 b 35': {
        Question:
          'O supervisor de mergulho é treinado para interromper a operação de mergulho em função de dificuldade de posicionamento que o mergulhador esteja passando na água mesmo com a força da correnteza abaixo do limite operacional?',
        Answer: 'white',
      },
      '2-2 c 36': {
        Question:
          'O Bellman é treinado a reconhecer quando existe correnteza e que precisa estar atento para não liberar excesso de umbilical para o mergulhador? ',
        Answer: 'white',
      },
      '2-2 d 37': {
        Question:
          'É considerado um limite de visibilidade que determine a interrupção do mergulho?',
        Answer: 'white',
      },
      '2-2 e 38': {
        Question:
          'É considerado na equipe de mergulho, pelo supervisor, quais são os mergulhadores com mais destreza em águas com visibilidade restrita?',
        Answer: 'white',
      },
      '2-2 f 39': {
        Question:
          'É respeitada a boa prática de se interromper o mergulho, avaliando o aumento da intensidade da correnteza e de sua direção?',
        Answer: 'white',
      },
      '2-2 g 40': {
        Question:
          'É analisada a possibilidade de obstáculos que possam prender o umbilical entre a cesta/sinete e o local de trabalho?',
        Answer: 'white',
      },
      '2-3 a 41': {
        Question:
          ' No caso de tarefas mais complexas como pull in inspeção e limpeza de caixas de mar, inspeção de amarras, é elaborada uma APR específica?',
        Answer: 'white',
      },
      '2-3 b 42': {
        Question:
          'Complementando a pergunta acima, no DDS é dado a ênfase sobre os detalhes da tarefa a todos os envolvidos da equipe?',
        Answer: 'white',
      },
      '2-3 c 43': {
        Question:
          'Nas UEPs alguém da área de segurança da frente/embarcação de mergulho acompanha a elaboração da APR?',
        Answer: 'white',
      },
      '2-3 d 44': {
        Question:
          'A equipe de mergulho é treinada e orientada a realizar APR referente a cada operação de mergulho?',
        Answer: 'white',
      },
      '2-3 e 45': {
        Question:
          'A equipe de mergulho tem a responsabilidade de participar da elaboração e do acompanhamento da eficácia da APR individual para cada operação de mergulho?',
        Answer: 'white',
      },
      '2-3 f 46': {
        Question:
          'Há o acompanhamento de algum colaborador da área de segurança na elaboração da APR?',
        Answer: 'white',
      },
      '2-3 g 47': {
        Question:
          'A equipe de mergulho é treinada e orientada a identificar algum perigo suspenso que não esteja contemplada na APR de operação de mergulho?',
        Answer: 'white',
      },
      '2-3 h 48': {
        Question:
          'A equipe de mergulho tem a responsabilidade de participar da elaboração e do acompanhamento da eficácia da APR individual para cada operação de mergulho?',
        Answer: 'white',
      },
      '2-4 a 49': {
        Question:
          'No caso de manobras em que haverá carga suspensa no escopo de trabalho é elaborado um planejamento detalhado a respeito?',
        Answer: 'white',
      },
      '2-4 b 50': {
        Question:
          'Os envolvidos têm a competência necessária para elaborar o planejamento?',
        Answer: 'white',
      },
      '2-4 c 52': {
        Question:
          'O mergulhador é orientado pelo supervisor para permanecer em local seguro durante a movimentação de carga no fundo do mar afim de garantir a máxima abrangência do planejamento da tarefa a ser realizada?',
        Answer: 'white',
      },
      '2-5 a 53': {
        Question:
          'Em isolamento de área emersa do mergulho é utilizada a fita correta que define o isolamento? (Fita zebrada) ',
        Answer: 'white',
      },
      '2-5 b 54': {
        Question:
          'Na frente/embarcação de mergulho (UEP), antes e após intervenção de mergulho o supervisor informa a sala de controle da unidade, para que esta faça o comunicado geral da realização da atividade de mergulho?',
        Answer: 'white',
      },
      '2-5 c 55': {
        Question:
          'Faz parte do procedimento operacional comunicar ao setor de segurança da frente/embarcação de mergulho a necessidade de isolamento da área em que se encontra instalado o sistema de mergulho?',
        Answer: 'white',
      },
      '2-5 d 56': {
        Question:
          'Há a prática de observar se a área demarcada com isolamento continua sinalizada?',
        Answer: 'white',
      },
      '2-6 a 57': {
        Question:
          'Há registros do horário da autorização informada pela UEP da liberação da entrada na zona dos 500m?',
        Answer: 'white',
      },
      '2-6 b 58': {
        Question:
          'É rotina a verificação sistemática dos boletins metoceânico pela tripulação de náutica da embarcação?',
        Answer: 'white',
      },
      '2-6 c 59': {
        Question:
          'Dentro da área de segurança de 500m são realizados os testes de deriva e calibração dos sensores que alimentam o sistema de posicionamento dinâmico da embarcação?',
        Answer: 'white',
      },
      '2-7 a 60': {
        Question:
          'O Supervisor de mergulho em comum acordo com o Comandante da embarcação estão atentos aos limites operacionais metoceânicos?',
        Answer: 'white',
      },
      '2-7 b 61': {
        Question:
          'O compensador de heave é testado periodicamente para garantir a sua operacionalidade?',
        Answer: 'white',
      },


    },
    'AM-3 - Alteração de aproamento (heading) da unidade (FPSO que sejam turret)': {
      '3-1 a 62': {
        Question:
          'O Supervisor e a equipe de mergulho são capacitados a identificar alterações de aproamento da frente/embarcação de mergulho durante o planejamento das atividades através das ferramentas PT, CLM, DDS?',
        Answer: 'white',
      },
      '3-1 b 63': {
        Question:
          'A equipe de mergulho tem consciência da importância de manter atualizadas as ferramentas PT, CLM, DDS?',
        Answer: 'white',
      },
      '3-1 c 64': {
        Question:
          'No planejamento pré-mergulho é sempre verificado a possibilidade de alguma tarefa simultânea com carga suspensa no raio da operação e está contemplado em (PT, CLM, DDS)?',
        Answer: 'white',
      },
      '3-2 a 65': {
        Question:
          'Durante a fase de planejamento da operação de pull in e pull out em FPSO do tipo turret é solicitado que por medida de segurança, seja inserido um pull back secundário?',
        Answer: 'white',
      },
      '3-2 b 66': {
        Question:
          'É levado em consideração durante a fase de planejamento das operações de pull in e pull out em FPSO do tipo turret, a influência das condições metoceânicas adversas? ',
        Answer: 'white',
      },
      '3-2 c 67': {
        Question:
          'As unidades com turret, de modo geral, asseguram ao mergulho que o pull back está programado e operacional para a atividade do pull in e pull out?',
      },
      '3-3 a 68': {
        Question:
          'É designado um membro da equipe de mergulho como observador em caso de alteração de aproamento (heading) da unidade (FPSO que sejam turret)?',
        Answer: 'white',
      },
      '3-3 b 69': {
        Question: 'O observador da equipe de mergulho é treinado e capacitado nesta modalidade operacional?',
        Answer: 'white',
      },
      '3-3 c 70': {
        Question:
          'No DDS é salientado a importância de observar e comunicar qualquer movimento do turret decorrente da mudança de aproamento do FPSO? ',
        Answer: 'white',
      },
      '3-3 d 71': {
        Question:
          'O observador de mergulho que observa a manobra de alteração de aproamento (heading) da unidade (FPSO que sejam turret) é munido de rádio portátil para pronta comunicação com o controle de mergulho?',
        Answer: 'white',
      },
      '3-3 e 72': {
        Question:
          'Existe no plano de comunicação a garantia da utilização da comunicação secundária em uma situação de necessidade operacional?',
        Answer: 'white',
      }

    },
    'AM-4 - Presença de linha e rede de pesca': {
      '4-1 a 73': {
        Question:
          'Há na frente/embarcação de mergulho ROV operacional para as inspeções pré mergulho para identificar possíveis linhas e redes de pesca?',
        Answer: 'white',
      },
      '4-1 b 74': {
        Question:
          'No caso de não haver ROV na embarcação de mergulho, para uma possível inspeção pré mergulho afim de identificar presença de linha ou rede de pesca, existe no procedimento operacional de mergulho a sistemática de inspeção ou varredura da área sem utilizar o ROV?',
        Answer: 'white',
      },
      '4-1 d 76': {
        Question:
          'É realizada uma inspeção preliminar ao mergulho pelo ROV afim de identificar alguma interferência ex. (linha de pesca, etc.)?',
        Answer: 'white',
      },
      '4-1 e 77': {
        Question: 'É contemplado na reunião pré-operação estarem presentes os mergulhadores e os operadores do ROV?',
        Answer: 'white',
      },
      '4-1 f 78': {
        Question:
          'É comtemplada na APR a utilização do ROV em uma situação emergencial com o mergulhador?',
        Answer: 'white',
      },
      '4-1 g 79': {
        Question:
          'Há o acompanhamento dos planos de manutenção preventiva e preditiva do ROV conforme preconiza o fabricante, respeitando a periodicidade indicada?',
        Answer: 'white',
      },
      '4-2 a 81': {
        Question:
          'O supervisor e os mergulhadores são orientados a interromperem a operação em função de má visibilidade?',
        Answer: 'white',
      },
      '4-2 b 82': {
        Question:
          'O supervisor de mergulho é treinado para interromper a operação de mergulho em função de dificuldade de posicionamento que o mergulhador esteja passando na água mesmo com a força da correnteza abaixo do limite operacional? ',
        Answer: 'white',
      },
      '4-2 c 83': {
        Question:
          'É considerado na equipe de mergulho, pelo supervisor, quais são os mergulhadores com mais destreza em águas com visibilidade restrita?',
        Answer: 'white',
      },
      '4-3 a 84': {
        Question:
          'A APR contempla o risco da possibilidade de linha de pesca e rede na estrutura da unidade?',
        Answer: 'white',
      },
      '4-3 b 85': {
        Question:
          'Na APR há citação da importância da faca de mergulho em boas condições para uma necessidade?',
        Answer: 'white',
      },
      '4-3 c 86': {
        Question:
          'É realizada a leitura da APR com toda a equipe de mergulho?',
        Answer: 'white',
      },
      '4-3 d 87': {
        Question:
          'É realizado todos os dias antes do 1º mergulho um teste de descida subida do equipamento sem estar tripulado?',
        Answer: 'white',
      },
      '4-3 e 88': {
        Question:
          ' A equipe de mergulho tem a responsabilidade de participar da elaboração e do acompanhamento da eficácia da APR individual para cada operação de mergulho?',
        Answer: 'white',
      },



    },
      'AM-5 – Deslocamento inadvertido da embarcação durante as operações de mergulho': {
        '5-1 a 89': {
          Question:
            'São realizados simulados com ênfase no procedimento de resposta a emergências subaquáticas, no controle emocional dos mergulhadores e supervisores envolvidos nas operações de mergulho?',
          Answer: 'white',
        },
        '5-1 b 90': {
          Question:
            'Os supervisores e mergulhadores são capacitados e avaliados periodicamente em treinamentos para lidar com intercorrências durante as operações de mergulho através de simulados e demais exercícios práticos para saber como agir no caso de presença de linha ou rede de pesca?',
          Answer: 'white',
        },
        '5-1 c 91': {
          Question:
            'De forma geral, os supervisores têm o conhecimento do perfil dos membros de suas respectivas equipes, para em caso de uma intercorrência saber transmitir as orientações necessárias e manter a estabilidade emocional do mergulhador de forma a evitar que o mesmo possa vir a ficar aprisionado no fundo?',
          Answer: 'white',
        },
        '5-1 d 92': {
          Question:
            'Existe verificação do equipamento individual (faca) pré-mergulho e pós-mergulho para identificar possíveis avarias?',
          Answer: 'white',
        },
        '5-2 a 93': {
          Question:
            'O Supervisor de mergulho em comum acordo com o Comandante da embarcação estão atentos aos limites operacionais meteoceonográficas?',
          Answer: 'white',
        },
        '5-2 b 94': {
          Question:
            'Existe check list que contempla condições perigosas e limites operacionais de acordo com as condições meteoceonográficas para o mergulho?',
          Answer: 'white',
        },



      },

      'AM-6 -Erro operacional no isolamento da caixa de mar / captação': {
        '6-1 a 95': {
          Question:
            'Os supervisores e equipe são treinados e tem competência para planejar atividades tendo como base a PT, CLM, APR e DDS?',
          Answer: 'white',
        },
        '6-1 b 96': {
          Question:
            'No planejamento das operações de mergulho em caixa de mar de uma unidade marítima, o isolamento do comando de abertura das válvulas é solicitado na PT ou CLM com evidências fotográficas?',
          Answer: 'white',
        },
        '6-1 c 97': {
          Question:
            'É divulgado para toda a equipe a PT ou CLM no DDS do dia da operação em caixa de mar?',
          Answer: 'white',
        },
        '6-1 d 98': {
          Question:
            'A equipe é familiarizada no processo de geração dos documentos que antecedem a qualquer operação de mergulho que contenha risco de diferencial de pressão?',
          Answer: 'white',
        },
        '6-1 e 99': {
          Question:
            'Os supervisores estão treinados e atualizados de modo a orientar a sua equipe na formulação dos documentos (PT, CLM, APR, DDS) inerentes ao início das atividades de mergulho?',
          Answer: 'white',
        },
        '6-1 f 100': {
          Question:
            'Os supervisores e a equipe de mergulho têm conhecimento dos riscos de sucção durante a operação de mergulho em caixa de mar?',
          Answer: 'white',
        },
        '6-1 g 101': {
          Question:
            'Nas atividades envolvendo sucção e bombeamento o supervisor de mergulho participa do planejamento das operações através da elaboração de PT, CLM e DDS?',
          Answer: 'white',
        },
        '6-2 a 102': {
          Question:
            'Os supervisores e equipe participam da elaboração de APR específica de caixa de mar de cada unidade, tendo em vista as peculiaridades de cada unidade marítima?',
          Answer: 'white',
        },
        '6-2 b 103': {
            Question:
              'É divulgado no DDS do dia da operação para toda a equipe a APR específica de trabalho em caixa de mar, que engloba atividades que necessitem de alinhamento de válvulas para atividades simultâneas que estejam planejadas?',
            Answer: 'white',
          },
          '6-2 c 104': {
            Question:
              'Na APR é especificado o risco de operação simultânea em caixa de mar (abertura e fechamento de válvula para inspeção e televisionamento de acionamento da válvula no interior da caixa de mar?',
            Answer: 'white',
          },
          '6-2 d 105': {
            Question:
              'A equipe de mergulho é treinada e capacitada a identificar riscos não contemplados em uma APR e participar de uma análise preliminar de risco referente a operações simultâneas?',
            Answer: 'white',
          },
          '6-2 e 106': {
            Question:
              'A equipe de mergulho é treinada a identificar riscos diversos que possam pôr em perigo o mergulhador quando atuando em caixa de mar com operações simultâneas?',
            Answer: 'white',
          },
          '6-2 f 107': {
            Question:
              'O supervisor e a equipe de mergulho são treinados e qualificados para elaboração de APR?',
            Answer: 'white',
          },
          '6-2 g 108': {
            Question:
              'Os supervisores são treinados e têm competência para a elaboração de APR com diferencial de pressão e alinhamento de válvulas manuais e automáticas de sistemas de sucção/bombeamento?',
            Answer: 'white',
          },
          '6-3 a 109': {
            Question:
              'Os supervisores e a equipe de mergulho são treinados e orientados a confirmar na CLM, a presença das fotografias das válvulas da caixa de mar (fechadas) a ser trabalhada?',
            Answer: 'white',
          },
          '6-3 b 110': {
            Question:
              'É divulgado para a equipe em DDS a CLM com as fotos das válvulas (fechada) da caixa de mar a ser trabalhada?',
            Answer: 'white',
          },
          '6-3 c 111': {
            Question:
              'A equipe de mergulho tem conhecimento da importância do CLM quando do planejamento de uma atividade de mergulho?',
            Answer: 'white',
          },
          '6-3 d 112': {
            Question:
              'O supervisor e a equipe de mergulho são competentes para identificar falhas no planejamento das atividades através de PT, CLM e DDS?',
            Answer: 'white',
          },
          '6-3 e 113': {
            Question:
              'Existe treinamento para o supervisor de mergulho a fim de desenvolver a percepção de falhas no planejamento das atividades de mergulho durante a confecção de PT, CLM e DDS?',
            Answer: 'white',
          },
          '6-3 f 114': {
            Question:
              'Os supervisores são treinados e tem competência para planejar as atividades e garantir que o procedimento de alinhamento das válvulas para atividade simultânea está contido na PT e CLM?',
            Answer: 'white',
          },
          '6-3 g 115': {
            Question:
              'É divulgado para toda a equipe a PT ou CLM no DDS do dia da operação em atividades que necessitem de alinhamento de válvulas para atividades simultâneas?',
            Answer: 'white',
          },
          '6-4 a 116': {
            Question:
              'Na CLM estão especificados os ramais telefônicos e o canal VHF que serão utilizados durante operações de mergulho em caixa de mar e são testados antes das operações entre o controle de mergulho e a UEP?',
            Answer: 'white',
          },
          '6-4 b 117': {
            Question:
              'Os supervisores são treinados e capacitados para garantir uma comunicação clara e objetiva entre o controle de mergulho e a UEP durante operação em caixa de mar?',
            Answer: 'white',
          },
          '6-4 c 118': {
            Question:
              'Existe plano de comunicação integrado entre a equipe de mergulho e a equipe de bordo da UEP?',
            Answer: 'white',
          },
          '6-4 d 119': {
            Question:
              'A equipe de mergulho é familiarizada com o plano de comunicação de bordo da UEP?',
            Answer: 'white',
          },
          '6-5 a 120': {
            Question:
              'Os supervisores são treinados e capacitados para garantir a cobrança na CLM ou na PT do desligamento e isolamento das válvulas de acionamento da caixa de mar em croqui ou fotografias?',
            Answer: 'white',
          },
          '6-5 b 121': {
            Question:
              'Os supervisores divulgam em DDS a CLM ou a PT com a confirmação em croqui ou fotografia das válvulas de acionamento (fechada) da caixa de mar?',
            Answer: 'white',
          },
          '6-6 a 122': {
            Question:
              'A CLM/PT de caixa de mar, é realizada com todos os envolvidos com as operações da UEP e da equipe de mergulho?',
            Answer: 'white',
          },
          '6-6 b 123': {
            Question:
              'A CLM/PT de operações de mergulho em caixa de mar é diária para garantir que todos os que participaram e assinaram a CLM/PT estejam cientes da operação?',
            Answer: 'white',
          },
          '6-6 c 124': {
            Question:
              'A equipe de mergulho é treinada e capacitada para identificar a validação de um documento pré-operação?',
            Answer: 'white',
          },
          '6-6 d 125': {
            Question:
              'A equipe de mergulho é treinada e orientada a participar assiduamente de todas as reuniões pré-operação?',
            Answer: 'white',
          },
          '6-6 e 126': {
            Question:
              'Os supervisores realizam DDS específico com toda a equipe no dia em que estão planejadas operações de mergulho que necessitem do alinhamento de válvulas manuais e automáticas de sistemas de sucção/bombeamento para a realização das operações de mergulho?',
            Answer: 'white',
          },
      },

      'AM-7 - Falha no isolamento de linhas de vácuo (pressão negativa) / pressurizadas (pressão positiva) - ex dutos, poços e acessórios overboard': {
        '7-1 a 127': {
          Question:
            'As equipes são orientadas no DDS sobre o isolamento das linhas com pressão negativa ou positiva na PT, CLL ou CLM antes de operações de mergulho?',
          Answer: 'white',
        },
        '7-1 b 128': {
          Question:
            'O supervisor e a equipe de mergulho são treinados e habilitados no planejamento de atividades utilizando as ferramentas PT, CLL, CLM, DDS? ',
          Answer: 'white',
        },
        '7-1 c 129': {
          Question:
            'A equipe de mergulho tem o conhecimento da importância do isolamento das linhas pressurizadas e com pressão negativas para a execução das tarefas submersas?',
          Answer: 'white',
        },
        '7-2 a 130': {
          Question:
            'Os supervisores e a equipe de mergulho são treinados e capacitados para planejar as operações de mergulho através da APR?',
          Answer: 'white',
        },
        '7-2 b 131': {
          Question:
            'As equipes são orientadas no DDS e na reunião pré-operação o planejamento das operações de mergulho quando em trabalhos em linhas com pressão negativa ou positiva?',
          Answer: 'white',
        },
        '7-2 c 132': {
          Question:
            'A equipe de mergulho está preparada para identificar um risco durante a atividade que não foi mapeada devidamente?',
          Answer: 'white',
        },
        '7-3 a 133': {
          Question:
            'Antes do início das operações de mergulho em overboard os supervisores checam e confirmam a equalização das pressões das linhas?',
          Answer: 'white',
        },
        '7-3 b 134': {
          Question:
            'O supervisor e sua equipe de mergulho são treinados e capacitados para o planejamento, têm familiarização com a atividade em dutos, linhas de alta pressão e domínio da ferramenta CLL?',
          Answer: 'white',
        },
        '7-4 a 135': {
            Question:
              'Na CLM/PT estão especificados os ramais telefônicos e o canal VHF que serão utilizados durante operações de mergulho com isolamento de linhas e são testados antes das operações entre o controle de mergulho e a UEP?',
            Answer: 'white',
          },
          '7-4 b 136': {
            Question:
              'Os supervisores são treinados e capacitados para garantir uma comunicação clara e objetiva entre o controle de mergulho e a UEP durante operações com linhas pressurizadas ou despressurizadas?',
            Answer: 'white',
          },
          '7-5 a 137': {
            Question:
              'Os supervisores têm competência para orientar os mergulhadores no posicionamento seguro e analisar através de recurso (extensão com fita na extremidade e ou cabo de nylon) nas linhas de vácuo (pressão negativa) / pressurizadas (pressão positiva) - ex. dutos, poços e acessórios overboard?',
            Answer: 'white',
          },
          '7-5 b 138': {
            Question:
              'O procedimento operacional de mergulho contempla o tipo de recurso material que o mergulhador deve utilizar para avaliar diferencial de pressão nas linhas de vácuo (pressão negativa) / pressurizadas (pressão positiva) - ex. dutos, poços e acessórios overboard?',
            Answer: 'white',
          },
          '7-6 a 139': {
            Question:
              'A CLM/PT de caixa de mar ou overboard, é realizada com todos os envolvidos nas operações da UEP e mergulho?',
            Answer: 'white',
          },
          '7-6 b 140': {
            Question:
              'A CLM/PT de operações de mergulho em caixa de mar é diária para garantir que todos os que participaram e assinaram a CLM/PT estejam cientes da operação?',
            Answer: 'white',
          },
          '7-6 c 141': {
            Question:
              'É procedimento da empresa que a equipe de mergulho tenha o compromisso de participar das reuniões pré-operação?',
            Answer: 'white',
          },



      },

      'CO-1 - Lesão ou alteração funcional': {
        '8-1 a 142': {
          Question:
            'É contemplado no Plano de resposta a emergência o treinamento/ simulado de aprisionamento de mergulhador no fundo?',
          Answer: 'white',
        },
        '8-1 b 143': {
          Question:
            'A equipe de mergulho participa dos treinamentos de resposta a emergência da frente/embarcação de mergulho que embarcam frequentemente? ',
          Answer: 'white',
        },
        '8-1 c 144': {
          Question:
            'Os supervisores de mergulho são capacitados e treinados para manter o controle emocional e orientar os mergulhadores na água para que os mesmos não fiquem presos no fundo durante uma emergência na frente/embarcação de mergulho e informar esta contingência para a equipe onshore? ',
          Answer: 'white',
        },
        '8-1 d 145': {
          Question:
            'Os mergulhadores são orientados pelos supervisores para estarem sempre atualizados com o plano de resposta a emergência para em caso de alguma emergência a bordo da frente/embarcação de mergulho seguirem o protocolo estabelecido?',
          Answer: 'white',
        },
        '8-1 e 146': {
          Question:
            'São realizados simulados com a equipe de mergulho em cenário de contingência com abrangência para equipe on-shore? ',
          Answer: 'white',
        },
        '8-2 a 147': {
          Question:
            'Os supervisores são capacitados e treinados para orientar a equipe de mergulho na realização do resgate do mergulhador aprisionado no fundo, seguindo os procedimentos atualizados?',
          Answer: 'white',
        },
        '8-2 b 148': {
          Question:
            'Existe periodicidade para realização de simulado de resgate do mergulhador conforme preconiza a NORMAM-15?',
          Answer: 'white',
        },
        '8-2 c 149': {
          Question:
            'A empresa controla os treinamentos e simulados com frequência que envolve toda a equipe de mergulho nos procedimentos de resgate do mergulhador e mantem os procedimentos atualizados pelo setor responsável?',
          Answer: 'white',
        },
        '8-3 a 150': {
            Question:
              'Durante as operações de mergulho raso o supervisor de mergulho monitora o mergulhador no local de trabalho através dos sistemas de TV e comunicação com a ajuda do Bellman?',
            Answer: 'white',
          },
          '8-3 b 151': {
            Question:
              'O mergulhador de emergência na superfície, permanece equipado e pronto para intervir no menor tempo possível sendo num simulado ou durante uma intercorrência real?',
            Answer: 'white',
          },
          '8-3 c 152': {
            Question:
              'Os equipamentos de comunicação são checados antes e durante as operações de mergulho?',
            Answer: 'white',
          },
          '8-3 d 153': {
            Question:
              'A Black box do sistema de monitoramento do mergulhador está de acordo com as normas vigentes com capacidade suficiente de armazenamento?',
            Answer: 'white',
          },
          '8-3 e 154': {
            Question:
              'Os manômetros de profundidade são calibrados periodicamente conforme o requisito legal?',
            Answer: 'white',
          },
          '8-3 f 155': {
            Question:
              'Os mergulhadores são equipados com profundimetro de pulso?',
            Answer: 'white',
          },
          '8-4 a 156': {
            Question:
              'É de uso obrigatório do mergulhador e do bellman o porte de faca específica para mergulho e está contemplado em check list de EPIs do mergulhador?',
            Answer: 'white',
          },
          '8-4 b 157': {
            Question:
              'É mantido no interior do sino/sinete um recurso secundário para corte?',
            Answer: 'white',
          },
          '8-5 a 158': {
            Question:
              'Os supervisores são capacitados e treinados para orientar a realização do resgate do mergulhador pelo mergulhador de emergência? ',
            Answer: 'white',
          },
          '8-5 b 159': {
            Question:
              'O mergulhador de emergência na superfície, permanece equipado e pronto para intervir no menor tempo possível sendo num simulado ou durante uma intercorrência real?',
            Answer: 'white',
          },
          '8-5 c 160': {
            Question:
              'Existe periodicidade para realização de simulado de resgate do mergulhador pelo mergulhador de emergência?',
            Answer: 'white',
          },
          '8-5 d 161': {
            Question:
              'A empresa controla através do seu departamento de segurança o treinamento frequente envolvendo toda a equipe no procedimento de resgate do mergulhador através do mergulhador de emergência?',
            Answer: 'white',
          },
          '8-5 e 162': {
            Question:
              'O resultado dos treinamentos de resgate e emergência da equipe de mergulho tem sido satisfatório?',
            Answer: 'white',
          },
          '8-6 a 163': {
            Question:
              'Os supervisores de mergulho são capacitados e treinados no plano de resposta a emergência, abortar o mergulho em andamento em caso de mergulhador presono fundo, retornar a cesta, sinete ou a superfície com o auxílio de Bellman ou mergulhador de emergência?',
            Answer: 'white',
          },
          '8-6 b 164': {
            Question:
              'A equipe de mergulho é conscientizada nos riscos de aprisionamento do mergulhador no fundo durante a atividade de mergulho?',
            Answer: 'white',
          },
          '8-6 c 165': {
            Question:
              'É garantido sempre dois mergulhadores com EMED (Emergências Medicas subaquáticas) na equipe conforme especificado na NORMAM-15?',
            Answer: 'white',
          },
          '8-6 d 166': {
            Question:
              'O plano de contingência prevê o passo a passo para abortar o mergulho e retornar a cesta, sinete ou a superfície com a ajuda do Bellman ou o mergulhador de emergência?',
            Answer: 'white',
          },
          '8-6 e 167': {
            Question:
              'A equipe de mergulho é conscientizada nos riscos inerentes a atividade que executam quando embarcados nas unidades marítimas?',
            Answer: 'white',
          },
          '8-6 f 168': {
            Question:
              'É realizado treinamento simulando um retorno emergencial em um tempo menor do que o habitual nas operações rotineiras? ',
            Answer: 'white',
          },
          '8-6 g 169': {
            Question:
              'É desenvolvida a importância e cultura de percepção de risco a fim de identificar riscos não contemplados em APR?',
            Answer: 'white',
          },
          '8-7 a 170': {
            Question:
              'Existem, ao menos, dois membros na equipe de mergulho capacitados e treinados em suporte básico a vida no atendimento inicial a emergência subaquática atendendo as normas vigentes?',
            Answer: 'white',
          },
          '8-7 b 171': {
            Question:
              'São realizados simulados de primeiros socorros com as equipes de mergulho com ênfase em resgate e atendimento de mergulhador que sofreu aprisionamento no fundo do mar?',
            Answer: 'white',
          },
          '8-7 c 172': {
            Question:
              'É cobrado eficácia nos simulados dos planos de resposta a emergência com ênfase na comunicação adequada?',
            Answer: 'white',
          },
          '8-7 d 173': {
            Question:
              'O treinamento de reciclagem em atendimento a emergências de primeiros socorros atende as orientações de acordo com as normas vigentes?',
            Answer: 'white',
          },
          '8-7 e 174': {
            Question:
              'São realizados simulados de primeiros socorros com as equipes de mergulho com ênfase na saúde dos mergulhadores?',
            Answer: 'white',
          },
          '8-8 a 175': {
            Question:
              'Há profissional de saúde de nível superior devidamente habilitado na frente/embarcação de mergulho?',
            Answer: 'white',
          },
          '8-8 b 176': {
            Question:
              'O profissional de saúde é capacitado e treinado em suporte avançado a vida e atendimento a emergências subaquática?',
            Answer: 'white',
          },
          '8-9 a 177': {
            Question:
              'A embarcação possui enfermaria que atenda aos requisitos da NORMAM-01, da NR-30 e demais legislações pertinentes? ',
            Answer: 'white',
          },
          '8-9 b 178': {
            Question:
              'A enfermaria possui quantidade de equipamentos e medicamentos que atende a lista de itens mínimos de enfermaria padronizada pela Petrobras para embarcações de mergulho? ',
            Answer: 'white',
          },
          '8-10 a 179': {
            Question:
              'Os meios de comunicação com o suporte médico em terra são adequados e tem backup?',
            Answer: 'white',
          },
          '8-10 b 180': {
            Question:
              'No procedimento de contingência está previsto o plano de resposta a emergência médica com os recursos de contato telefônico ou por vídeo? ',
            Answer: 'white',
          },
          '8-10 c 181': {
            Question:
              'Existe plano de atendimento médico emergencial na empresa 24 horas para atender as equipes embarcadas no offshore?',
            Answer: 'white',
          },
          '8-11 a 182': {
            Question:
              'Existe procedimento específico para em caso de acidente com necessidade de remoção e transporte para hospital ou clínica em terra?',
            Answer: 'white',
          },
          '8-11 b 183': {
            Question:
              'Os supervisores estão aptos a orientar os responsáveis pelo transporte de acidentados via aérea, de que após a realização de mergulho, o voo deve cumprir um limite de altitude durante o voo em cumprimento da NORMAM-15?',
            Answer: 'white',
          },
          '8-11 c 184': {
            Question:
              'O Plano de contingência garante acesso a rede hospitalar especializada e a disponibilidade de recursos para um tratamento específico até o pronto reestabelecimento do acidentado?',
            Answer: 'white',
          },
          '8-11 d 185': {
            Question:
              'Existe procedimento da empresa que propicie a remoção do acidentado embarcado até a rede hospitalar?',
            Answer: 'white',
          },
          '8-11 e 186': {
            Question:
              'O RH da empresa presta suporte ao colaborador em caso de afastamento?',
            Answer: 'white',
          },
          '8-12 a 187': {
            Question:
              'A empresa através do seu RH tem como procedimento a avaliação médica criteriosa para liberação das atividades laborais do acidentado?',
            Answer: 'white',
          },
          '8-12 b 188': {
            Question:
              'O colaborador acidentado bem como sua família são acompanhados pelo RH no caso de um afastamento prolongado?',
            Answer: 'white',
          },



      },


  };

  let listKeysQuestionAprisionamentoMergulhador=[
    {
      'AM-1 - Umbilical preso em obstáculo': [
        { '1-2': { Check: [], Answer: 'white' } },
        { '1-3': { Check: [], Answer: 'white' } },
        { '1-4': { Check: [], Answer: 'white' } },
        { '1-5': { Check: [], Answer: 'white' } },
        { '1-6': { Check: [], Answer: 'white' } },
      ].reverse(),
      'AM-2 - Carga suspensa sobre área de mergulho': [
        { '2-1': { Check: [], Answer: 'white' } },
        { '2-2': { Check: [], Answer: 'white' } },
        { '2-3': { Check: [], Answer: 'white' } },
        { '2-4': { Check: [], Answer: 'white' } },
        { '2-5': { Check: [], Answer: 'white' } },
        { '2-6': { Check: [], Answer: 'white' } },
        { '2-7': { Check: [], Answer: 'white' } },
      ].reverse(),
      'AM-3 - Alteração de aproamento (heading) da unidade (FPSO que sejam turret)': [
        { '3-1': { Check: [], Answer: 'white' } },
        { '3-2': { Check: [], Answer: 'white' } },
        { '3-3': { Check: [], Answer: 'white' } },

      ].reverse(),
      'AM-4 - Presença de linha e rede de pesca': [
        { '4-1': { Check: [], Answer: 'white' } },
        { '4-2': { Check: [], Answer: 'white' } },
        { '4-3': { Check: [], Answer: 'white' } },

      ].reverse(),
      'AM-5 – Deslocamento inadvertido da embarcação durante as operações de mergulho': [
        { '5-1': { Check: [], Answer: 'white' } },
        { '5-2': { Check: [], Answer: 'white' } },

      ].reverse(),
      'AM-6 -Erro operacional no isolamento da caixa de mar / captação': [
        { '6-1': { Check: [], Answer: 'white' } },
        { '6-2': { Check: [], Answer: 'white' } },
        { '6-3': { Check: [], Answer: 'white' } },
        { '6-4': { Check: [], Answer: 'white' } },
        { '6-5': { Check: [], Answer: 'white' } },
        { '6-6': { Check: [], Answer: 'white' } },



      ].reverse(),
      'AM-7 - Falha no isolamento de linhas de vácuo (pressão negativa) / pressurizadas (pressão positiva) - ex dutos, poços e acessórios overboard': [
        { '7-1': { Check: [], Answer: 'white' } },
        { '7-2': { Check: [], Answer: 'white' } },
        { '7-3': { Check: [], Answer: 'white' } },
        { '7-4': { Check: [], Answer: 'white' } },
        { '7-5': { Check: [], Answer: 'white' } },
        { '7-6': { Check: [], Answer: 'white' } },


      ].reverse(),
      'CO-1 - Lesão ou alteração funcional': [
        { '8-1': { Check: [], Answer: 'white' } },
        { '8-2': { Check: [], Answer: 'white' } },
        { '8-3': { Check: [], Answer: 'white' } },
        { '8-4': { Check: [], Answer: 'white' } },
        { '8-5': { Check: [], Answer: 'white' } },
        { '8-6': { Check: [], Answer: 'white' } },
        { '8-7': { Check: [], Answer: 'white' } },
        { '8-8': { Check: [], Answer: 'white' } },
        { '8-9': { Check: [], Answer: 'white' } },
        { '8-10': { Check: [], Answer: 'white' } },
        { '8-11': { Check: [], Answer: 'white' } },
        { '8-12': { Check: [], Answer: 'white' } },


      ],

    }]


  export {TitlesAndQuestionsAprisionamentoMergulhador,listKeysQuestionAprisionamentoMergulhador,titleFormAprisionamentoMergulhador,titleHazardAprisionamentoMergulhador,SecondIdAprisionamentoMergulhador,listAMCOAprisionamentoMergulhador}

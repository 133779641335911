import styled from 'styled-components';

export const DivFrame = styled.div`
  width: 602px;
  height: 632px;
  border: solid 2px #00529b;
  margin: 0 auto;
  border-radius: 5px;
  background: #fff;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 64px;

  h1 {
    color: #00529b;
    text-align: center;
    margin-bottom: 96px;
  }
`;




const myArrayVerification=(myArray,setArray,groupName,AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm)=>{
  let groupNameSplit = groupName.split(' ');
  myArray.forEach(el => {
    for(let FirstKey of Object.keys(el) ){
      for (let SecondKey of Object.keys(el[FirstKey])){
        let KeySplit = SecondKey.split(' ');
        if (groupNameSplit[0] === KeySplit[0]) {
          if(!questionsSelected.includes(groupName)){
            for(let el of listKeysQuestion[0][FirstKey]){
              if (Object.keys(el)[0] === groupNameSplit[0]) {
                let dict = {};
                dict[groupName] = AnswerCheck;
                AnswerForm.push(dict);
                setAnswerForm([...AnswerForm]);

                el[groupNameSplit[0]]['Check'].push(AnswerCheck);
                questionsSelected.push(groupName);
                setListKeys([...listKeysQuestion]);
                setquestionsSelected([...questionsSelected]);
                break;
              }
            }
          }else{
            for(let el of listKeysQuestion[0][FirstKey] ){
              if (Object.keys(el)[0] === groupNameSplit[0]) {
                let dict = {};
                dict[groupName] = AnswerCheck;
                AnswerForm[questionsSelected.indexOf(groupName)]=dict;
                setAnswerForm([...AnswerForm]);
               el[groupNameSplit[0]]['Check'][el[groupNameSplit[0]]['Check'].length-1]=AnswerCheck
                setListKeys([...listKeysQuestion]);
                break;
              }
            }
          }

            break;
        }


      }

      for(let el of listKeysQuestion[0][FirstKey] ){
        if (Object.keys(el)[0] === groupNameSplit[0]) {
          if (el[groupNameSplit[0]]['Check'].includes('No') && el[groupNameSplit[0]]['Check'].includes('Yes')) {
            el[groupNameSplit[0]]['Answer'] = 'yellow';
            setListKeys([...listKeysQuestion]);
          } else if(el[groupNameSplit[0]]['Check'].includes('Yes') && !el[groupNameSplit[0]]['Check'].includes('No') ){
            el[groupNameSplit[0]]['Answer'] = 'green';
            setListKeys([...listKeysQuestion]);
          }else if(!el[groupNameSplit[0]]['Check'].includes('Yes') && el[groupNameSplit[0]]['Check'].includes('No') ){
            el[groupNameSplit[0]]['Answer'] = 'red';
            setListKeys([...listKeysQuestion]);
          } else {
            el[groupNameSplit[0]]['Answer'] = 'white';
            setListKeys([...listKeysQuestion]);
          }
        }
      }


    }

  });

      setArray([...myArray]);
}

const VerificationExposicaoInadequadaMergulhador= (listKeysQuestion, setListKeys,groupName,AnswerCheck,myArray,setArray,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm)=>{

    if(groupName==="1-6 b 29"){
        myArrayVerification(myArray,setArray,'2-6 b 47',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);

    }else if(groupName==="1-6 d 31"){
        myArrayVerification(myArray,setArray,'5-5 d 78',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
    }


}

export default VerificationExposicaoInadequadaMergulhador;

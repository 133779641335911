import { TitlesAndQuestionsFalhaSistemaLancamento } from '../formsModel/formFalhaSistemaLancamento';

const myArrayVerification = (
  myArray,
  setArray,
  groupName,
  AnswerCheck,
  listKeysQuestion,
  setListKeys,
  questionsSelected,
  setquestionsSelected,
  AnswerForm,
  setAnswerForm,
  AnswerQuestionForm,
  setAnswerQuestionForm
) => {
  let groupNameSplit = groupName.split(' ');
  myArray.forEach(el => {
    for (let FirstKey of Object.keys(el)) {
      for (let SecondKey of Object.keys(el[FirstKey])) {
        let KeySplit = SecondKey.split(' ');
        if (groupNameSplit[0] === KeySplit[0]) {
          if (!questionsSelected.includes(groupName)) {
            for (let el of listKeysQuestion[0][FirstKey]) {
              if (Object.keys(el)[0] === groupNameSplit[0]) {
                let dict = {};
                dict[groupName] = AnswerCheck;
                AnswerForm.push(dict);
                setAnswerForm([...AnswerForm]);

                if (
                  TitlesAndQuestionsFalhaSistemaLancamento[FirstKey][groupName]
                ) {
                  AnswerQuestionForm.push({
                    tagQuestion: groupName,
                    question:
                      TitlesAndQuestionsFalhaSistemaLancamento[FirstKey][
                        groupName
                      ].Question,
                  });
                  setAnswerQuestionForm([...AnswerQuestionForm]);

                  el[groupNameSplit[0]]['Check'].push(AnswerCheck);
                  questionsSelected.push(groupName);
                  setListKeys([...listKeysQuestion]);
                  setquestionsSelected([...questionsSelected]);
                  break;
                }
              }
            }
          } else {
            for (let el of listKeysQuestion[0][FirstKey]) {
              if (Object.keys(el)[0] === groupNameSplit[0]) {
                let dict = {};
                dict[groupName] = AnswerCheck;
                AnswerForm[questionsSelected.indexOf(groupName)] = dict;
                setAnswerForm([...AnswerForm]);
                el[groupNameSplit[0]]['Check'][
                  el[groupNameSplit[0]]['Check'].length - 1
                ] = AnswerCheck;
                setListKeys([...listKeysQuestion]);
                break;
              }
            }
          }

          break;
        }
      }

      for (let el of listKeysQuestion[0][FirstKey]) {
        if (Object.keys(el)[0] === groupNameSplit[0]) {
          if (
            el[groupNameSplit[0]]['Check'].includes('No') &&
            el[groupNameSplit[0]]['Check'].includes('Yes')
          ) {
            el[groupNameSplit[0]]['Answer'] = 'yellow';
            setListKeys([...listKeysQuestion]);
          } else if (
            el[groupNameSplit[0]]['Check'].includes('Yes') &&
            !el[groupNameSplit[0]]['Check'].includes('No')
          ) {
            el[groupNameSplit[0]]['Answer'] = 'green';
            setListKeys([...listKeysQuestion]);
          } else if (
            !el[groupNameSplit[0]]['Check'].includes('Yes') &&
            el[groupNameSplit[0]]['Check'].includes('No')
          ) {
            el[groupNameSplit[0]]['Answer'] = 'red';
            setListKeys([...listKeysQuestion]);
          } else {
            el[groupNameSplit[0]]['Answer'] = 'white';
            setListKeys([...listKeysQuestion]);
          }
        }
      }
    }
  });

  setArray([...myArray]);
};

const VerificationFalhaSistemaLancamento = (
  listKeysQuestion,
  setListKeys,
  groupName,
  AnswerCheck,
  myArray,
  setArray,
  questionsSelected,
  setquestionsSelected,
  AnswerForm,
  setAnswerForm,
  AnswerQuestionForm,
  setAnswerQuestionForm
) => {
  if (groupName === '5-6 c 109') {
    myArrayVerification(
      myArray,
      setArray,
      '6-5 0',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
    myArrayVerification(
      myArray,
      setArray,
      '7-7 0',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
  }
};

export default VerificationFalhaSistemaLancamento;


const titleFormEmergenciasMedicasHiberbaricas="Emergências não hiberbáricas (alteração clínica do mergulhador)"
const titleHazardEmergenciasMedicasHiberbaricas="Condição de Saúde Inadequada";
const SecondIdEmergenciasMedicasHiberbaricas="EmergenciasMedicasHiberbaricas";
const listAMCOEmergenciasMedicasHiberbaricas=['AM1 -  Afecção/Condição cardiovasculares, respiratórios e auditivas','CO1 – Lesão ou perturbação funcional, com potencial de sequela e fatalidade'];
let TitlesAndQuestionsEmergenciasMedicasHiberbaricas = {
    'AM1 -  Afecção/Condição cardiovasculares, respiratórios e auditivas': {
      '1-1 a 1': {
        Question:
          'O PCMSO, ou documento que ele remete, define claramente os requisitos de saúde estabelecidos por legislação ou obrigatoriedade contratual?',
        Answer: 'white',
      },
      '1-1 b 2': {
        Question:
          ' O PCMSO, ou documento a que ele remete, define claramente os requisitos de saúde relacionados a aptidão ou inaptidão médica das atividades ocupacionais de mergulho?',
        Answer: 'white',
      },
      '1-1 c 3': {
        Question:
          'O PCMSO é atualizado conforme as recomendações de parâmetros mínimos de saúde na aptidão ao mergulho definidos pelas recomendações Petrobras?',
        Answer: 'white',
      },
      '1-1 d 4': {
        Question:
          'No exame semestral dos mergulhadores é realizado o exame de resistência cardiovascular (MET e VO2 MAX) e adequados controles de fatores de riscos cardiológicos (triglicerídeos, colesterol e glicemia) em atendimento as exigências contratuais e legais necessárias? ',
        Answer: 'white',
      },
      '1-1 e 5': {
        Question:
          ' Nas avaliações médicas periódicas é mantida a rotina de exames médicos e é garantido o controle de substâncias licitas e ilícitas?',
        Answer: 'white',
      },
      '1-2 a 6': {
        Question:
          'Existe implantada política de substâncias proibidas (ex: anabolizantes, anfetaminas, psicotrópicos, drogas ilícitas)?',
        Answer: 'white',
      },
      '1-2 b 7': {
        Question:
          'A politica de substâncias proibidas (ex: anabolizantes, anfetaminas, psicotrópicos, drogas ilícitas) é divulgada para a equipe de mergulho?',
        Answer: 'white',
      },
      '1-2 c 8': {
        Question: 'Há sistemática no controle de uso indevido de medicações e substâncias proibidas (ex: anabolizantes, anfetaminas, psicotrópicos, drogas ilícitas)?',
        Answer: 'white',
      },
      '1-2 d 9': {
        Question:
          'É divulgada para as equipes de mergulho programa de conscientização quanto ao risco do uso de substâncias proibidas (ex: anabolizantes, anfetaminas, psicotrópicos, drogas ilícitas) com foco em problemas cardíacos?',
        Answer: 'white',
      },
      '1-2 e 10': {
        Question:
          'São realizados trabalhos de conscientização com os mergulhadores sobre as consequências da omissão de informação médica?',
        Answer: 'white',
      },
      '1-3 a 11': {
        Question:
          'Existe orientação médica específica para uma adequada composição corporal do mergulhador?',
        Answer: 'white',
      },
      '1-3 b 12': {
        Question:
          'São avaliadas nos exames periódicos a composição corporal (resistência e potência muscular preservadas) para a atividade?',
        Answer: 'white',
      },
      '1-3 c 13': {
        Question:
          'O controle do IMC, cintura e percentual de gordura corporal é realizado na equipe de forma rotineira por agente de saúde qualificado?',
        Answer: 'white',
      },
      '1-3 d 14': {
        Question:
          'Existe acompanhamento médico com verificação das condições físicas dos mergulhadores antes do embarque? ',
        Answer: 'white',
      },
      '1-3 e 15': {
        Question:
          'Existe programa de controle de obesidade com metas e objetivos bem definidos?',
        Answer: 'white',
      },
      '1-3 f 16': {
        Question:
          'Essas metas são acompanhadas periodicamente e os resultados são utilizados para melhoria contínua do programa?',
        Answer: 'white',
      },
      '1-3 g 17': {
        Question:
          'Existe programa de conscientização da responsabilidade do mergulhador se manter em bom condicionamento físico? ',
        Answer: 'white',
      },
      '1-3 h 18': {
        Question:
          'A empresa promove ações que motivem os mergulhadores a praticarem atividades físicas regulares?',
        Answer: 'white',
      },

      '1-4 a 19': {
        Question:
          'Os mergulhadores mantêm um estilo de vida saudável, compatível com a exigência da atividade, especialmente com relação aos aspectos nutricionais? ',
        Answer: 'white',
      },
      '1-4 b 20': {
        Question:
          'A equipe de mergulho conhece e aplica os aspectos descritos no Anexo E - Guia Nutricional para Mergulhadores, do PE-1PBR-00221 – MS Operações de Mergulho?',
        Answer: 'white',
      },
      '1-4 c 21': {
        Question:
          'O PCMSO aborda (ou remete a programas que o façam) ações preventivas em saúde que visam qualidade de vida e saúde integral da equipe de mergulho?',
        Answer: 'white',
      },
      '1-4 d 22': {
        Question:
          'Há procedimento específico para controle de riscos cardiovasculares, mais precisamente obesidade?',
        Answer: 'white',
      },
      '1-4 e 23': {
        Question:
          'Os mergulhadores são orientados para a manutenção de hábitos saudáveis com consequente conscientização e responsabilização de seus atos?',
        Answer: 'white',
      },
      '1-4 f 24': {
        Question:
          'É divulgada para as equipes de mergulho programa de conscientização de estilo de vida saudável que contemple riscos cardiovasculares? ',
        Answer: 'white',
      },
      '1-5 a 25': {
        Question:
          'Existe algum programa de conscientização de alimentação saudável e acompanhamento nutricional dos mergulhadores?',
        Answer: 'white',
      },
      '1-5 b 26': {
        Question:
          'A empresa de alimentação da frente/embarcação de mergulho é orientada para disponibilizar alimentos saudáveis as equipes de mergulho?',
        Answer: 'white',
      },
      '1-5 c 27': {
        Question:
          'Existe um planejamento entre as refeições e o horário do mergulho de forma a garantir que os mergulhadores não irão realizar as atividades com o estômago cheio (intervalo mínimo de 1 hora após o término da refeição)?',
        Answer: 'white',
      },
      '1-5 d 28': {
        Question:
          'Há evidências consistentes que os mergulhadores são devidamente orientados sobre as especificidades relacionadas à alimentação como fator de risco/proteção nas atividades de mergulho?',
        Answer: 'white',
      },
      '1-5 e 29': {
        Question:
          'Os supervisores orientam os mergulhadores sobre a importância de evitar alimentos de difícil digestão e bebidas carbonatadas (ex: refrigerantes) antes de qualquer mergulho?',
        Answer: 'white',
      },
      '1-6 a 30': {
        Question:
          'Existe algum programa de conscientização e acompanhamento nutricional dos mergulhadores?',
        Answer: 'white',
      },
      '1-6 b 31': {
        Question:
          'Existe um planejamento de hidratação de forma que o mergulhador consiga repor as perdas durante a atividade?',
        Answer: 'white',
      },
      '1-6 c 32': {
        Question:
          'Existe a recomendação de ingestão de ao menos 2L de água por dia?',
        Answer: 'white',
      },
      '1-6 d 33': {
        Question:
          'Os mergulhadores são orientados que devem beber líquido suficiente para equilibrar as perdas de líquido duas horas antes do exercício, 400–600 mL de líquido devem ser consumidos e após o mergulho, para repor as perdas pelo suor durante o exercício?',
        Answer: 'white',
      },
      '1-6 e 34': {
        Question:
          'São fornecidos aos mergulhadores isotônicos após realização das atividades de mergulho?',
        Answer: 'white',
      },
      '1-7 a 35': {
        Question:
          'No pré-embarque, é realizada avaliação de saúde em todos os mergulhadores, adotando minimamente os parâmetros estabelecidos na Tabela 2 – Avaliação de saúde pré embarque dos mergulhadores do PE-1BBR-00221 – Operações de Mergulho?',
        Answer: 'white',
      },
      '1-7 b 36': {
        Question:
          'Qualquer mergulhador que não se enquadre nos critérios da Tabela 2 – Avaliação de saúde pré embarque dos mergulhadores do PE-1BBR-00221 – Operações de Mergulho não é considerado apto para mergulho durante todo o período de embarque?',
        Answer: 'white',
      },
      '1-7 c 37': {
        Question:
          'A medida da circunferência da cintura é realizada seguindo a observação do item 3.8.1.8 do PE-1BBR-00221 – Operações de Mergulho?',
        Answer: 'white',
      },
      '1-7 d 38': {
        Question:
          'Nas avaliações médicas pré-embarque é mantida a rotina de exames médicos e é garantido o controle de substâncias licitas e ilícitas?',
        Answer: 'white',
      },
      '1-8 a 39': {
        Question:
          'No inicio de cada turno de trabalho são realizadas avaliações de saúde em todos os mergulhadores adotando minimamente os parâmetros estabelecidos na Tabela 3 – Avaliação de saúde diária dos mergulhadores do PE-1BBR-00221 – Operações de Mergulho?',
        Answer: 'white',
      },
      '1-8 b 40': {
        Question:
          'A equipe que realiza as avaliações pré-mergulho tem clareza dos requisitos de aptidão e inaptidão para o mergulho? ',
        Answer: 'white',
      },
      '1-8 c 41': {
        Question:
          'As fichas de avaliação pré mergulho possuem claramente os critérios de inaptidão para mergulho e contato com a equipe médica responsável em caso de dúvidas? ',
        Answer: 'white',
      },
      '1-8 d 42': {
        Question:
          'A estrutura do documento explicita claramente a condição final (indicado ou não indicado para mergulho)?',
        Answer: 'white',
      },
      '1-8 e 43': {
        Question:
          'Há registro que define a responsabilidade do mergulhador quanto à comunicação de seu estado de saúde no pré-mergulho?',
        Answer: 'white',
      },
      '1-8 f 44': {
        Question:
          'Os mergulhadores são orientados para em caso de problemas cardiovasculares, respiratórios e auditivos informar a supervisão antes de iniciar qualquer mergulho?',
        Answer: 'white',
      },
      '1-8 g 45': {
        Question:
          'São realizados trabalhos de conscientização com os mergulhadores sobre as consequências da omissão de informação médica?',
        Answer: 'white',
      },
      '1-9 a 46': {
        Question:
          'Os equipamentos que fazem parte do sistema de comunicação e monitoramento do mergulhador são testados antes e durante as operações de mergulho periodicamente? ',
        Answer: 'white',
      },
      '1-9 b 47': {
        Question:
          'Os supervisores de mergulho são orientados para manter comunicação constante com os mergulhadores durante as operações de mergulho visando verificarem seu estado de saúde?',
        Answer: 'white',
      },
      '1-10 a 48': {
        Question:
          'Para garantir a qualidade do ar captado são cumpridos os requisitos da NORMAM-15? ',
        Answer: 'white',
      },
      '1-10 b 49': {
        Question:
          'Em casos específicos onde haja risco de contaminação do ar captado pelo compressor por fatores externos ao sistema de mergulho, a equipe de mergulho é orientada a utilizar como suprimento de ar um quadro de cilindros de alta pressão composto por no mínimo oito cilindros para mergulhos até 30m e mínimo de doze cilindros para mergulhos de 30m até 50m que atendam aos requisitos da NORMAM-15? ',
        Answer: 'white',
      },
      '1-10 c 50': {
        Question:
          'Existe monitoramento do volume de ar comprimido disponibilizado para os mergulhadores e da quantidade de suprimento de emergência nos cilindros contidos nos quadros de ar?',
        Answer: 'white',
      },
      '1-10 d 51': {
        Question:
          'São observadas as validades dos cilindros de ar comprimido e dos quadros que compõem o sistema de suprimento de ar para os mergulhadores?',
        Answer: 'white',
      },
      '1-11 a 52': {
        Question:
          'É respeitado o horário de descanso do mergulhador de tal forma que garanta sono ininterrupto de 6 horas garantindo o seu bem-estar?',
        Answer: 'white',
      },
      '1-11 b 53': {
        Question:
          'É levado em consideração a susceptibilidade do mergulhador quando escalado para trabalhos noturnos?',
        Answer: 'white',
      },
      '1-11 c 54': {
        Question:
          'É avaliado o ambiente de descanso dos mergulhadores quanto a barulhos externos que possam vir a ocasionar prejuízos ao seu descanso?',
        Answer: 'white',
      },
      '1-11 d 55': {
        Question:
          'O período de descanso dos trabalhadores de turno é estabelecido, bem definido em procedimento e respeitado conforme legislação vigente? ',
        Answer: 'white',
      },
      '1-11 e 56': {
        Question:
          'Existe o procedimento de acompanhar o desempenho e saúde dos funcionários que trabalham em turno (misto ou noturno) por médico especialista em distúrbios do sono (neurologista)?',
        Answer: 'white',
      },
      '1-12 a 57': {
        Question:
          'São realizados simulados com ênfase no procedimento de resposta a emergências subaquáticas, no controle emocional dos mergulhadores e supervisores envolvidos nas operações de mergulho?',
        Answer: 'white',
      },
      '1-12 b 58': {
        Question:
          'Os supervisores e mergulhadores são capacitados e avaliados periodicamente em treinamentos para lidar com intercorrências durante as operações de mergulho através de simulados e demais exercícios práticos garantindo seu controle emocional?',
        Answer: 'white',
      },
      '1-12 c 59': {
        Question:
          'Nos simulados de mergulho, os supervisores são orientados a focar na calma e no controle emocional dos mergulhadores para evitar que os mesmos entrem em desespero perante uma intercorrência?',
        Answer: 'white',
      },
      '1-12 d 60': {
        Question:
          'De forma geral, os supervisores têm o conhecimento do perfil dos membros de suas respectivas equipes, para em caso de uma intercorrência saber transmitir as orientações necessárias e manter a estabilidade emocional do mergulhador para que o mesmo não entre em desespero?',
        Answer: 'white',
      },
    },
    'AM 2 -  Afecção/Condição osteomusculares': {
      '2-1 a 61': {
        Question:
          'O PCMSO, ou documento que ele remete, define claramente os requisitos de saúde estabelecidos por legislação ou obrigatoriedade contratual?',
        Answer: 'white',
      },
      '2-1 b 62': {
        Question:
          'O PCMSO, ou documento a que ele remete, define claramente os requisitos de saúde relacionados a aptidão ou inaptidão médica das atividades ocupacionais de mergulho?',
        Answer: 'white',
      },
      '2-1 c 63': {
        Question:
          'O PCMSO é atualizado conforme as recomendações de parâmetros mínimos de saúde na aptidão ao mergulho definidos pelas recomendações Petrobras?',
        Answer: 'white',
      },
      '2-1 d 64': {
        Question:
          'São avaliadas nos exames periódicos a composição corporal (resistência, tônus, força e potência muscular preservadas) para a atividade?',
        Answer: 'white',
      },
      '2-1 e 65': {
        Question:
          'São realizados exames específicos que verifiquem as condições osteomusculares dos mergulhadores indicando a aptidão ou inaptidão para a atividade de mergulho?',
        Answer: 'white',
      },
      '2-1 f 66': {
        Question:
          'Nas avaliações médicas periódicas é mantida a rotina de exames médicos e é garantido o controle de substâncias licitas e ilícitas?',
        Answer: 'white',
      },
      '2-2 a 67': {
        Question:
          'Existe orientação médica específica para uma adequada composição corporal do mergulhador?',
        Answer: 'white',
      },
      '2-2 b 68': {
        Question:
          'O controle do IMC, cintura e percentual de gordura corporal é realizado na equipe de forma rotineira por agente de saúde qualificado?',
        Answer: 'white',
      },
      '2-2 c 69': {
        Question:
          'Existe acompanhamento médico com verificação das condições físicas dos mergulhadores antes do embarque? ',
        Answer: 'white',
      },
      '2-2 d 70': {
        Question:
          'Há procedimento específico para controle de riscos osteomusculares?',
        Answer: 'white',
      },
      '2-2 e 71': {
        Question:
          'Existe programa de conscientização da responsabilidade do mergulhador se manter em bom condicionamento físico? ',
        Answer: 'white',
      },
      '2-2 f 72': {
        Question:
          'A empresa promove ações que motivem os mergulhadores a praticarem atividades físicas regulares?',
        Answer: 'white',
      },
      '2-3 a 73': {
        Question:
          ' Existe algum programa de conscientização de alimentação saudável e acompanhamento nutricional dos mergulhadores?',
        Answer: 'white',
      },
      '2-3 b 74': {
        Question:
          'A empresa de alimentação da frente/embarcação de mergulho é orientada para disponibilizar alimentos saudáveis as equipes de mergulho?',
        Answer: 'white',
      },
      '2-3 c 75': {
        Question:
          ' Existe um planejamento entre as refeições e o horário do mergulho de forma a garantir que os mergulhadores não irão realizar as atividades com o estômago cheio (intervalo mínimo de 1 hora após o término da refeição)?',
        Answer: 'white',
      },
      '2-3 d 76': {
        Question:
          'Há evidências consistentes que os mergulhadores são devidamente orientados sobre as especificidades relacionadas à alimentação como fator de risco/proteção nas atividades de mergulho?',
        Answer: 'white',
      },
      '2-4 a 77': {
        Question:
          'Existe algum programa de conscientização e acompanhamento nutricional dos mergulhadores?',
        Answer: 'white',
      },
      '2-4 b 78': {
        Question:
          'Existe um planejamento de hidratação de forma que o mergulhador consiga repor as perdas durante a atividade?',
        Answer: 'white',
      },
      '2-4 c 79': {
        Question:
          'Existe a recomendação de ingestão de ao menos 2L de água por dia?',
        Answer: 'white',
      },
      '2-4 d 80': {
        Question:
          'Os mergulhadores são orientados que devem beber líquido suficiente para equilibrar as perdas de líquido duas horas antes do exercício, 400–600 mL de líquido devem ser consumidos e após o mergulho, para repor as perdas pelo suor durante o exercício?',
        Answer: 'white',
      },
      '2-4 e 81': {
        Question:
          'São fornecidos aos mergulhadores isotônicos após realização das atividades de mergulho?',
        Answer: 'white',
      },
      '2-5 a 82': {
        Question:
          'Os mergulhadores mantêm um estilo de vida saudável, compatível com a exigência da atividade, especialmente com relação aos aspectos nutricionais? ',
        Answer: 'white',
      },
      '2-5 b 83': {
        Question:
          'A equipe de mergulho conhece e aplica os aspectos descritos no Anexo E - Guia Nutricional para Mergulhadores, do PE-1PBR-00221 – MS Operações de Mergulho?',
        Answer: 'white',
      },
      '2-5 c 84': {
        Question:
          'O PCMSO aborda (ou remete a programas que o façam) ações preventivas em saúde que visam qualidade de vida e saúde integral da equipe de mergulho?',
        Answer: 'white',
      },
      '2-5 d 85': {
        Question:
          'Os mergulhadores são orientados para a manutenção de hábitos saudáveis com consequente conscientização e responsabilização de seus atos?',
        Answer: 'white',
      },
      '2-5 e 86': {
        Question:
          'É divulgada para as equipes de mergulho programa de conscientização de estilo de vida saudável que contemple riscos osteomusculares?',
        Answer: 'white',
      },
      '2-6 a 87': {
        Question:
          'É respeitado o horário de descanso do mergulhador de tal forma que garanta sono ininterrupto de 6 horas garantindo o seu bem-estar?',
        Answer: 'white',
      },
      '2-6 b 88': {
        Question:
          'É levado em consideração a susceptibilidade do mergulhador quando escalado para trabalhos noturnos?',
        Answer: 'white',
      },
      '2-6 c 89': {
        Question:
          'É avaliado o ambiente de descanso dos mergulhadores quanto a barulhos externos que possam vir a ocasionar prejuízos ao seu descanso?',
        Answer: 'white',
      },
      '2-6 d 90': {
        Question:
          'O período de descanso dos trabalhadores de turno é estabelecido, bem definido em procedimento e respeitado conforme legislação vigente? ',
        Answer: 'white',
      },
      '2-7 a 91': {
        Question:
          'Existe controle das condições osteomusculares pré-existentes dos mergulhadores?',
        Answer: 'white',
      },
      '2-7 b 92': {
        Question:
          'É levado em consideração essas condições osteomusculares pré-existentes para a realização das atividades de mergulho?',
        Answer: 'white',
      },
      '2-8 a 93': {
        Question:
          'Os riscos ergonômicos da atividade de mergulho estão contemplados na APR?',
        Answer: 'white',
      },
      '2-8 b 94': {
        Question:
          'A equipe de mergulho recebe orientações quanto aos riscos ergonômicos da atividade do dia a ser efetuada?',
        Answer: 'white',
      },
      '2-8 c 95': {
        Question:
          'Existe um planejamento de movimentação considerando o peso efetivo emerso e submerso dos equipamentos utilizados na atividade?',
        Answer: 'white',
      },
      '2-9 a 96': {
        Question:
          'No inicio de cada turno de trabalho são realizadas avaliações de saúde em todos os mergulhadores?',
        Answer: 'white',
      },
      '2-9 b 97': {
        Question:
          'A equipe que realiza as avaliações pré-mergulho tem clareza dos requisitos de aptidão e inaptidão para o mergulho? ',
        Answer: 'white',
      },
      '2-9 c 98': {
        Question:
          'As fichas de avaliação pré mergulho possuem claramente os critérios de inaptidão para mergulho e contato com a equipe médica responsável em caso de dúvidas? ',
        Answer: 'white',
      },
      '2-9 d 99': {
        Question:
          'A estrutura do documento explicita claramente a condição final (indicado ou não indicado para mergulho)?',
        Answer: 'white',
      },
      '2-9 e 100': {
        Question:
          'Há registro que define a responsabilidade do mergulhador quanto à comunicação de seu estado de saúde no pré-mergulho?',
        Answer: 'white',
      },
      '2-9 f 101': {
        Question:
          'Os mergulhadores são orientados para em caso de problemas osteomusculares informar a supervisão antes de iniciar qualquer mergulho?',
        Answer: 'white',
      },
      '2-9 g 102': {
        Question:
          'São realizados trabalhos de conscientização com os mergulhadores sobre as consequências da omissão de informação médica?',
        Answer: 'white',
      },
      '2-10 a 103': {
        Question:
          'São realizados simulados com ênfase no procedimento de resposta a emergências subaquáticas, no controle emocional dos mergulhadores e supervisores envolvidos nas operações de mergulho?',
        Answer: 'white',
      },

      '2-10 b 104': {
        Question:
          'Os supervisores e mergulhadores são capacitados e avaliados periodicamente em treinamentos para lidar com intercorrências durante as operações de mergulho através de simulados e demais exercícios práticos garantindo seu controle emocional?',
        Answer: 'white',
      },

      '2-10 c 105': {
        Question:
          'Nos simulados de mergulho, os supervisores são orientados a focar na calma e no controle emocional dos mergulhadores para evitar que os mesmos entrem em desespero perante uma intercorrência?',
        Answer: 'white',
      },

      '2-10 d 106': {
        Question:
          'Em geral, os supervisores têm o conhecimento do perfil dos membros de suas respectivas equipes, para em caso de uma intercorrência saber transmitir as orientações necessárias e manter a estabilidade emocional do mergulhador para que o mesmo não entre em desespero?',
        Answer: 'white',
      },



    },
    'AM3 - Afecção/Condição gastrointestinais': {
      '3-1 a 107': {
        Question:
          'O PCMSO, ou documento que ele remete, define claramente os requisitos de saúde estabelecidos por legislação ou obrigatoriedade contratual?',
        Answer: 'white',
      },
      '3-1 b 108': {
        Question:
          'O PCMSO, ou documento a que ele remete, define claramente os requisitos de saúde relacionados a aptidão ou inaptidão médica das atividades ocupacionais de mergulho?',
        Answer: 'white',
      },
      '3-1 c 109': {
        Question:
          'O PCMSO é atualizado conforme as recomendações de parâmetros mínimos de saúde na aptidão ao mergulho definidos pelas recomendações Petrobras?',
        Answer: 'white',
      },
      '3-1 d 110': {
        Question:
          'Está contemplado no plano de exames médicos dos mergulhadores o exame de endoscopia, teste alérgico, exame de sangue?',
        Answer: 'white',
      },
      '3-1 e 111': {
        Question:
          'Nas avaliações médicas periódicas é mantida a rotina de exames médicos e é garantido o controle de substâncias licitas e ilícitas?',
        Answer: 'white',
      },
      '3-1 f 112': {
        Question:
          'Existe um acompanhamento minucioso da saúde do mergulhador por equipe de saúde qualificada de modo que seja possível identificar condições de saúde especificas, como: gastrite, intolerância alimentar, doença celíaca, síndrome do intestino irritável e refluxo?',
        Answer: 'white',
      },
      '3-2 a 113': {
        Question:
          'Os mergulhadores mantêm um estilo de vida saudável, compatível com a exigência da atividade, especialmente com relação aos aspectos nutricionais? ',
        Answer: 'white',
      },
      '3-2 b 114': {
        Question:
          'A equipe de mergulho conhece e aplica os aspectos descritos no Anexo E - Guia Nutricional para Mergulhadores, do PE-1PBR-00221 – MS Operações de Mergulho?',
        Answer: 'white',
      },
      '3-2 c 115': {
        Question:
          'O PCMSO aborda (ou remete a programas que o façam) ações preventivas em saúde que visam qualidade de vida e saúde integral da equipe de mergulho?',
        Answer: 'white',
      },
      '3-2 d 116': {
        Question:
          'Há procedimento específico para controle de riscos gastrointestinais?',
        Answer: 'white',
      },
      '3-2 e 117': {
        Question:
          'Os mergulhadores são orientados para a manutenção de hábitos saudáveis com consequente conscientização e responsabilização de seus atos?',
        Answer: 'white',
      },
      '3-2 f 118': {
        Question:
          'É divulgada para as equipes de mergulho programa de conscientização de estilo de vida saudável que contemple riscos gastrointestinais?',
        Answer: 'white',
      },
      '3-2 g 119': {
        Question:
          'A empresa promove DDS de conscientização a vida saudável, sobre o sedentarismo associado a má alimentação e baixa ingestão de líquidos podendo causar doenças gastrointestinais?',
        Answer: 'white',
      },
      '3-3 a 120': {
        Question:
          'Existe controle das condições sanitárias e de segurança dos alimentos e água consumidos na frente/embarcação de mergulho?',
        Answer: 'white',
      },
      '3-3 b 121': {
        Question:
          'Existem programas de conscientização de higiene quanto as boas práticas a serem cumpridas?',
        Answer: 'white',
      },
      '3-3 c 122': {
        Question:
          'São realizados testes laboratoriais na água consumida na frente/embarcação de mergulho?',
        Answer: 'white',
      },
      '3-3 d 123': {
        Question:
          'São seguidas as leis sanitárias quanto ao armazenamento e manipulação dos alimentos na frente/embarcação de mergulho?',
        Answer: 'white',
      },
      '3-4 a 124': {
        Question:
          'Existe algum programa de conscientização de alimentação saudável e acompanhamento nutricional dos mergulhadores?',
        Answer: 'white',
      },
      '3-4 b 125': {
        Question:
          'A empresa de alimentação da frente/embarcação de mergulho é orientada para disponibilizar alimentos saudáveis as equipes de mergulho?',
        Answer: 'white',
      },
      '3-4 c 126': {
        Question:
          'Existe um planejamento entre as refeições e o horário do mergulho de forma a garantir que os mergulhadores não irão realizar as atividades com o estômago cheio (intervalo mínimo de 1 hora após o término da refeição)?',
        Answer: 'white',
      },
      '3-4 d 127': {
        Question:
          'Há evidências consistentes que os mergulhadores são devidamente orientados sobre as especificidades relacionadas à alimentação como fator de risco/proteção nas atividades de mergulho?',
        Answer: 'white',
      },
      '3-4 e 128': {
        Question:
          'Os supervisores orientam os mergulhadores sobre a importância de evitar alimentos de difícil digestão e bebidas carbonatadas (ex: refrigerantes) antes de qualquer mergulho?',
        Answer: 'white',
      },
      '3-5 a 129': {
        Question:
          'Existe algum programa de conscientização e acompanhamento nutricional dos mergulhadores?',
        Answer: 'white',
      },
      '3-5 b 130': {
        Question:
          'Existe um planejamento de hidratação de forma que o mergulhador consiga repor as perdas durante a atividade?',
        Answer: 'white',
      },
      '3-5 c 131': {
        Question:
          'Existe a recomendação de ingestão de ao menos 2L de água por dia?',
        Answer: 'white',
      },
      '3-5 d 132': {
        Question:
          'Os mergulhadores são orientados que devem beber líquido suficiente para equilibrar as perdas de líquido duas horas antes do exercício, 400–600 mL de líquido devem ser consumidos e após o mergulho, para repor as perdas pelo suor durante o exercício?',
        Answer: 'white',
      },
      '3-5 e 133': {
        Question:
          'São fornecidos aos mergulhadores isotônicos após realização das atividades de mergulho?',
        Answer: 'white',
      },
      '3-6 a 134': {
        Question:
          'O responsável pela avaliação pré mergulho possui competência para a realização da mesma?',
        Answer: 'white',
      },
      '3-6 b 135': {
        Question:
          'No inicio de cada turno de trabalho são realizadas avaliações de saúde em todos os mergulhadores?',
        Answer: 'white',
      },
      '3-6 c 136': {
        Question:
          'A equipe que realiza as avaliações pré-mergulho tem clareza dos requisitos de aptidão e inaptidão para o mergulho? ',
        Answer: 'white',
      },
      '3-6 d 137': {
        Question:
          ' As fichas de avaliação pré mergulho possuem claramente os critérios de inaptidão para mergulho e contato com a equipe médica responsável em caso de dúvidas? ',
        Answer: 'white',
      },
      '3-6 e 138': {
        Question:
          'A estrutura do documento explicita claramente a condição final (indicado ou não indicado para mergulho)?',
        Answer: 'white',
      },
      '3-6 f 139': {
        Question:
          'Há registro que define a responsabilidade do mergulhador quanto à comunicação de seu estado de saúde no pré-mergulho?',
        Answer: 'white',
      },
      '3-6 g 140': {
        Question:
          'Os mergulhadores são orientados para em caso de problemas gastrointestinais, informar a supervisão de mergulho e ao setor de saúde da frente/embarcação de mergulho?',
        Answer: 'white',
      },
      '3-6 h 141': {
        Question:
          'São realizados trabalhos de conscientização com os mergulhadores sobre as consequências da omissão de informação médica?',
        Answer: 'white',
      },
      '3-7 a 142': {
        Question:
          'São respeitados os intervalos de tempo, composição e volume de refeição antes da realização do mergulho?',
        Answer: 'white',
      },
      '3-7 b 143': {
        Question:
          'A equipe de mergulho adota intervalo mínimo de 1h e 30 min para as principais refeições do dia (café da manhã, almoço, jantar e ceia), sendo o período entre o término da refeição e o início da operação de mergulho de, no mínimo, 1 hora?',
        Answer: 'white',
      },
      '3-7 c 144': {
        Question:
          'As equipes de mergulho que irão mergulhar após o horário das refeições são orientadas a cumprirem as orientações nutricionais pré-mergulho?',
        Answer: 'white',
      },
      '3-7 d 145': {
        Question:
          'A empresa fornece para a equipe de mergulho cardápio de alimentação adequada conforme orientação de nutricionista em quantidade adequada antes e após o mergulho?',
        Answer: 'white',
      },
      '3-7 e 146': {
        Question:
          'São realizadas campanhas de orientação nutricional para a equipe de mergulho e é informada a importância do cumprimento das orientações alimentares pré-mergulho e pós-mergulho?',
        Answer: 'white',
      },
      '3-8 a 147': {
        Question:
          'É respeitado o horário de descanso do mergulhador de tal forma que garanta sono ininterrupto de 6 horas garantindo o seu bem-estar?',
        Answer: 'white',
      },
      '3-8 b 148': {
        Question:
          'É levado em consideração a susceptibilidade do mergulhador quando escalado para trabalhos noturnos?',
        Answer: 'white',
      },
      '3-8 c 149': {
        Question:
          'É avaliado o ambiente de descanso dos mergulhadores quanto a barulhos externos que possam vir a ocasionar prejuízos ao seu descanso?',
        Answer: 'white',
      },
      '3-8 d 150': {
        Question:
          'O período de descanso dos trabalhadores de turno é estabelecido, bem definida em procedimento e respeitada conforme legislação vigente?',
        Answer: 'white',
      },
      '3-9 a 151': {
        Question:
          'São realizados simulados com ênfase no procedimento de resposta a emergências subaquáticas, no controle emocional dos mergulhadores e supervisores envolvidos nas operações de mergulho?',
        Answer: 'white',
      },
      '3-9 b 152': {
        Question:
          'Os supervisores e mergulhadores são capacitados e avaliados periodicamente em treinamentos para lidar com intercorrências durante as operações de mergulho através de simulados e demais exercícios práticos garantindo seu controle emocional?',
        Answer: 'white',
      },
      '3-9 c 153': {
        Question:
          'Nos simulados de mergulho, os supervisores são orientados a focar na calma e no controle emocional dos mergulhadores para evitar que os mesmos entrem em desespero perante uma intercorrência?',
        Answer: 'white',
      },
      '3-9 d 154': {
        Question:
          'De forma geral, os supervisores têm o conhecimento do perfil dos membros de suas respectivas equipes, para em caso de uma intercorrência saber transmitir as orientações necessárias e manter a estabilidade emocional do mergulhador para que o mesmo não entre em desespero?',
        Answer: 'white',
      },


    },
    'AM-4 - Afecção/Condição neuropsicológicos': {
      '4-1 a 155': {
        Question:
          'São realizados exames específicos que verifiquem as condições neuropsicológicas dos mergulhadores indicando a aptidão ou inaptidão para o embarque e realização da atividade de mergulho?',
        Answer: 'white',
      },
      '4-1 b 156': {
        Question:
          'Os exames médicos, que definem a aptidão ao mergulho contemplam identificar doenças neuropsicológicas como (insônia e outros distúrbios do sono, ansiedade e depressão, problemas articulares, estresse)?',
        Answer: 'white',
      },
      '4-1 c 157': {
        Question:
          'Nas avaliações médicas periódicas e nas avaliações médicas pré-embarque é mantida a rotina de exames médicos e é garantido o controle de substâncias licitas e ilícitas?',
        Answer: 'white',
      },
      '4-1 d 158': {
        Question:
          'Existe programa de saúde mental na empresa?',
        Answer: 'white',
      },
      '4-1 e 159': {
        Question:
          'Existe canal de atendimento de saúde por profissionais qualificados na área médica (psicólogo, neurologista, psiquiatra) disponível para a equipe de mergulho?',
        Answer: 'white',
      },
      '4-1 f 160': {
        Question: 'São realizados trabalhos de conscientização com os mergulhadores sobre as consequências da omissão de informação psicológica?',
        Answer: 'white',
      },
      '4-2 a 161': {
        Question: 'Existe sistemática de avaliação da saúde mental nos exames ocupacionais associados à definição de aptidão psicológica para as atividades de mergulho?',
        Answer: 'white',
      },
      '4-2 b 162': {
        Question: 'Existe sistemática para suporte aos mergulhadores sob riscos psicológicos devido a atividade ou advindas de situações externas (família, quarentena, problemas financeiros...)?',
        Answer: 'white',
      },
      '4-2 c 163': {
        Question: 'Os mergulhadores e supervisores possuem treinamento na importância do autocontrole emocional, autoconhecimento, em como manter a calma e a saúde mental?',
        Answer: 'white',
      },
      '4-3 a 164': {
        Question: 'Os mergulhadores mantêm um estilo de vida saudável, compatível com a exigência da atividade? ',
        Answer: 'white',
      },
      '4-3 b 165': {
        Question: 'O PCMSO aborda (ou remete a programas que o façam) ações preventivas em saúde que visam qualidade de vida e saúde integral da equipe de mergulho?',
        Answer: 'white',
      },
      '4-3 c 166': {
        Question: 'Os mergulhadores são orientados para a manutenção de hábitos saudáveis com consequente conscientização e responsabilização de seus atos?',
        Answer: 'white',
      },
      '4-3 d 167': {
        Question: 'É divulgada para as equipes de mergulho programa de conscientização de estilo de vida saudável que contemple riscos neuropsicológicos?',
        Answer: 'white',
      },
      '4-4 a 168': {
        Question: 'No check list pré mergulho são verificadas as condições psicológicas dos mergulhadores?',
        Answer: 'white',
      },
      '4-4 b 169': {
        Question: 'O responsável pela avaliação psicológica possui competência para a realização da mesma?',
        Answer: 'white',
      },
      '4-4 c 170': {
        Question: 'Os mergulhadores são orientados para em caso de problemas neuropsicológicos (tontura, dor de cabeça, alteração na visão, irritabilidade e etc.), informar ao supervisor de mergulho e ao setor de saúde da frente/embarcação de mergulho?',
        Answer: 'white',
      },
      '4-4 d 171': {
        Question: 'A equipe de mergulho é orientada quanto a gravidade em omitir ou não manifestar indisposição psicológica antes de realizar alguma atividade de mergulho?',
        Answer: 'white',
      },
      '4-4 e 172': {
        Question: 'Há sistemática de controle de riscos e suporte em saúde mental com critérios definidos de acompanhamento - incluindo referência a serviços especializados, feedback para a equipe de saúde e desdobramento de ações ocupacionais e administrativas, se necessário?',
        Answer: 'white',
      },
      '4-5 a 173': {
        Question: 'É respeitado o horário de descanso do mergulhador de tal forma que garanta sono ininterrupto de 6 horas garantindo o seu bem-estar?',
        Answer: 'white',
      },
      '4-5 b 174': {
        Question: 'É levado em consideração a susceptibilidade do mergulhador quando escalado para trabalhos noturnos?',
        Answer: 'white',
      },
      '4-5 c 175': {
        Question: 'É avaliado o ambiente de descanso dos mergulhadores quanto a barulhos externos que possam vir a ocasionar prejuízos ao seu descanso?',
        Answer: 'white',
      },
      '4-5 d 176': {
        Question: 'O período de descanso dos trabalhadores de turno é estabelecido, bem definida em procedimento e respeitada conforme legislação vigente? ',
        Answer: 'white',
      },
      '4-6 a 177': {
        Question: 'É realizado treinamento das equipes da frente/embarcação de mergulho em como identificar sintomas neuropsicológicos?',
        Answer: 'white',
      },
      '4-6 b 178': {
        Question: 'A equipe médica é treinada e tem experiência em identificar sintomas neuropsicológicos, no tratamento e procedimento de desembarque caso necessário?',
        Answer: 'white',
      },
      '4-6 c 179': {
        Question: 'Existe sistemática para suporte aos mergulhadores sob riscos psicológicos devido a atividade ou advindas de situações externas (família, quarentena, problemas financeiros...)?',
        Answer: 'white',
      },
      '4-7 a 180': {
        Question: 'Há sistemática no controle de uso indevido de medicações e substâncias proibidas (ex: anabolizantes, anfetaminas, psicotrópicos, drogas ilícitas)?',
        Answer: 'white',
      },
      '4-7 b 181': {
        Question: 'Existe implantada política de álcool e drogas?',
        Answer: 'white',
      },
      '4-7 c 182': {
        Question: 'A politica de álcool e drogas é divulgada para a equipe de mergulho?',
        Answer: 'white',
      },
      '4-7 d 183': {
        Question: 'É divulgada para as equipes de mergulho programa de conscientização quanto ao risco do uso de álcool e drogas com foco em problemas psicológicos?',
        Answer: 'white',
      },
      '4-7 e 184': {
        Question: 'São realizados trabalhos de conscientização com os mergulhadores sobre as consequências da omissão de informação médica?',
        Answer: 'white',
      },
      '4-7 f 185': {
        Question: 'A equipe de mergulho é incentivada a relatar ao setor médico da empresa quando há necessidadede tratamento médico com uso de medicamentos?',
        Answer: 'white',
      },
      '4-7 g 187': {
        Question: 'São realizados exames toxicológicos por amostragem nos mergulhadores nas frentes/embarcações de mergulho?',
        Answer: 'white',
      },
      '4-8 a 188': {
        Question: 'Para garantir a qualidade do ar captado são cumpridos os requisitos da NORMAM-15? ',
        Answer: 'white',
      },
      '4-8 b 189': {
        Question: 'Em casos específicos onde haja risco de contaminação do ar captado pelo compressor por fatores externos ao sistema de mergulho, a equipe de mergulho é orientada a utilizar como suprimento de ar um quadro de cilindros de alta pressão composto por no mínimo oito cilindros para mergulhos até 30m e mínimo de doze cilindros para mergulhos de 30m até 50m que atendam aos requisitos da Normam 15? ',
        Answer: 'white',
      },
      '4-8 c 190': {
        Question: 'Em casos específicos onde haja risco de contaminação do ar captado pelo compressor por fatores externos ao sistema de mergulho, a equipe de mergulho é orientada a utilizar como suprimento de ar um quadro de cilindros de alta pressão composto por no mínimo oito cilindros para mergulhos até 30m e mínimo de doze cilindros para mergulhos de 30m até 50m que atendam aos requisitos da Normam 15? ',
        Answer: 'white',
      },
      '4-8 d 191': {
        Question: 'Existe monitoramento do volume de ar comprimido disponibilizado para os mergulhadores e da quantidade de suprimento de emergência nos cilindros contidos nos quadros de ar?',
        Answer: 'white',
      },
      '4-8 e 192': {
        Question: 'São observadas as validades dos cilindros de ar comprimido e dos quadros que compõem o sistema de suprimento de ar para os mergulhadores?',
        Answer: 'white',
      },

      '4-9 a 193': {
        Question: 'São realizados simulados com ênfase no procedimento de resposta a emergências subaquáticas, no controle emocional dos mergulhadores e supervisores envolvidos nas operações de mergulho?',
        Answer: 'white',
      },

      '4-9 b 194': {
        Question: 'Os supervisores e mergulhadores são capacitados e avaliados periodicamente em treinamentos para lidar com intercorrências durante as operações de mergulho através de simulados e demais exercícios práticos garantindo seu controle emocional?',
        Answer: 'white',
      },

      '4-9 c 195': {
        Question: 'Nos simulados de mergulho, os supervisores são orientados a focar na calma e no controle emocional dos mergulhadores para evitar que os mesmos entrem em desespero perante uma intercorrência?',
        Answer: 'white',
      },

      '4-9 d 196': {
        Question: 'De forma geral, os supervisores têm o conhecimento do perfil dos membros de suas respectivas equipes, para em caso de uma intercorrência saber transmitir as orientações necessárias e manter a estabilidade emocional do mergulhador para que o mesmo não entre em desespero?',
        Answer: 'white',
      },


    },
    'CO1 – Lesão ou perturbação funcional, com potencial de sequela e fatalidade': {
      '5-1 a 197': {
        Question:
          'Durante as operações de mergulho raso o supervisor de mergulho monitora o mergulhador no local de trabalho através do sistema de comunicação?',
        Answer: 'white',
      },
      '5-1 b 198': {
        Question:
          'Os equipamentos de comunicação são checados antes e durante as operações de mergulho?',
        Answer: 'white',
      },
      '5-1 c 199': {
        Question:
          'Os equipamentos que fazem parte do sistema de monitoramento do mergulhador estão contemplados no plano de manutenção do sistema de mergulho?',
        Answer: 'white',
      },
      '5-1 d 200': {
        Question:
          'A Black box do sistema de monitoramento do mergulhador está de acordo com as normas vigentes com capacidade suficiente de armazenamento?',
        Answer: 'white',
      },
      '5-2 a 201': {
        Question:
          'Os supervisores de mergulho são capacitados e treinados no plano de resposta a emergência, abortar o mergulho em andamento?',
        Answer: 'white',
      },
      '5-2 b 202': {
        Question:
          'O plano de contingência prevê o passo a passo para abortar o mergulho?',
        Answer: 'white',
      },
      '5-2 c 203': {
        Question:
          'São realizados com a equipe de mergulho simulados de atendimento médico de mergulhador na superfície e transporte até a enfermaria?',
        Answer: 'white',
      },
      '5-3 a 204': {
        Question:
          'Os supervisores são capacitados e treinados para orientar a realização do resgate do mergulhador pelo mergulhador de emergência?',
        Answer: 'white',
      },
      '5-3 b 205': {
        Question:
          'O mergulhador de emergência na superfície, permanece equipado e pronto para intervir no menor tempo possível sendo num simulado ou durante uma intercorrência real?',
        Answer: 'white',
      },
      '5-3 c 206': {
        Question:
          'Existe periodicidade para realização de simulado de resgate do mergulhador pelo mergulhador de emergência?',
        Answer: 'white',
      },
      '5-3 d 207': {
        Question:
          'A empresa controla através do seu departamento de segurança o treinamento frequente envolvendo toda a equipe no procedimento de resgate do mergulhador através do mergulhador de emergência?',
        Answer: 'white',
      },
      '5-3 e 208': {
        Question:
          'O resultado dos treinamentos de resgate e emergência da equipe de mergulho tem sido satisfatório?',
        Answer: 'white',
      },
      '5-4 a 209': {
        Question:
          'Existe plano de resposta a emergências da frente/embarcação de mergulho?',
        Answer: 'white',
      },
      '5-4 b 210': {
        Question:
          'Os supervisores de mergulho são capacitados e treinados para manter o controle mental e orientar os mergulhadores na água durante uma emergência na frente/embarcação de mergulho? ',
        Answer: 'white',
      },
      '5-4 c 211': {
        Question:
          'Os mergulhadores são orientados pelos supervisores para em caso de alguma emergência a bordo da frente/embarcação de mergulho, os mergulhadores retornarem o mais breve a unidade mantendo o controle físico e mental?',
        Answer: 'white',
      },
      '5-4 d 212': {
        Question:
          'A equipe de mergulho participa dos treinamentos de emergência da frente/embarcação de mergulho que embarcam frequentemente?',
        Answer: 'white',
      },
      '5-5 a 213': {
        Question:
          'Existem, ao menos, dois membros na equipe de mergulho capacitados e treinados em suporte básico a vida no atendimento inicial a emergência subaquática atendendo as normas vigentes?',
        Answer: 'white',
      },
      '5-5 b 214': {
        Question:
          'É cobrado eficácia nos simulados dos planos de resposta a emergência com ênfase na comunicação adequada?',
        Answer: 'white',
      },
      '5-5 c 215': {
        Question:
          'O treinamento de reciclagem em atendimento a emergências subaquáticas de primeiros socorros contempla orientações de acordo com as normas vigentes?',
        Answer: 'white',
      },
      '5-5 d 216': {
        Question:
          'São realizados simulados de primeiros socorros com as equipes de mergulho com ênfase na saúde dos mergulhadores?',
        Answer: 'white',
      },
      '5-6 a 217': {
        Question:
          'Há profissional de saúde de nível superior devidamente habilitado na frente/embarcação de mergulho?',
        Answer: 'white',
      },
      '5-6 b 218': {
        Question:
          'O profissional de saúde é capacitado e treinado em suporte avançado a vida e atendimento a emergências subaquática?',
        Answer: 'white',
      },
      '5-7 a 219': {
        Question:
          'A embarcação possui enfermaria que atenda aos requisitos da Normam-01, da NR-30 e demais legislações pertinentes? ',
        Answer: 'white',
      },
      '5-7 b 220': {
        Question:
          'A enfermaria possui quantidade de equipamentos e medicamentos que atende a lista de itens mínimos de enfermaria padronizada pela Petrobras para embarcações de mergulho? ',
        Answer: 'white',
      },
      '5-8 a 221': {
        Question:
          'Os meios de comunicação com o suporte médico em terra são adequados e tem backup?',
        Answer: 'white',
      },
      '5-8 b 222': {
        Question:
          'No procedimento de contingência está previsto o plano de resposta a emergência médica com os recursos de contato telefônico ou por vídeo?',
        Answer: 'white',
      },
      '5-8 c 223': {
        Question:
          'Existe plano de atendimento médico emergencial na empresa 24 horas para atender as equipes embarcadas no offshore?',
        Answer: 'white',
      },
      '5-9 a 224': {
        Question:
          'Existe procedimento específico para em caso de acidente com necessidade de remoção e transporte para hospital ou clínica em terra?',
        Answer: 'white',
      },
      '5-9 b 225': {
        Question:
          'Os supervisores estão aptos a orientar os responsáveis pelo transporte de acidentados via aérea, de que após a realização de mergulho, o voo deve cumprir um limite de altitude durante o voo em cumprimento da NORMAM-15?',
        Answer: 'white',
      },
      '5-9 c 226': {
        Question:
          'O Plano de contingência garante acesso a rede hospitalar especializada e a disponibilidade de recursos para um tratamento específico até o pronto reestabelecimento do acidentado?',
        Answer: 'white',
      },
      '5-9 d 227': {
        Question:
          'Existe procedimento da empresa que propicie a remoção do acidentado embarcado até a rede hospitalar?',
        Answer: 'white',
      },
      '5-9 e 228': {
        Question:
          'O RH da empresa presta suporte ao colaborador em caso de afastamento?',
        Answer: 'white',
      },
      '5-10 a 229': {
        Question:
          'O mergulhador acidentado tem suporte pelo RH no caso de afastamento e de retorno as atividades laborais?',
        Answer: 'white',
      },
      '5-10 b 230': {
        Question:
          'A empresa através do seu RH tem como procedimento a avaliação médica criteriosa da saúde do mergulhador para liberação das atividades laborais do acidentado?',
        Answer: 'white',
      },


    },


  };

  let listKeysQuestionEmergenciasMedicasHiberbaricas=[
    {
      'AM1 -  Afecção/Condição cardiovasculares, respiratórios e auditivas': [
        { '1-1': { Check: [], Answer: 'white' } },
        { '1-2': { Check: [], Answer: 'white' } },
        { '1-3': { Check: [], Answer: 'white' } },
        { '1-4': { Check: [], Answer: 'white' } },
        { '1-5': { Check: [], Answer: 'white' } },
        { '1-6': { Check: [], Answer: 'white' } },
        { '1-7': { Check: [], Answer: 'white' } },
        { '1-8': { Check: [], Answer: 'white' } },
        { '1-9': { Check: [], Answer: 'white' } },
        { '1-10': { Check: [], Answer: 'white' } },
        { '1-11': { Check: [], Answer: 'white' } },
        { '1-12': { Check: [], Answer: 'white' } },
      ].reverse(),
      'AM 2 -  Afecção/Condição osteomusculares': [
        { '2-1': { Check: [], Answer: 'white' } },
        { '2-2': { Check: [], Answer: 'white' } },
        { '2-3': { Check: [], Answer: 'white' } },
        { '2-4': { Check: [], Answer: 'white' } },
        { '2-5': { Check: [], Answer: 'white' } },
        { '2-6': { Check: [], Answer: 'white' } },
        { '2-7': { Check: [], Answer: 'white' } },
        { '2-8': { Check: [], Answer: 'white' } },
        { '2-9': { Check: [], Answer: 'white' } },
        { '2-10': { Check: [], Answer: 'white' } },

      ].reverse(),
      'AM3 - Afecção/Condição gastrointestinais': [
        { '3-1': { Check: [], Answer: 'white' } },
        { '3-2': { Check: [], Answer: 'white' } },
        { '3-3': { Check: [], Answer: 'white' } },
        { '3-4': { Check: [], Answer: 'white' } },
        { '3-5': { Check: [], Answer: 'white' } },
        { '3-6': { Check: [], Answer: 'white' } },
        { '3-7': { Check: [], Answer: 'white' } },
        { '3-8': { Check: [], Answer: 'white' } },
        { '3-9': { Check: [], Answer: 'white' } },

      ].reverse(),
      'AM-4 - Afecção/Condição neuropsicológicos': [
        { '4-1': { Check: [], Answer: 'white' } },
        { '4-2': { Check: [], Answer: 'white' } },
        { '4-3': { Check: [], Answer: 'white' } },
        { '4-4': { Check: [], Answer: 'white' } },
        { '4-5': { Check: [], Answer: 'white' } },
        { '4-6': { Check: [], Answer: 'white' } },
        { '4-7': { Check: [], Answer: 'white' } },
        { '4-8': { Check: [], Answer: 'white' } },
        { '4-9': { Check: [], Answer: 'white' } },


      ].reverse(),
      'CO1 – Lesão ou perturbação funcional, com potencial de sequela e fatalidade': [
        { '5-1': { Check: [], Answer: 'white' } },
        { '5-2': { Check: [], Answer: 'white' } },
        { '5-3': { Check: [], Answer: 'white' } },
        { '5-4': { Check: [], Answer: 'white' } },
        { '5-5': { Check: [], Answer: 'white' } },
        { '5-6': { Check: [], Answer: 'white' } },
        { '5-7': { Check: [], Answer: 'white' } },
        { '5-8': { Check: [], Answer: 'white' } },
        { '5-9': { Check: [], Answer: 'white' } },
        { '5-10': { Check: [], Answer: 'white' } },


      ],



    }]


  export {TitlesAndQuestionsEmergenciasMedicasHiberbaricas,listKeysQuestionEmergenciasMedicasHiberbaricas,titleFormEmergenciasMedicasHiberbaricas,titleHazardEmergenciasMedicasHiberbaricas,SecondIdEmergenciasMedicasHiberbaricas,listAMCOEmergenciasMedicasHiberbaricas}

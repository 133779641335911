import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { toast } from 'react-toastify';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { feedBack } from '../../store/modules/auth/actions';

import {
  Container,
  DivFeedback,
  DivTitle,
  DivContent,
  DivDescription,
  DivAssunto,
  DivUnregistredUser,
} from './styles';
import api from '../../services/api';
import history from '../../services/history';

export default function UserFeedback() {
  document.title = 'Fale conosco';

  const dispatch = useDispatch();

  const { loginForFeedback } = useSelector(state => state.auth);
  const [fbTitle, setFbTitle] = useState(' ');
  const [fbDescription, setFbDescription] = useState(' ');

  const [unregistredUser, setUnregistredUser] = useState(' ');

  async function handleSubmit() {
    try {
      const feedbackModel = {
        login: loginForFeedback,
        unregistredUser,
        title: fbTitle,
        description: fbDescription,
      };

      dispatch(feedBack());
      api.post('/feedback', feedbackModel);
      toast.success('Registrado com sucesso');
      history.push('/');
    } catch (error) {
      toast.success('Registrado com sucesso');
      history.push('/');
    }
  }

  return (
    <Container>
      <DivFeedback>
        <DivTitle>
          <h2>FALE CONOSCO</h2>
          <h3>Gostaria de nos contar como foi sua experiência?</h3>
        </DivTitle>

        <DivContent>
          {!loginForFeedback ? (
            <DivUnregistredUser>
              <TextField
                id="outlined-basic"
                label="Seu nome"
                multiline
                rowsMax="2"
                value={unregistredUser}
                onChange={e => setUnregistredUser(e.target.value)}
                variant="outlined"
              />
            </DivUnregistredUser>
          ) : null}

          <DivAssunto>
            <TextField
              id="outlined-basic"
              label="Assunto"
              multiline
              rowsMax="2"
              value={fbTitle}
              onChange={e => setFbTitle(e.target.value)}
              variant="outlined"
            />
          </DivAssunto>

          <DivDescription>
            <TextField
              id="outlined-basic"
              label="Descrição"
              multiline
              rowsMax="6"
              value={fbDescription}
              onChange={e => setFbDescription(e.target.value)}
              variant="outlined"
            />
          </DivDescription>

          <Button
            variant="contained"
            color="primary"
            onClick={() => handleSubmit()}
          >
            Enviar
          </Button>
        </DivContent>
      </DivFeedback>
    </Container>
  );
}

/* eslint-disable react/forbid-prop-types */
import React from 'react';

import PropTypes from 'prop-types';
import { Pie, Doughnut, HorizontalBar, Line } from 'react-chartjs-2';

export function Chart1(props) {
  const { chart } = props;

  if (chart) {
    const option = {
      maintainAspectRatio: false,
      tooltips: {
        callbacks: {
          label(tooltipItem, data) {
            const dataset = data.datasets[tooltipItem.datasetIndex];
            const meta = dataset._meta[Object.keys(dataset._meta)[0]];
            const { total } = meta;
            const currentValue = dataset.data[tooltipItem.index];
            const percentage = parseFloat(
              ((currentValue / total) * 100).toFixed(1)
            );
            return `${currentValue} (${percentage}%)`;
          },
          title(tooltipItem, data) {
            return data.labels[tooltipItem[0].index];
          },
        },
      },
    };
    return (
      <Pie key="ct1" data={chart} width={250} height={170} options={option} />
    );
  }
}

export function Chart2(props) {
  const { chart } = props;

  if (chart) {
    return (
      <Pie
        data={chart}
        width={250}
        height={170}
        options={{ maintainAspectRatio: false }}
      />
    );
  }
}

export function Chart3(props) {
  const { chart } = props;

  if (chart) {
    return (
      <HorizontalBar
        data={chart}
        width={400}
        height={180}
        options={{ maintainAspectRatio: false }}
      />
    );
  }
}

export function Chart4(props) {
  const { chart } = props;

  if (chart) {
    return (
      <Line
        data={chart}
        width={400}
        height={180}
        options={{ maintainAspectRatio: false }}
      />
    );
  }
}

export function Chart5(props) {
  const { chart } = props;

  if (chart) {
    return (
      <Doughnut
        key="ct5"
        data={chart}
        width={250}
        height={170}
        options={{ maintainAspectRatio: false }}
      />
    );
  }
}

export function Chart6(props) {
  const { chart } = props;

  if (chart) {
    return (
      <Doughnut
        key="ct2"
        data={chart}
        width={250}
        height={170}
        options={{ maintainAspectRatio: false }}
      />
    );
  }
}

Chart1.propTypes = {
  props: PropTypes.any,
};

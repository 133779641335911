const titleFormAcidentesHiperbaricos="Acidentes Hiperbáricos";
const titleHazardAcidentesHiperbaricos="Ambiente Hiperbárico";
const SecondIdAcidentesHiperbaricos="AcidentesHiperbaricos";
const listAMCOAcidentesHiperbaricos=['AM 1- Variações de pressão não programadas (subida ou descida não programada)','CO 1- Lesão ou alteração funcional'];
let TitlesAndQuestionsAcidentesHiperbaricos = {
    'AM 1- Variações de pressão não programadas (subida ou descida não programada)': {
      '1-1 a 1': {
        Question:
          'O planejamento das atividades e a instrução de trabalho é elaborado contemplando as possíveis variações de pressão não programadas durante o mergulho?',
        Answer: 'white',
      },

      '1-1 b 2': {
        Question:
          'Existe treinamento para os supervisores e mergulhadores orientando sobre a importância da subida e descida controlada?',
        Answer: 'white',
      },
      '1-2 a 3': {
        Question: 'No check list diário é inspecionado o LARS com todos os seus acessórios?',
        Answer: 'white',
      },
      '1-2 b 4': {
        Question:
          'O plano de manutenção do LARS está sendo cumprido em relação a sua periodicidade seguindo as recomendações do fornecedor?',
        Answer: 'white',
      },
      '1-3 a 5': {
        Question:
          ' A instrução técnica de manutenção do LARS define e identifica quem possui a atribuição de manutenir cada parte do LARS e qual a capacitação necessária para a tarefa?',
        Answer: 'white',
      },
      '1-3 b 6': {
        Question:
          'As equipes de manutenção estão capacitadas e treinadas conforme a IT para a realização da manutenção dos equipamentos de lançamento/recolhimento do mergulhador, voltadas para a parte mecânica?',
        Answer: 'white',
      },
      '1-3 c 7': {
        Question:
          'Existe controle de treinamento periódico obrigatório para a equipe de manutenção?',
        Answer: 'white',
      },
      '1-4 a 8': {
        Question:
          'Os supervisores e técnicos de saturação são capacitados e treinados para garantir os parâmetros exigidos no controle dos níveis de vida? (Aplicável apenas para mergulho saturado)',
        Answer: 'white',
      },
      '1-4 b 9': {
        Question:
          'Existe plano de treinamento e reciclagem periódico para supervisores e técnicos de saturação a fim de garantir os controles dos parâmetros exigidos em normas e procedimentos de segurança da saturação? (Aplicável apenas para mergulho saturado)',
        Answer: 'white',
      },
      '1-5 a 10': {
        Question:
          'Os dispositivos de controle de profundidade dos mergulhadores no painel de controle são testados e monitorados no início das operações de mergulho?',
        Answer: 'white',
      },
      '1-5 b 11': {
        Question:
          'É realizada a verificação da operacionalidade dos equipamentos e acessórios de controle da profundidade (Pneufatometro, profundimetro, telemetria e dispositivo de monitoramento da velocidade de subida/descida do guincho)?',
        Answer: 'white',
      },
      '1-6 a 12': {
        Question:
          'Os supervisores e mergulhadores são capacitados e avaliados periodicamente em treinamentos para lidar com intercorrências durante as operações de mergulho através de simulados e demais exercícios práticos? ',
        Answer: 'white',
      },

      '1-6 b 13': {
        Question:
          'Nos simulados de mergulho, os supervisores são orientados a focar na calma e no controle emocional dos mergulhadores? ',
        Answer: 'white',
      },
      '1-7 a 14': {
        Question:
          'Os operadores de câmara estão habilitados para operar a câmara hiperbárica e manter um rigoroso controle das pressões nos compartimentos da câmara durante a pressurização e despressurização?',
        Answer: 'white',
      },
      '1-7 b 15': {
        Question:
          'Os supervisores de mergulho são orientados a monitorar os operadores de câmara durante a descompressão ou tratamento hiperbárico para um rigoroso controle das profundidades e períodos de cada tabela? ',
        Answer: 'white',
      }
    },
    'AM2-Falha na aplicação da tabela de descompressão': {
      '2-1 a 16': {
        Question:
          'O planejamento da tabela de descompressão é realizado de forma rigorosa seguindo o procedimento para a revisão afim de evitar falhas durante a descompressão? ',
        Answer: 'white',
      },
      '2-1 b 17': {
        Question:
          'Os supervisores e operadores de câmara são treinados e habilitados para exercer suas devidas funções, passando por treinamentos, atualizações e reciclagens? ',
        Answer: 'white',
      },
      '2-2 a 18': {
        Question:
          'Os supervisores são orientados para aplicar os diversos tipos de contingências (temperatura da água, correnteza, altitude e etc.) existentes nos manuais da Marinha e do PE-1PBR-00221 Anexo D- Uniformização da Aplicação de Contingências nas Tabelas de Mergulho? ',
        Answer: 'white',
      },
      '2-2 b 19': {
        Question:
          'Na reciclagem dos supervisores contempla os diversos tipos de contingências aplicáveis durante as operações de mergulho raso? ',
        Answer: 'white',
      },
      '2-3 a 20': {
        Question:
          'Os supervisores são capacitados e treinados para a utilização de tabelas padrão de descompressão?',
        Answer: 'white',
      },
      '2-3 b 21': {
        Question:
          'Os supervisores estão cientes de sempre utilizarem a profundidade máxima do mergulho como parâmetro para utilização da tabela de descompressão',
        Answer: 'white',
      },
      '2-4 a 22': {
        Question:
          'Os supervisores de mergulho passam por reciclagem do uso da tabela de descompressão com período determinado? ',
        Answer: 'white',
      },
      '2-4 b 23': {
        Question:
          'Durante o processo de planejamento das operações é contemplada e discutida a utilização da tabela de descompressão com ênfase no intervalo de superfície',
        Answer: 'white',
      },
      '2-5 a 24': {
        Question:
          'Nas equipes a bordo, existem pelo menos dois supervisores de mergulho qualificados para operação de câmara hiperbárica? ',
        Answer: 'white',
      },
      '2-5 b 25': {
        Question:
          'É estabelecido um número de horas de operação na câmara hiperbárica para credenciamento do operador? ',
        Answer: 'white',
      },


    },
    'AM 3-Falha na operação da câmara hiperbárica': {
      '3-1 a 26': {
        Question:
          'O planejamento da operação de mergulho é realizado de forma rigorosa seguindo os procedimentos visando evitar possíveis falhas durante o período da descompressão? ',
        Answer: 'white',
      },
      '3-1 b 27': {
        Question:
          'Os supervisores e operadores de câmara são treinados e habilitados para exercer suas devidas funções, passando por treinamentos, atualizações e reciclagens? ',
        Answer: 'white',
      },
      '3-2 a 28': {
        Question:
          '  Os manômetros e válvulas de segurança da câmara hiperbárica são aferidos nos prazos determinados dos procedimentos de manutenção? ',
        Answer: 'white',
      },
      '3-2 b 29': {
        Question:
          'Os certificados dos manômetros e válvulas de segurança da câmara hiperbárica estão dentro da validade e as identificações nos equipamentos com a data de aferição e validade legíveis? ',
        Answer: 'white',
      },
      '3-2 c 30': {
        Question:
          'As válvulas de segurança da câmara hiperbárica sofrem manutenção e aferição por pessoal habilitado e certificado? ',
        Answer: 'white',
      },
      '3-2 d 31': {
        Question:
          'Os manômetros de pressão da câmara hiperbárica são substituídos ao atingir o seu tempo de vida útil? ',
      },
      '3-3 a 32': {
        Question:
          'O plano de manutenção da câmara hiperbárica está de acordo com as recomendações do fornecedor? ',
        Answer: 'white',
      },
      '3-3 b 33': {
        Question: ' A manutenção programada contempla a inspeção e manutenção do sistema de comunicação da câmara hiperbárica, iluminação e limpeza nas sedes das vigias',
        Answer: 'white',
      },
      '3-4 a 34': {
        Question:
          'Existe um plano periódico de reciclagem na capacitação e treinamento dos técnicos de manutenção dos equipamentos da câmara hiperbárica? ',
        Answer: 'white',
      },
      '3-4 b 35': {
        Question:
          'Há equipes de técnicos que atuam na manutenção das câmaras de descompressão que são qualificados em suas respectivas áreas de atuação (mecânica e elétrica',
        Answer: 'white',
      },
      '3-5 a 36': {
        Question:
          'Há pelo menos dois membros da equipe qualificados para operar a câmara hiperbárica? ',
        Answer: 'white',
      },
      '3-5 b 37': {
        Question:
          'Os operadores da câmara passam por avaliação e treinamento para operar a câmara hiperbárica? ',
        Answer: 'white',
      },
      '3-5 c 38': {
        Question:
          'Existe plano de gestão para avaliação da capacitação técnica da equipe responsável pela descompressão dos mergulhadores? ',
        Answer: 'white',
      },
      '3-6 a 39': {
        Question:
          'O pessoal qualificado para operar câmara hiperbárica, realiza periodicamente simulados de operação de câmara hiperbárica? ',
        Answer: 'white',
      },
      '3-6 b 40': {
        Question:
          'Durante os simulados de operação da câmara hiperbárica, os operadores recebem orientação dos supervisores de mergulho? ',
        Answer: 'white',
      },
      '3-6 c 41': {
        Question:
          'Existe matriz de simulado de emergência realizado por equipes que operam a câmara hiperbárica?  ',
        Answer: 'white',
      },
      '3-6 d 42': {
        Question:
          'Existe na empresa rastreabilidade referente aos documentos gerados nos treinamentos de emergência na câmara hiperbárica? ',
        Answer: 'white',
      },
      '3-7 a 43': {
        Question:
          'É contemplado em projeto a redundância dos manômetros das camaras hiperbáricas?',
        Answer: 'white',
      },
      '3-7 b 44': {
        Question:
          'Os operadores de câmara hiperbárica são treinados para identificar falhas dos manômetros e utilizar a redundância disponível? ',
        Answer: 'white',
      },
      '3-7 c 45': {
        Question:
          'No plano de manutenção contempla a periodicidade de substituição dos manômetros das câmaras hiperbárica?',
        Answer: 'white',
      },
    },
    'AM 4-Efeitos da pressão no organismo do mergulhador': {
      '4-1 a 46': {
        Question:
          'No planejamento do mergulho, é levado em conta o monitoramento dos efeitos da pressão no organismo do mergulhador? ',
        Answer: 'white',
      },
      '4-1 b 47': {
        Question:
          'O supervisor de mergulho orienta os mergulhadores a evitar as variações de profundidade durante o mergulho para evitar os efeitos da pressão hiperbáricas no organismo? ',
        Answer: 'white',
      },
      '4-1 c 48': {
        Question:
          'O supervisor de mergulho é treinado em gestão de mudança para o caso de uma alteração repentina na profundidade original de projeto? ',
        Answer: 'white',
      },
      '4-1 d 49': {
        Question:
          'No planejamento de uma operação é contemplada a presença de um supervisor de mergulho para ajustar o projeto ao ambiente subaquático? ',
        Answer: 'white',
      },
      '4-2 a 50': {
        Question: 'Os supervisores de mergulho estão capacitados, treinados e atualizados para a utilização das tabelas de mergulho repetitivo? ',
        Answer: 'white',
      },
      '4-2 b 51': {
        Question:
          'São comtempladas durante o planejamento de uma tarefa juntamente com o supervisor de mergulho a necessidade da utilização da tabela de mergulho repetitivo? ',
        Answer: 'white',
      },
      '4-3 a 52': {
        Question:
          'Os supervisores orientam os mergulhadores para manter a velocidade de subida e descida dos mergulhos dentro dos limites operacionais',
        Answer: 'white',
      },
      '4-3 b 53': {
        Question:
          'Existe treinamento para os supervisores e mergulhadores orientando sobre a importância da subida e descida controlada? ',
        Answer: 'white',
      },
      '4-3 c 54': {
        Question:
          'O plano de inspeção é auditado pela gerência operacional a fim de garantir que todos os equipamentos que compõe o sistema de subida e descida do mergulhador estejam em conformidade?  ',
        Answer: 'white',
      },
      '4-4 a 55': {
        Question:
          'A equipe de mergulhadores tem experiência em executar atividades de mergulho próximo a ZVM? ',
        Answer: 'white',
      },
      '4-4 b 56': {
        Question:
          'O plano de inspeção é auditado pela gerencia operacional a fim de garantir que todos os equipamentos que compõe o sistema de subida e descida do mergulhador estejam em conformidade?  ',
        Answer: 'white',
      },
      '4-4 c 57': {
        Question:
          'Os supervisores e mergulhadores verificam os sistemas de monitoramento de profundidade antes e durante as operações de mergulho',
        Answer: 'white',
      },
      '4-5 a 58': {
        Question:
          'O Supervisor de saturação está apto na manipulação do processo da fabricação de mistura respiratória?( aplicável apenas para mergulho saturado)',
        Answer: 'white',
      },
      '4-5 b 59': {
        Question:
          'No procedimento de fabricação de mistura respiratória o supervisor de saturação é orientado a verificar no planejamento das tarefas as profundidades a serem alcançadas pelo mergulhador? (aplicável apenas para mergulho saturado)',
        Answer: 'white',
      },
      '4-5 c 60': {
        Question:
          'Os analisadores são aferidos periodicamente e tem a sua rastreabilidade conferida através de plano de manutenção?( aplicável apenas para mergulho saturado)',
        Answer: 'white',
      },
      '4-6 a 61': {
        Question:
          'As avaliações de saúde pré-mergulho levam em consideração o estado das vias aéreas dos mergulhadores?',
        Answer: 'white',
      },
      '4-6 b 62': {
        Question:
          'Os agentes de saúde que realizam o exame pré-mergulho têm competência e capacidade de identificar problemas nas vias aéreas?',
        Answer: 'white',
      },
      '4-6 c 63': {
        Question:
          'Os mergulhadores são orientados dos riscos da automedicação e os problemas que podem causar durante as operações de mergulho? ',
        Answer: 'white',
      },
      '4-6 d 64': {
        Question:
          'Existe acompanhamento médico para verificação das condições físicas dos mergulhadores antes do embarque? ',
        Answer: 'white',
      },
      '4-6 e 65': {
        Question:
          'São realizados trabalhos de conscientização com os mergulhadores sobre as consequências da inveracidade e/ou omissão da informação médica',
        Answer: 'white',
      },
      '4-6 f 66': {
        Question:
          'A equipe médica responsável pelos mergulhadores é qualificada em medicina hiperbárica? ',
        Answer: 'white',
      },
      '4-6 g 67': {
        Question:
          'São realizados trabalhos de conscientização com os mergulhadores sobre os riscos da automedicação? ',
        Answer: 'white',
      },
      '4-7 a 68': {
        Question:
          'O médico hiperbárico que realiza o exame na equipe de mergulho é habilitado em medicina hiperbárica e cumpre os requisitos normativos nos exames? ',
        Answer: 'white',
      },
      '4-7 b 69': {
        Question:
          'No exame de saúde dos mergulhadores os requisitos contratuais, legais e a saúde integral dos mergulhadores são cumpridos criteriosamente? ',
        Answer: 'white',
      },
      '4-7 c 70': {
        Question:
          'Existe um setor na empresa que seja responsável pelo controle dos exames médico periódicos dos mergulhadores? ',
        Answer: 'white',
      },
      '4-7 d 71': {
        Question:
          'Os exames médicos periódicos realizados pelos mergulhadores atendem as exigências contratuais e legais necessárias? ',
        Answer: 'white',
      },


    },
    'AM 5-Suscetibilidade individual do mergulhador': {
      '5-1 a 72': {
        Question:
          'No planejamento das atividades, são levados em conta as condições físicas dos mergulhadores para a realização das tarefas planejadas? ',
        Answer: 'white',
      },
      '5-1 b 73': {
        Question:
          'Os mergulhadores são orientados para avaliar a atividade de mergulho planejada para ele e decidir se está em condições físicas e emocional para executar tal tarefa? ',
        Answer: 'white',
      },
      '5-1 c 74': {
        Question:
          'No planejamento de uma operação é contemplada a presença de um representante do mergulho a fim de dimensionar o perfil físico do mergulhador a ser empenhado no projeto? ',
        Answer: 'white',
      },
      '5-1 d 75': {
        Question:
          'Faz parte do check list pré-mergulho perguntar ao mergulhador se o mesmo se encontra em boas condições físicas e psicológicas? ',
        Answer: 'white',
      },
      '5-2 a 76': {
        Question:
          'São realizados trabalhos de conscientização com os mergulhadores sobre as consequências da omissão de informação médica?',
        Answer: 'white',
      },
      '5-2 b 77': {
        Question:
          'A equipe médica responsável pelos mergulhadores é qualificada em medicina hiperbárica?',
        Answer: 'white',
      },
      '5-2 c 78': {
        Question:
          'Os mergulhadores são orientados para em caso de problemas gastrointestinais possibilidades;(vomito, desarranjo intestinal e etc.), informar a supervisão para interromper o mergulho?',
        Answer: 'white',
      },
      '5-3 a 79': {
        Question:
          'O controle do IMC, cintura e percentual de gordura corporal é realizado na equipe de forma rotineira por agente de saúde qualificado?',
        Answer: 'white',
      },
      '5-3 b 80': {
        Question:
          'No exame semestral dos mergulhadores é realizado o exame de resistência cardiovascular (MET e VO2 MAX) e adequados controles de fatores de riscos cardiológicos (triglicerídeos, colesterol e glicemia) e atendem as exigências contratuais e legais necessárias? ',
        Answer: 'white',
      },
      '5-3 c 81': {
        Question:
          'A conscientização de estilo de vida saudável com foco em riscos cardíacos é divulgada para as equipes de mergulho? ',
        Answer: 'white',
      },
      '5-3 d 82': {
        Question:
          'Existe programa focado na vida saudável a bordo? ',
        Answer: 'white',
      },
      '5-3 e 83': {
        Question:
          'É feito divulgação de programas de conscientização de alimentação saudável por nutricionistas para o pessoal de mergulho? ',
        Answer: 'white',
      },
      '5-3 f 84': {
        Question:
          'As empresas de alimentação a bordo são orientadas para disponibilizar alimentos saudáveis as equipes de mergulho? ',
        Answer: 'white',
      },
      '5-3 g 85': {
        Question:
          'Existe na empresa programa de acompanhamento e orientação a hábitos saudáveis para a equipe de mergulhadores? ',
        Answer: 'white',
      },
      '5-3 h 86': {
        Question:
          'Existe acompanhamento médico com verificação das condições físicas dos mergulhadores antes do embarque? ',
        Answer: 'white',
      },
      '5-4 a 87': {
        Question:
          'Os exames medico periódico realizado pelos mergulhadores atendem as exigências contratuais e legais necessárias?  ',
        Answer: 'white',
      },
      '5-4 b 88': {
        Question:
          'Na avaliação médica dos mergulhadores é cumprido os requisitos contratuais e legais, considerando a saúde integral com base na aptidão?',
        Answer: 'white',
      },
      '5-5 a 89': {
        Question:
          'Existe programa de conscientização da responsabilidade do mergulhador se manter em boa forma física? ',
        Answer: 'white',
      },
      '5-5 b 90': {
        Question:
          'Existem processo de acompanhamento da condição física do mergulhador? ',
        Answer: 'white',
      },
      '5-5 c 91': {
        Question:
          'Os mergulhadores são orientados para a manutenção de hábitos saudáveis com consequente conscientização e responsabilização de seus atos?',
        Answer: 'white',
      },
      '5-5 d 92': {
        Question:
          'Existe programas corporativos que comprovam a boa saúde e bem-estar dos mergulhadores?',
        Answer: 'white',
      },
      '5-6 a 93': {
        Question:
          'Faz parte do sistema de monitoramento do mergulhador sensores que monitorem a eliminação dos gases inertes do corpo do mergulhador? ',
        Answer: 'white',
      },
      '5-6 b 94': {
        Question:
          'Os supervisores são treinados e capacitados para o manuseio dos sensores de monitoramento de gases e fatores ambientais?',
        Answer: 'white',
      },
      '5-6 c 95': {
        Question:
          'Existe no plano de manutenção orientação de manutenção e calibração dos sensores de monitoramento do sistema de mergulho? ',
        Answer: 'white',
      },
    },
    'AM 6-Não cumprimento das restrições pós mergulho': {
      '6-1 a 96': {
        Question:
          'Durante o planejamento das atividades, é lavado em conta as restrições pós mergulho?',
        Answer: 'white',
      },
      '6-1 b 97': {
        Question:
          'Os mergulhadores são orientados para evitar levantar pesos excessivos durante o trabalho, tomar banho com água quente e frequentar academia após ter realizado mergulho?',
        Answer: 'white',
      },
      '6-1 c 98': {
        Question:
          'No planejamento das operações de mergulho leva-se em consideração a não utilização do mergulhador que se encontra em período de observação pós mergulho? ',
        Answer: 'white',
      },
      '6-1 d 99': {
        Question:
          'Existe uma câmara hiperbárica sempre livre e disponível para atender a uma recorrência pós mergulho? ',
        Answer: 'white',
      },
      '6-2 a 100': {
        Question:
          'Os supervisores de mergulho raso, recebem treinamento sobre as contingencias determinadas na tabela de descompressão quanto ao esforço físico, água fria e demais fatores de risco?',
        Answer: 'white',
      },
      '6-2 b 101': {
        Question:
          'As equipes de mergulho são capacitadas e treinadas em percepção de risco, quando as condições de mergulho apresentam contingências tais como (grande esforço físico, água fria, correnteza no limite e etc.)?',
        Answer: 'white',
      },
      '6-2 c 102': {
        Question:
          'Na APR da atividade de mergulho é contemplada a temperatura da água, esforço físico altitude e banho quente? ',
        Answer: 'white',
      },
      '6-3 a 103': {
        Question:
          'Os supervisores de mergulho são treinados e capacitados para em caso de necessidade de transporte aérea após a realização de mergulho, orientar os pilotos quanto a necessidade de cumprir a o que preconiza a NORMAM-15?',
        Answer: 'white',
      },
      '6-3 b 104': {
        Question:
          'Os supervisores de mergulho raso são treinados e capacitados para avaliação de risco no planejamento das atividades com relação ao cumprimento das restrições pós mergulho?',
        Answer: 'white',
      },
      '6-3 c 105': {
        Question:
          'Na matriz de treinamento dos supervisores e mergulhadores é contemplada o cuidado a ser tomado com relação a voar após o mergulho? ',
        Answer: 'white',
      },
      '6-3 d 106': {
        Question:
          'É feita a divulgação para os mergulhadores dos riscos que se corre voando após a realização de um mergulho? ',
        Answer: 'white',
      },

    },
    'AM 7-Subida involuntária do mergulhador': {
      '7-1 a 107': {
        Question:
          'No planejamento das atividades de mergulho, é analisado a possibilidade de subida involuntária do mergulhador?',
        Answer: 'white',
      },
      '7-1 b 108': {
        Question:
          'As equipes de mergulho são informadas da possibilidade de subida involuntária durante as operações de mergulho?',
        Answer: 'white',
      },
      '7-1 c 109': {
        Question:
          'No planejamento da tarefa quando é necessário a utilização de para quedas ou outro meio que possa acarretar uma subida indevida do mergulhador, este risco é mapeado em uma APR? ',
        Answer: 'white',
      },
      '7-1 d 110': {
        Question:
          'Existe reunião pré-operação com a presença dos supervisores e mergulhadores evidenciando os cuidados a serem tomados com subida involuntária? ',
        Answer: 'white',
      },
      '7-2 a 111': {
        Question:
          'Os supervisores de mergulho são capacitados e treinados nos requisitos de segurança em trabalhos com a necessidade de utilização de bolsas de içamento subaquáticas (ex. paraquedas)?',
        Answer: 'white',
      },
      '7-2 b 112': {
        Question:
          'As equipes de mergulho são orientadas e recebem treinamentos dos requisitos de segurança pelos supervisores de mergulho quando da necessidade de se utilizar bolsas de içamento subaquáticas (ex. paraquedas)?',
        Answer: 'white',
      },
      '7-2 c 113': {
        Question:
          'Existe treinamento especifico com os mergulhadores para a utilização de paraquedas? ',
        Answer: 'white',
      },
      '7-3 a 114': {
        Question:
          'Os supervisores são competência e treinamento para orientar os mergulhadores na utilização do colete equilibrador?',
        Answer: 'white',
      },
      '7-3 b 115': {
        Question:
          'Os supervisores de mergulho raso, orientam e realizam simulados para a utilização do colete equilibrador?',
        Answer: 'white',
      },

    },
    'AM 8-Variação significativa de temperatura não programadas (aplicável somente para mergulho saturado)': {
      '8-1 a 116': {
        Question:
          'No procedimento de mergulho saturado contempla o funcionamento do sistema de água quente antes da operação a fim que haja estabilização da temperatura?( aplicável apenas para mergulho saturado)',
        Answer: 'white',
      },
      '8-1 b 117': {
        Question:
          'No procedimento de mergulho saturado contempla a verificação da temperatura da água quente pelo bellman a fim de evitar choque térmico? (aplicável apenas para mergulho saturado)',
        Answer: 'white',
      },
      '8-2 a 118': {
        Question:
          'No controle de mergulho consta um controle de temperatura com visor e alarme de variação de temperatura? (aplicável apenas para mergulho saturado)',
        Answer: 'white',
      },
      '8-2 b 119': {
        Question:
          'Os técnicos de equipamento são treinados e habilitados na manutenção e operação correta do sistema de fornecimento de água quente para os mergulhadores? (aplicável apenas para mergulho saturado)',
        Answer: 'white',
      },
      '8-2 c 120': {
        Question:
          'No controle de mergulho consta um controle de temperatura com visor e alarme de variação de temperatura?( aplicável apenas para mergulho saturado)',
        Answer: 'white',
      },
      '8-3 a 121': {
        Question:
          'Consta no procedimento de mergulho a presença constante do técnico de manutenção no equipamento de fornecimento de água quente para os mergulhadores? (aplicável apenas para mergulho saturado)',
        Answer: 'white',
      },
      '8-3 b 122': {
        Question:
          'Consta no procedimento de operação do sistema de fornecimento de água quente para os mergulhadores a manutenção pós operação a fim de identificar qualquer anormalidade? (aplicável apenas para mergulho saturado)',
        Answer: 'white',
      },
      '8-3 c 123': {
          Question:
            'Consta no procedimento de operação do sistema de fornecimento de água quente para os mergulhadores a manutenção pós operação a fim de identificar qualquer anormalidade?  ( aplicável apenas para mergulho saturado)',
          Answer: 'white',
        },

      },

      'AM 9-Intoxicação por oxigênio': {
        '9-1 a 124': {
          Question:
            'Os supervisores e técnicos de saturação são qualificados na utilização da tabela de descompressão? (aplicável apenas para mergulho saturado)',
          Answer: 'white',
        },
        '9-1 b 125': {
          Question:
            'Os supervisores e técnicos de saturação são treinados e habilitados no manuseio e entendimento dos analisadores utilizados no controle de saturação?( aplicável apenas para mergulho saturado)',
          Answer: 'white',
        },
        '9-2 a 126': {
          Question:
            'Existe rastreabilidade da descompressão a fim de identificar possível irregularidade?',
          Answer: 'white',
        },
        '9-2 b 127': {
          Question:
            'A tabela utilizada para a descompressão é de pleno domínio dos operadores de câmara?',
          Answer: 'white',
        },
        '9-2 c 128': {
          Question:
            'Os supervisores de mergulho são capacitados e treinados para o cumprimento fiel das tabelas de mergulho de acordo com o “Anexo D - Uniformização da Aplicação de Contingências nas Tabelas de Mergulho” do padrão PE-1PBR-00221?',
          Answer: 'white',
        },
        '9-2 d 129': {
          Question:
            'Os supervisores e operadores de câmara tem ciência de que não podem estender os ciclos de aplicação de oxigênio sem autorização previa do médico hiperbárico durante uma descompressão ou um tratamento hiperbárico com o risco de intoxicação por oxigênio?',
          Answer: 'white',
        },
        '9-3 a 130': {
          Question:
            'O mergulhador recém contratado é submetido ao teste de tolerância de O2?',
          Answer: 'white',
        },
        '9-3 b 131': {
          Question:
            'Existe periodicidade para o mergulhador ser submetido ao teste de tolerância de O2',
          Answer: 'white',
        },

      },

      'CO 1- Lesão ou alteração funcional': {
        '10-1 a 132': {
          Question:
            'Durante as operações de mergulho raso o supervisor de mergulho monitora o mergulhador no local de trabalho através do sistema de comunicação com a ajuda do Bellman?',
          Answer: 'white',
        },
        '10-1 b 133': {
          Question:
            'Os equipamentos de comunicação são checados antes e durante as operações de mergulho?',
          Answer: 'white',
        },
        '10-1 c 134': {
          Question:
            'Os equipamentos que fazem parte do sistema de monitoramento do mergulhador estão contemplados no plano de manutenção do sistema de mergulho?',
          Answer: 'white',
        },
        '10-1 d 135': {
          Question:
            'A Black box do sistema de monitoramento do mergulhador está de acordo com as normas vigentes com capacidade suficiente de armazenamento?',
          Answer: 'white',
        },
        '10-2 a 136': {
          Question:
            'Os supervisores de mergulho são capacitados e treinados para manter o controle mental e orientar os mergulhadores na água durante uma emergência na unidade? Ex. (Fogo, abalroamento, adernamento e etc.)',
          Answer: 'white',
        },
        '10-2 b 137': {
          Question:
            'Os mergulhadores são orientados pelos supervisores para em caso de alguma emergência a bordo da unidade, os mergulhadores retornarem o mais breve a unidade mantendo o controle físico e mental?',
          Answer: 'white',
        },
        '10-2 c 138': {
          Question:
            'A equipe de mergulho participa dos treinamentos de emergência das unidades que embarcam frequentemente?',
          Answer: 'white',
        },
        '10-2 d 139': {
          Question:
            'São realizados simulados com a equipe de mergulho em cenário de contingência?',
          Answer: 'white',
        },

        '10-3 a 140': {
            Question:
              'Os supervisores são capacitados e treinados para orientar a realização do resgate do mergulhador pelo mergulhador de emergência?',
            Answer: 'white',
          },
          '10-3 b 141': {
            Question:
              'O mergulhador de emergência na superfície, permanece equipado e pronto para intervir no menor tempo possível sendo num simulado ou durante uma intercorrência real?',
            Answer: 'white',
          },
          '10-3 c 142': {
            Question:
              'Existe periodicidade para realização de simulado de resgate do mergulhador pelo mergulhador de emergência?',
            Answer: 'white',
          },
          '10-3 d 143': {
            Question:
              'A empresa controla através do seu departamento de segurança o treinamento frequente envolvendo toda a equipe no procedimento de resgate do mergulhador através do mergulhador de emergência?',
            Answer: 'white',
          },
          '10-4 a 144': {
            Question:
              'Os supervisores de mergulho são capacitados e treinados no plano de resposta a emergência, abortar o mergulho em andamento, retornar a cesta, sinete ou a superfície com o auxílio de Bellman ou mergulhador de emergência?',
            Answer: 'white',
          },
          '10-4 b 145': {
            Question:
              'O plano de contingência prevê o passo a passo para abortar o mergulho e retornar a cesta, sinete ou ao a superfície com a ajuda do Bellman ou o mergulhador de emergência?',
            Answer: 'white',
          },
          '10-4 c 146': {
            Question:
              'A equipe de mergulho é conscientizada nos riscos inerentes a atividade que executam quando embarcados nas unidades marítimas?',
            Answer: 'white',
          },
          '10-5 a 147': {
            Question:
              'Os supervisores são capacitados e treinados para respeitar rigorosamente as lineares de subida e paradas descompressivas durante uma descompressão ou tratamento?',
            Answer: 'white',
          },
          '10-5 b 148': {
            Question:
              'Os operadores de câmara recebem orientação dos supervisores para respeitar rigorosamente as lineares de subida e as paradas durante uma descompressão ou tratamento?',
            Answer: 'white',
          },
          '10-5 c 149': {
            Question:
              'A equipe de mergulho tem pleno conhecimento e treinamento no rigor das lineares de subidas e paradas caso haja necessidade de se fazer uma descompressão na água?',
            Answer: 'white',
          },
          '10-6 a 150': {
            Question:
              'No local de trabalho existe uma câmara hiperbárica dedicada e operacional para utilização imediata para casos de emergência com acesso totalmente desobstruído?',
            Answer: 'white',
          },
          '10-6 b 151': {
            Question:
              'O plano de inspeção e manutenção da câmara hiperbárica é realizado por pessoal qualificado e treinado para garantir a sua operacionalidade em caso de mergulho programado com descompressão ou emergência hiperbárica?',
            Answer: 'white',
          },
          '10-6 c 152': {
            Question:
              'Caso haja uma segunda câmara hiperbárica na frente/embarcação de mergulho a mesma é testada periodicamente e é registrado em logbook?',
            Answer: 'white',
          },
          '10-7 a 153': {
            Question:
              'Existem, ao menos, dois membros na equipe de mergulho capacitados e treinados em suporte básico a vida, no atendimento inicial a emergências subaquáticas, atendendo assim as normas vigentes (Normam 15)?',
            Answer: 'white',
          },
          '10-7 b 154': {
            Question:
              'É cobrado eficácia nos simulados dos planos de resposta a emergência com ênfase na comunicação adequada?',
            Answer: 'white',
          },
          '10-7 c 155': {
            Question:
              'O treinamento de reciclagem em atendimento a emergências de primeiros socorros atende as orientações de acordo com as normas vigentes?',
            Answer: 'white',
          },
          '10-7 d 156': {
            Question:
              'São realizados simulados de primeiros socorros com as equipes de mergulho com ênfase na saúde dos mergulhadores?',
            Answer: 'white',
          },

          '10-7 b 156': {
            Question:
              'É cobrado eficácia nos simulados dos planos de resposta a emergência com ênfase na comunicação adequada?',
            Answer: 'white',
          },
          '10-8 a 157': {
            Question:
              'Há profissional de saúde de nível superior devidamente habilitado nas embarcações sob contrato?',
            Answer: 'white',
          },
          '10-8 b 158': {
            Question:
              'O profissional de saúde é capacitado e treinado em suporte avançado de vida e atendimento a emergência subaquática?',
            Answer: 'white',
          },
          '10-9 a 159': {
            Question:
              'A frente/embarcação de mergulho possui enfermaria que atenda aos requisitos da Normam-01, da NR-30 e demais legislações pertinentes?',
            Answer: 'white',
          },
          '10-9 b 160': {
            Question:
              'A enfermaria possui quantidade de equipamentos e medicamentos que atende a lista de itens mínimos de enfermaria padronizada pela Petrobras para frente/embarcação de mergulho?',
            Answer: 'white',
          },
          '10-10 a 161' : {
            Question:
              'Os meios de comunicação com o suporte médico em terra são adequados e tem backup?',
            Answer: 'white',
          },
          '10-10 b 162': {
            Question:
              'No procedimento de contingência está previsto o plano de resposta a emergência médica com os recursos de contato telefônico ou por vídeo?',
            Answer: 'white',
          },
          '10-10 c 163': {
            Question:
              'Existe plano de atendimento médico emergencial na empresa 24 horas para atender as equipes embarcadas no offshore?',
            Answer: 'white',
          },
          '10-11 a 164': {
            Question:
              'Existe procedimento específico para em caso de acidente com necessidade de remoção e transporte para hospital ou clínica em terra?',
            Answer: 'white',
          },
          '10-11 b 165': {
            Question:
              'Os supervisores estão aptos a orientar os responsáveis pelo transporte de acidentados via aérea, de que após a realização de mergulho, o voo deve cumprir um limite de altitude durante o voo em cumprimento da NORMAM?',
            Answer: 'white',
          },
          '10-11 c 166': {
            Question:
              'O Plano de contingência garante acesso a rede hospitalar especializada e a disponibilidade de recursos para um tratamento específico até o pronto reestabelecimento do acidentado?',
            Answer: 'white',
          },
          '10-11 d 167': {
            Question:
              'Existe procedimento da empresa que propicie a remoção do acidentado embarcado até a rede hospitalar?',
            Answer: 'white',
          },
          '10-11 e 168': {
            Question:
              'O RH da empresa está capacitado a proporcionar todo o acompanhamento ao acidentado desde a internação até o seu retorno as atividades?',
            Answer: 'white',
          },
          '10-12 a 169': {
            Question:
              'É realizada uma criteriosa avaliação médica especializada antes do retorno do mergulhador ao trabalho?',
            Answer: 'white',
          },
          '10-12 b 170': {
            Question:
              'O RH da empresa acompanha o processo de retorno do acidentado as suas atividades laborais após finalizado o tratamento?',
            Answer: 'white',
          },


      },


  };

  let listKeysQuestionAcidentesHiperbaricos=[
    {
      'AM 1- Variações de pressão não programadas (subida ou descida não programada)': [
        { '1-1': { Check: [], Answer: 'white' } },
        { '1-2': { Check: [], Answer: 'white' } },
        { '1-3': { Check: [], Answer: 'white' } },
        { '1-4': { Check: [], Answer: 'white' } },
        { '1-5': { Check: [], Answer: 'white' } },
        { '1-6': { Check: [], Answer: 'white' } },
        { '1-7': { Check: [], Answer: 'white' } },
      ].reverse(),
      'AM2-Falha na aplicação da tabela de descompressão': [
        { '2-1': { Check: [], Answer: 'white' } },
        { '2-2': { Check: [], Answer: 'white' } },
        { '2-3': { Check: [], Answer: 'white' } },
        { '2-4': { Check: [], Answer: 'white' } },
        { '2-5': { Check: [], Answer: 'white' } },
      ].reverse(),
      'AM 3-Falha na operação da câmara hiperbárica': [
        { '3-1': { Check: [], Answer: 'white' } },
        { '3-2': { Check: [], Answer: 'white' } },
        { '3-3': { Check: [], Answer: 'white' } },
        { '3-4': { Check: [], Answer: 'white' } },
        { '3-5': { Check: [], Answer: 'white' } },
        { '3-6': { Check: [], Answer: 'white' } },
        { '3-7': { Check: [], Answer: 'white' } },

      ].reverse(),
      'AM 4-Efeitos da pressão no organismo do mergulhador': [
        { '4-1': { Check: [], Answer: 'white' } },
        { '4-2': { Check: [], Answer: 'white' } },
        { '4-3': { Check: [], Answer: 'white' } },
        { '4-4': { Check: [], Answer: 'white' } },
        { '4-5': { Check: [], Answer: 'white' } },
        { '4-6': { Check: [], Answer: 'white' } },
        { '4-7': { Check: [], Answer: 'white' } },
      ].reverse(),
      'AM 5-Suscetibilidade individual do mergulhador': [
        { '5-1': { Check: [], Answer: 'white' } },
        { '5-2': { Check: [], Answer: 'white' } },
        { '5-3': { Check: [], Answer: 'white' } },
        { '5-4': { Check: [], Answer: 'white' } },
        { '5-5': { Check: [], Answer: 'white' } },
        { '5-6': { Check: [], Answer: 'white' } },

      ].reverse(),
      'AM 6-Não cumprimento das restrições pós mergulho': [
        { '6-1': { Check: [], Answer: 'white' } },
        { '6-2': { Check: [], Answer: 'white' } },
        { '6-3': { Check: [], Answer: 'white' } },

      ].reverse(),
      'AM 7-Subida involuntária do mergulhador': [
        { '7-1': { Check: [], Answer: 'white' } },
        { '7-2': { Check: [], Answer: 'white' } },
        { '7-3': { Check: [], Answer: 'white' } },

      ].reverse(),
      'AM 8-Variação significativa de temperatura não programadas (aplicável somente para mergulho saturado)': [
        { '8-1': { Check: [], Answer: 'white' } },
        { '8-2': { Check: [], Answer: 'white' } },
        { '8-3': { Check: [], Answer: 'white' } },

      ].reverse(),
      'AM 9-Intoxicação por oxigênio': [
        { '9-1': { Check: [], Answer: 'white' } },
        { '9-2': { Check: [], Answer: 'white' } },
        { '9-3': { Check: [], Answer: 'white' } },

      ].reverse(),
      'CO 1- Lesão ou alteração funcional': [
        { '10-1': { Check: [], Answer: 'white' } },
        { '10-2': { Check: [], Answer: 'white' } },
        { '10-3': { Check: [], Answer: 'white' } },
        { '10-4': { Check: [], Answer: 'white' } },
        { '10-5': { Check: [], Answer: 'white' } },
        { '10-6': { Check: [], Answer: 'white' } },
        { '10-7': { Check: [], Answer: 'white' } },
        { '10-8': { Check: [], Answer: 'white' } },
        { '10-9': { Check: [], Answer: 'white' } },
        { '10-10': { Check: [], Answer: 'white' } },
        { '10-11': { Check: [], Answer: 'white' } },
        { '10-12': { Check: [], Answer: 'white' } },

      ],

    }]


  export {TitlesAndQuestionsAcidentesHiperbaricos,listKeysQuestionAcidentesHiperbaricos,titleFormAcidentesHiperbaricos,titleHazardAcidentesHiperbaricos,SecondIdAcidentesHiperbaricos,listAMCOAcidentesHiperbaricos}

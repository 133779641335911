/* eslint-disable no-empty */
/* eslint-disable consistent-return */
import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import MaterialTable from 'material-table';
import { useSelector } from 'react-redux';

import { FaMedal } from 'react-icons/fa';

import { lighten } from 'polished';
import { toast } from 'react-toastify';
import api from '../../services/api';

import { ContainerComponent } from '../../components/Container';
import {
  DivBar,
  ComboCompanyName,
  DivNewAudit,
  DivRanking,
  DivTable,
  DivCompletePage,
  DivDashboard,
} from './styles';
import history from '../../services/history';

export default function Auditoria() {
  document.title = 'Auditorias';

  useEffect(() => window.scrollTo(0, 0), []);

  const { auditor, admin, sudo } = useSelector(state => state.auth.groupLevel);
  /**
   * auditorias: {
   *  tableModel: { columns, data },
   *  ranking: Number,
   *  generalPontuation: Number
   * }
   */
  const [auditoriaData, setAuditoriaData] = useState(false);
  const [companyData, setCompanyData] = useState(false);
  const [names, setNames] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(false);

  const apiCall = useCallback(async () => {
    try {
      const response = await api.get('company/names/all');

      if (response.status === 200) {
        setNames(response.data);
      }
    } catch (error) {}
  }, []);

  useEffect(() => {
    apiCall();
  }, [apiCall]);

  const apiCallForCompanyData = useCallback(async companyId => {
    try {
      const response = await api.get(`company/${companyId}`);

      if (response.status === 200) {
        if (response.data.err) {
          toast.info(response.data.err);
        } else if (response.data) {
          const { tableModel, ranking, generalPontuation } = response.data;
          setAuditoriaData(tableModel.companyTable);
          setCompanyData({
            ranking,
            generalPontuation,
          });
        }
      }
    } catch (error) {
      return false;
    }
  }, []);

  const getCompanyData = {
    async getCompanyData(comboCompanyValue) {
      setAuditoriaData(false);
      setCompanyData(false);

      if (comboCompanyValue && comboCompanyValue !== ' ') {
        setSelectedCompany(comboCompanyValue);
        apiCallForCompanyData(comboCompanyValue);
      }

      if (comboCompanyValue && comboCompanyValue === ' ') {
        setSelectedCompany(false);
      }
    },
  };

  const downloadDocument = {
    requestDownload(path) {
      api
        .get(`/auditoria/files/${path}`, { responseType: 'blob' })
        .then(response => {
          const file = new Blob([response.data], { type: 'application/pdf' });
          const a = document.createElement('a');
          document.body.appendChild(a);
          const blobUrl = window.URL.createObjectURL(file);
          a.href = blobUrl;
          a.download = 'Auditoria';
          a.click();
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          toast.error('Documento não encontrado');
        });
    },
  };

  const deleteAuditoria = useCallback(
    async fileId => {
      try {
        const payload = {
          companyId: selectedCompany,
          file: fileId,
        };
        const response = await api.put(`company/auditoria/delete`, payload);

        if (response.status === 200) {
          toast.success('Deletado com sucesso');
        }
      } catch (error) {
        toast.error('Erro ao deletar auditoria');
      }
    },
    [selectedCompany]
  );

  const GoToDashboard=()=>{
    return history.push("/auditoria/dashboard")
  }

  return (
    <ContainerComponent>
      {(auditor || admin || sudo) && (
        <>
          <DivBar>
            <ComboCompanyName>
              <h2>Selecione a empresa</h2>

              {names && (
                <select
                  name="companyId"
                  id="companyId"
                  onChange={e => {
                    getCompanyData.getCompanyData(e.target.value);
                  }}
                >
                  <option value=" "> </option>
                  {names.map(name => (
                    <option key={name._id} value={name._id}>
                      {name.name}
                    </option>
                  ))}
                </select>
              )}
            </ComboCompanyName>

            {selectedCompany && !(admin || sudo) && (
              <DivNewAudit>
                <h2>Empresa selecionada:</h2>
                <h3>{selectedCompany}</h3>
                <Link to={`/auditoria/${selectedCompany}`}>Novo cadastro</Link>
              </DivNewAudit>
            )}

            <DivDashboard>
              <button onClick={GoToDashboard}>Dashboard</button>
            </DivDashboard>

            <DivRanking>
              <h1>
                Ranking: {companyData.ranking}&emsp;&emsp; <FaMedal />
              </h1>

              <h2>Nota: {companyData.generalPontuation}</h2>
            </DivRanking>
          </DivBar>

          {selectedCompany ? (
            <DivTable>
              <h2>Histórico</h2>
              <MaterialTable
                options={{
                  headerStyle: {
                    backgroundColor: lighten(0.05, '#00529b'),
                    color: '#eee',
                    fontSize: '1.6rem',
                    fontWeight: 'bold',
                    textAlign: 'center',
                  },
                }}
                title=" "
                columns={auditoriaData.columns}
                data={auditoriaData.data}
                actions={[
                  {
                    icon: 'get_app',

                    onClick: (event, rowData) => {
                      if (rowData.file) {
                        downloadDocument.requestDownload(rowData.file);
                      }
                    },
                  },
                ]}
                editable={{
                  onRowDelete: oldData =>
                    new Promise(resolve => {
                      const { file } = oldData;

                      deleteAuditoria(file);

                      setTimeout(() => {
                        resolve();
                        setAuditoriaData(prevState => {
                          const data = [...prevState.data];
                          data.splice(data.indexOf(oldData), 1);
                          return { ...prevState, data };
                        });
                      }, 600);
                    }),
                }}
                detailPanel={[
                  {
                    render: rowData => {
                      return (
                        <div
                          style={{
                            fontSize: 16,
                            textAlign: 'center',
                            color: '#00529b',
                            backgroundColor: '#e8eaef',
                          }}
                        >
                          {rowData.observation
                            ? rowData.observation
                            : 'Não há obervação disponível'}
                        </div>
                      );
                    },
                  },
                ]}
              />
            </DivTable>
          ) : (
            <>
              <DivCompletePage />
              <DivCompletePage />
              <DivCompletePage />
              <DivCompletePage />
              <DivCompletePage />
            </>
          )}
        </>
      )}
    </ContainerComponent>
  );
}

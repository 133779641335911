import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  right: 196px;
  top: 48px;
`;

export const Badge = styled.button`
  position: absolute;
  background: none;
  border: solid 2px #00529b;
  border-radius: 50%;

  @media (max-width: 768px) {
    top: 8.5rem;
    right: 16rem;
  }
`;

import styled from 'styled-components';
import { lighten } from 'polished';

export const DivBar = styled.div`
  display: flex;
  justify-content: space-between;
  width: 1109px;
  height: 128px;
`;

export const ComboCompanyName = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 320px;
  height: 128px;
  background: rgba(255, 255, 255, 1);
  border-radius: 5px;

  h2 {
    color: #00529b;
    margin-bottom: 12px;
  }

  select {
    margin-top: 8px;
    width: 238px;
    height: 28px;
    border-radius: 5px;
    border: 1px solid #000;
    padding-left: 5px;
    background: #fff;
  }
`;

export const DivNewAudit = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 320px;
  height: 128px;
  background: rgba(255, 255, 255, 1);
  border-radius: 5px;

  h2 {
    color: #00529b;
  }

  h3 {
    color: #b56a1e;
    margin: 12px 0 12px 0;
  }

  a {
    width: 238px;
    height: 28px;
    border: solid 1px #eee;
    background: #00529b;
    color: #fff;
    font-weight: bold;
    text-align: center;
    padding-top: 4px;
    border-radius: 5px;

    &:hover {
      background: ${lighten(0.05, '#00529b')};
    }
  }
`;

export const DivRanking = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 320px;
  height: 128px;
  background: rgba(255, 255, 255, 1);
  border-radius: 5px;

  h1 {
    margin-left: 32px;
    color: #00529b;
  }

  h2 {
    margin-left: 32px;
    margin-top: 12px;
    color: #b56a1e;
  }
`;


export const DivDashboard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 320px;
  height: 128px;
  background: rgba(255, 255, 255, 1);
  border-radius: 5px;

  button{
    background:#3a5185;
    color:white;
    padding:10px;
    border:none;
    border-radius:5px;
    font-size:15px;
    font-weight:bold;
  }

  button:hover{
    background:#6cafd9;
    cursor:pointer;
  }

`;

export const DivTable = styled.div`
  margin-top: 48px;
  position: relative;

  h2 {
    color: #00529b;
    margin-bottom: 16px;
    position: absolute;
    z-index: 5;
    left: 32px;
    top: 16px;

    &:hover {
      color: ${lighten(0.08, '#00529b')};
    }
  }

  button + button {
    margin-right: 48px;
  }
`;

export const DivCompletePage = styled.div`
  height: 96px;
`;

import styled from 'styled-components';

export const Footer = styled.footer`
  display: flex;
  width: 100%;
  height: 108px;
  background: #051367;

  @media(max-width:768px){
    display:none;
  }
`;

export const Content = styled.div`
  display: flex;
  min-width: 1109px;
  max-width: 1309px;
  height: 108px;
  background: #051367;
  margin: auto;
  align-items: center;
  justify-content:center;
  border-radius: 5px;
`;

export const DivCopyright = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  margin-left: 12px;
  position: relative;

  img {
    margin: 0 0 8px 1px;
    width: 40%;
  }

  span {
    font-size: 10px;
    position: absolute;
    top: 66px;
    left: -16px;
  }
`;

export const DivLinkArea = styled.div`
  display: flex;
  flex-direction: column;
  height: 64px;
  justify-content: space-around;
`;

export const DivLinks = styled.div`
  nav {
    a {
      position: relative;
      color: #DFF6FF;
      font-size: 1.6rem;
      transition: color 0.3s;
      text-decoration:none;
      margin-left: 1.5em;
      margin-right: 1.5em;

      &:hover {
        color: #5D8BF4;
        font-weight: 700;
      }
    }

    span {
      color: #5D8BF4;
      font-size: 1.6rem;
      font-weight: bold;
    }
  }
`;

import React, { useEffect, useState, useCallback } from 'react';
import MaterialTable from 'material-table';
import { useSelector } from 'react-redux';

import { FaMedal } from 'react-icons/fa';

import { lighten } from 'polished';
import { toast } from 'react-toastify';
import api from '../../services/api';

import { ContainerComponent } from '../../components/Container';

import { DivRanking, DivTable, DivCompletePage } from './styles';

export default function Company() {
  document.title = 'Auditorias';

  const { company } = useSelector(state => state.auth.groupLevel);

  /**
   * auditorias: {
   *  tableModel: { columns, data },
   *  ranking: Number,
   *  generalPontuation: Number
   * }
   */
  const [auditoriaData, setAuditoriaData] = useState(false);
  const [companyData, setCompanyData] = useState(false);

  const apiCall = useCallback(async () => {
    try {
      const response = await api.get('company');

      if (response.status === 200) {
        if (response.data.err) {
          toast.info(response.data.err);
        } else if (response.data) {
          const { tableModel, ranking, generalPontuation } = response.data;
          setAuditoriaData(tableModel.companyTable);
          setCompanyData({
            ranking,
            generalPontuation,
          });
        }
      }
    } catch (error) {
      return false;
    }
  }, []);

  useEffect(() => {
    apiCall();
  }, [apiCall]);

  const downloadDocument = {
    requestDownload(path) {
      api
        .get(`/auditoria/files/${path}`, { responseType: 'blob' })
        .then(response => {
          const file = new Blob([response.data], { type: 'application/pdf' });
          const a = document.createElement('a');
          document.body.appendChild(a);
          const blobUrl = window.URL.createObjectURL(file);
          a.href = blobUrl;
          a.download = 'Auditoria';
          a.click();
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          toast.error('Documento não encontrado');
        });
    },
  };

  return (
    <ContainerComponent>
      {company && companyData && (
        <>
          <DivRanking>
            <h1>
              Ranking: {companyData.ranking}&emsp;&emsp; <FaMedal />
            </h1>

            <h2>Nota: {companyData.generalPontuation}</h2>
          </DivRanking>

          <DivTable>
            <h2>Histórico</h2>
            <MaterialTable
              options={{
                headerStyle: {
                  backgroundColor: lighten(0.05, '#00529b'),
                  color: '#eee',
                  fontSize: '1.6rem',
                  fontWeight: 'bold',
                  textAlign: 'center',
                },
              }}
              title=" "
              columns={auditoriaData.columns}
              data={auditoriaData.data}
              actions={[
                {
                  icon: 'get_app',

                  onClick: (event, rowData) => {
                    if (rowData.file) {
                      downloadDocument.requestDownload(rowData.file);
                    }
                  },
                },
              ]}
              detailPanel={[
                {
                  render: rowData => {
                    return (
                      <div
                        style={{
                          fontSize: 16,
                          textAlign: 'center',
                          color: '#00529b',
                          backgroundColor: '#e8eaef',
                        }}
                      >
                        {rowData.observation
                          ? rowData.observation
                          : 'Não há obervação disponível'}
                      </div>
                    );
                  },
                },
              ]}
            />
          </DivTable>
        </>
      )}
      <DivCompletePage />
    </ContainerComponent>
  );
}

/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import { Wrapper } from './styles';
import { HeaderComponentSigned } from '../../../components/Header';
import FooterComponent from '../../../components/Footer';

export function DefaultLayout({ children }) {
  return (
    <Wrapper>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      {children}
    </Wrapper>
  );
}

export function SignedLayout({ children }) {
  return (
    <Wrapper>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <HeaderComponentSigned />
      {children}
      <FooterComponent />
    </Wrapper>
  );
}

DefaultLayout.propTypes = {
  children: PropTypes.any.isRequired,
};

SignedLayout.propTypes = {
  children: PropTypes.any.isRequired,
};

import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import ShowUsers from '../pages/ShowUsers';

import SignIn from '../pages/SignIn';
import UserFeedback from '../pages/UserFeedback';
import Index from '../pages/Index';

// Apenas autenticado
import Dashboard from '../pages/Dashboard';
import SearchAlert from '../pages/SearchAlert';
import ShowResults from '../pages/ShowResults';
import AddUser from '../pages/AddUser';
import AddAlert from '../pages/AddAlert';
import ShowAlert from '../pages/ShowAlert';
import ShowFeedback from '../pages/ShowFeedback';
import WhoAreUs from '../pages/WhoAreUs';
import OurActivities from '../pages/OurActivities';
import Card1 from '../pages/Card1';
import Card2 from '../pages/Card2';
import TermsOfUse from '../pages/TermsOfUse';
import __ShowChecklist from '../pages/__ShowChecklist';
import ResetPassword from '../pages/ResetPassword';
import Company from '../pages/Company';
import Auditoria from '../pages/Auditoria';
import AddAuditoria from '../pages/AddAuditoria';
import AddDocPetrobras from '../pages/AddDocPetrobras';
import ShowPetrobrasDocument from '../pages/ShowPetrobrasDocument';
import BOWTIE from '../pages/bowtie';
import Diagrama from '../pages/bowtie/diagrama/index';
import DashboardExcel from '../pages/bowtie/dashboard';
import Checklist from '../pages/bowtie/checklist';
import Checklist1 from '../pages/bowtie/checklist/botoes/checklist1';
import Checklist2 from '../pages/bowtie/checklist/botoes/checklist2';
import Checklist3 from '../pages/bowtie/checklist/botoes/checklist3';
import Checklist4 from '../pages/bowtie/checklist/botoes/checklist4';

import Diagram1 from '../pages/bowtie/diagrama/checklist1/index'
import Event1Diagram1 from '../pages/bowtie/diagrama/checklist1/Event1/index'


import Diagram2 from '../pages/bowtie/diagrama/checklist2'
import Event1Diagram2 from '../pages/bowtie/diagrama/checklist2/Event1/index'

import Diagram3 from '../pages/bowtie/diagrama/checklist3'
import Event1Diagram3 from '../pages/bowtie/diagrama/checklist3/Event1/index'


import Diagram4 from '../pages/bowtie/diagrama/checklist4'
import Event1Diagram4 from '../pages/bowtie/diagrama/checklist4/Event1/index'




export default function Routes() {
  return (
    <Switch>
      <Route path="/login" exact component={SignIn} />
      <Route path="/" exact component={Index} isPrivate />
      <Route path="/card1" exact component={Card1} isPrivate />
      <Route path="/card2" exact component={Card2} isPrivate />
      <Route path="/quem-somos" exact component={WhoAreUs} isPrivate />
      <Route path="/nossas-atividades" exact component={OurActivities} isPrivate />
      <Route path="/fale-conosco" exact component={UserFeedback} />
      <Route path="/termos-de-uso" exact component={TermsOfUse} isPrivate />
      <Route path="/recuperar-senha" exact component={ResetPassword} />
      <Route path="/dashboard" exact component={Dashboard} isPrivate />
      <Route path="/alertas/cadastrar" exact component={AddAlert} isPrivate />
      <Route path="/usuario/cadastrar" exact component={AddUser} isPrivate />
      <Route path="/usuarios" exact component={ShowUsers} isPrivate />
      <Route path="/pesquisa" exact component={SearchAlert} isPrivate />
      <Route path="/pesquisa/resultado" exact component={ShowResults} isPrivate />
      <Route path="/alerta/:id" exact component={ShowAlert} isPrivate />
      <Route path="/feedback" exact component={ShowFeedback} isPrivate />
      <Route path="/auditoria/checklist" exact component={__ShowChecklist} isPrivate />
      <Route path="/empresa" exact component={Company} isPrivate />
      <Route path="/auditoria" exact component={Auditoria} isPrivate />
      <Route path="/auditoria/dashboard" exact component={DashboardExcel} isPrivate />
      <Route path="/auditoria/:companyId" exact component={AddAuditoria} isPrivate />
      <Route path="/petrobras/doc" exact component={AddDocPetrobras} isPrivate />
      <Route path="/petrobras" exact component={ShowPetrobrasDocument} isPrivate />
      <Route path="/bowtie" exact component={BOWTIE} isPrivate />
      <Route path="/bowtie/diagrama" exact component={Diagrama} isPrivate />
      <Route path="/bowtie/checklist" exact component={Checklist} isPrivate />
      <Route path="/bowtie/checklist/botoes/checklist1" exact component={Checklist1} isPrivate />
      <Route path="/bowtie/checklist/botoes/checklist2" exact component={Checklist2} isPrivate />
      <Route path="/bowtie/checklist/botoes/checklist3" exact component={Checklist3} isPrivate />
      <Route path="/bowtie/checklist/botoes/checklist4" exact component={Checklist4} isPrivate />
      <Route path="/bowtie/diagrama/checklist1" exact component={Diagram1} isPrivate />
      <Route path="/bowtie/diagrama/checklist1/Event1" exact component={Event1Diagram1} isPrivate />


      <Route path="/bowtie/diagrama/checklist2" exact component={Diagram2} isPrivate />
      <Route path="/bowtie/diagrama/checklist2/Event1" exact component={Event1Diagram2} isPrivate />


      <Route path="/bowtie/diagrama/checklist3" exact component={Diagram3} isPrivate />
      <Route path="/bowtie/diagrama/checklist3/Event1" exact component={Event1Diagram3} isPrivate />

      <Route path="/bowtie/diagrama/checklist4" exact component={Diagram4} isPrivate />
      <Route path="/bowtie/diagrama/checklist4/Event1" exact component={Event1Diagram4} isPrivate />
      <Route path="/" component={() => <h1>404</h1>} />
    </Switch>
  );
}

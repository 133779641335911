import React from 'react';
import { ContainerComponent } from '../../components/Container';
import { StaticView } from '../../components/StaticView/styles';

export default function WhoAreUs() {
  document.title = 'Quem somos';

  return (
    <ContainerComponent>
      <StaticView>
        <h1>QUEM SOMOS</h1>

        <p>
          A Petrobras busca atingir a excelência nas operações de mergulho
          através do PEODIVE, programa que estabelece padrões de gestão a serem
          atendidos pelas empresas. O objetivo principal do programa é apoiar as
          empresas prestadoras de serviços de mergulho na melhoria do desempenho
          operacional, através de verificações periódicas da gestão de
          segurança, meio ambiente e saúde. O PEODIVE tem como base as
          Diretrizes Corporativas de Segurança, Meio Ambiente e Saúde da
          Petrobras e as melhores práticas operacionais e gerenciais nestes
          segmentos observados mundialmente, tendo como benchmarking práticas
          adotadas no Mar do Norte e Golfo do México.
        </p>
      </StaticView>
    </ContainerComponent>
  );
}

import styled from 'styled-components';
import { lighten } from 'polished';

export const Container = styled.div``;

export const Content = styled.div`
  width: 90%;
  max-width: 1109px;

  margin: 32px auto;
`;

export const DivGrid = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;

  padding: 32px;

  background: #fbfbfb;
  border: solid 2px #00529b;
  border-radius: 5px;
`;

export const DivCharts = styled.div`
  display: flex;
  justify-content: center;

  width: 100%;

  border-radius: 5px;
  background: #fff;

  @media(max-width:768px){
    flex-direction:column;
  }
`;

export const SmallColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 384px;
`;

export const LargeColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 384px;
`;

export const SmallChart = styled.div`
  display: flex;
  width: 100%;
  height: 192px;
  border: solid 1px #00529b;

  & + div {
    border-top: 0px;
  }
`;

export const LargeChart = styled.div`
  display: flex;
  width: 100%;
  height: 192px;
  border: solid 1px #00529b;
  border-left: 0;
  border-right: 0;

  & + div {
    border-top: 0;
  }
`;

export const DivBox = styled.div`
  display: flex;

  width: 100%;

  margin-top: 32px;

  @media(max-width:768px){
    flex-direction:column;
  }
`;

export const DivSearch = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  border: solid 1px #00529b;

  margin-right: 20px;
  padding: 24px;

  background: #eee;
  border-radius: 5px;

  h2 {
    color: #00529b;
  }

  > label {
    font-size: 1.6rem;
    color: #00529b;
    font-weight: bold;
    margin-top: 12px;
  }

  input {
    width: 100%;
    height: 32px;
    border-radius: 5px;
    border: 1px solid #000;
    padding: 0 8px;
  }

  select {
    width: 100%;
    height: 28px;
    border-radius: 5px;
    border: 1px solid #000;
    background: #fff;
  }

  @media(max-width:768px){
    .form-check{
      display:flex;
    }
  }


`;

export const DivLastAlerts = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  padding: 24px;

  border: solid 1px #00529b;

  border-radius: 5px;

  h2 {
    color: #00529b;
  }
`;

export const ListLastAlerts = styled.ul`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  list-style: none;

  li {
    width: 100%;
    padding: 16px 0;
  }
`;

export const AlertTitle = styled.div`
  width: 100%;

  a {
    font-size: 14px;
    color: #b56a1e;
    font-weight: bold;
  }
`;

export const AlertDescription = styled.div`
  width: 100%;
  max-height: 50px;
  overflow: hidden;

  span {
    margin-top: 8px;
    font-size: 12px;
    max-height: 32px;
  }
`;

export const DivDate = styled.div`
  width: 100%;

  display: flex;
  justify-content: flex-start;
  align-items: baseline;

  margin-top: 12px;

  label {
    font-size: 1.6rem;
    color: #00529b;
    font-weight: bold;
    margin-right: 8px;
  }

  span {
    color: #00529b;
    font-weight: bold;
    margin-right: 8px;
  }

  input {
    width: 72px;
    height: 30px;
    text-align: center;
    margin-right: 8px;
  }
`;

export const SearchButton = styled.div`
  width: 100%;
  height: 64px;
  border: 0;
  margin-top: 20px;

  display: flex;

  button {
    width: 100%;
    height: 48px;
    border: solid 1px #eee;
    border-radius: 5px;
    background: #00529b;
    color: #fff;
    font-weight: bold;

    &:hover {
      background: ${lighten(0.08, '#00529b')};
    }
  }
`;

export const LoadingSearch = styled.div`
  width: 82px;
  height: 64px;
  border: 0;

  border: solid 1px #eee;
  border-radius: 5px;
`;

import React, { useEffect, useState, useCallback } from 'react';
import MaterialTable from 'material-table';
import { useSelector } from 'react-redux';

import { lighten } from 'polished';
import api from '../../services/api';

import { ContainerComponent } from '../../components/Container';
import { DivTable, DivCompletePage } from './styles';

export default function ShowFeedback() {
  document.title = 'Feedbacks';

  const { admin } = useSelector(state => state.auth.groupLevel);

  const [feedbackData, setFeedbackData] = useState(false);

  const apiCall = useCallback(async () => {
    const response = await api.get('feedback');

    if (response.status === 200) {
      const { feedbackTable } = response.data;

      if (feedbackTable) {
        setFeedbackData(feedbackTable);
      }
    }
  }, []);

  useEffect(() => {
    if (admin) {
      apiCall();
    }
  }, [admin, apiCall]);

  const visualizeFeedback = useCallback(async feedback_id => {
    await api.put(`/feedback/${feedback_id}`);
  }, []);

  return (
    <ContainerComponent>
      {admin && (
        <DivTable>
          <h2>Mensagens</h2>
          <MaterialTable
            options={{
              headerStyle: {
                backgroundColor: lighten(0.05, '#00529b'),
                color: '#eee',
                fontSize: '1.6rem',
                fontWeight: 'bold',
              },
            }}
            title=" "
            columns={feedbackData.columns}
            data={feedbackData.data}
            detailPanel={[
              {
                render: rowData => {
                  if (rowData.status === 'Pendente') {
                    visualizeFeedback(rowData._id);
                  }
                  rowData.status = 'Visualizado';
                  return (
                    <div
                      style={{
                        fontSize: 16,
                        textAlign: 'center',
                        color: '#00529b',
                        backgroundColor: '#e8eaef',
                      }}
                    >
                      {rowData.description}
                    </div>
                  );
                },
              },
            ]}
          />
        </DivTable>
      )}

      <DivCompletePage />
    </ContainerComponent>
  );
}

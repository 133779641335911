import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { searchResult, searchFail } from './actions';

import api from '../../../services/api';
import history from '../../../services/history';

export function* performSearch(formattedSearchModel) {
  const { payload } = formattedSearchModel;

  formattedSearchModel = payload;

  try {
    const response = yield call(api.post, 'search', formattedSearchModel);

    if (response.status === 200) {
      const { alertsFound } = response.data;

      if (alertsFound && alertsFound.length > 0) {
        yield put(searchResult(alertsFound));

        history.push('/pesquisa/resultado');
      } else {
        yield put(searchFail());
        toast.warn('Nenhum resultado encontrado');
      }
    }
  } catch (error) {
    toast.error(
      'Erro ao realizar pesquisa. Tente novamente em alguns instantes'
    );
    yield put(searchFail());
  }
}

export default all([
  // takeLatest('persist/REHYDRATE', setToken),
  takeLatest('@search/SEARCH_REQUEST', performSearch),
]);

import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Image,
} from '@react-pdf/renderer';
import React from 'react';
import Logo from '../../../assets/peodive-logo.jpg';

const styles = StyleSheet.create({
  page: {
    backgroundColor: 'white',
    color: 'black',
    borderColor: '#395582',
    borderWidth: 2,
    margin: 7,
  },
  section: {
    margin: 10,
    padding: 10,
  },
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
});

function BasicDocument({ exportExcelData, exportAnswerFormExcel,selectedEvent,InformationUser,DateUser }) {
  const Answers = exportAnswerFormExcel;
  const ReportAnswers = exportExcelData;
  return (
    <div>
      <PDFViewer style={styles.viewer}>
        {/* Start of the document*/}
        <Document>
          {/*render a single page*/}
          <Page size="A4" style={styles.page}>
            <View style={styles.section}>
            <View style={{alignItems:'flex-end',height:25}}>
                  <Text style={{fontSize:8,color:'#96999e'}}>SMS/ECE/SMCN</Text>
                  <Text style={{fontSize:10,color:'#96999e'}}>Segurança em Mergulho e Conformidade Naval</Text>
            </View>
              <View style={{ flexDirection: 'row' }}>

                <Image src={Logo} style={{ width: '20%' }} />
                <View style={{
                    paddingTop: 20,
                    paddingLeft:30,
                    flex:1,
                    alignItems: 'center'
                  }}>
                <Text
                >
                  {selectedEvent}
                </Text>
                </View>
              </View>

              <View style={{flexDirection:'row',flex:1,alignItems:'center',marginLeft:10,marginTop:10}}>
                    <View style={{marginRight:5}}>
                      <Text style={{fontSize:8}}>Nome:{InformationUser.name}</Text>
                    </View>
                    <View style={{marginRight:5}}>
                      <Text style={{fontSize:8}}>Email:{InformationUser.email}</Text>
                    </View>
                    <View style={{marginRight:5}}>
                      <Text style={{fontSize:8}}>Telefone:{InformationUser.telefone}</Text>
                    </View>
                    <View >
                      <Text style={{fontSize:8}}>Empresa:{InformationUser.empresa}</Text>
                    </View>
                    <View >
                      <Text style={{fontSize:8}}>Data:{DateUser}</Text>
                    </View>

              </View>
            </View>
              <View style={{borderColor:'#96999e',borderWidth:5,marginLeft:25,marginRight:25}}></View>
            <View style={styles.section}>
              {ReportAnswers.map(report => (
                <View style={{ flexDirection: 'row' }}>
                  <View style={{ flexDirection: 'column', marginBottom: 10 }}>
                    <Text style={{ fontSize: 14 }}>
                      Total de BARREIRAS:{report['Total de Grupos']}
                    </Text>
                    <Text style={{ fontSize: 14 }}>
                      Total de BARREIRA ÍNTEGRA:
                      {report['Total de BARREIRA ÍNTEGRA']}
                    </Text>
                    <Text style={{ fontSize: 14 }}>
                      Total de BARREIRA DEGRADADA:
                      {report['Total de BARREIRA DEGRADADA']}
                    </Text>
                    <Text style={{ fontSize: 14 }}>
                      Total de BARREIRA NAO IMPLEMENTADA:
                      {report['Total de BARREIRA NAO IMPLEMENTADA']}
                    </Text>
                    <Text style={{ fontSize: 14 }}>
                      Total de BARREIRA NAO APLICÁVEL:{report['Total de NĀO APLICÁVEL']}
                    </Text>
                  </View>

                  <View
                    style={{
                      borderLeftWidth: 0.5,
                      borderColor: 'gray',
                      marginLeft: 15,
                      marginBottom: 10,
                      marginRight: 10,
                    }}
                  ></View>

                  <View style={{ flexDirection: 'column' }}>
                    <Text style={{ fontSize: 14 }}>
                      BARREIRA ÍNTEGRA:{report['% BARREIRA ÍNTEGRA']}
                    </Text>
                    <Text style={{ fontSize: 14 }}>
                      BARREIRA NAO IMPLEMENTADA:
                      {report['% BARREIRA NAO IMPLEMENTADA']}
                    </Text>
                    <Text style={{ fontSize: 14 }}>
                      BARREIRA DEGRADADA:{report['% BARREIRA DEGRADADA']}
                    </Text>
                    <Text style={{ fontSize: 14 }}>
                      BARREIRA NAO APLICÁVEL:{report['% NĀO APLICÁVEL']}
                    </Text>
                  </View>
                </View>
              ))}
            </View>
            <View style={{borderColor:'#96999e',borderWidth:5,marginLeft:25,marginRight:25}}></View>
            <View style={styles.section}>
              {Answers.map((answer, index) => (
              <View>
                  <View style={{ marginTop: 10, marginBottom: 10 }}>

                  <View style={{ flexDirection: 'column' }}>
                    <Text style={{ fontSize: 13 }}>Numero:{localStorage.getItem('name') === 'secret@key' ? answer.numero : answer.numero.split(" ")[2]}</Text>
                    <Text style={{ fontSize: 13 }}>
                      Pergunta:{answer.pergunta}
                    </Text>
                    <Text style={{ fontSize: 13 }}>
                      Resposta:{answer.resposta}
                    </Text>
                  </View>
                </View>
                {(index+1)===6 ? (
                  <View>
                      <Text break style={{color:'white'}}>break</Text>
                      <Text break style={{color:'white'}}>break</Text>
                  </View>

                ):((index+1)%3===0 && (index+1)>=15 ? (
                  <View>
                      <Text break style={{color:'white'}}>break</Text>
                      <Text break style={{color:'white'}}>break</Text>
                      <Text break style={{color:'white'}}>break</Text>
                  </View>
                ):'')}
              </View>


              ))}
            </View>
          </Page>
        </Document>
      </PDFViewer>
    </div>
  );
}
export default BasicDocument;

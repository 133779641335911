import styled from 'styled-components';
import { lighten } from 'polished';

export const DivRanking = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 320px;
  height: 128px;
  background: rgba(255, 255, 255, 1);
  border-radius: 5px;

  h1 {
    margin-left: 32px;
    color: #00529b;
  }

  h2 {
    margin-left: 32px;
    margin-top: 12px;
    color: #b56a1e;
  }
`;

export const DivTable = styled.div`
  margin-top: 48px;
  position: relative;

  h2 {
    color: #00529b;
    margin-bottom: 16px;
    position: absolute;
    z-index: 5;
    left: 32px;
    top: 16px;

    &:hover {
      color: ${lighten(0.08, '#00529b')};
    }
  }

  button + button {
    margin-right: 48px;
  }
`;

export const DivCompletePage = styled.div`
  height: 96px;
`;

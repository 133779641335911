import { TitlesAndQuestionsFaltaRespiratorio } from '../formsModel/formFaltaRespiratorioModelNew';

const myArrayVerification = (
  myArray,
  setArray,
  groupName,
  AnswerCheck,
  listKeysQuestion,
  setListKeys,
  questionsSelected,
  setquestionsSelected,
  AnswerForm,
  setAnswerForm,
  AnswerQuestionForm,
  setAnswerQuestionForm
) => {
  let groupNameSplit = groupName.split(' ');
  myArray.forEach(el => {
    for (let FirstKey of Object.keys(el)) {
      for (let SecondKey of Object.keys(el[FirstKey])) {
        let KeySplit = SecondKey.split(' ');
        if (groupNameSplit[0] === KeySplit[0]) {
          if (!questionsSelected.includes(groupName)) {
            for (let el of listKeysQuestion[0][FirstKey]) {
              if (Object.keys(el)[0] === groupNameSplit[0]) {
                let dict = {};
                dict[groupName] = AnswerCheck;
                AnswerForm.push(dict);
                setAnswerForm([...AnswerForm]);

                if (TitlesAndQuestionsFaltaRespiratorio[FirstKey][groupName]) {
                  AnswerQuestionForm.push({
                    tagQuestion: groupName,
                    question:
                      TitlesAndQuestionsFaltaRespiratorio[FirstKey][groupName]
                        .Question,
                  });
                  setAnswerQuestionForm([...AnswerQuestionForm]);

                  el[groupNameSplit[0]]['Check'].push(AnswerCheck);
                  questionsSelected.push(groupName);
                  setListKeys([...listKeysQuestion]);
                  setquestionsSelected([...questionsSelected]);
                  break;
                }
              }
            }
          } else {
            for (let el of listKeysQuestion[0][FirstKey]) {
              if (Object.keys(el)[0] === groupNameSplit[0]) {
                let dict = {};
                dict[groupName] = AnswerCheck;
                AnswerForm[questionsSelected.indexOf(groupName)] = dict;
                setAnswerForm([...AnswerForm]);
                el[groupNameSplit[0]]['Check'][
                  el[groupNameSplit[0]]['Check'].length - 1
                ] = AnswerCheck;
                setListKeys([...listKeysQuestion]);
                break;
              }
            }
          }

          break;
        }
      }

      for (let el of listKeysQuestion[0][FirstKey]) {
        if (Object.keys(el)[0] === groupNameSplit[0]) {
          if (
            el[groupNameSplit[0]]['Check'].includes('No') &&
            el[groupNameSplit[0]]['Check'].includes('Yes')
          ) {
            el[groupNameSplit[0]]['Answer'] = 'yellow';
            setListKeys([...listKeysQuestion]);
          } else if (
            el[groupNameSplit[0]]['Check'].includes('Yes') &&
            !el[groupNameSplit[0]]['Check'].includes('No')
          ) {
            el[groupNameSplit[0]]['Answer'] = 'green';
            setListKeys([...listKeysQuestion]);
          } else if (
            !el[groupNameSplit[0]]['Check'].includes('Yes') &&
            el[groupNameSplit[0]]['Check'].includes('No')
          ) {
            el[groupNameSplit[0]]['Answer'] = 'red';
            setListKeys([...listKeysQuestion]);
          } else {
            el[groupNameSplit[0]]['Answer'] = 'white';
            setListKeys([...listKeysQuestion]);
          }
        }
      }
    }
  });

  setArray([...myArray]);
};

const VerificationFaltaSistemaRespiratorio = (
  listKeysQuestion,
  setListKeys,
  groupName,
  AnswerCheck,
  myArray,
  setArray,
  questionsSelected,
  setquestionsSelected,
  AnswerForm,
  setAnswerForm,
  AnswerQuestionForm,
  setAnswerQuestionForm
) => {
  if (groupName === '7-1 a 113') {
    myArrayVerification(
      myArray,
      setArray,
      '8-1 a 147',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
  } else if (groupName === '7-1 b 114') {
    myArrayVerification(
      myArray,
      setArray,
      '8-1 b 148',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
  } else if (groupName === '7-2 a 115') {
    myArrayVerification(
      myArray,
      setArray,
      '8-2 a 149',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
  } else if (groupName === '7-2 b 116') {
    myArrayVerification(
      myArray,
      setArray,
      '8-2 b 150',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
  } else if (groupName === '7-2 c 117') {
    myArrayVerification(
      myArray,
      setArray,
      '8-2 c 151',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
  } else if (groupName === '7-3 a 118') {
    myArrayVerification(
      myArray,
      setArray,
      '8-3 a 152',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
  } else if (groupName === '7-3 b 119') {
    myArrayVerification(
      myArray,
      setArray,
      '8-3 b 153',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
  } else if (groupName === '7-3 c 120') {
    myArrayVerification(
      myArray,
      setArray,
      '8-3 c 154',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
  } else if (groupName === '7-3 d 121') {
    myArrayVerification(
      myArray,
      setArray,
      '8-3 d 155',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
  } else if (groupName === '7-4 a 121') {
    myArrayVerification(
      myArray,
      setArray,
      '8-4 a 156',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
  } else if (groupName === '7-4 b 122') {
    myArrayVerification(
      myArray,
      setArray,
      '8-4 b 157',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
  } else if (groupName === '7-5 a 124') {
    myArrayVerification(
      myArray,
      setArray,
      '8-5 a 159',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
  } else if (groupName === '7-5 b 125') {
    myArrayVerification(
      myArray,
      setArray,
      '8-5 b 160',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
  } else if (groupName === '7-5 c 126') {
    myArrayVerification(
      myArray,
      setArray,
      '8-5 c 161',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
  } else if (groupName === '7-6 a 127') {
    myArrayVerification(
      myArray,
      setArray,
      '8-6 a 162',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
  } else if (groupName === '7-6 b 128') {
    myArrayVerification(
      myArray,
      setArray,
      '8-6 b 163',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
  } else if (groupName === '7-6 c 129') {
    myArrayVerification(
      myArray,
      setArray,
      '8-6 c 164',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
  } else if (groupName === '7-7 a 131') {
    myArrayVerification(
      myArray,
      setArray,
      '8-7 a 166',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
  } else if (groupName === '7-7 b 132') {
    myArrayVerification(
      myArray,
      setArray,
      '8-7 b 167',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
  } else if (groupName === '7-8 a 133') {
    myArrayVerification(
      myArray,
      setArray,
      '8-8 a 168',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
  } else if (groupName === '7-8 b 134') {
    myArrayVerification(
      myArray,
      setArray,
      '8-8 b 169',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
  } else if (groupName === '7-8 c 135') {
    myArrayVerification(
      myArray,
      setArray,
      '8-8 c 170',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
  } else if (groupName === '7-8 d 136') {
    myArrayVerification(
      myArray,
      setArray,
      '8-8 d 171',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
  } else if (groupName === '7-9 a 137') {
    myArrayVerification(
      myArray,
      setArray,
      '8-9 a 172',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
  } else if (groupName === '7-9 b 138') {
    myArrayVerification(
      myArray,
      setArray,
      '8-9 b 173',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
  } else if (groupName === '7-9 c 139') {
    myArrayVerification(
      myArray,
      setArray,
      '8-9 c 174',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
  } else if (groupName === '7-10 a 140') {
    myArrayVerification(
      myArray,
      setArray,
      '8-10 a 175',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
  } else if (groupName === '7-10 b 141') {
    myArrayVerification(
      myArray,
      setArray,
      '8-10 b 176',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
  } else if (groupName === '7-10 c 142') {
    myArrayVerification(
      myArray,
      setArray,
      '8-10 c 177',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
  } else if (groupName === '7-10 d 143') {
    myArrayVerification(
      myArray,
      setArray,
      '8-10 d 178',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
  } else if (groupName === '7-10 e 144') {
    myArrayVerification(
      myArray,
      setArray,
      '8-10 e 179',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
  } else if (groupName === '7-11 a 145') {
    myArrayVerification(
      myArray,
      setArray,
      '8-11 a 180',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
  } else if (groupName === '7-11 b 146') {
    myArrayVerification(
      myArray,
      setArray,
      '8-11 b 181',
      AnswerCheck,
      listKeysQuestion,
      setListKeys,
      questionsSelected,
      setquestionsSelected,
      AnswerForm,
      setAnswerForm,
      AnswerQuestionForm,
      setAnswerQuestionForm
    );
  }
};

export default VerificationFaltaSistemaRespiratorio;

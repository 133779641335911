import React, { useEffect } from 'react';

import { ContainerComponent } from '../../components/Container';
import { StaticView } from '../../components/StaticView/styles';

export default function OurActivities() {
  document.title = 'Nossas Atividades';

  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <ContainerComponent>
      <StaticView>
        <h1>NOSSAS ATIVIDADES</h1>

        <p>
          O Programa de Excelência Operacional em Mergulho atua no suporte
          técnico aos padrões e procedimentos, na análise de riscos, na
          investigação de acidentes e na realização de auditorias e avaliações,
          além de acompanhar constantemente a emissão de diretivas
          internacionais relativas à atividade de mergulho. Nossas principais
          atividades são:
        </p>

        <span>
          1. Orientar as gerências do E&P e demais áreas da Companhia quanto à
          legislação, aos requisitos técnicos e às melhores práticas da
          indústria para atividade de mergulho.
        </span>

        <span>
          2. Realizar auditorias e avaliações de empresas de mergulho que
          prestam serviço para a PETROBRAS.
        </span>

        <span>
          3. Prestar suporte técnico aos padrões, procedimentos e análises de
          risco de operações com mergulho.
        </span>

        <span>
          4. Prestar suporte técnico às investigações de acidentes/incidentes
          com mergulho.
        </span>

        <span>
          5. Prestar suporte técnico quanto aos requisitos e especificações de
          embarcações/frentes de mergulho para os processos licitatórios e para
          a gestão contratual.
        </span>

        <span>
          6. Acompanhar a emissão de diretivas técnicas relevantes, bem como
          acidentes/incidentes ocorridos no mundo, de forma a assessorar a
          Companhia quanto a eventuais necessidades ou providências especiais.
        </span>

        <span>
          7. Manter contato com autoridades marítimas e fabricantes de
          equipamentos e subsistemas, fomentando a evolução dos requisitos de
          segurança na atividade de mergulho.
        </span>
      </StaticView>
    </ContainerComponent>
  );
}

import { TitlesAndQuestionsExposicaoDescargasEletricas } from "../formsModel/formExposicaoDescargasEletricas";



const myArrayVerification=(myArray,setArray,groupName,AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm)=>{
  let groupNameSplit = groupName.split(' ');
  myArray.forEach(el => {
    for(let FirstKey of Object.keys(el) ){
      for (let SecondKey of Object.keys(el[FirstKey])){
        let KeySplit = SecondKey.split(' ');
        if (groupNameSplit[0] === KeySplit[0]) {
          if(!questionsSelected.includes(groupName)){
            for(let el of listKeysQuestion[0][FirstKey]){
              if (Object.keys(el)[0] === groupNameSplit[0]) {
                let dict = {};
                dict[groupName] = AnswerCheck;
                AnswerForm.push(dict);
                setAnswerForm([...AnswerForm]);
                AnswerQuestionForm.push({tagQuestion:groupName,question:TitlesAndQuestionsExposicaoDescargasEletricas[FirstKey][groupName].Question})
                setAnswerQuestionForm([...AnswerQuestionForm])

                el[groupNameSplit[0]]['Check'].push(AnswerCheck);
                questionsSelected.push(groupName);
                setListKeys([...listKeysQuestion]);
                setquestionsSelected([...questionsSelected]);
                break;
              }
            }
          }else{
            for(let el of listKeysQuestion[0][FirstKey] ){
              if (Object.keys(el)[0] === groupNameSplit[0]) {
                let dict = {};
                dict[groupName] = AnswerCheck;
                AnswerForm[questionsSelected.indexOf(groupName)]=dict;
                setAnswerForm([...AnswerForm]);
               el[groupNameSplit[0]]['Check'][el[groupNameSplit[0]]['Check'].length-1]=AnswerCheck
                setListKeys([...listKeysQuestion]);
                break;
              }
            }
          }

            break;
        }


      }

      for(let el of listKeysQuestion[0][FirstKey] ){
        if (Object.keys(el)[0] === groupNameSplit[0]) {
          if (el[groupNameSplit[0]]['Check'].includes('No') && el[groupNameSplit[0]]['Check'].includes('Yes')) {
            el[groupNameSplit[0]]['Answer'] = 'yellow';
            setListKeys([...listKeysQuestion]);
          } else if(el[groupNameSplit[0]]['Check'].includes('Yes') && !el[groupNameSplit[0]]['Check'].includes('No') ){
            el[groupNameSplit[0]]['Answer'] = 'green';
            setListKeys([...listKeysQuestion]);
          }else if(!el[groupNameSplit[0]]['Check'].includes('Yes') && el[groupNameSplit[0]]['Check'].includes('No') ){
            el[groupNameSplit[0]]['Answer'] = 'red';
            setListKeys([...listKeysQuestion]);
          } else {
            el[groupNameSplit[0]]['Answer'] = 'white';
            setListKeys([...listKeysQuestion]);
          }
        }
      }


    }

  });

      setArray([...myArray]);
}

const VerificationExposicaoDescargasEletricas= (listKeysQuestion, setListKeys,groupName,AnswerCheck,myArray,setArray,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm)=>{

    if(groupName==="1-5 a 18"){
        myArrayVerification(myArray,setArray,'2-5 a 39',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
    }else if(groupName==="1-5 b 19"){
        myArrayVerification(myArray,setArray,'2-5 b 40',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);

    }else if(groupName==="1-5 c 20"){
        myArrayVerification(myArray,setArray,'2-5 c 41',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);

    }else if(groupName==="1-5 d 21"){
        myArrayVerification(myArray,setArray,'2-5 d 42',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
        myArrayVerification(myArray,setArray,'12-1 a 194',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);

    }


}

export default VerificationExposicaoDescargasEletricas;

import React, { useEffect } from 'react';

import { ContainerComponent } from '../../components/Container';
import { StaticView } from '../../components/StaticView/styles';

import pic1 from '../../assets/card1/pic01.jpeg';

export default function Card1() {
  document.title = 'Peodive';

  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <ContainerComponent>
      <StaticView>
        <img id="regras-mergulho" src={pic1} alt="imagem-1" />
      </StaticView>
    </ContainerComponent>
  );
}

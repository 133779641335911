import { TitlesAndQuestionsExposicaoMergulhadoresContaminantes } from "../formsModel/formExposicaoMergulhadoresContaminantes";



const myArrayVerification=(myArray,setArray,groupName,AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm)=>{
  let groupNameSplit = groupName.split(' ');
  myArray.forEach(el => {
    for(let FirstKey of Object.keys(el) ){
      for (let SecondKey of Object.keys(el[FirstKey])){
        let KeySplit = SecondKey.split(' ');
        if (groupNameSplit[0] === KeySplit[0]) {
          if(!questionsSelected.includes(groupName)){
            for(let el of listKeysQuestion[0][FirstKey]){
              if (Object.keys(el)[0] === groupNameSplit[0]) {
                let dict = {};
                dict[groupName] = AnswerCheck;
                AnswerForm.push(dict);
                setAnswerForm([...AnswerForm]);
                AnswerQuestionForm.push({tagQuestion:groupName,question:TitlesAndQuestionsExposicaoMergulhadoresContaminantes[FirstKey][groupName] ? TitlesAndQuestionsExposicaoMergulhadoresContaminantes[FirstKey][groupName].Question:"Pergunta não encontrada no modelo atual"})
                setAnswerQuestionForm([...AnswerQuestionForm])

                el[groupNameSplit[0]]['Check'].push(AnswerCheck);
                questionsSelected.push(groupName);
                setListKeys([...listKeysQuestion]);
                setquestionsSelected([...questionsSelected]);
                break;
              }
            }
          }else{
            for(let el of listKeysQuestion[0][FirstKey] ){
              if (Object.keys(el)[0] === groupNameSplit[0]) {
                let dict = {};
                dict[groupName] = AnswerCheck;
                AnswerForm[questionsSelected.indexOf(groupName)]=dict;
                setAnswerForm([...AnswerForm]);
               el[groupNameSplit[0]]['Check'][el[groupNameSplit[0]]['Check'].length-1]=AnswerCheck
                setListKeys([...listKeysQuestion]);
                break;
              }
            }
          }

            break;
        }


      }

      for(let el of listKeysQuestion[0][FirstKey] ){
        if (Object.keys(el)[0] === groupNameSplit[0]) {
          if (el[groupNameSplit[0]]['Check'].includes('No') && el[groupNameSplit[0]]['Check'].includes('Yes')) {
            el[groupNameSplit[0]]['Answer'] = 'yellow';
            setListKeys([...listKeysQuestion]);
          } else if(el[groupNameSplit[0]]['Check'].includes('Yes') && !el[groupNameSplit[0]]['Check'].includes('No') ){
            el[groupNameSplit[0]]['Answer'] = 'green';
            setListKeys([...listKeysQuestion]);
          }else if(!el[groupNameSplit[0]]['Check'].includes('Yes') && el[groupNameSplit[0]]['Check'].includes('No') ){
            el[groupNameSplit[0]]['Answer'] = 'red';
            setListKeys([...listKeysQuestion]);
          } else {
            el[groupNameSplit[0]]['Answer'] = 'white';
            setListKeys([...listKeysQuestion]);
          }
        }
      }


    }

  });

      setArray([...myArray]);
}

const VerificationExposicaoMergulhadoresContaminantes= (listKeysQuestion, setListKeys,groupName,AnswerCheck,myArray,setArray,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm)=>{

    if(groupName==="4-2 a 59"){
        myArrayVerification(myArray,setArray,'6-3 a 134',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
    }else if(groupName==="4-2 b 60"){
        myArrayVerification(myArray,setArray,'6-3 b 135',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
    }else if(groupName==="4-2 c 61"){
        myArrayVerification(myArray,setArray,'6-3 c 136',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
    }else if(groupName==="4-2 d 62"){
        myArrayVerification(myArray,setArray,'6-3 d 137',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
    }else if(groupName==="4-2 e 63"){
        myArrayVerification(myArray,setArray,'6-3 e 138',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
    }else if(groupName==="4-6 a 76"){
        myArrayVerification(myArray,setArray,'6-5 a 141',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
    }else if(groupName==="4-6 d 79"){
        myArrayVerification(myArray,setArray,'6-5 d 144',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
    }else if(groupName==="4-6 e 80"){
        myArrayVerification(myArray,setArray,'6-5 e 145',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
    }else if(groupName==="4-6 f 81"){
        myArrayVerification(myArray,setArray,'6-5 f 146',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
    }else if(groupName==="4-7 a 82"){
        myArrayVerification(myArray,setArray,'5-4 a 109',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
        myArrayVerification(myArray,setArray,'6-6 a 147',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);

    }else if(groupName==="4-7 b 83"){
        myArrayVerification(myArray,setArray,'5-4 b 110',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
        myArrayVerification(myArray,setArray,'6-6 b 148',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);

    }else if(groupName==="4-7 c 84"){
        myArrayVerification(myArray,setArray,'5-4 c 111',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
        myArrayVerification(myArray,setArray,'6-6 c 149',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);

    }else if(groupName==="4-7 d 85"){
        myArrayVerification(myArray,setArray,'5-4 d 112',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
        myArrayVerification(myArray,setArray,'6-6 d 150',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);

    }else if(groupName==="4-8 a 86"){
        myArrayVerification(myArray,setArray,'5-5 a 113',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
        myArrayVerification(myArray,setArray,'6-7 a 151',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);

    }else if(groupName==="4-8 b 87"){
        myArrayVerification(myArray,setArray,'5-5 b 114',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
        myArrayVerification(myArray,setArray,'6-7 b 152',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);

    }else if(groupName==="4-9 a 88"){
        myArrayVerification(myArray,setArray,'5-6 a 115',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
        myArrayVerification(myArray,setArray,'6-8 a 153',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);

    }else if(groupName==="4-10 a 90"){
        myArrayVerification(myArray,setArray,'5-7 a 117',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
        myArrayVerification(myArray,setArray,'6-9 a 155',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);

    }else if(groupName==="4-9 b 89"){
        myArrayVerification(myArray,setArray,'5-6 b 116',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
        myArrayVerification(myArray,setArray,'6-8 b 154',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);

    }else if(groupName==="4-10 b 91"){
        myArrayVerification(myArray,setArray,'5-7 b 118',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
        myArrayVerification(myArray,setArray,'6-9 b 156',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);

    }else if(groupName==="4-10 c 92"){
        myArrayVerification(myArray,setArray,'5-7 c 119',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
        myArrayVerification(myArray,setArray,'6-9 c 157',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);

    }else if(groupName==="4-11 a 93"){
        myArrayVerification(myArray,setArray,'5-8 a 120',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
        myArrayVerification(myArray,setArray,'6-10 a 158',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);

    }else if(groupName==="4-11 b 94"){
        myArrayVerification(myArray,setArray,'5-8 b 121',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
        myArrayVerification(myArray,setArray,'6-10 b 159',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);

    }else if(groupName==="4-11 c 95"){
        myArrayVerification(myArray,setArray,'5-8 c 122',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
        myArrayVerification(myArray,setArray,'6-10 c 160',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);

    }else if(groupName==="4-11 d 96"){
        myArrayVerification(myArray,setArray,'5-8 d 123',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
        myArrayVerification(myArray,setArray,'6-10 d 161',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);

    }else if(groupName==="4-11 e 97"){
        myArrayVerification(myArray,setArray,'5-8 e 124',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
        myArrayVerification(myArray,setArray,'6-10 e 162',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);

    }else if(groupName==="4-12 b 99"){
        myArrayVerification(myArray,setArray,'5-9 b',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);

    }else if(groupName==="5-1 a 100"){
        myArrayVerification(myArray,setArray,'5-9 b 126',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);

    }else if(groupName==="5-1 b 101"){
        myArrayVerification(myArray,setArray,'6-1 b 128',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);

    }else if(groupName==="5-1 c 102"){
        myArrayVerification(myArray,setArray,'6-1 c 129',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);

    }else if(groupName==="5-1 d 103"){
        myArrayVerification(myArray,setArray,'6-1 d 130',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);

    }else if(groupName==="5-1 e 104"){
        myArrayVerification(myArray,setArray,'6-1 e 131',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);

    }else if(groupName==="5-2 a 105"){
        myArrayVerification(myArray,setArray,'6-2 a 132',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);

    }else if(groupName==="5-2 b 106"){
        myArrayVerification(myArray,setArray,'6-2 b 133',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);

    }else if(groupName==="5-3 a 107"){
        myArrayVerification(myArray,setArray,'6-4 a 139',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);

    }else if(groupName==="5-3 b 108"){
        myArrayVerification(myArray,setArray,'6-4 b 140',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);

    }


}

export default VerificationExposicaoMergulhadoresContaminantes;

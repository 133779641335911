import { toast } from 'react-toastify';

export function addUserRequest({ login, name, password, email, userLevel }) {
  return {
    type: '@addUser/ADD_USER_REQUEST',
    payload: { login, name, password, email, userLevel },
  };
}

export function addUserAddSuccess() {
  return {
    type: '@addUser/ADD_USER_RESULT',
  };
}

export function addUserFailure() {
  return {
    type: '@addUser/ADD_USER_FAILURE',
  };
}

export function deleteUserRequest({ login }) {
  return {
    type: '@user/DELETE_USER_REQUEST',
    payload: { login },
  };
}

export function deleteUserAddSuccess() {
  return {
    type: '@user/DELETE_USER_RESULT',
  };
}

export function deleteUserFailure() {
  return {
    type: '@user/DELETE_USER_FAILURE',
  };
}

export function updateUserRequest({ login, name, email }) {
  return {
    type: '@user/UPDATE_USER_REQUEST',
    payload: { login, name, email },
  };
}

export function updateUserAddSuccess() {
  toast.info('Usuário atualizado com sucesso');
  return {
    type: '@user/UPDATE_USER_RESULT',
  };
}

export function updateUserFailure() {
  return {
    type: '@user/UPDATE_USER_FAILURE',
  };
}

export function requestNewCode({ username, email }) {
  return {
    type: '@user/REQUEST_NEW_CODE',
    payload: { username, email },
  };
}

export function changePassword({ username, email, newPassword, code }) {
  return {
    type: '@user/CHANGE_PASSWORD',
    payload: { username, email, newPassword, code },
  };
}

import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DivRequestCode = styled.div`
  width: 60rem;
  height: 64rem;
  margin: 64px auto;
  border: solid 2px #00529b;
  position: relative;
  background: #e8eaef;

  img {
    width: 12.8rem;
    height: 12.8rem;
    display: flex;
    margin: 32px auto 0 auto;
    border-radius: 50px;
  }

  h2 {
    display: flex;
    margin: 20px auto 0 128px;
    color: #00529b;
  }

  form {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 28.2rem;
    left: 8.6rem;

    input {
      width: 42.7rem;
      height: 4.8rem;
      background: #fff;
      border: 0;
      border-radius: 4px;
      height: 44px;
      padding: 0 15px;
      color: #000;
      margin: 0 0 10px;

      &::placeholder {
        color: ${darken(-0.7, '#000')};
      }
    }

    input + input {
      margin-top: 3.2rem;
    }

    input + button {
      margin-top: 6.4rem;
    }

    span {
      color: #00529b;
      align-self: flex-start;
      margin: 0 0 5px;
      font-weight: bold;
    }

    button {
      margin: 9.6rem 0 0;
      height: 44px;
      background: #00529b;
      font-weight: bold;
      color: #fff;
      border: 0;
      border-radius: 4px;
      font-size: 16px;
      transition: background 0.2s;

      &:hover {
        background: ${darken(0.03, '#00529b')};
      }

      &:disabled {
        cursor: not-allowed;
      }
    }

    button + button {
      margin: 1.6rem 0 0;

      height: 44px;
      background: #b56a1e;
      font-weight: bold;
      color: #fff;
      border: 0;
      border-radius: 4px;
      font-size: 16px;
      transition: background 0.2s;

      &:hover {
        background: ${darken(0.03, '#b56a1e')};
      }
    }
  }
`;

export const DivChangePassword = styled.div`
  width: 60rem;
  height: 80rem;
  margin: 64px auto;
  border: solid 2px #00529b;
  position: relative;
  background: #e8eaef;

  img {
    width: 12.8rem;
    height: 12.8rem;
    display: flex;
    margin: 32px auto 0 auto;
    border-radius: 50px;
  }

  h2 {
    display: flex;
    margin: 20px auto 0 128px;
    color: #00529b;
  }

  form {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 28.2rem;
    left: 8.6rem;

    input {
      width: 42.7rem;
      height: 4.8rem;
      background: #fff;
      border: 0;
      border-radius: 4px;
      height: 44px;
      padding: 0 15px;
      color: #000;
      margin: 0 0 10px;

      &::placeholder {
        color: ${darken(-0.7, '#000')};
      }
    }

    input + input {
      margin-top: 3.2rem;
    }

    input + button {
      margin-top: 6.4rem;
    }

    span {
      color: #00529b;
      align-self: flex-start;
      margin: 0 0 5px;
      font-weight: bold;
    }

    button {
      margin: 9.6rem 0 0;
      height: 44px;
      background: #00529b;
      font-weight: bold;
      color: #fff;
      border: 0;
      border-radius: 4px;
      font-size: 16px;
      transition: background 0.2s;

      &:hover {
        background: ${darken(0.03, '#00529b')};
      }

      &:disabled {
        cursor: not-allowed;
      }
    }

    button + button {
      margin: 1.6rem 0 0;

      height: 44px;
      background: #b56a1e;
      font-weight: bold;
      color: #fff;
      border: 0;
      border-radius: 4px;
      font-size: 16px;
      transition: background 0.2s;

      &:hover {
        background: ${darken(0.03, '#b56a1e')};
      }
    }
  }
`;

export const HasCode = styled.div`
  display: flex;
  margin: 32px auto 0 359px;

  label {
    color: #00529b;
    font-size: 16px;
    margin-left: 12px;
  }

  input {
    display: flex;
  }
`;

const titleFormFalhaSistemaLancamento =
  'Falha no Sistema de Lançamento e Recuperação';
const titleHazardFalhaSistemaLancamento = 'Queda de Grande Altura';
const SecondIdFalhaSistemaLancamento = 'FalhaSistemaLancamento';
const listAMCOFalhaSistemaLancamento = [
  'AM 1- Falha mecânica no sistema de lançamento (ex: sistema de acoplamento, guinchos, cabos, guideframe, roldanas, orings)',
  'CO 1- Descida involuntária e queda de mergulhador ao mar, com lesões e alterações funcionais',
];
let TitlesAndQuestionsFalhaSistemaLancamento = {
  'AM 1- Falha mecânica no sistema de lançamento (ex: sistema de acoplamento, guinchos, cabos, guideframe, roldanas, orings)': {
    '1-1 a 1': {
      Question:
        'São realizados testes de comprovação de capacitação técnica dos técnicos (elétrica, mecânico, eletroeletrônica) para serem admitidos? ',
      Answer: 'white',
    },
    '1-1 b 2': {
      Question:
        ' O pessoal técnico que realiza o plano de inspeção e manutenção do LARS possui qualificação técnica comprovada documentalmente e conhecem todas as partes dos componentes que compõem o sistema LARS, tal como cabos de aço, guincho hidráulico/elétrico/pneumático, polias, cilindros hidráulicos e etc?',
      Answer: 'white',
    },
    '1-2 a 3': {
      Question:
        'Nos planos de inspeção e manutenção são contempladas as recomendações dos fabricantes dos guinchos, cabos, roldanas, válvulas de contrabalanço, cilindros hidráulicos e mangueiras que fazem parte do sistema de mergulho?',
      Answer: 'white',
    },
    '1-2 b 4': {
      Question:
        'O processo de aquisição de suprimentos está devidamente orientado a priorizar o atendimento das requisições dos itens (guinchos, cabos, roldanas, válvulas de contrabalanço, cilindros hidráulicos e mangueiras) considerados como críticos para o sistema de mergulho?',
      Answer: 'white',
    },
    '1-2 c 5': {
      Question:
        ' O Plano de Manutenção Programada do LARS é realizado periodicamente conforme o cronograma?',
      Answer: 'white',
    },
    '1-2 d 6': {
      Question:
        'O software de manutenção que monitora o sistema de mergulho e sinaliza a periodicidade sistemática e é individual da inspeção em cada equipamento?',
      Answer: 'white',
    },
    '1-2 e 7': {
      Question:
        'As pendencias geradas no software pelas inspeções são acompanhadas por indicadores?',
      Answer: 'white',
    },
    '1-2 f 8': {
      Question:
        'É realizado inspeção no equipamento quando se dá as trocas de turma pelo pessoal que está embarcando e fica documentado?',
      Answer: 'white',
    },
    '1-2 g 9': {
      Question:
        'Existe um plano de inspeção e manutenção do LARS e é realizado por pessoal qualificado? ',
      Answer: 'white',
    },
    '1-2 h 10': {
      Question:
        'O plano de manutenção e recuperação do LARS tem intervalo definido e é cumprido rigorosamente? ',
      Answer: 'white',
    },
    '1-3 a 11': {
      Question:
        'É contemplado no projeto original do LARS a existência de mecanismos limitador de final do curso da haste de acionamento dos cilindros hidráulicos do LARS?',
      Answer: 'white',
    },

    '1-3 b 12': {
      Question:
        'Existe mecanismo limitador de final do curso da haste de acionamento nos cilindros de acionamento do A-FRAME do LARS?',
      Answer: 'white',
    },
    '1-3 c 13': {
      Question:
        'É realizado a abertura do ângulo máximo do pórtico durante os testes pré-mobilização?',
      Answer: 'white',
    },
    '1-4 a 14': {
      Question:
        'As certificações dos equipamentos do LARS, CSSM (Certificado de Segurança de Sistema de Mergulho) é realizada de acordo com a legislação vigente (NORMAM-15)?',
      Answer: 'white',
    },
    '1-4 b 15': {
      Question:
        'Há um setor responsável e habilitado na empresa que faz o acompanhamento do prazo de validade de cada LARS certificado nas diversas frentes operacionais de mergulho?',
      Answer: 'white',
    },
    '1-4 c 16': {
      Question: 'É antecipado o prazo das recertificações que estão a vencer?',
      Answer: 'white',
    },
    '1-5 a 17': {
      Question:
        'É realizado testes de abertura máxima dos cilindros de acionamento ou de abertura de A-FRAME durante os testes de certificação? ',
      Answer: 'white',
    },
    '1-5 b 18': {
      Question:
        'É realizado testes de abertura máxima dos cilindros de acionamento ou de abertura de A-FRAME durante os testes pré-operacionais? ',
      Answer: 'white',
    },
    '1-5 c 19': {
      Question:
        'Existe inspeção técnica pós instalação do LARS no local de operação de mergulho feita por um responsável técnico da empresa e está no procedimento?',
      Answer: 'white',
    },
    '1-5 d 20': {
      Question:
        'Os técnicos (mecânicos, eletrônicos, elétricos) recebem treinamento nos equipamentos que compõe o LARS?',
      Answer: 'white',
    },
    '1-5 e 21': {
      Question:
        'As equipes de manutenção recebem treinamento de percepção de risco em equipamentos e materiais? ',
      Answer: 'white',
    },
    '1-5 f 22': {
      Question:
        'Durante o processo que antecede a aceitação do LARS é realizado inspeção dos equipamentos por empresa idônea devidamente credenciada pela Marinha?',
      Answer: 'white',
    },
  },
  'AM2-Falha do suprimento de alimentação: elétrica, hidráulica e pneumática': {
    '2-1 a 23': {
      Question:
        'Na execução do projeto do sistema de mergulho é previsto a redundância da fonte de alimentação do LARS? ',
      Answer: 'white',
    },
    '2-1 b 24': {
      Question:
        'São realizados testes periódicos no sistema de fornecimento de eletricidade de emergência que supre o sistema de mergulho?',
      Answer: 'white',
    },
    '2-1 c 25': {
      Question:
        'No caso da utilização de gerador de emergência exclusivo este está inserido no plano de manutenção periódica?',
      Answer: 'white',
    },
    '2-1 d 26': {
      Question:
        'O procedimento operacional da empresa contempla testes simulados com a falha do suprimento principal?',
      Answer: 'white',
    },
    '2-1 e 27': {
      Question:
        'São realizados simulados de mergulho com a falha no suprimento principal?',
      Answer: 'white',
    },
    '2-2 a 28': {
      Question:
        'É contemplado no projeto de utilização do LARS o volume de ar suficiente para atender com redundância toda a demanda operacional?',
      Answer: 'white',
    },
    '2-2 b 29': {
      Question:
        'É calculado o volume de ar utilizado no LARS com estoque suficiente?',
      Answer: 'white',
    },
    '2-2 c 30': {
      Question:
        'São realizados testes simulados em todas as frentes de mergulho (FUEP / SDSV) com perda da alimentação principal?',
      Answer: 'white',
    },
    '2-2 d 31': {
      Question:
        'Se a resposta da pergunta anterior for (sim), os resultados dos testes simulados são satisfatórios e garantem o recolhimento da cesta sino até a superfície?',
      Answer: 'white',
    },
    '2-2 e 32': {
      Question:
        ' Há disponibilidade de 1 (um) rack de ar comprimido nas frentes operacionais exclusivamente como back up do sistema LARS?',
      Answer: 'white',
    },
    '2-3 a 33': {
      Question:
        ' Há redundância no sistema hidráulico de acionamento dos equipamentos que compõem o LARS?',
      Answer: 'white',
    },
    '2-3 b 34': {
      Question: 'O setor de manutenção realiza auditorias periódicas no LARS?',
      Answer: 'white',
    },
    '2-3 c 35': {
      Question:
        'O Programa de Manutenção Planejado (PMP) da empresa contempla uma periodicidade que garanta a integridade e a eficácia do sistema hidráulico do LARS?',
      Answer: 'white',
    },
    '2-3 d 36': {
      Question: 'Há um responsável técnico pelo sistema hidráulico do LARS?',
      Answer: 'white',
    },
    '2-4 a 37': {
      Question:
        'Existe um item no check list que menciona a verificação do status do barramento da unidade em que se encontra a alimentação elétrica do sistema de mergulho? ',
      Answer: 'white',
    },
    '2-4 b 38': {
      Question:
        ' No projeto de montagem do sistema de mergulho na unidade é contemplada a verificação da capacidade de fornecimento elétrico do barramento?',
      Answer: 'white',
    },
    '2-4 c 39': {
      Question:
        'Quando em operações de mergulho nas FUEPs, em que a alimentação elétrica é fornecida pela unidade, fica claro e explicitado na pauta da reunião pré-operacional a responsabilidade de garantir a condição proposta?',
      Answer: 'white',
    },
    '2-4 d 40': {
      Question:
        'Há na equipe de mergulho um técnico eletricista capacitado para acompanhar e que entende como é feita essa alimentação elétrica?',
      Answer: 'white',
    },
    '2-5 a 41': {
      Question:
        'No plano de manutenção é contemplado a periodicidade de inspeção, substituição, reparos das mangueiras e comandos hidráulicos cumprindo os prazos planejados?',
      Answer: 'white',
    },
    '2-5 b 42': {
      Question:
        'Existe processo de acompanhamento nas frentes de mergulho que garanta o suprimento imediato e reposição dos itens críticos do sistema de lançamento e recuperação dos mergulhadores?',
      Answer: 'white',
    },
    '2-5 c 43': {
      Question:
        'O fornecedor de equipamentos e peças (comandos e mangueiras hidráulicos, elétrica e eletrônicos) é credenciado e existe uma sistemática de rastreabilidade de certificação das peças?',
      Answer: 'white',
    },
    '2-5 d 44': {
      Question:
        'Existe procedimento de troca periódica do fluido hidráulico do sistema de lançamento e recolhimento do LARS?',
      Answer: 'white',
    },
    '2-5 e 45': {
      Question:
        'É realizado periodicamente a análise em laboratório ou substituição completa dos fluidos hidráulicos do LARS?',
      Answer: 'white',
    },
    '2-6 a 46': {
      Question:
        'É exigido documentos que comprovem a qualificação técnica da equipe de manutenção do LARS?',
      Answer: 'white',
    },
    '2-6 b 47': {
      Question:
        'São realizados testes de comprovação de capacitação técnica para ser admitido na função (Mecânica, elétrica, eletrônica)?',
      Answer: 'white',
    },
    '2-6 c 48': {
      Question:
        'O sistema de recrutamento avalia a capacidade técnica do candidato ao cargo de técnico em (elétrica, eletrônica, mecânica), levando-se em conta a peculiaridade do LARS e a responsabilidade de um sistema de lançamento e recolhimento humano?',
      Answer: 'white',
    },
    '2-6 d 49': {
      Question:
        'A empresa promove treinamentos visando capacitar a equipe de manutenção levando em consideração e expondo o número de eventos indesejados que já ocorreram no mercado do mergulho em relação a falhas do LARS?',
      Answer: 'white',
    },
    '2-7 a 50': {
      Question:
        'No check list pré-operacional estão contemplados os testes funcionais pré-operação em todo o sistema de lançamento e recolhimento dos mergulhadores?',
      Answer: 'white',
    },
    '2-7 b 51': {
      Question:
        'Existe IT (Instrução de Trabalho) especifica para operar o equipamento LARS?',
      Answer: 'white',
    },
    '2-7 c 52': {
      Question:
        'É realizado check visual em todos os equipamentos que compõe o sistema de lançamento e recolhimento dos mergulhadores?',
      Answer: 'white',
    },
    '2-7 d 53': {
      Question:
        'O check list pré-operacional de mergulho é atualizado de acordo com as normas (NORMAN-15, NRs)?',
      Answer: 'white',
    },
    '2-7 e 54': {
      Question:
        'O check list pré-operacional está de acordo com o procedimento operacional de mergulho e está contemplado no cronograma de VCP (Verificação de conformidade do Procedimento) as revisões?',
      Answer: 'white',
    },
  },
  'AM 3-Falha de projeto': {
    '3-1 a 55': {
      Question:
        'É contemplado no book de documentos que compõe o sistema de mergulho a FMEA (Análise de Modos e Efeitos de Falhas)?',
      Answer: 'white',
    },
    '3-1 b 56': {
      Question:
        'É realizado o estudo da FMEA (Análise de Modos e Efeitos de Falhas) durante a fase de elaboração do projeto?',
      Answer: 'white',
    },
    '3-1 c 57': {
      Question:
        'Foram elaborados FMEA (Análise de Modos e Efeitos de Falhas) para todos os projetos que contemplam modificações do LARS?',
      Answer: 'white',
    },
    '3-2 a 58': {
      Question:
        'É realizado teste de capacidade de carga no sistema de lançamento e recolhimento do mergulhador e está contemplado no CSSM (Certificado de Segurança de Sistema de Mergulho)?',
      Answer: 'white',
    },
    '3-2 b 59': {
      Question:
        'Em todas as frentes operacionais o sistema de polia é equipado com o recurso para evitar a saída do cabo da roldana?',
      Answer: 'white',
    },
    '3-3 a 60': {
      Question:
        'É verificado se é contemplado no estudo de projeto o dimensional, capacidade de carga e material dos componentes do LARS?',
    },
    '3-3 b 61': {
      Question:
        'Durante a montagem do equipamento de mergulho na unidade marítima é observado o Estudo de Projeto?',
      Answer: 'white',
    },
    '3-4 a 62': {
      Question:
        'Todos os CSSM (Certificado de Segurança de Sistema de Mergulho) estão dentro do seu prazo de validade?',
      Answer: 'white',
    },
    '3-4 b 63': {
      Question:
        'Existe algum setor específico na empresa que faça este controle dos CSSM (Certificado de Segurança de Sistema de Mergulho) das diversas frentes operacionais?',
      Answer: 'white',
    },
    '3-5 a 64': {
      Question:
        'O projeto original do sistema de mergulho contempla a redundância dos equipamentos de içamento do sino/sinete/cesta?',
      Answer: 'white',
    },
    '3-5 b 65': {
      Question:
        'O sistema de lançamento e recuperação do sino/sinete/cesta é dimensionado para suportar a carga da duplicidade dos guinchos e cabos de aço?',
      Answer: 'white',
    },
    '3-5 c 66': {
      Question:
        'São realizados simulados com o sistema back up de recolhimento, tanto dos guinchos como dos cabos de recolhimento?',
      Answer: 'white',
    },
  },
  'AM 4-Erro operacional no lançamento / recuperação': {
    '4-1 a 67': {
      Question:
        'É exigido na contratação documentos que qualificam tecnicamente os operadores de LARS (DIVE TECH)?',
      Answer: 'white',
    },
    '4-1 b 68': {
      Question:
        'São realizados testes de comprovação da capacitação técnica do (DIVE TECH) no processo de contratação?',
      Answer: 'white',
    },
    '4-1 c 69': {
      Question:
        'Todos os colaboradores de uma equipe de mergulho são capacitados para operar o guincho?',
      Answer: 'white',
    },
    '4-1 d 70': {
      Question:
        'Há algum programa interno para treinar os novos colaboradores a serem capacitados a operar o guincho?',
      Answer: 'white',
    },
    '4-1 e 71': {
      Question:
        'Há procedimento de como deve ser executada a manobra de entrada e saída da água?',
      Answer: 'white',
    },
    '4-2 a 72': {
      Question:
        'Durante o planejamento da tarefa é levado em consideração a condição meteoceanográficos da área de operação?',
      Answer: 'white',
    },
    '4-2 b 73': {
      Question:
        'Para o início do mergulho o supervisor de mergulho verifica junto ao oficial do passadiço as condições meteoceanográficos do momento?',
      Answer: 'white',
    },
    '4-2 c 74': {
      Question:
        'São analisadas as previsões dos boletins meteoceanográficos com antecedência regularmente   prevendo as condições que serão encontradas nos dias subsequentes?',
      Answer: 'white',
    },
    '4-2 d 75': {
      Question:
        'É respeitado o limite de altura de swell ao lançar a cesta/sinete, principalmente de FUEP?',
      Answer: 'white',
    },
    '4-3 a 76': {
      Question:
        'Na área onde estará sendo realizado o mergulho é sinalizada e informada via rádio abrangendo toda a embarcação?',
      Answer: 'white',
    },
    '4-3 b 77': {
      Question:
        'Durante o planejamento da tarefa é exigida a participação de um representante do mergulho acompanhando?',
      Answer: 'white',
    },
    '4-3 c 78': {
      Question:
        'Em caso de operações simultâneas, há a realização de reunião pré-operacional que garanta as condições adequadas para lançamento e recolhimento dos mergulhadores, com sua respectiva ATA com todos os participantes envolvidos?',
      Answer: 'white',
    },
    '4-4 a 79': {
      Question:
        'O check list do sistema de lançamento e recuperação do mergulhador é chancelado pelo responsável técnico de mergulho?',
      Answer: 'white',
    },
    '4-4 b 80': {
      Question:
        'No check list é contemplado o funcionamento individual dos equipamentos que compõe o sistema de lançamento e recuperação do mergulhador? ',
      Answer: 'white',
    },
    '4-4 c 81': {
      Question:
        'É realizado todos os dias antes do 1º mergulho um teste de descida subida do equipamento sem estar tripulado?',
      Answer: 'white',
    },
    '4-5 a 82': {
      Question:
        'Durante o lançamento e recolhimento do mergulhador a equipe de superfície envolvida na operação é monitorada pelo supervisor através de rádio de comunicação?',
      Answer: 'white',
    },
    '4-5 b 83': {
      Question:
        'Durante as manobras de lançamento/recolhimento a comunicação entre o controle de mergulho e operador do guincho se dá através do sistema eco (repetição da mensagem pelo receptor) a fim de evitar mal-entendido?',
      Answer: 'white',
    },
    '4-5 c 84': {
      Question:
        'O observador que sinaliza ao operador do guincho tem conhecimento de sua responsabilidade em sinalizar nos momentos de entrada e saída do sinete/cesta da água?',
      Answer: 'white',
    },
    '4-5 d 85': {
      Question:
        'As baterias dos rádios portáteis são deixadas em carga durante o período de um dia para o outro?',
      Answer: 'white',
    },
    '4-6 a 86': {
      Question:
        'O supervisor e os mergulhadores são capacitados a manter o controle de velocidade durante a subida e na descida do mergulhador?',
      Answer: 'white',
    },
    '4-6 b 87': {
      Question:
        'Os mergulhadores são treinados a identificar, ajustar e operar os equipamentos que controlam a velocidade dos guinchos do sistema de lançamento e recolhimento do mergulhador?',
      Answer: 'white',
    },
  },
  'CO 1- Descida involuntária e queda de mergulhador ao mar, com lesões e alterações funcionais': {
    '5-1 a 88': {
      Question:
        'São realizados testes periódicos a fim de identificar falhas no sistema de freios primário e secundário?',
      Answer: 'white',
    },
    '5-1 b 89': {
      Question:
        'No plano de manutenção é contemplado testes com carga para testar a eficácia do freio secundário?',
      Answer: 'white',
    },
    '5-1 c 90': {
      Question:
        'Os guinchos que são utilizados são munidos com sistema de freio automático?',
      Answer: 'white',
    },
    '5-1 d 91': {
      Question:
        'Na descida de teste do sistema, que deve ser realizada antes das operações de mergulho o sinete/cesta sem estar tripulado, é realizado o teste do sistema de freios?',
      Answer: 'white',
    },
    '5-1 e 92': {
      Question:
        'Através das PMP (Plano de manutenção programada) já foi identificada a necessidade de substituir componentes do sistema de freio antes do tempo previsto?',
      Answer: 'white',
    },
    '5-2 a 93': {
      Question:
        'No projeto do LARS é contemplado o sistema secundário de recuperação do mergulhador? ',
      Answer: 'white',
    },
    '5-2 b 94': {
      Question:
        'É testado frequentemente o sistema secundário de recuperação do mergulhador a fim de garantir a sua operacionalidade?',
      Answer: 'white',
    },
    '5-2 c 95': {
      Question:
        'Para utilização do lastro como sistema secundário de recuperação do sinete/cesta são realizados testes simulados periodicamente?',
      Answer: 'white',
    },

    '5-3 a 96': {
      Question:
        'No plano de resposta a emergência da empresa é contemplado os cenários de falha do LARS?',
      Answer: 'white',
    },
    '5-3 b 97': {
      Question:
        'É implementada a utilização da VCP (Verificação de conformidade e procedimento) nos procedimentos de resposta a emergência para o cenário de falha no LARS a fim de mantê-los atualizados?',
      Answer: 'white',
    },
    '5-3 c 98': {
      Question:
        'As equipes de mergulho são treinadas e atualizadas nos procedimentos de resposta a emergência para o cenário de falha no LARS?',
      Answer: 'white',
    },
    '5-4 a 99': {
      Question:
        'O bote de resgate é operado por tripulação treinada e credenciada no seu manuseio?',
      Answer: 'white',
    },
    '5-4 b 100': {
      Question:
        'Consta no plano de manutenção o teste funcional e a manutenção periódica do bote de resgate?',
      Answer: 'white',
    },
    '5-4 c 101': {
      Question:
        'Antes de iniciar operações com mergulho é verificado e confirmado com o pessoal de náutica/cabotagem a operacionalidade do bote de resgate?',
      Answer: 'white',
    },
    '5-4 d 102': {
      Question:
        'Em UEP, na reunião pré-operacional, fica claro e definido em ata como deve ser a atuação de cada envolvido e suas respectivas ações?',
      Answer: 'white',
    },
    '5-5 a 103': {
      Question:
        'A equipe de mergulho é treinada na correta utilização da cesta de transbordo?',
      Answer: 'white',
    },
    '5-5 b 104': {
      Question:
        'A cesta de transbordo disponibilizada a bordo é submetida ao plano de manutenção periódico?',
      Answer: 'white',
    },
    '5-5 c 105': {
      Question:
        'É avaliado caso seja necessário a utilização da cesta de transbordo para resgate dos mergulhadores, que a mesma esteja funcional para o lançamento no raio de ação onde está ocorrendo a operação de mergulho?',
      Answer: 'white',
    },
    '5-5 d 106': {
      Question:
        'É definido na reunião pré-operacional em FUEP (Frente de Unidade Estacionaria de Produção) de quanto tempo será necessário na unidade para a cesta estar na água para um possível resgate?',
      Answer: 'white',
    },
    '5-6 a 107': {
      Question:
        'Existem a bordo no mínimo dois mergulhadores treinados em emergências médicas subaquáticas de acordo com a NORMAM-15? ',
      Answer: 'white',
    },
    '5-6 b 108': {
      Question:
        'Existe treinamento periódico de reciclagem em atendimento a emergências medicas subaquáticas e primeiros socorros que atende as orientações de acordo com as normas vigentes abrangendo os cenários de (ex: parada cardíaca, insuficiência respiratória, engasgo, fratura, acidente hiperbárico, etc)?',
      Answer: 'white',
    },
    '5-6 c 109': {
      Question:
        'Existem, ao menos, dois membros na equipe de mergulho capacitados e treinados em suporte básico a vida, no atendimento inicial a emergências subaquáticas, atendendo assim as normas vigentes (Normam 15)?',
      Answer: 'white',
    },
    '5-6 d 110': {
      Question:
        'É cobrado eficácia nos simulados dos planos de resposta a emergência com ênfase na comunicação adequada?',
      Answer: 'white',
    },
    '5-6 e 111': {
      Question:
        'São realizados simulados de primeiros socorros com as equipes de mergulho com ênfase na saúde dos mergulhadores?',
      Answer: 'white',
    },
    '5-7 a 112': {
      Question:
        'Há profissional de saúde de nível superior devidamente habilitado a bordo?',
      Answer: 'white',
    },
    '5-7 b 113': {
      Question:
        'O profissional de saúde é capacitado e treinado em suporte avançado de vida e atendimento a emergência subaquática conforme NORMAM-15 (capitulo 1, item 0138)?',
      Answer: 'white',
    },
    '5-8 a 114': {
      Question:
        'A embarcação possui enfermaria que atenda aos requisitos da Normam-01, da NR-30 e demais legislações pertinentes?',
      Answer: 'white',
    },
    '5-8 b 115': {
      Question:
        'A enfermaria possui quantidade de equipamentos e medicamentos que atende a lista de itens mínimos de enfermaria padronizada pela Petrobras para embarcações de mergulho?',
      Answer: 'white',
    },
    '5-9 a 116': {
      Question:
        'Os meios de comunicação com o suporte médico em terra são adequados e tem backup?',
      Answer: 'white',
    },
    '5-9 b 117': {
      Question:
        'No procedimento de contingência está previsto o plano de resposta a emergência médica com os recursos de contato telefônico ou por vídeo?',
      Answer: 'white',
    },
    '5-9 c 118': {
      Question:
        'Existe plano de atendimento médico emergencial na empresa 24 horas para atender as equipes embarcadas no offshore?',
      Answer: 'white',
    },
    '5-9 d 119': {
      Question:
        'Existe ambiente hospitalar a bordo da unidade marítima para atendimento emergencial com medicação e equipamentos médicos?',
      Answer: 'white',
    },
    '5-9 e 120': {
      Question:
        'É previsto no procedimento de emergência da empresa, antes de uma operação de mergulho, que haja a verificação de uma unidade marítima mais próxima das operações adequada a receber o mergulhador vitimado com disponibilidade de resgate aeromédico?',
      Answer: 'white',
    },
    '5-10 a 121': {
      Question:
        'Existe procedimento específico para em caso de acidente com necessidade de remoção e transporte para hospital ou clínica em terra?',
      Answer: 'white',
    },
    '5-10 b 122': {
      Question:
        'Os supervisores estão aptos a orientar os responsáveis pelo transporte de acidentados via aérea, de que após a realização de mergulho, o voo deve cumprir um limite de altitude durante o voo em cumprimento da NORMAM-15?',
      Answer: 'white',
    },
    '5-10 c 123': {
      Question:
        'O Plano de contingência garante acesso a rede hospitalar especializada e a disponibilidade de recursos para um tratamento específico até o pronto reestabelecimento do acidentado?',
      Answer: 'white',
    },
    '5-10 d 124': {
      Question:
        'Existe procedimento da empresa que propicie a remoção do acidentado embarcado até a rede hospitalar?',
      Answer: 'white',
    },
    '5-11 a 125': {
      Question:
        'É realizada uma criteriosa avaliação médica especializada antes do retorno do mergulhador ao trabalho?',
      Answer: 'white',
    },
    '5-11 b 126': {
      Question:
        'O RH da empresa acompanha o processo de retorno do acidentado as suas atividades laborais após finalizado o tratamento?',
      Answer: 'white',
    },
  },

  'CO 2-Ruptura do cabo e queda de mergulhador ao mar, com lesões e alterações funcionais (FUEPS)': {
    '6-1 a 127': {
      Question:
        'No projeto do LARS é contemplado o sistema secundário de recuperação do mergulhador?',
      Answer: 'white',
    },
    '6-1 b 128': {
      Question:
        'É testado frequentemente o sistema secundário de recuperação do mergulhador a fim de garantir a sua operacionalidade?',
      Answer: 'white',
    },
    '6-1 c 129': {
      Question:
        'Para utilização do lastro como sistema secundário de recuperação do sinete/cesta são realizados testes simulados periodicamente?',
      Answer: 'white',
    },
    '6-2 a 130': {
      Question:
        'É implementada a utilização da VCP (Verificação de conformidade e procedimento) nos documentos e planos de emergência a fim de mantê-los atualizados?',
      Answer: 'white',
    },
    '6-2 b 131': {
      Question:
        'As equipes de mergulho são treinadas e atualizadas nos procedimentos e contingências?',
      Answer: 'white',
    },
    '6-3 a 132': {
      Question:
        'O bote de resgate é operado por tripulação treinada e credenciada no seu manuseio?',
      Answer: 'white',
    },
    '6-3 b 133': {
      Question:
        'Costa no plano de manutenção o teste funcional e a manutenção periódica do bote de resgate?',
      Answer: 'white',
    },
    '6-3 c 134': {
      Question:
        'Antes de iniciar operações com mergulho é verificado e confirmado com o pessoal de náutica/cabotagem a operacionalidade do bote de resgate?',
      Answer: 'white',
    },
    '6-3 d 135': {
      Question:
        'Em FUEP, na reunião pré-operacional, fica claro e definido em ata como deve ser a atuação de cada envolvido e suas respectivas ações?',
      Answer: 'white',
    },
    '6-4 a 136': {
      Question:
        'A equipe de mergulho é treinada na correta utilização da cesta de transbordo?',
      Answer: 'white',
    },
    '6-4 b 137': {
      Question:
        'A cesta de transbordo disponibilizada a bordo é submetida ao plano de manutenção periódico?',
      Answer: 'white',
    },
    '6-4 c 138': {
      Question:
        'É avaliado caso seja necessário a utilização da cesta de transbordo para resgate dos mergulhadores, que a mesma esteja funcional para o lançamento no raio de ação onde está ocorrendo a operação de mergulho?',
      Answer: 'white',
    },
    '6-4 d 139': {
      Question:
        'É definido na reunião pré-operacional em FUEP de quanto tempo será necessário na unidade para a cesta estar na água para um possível resgate?',
      Answer: 'white',
    },
    '6-5 0': {
      Question:
        'Existem, ao menos, dois membros na equipe de mergulho capacitados e treinados em suporte básico a vida, no atendimento inicial a emergências subaquáticas, atendendo assim as normas vigentes (Normam 15)?',
      Answer: 'white',
    },
    '6-5 a 140': {
      Question:
        'Os supervisores e equipe de mergulho são capacitados e treinados em suporte básico a vida no atendimento inicial a emergência subaquática atendendo as normas vigentes?',
      Answer: 'white',
    },
    '6-5 b 141': {
      Question:
        'É cobrado eficácia nos simulados dos planos de resposta a emergência com ênfase na comunicação adequada?',
      Answer: 'white',
    },
    '6-5 c 142': {
      Question:
        'O treinamento de reciclagem em atendimento a emergências de primeiros socorros atende as orientações de acordo com as normas vigentes?',
      Answer: 'white',
    },
    '6-5 d 143': {
      Question:
        'São realizados simulados de primeiros socorros com as equipes de mergulho com ênfase na saúde dos mergulhadores?',
      Answer: 'white',
    },
    '6-6 a 144': {
      Question:
        'Há profissional de saúde de nível superior devidamente habilitado nas embarcações sob contrato?',
      Answer: 'white',
    },
    '6-6 b 145': {
      Question:
        'O profissional de saúde é capacitado e treinado em suporte avançado de vida e atendimento a emergência subaquática?',
      Answer: 'white',
    },
    '6-7 a 146': {
      Question:
        'As estruturas para primeiro atendimento no suporte avançado a vida são adequadas?',
      Answer: 'white',
    },
    '6-7 b 147': {
      Question:
        'As estruturas para primeiro atendimento no suporte avançado a vida, seguem os padrões mínimos de manutenção e seguem as normas vigentes?',
      Answer: 'white',
    },
    '6-8 a 148': {
      Question:
        'Os meios de comunicação com o suporte médico em terra são adequados e tem backup?',
      Answer: 'white',
    },
    '6-8 b 149': {
      Question:
        'No procedimento de contingência está previsto o plano de resposta a emergência médica com os recursos de contato telefônico ou por vídeo?',
      Answer: 'white',
    },
    '6-8 c 150': {
      Question:
        'Existe plano de atendimento médico emergencial na empresa 24 horas para atender as equipes embarcadas no offshore?',
      Answer: 'white',
    },
    '6-8 d 151': {
      Question:
        'Existe ambiente hospitalar a bordo da unidade marítima para atendimento emergencial com medicação e equipamentos médico?',
      Answer: 'white',
    },
    '6-9 a 152': {
      Question:
        'Existe procedimento específico para em caso de acidente com necessidade de remoção e transporte para hospital ou clínica em terra?',
      Answer: 'white',
    },
    '6-9 b 153': {
      Question:
        'Os supervisores estão aptos a orientar os responsáveis pelo transporte de acidentados via aérea, de que após a realização de mergulho, o voo deve cumprir um limite de altitude durante o voo em cumprimento da NORMAM?',
      Answer: 'white',
    },
    '6-9 c 154': {
      Question:
        'O Plano de contingência garante acesso a rede hospitalar especializada e a disponibilidade de recursos para um tratamento específico até o pronto reestabelecimento do acidentado?',
      Answer: 'white',
    },
    '6-9 d 155': {
      Question:
        'Existe procedimento da empresa que propicie a remoção do acidentado embarcado até a rede hospitalar?',
      Answer: 'white',
    },
    '6-10 a 156': {
      Question:
        ' É realizada uma criteriosa avaliação médica especializada antes do retorno do mergulhador ao trabalho?',
      Answer: 'white',
    },
    '6-10 b 157': {
      Question:
        'O RH (Recursos Humanos) da empresa acompanha o processo de retorno do acidentado as suas atividades laborais após finalizado o tratamento?',
      Answer: 'white',
    },
  },

  'CO 3-Travamento do Sistema (FUEP)': {
    '7-1 a 158': {
      Question:
        'É disponibilizado guindaste da unidade na área onde está montado o sistema de mergulho?',
      Answer: 'white',
    },
    '7-1 b 159': {
      Question:
        'É contemplado no procedimento de emergência a utilização do guindaste da unidade em uma emergência?',
      Answer: 'white',
    },
    '7-1 c 160': {
      Question:
        'Há um sistema secundário de içamento disponível que garanta o recolhimento dos mergulhadores em caso de falha no sistema principal?',
      Answer: 'white',
    },
    '7-1 d 161': {
      Question:
        'Esse (s) sistema secundário passa por inspeções e testes regularmente garantindo a sua operacionalidade?',
      Answer: 'white',
    },
    '7-2 a 162': {
      Question:
        'No projeto do LARS é contemplado o sistema secundário de recuperação do mergulhador? ',
      Answer: 'white',
    },
    '7-2 b 163': {
      Question:
        'É testado frequentemente o sistema secundário de recuperação do mergulhador a fim de garantir a sua operacionalidade segura?',
      Answer: 'white',
    },
    '7-2 c 164': {
      Question: 'O sistema de recuperação secundaria está operacional?',
      Answer: 'white',
    },
    '7-3 a 165': {
      Question:
        'O umbilical do sitema de lançamento e recolhimento do mergulhador é configurado conforme preconiza a Normam-15?',
      Answer: 'white',
    },
    '7-3 b 166': {
      Question:
        'No plano de manutenção está contemplado a inspeção cuidadosa do umbilical do sistema de lançamento e recolhimento do mergulhador?',
      Answer: 'white',
    },
    '7-4 a 167': {
      Question:
        'O bote de resgate é operado por tripulação treinada e credenciada no seu manuseio?',
      Answer: 'white',
    },
    '7-4 b 168': {
      Question:
        'Costa no plano de manutenção o teste funcional e a manutenção periódica do bote de resgate?',
      Answer: 'white',
    },
    '7-4 c 169': {
      Question:
        'Antes de iniciar operações com mergulho é verificado e confirmado com o pessoal de náutica/cabotagem a operacionalidade do bote de resgate?',
      Answer: 'white',
    },
    '7-4 d 170': {
      Question:
        'Em FUEP, na reunião pré-operacional, fica claro e definido em ata como deve ser a atuação de cada envolvido e suas respectivas ações?',
      Answer: 'white',
    },
    '7-5 a 171': {
      Question:
        'A equipe de mergulho é treinada na correta utilização da cesta de transbordo?',
      Answer: 'white',
    },
    '7-5 b 172': {
      Question:
        'A cesta de transbordo disponibilizada a bordo é submetida ao plano de manutenção periódico?',
      Answer: 'white',
    },
    '7-5 c 173': {
      Question:
        'É avaliado caso seja necessário a utilização da cesta de transbordo para resgate dos mergulhadores, que a mesma esteja funcional para o lançamento no raio de ação onde está ocorrendo a operação de mergulho?',
      Answer: 'white',
    },
    '7-5 d 174': {
      Question:
        'É definido na reunião pré-operacional em FUEP de quanto tempo será necessário na unidade para a cesta estar na água para um possível resgate?',
      Answer: 'white',
    },
    '7-6 a 175': {
      Question:
        ' É implementada a utilização da VCP (Verificação de conformidade e procedimento) nos documentos e planos de emergência a fim de mantê-los atualizados?',
      Answer: 'white',
    },
    '7-6 b 176': {
      Question:
        'As equipes de mergulho são treinadas e atualizadas nos procedimentos e contingências?',
      Answer: 'white',
    },
    '7-7 0': {
      Question:
        'Existem, ao menos, dois membros na equipe de mergulho capacitados e treinados em suporte básico a vida, no atendimento inicial a emergências subaquáticas, atendendo assim as normas vigentes (Normam 15)?',
      Answer: 'white',
    },
    '7-7 a 177': {
      Question:
        'Os supervisores e equipe de mergulho são capacitados e treinados em suporte básico a vida no atendimento inicial a emergência subaquática atendendo as normas vigentes?',
      Answer: 'white',
    },
    '7-7 b 178': {
      Question:
        'É cobrado eficácia nos simulados dos planos de resposta a emergência com ênfase na comunicação adequada?',
      Answer: 'white',
    },
    '7-7 c 179': {
      Question:
        'O treinamento de reciclagem em atendimento a emergências de primeiros socorros atende as orientações de acordo com as normas vigentes?',
      Answer: 'white',
    },
    '7-7 d 180': {
      Question:
        'São realizados simulados de primeiros socorros com as equipes de mergulho com ênfase na saúde dos mergulhadores?',
      Answer: 'white',
    },
    '7-8 a 181': {
      Question:
        'Há profissional de saúde de nível superior devidamente habilitado nas embarcações sob contrato?',
      Answer: 'white',
    },
    '7-8 b 182': {
      Question:
        'O profissional de saúde é capacitado e treinado em suporte avançado de vida e atendimento a emergência subaquática?',
      Answer: 'white',
    },
    '7-9 a 183': {
      Question:
        'As estruturas para primeiro atendimento no suporte avançado a vida são adequadas?',
      Answer: 'white',
    },
    '7-9 b 184': {
      Question:
        'As estruturas para primeiro atendimento no suporte avançado a vida, seguem os padrões mínimos de manutenção e seguem as normas vigentes?',
      Answer: 'white',
    },
    '7-10 a 185': {
      Question:
        'Os meios de comunicação com o suporte médico em terra são adequados e tem backup?',
      Answer: 'white',
    },
    '7-10 b 186': {
      Question:
        'No procedimento de contingência está previsto o plano de resposta a emergência médica com os recursos de contato telefônico ou por vídeo?',
      Answer: 'white',
    },
    '7-10 c 187': {
      Question:
        'Existe plano de atendimento médico emergencial na empresa 24 horas para atender as equipes embarcadas no offshore?',
      Answer: 'white',
    },
    '7-10 d 188': {
      Question:
        'Existe ambiente hospitalar a bordo da unidade marítima para atendimento emergencial com medicação e equipamentos médico?',
      Answer: 'white',
    },
    '7-11 a 189': {
      Question:
        'Existe procedimento específico para em caso de acidente com necessidade de remoção e transporte para hospital ou clínica em terra?',
      Answer: 'white',
    },
    '7-11 b 190': {
      Question:
        'Os supervisores estão aptos a orientar os responsáveis pelo transporte de acidentados via aérea, de que após a realização de mergulho, o voo deve cumprir um limite de altitude durante o voo em cumprimento da NORMAM?',
      Answer: 'white',
    },
    '7-11 c 191': {
      Question:
        'Existe procedimento da empresa que propicie a remoção do acidentado embarcado até a rede hospitalar?',
      Answer: 'white',
    },
    '7-12 a 192': {
      Question:
        'É realizada uma criteriosa avaliação médica especializada antes do retorno do mergulhador ao trabalho?',
      Answer: 'white',
    },
    '7-12 b 193': {
      Question:
        'O RH da empresa acompanha o processo de retorno do acidentado as suas atividades laborais após finalizado o tratamento?',
      Answer: 'white',
    },
  },
};

let listKeysQuestionFalhaSistemaLancamento = [
  {
    'AM 1- Falha mecânica no sistema de lançamento (ex: sistema de acoplamento, guinchos, cabos, guideframe, roldanas, orings)': [
      { '1-1': { Check: [], Answer: 'white' } },
      { '1-2': { Check: [], Answer: 'white' } },
      { '1-3': { Check: [], Answer: 'white' } },
      { '1-4': { Check: [], Answer: 'white' } },
      { '1-5': { Check: [], Answer: 'white' } },
    ].reverse(),
    'AM2-Falha do suprimento de alimentação: elétrica, hidráulica e pneumática': [
      { '2-1': { Check: [], Answer: 'white' } },
      { '2-2': { Check: [], Answer: 'white' } },
      { '2-3': { Check: [], Answer: 'white' } },
      { '2-4': { Check: [], Answer: 'white' } },
      { '2-5': { Check: [], Answer: 'white' } },
      { '2-6': { Check: [], Answer: 'white' } },
      { '2-7': { Check: [], Answer: 'white' } },
    ].reverse(),
    'AM 3-Falha de projeto': [
      { '3-1': { Check: [], Answer: 'white' } },
      { '3-2': { Check: [], Answer: 'white' } },
      { '3-3': { Check: [], Answer: 'white' } },
      { '3-4': { Check: [], Answer: 'white' } },
      { '3-5': { Check: [], Answer: 'white' } },
    ].reverse(),
    'AM 4-Erro operacional no lançamento / recuperação': [
      { '4-1': { Check: [], Answer: 'white' } },
      { '4-2': { Check: [], Answer: 'white' } },
      { '4-3': { Check: [], Answer: 'white' } },
      { '4-4': { Check: [], Answer: 'white' } },
      { '4-5': { Check: [], Answer: 'white' } },
      { '4-6': { Check: [], Answer: 'white' } },
    ].reverse(),
    'CO 1- Descida involuntária e queda de mergulhador ao mar, com lesões e alterações funcionais': [
      { '5-1': { Check: [], Answer: 'white' } },
      { '5-2': { Check: [], Answer: 'white' } },
      { '5-3': { Check: [], Answer: 'white' } },
      { '5-4': { Check: [], Answer: 'white' } },
      { '5-5': { Check: [], Answer: 'white' } },
      { '5-6': { Check: [], Answer: 'white' } },
      { '5-7': { Check: [], Answer: 'white' } },
      { '5-8': { Check: [], Answer: 'white' } },
      { '5-9': { Check: [], Answer: 'white' } },
      { '5-10': { Check: [], Answer: 'white' } },
      { '5-11': { Check: [], Answer: 'white' } },
    ],
    'CO 2-Ruptura do cabo e queda de mergulhador ao mar, com lesões e alterações funcionais (FUEPS)': [
      { '6-1': { Check: [], Answer: 'white' } },
      { '6-2': { Check: [], Answer: 'white' } },
      { '6-3': { Check: [], Answer: 'white' } },
      { '6-4': { Check: [], Answer: 'white' } },
      { '6-5': { Check: [], Answer: 'white' } },
      { '6-6': { Check: [], Answer: 'white' } },
      { '6-7': { Check: [], Answer: 'white' } },
      { '6-8': { Check: [], Answer: 'white' } },
      { '6-9': { Check: [], Answer: 'white' } },
      { '6-10': { Check: [], Answer: 'white' } },
    ],
    'CO 3-Travamento do Sistema (FUEP)': [
      { '7-1': { Check: [], Answer: 'white' } },
      { '7-2': { Check: [], Answer: 'white' } },
      { '7-3': { Check: [], Answer: 'white' } },
      { '7-4': { Check: [], Answer: 'white' } },
      { '7-5': { Check: [], Answer: 'white' } },
      { '7-6': { Check: [], Answer: 'white' } },
      { '7-7': { Check: [], Answer: 'white' } },
      { '7-8': { Check: [], Answer: 'white' } },
      { '7-9': { Check: [], Answer: 'white' } },
      { '7-10': { Check: [], Answer: 'white' } },
      { '7-11': { Check: [], Answer: 'white' } },
      { '7-12': { Check: [], Answer: 'white' } },
    ],
  },
];

export {
  TitlesAndQuestionsFalhaSistemaLancamento,
  listKeysQuestionFalhaSistemaLancamento,
  titleFormFalhaSistemaLancamento,
  titleHazardFalhaSistemaLancamento,
  SecondIdFalhaSistemaLancamento,
  listAMCOFalhaSistemaLancamento,
};

import React, { useEffect } from 'react';

import { ContainerComponent } from '../../components/Container';
import { StaticView } from '../../components/StaticView/styles';

// import pic1 from '../../assets/card2/pic01.png';
// import pic2 from '../../assets/card2/pic02.png';
// import pic3 from '../../assets/card2/pic03.png';
// import pic4 from '../../assets/card2/pic04.png';
// import pic5 from '../../assets/card2/pic05.png';
// import pic6 from '../../assets/card2/pic06.png';
import diverlessImage from '../../assets/card2/diverlessImage.png';

export default function Card2() {
  document.title = 'Peodive';

  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <ContainerComponent>
      <StaticView>
        <h2>MERGULHO NA PETROBRAS: UM OLHAR PARA O FUTURO</h2>

        <p>
          Com o advento da exploração de petróleo em águas cada vez mais
          profundas, o mergulho humano deixou de ser opção para as operações no
          fundo do mar. Nesse contexto, tecnologias diverless têm sido bastante
          desenvolvidas nas últimas duas décadas, principalmente por meio do uso
          de ROVs (Remotely Operated Vehicles), AUVs (Autonomous Underwater
          Vehicle) e dispositivos similares. O desenho dos projetos submarinos
          também foi modificado para não depender mais de mergulhadores. No
          entanto, o mergulho continuou sendo uma opção amplamente utilizada
          para atividades próximas à superfície, principalmente no Brasil.
          <br />
          <br />
          O mergulho é reconhecido pela Organização Internacional do Trabalho
          como a segunda profissão mais perigosa do mundo, sendo superado apenas
          pelos astronautas. No entanto, há uma grande diferença entre estas
          duas atividades no que diz respeito aos controles de segurança.
          Cientes disso, podemos dizer que hoje um dos nossos maiores desafios é
          reduzir a exposição humana ao risco em operações submarinas rasas.
          Ainda não é o caso de dizer que podemos eliminar completamente o
          mergulho humano por tecnologias diverless, porque não é viável e há
          atividades em que a habilidade e o julgamento humanos ainda são
          necessários. O que podemos fazer é substituir o mergulho em tarefas
          simples e manuais, as quais os robôs podem realmente fazer melhor,
          tais como inspeção visual e limpeza de cascos, risers e equipamentos
          submarinos. Existem diversas tecnologias disponíveis no mercado para
          isso, mas ainda há muito espaço para melhorias.
          <br />
          <br />
          Por falar em novas tecnologias, já que estamos reduzindo, mas não
          eliminando o mergulho, por que não trazer novas tecnologias para
          aumentar a segurança no mergulho? Você já parou para pensar que o
          mergulho tem sido feito quase da mesma forma nas últimas duas ou três
          décadas? Por exemplo, existem muitas tecnologias disponíveis hoje para
          jogos baseados em Simuladores de Realidade Virtual (VR). Existem
          também todos os tipos de monitoramento de sinais biológicos para
          atletas de alto rendimento. Mas os mergulhadores, que passam por
          muitas tarefas arriscadas e desafiadoras debaixo d'água, muitas vezes
          com alta demanda de esforço físico, não são devidamente treinados para
          isso ou monitorados fisicamente durante as operações. E, claro, também
          precisamos trabalhar de maneira adequada para garantir a adequação e
          confiabilidade dos equipamentos de mergulho, porque eles são
          responsáveis por manter a vida do mergulhador em um ambiente que não
          foi feito para a sobrevivência humana.
          <br />
          <br />
          <img
            id="diverless"
            src={diverlessImage}
            alt="Programa Sinergias Diverless"
          />
        </p>
      </StaticView>
    </ContainerComponent>
  );

  // return (
  //   <ContainerComponent>
  //     <StaticView>
  //       <DivLogo>
  //         <img src={Logo} alt="logo-peodive" />
  //       </DivLogo>

  //       <h2>Fatos sobre mergulho e segurança na Petrobras</h2>

  //       <SpaceBetweenTitle />

  //       <p>
  //         Atividades de mergulho profissional na Petrobras são necessárias
  //         atualmente para realizar interligação, operação, inspeção, manutenção
  //         e desmobilização de sistemas de produção. São operações que envolvem
  //         grande número de pessoas, além de um complexo planejamento e logística
  //         de materiais.
  //         <br />
  //         <br />
  //         Em 2018, foi realizado mais de 31 mil mergulhos, sendo que apenas 3%
  //         desses eram profundos, de até 300 m, chamados de mergulho saturados.
  //         Os outros 97% foram mergulhos rasos, na região de variação de maré,
  //         com até, aproximadamente, 30 metros de profundidade.
  //         <br />
  //         <br />
  //         <img src={pic1} alt="imagem-1" />
  //         <br />
  //         <br />
  //         Estudos preliminares já apontam a possibilidade de substituir mais de
  //         50% das atividades com uso de novas metodologias e tecnologias. Esses
  //         números estão sob análise e ações para realização de testes de campo
  //         das tecnologias, com algumas já em andamento e outras previstas para o
  //         próximo ano.
  //         <br />
  //         <br />
  //         <img src={pic2} alt="imagem-2" />
  //         <br />
  //         <br />
  //         Um dos objetivos é retirar o homem de tarefas de alta periculosidade,
  //         permitir operações em condições meteoceanográficas mais adversas, com
  //         maior produtividade, além de reduzir operações simultâneas e permitir
  //         redução do tempo de mergulho quando necessário. Um dos marcos do
  //         Programa prevê a redução de 40% na utilização de mergulho até 2021.
  //         <br />
  //         <br />
  //         <img src={pic3} alt="imagem-3" />
  //         <br />
  //         <br />
  //         Houve uma redução de 40% o tempo médio de inspeções de dutos flexíveis
  //         em região rasa, devido à otimização do processo de inspeção. Para
  //         isso, houve análise de engenharia que possibilitou restringir o escopo
  //         de inspeção, com revisão de procedimentos operacionais de inspeção
  //         realizada em junho de 2019.
  //         <br />
  //         <br />
  //         <img src={pic4} alt="imagem-3" />
  //         <br />
  //         <br />
  //         Na Petrobras, utiliza - se ROVs nos serviços de observação e inspeção
  //         ou em tarefas relacionadas à garantia da produção offshore, desde a
  //         década de 1980, quando o aumento das profundidades dos campos de
  //         produção offshore ultrapassaram o limite convencional de mergulho de
  //         300m. Este ano, algumas atividades anteriormente realizadas por
  //         mergulhadores, passaram a utilizar ROVs, obtendo bons resultados na
  //         redução da exposição do homem ao risco das operações offshore. Com a
  //         análise desses dados e visualização de oportunidades, está sendo feita
  //         a abrangência do uso de ROVs para diversos procedimentos.
  //         <br />
  //         <br />
  //         <img src={pic5} alt="imagem-4" />
  //         <br />
  //         <br />
  //         Para áreas de implantação, inspeção, manutenção e descomissionamento,
  //         há tecnologias desenvolvidas pelo mercado com grau de maturidade para
  //         serem aplicadas na Petrobras, tais como ROVs elétricos, câmeras de
  //         alta definição, AUVs (da sigla em inglês Autonomous Underwater
  //         Vehicles), sistemas de monitoramento que podem diminuir em muito o uso
  //         de mergulho e crawlers*.
  //         <br />
  //         <br />
  //         <img src={pic6} alt="imagem-5" />
  //         <br />
  //         <br />
  //         *Os crawlers são robôs capazes de se manterem sobre uma parede
  //         vertical em cascos de navios, tanques e outras aplicações, através de
  //         ímãs extremamente fortes. Isso permite que o robô se mova ao longo da
  //         parede com segurança e facilidade, sem se desprender em virtude da
  //         ação das ondas nas unidades de produção.
  //       </p>

  //       <span>Fonte: Petrobras</span>

  //       <EndOfPage />
  //     </StaticView>
  //   </ContainerComponent>
  // );
}

import { TitlesAndQuestionsAprisionamentoMergulhador } from "../formsModel/formAprisionamentoMergulhador";



const myArrayVerification=(myArray,setArray,groupName,AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm)=>{
  let groupNameSplit = groupName.split(' ');
  myArray.forEach(el => {
    for(let FirstKey of Object.keys(el) ){
      for (let SecondKey of Object.keys(el[FirstKey])){
        let KeySplit = SecondKey.split(' ');
        if (groupNameSplit[0] === KeySplit[0]) {
          if(!questionsSelected.includes(groupName)){
            for(let el of listKeysQuestion[0][FirstKey]){
              if (Object.keys(el)[0] === groupNameSplit[0]) {
                let dict = {};
                dict[groupName] = AnswerCheck;
                AnswerForm.push(dict);
                setAnswerForm([...AnswerForm]);

                if(TitlesAndQuestionsAprisionamentoMergulhador[FirstKey][groupName]){
                  AnswerQuestionForm.push({tagQuestion:groupName,question:TitlesAndQuestionsAprisionamentoMergulhador[FirstKey][groupName].Question})
                setAnswerQuestionForm([...AnswerQuestionForm])

                el[groupNameSplit[0]]['Check'].push(AnswerCheck);
                questionsSelected.push(groupName);
                setListKeys([...listKeysQuestion]);
                setquestionsSelected([...questionsSelected]);
                break;
                }

              }
            }
          }else{
            for(let el of listKeysQuestion[0][FirstKey] ){
              if (Object.keys(el)[0] === groupNameSplit[0]) {
                let dict = {};
                dict[groupName] = AnswerCheck;
                AnswerForm[questionsSelected.indexOf(groupName)]=dict;
                setAnswerForm([...AnswerForm]);
               el[groupNameSplit[0]]['Check'][el[groupNameSplit[0]]['Check'].length-1]=AnswerCheck
                setListKeys([...listKeysQuestion]);
                break;
              }
            }
          }

            break;
        }


      }

      for(let el of listKeysQuestion[0][FirstKey] ){
        if (Object.keys(el)[0] === groupNameSplit[0]) {
          if (el[groupNameSplit[0]]['Check'].includes('No') && el[groupNameSplit[0]]['Check'].includes('Yes')) {
            el[groupNameSplit[0]]['Answer'] = 'yellow';
            setListKeys([...listKeysQuestion]);
          } else if(el[groupNameSplit[0]]['Check'].includes('Yes') && !el[groupNameSplit[0]]['Check'].includes('No') ){
            el[groupNameSplit[0]]['Answer'] = 'green';
            setListKeys([...listKeysQuestion]);
          }else if(!el[groupNameSplit[0]]['Check'].includes('Yes') && el[groupNameSplit[0]]['Check'].includes('No') ){
            el[groupNameSplit[0]]['Answer'] = 'red';
            setListKeys([...listKeysQuestion]);
          } else {
            el[groupNameSplit[0]]['Answer'] = 'white';
            setListKeys([...listKeysQuestion]);
          }
        }
      }


    }

  });

      setArray([...myArray]);
}

const VerificationAprisionamentoMergulhador= (listKeysQuestion, setListKeys,groupName,AnswerCheck,myArray,setArray,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm)=>{

    if(groupName==="1-1 b 2"){
        myArrayVerification(myArray,setArray,'4-1 c 75',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
    }else if(groupName==="1-1 g 7"){
        myArrayVerification(myArray,setArray,'4-1 h 80',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
    }else if(groupName==="1-4 a 16"){
        myArrayVerification(myArray,setArray,'2-2 a 34',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
        myArrayVerification(myArray,setArray,'4-2 a 81',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
    }else if(groupName==="1-4 b 17"){
        myArrayVerification(myArray,setArray,'2-2 b 35',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
        myArrayVerification(myArray,setArray,'4-2 b 82',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
    }else if(groupName==="1-4 c 18"){
        myArrayVerification(myArray,setArray,'2-2 c 36',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);

    }else if(groupName==="1-4 d 19"){
        myArrayVerification(myArray,setArray,'2-2 d 37',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
    }else if(groupName==="1-4 e 20"){
        myArrayVerification(myArray,setArray,'2-2 e 38',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
        myArrayVerification(myArray,setArray,'4-2 c 83',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);

    }else if(groupName==="1-4 f 21"){
        myArrayVerification(myArray,setArray,'2-2 f 39',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);

    }else if(groupName==="1-4 g 22"){
        myArrayVerification(myArray,setArray,'2-2 g 40',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
    }else if(groupName==="1-5 a 23"){
        myArrayVerification(myArray,setArray,'5-1 a 89',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
    }else if(groupName==="2-1 a 31"){
        myArrayVerification(myArray,setArray,'3-1 b 63',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
    }else if(groupName==="2-1 b 32"){
        myArrayVerification(myArray,setArray,'3-1 c 64',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
    }else if(groupName==="2-3 e 45"){
        myArrayVerification(myArray,setArray,'2-3 h 48',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
        myArrayVerification(myArray,setArray,'4-3 e 88',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);

    }else if(groupName==="6-6 b 123"){
        myArrayVerification(myArray,setArray,'7-6 b 140',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
    }else if(groupName==="8-1 b 143"){
        myArrayVerification(myArray,setArray,'9-1 b 179',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
        myArrayVerification(myArray,setArray,'10-1 b 211',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
    }else if(groupName==="8-1 c 144"){
        myArrayVerification(myArray,setArray,'9-1 c 180',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
        myArrayVerification(myArray,setArray,'10-1 c 212',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
    }else if(groupName==="8-1 d 145"){
        myArrayVerification(myArray,setArray,'9-1 d 181',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
        myArrayVerification(myArray,setArray,'10-1 d 213',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
    }else if(groupName==="8-4 a 155"){
        myArrayVerification(myArray,setArray,'9-4 a 189',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
        myArrayVerification(myArray,setArray,'2-2 f 39',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
    }else if(groupName==="8-4 b 156"){
        myArrayVerification(myArray,setArray,'9-4 b 190',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
        myArrayVerification(myArray,setArray,'10-4 b 226',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
    }else if(groupName==="8-4 c 157"){
        myArrayVerification(myArray,setArray,'10-4 c 227',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
    }else if(groupName==="8-5 a 160"){
        myArrayVerification(myArray,setArray,'9-5 a 192',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
        myArrayVerification(myArray,setArray,'10-5 a 230',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
    }else if(groupName==="8-5 b 161"){
        myArrayVerification(myArray,setArray,'9-5 b 193',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
        myArrayVerification(myArray,setArray,'10-5 b 231',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
    }else if(groupName==="8-5 c 162"){
        myArrayVerification(myArray,setArray,'10-5 c 232',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
    }else if(groupName==="8-5 d 163"){
        myArrayVerification(myArray,setArray,'9-5 d 195',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
    }else if(groupName==="8-6 a 164"){
        myArrayVerification(myArray,setArray,'9-6 a 196',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
        myArrayVerification(myArray,setArray,'10-6 a 234',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
    }else if(groupName==="8-6 b 165"){
        myArrayVerification(myArray,setArray,'9-6 b 197',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
        myArrayVerification(myArray,setArray,'10-6 b 235',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
    }else if(groupName==="8-7 a 166"){
        myArrayVerification(myArray,setArray,'9-7 a 198',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
        myArrayVerification(myArray,setArray,'10-7 a 236',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
    }else if(groupName==="8-7 b 167"){
        myArrayVerification(myArray,setArray,'9-7 b 199',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
        myArrayVerification(myArray,setArray,'10-7 b 237',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
    }else if(groupName==="8-8 a 168"){
        myArrayVerification(myArray,setArray,'9-8 a 200',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
        myArrayVerification(myArray,setArray,'10-8 a 238',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
    }else if(groupName==="8-8 c 170"){
        myArrayVerification(myArray,setArray,'9-8 c 202',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
        myArrayVerification(myArray,setArray,'10-8 c 240',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
    }else if(groupName==="8-9 a 171"){
        myArrayVerification(myArray,setArray,'9-9 a 203',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
        myArrayVerification(myArray,setArray,'10-9 a 241',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
    }else if(groupName==="8-9 b 172"){
        myArrayVerification(myArray,setArray,'9-9 b 204',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
        myArrayVerification(myArray,setArray,'10-9 b 242',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
    }else if(groupName==="8-9 c 173"){
        myArrayVerification(myArray,setArray,'9-9 c 205',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
        myArrayVerification(myArray,setArray,'10-9 c 243',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
    }else if(groupName==="8-9 e 175"){
        myArrayVerification(myArray,setArray,'9-9 e 207',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
        myArrayVerification(myArray,setArray,'10-9 e 245',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);

    }else if(groupName==="9-1 a 178"){
        myArrayVerification(myArray,setArray,'10-1 a 210',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);

    }else if(groupName==="9-3 d 188"){
        myArrayVerification(myArray,setArray,'10-2 g 220',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
        myArrayVerification(myArray,setArray,'10-3 d 224',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
    }else if(groupName==="10-2 d 217"){
        myArrayVerification(myArray,setArray,'10-3 a 221',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
    }else if(groupName==="10-2 e 218"){
        myArrayVerification(myArray,setArray,'10-3 b 222',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
    }


}

export default VerificationAprisionamentoMergulhador;

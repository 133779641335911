/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';

import { Document, Page, pdfjs } from 'react-pdf';
import { toast } from 'react-toastify';

import { SpinLoader } from 'react-css-loaders';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';

import { ContainerComponent } from '../../components/Container';
import { InputPage } from '../ShowPetrobrasDocument/styles';
import {
  DivSelectDocument,
  DivButtons,
  PdfContent,
  DivChangePage,
  DivAwaitSelect,
  DivSelectPage,
} from './styles';
import api from '../../services/api';

export default function __ShowChecklist() {
  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  });

  const [loading, setLoading] = useState(false);

  const [checklist, setChecklist] = useState(false);

  const [numPages, setNumPages] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);

  const changePage = {
    getPreviousPage() {
      if (pageNumber > 1) {
        setPageNumber(pageNumber - 1);
      }
    },

    getNextPage() {
      if (pageNumber < numPages) {
        setPageNumber(pageNumber + 1);
      }
    },

    jumpToPage(selectedPage) {
      if (selectedPage && selectedPage > 0 && selectedPage < numPages + 1) {
        setPageNumber(selectedPage);
      }
    },
  };

  const requestDocument = {
    async getDocument(value) {
      setLoading(true);
      api
        .get(`/audit/checklist/${value}`, { responseType: 'blob' })
        .then(response => {
          setLoading(false);
          setPageNumber(1);
          setChecklist(response.data);
        })
        .catch(error => {
          setLoading(false);
          toast.error('Erro ao exibir documento');
        });
    },
  };

  document.addEventListener('contextmenu', event => event.preventDefault());

  return (
    <ContainerComponent>
      <DivSelectDocument>
        {loading ? (
          <SpinLoader size="3" />
        ) : (
          <DivButtons>
            <button
              type="button"
              onClick={() => requestDocument.getDocument('doc1')}
            >
              LV (Mergulho Raso)
            </button>
            <button
              type="button"
              onClick={() => requestDocument.getDocument('doc2')}
            >
              LV (Mergulho Profundo)
            </button>
            <button
              type="button"
              onClick={() => requestDocument.getDocument('doc3')}
            >
              LV (Mergulho Raso LDB)
            </button>
            <button
              type="button"
              onClick={() => requestDocument.getDocument('doc4')}
            >
              LV (Gestão)
            </button>
          </DivButtons>
        )}
      </DivSelectDocument>

      {checklist ? (
        <>
          <DivSelectPage>
            <DivChangePage>
              <button
                type="button"
                onClick={() => changePage.getPreviousPage()}
              >
                <MdKeyboardArrowLeft />
              </button>

              <span>
                Página {pageNumber} de {numPages}
              </span>

              <button type="button" onClick={() => changePage.getNextPage()}>
                <MdKeyboardArrowRight />
              </button>
            </DivChangePage>

            <InputPage>
              <label htmlFor="jumpToPage">Ir para página: </label>
              <input
                name="jumpToPage"
                type="number"
                id="jumpToPage"
                min="1"
                max={numPages.toString()}
                onChange={e => changePage.jumpToPage(e.target.valueAsNumber)}
              />
            </InputPage>
          </DivSelectPage>

          <PdfContent id="pdfContent">
            <Document
              file={checklist}
              onLoadSuccess={pdf => setNumPages(pdf.numPages)}
              onLoadError={error => alert('Por favor, tente novamente')}
            >
              <Page pageNumber={pageNumber} width={1109} />
            </Document>
          </PdfContent>

          <DivChangePage>
            <button type="button" onClick={() => changePage.getPreviousPage()}>
              <MdKeyboardArrowLeft />
            </button>

            <span>
              Página {pageNumber} de {numPages}
            </span>

            <button type="button" onClick={() => changePage.getNextPage()}>
              <MdKeyboardArrowRight />
            </button>
          </DivChangePage>
        </>
      ) : (
        <DivAwaitSelect>
          <h1>Selecione um documento acima</h1>
        </DivAwaitSelect>
      )}
    </ContainerComponent>
  );
}

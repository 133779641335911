/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { SpinLoader, BubbleLoader } from 'react-css-loaders';
import api from '../../services/api';

import { searchRequest } from '../../store/modules/search/actions';

import './styleSearch.css';

import {
  Chart1,
  Chart2,
  Chart3,
  Chart4,
  Chart5,
  Chart6,
} from '../../components/Charts';
import {
  Container,
  Content,
  DivCharts,
  DivGrid,
  LargeColumn,
  SmallColumn,
  LargeChart,
  SmallChart,
  DivBox,
  DivLastAlerts,
  DivSearch,
  DivDate,
  SearchButton,
  ListLastAlerts,
  AlertTitle,
  AlertDescription,
  LoadingSearch,
} from './styles';
import { prepareSearchModel } from './util';

export default function SearchAlert() {
  document.title = 'Pesquisar Alerta';

  const dispatch = useDispatch();

  const [chartData, setChartData] = useState(false);
  const [keyWords, setKeyWords] = useState('');
  let [location, setLocation] = useState([]);
  const [causedBy, setCausedBy] = useState('');
  const [accidentDegree, setAccidentDegree] = useState('');
  const [minYear, setMinYear] = useState(1960);
  const [maxYear, setMaxYear] = useState('');

  const [isAdmin, setIsAdmin] = useState(false);
  const [isSudo, setIsSudo] = useState(false);
  const [isAuditor, setIsAuditor] = useState(false);
  const [isPetrobras, setIsPetrobras] = useState(false);

  const searchLoading = useSelector(state => state.search.searchLoading);

  const { groupLevel } = useSelector(state => state.auth);

  useEffect(() => {
    const { admin, sudo, auditor, petrobras } = groupLevel;

    setIsSudo(sudo);
    setIsAdmin(admin);
    setIsAuditor(auditor);
    setIsPetrobras(petrobras);
  }, [groupLevel]);

  useEffect(() => {
    api
      .get('chart')
      .then(response => {
        setChartData(response.data);
      })
      .catch(error => {
        alert('Erro ao consultar os dados');
      });
  }, []);

  const OnChangeLocation = e => {
    location.push(e.target.value);
    setLocation(location);
  };

  const OnChangeSelectAll = e => {
    let checkboxs = document.getElementsByName('inputLocalAtividade');
    for (let i = 0; i < checkboxs.length; i++) {
      //zero-based array
      checkboxs[i].checked = !checkboxs[i].checked;
      location.push(checkboxs[i].value);
      setLocation(location);
    }
  };
  const handlePerformSearch = useCallback(() => {
    const searchModel = {
      keyWords,
      location,
      causedBy,
      accidentDegree,
      minYear,
      maxYear,
    };

    const formattedSearchModel = prepareSearchModel(searchModel);

    if (formattedSearchModel) {
      dispatch(searchRequest(formattedSearchModel));
    }
  }, [
    accidentDegree,
    causedBy,
    dispatch,
    keyWords,
    location,
    maxYear,
    minYear,
  ]);

  return (
    <Container>
      <Content>
        <DivGrid>
          {isAdmin || isSudo || isAuditor || isPetrobras ? (
            <DivCharts>
              <SmallColumn>
                <SmallChart>
                  {chartData.chart1 ? (
                    <Chart1 chart={chartData.chart1} />
                  ) : (
                    <SpinLoader />
                  )}
                </SmallChart>
                <SmallChart>
                  {chartData.chart2 ? (
                    <Chart2 chart={chartData.chart2} />
                  ) : (
                    <SpinLoader />
                  )}
                </SmallChart>
              </SmallColumn>

              <LargeColumn>
                <LargeChart>
                  {chartData.chart3 ? (
                    <Chart3 chart={chartData.chart3} />
                  ) : (
                    <SpinLoader />
                  )}
                </LargeChart>
                <LargeChart>
                  {chartData.chart4 ? (
                    <Chart4 chart={chartData.chart4} />
                  ) : (
                    <SpinLoader />
                  )}
                </LargeChart>
              </LargeColumn>

              <SmallColumn>
                <SmallChart>
                  {chartData.chart5 ? (
                    <Chart5 chart={chartData.chart5} />
                  ) : (
                    <SpinLoader />
                  )}
                </SmallChart>
                <SmallChart>
                  {chartData.chart6 ? (
                    <Chart6 chart={chartData.chart6} />
                  ) : (
                    <SpinLoader />
                  )}
                </SmallChart>
              </SmallColumn>
            </DivCharts>
          ) : null}

          <DivBox>
            <DivSearch>
              <h2>Selecione os filtros:</h2>
              <label htmlFor="keyWords">Busca por palavra</label>
              <input
                name="keyWords"
                type="text"
                id="keyWords"
                onChange={e => setKeyWords(e.target.value)}
                placeholder="Separe palavras com ; Exemplo: Palavra1; Palavra2..."
              />

              {isAdmin || isSudo || isAuditor ? (
                <>
                  <label for="location">Local do Acidente</label>
                  <div class="d-flex">
                    <div class="form-check">
                      <input
                        onChange={OnChangeSelectAll}
                        class="form-check-input"
                        type="checkbox"
                        id="selectall"
                        name="selectall"
                        autocomplete="off"
                      />
                      <label class="form-check-label" for="flexCheckDefault">
                        Selecionar todos os itens abaixo
                      </label>
                    </div>
                  </div>
                  <div class="d-flex">
                    <div class="form-check">
                      <input
                        onChange={OnChangeLocation}
                        class="form-check-input"
                        type="checkbox"
                        value="BDC"
                        name="inputLocalAtividade"
                        id="inputLocalAtividade"
                      />
                      <label class="form-check-label" for="flexCheckDefault">
                        Bacia de Campos
                      </label>
                    </div>

                    <div class="form-check">
                      <input
                        onChange={OnChangeLocation}
                        class="form-check-input"
                        type="checkbox"
                        value="NOB"
                        name="inputLocalAtividade"
                        id="inputLocalAtividade"
                      />
                      <label class="form-check-label" for="flexCheckDefault">
                        Nordeste Brasileiro
                      </label>
                    </div>

                    <div class="form-check">
                      <input
                        onChange={OnChangeLocation}
                        class="form-check-input"
                        type="checkbox"
                        value="BRA"
                        name="inputLocalAtividade"
                        id="inputLocalAtividade"
                      />
                      <label class="form-check-label" for="flexCheckDefault">
                        Demais regiões do Brasil
                      </label>
                    </div>
                  </div>

                  <div class="d-flex">
                    <div class="form-check">
                      <input
                        onChange={OnChangeLocation}
                        class="form-check-input"
                        type="checkbox"
                        value="USA"
                        name="inputLocalAtividade"
                        id="inputLocalAtividade"
                      />
                      <label class="form-check-label" for="flexCheckDefault">
                        Estados Unidos
                      </label>
                    </div>

                    <div class="form-check">
                      <input
                        onChange={OnChangeLocation}
                        class="form-check-input"
                        type="checkbox"
                        value="GFM"
                        name="inputLocalAtividade"
                        id="inputLocalAtividade"
                      />
                      <label class="form-check-label" for="flexCheckDefault">
                        Golfo do México
                      </label>
                    </div>

                    <div class="form-check">
                      <input
                        onChange={OnChangeLocation}
                        class="form-check-input"
                        type="checkbox"
                        value="ATS"
                        name="inputLocalAtividade"
                        id="inputLocalAtividade"
                      />
                      <label class="form-check-label" for="flexCheckDefault">
                        Atlântico Sul
                      </label>
                    </div>
                  </div>

                  <div class="d-flex">
                    <div class="form-check">
                      <input
                        onChange={OnChangeLocation}
                        class="form-check-input"
                        type="checkbox"
                        value="ATN"
                        name="inputLocalAtividade"
                        id="inputLocalAtividade"
                      />
                      <label class="form-check-label" for="flexCheckDefault">
                        Atlântico Norte
                      </label>
                    </div>

                    <div class="form-check">
                      <input
                        onChange={OnChangeLocation}
                        class="form-check-input"
                        type="checkbox"
                        value="PAT"
                        name="inputLocalAtividade"
                        id="inputLocalAtividade"
                      />
                      <label class="form-check-label" for="flexCheckDefault">
                        Patagônia
                      </label>
                    </div>

                    <div class="form-check">
                      <input
                        onChange={OnChangeLocation}
                        class="form-check-input"
                        type="checkbox"
                        value="ORM"
                        name="inputLocalAtividade"
                        id="inputLocalAtividade"
                      />
                      <label class="form-check-label" for="flexCheckDefault">
                        Oriente Médio
                      </label>
                    </div>
                  </div>

                  <div class="d-flex">
                    <div class="form-check">
                      <input
                        onChange={OnChangeLocation}
                        class="form-check-input"
                        type="checkbox"
                        value="SAS"
                        name="inputLocalAtividade"
                        id="inputLocalAtividade"
                      />
                      <label class="form-check-label" for="flexCheckDefault">
                        Sudeste Asiático
                      </label>
                    </div>

                    <div class="form-check">
                      <input
                        onChange={OnChangeLocation}
                        class="form-check-input"
                        type="checkbox"
                        value="AFR"
                        name="inputLocalAtividade"
                        id="inputLocalAtividade"
                      />
                      <label class="form-check-label" for="flexCheckDefault">
                        África
                      </label>
                    </div>

                    <div class="form-check">
                      <input
                        onChange={OnChangeLocation}
                        class="form-check-input"
                        type="checkbox"
                        value="MDN"
                        name="inputLocalAtividade"
                        id="inputLocalAtividade"
                      />
                      <label class="form-check-label" for="flexCheckDefault">
                        Mar do Norte
                      </label>
                    </div>
                  </div>

                  <div class="d-flex">
                    <div class="form-check">
                      <input
                        onChange={OnChangeLocation}
                        class="form-check-input"
                        type="checkbox"
                        value="NIF"
                        name="inputLocalAtividade"
                        id="inputLocalAtividade"
                      />
                      <label class="form-check-label" for="flexCheckDefault">
                        Não Informado
                      </label>
                    </div>
                  </div>
                </>
              ) : null}

              <label htmlFor="causedBy">Causa</label>
              <select
                name="causedBy"
                id="causedBy"
                onChange={e => setCausedBy(e.target.value)}
              >
                <option value=""> </option>
                <option value="FH">Falha Humana</option>
                <option value="FE">Falha Equipamento</option>
                <option value="FH-FE">Ambos</option>
              </select>
              <label htmlFor="accidentDegree">Classificação</label>
              <select
                name="accidentDegree"
                id="accidentDegree"
                onChange={e => setAccidentDegree(e.target.value)}
              >
                <option value=""> </option>
                <option value="I">Incidente com alto potencial</option>
                <option value="AF">Acidente com lesão</option>
                <option value="F">Acidente fatal</option>
              </select>
              {isAdmin || isSudo || isAuditor ? (
                <DivDate>
                  <label htmlFor="minYear">Período</label>
                  <input
                    name="minYear"
                    type="number"
                    id="minYear"
                    onChange={e => setMinYear(e.target.value)}
                    placeholder="1960"
                    min="1960"
                    max="2020"
                  />
                  <span>até</span>

                  <input
                    name="maxYear"
                    type="number"
                    id="maxYear"
                    onChange={e => setMaxYear(e.target.value)}
                    placeholder="2020"
                    min={Number(minYear) + 1}
                    max="2020"
                  />
                </DivDate>
              ) : null}
              {!searchLoading ? (
                <SearchButton>
                  <button
                    type="button"
                    onClick={() => {
                      handlePerformSearch();
                    }}
                  >
                    Pesquisar
                  </button>
                </SearchButton>
              ) : (
                <LoadingSearch>
                  <BubbleLoader size={3} />
                </LoadingSearch>
              )}
            </DivSearch>

            <DivLastAlerts>
              <h2>Últimos alertas adicionados</h2>

              <ListLastAlerts>
                {chartData.lastFiveAdded ? (
                  chartData.lastFiveAdded.map(lastAlert => (
                    <li key={String(lastAlert._id)}>
                      <div>
                        <AlertTitle>
                          <Link to={`/alerta/${lastAlert._id}`}>
                            {lastAlert.title}
                          </Link>
                        </AlertTitle>
                      </div>
                      <AlertDescription>
                        <span>{lastAlert.description}</span>
                      </AlertDescription>
                    </li>
                  ))
                ) : (
                  <SpinLoader />
                )}
              </ListLastAlerts>
            </DivLastAlerts>
          </DivBox>
        </DivGrid>
      </Content>
    </Container>
  );
}

import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DivFeedback = styled.div`
  width: 602px;
  height: 632px;
  border: solid 2px #00529b;
  margin: 0 auto;
  border-radius: 5px;
  background: #e8eaef;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 64px;

  button {
    margin-top: 48px;
    height: 42px;
    background: #00529b;
    font-size: 14px;
  }

  @media only screen and (max-width: 768px) {
    width: 330px;
  }
`;

export const DivUnregistredUser = styled.div`
  background: #fff;
  margin-bottom: 32px;
`;

export const DivTitle = styled.div`
  display: flex;
  flex-direction: column;
  margin: 32px auto;

  h2 {
    text-align: center;
    color: #00529b;
    font-size: 32px;
  }

  h3 {
    margin-top: 64px;
    text-align: center;
    color: #b56a1e;
    font-size: 16px;
  }
`;

export const DivContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: 48px auto;

  label {
    font-size: 20px;
  }

  textarea {
    background: #fff;
    width: 480px;
    font-size: 14px;
  }

  div + button {
    margin-top: 82px;
  }

  @media only screen and (max-width: 768px) {
    textarea {
      background: #fff;
      width: 286px;
      font-size: 14px;
    }
  }
`;

export const DivAssunto = styled.div`
  background: #fff;
`;

export const DivDescription = styled.div`
  background: #fff;
  margin-top: 32px;
`;

/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { FaCloudDownloadAlt, FaTrashAlt } from 'react-icons/fa';
import { toast } from 'react-toastify';
import api from '../../services/api';
import history from '../../services/history';

import { ContainerComponent } from '../../components/Container';
import { DivAlert, DivImages, DownloadButton, DeleteButton } from './styles';

/**
 *
 * @param {*} :id (match > params > id)
 */
export default function ShowAlert({ match }) {
  document.title = 'Alerta';

  const [alert, setAlert] = useState([]);
  const [alertId, setAlertId] = useState([]);
  const [images, setImages] = useState([]);
  const [pdfName, setPdfName] = useState(false);

  const alerts = useSelector(state => state.search.alertsFound);
  const { admin } = useSelector(state => state.auth.groupLevel);

  const printArray = {
    convert(array) {
      const obj = Object(array);
      return Object.values(obj).map(ob => <li key={ob}>{ob}</li>);
    },
  };

  const downloadPdf = {
    requestDownload() {
      api
        .get(`alert/files/pdf/${alertId}`, { responseType: 'blob' })
        .then(response => {
          const file = new Blob([response.data], { type: 'application/pdf' });
          const a = document.createElement('a');
          document.body.appendChild(a);
          const blobUrl = window.URL.createObjectURL(file);
          a.href = blobUrl;
          a.download = pdfName || 'Documento';
          a.click();
        })
        .catch(error => {
          toast.error('Documento não encontrado');
        });
    },
  };

  const getAlertById = useCallback(async alertKey => {
    if (alertKey) {
      const response = await api.get(`alert/one/${alertKey}`);

      if (response.status === 200) {
        setAlert(response.data);
        setAlertId(alertKey);
        setPdfName(response.data.pdfName);
      } else {
        history.push('/pesquisa');
      }
    }
  }, []);

  useEffect(() => {
    const receivedAlertId = Number(match.params.id);

    if (receivedAlertId) {
      let selectedAlert = false;

      if (alerts) {
        selectedAlert = alerts.find(x => x._id === receivedAlertId);
      }

      if (!selectedAlert) {
        getAlertById(receivedAlertId);
      } else {
        setAlertId(receivedAlertId);
        setAlert(selectedAlert);
        setPdfName(selectedAlert.pdfName);
      }

      api
        .get(`alert/files/image/${receivedAlertId}`)
        .then(response => setImages(response.data));
    }
  }, [alerts, getAlertById, match.params.id]);

  const deleteAlert = {
    async deleteAlertById() {
      const response = await api.delete(`alert/one/${alertId}`);

      const { sucess } = response.data;

      if (sucess) {
        toast.info('Alerta deletado');
        history.push('/pesquisa');
      } else {
        toast.error('Erro ao deletar alerta');
      }
    },
  };

  return (
    <ContainerComponent>
      <DivAlert>
        <h1>{alert.title}</h1>

        <DownloadButton
          type="button"
          onClick={() => downloadPdf.requestDownload()}
        >
          <FaCloudDownloadAlt />
          DOWNLOAD
        </DownloadButton>

        {admin ? (
          <DeleteButton
            type="button"
            onClick={() => deleteAlert.deleteAlertById()}
          >
            <FaTrashAlt />
            Deletar
          </DeleteButton>
        ) : null}

        <h2>O que aconteceu?</h2>
        <p>&emsp;&emsp;&emsp;{alert.description}</p>

        <h2>Por que aconteceu?</h2>
        {printArray.convert(alert.causes)}

        <h2>O que devemos fazer para evitar?</h2>
        {printArray.convert(alert.lessons_learned)}

        <h2>Referência</h2>
        {alert.ref !== 'Memória Técnica (BV)' ? (
          <a href={alert.ref}>{alert.ref}</a>
        ) : (
          <a href="#">{alert.ref}</a>
        )}

        <DivImages>
          <h1>Anexos</h1>

          {images.map(img => (
            <img src={img} alt="imagem" />
          ))}
        </DivImages>
      </DivAlert>
    </ContainerComponent>
  );
}

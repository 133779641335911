import produce from 'immer';

const INITIAL_STATE = {
  chartSearch: null,
  chartDataFromResult: false,
  alertsFound: null,
  loading: false,
  lastFiveAlerts: null,
  searchLoading: false,
};

export default function search(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@auth/SIGN_IN_SUCCESS':
      return produce(state, draft => {
        draft.chartSearch = action.payload.chartSearch;
        draft.lastFiveAlerts = action.payload.lastFiveAlerts;
      });

    case '@auth/SIGN_FAILURE':
      return produce(state, draft => {
        draft.loading = false;
      });

    case '@auth/SIGN_OUT': {
      return produce(state, draft => {
        draft.chartSearch = null;
        draft.chartDataFromResult = false;
        draft.alertsFound = null;
        draft.lastFiveAlerts = null;
        draft.loading = false;
      });
    }
    case '@search/SEARCH_REQUEST': {
      return produce(state, draft => {
        draft.searchLoading = true;
      });
    }

    case '@search/SEARCH_RESULT': {
      return produce(state, draft => {
        draft.alertsFound = action.payload.alertsFound;
        draft.searchLoading = false;
      });
    }

    case '@search/SEARCH_FAIL': {
      return produce(state, draft => {
        draft.searchLoading = false;
      });
    }

    default:
      return state;
  }
}

import React, { useEffect, useState, useCallback } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from 'react-redux';

import { lighten } from 'polished';
import api from '../../services/api';

import {
  deleteUserRequest,
  updateUserRequest,
} from '../../store/modules/user/actions';

import { ContainerComponent } from '../../components/Container';
import { DivTable } from './styles';

export default function ShowUsers() {
  document.title = 'Usuários';

  const dispatch = useDispatch();

  const { admin, sudo } = useSelector(state => state.auth.groupLevel);

  const [sudoTableState, setSudoTableState] = useState(false);
  const [normalTableState, setNormalTableState] = useState(false);
  const [companyTableState, setCompanyTableState] = useState(false);
  const [auditorTableState, setAuditorTableState] = useState(false);
  const [petrobrasTableState, setPetrobrasTableState] = useState(false);

  const apiCall = useCallback(async () => {
    const response = await api.get('users');

    if (response.status === 200) {
      const {
        sudoTable,
        normalTable,
        companyTable,
        auditorTable,
        petrobrasTable,
      } = response.data;

      if (sudoTable) {
        setSudoTableState(sudoTable);
      }

      if (normalTable) {
        setNormalTableState(normalTable);
      }

      if (companyTable) {
        setCompanyTableState(companyTable);
      }

      if (auditorTable) {
        setAuditorTableState(auditorTable);
      }

      if (petrobrasTable) {
        setPetrobrasTableState(petrobrasTable);
      }
    }
  }, []);

  useEffect(() => {
    if (admin || sudo) {
      apiCall();
    }
  }, [admin, apiCall, sudo]);

  return (
    <ContainerComponent>
      {admin && (
        <>
          <DivTable>
            <h2>Empresas</h2>
            <MaterialTable
              options={{
                headerStyle: {
                  backgroundColor: lighten(0.05, '#00529b'),
                  color: '#eee',
                  fontSize: '1.6rem',
                  fontWeight: 'bold',
                },
              }}
              title=" "
              columns={companyTableState.columns}
              data={companyTableState.data}
              editable={{
                onRowUpdate: (newData, oldData) =>
                  new Promise(resolve => {
                    if (oldData) {
                      const { login, name, email } = newData;
                      dispatch(updateUserRequest({ login, name, email }));
                    }
                    setTimeout(() => {
                      resolve();
                      if (oldData) {
                        setCompanyTableState(prevState => {
                          const data = [...prevState.data];
                          data[data.indexOf(oldData)] = newData;
                          return { ...prevState, data };
                        });
                      }
                    }, 600);
                  }),
                onRowDelete: oldData =>
                  new Promise(resolve => {
                    const { login } = oldData;
                    dispatch(deleteUserRequest({ login }));
                    setTimeout(() => {
                      resolve();
                      setCompanyTableState(prevState => {
                        const data = [...prevState.data];
                        data.splice(data.indexOf(oldData), 1);
                        return { ...prevState, data };
                      });
                    }, 600);
                  }),
              }}
            />
          </DivTable>

          <DivTable>
            <h2>Auditores</h2>
            <MaterialTable
              options={{
                headerStyle: {
                  backgroundColor: lighten(0.05, '#00529b'),
                  color: '#eee',
                  fontSize: '1.6rem',
                  fontWeight: 'bold',
                },
              }}
              title=" "
              columns={auditorTableState.columns}
              data={auditorTableState.data}
              editable={{
                onRowUpdate: (newData, oldData) =>
                  new Promise(resolve => {
                    if (oldData) {
                      const { login, name, email } = newData;
                      dispatch(updateUserRequest({ login, name, email }));
                    }
                    setTimeout(() => {
                      resolve();
                      if (oldData) {
                        setAuditorTableState(prevState => {
                          const data = [...prevState.data];
                          data[data.indexOf(oldData)] = newData;
                          return { ...prevState, data };
                        });
                      }
                    }, 600);
                  }),
                onRowDelete: oldData =>
                  new Promise(resolve => {
                    const { login } = oldData;
                    dispatch(deleteUserRequest({ login }));
                    setTimeout(() => {
                      resolve();
                      setAuditorTableState(prevState => {
                        const data = [...prevState.data];
                        data.splice(data.indexOf(oldData), 1);
                        return { ...prevState, data };
                      });
                    }, 600);
                  }),
              }}
            />
          </DivTable>

          <DivTable>
            <h2>Petrobras</h2>
            <MaterialTable
              options={{
                headerStyle: {
                  backgroundColor: lighten(0.05, '#00529b'),
                  color: '#eee',
                  fontSize: '1.6rem',
                  fontWeight: 'bold',
                },
              }}
              title=" "
              columns={petrobrasTableState.columns}
              data={petrobrasTableState.data}
              editable={{
                onRowUpdate: (newData, oldData) =>
                  new Promise(resolve => {
                    if (oldData) {
                      const { login, name, email } = newData;
                      dispatch(updateUserRequest({ login, name, email }));
                    }
                    setTimeout(() => {
                      resolve();
                      if (oldData) {
                        setPetrobrasTableState(prevState => {
                          const data = [...prevState.data];
                          data[data.indexOf(oldData)] = newData;
                          return { ...prevState, data };
                        });
                      }
                    }, 600);
                  }),
                onRowDelete: oldData =>
                  new Promise(resolve => {
                    const { login } = oldData;
                    dispatch(deleteUserRequest({ login }));
                    setTimeout(() => {
                      resolve();
                      setPetrobrasTableState(prevState => {
                        const data = [...prevState.data];
                        data.splice(data.indexOf(oldData), 1);
                        return { ...prevState, data };
                      });
                    }, 600);
                  }),
              }}
            />
          </DivTable>

          <DivTable>
            <h2>Gerenciais</h2>
            <MaterialTable
              options={{
                headerStyle: {
                  backgroundColor: lighten(0.05, '#00529b'),
                  color: '#eee',
                  fontSize: '1.6rem',
                  fontWeight: 'bold',
                },
              }}
              title=" "
              columns={sudoTableState.columns}
              data={sudoTableState.data}
              editable={{
                onRowUpdate: (newData, oldData) =>
                  new Promise(resolve => {
                    if (oldData) {
                      const { login, name, email } = newData;
                      dispatch(updateUserRequest({ login, name, email }));
                    }
                    setTimeout(() => {
                      resolve();
                      if (oldData) {
                        setSudoTableState(prevState => {
                          const data = [...prevState.data];
                          data[data.indexOf(oldData)] = newData;
                          return { ...prevState, data };
                        });
                      }
                    }, 600);
                  }),
                onRowDelete: oldData =>
                  new Promise(resolve => {
                    const { login } = oldData;
                    dispatch(deleteUserRequest({ login }));
                    setTimeout(() => {
                      resolve();
                      setSudoTableState(prevState => {
                        const data = [...prevState.data];
                        data.splice(data.indexOf(oldData), 1);
                        return { ...prevState, data };
                      });
                    }, 600);
                  }),
              }}
            />
          </DivTable>
        </>
      )}

      <DivTable>
        <h2>Comuns</h2>
        <MaterialTable
          options={{
            headerStyle: {
              backgroundColor: lighten(0.05, '#00529b'),
              color: '#eee',
              fontSize: '1.6rem',
              fontWeight: 'bold',
            },
          }}
          title=" "
          columns={normalTableState.columns}
          data={normalTableState.data}
          editable={{
            onRowUpdate: (newData, oldData) =>
              new Promise(resolve => {
                if (oldData) {
                  const { login, name, email } = newData;
                  dispatch(updateUserRequest({ login, name, email }));
                }
                setTimeout(() => {
                  resolve();
                  if (oldData) {
                    setNormalTableState(prevState => {
                      const data = [...prevState.data];
                      data[data.indexOf(oldData)] = newData;
                      return { ...prevState, data };
                    });
                  }
                }, 600);
              }),
            onRowDelete: oldData =>
              new Promise(resolve => {
                const { login } = oldData;
                dispatch(deleteUserRequest({ login }));
                setTimeout(() => {
                  resolve();
                  setNormalTableState(prevState => {
                    const data = [...prevState.data];
                    data.splice(data.indexOf(oldData), 1);
                    return { ...prevState, data };
                  });
                }, 600);
              }),
          }}
        />
      </DivTable>
    </ContainerComponent>
  );
}

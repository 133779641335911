import styled from 'styled-components';

export const Container = styled.div`
  width: 1109px;
  margin-top: 64px;
  border: solid 2px #00529b;
  border-radius: 5px;
  background: #fbfbfb;

  @media (max-width: 768px) {
    width:374px;
  }
`;

export const Content = styled.div`
  max-width: 600px;
  margin: 50px auto;

  display: flex;
  align-items: center;
  justify-content: center;

  ul {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 15px;
  }

  li{
    &:hover{
      -webkit-transform: scale(1.1);
	transform: scale(1.1);
  transition:0.4s;
  cursor:pointer;
    }
  }

  @media (max-width: 768px) {
    ul{
      display:flex;
      flex-direction:column;
    }
  }
`;

export const DashboardItem = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: #00529b;
  border-radius: 6px;
  width: 180px;
  height: 180px;
  padding: 20px 20px;
  position: relative;

  span {
    color: #fff;
    font-size: 18px;
    text-align: center;
  }

  button {
    border: 0;
    background: none;
  }

  button#add {
    position: absolute;
    right: 0;
    top: 0;
    padding: 5px;
  }
`;

import styled from 'styled-components';

export const DivAwaitSelect = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
  width: 1109px;
  height: 512px;
  background: #fbfbfb;

  h1 {
    color: #00529b;
    font-weight: bold;
  }
`;

export const DivSelectDocument = styled.div`
  width: 100%;
  height: 48px;
  background: #fbfbfb;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 5px;
`;

export const DivButtons = styled.div`
  button {
    margin: 12px 24px 12px 24px;
    background: #00529b;
    border: 0;
    border-radius: 5px;
    font-weight: bold;
    color: #fff;
    width: 196px;
    height: 32px;
  }
`;

export const PdfContent = styled.div`
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  user-select: none;

  cursor: not-allowed;
  width: 100%;
`;

export const DivChangePage = styled.div`
  margin: 32px auto 32px auto;
  width: 512px;
  height: 32px;
  background: #fbfbfb;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 5px;

  span {
    color: #00529b;
  }

  button {
    background: none;
    border: none;

    svg {
      color: #00529b;
      width: 32px;
      height: 32px;
    }
  }
`;

export const DivSelectPage = styled.div`
  width: 100%;
  display: flex;
  position: relative;
`;

export const ComboCompanyName = styled.div`
  margin-left: 32px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 1080px;
  height: 32px;
  background: #fbfbfb;
  border-radius: 5px;

  h2 {
    color: #00529b;
    margin-right: 8px;
  }

  select {
    margin-top: 8px;
    margin-left: 5px;
    width: 338px;
    height: 28px;
    border-radius: 5px;
    border: 1px solid #000;
    padding-left: 5px;
    background: #fff;
  }
`;

export const InputPage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  top: 32px;
  background: #fbfbfb;
  border: 0;
  border-radius: 5px;
  width: 196px;
  height: 32px;

  label {
    color: #00529b;
    margin-right: 4px;
  }

  input {
    margin-right: 4px;
    font-size: 16px;
    border-radius: 5px;
    text-align: center;
    border: solid 1px #00529b;
  }
`;

/**
 *   position: absolute;
  top: 228px;
  right: 254px;
 */

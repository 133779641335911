import React from 'react';
import { MdNotifications } from 'react-icons/md';

import { Container, Badge } from './styles';
import history from '../../services/history';

export default function Notifications() {
  function redirectToFeedback() {
    history.push('/feedback');
  }

  return (
    <Container>
      <Badge onClick={() => redirectToFeedback()}>
        <MdNotifications color="#00529b" size={20} />
      </Badge>
    </Container>
  );
}

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input } from '@rocketseat/unform';
import * as Yup from 'yup';
import history from '../../services/history';
import converVideo from '../../assets/videos/Vídeo_site_atual.mp4'

import { signInRequest } from '../../store/modules/auth/actions';

import { Container } from './styles';


import Logo from '../../assets/peodive-logo.svg';


const schema = Yup.object().shape({
  login: Yup.string().required('Campo obrigatório'),
  password: Yup.string().required('Campo de senha é obrigatório'),
});

export default function SignIn() {
  document.title = 'Login';

  const dispatch = useDispatch();
  const loading = useSelector(state => state.auth.loading);

  // Checando se usuário já está logado
  const signed = useSelector(state => state.auth.signed);
  if (signed) {
    history.push('/');
  }

  function handleSubmit({ login, password }) {

    dispatch(signInRequest(login, password));
  }

  return (


    <Container>
      <video autoplay='' muted loop id="myVideo">
          <source src={converVideo} type="video/mp4" />
        </video>


        <div class="d-flex justify-content-center">
        <Form schema={schema} onSubmit={handleSubmit}>
          <img src={Logo} alt="whatsapp-logo" />
          <Input name="login" type="string" placeholder="Seu login" />
          <Input name="password" type="password" placeholder="Sua senha" />

          <button type="submit">{loading ? 'Carregando...' : 'Acessar'}</button>

          <button
            type="button"
            onClick={() => history.push('/recuperar-senha')}
          >
            Recuperar senha
          </button>
        </Form>

        </div>




    </Container>
  );
}

import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import history from '../../../services/history';

import {
  addUserAddSuccess,
  addUserFailure,
  deleteUserAddSuccess,
  deleteUserFailure,
  updateUserAddSuccess,
  updateUserFailure,
} from './actions';

import api from '../../../services/api';

const groupLevelFunction = {
  getGroupLevel(userLevel) {
    const groupLevel = {
      admin: false,
      sudo: false,
      normal: false,
      company: false,
      auditor: false,
      petrobras: false,
    };

    if (userLevel && userLevel === 'sudo') {
      groupLevel.sudo = true;
      return groupLevel;
    }

    if (userLevel && userLevel === 'company') {
      groupLevel.company = true;
      return groupLevel;
    }

    if (userLevel && userLevel === 'auditor') {
      groupLevel.auditor = true;
      return groupLevel;
    }

    if (userLevel && userLevel === 'petrobras') {
      groupLevel.petrobras = true;
      return groupLevel;
    }

    groupLevel.normal = true;
    return groupLevel;
  },
};

export function* addUser({ payload }) {
  try {
    const { login, name, password, email, userLevel } = payload;

    const userForm = {
      _id: login,
      name,
      password,
      email,
      groupLevel: groupLevelFunction.getGroupLevel(userLevel),
    };

    const response = yield call(api.post, 'user', userForm);

    if (response.status === 201) {
      toast.success('Usuário cadastrado com sucesso');
      yield put(addUserAddSuccess());
    } else {
      const { err } = response.data;
      if (err) {
        toast.error(err);
      }
      yield put(addUserFailure());
    }
  } catch (error) {
    toast.error('Por favor, verifique os campos');
    yield put(addUserFailure());
  }
}

export function* deleteUser({ payload }) {
  try {
    const { login } = payload;

    const response = yield call(api.delete, `user/${login}`);

    if (response.status === 200) {
      yield put(deleteUserAddSuccess());
      toast.info('Usuário deletado com sucesso');
    } else {
      yield put(deleteUserFailure());
    }
  } catch (error) {
    yield put(deleteUserFailure());
  }
}

export function* updateUser({ payload }) {
  try {
    const { login, name, email } = payload;

    yield call(api.put, 'user', { login, name, email });

    yield put(updateUserAddSuccess());
  } catch (error) {
    yield put(updateUserFailure());
    toast.error('Erro ao atualizar usuário');
  }
}

export function* requestNewCode({ payload }) {
  try {
    const { username, email } = payload;

    const response = yield call(api.post, 'request-reset-code', {
      username,
      email,
    });

    if (response.status === 200) {
      const { sucess, err } = response.data;

      if (sucess) {
        toast.success(sucess);
      }

      if (err) {
        toast.error(err);
      }
    }
  } catch (error) {
    toast.error('Erro ao obter novo código');
  }
}

export function* changePassword({ payload }) {
  try {
    const { username, email, newPassword, code } = payload;

    const response = yield call(api.post, 'reset-password', {
      username,
      email,
      newPassword,
      code,
    });

    if (response.status === 200) {
      const { sucess, err } = response.data;

      if (sucess) {
        toast.success(sucess);
        history.push('/');
      }

      if (err) {
        toast.error(err);
      }
    }
  } catch (error) {
    toast.error('Erro ao obter novo código');
  }
}

export default all([
  takeLatest('@addUser/ADD_USER_REQUEST', addUser),
  takeLatest('@user/DELETE_USER_REQUEST', deleteUser),
  takeLatest('@user/UPDATE_USER_REQUEST', updateUser),
  takeLatest('@user/REQUEST_NEW_CODE', requestNewCode),
  takeLatest('@user/CHANGE_PASSWORD', changePassword),
]);

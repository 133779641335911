


const myArrayVerification=(myArray,setArray,groupName,AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm)=>{
  let groupNameSplit = groupName.split(' ');
  myArray.forEach(el => {
    for(let FirstKey of Object.keys(el) ){
      for (let SecondKey of Object.keys(el[FirstKey])){
        let KeySplit = SecondKey.split(' ');
        if (groupNameSplit[0] === KeySplit[0]) {
          if(!questionsSelected.includes(groupName)){
            for(let el of listKeysQuestion[0][FirstKey]){
              if (Object.keys(el)[0] === groupNameSplit[0]) {
                let dict = {};
                dict[groupName] = AnswerCheck;
                AnswerForm.push(dict);
                setAnswerForm([...AnswerForm]);

                el[groupNameSplit[0]]['Check'].push(AnswerCheck);
                questionsSelected.push(groupName);
                setListKeys([...listKeysQuestion]);
                setquestionsSelected([...questionsSelected]);
                break;
              }
            }
          }else{
            for(let el of listKeysQuestion[0][FirstKey] ){
              if (Object.keys(el)[0] === groupNameSplit[0]) {
                let dict = {};
                dict[groupName] = AnswerCheck;
                AnswerForm[questionsSelected.indexOf(groupName)]=dict;
                setAnswerForm([...AnswerForm]);
               el[groupNameSplit[0]]['Check'][el[groupNameSplit[0]]['Check'].length-1]=AnswerCheck
                setListKeys([...listKeysQuestion]);
                break;
              }
            }
          }

            break;
        }


      }

      for(let el of listKeysQuestion[0][FirstKey] ){
        if (Object.keys(el)[0] === groupNameSplit[0]) {
          if (el[groupNameSplit[0]]['Check'].includes('No') && el[groupNameSplit[0]]['Check'].includes('Yes')) {
            el[groupNameSplit[0]]['Answer'] = 'yellow';
            setListKeys([...listKeysQuestion]);
          } else if(el[groupNameSplit[0]]['Check'].includes('Yes') && !el[groupNameSplit[0]]['Check'].includes('No') ){
            el[groupNameSplit[0]]['Answer'] = 'green';
            setListKeys([...listKeysQuestion]);
          }else if(!el[groupNameSplit[0]]['Check'].includes('Yes') && el[groupNameSplit[0]]['Check'].includes('No') ){
            el[groupNameSplit[0]]['Answer'] = 'red';
            setListKeys([...listKeysQuestion]);
          } else {
            el[groupNameSplit[0]]['Answer'] = 'white';
            setListKeys([...listKeysQuestion]);
          }
        }
      }


    }

  });

      setArray([...myArray]);
}

const VerificationFalhaEquipamentoIndividual= (listKeysQuestion, setListKeys,groupName,AnswerCheck,myArray,setArray,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm)=>{

    if(groupName==="1-3 b 8"){
        myArrayVerification(myArray,setArray,'2-3 b 21',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
        myArrayVerification(myArray,setArray,'3-1 b 26',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);

    }else if(groupName==="1-3 c 9"){
        myArrayVerification(myArray,setArray,'2-3 c 22',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);

    }else if(groupName==="1-4 a 10"){
        myArrayVerification(myArray,setArray,'3-3 a 36',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
        myArrayVerification(myArray,setArray,'4-1 a 41',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
        myArrayVerification(myArray,setArray,'5-2 a 59',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);

    }else if(groupName==="1-4 b 11"){
        myArrayVerification(myArray,setArray,'3-3 b 37',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
        myArrayVerification(myArray,setArray,'4-1 b 42',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
        myArrayVerification(myArray,setArray,'5-2 b 60',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);

    }else if(groupName==="1-4 c 12"){
        myArrayVerification(myArray,setArray,'3-3 c 38',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
        myArrayVerification(myArray,setArray,'4-1 c 43',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
        myArrayVerification(myArray,setArray,'5-2 c 61',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);

    }else if(groupName==="1-4 d 13"){
        myArrayVerification(myArray,setArray,'3-3 d 39',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
        myArrayVerification(myArray,setArray,'4-1 d 44',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
        myArrayVerification(myArray,setArray,'5-2 d 62',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);

    }else if(groupName==="1-4 e 14"){
        myArrayVerification(myArray,setArray,'3-3 e 40',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
        myArrayVerification(myArray,setArray,'4-1 e 45',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
        myArrayVerification(myArray,setArray,'5-2 e 63',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);

    }else if(groupName==="6-1 a 73"){
        myArrayVerification(myArray,setArray,'7-1 c 109',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
        myArrayVerification(myArray,setArray,'8-1 a 148',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
        myArrayVerification(myArray,setArray,'9-1 a 186',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);


    }else if(groupName==="6-1 b 74"){
        myArrayVerification(myArray,setArray,'7-2 b 111',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
        myArrayVerification(myArray,setArray,'8-1 b 149',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
        myArrayVerification(myArray,setArray,'9-1 b 187',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);


    }else if(groupName==="6-4 c 84"){
        myArrayVerification(myArray,setArray,'7-4 d 119',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
        myArrayVerification(myArray,setArray,'8-3 d 157',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);



    }else if(groupName==="6-4 d 85"){
        myArrayVerification(myArray,setArray,'7-4 c 118',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
        myArrayVerification(myArray,setArray,'8-3 c 156',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);



    }else if(groupName==="6-4 f 87"){
        myArrayVerification(myArray,setArray,'7-4 f 121',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
        myArrayVerification(myArray,setArray,'8-3 f 159',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);



    }else if(groupName==="6-5 b 90"){
        myArrayVerification(myArray,setArray,'7-6 b 130',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
        myArrayVerification(myArray,setArray,'8-5 b 168',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
        myArrayVerification(myArray,setArray,'9-3 b 193',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);


    }else if(groupName==="6-5 c 91"){
        myArrayVerification(myArray,setArray,'7-6 c 131',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
        myArrayVerification(myArray,setArray,'8-5 c 169',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
        myArrayVerification(myArray,setArray,'9-3 c 194',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);


    }else if(groupName==="6-6 a 93"){
        myArrayVerification(myArray,setArray,'7-7 a 133',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
        myArrayVerification(myArray,setArray,'8-6 a 171',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
        myArrayVerification(myArray,setArray,'9-4 a 198',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);


    }else if(groupName==="6-6 b 94"){
        myArrayVerification(myArray,setArray,'7-7 b 134',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
        myArrayVerification(myArray,setArray,'8-6 b 172',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
        myArrayVerification(myArray,setArray,'9-4 b 199',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);


    }else if(groupName==="6-7 a 95"){
        myArrayVerification(myArray,setArray,'7-8 a 135',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
        myArrayVerification(myArray,setArray,'8-7 a 173',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
        myArrayVerification(myArray,setArray,'9-5 a 200',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);


    }else if(groupName==="6-7 b 96"){
        myArrayVerification(myArray,setArray,'7-8 b 136',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
        myArrayVerification(myArray,setArray,'8-7 b 174',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
        myArrayVerification(myArray,setArray,'9-5 a 200',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);


    }else if(groupName==="6-8 a 97"){
        myArrayVerification(myArray,setArray,'8-8 a 175',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);

    }else if(groupName==="6-8 b 98"){
        myArrayVerification(myArray,setArray,'7-9 b 138',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
        myArrayVerification(myArray,setArray,'9-6 b 203',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);



    }else if(groupName==="6-8 c 99"){
        myArrayVerification(myArray,setArray,'7-9 c',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
        myArrayVerification(myArray,setArray,'8-8 c',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
        myArrayVerification(myArray,setArray,'9-6 c',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);


    }else if(groupName==="6-8 d 100"){
        myArrayVerification(myArray,setArray,'7-9 d',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
        myArrayVerification(myArray,setArray,'8-8 d',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);



    }else if(groupName==="6-9 a 101"){
        myArrayVerification(myArray,setArray,'7-10 a',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
        myArrayVerification(myArray,setArray,'8-9 a',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
        myArrayVerification(myArray,setArray,'9-7 a',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);


    }else if(groupName==="6-9 b 102"){
        myArrayVerification(myArray,setArray,'7-10 b',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
        myArrayVerification(myArray,setArray,'8-9 b',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
        myArrayVerification(myArray,setArray,'9-7 b',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);


    }else if(groupName==="6-9 c 103"){
        myArrayVerification(myArray,setArray,'7-10 c',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
        myArrayVerification(myArray,setArray,'8-9 c',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
        myArrayVerification(myArray,setArray,'9-7 c',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);


    }else if(groupName==="6-9 d 104"){
        myArrayVerification(myArray,setArray,'7-10 e',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
        myArrayVerification(myArray,setArray,'8-9 e',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
        myArrayVerification(myArray,setArray,'9-7 e',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);


    }else if(groupName==="6-10 a 105"){
        myArrayVerification(myArray,setArray,'7-11 a',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
        myArrayVerification(myArray,setArray,'8-10 a',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
        myArrayVerification(myArray,setArray,'9-8 a',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);


    }else if(groupName==="6-10 b 106"){
        myArrayVerification(myArray,setArray,'7-11 b',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
        myArrayVerification(myArray,setArray,'8-10 b',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
        myArrayVerification(myArray,setArray,'9-8 b',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);


    }else if(groupName==="7-3 a 113"){
        myArrayVerification(myArray,setArray,'8-2 a',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);

    }else if(groupName==="7-3 b 114"){
        myArrayVerification(myArray,setArray,'8-2 b',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);

    }else if(groupName==="7-3 c 115"){
        myArrayVerification(myArray,setArray,'8-2 c',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);



    }else if(groupName==="6-4 a 82"){
        myArrayVerification(myArray,setArray,'7-4 a',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
        myArrayVerification(myArray,setArray,'8-3 a',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);



    }else if(groupName==="6-4 e 86"){
        myArrayVerification(myArray,setArray,'7-4 e',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
        myArrayVerification(myArray,setArray,'8-3 e',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);



    }else if(groupName==="7-5 a 123"){
        myArrayVerification(myArray,setArray,'8-4 a',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);



    }else if(groupName==="7-5 b 124"){
        myArrayVerification(myArray,setArray,'8-4 b',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);

    }else if(groupName==="7-5 d 126"){
        myArrayVerification(myArray,setArray,'8-4 d',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);



    }else if(groupName==="7-5 e 127"){
        myArrayVerification(myArray,setArray,'8-4 e',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
        myArrayVerification(myArray,setArray,'9-3 e',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);



    }else if(groupName==="7-5 f 128"){
        myArrayVerification(myArray,setArray,'8-4 f',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
        myArrayVerification(myArray,setArray,'9-3 f',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);



    }else if(groupName==="7-10 d 144"){
        myArrayVerification(myArray,setArray,'8-9 d',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);
        myArrayVerification(myArray,setArray,'9-7 d',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);



    }else if(groupName==="7-9 a 137"){
        myArrayVerification(myArray,setArray,'9-6 a',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm);



    }


}

export default VerificationFalhaEquipamentoIndividual;

import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
 

  img {
    width: 19.6rem;
    height: 19.6rem;
    position: absolute;
    top: -1.2rem;
    left: 11.6rem;
    
  }

  
  form {
    display: flex;
    flex-direction: column;
    position: absolute;
    margin-top:50px;
    width: 44rem;
  height: 54rem;
  border: solid 2px #00529b;
  background: #e8eaef;
  border-radius:20px;
  box-shadow: -3px 2px 17px 2px #0F1DA6;
  padding:160px 100px 100px 100px;

    input {
      width: 23.7rem;
      height: 4.8rem;
      background: #fff;
      border: 0;
      border-radius: 4px;
      height: 44px;
      padding: 0 15px;
      color: #000;
      margin: 0 0 10px;

      &::placeholder {
        color: ${darken(0.1, '#000')};
      }

      
    }

    input:focus{
      border:1px solid #424ead;
    }

    input + input {
      margin-top: 3.2rem;
    }

    input + button {
      margin-top: 6.4rem;
    }

    span {
      color: #fff;
      align-self: flex-start;
      margin: 0 0 5px;
      font-weight: bold;
    }

    button {
      margin: 9.6rem 0 0;
      height: 44px;
      background: #00529b;
      font-weight: bold;
      color: #fff;
      border: 0;
      border-radius: 4px;
      font-size: 16px;
      transition: background 0.2s;

      &:hover {
        background: ${darken(0.03, '#00529b')};
      }
    }

    button + button {
      margin: 1.6rem 0 0;

      height: 44px;
      background: #b56a1e;
      font-weight: bold;
      color: #fff;
      border: 0;
      border-radius: 4px;
      font-size: 16px;
      transition: background 0.2s;

      &:hover {
        background: ${darken(0.03, '#b56a1e')};
      }
    }
  }
`;

export const DivSignIn = styled.div`
margin: 45px auto 20px auto;
`;

import api from '../../services/api';

const groupLevelFunction = {
  getGroupLevel(userLevel) {
    const groupLevel = {
      admin: false,
      sudo: false,
      normal: false,
      company: false,
      auditor: false,
      petrobras: false,
    };

    if (userLevel && userLevel === 'sudo') {
      groupLevel.sudo = true;
      return groupLevel;
    }

    if (userLevel && userLevel === 'company') {
      groupLevel.company = true;
      return groupLevel;
    }

    if (userLevel && userLevel === 'auditor') {
      groupLevel.auditor = true;
      return groupLevel;
    }

    if (userLevel && userLevel === 'petrobras') {
      groupLevel.petrobras = true;
      return groupLevel;
    }

    groupLevel.normal = true;
    return groupLevel;
  },
};

const validateFormUtil = {
  isValidValue(field) {
    if (!field || field === '' || field === ' ') {
      return 'precisa ser válido';
    }
    return true;
  },

  isMin4(field) {
    if (field.length < 4) {
      return 'precisa ter no mínimo 4 caracteres';
    }
    return true;
  },

  isMin6(field) {
    if (field.length < 6) {
      return 'precisa ter no mínimo 6 caracteres';
    }
    return true;
  },
};

function validateForm(field, fieldName, min) {
  let isValid = validateFormUtil.isValidValue(field);
  if (isValid !== true) {
    return `O campo ${fieldName} ${isValid}`;
  }

  if (min === 6) {
    isValid = validateFormUtil.isMin6(field);
    if (isValid !== true) {
      return `O campo ${fieldName} ${isValid}`;
    }
  }

  if (min === 4) {
    isValid = validateFormUtil.isMin4(field);
    if (isValid !== true) {
      return `O campo ${fieldName} ${isValid}`;
    }
  }
  return true;
}

function isAllRigth(userForm) {
  const { login, name, password, email } = userForm;

  const byLogin = validateForm(login, 'login', 6);
  if (byLogin !== true) {
    return byLogin;
  }

  const byName = validateForm(name, 'nome', 4);
  if (byName !== true) {
    return byName;
  }

  const byPassword = validateForm(password, 'senha', 6);
  if (byPassword !== true) {
    return byPassword;
  }

  const byEmail = validateForm(email, 'e-mail', 4);

  if (!byEmail !== true) {
    return byEmail;
  }

  return true;
}

export function checkForm(payload) {
  const isValidForm = isAllRigth(payload);

  if (isValidForm !== true) {
    return isValidForm;
  }

  return true;
}

export async function addUser(payload) {
  const { login, name, password, email, userLevel,empresa } = payload;

  const userForm = {
    _id: login,
    name,
    password,
    email,
    groupLevel: groupLevelFunction.getGroupLevel(userLevel),
    empresa
  };

  try {
    const response = await api.post('user', userForm);

    if (response.status === 201) {
      return true;
    }
    const { err } = response.data;
    if (err) {
      return err;
    }
    return 'Erro ao cadastrar usuário';
  } catch (error) {
    return 'Por favor, verifique os campos';
  }
}

import { createGlobalStyle } from 'styled-components';

import 'react-toastify/dist/ReactToastify.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'rc-menu/assets/index.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

export default createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap');
  @import url('https://fonts.googleapis.com/icon?family=Material+Icons');

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  html {
  font-size: 62.5%;
  scroll-behavior: smooth;
 }

  *:focus {
    outline: 0;
  }


  @media (max-width: 1080px) {
  html {
    font-size: 58%;
  }
}

  @media (max-width: 720px) {
    html {
      font-size: 54%;
    }
  }


  body {
   -webkit-font-smoothing: antialiased;
   font-size: 1.6rem;
    font-family: 'Titillium Web', sans-serif;
    color: #333;
  }

  body, input, button {
    font: 14px 'Roboto', sans-serif;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  ul {
    list-style: none;
  }

  button {
    cursor: pointer;
  }

  ::selection {
  background: #7159c1;
  color: #fff;
 }


`;

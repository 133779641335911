import React from 'react';
import { Link } from 'react-router-dom';

import { Footer, Content, DivCopyright, DivLinkArea, DivLinks } from './styles';
import projectLogo from '../../assets/peodive-logo.svg';

export default function FooterComponent() {
  return (
    <Footer>
      <Content>
        <DivCopyright>
          <img src={projectLogo} alt="#" />
          
        </DivCopyright>

        <DivLinkArea>
          <DivLinks>
            <nav>
              <Link to="/">Início</Link>
              <span>|</span>
              <Link to="/quem-somos">Quem Somos</Link>
              <span>|</span>
              <Link to="/nossas-atividades">Nossas Atividades</Link>
              <span>|</span>
              <Link to="/fale-conosco">Fale Conosco</Link>
              <span>|</span>
              <Link to="/termos-de-uso">Termos de Uso</Link>
            </nav>
          </DivLinks>
        </DivLinkArea>
      </Content>
    </Footer>
  );
}


const titleFormIncendioExplosaoCamara="INCÊNDIO E/OU EXPLOSÃO EM UMA CÂMARA OU NAS PROXIMIDADES DO SISTEMA DE MERGULHO"
const titleHazardIncendioExplosaoCamara="Atmosfera Explosiva";
const SecondIdIncendioExplosaoCamara="IncendioExplosaoCamara";
const listAMCOIncendioExplosaoCamara=['AM-1 - Elevado percentual de O2 na câmara (>25%)','CO-1-Intoxicação com lesão, alteração funcional e potencial de fatalidade'];
let TitlesAndQuestionsIncendioExplosaoCamara = {
    'AM-1 - Elevado percentual de O2 na câmara (>25%)': {
      '1-1 a 1': {
        Question:
          'Existe controle da validade das células dos analisadores de O2?',
        Answer: 'white',
      },
      '1-1 b 2': {
        Question:
          ' São realizados testes nos analisadores de O2 na frente de trabalho, antes de se iniciar operações de mergulho?',
        Answer: 'white',
      },
      '1-1 c 3': {
        Question:
          ' Os analisadores de O2 com certificados vencidos são tirados de operação?',
        Answer: 'white',
      },
      '1-1 d 4': {
        Question:
          ' Existem analisadores de O2 sobressalentes nas frentes de trabalho com certificados válidos?',
        Answer: 'white',
      },
      '1-2 a 5': {
        Question:
          ' Os analisadores de O2 são aferidos utilizando gás de calibragem antes de iniciarem o seu uso? ',
        Answer: 'white',
      },
      '1-2 b 6': {
        Question:
          ' No plano de manutenção do sistema de mergulho é contemplado a manutenção e aferição do analizador de O2 conforme orientação do fabricante? ',
        Answer: 'white',
      },
      '1-2 c 7': {
        Question:
          ' Existe sistemática de monitoramento dos equipamentos de analise da câmara hiperbárica quanto a validade da aferição e certificação?',
        Answer: 'white',
      },
      '1-2 d 8': {
        Question: 'Os equipamentos de análise da câmara de hiperbárica são vistoriados periodicamente, para se certificar que estão com os certificados dentro da validade?',
        Answer: 'white',
      },
      '1-3 a 9': {
        Question:
          'É mantida ventilação forçada nos compartimentos que recebem linhas de baixa pressão de O2? ',
        Answer: 'white',
      },
      '1-3 b 10': {
        Question:
          ' Nas áreas fechadas onde se manipula O2 em baixa pressão é mantido sistema de ventilação forçada? ',
        Answer: 'white',
      },
      '1-3 c 11': {
        Question:
          'Está previsto no plano de manutenção preventiva da câmara hiperbárica, o sistema de ventilação com suas válvulas e manetes? ',
        Answer: 'white',
      },
      '1-3 d 12': {
        Question:
          'As manutenções nos sistemas de ventilação da câmara hiperbárica são realizadas e monitoradas por pessoal competente?',
        Answer: 'white',
      },
      '1-4 a 13': {
        Question:
          'Os operadores de câmaras hiperbáricas são treinados e capacitados para controlarem os parâmetros estipulados em procedimentos a fim de manter a estadia do mergulhador segura no interior das câmaras? ',
        Answer: 'white',
      },
      '1-4 c 15': {
        Question:
          'Existe treinamento periódico especifico para a função de operadores de câmara hiperbárica? ',
        Answer: 'white',
      },
      '1-5 a 16': {
        Question:
          'A câmara hiperbárica é equipada com BIBS de exaustão externa?',
        Answer: 'white',
      },
      '1-5 b 17': {
        Question:
          'Os técnicos de câmara são treinados a fim de identificar mau funcionamento dos BIBS de exaustão externa? ',
        Answer: 'white',
      },
      '1-5 c 18': {
        Question:
          ' Os BIBS utilizados na frente/embracação de mergulho, estão de acordo com as especificações das Normas vigentes?',
        Answer: 'white',
      },

      '1-5 d 19': {
        Question:
          'As equipes de mergulho são treinadas e capacitadas para a utilização correta dos BIBS nas câmaras hiperbáricas?',
        Answer: 'white',
      },
      '1-6 a 20': {
        Question:
          'É realizada periodicamente testes de verificação funcional dos BIBS de exaustão externa?',
        Answer: 'white',
      },
      '1-6 b 21': {
        Question:
          'Consta no plano de manutenção do sistema de mergulho a manutenção periódica conforme orientação do fabricante dos componentes que compõem os BIBS de exaustão externa?',
        Answer: 'white',
      },
      '1-6 c 22': {
        Question:
          'No plano de inspeção e manutenção periódica da câmara hiperbarica, são verificados todos os componentes do sistema, incluindo os BIBS?',
        Answer: 'white',
      },
      '1-6 d 23': {
        Question:
          'Os colaboradores que realizam as inspeções de manutenções na câmara hiperbárica são treinados e capacitados para realizar esta função?',
        Answer: 'white',
      },

    },
    'AM-2 – Materiais ou substâncias inflamáveis dentro da câmara hiperbárica': {
      '2-1 a 24': {
        Question:
          'Consta no check list pré-operação a verificação da presença de materiais inflamáveis na câmara hiperbárica?',
        Answer: 'white',
      },
      '2-1 b 25': {
        Question:
          'As equipes de mergulho são treinadas e orientadas a evitarem manter no interior das câmaras hiperbáricas materiais inflamáveis? ',
        Answer: 'white',
      },
      '2-1 d 27': {
        Question:
          'No DDS das equipes de mergulho é ressaltado a importância do check list pré-operação dos equipamentos de mergulho, incluíndo a câmara hiperbárica?',
        Answer: 'white',
      },
      '2-2 a 28': {
        Question:
          'Existe analisador multigás de monitoramaneto da atmosfera da câmara hiperbárica no painel de controle de mergulho?',
        Answer: 'white',
      },
      '2-2 b 29': {
        Question:
          'A calibração do analisador multigás é realizada antes do início das operações diárias para garantir a precisão de leitura?',
        Answer: 'white',
      },
      '2-2 c 30': {
        Question:
          'O supervisor de mergulho é treinado na calibração e utilização do analisador multigás?',
        Answer: 'white',
      },
      '2-2 d 31': {
        Question:
          'Os fornecedores do analisador multigás estão contemplados na lista de fornecedores críticos da empresa?',
        Answer: 'white',
      },

    },
    'AM-3 - Energia elétrica estática dentro da câmara hiperbárica': {
      '3-1 a 32': {
        Question:
          'Existe sistemática de não utilizar materiais ou equipamentos que possam gerar eletricidade e consequente formação de centelhas no interior da câmara hiperbárica (lanternas e outros equipamentos sem classificação EX)?',
        Answer: 'white',
      },
      '3-1 b 33': {
        Question:
          'É realizado treinamento e conscientização dos mergulhadores em não utilizarem materiais que possam vir a gerar centelhas no interior da câmara?',
        Answer: 'white',
      },
      '3-1 c 34': {
        Question:
          'Durante a mobilização da câmara hiperbárica é levado em consideração o aterramento adequado e a não utilização de materiais e equipamentos que possam gerar eletricitadade estática e formação de centelhas?',
        Answer: 'white',
      },
      '3-1 d 35': {
        Question:
          'No projeto de fabricação das câmaras hiperbáricas, estão especificados materiais que previnam a geração de eletricidade estática e a formação de centelhas?',
        Answer: 'white',
      },
      '3-2 a 36': {
        Question:
          'É considerado em projeto a utilização de conectores elétricos apropriados e aprovados a fim de evitar risco de incêndio no interior da câmara hiperbárica?',
        Answer: 'white',
      },
      '3-2 b 37': {
        Question:
          'São mantidos em estoque conectores eletricos originais e certificados para utilização em câmaras hiperbáricas?',
        Answer: 'white',
      },
      '3-2 c 38': {
        Question:
          'Consta no procedimento de manutenção do sistema de mergulho a verificação e a substituição caso necessário dos conectores elétricos das câmaras hiperbáricas? ',
        Answer: 'white',
      },
      '3-2 d 39': {
        Question:
          'Os conectores elétricos disponibilizados para troca na manutenção no interior das câmaras hiperbáricas são os indicados pelo fabricante? ',
        Answer: 'white',
      },
      '3-3 a 40': {
        Question:
          'São mantidos os isolamentos elétricos conforme orientação do fabricante das câmaras hiperbáricas?',
        Answer: 'white',
      },
      '3-3 b 41': {
        Question:
          'Os isolamentos elétricos feitos no interior das câmaras hiperbáricas obedecem às orientaçãos técnicas do fabricante do equipamento?',
        Answer: 'white',
      },
      '3-3 c 42': {
        Question:
          'Os técnicos de equipamentos que realizam as manutenções nas câmaras hiperbáricas são orientados a só utilizar isolamentos elétricos indicados pelo fabricante?',
        Answer: 'white',
      },
      '3-4 a 43': {
        Question:
          'Os técnicos de manutenção em elétrica são capacitados nas normas vigentes (NR10) e nas atividades por eles exercidas?',
        Answer: 'white',
      },
      '3-4 b 44': {
        Question:
          'Os técnicos de manutenção em elétrica cumprem rigorosamente as especificações técnicas mantendo a atenção nas voltagens dos equipamentos utilizados nas câmaras hiperbáricas?',
        Answer: 'white',
      },
      '3-4 c 45': {
        Question:
          'Os técnicos de manutenção em elétrica responsáveis pelas manutenções na câmara hiperbárica possuem treinamento e competência para somente utilizar equipamentos elétricos dentro da faixa de voltagem permitidas nas Normas vigentes (NR10)?',
        Answer: 'white',
      },

    },
    'AM-4-Incêndio/explosão de origem externa': {
      '4-1 a 46': {
        Question:
          'Nas visitas técnicas a bordo da frente/embarcação de mergulho é verificada a classificação da zona de risco em que o sistema de mergulho será instalado?',
        Answer: 'white',
      },
      '4-1 b 47': {
        Question:
          'São elaboradas análises de risco antes da instalação da câmara hiperbárica e acessórios nas áreas definidas quanto ao rísco de incêndio ou explosão (área classificada)?',
        Answer: 'white',
      },
      '4-1 c 48': {
        Question:
          'No projeto de instalação da câmara hiperbárica e seus acessórios, é definido uma distância mínima de equipamentos que possam por em risco a integridade da câmara hiperbárica (área de solda, equipamentos que emitem fagulhas e etc.)?',
        Answer: 'white',
      },
      '4-2 a 49': {
        Question:
          ' É realizado DDS com a equipe de mergulho conscientizando sobre o tema ‘fontes de inignição na área de mergulho e suas proximidades, principio de incêndio e seus perigos’?',
        Answer: 'white',
      },
      '4-2 b 50': {
        Question:
          'Existe um controle da sistemática dos treinamentos e DDS realizados na frente/embarcação de mergulho?',
        Answer: 'white',
      },
      '4-3 a 51': {
        Question: 'No projeto de instalação do sistema de mergulho na frente/embarcação de mergulho é contemplado a instalação de sensores de fumaça e de chamas?',
        Answer: 'white',
      },
      '4-3 b 52': {
        Question: 'A frente/embarcação de mergulho onde o sistema de mergulho está montado disponibiliza sensores de fumaça e de chamas?',
        Answer: 'white',
      },
      '4-4 a 53': {
        Question: 'Na área de montagem do sistema de mergulho existe sistema de detecção e combate a princípios de incêndio?',
        Answer: 'white',
      },
      '4-4 b 54': {
        Question: 'No planejamento das atividades de mergulho a bordo da frente/embarcação de mergulho é contemplada na PT, CLM, APR, DDS os treinamentos de combate a incêndio?',
        Answer: 'white',
      },

    },
    'AM-5-Vazamento de Cilindro de O2': {
      '5-1 a 55': {
        Question:
          'É contemplado no projeto de instalação do sistema de mergulho uma área exclusiva (área externa ou ventilada) para o armazenamento de cilindros de O2?',
        Answer: 'white',
      },
      '5-1 b 56': {
        Question:
          'Os profissionais que fazem o manuseio dos cilindros de O2 são capacitados e treinados?',
        Answer: 'white',
      },
      '5-2 a 57': {
        Question:
          'Existe controle do certificado de validade do teste hidrostático dos cilindros de O2 pela equipe onshore?',
        Answer: 'white',
      },
      '5-2 b 58': {
        Question:
          'A Black box do sistema de monitoramento do mergulhador está de acordo com as normas vigentes e com capacidade suficiente de armazenamento?',
        Answer: 'white',
      },
      '5-2 c 59': {
        Question:
          'Existe controle do certificado de validade das válvulas e mangueiras utilizadas em conjunto com os cilindros de O2 pela equipe onshore?',
        Answer: 'white',
      },
      '5-2 d 60': {
        Question:
          'São verificados periodicamente a validade dos certificados das válvulas e mangueiras utilizadas em conjunto com os cilindros de O2 a bordo da frente/embarcação de mergulho?',
        Answer: 'white',
      },
      '5-3 a 61': {
        Question:
          'É registrado em check list a verificação periódica do estado físico dos cilindros de O2 e de seus componentes (válvulas, mangueiras e conexões)?',
        Answer: 'white',
      },
      '5-3 b 62': {
        Question:
          'A equipe de mergulho realiza diariamente inspeções visuais em toda a estrutura dos cilindros de O2 e em seus componentes (válvulas, mangueiras e conexões) conforme procedimento?',
        Answer: 'white',
      },
      '5-4 a 63': {
        Question:
          'No projeto de instalação do sistema de mergulho a bordo da frente/embarcação de mergulho é considerado na área de estocagem dos cilindros de O2 a proteção contra danos provocados por queda de objetos e tráfego de guindastes?',
        Answer: 'white',
      },
      '5-4 b 64': {
        Question:
          'É considerado na APR o risco de movimentação de cargas acima da área de estocagem dos cilindros de O2?',
        Answer: 'white',
      },
      '5-4 c 65': {
        Question:
          'Durante reunião pré tarefa é mencionado sobre o risco de movimentação de carga acima da área de estocagem dos cilindros de O2 com os responsáveis da frente/embarcação de mergulho?',
        Answer: 'white',
      },

    },
    'AM-6-Vazamento de tanque de volume': {
      '6-1 a 66': {
        Question:
          'Existe controle do certificado de validade dos tanques de volume pela equipe onshore?',
        Answer: 'white',
      },
      '6-1 b 67': {
        Question:
          'São verificados periodicamente a validade dos certificados dos tanques de volume a bordo da frente/embarcação de mergulho? ',
        Answer: 'white',
      },
      '6-2 a 68': {
        Question:
          'São realizadas verificações de rotina na válvula de segurança e no pressostato do tanque de volume por equipe treinada?',
        Answer: 'white',
      },
      '6-2 b 69': {
        Question:
          'Existe no procedimento operacional a informação da necessidade de verificação da válvula de segurança e pressostato do tanque de volume? ',
        Answer: 'white',
      },
      '6-3 a 70': {
        Question:
          'A drenagem do tanque de volume é realizada periodicamente conforme procedimento?',
        Answer: 'white',
      },
      '6-3 b 71': {
        Question:
          'A equipe é orientada e treinada quanto a periodicidade de realização da drenagem do tanque de volume?',
        Answer: 'white',
      },

    },
    'CO-1-Intoxicação com lesão, alteração funcional e potencial de fatalidade': {
      '7-1 a 72': {
        Question:
          'Existe treinamento para a equipe de mergulho a bordo da frente/embarcação com ênfase ao plano de resposta a emergência contemplando um cenário de incêndio?',
        Answer: 'white',
      },
      '7-1 b 73': {
        Question:
          'Os mergulhadores são orientados pelos supervisores para estarem sempre atualizados com o plano de emergência para em caso de alguma emergência a bordo da frente/embarcação seguirem o protocolo estabelecido?',
        Answer: 'white',
      },
      '7-1 c 74': {
        Question:
          'São realizados simulados com a equipe de mergulho em cenário de contingência com o objetivo de manter o equilíbrio emocional da equipe?',
        Answer: 'white',
      },
      '7-2 a 75': {
        Question:
          'Existe monitoramento do volume de ar comprimido mantido como suprimento de emergência nos cilindros contidos nos quadros de emergência?',
        Answer: 'white',
      },
      '7-2 b 76': {
        Question:
          'São observadas as validades dos cilindros de ar comprimido e dos quadros que compõem o sistema de suprimento de emergência para os mergulhadores?',
        Answer: 'white',
      },
      '7-3 a 77': {
        Question:
          'São mantidos extintores de incêndio na câmara hiperbárica e nas suas proximidades?',
        Answer: 'white',
      },
      '7-3 b 78': {
        Question:
          'Os extintores portáteis do interior da câmara hiperbárica e das proximidades são inspecionados e testados periodicamenete como definido em norma?',
        Answer: 'white',
      },
      '7-3 c 79': {
        Question:
          'Existe controle da data de validade dos extintores que compõem o sistema de combate a incêndio do sistema de mergulho? ',
        Answer: 'white',
      },
      '7-4 a 80': {
        Question:
          'Existe no controle de estoque a verificação da quantidade do suprimento autônomo de ar comprimido na frente/embarcação de mergulho? ',
        Answer: 'white',
      },
      '7-4 b 81': {
        Question:
          'No Plano de manutenção do sistema de mergulho é contemplado a verificação da validade e da manutenção do suprimento autônomo de ar comprimido?',
        Answer: 'white',
      },
      '7-4 c 82': {
        Question:
          'Há disponibilidade de pelo menos 5 conjuntos de suprimento autônomo de ar comprimido na frente/embarcação de mergulho? ',
        Answer: 'white',
      },
      '7-4 d 83': {
        Question:
          'São realizados os testes hidrostáticos do suprimento autônomo de ar comprimido da frente/embarcação de mergulho conforme as normas vigentes?',
        Answer: 'white',
      },
      '7-5 a 84': {
        Question:
          'Existe procedimento referente a captação dos quadros de ar de emergência (alta pressão) para a câmara hiperbárica?',
        Answer: 'white',
      },
      '7-5 b 85': {
        Question:
          'É realizada a ventilação do interior da câmara hiperbárica contaminada através dos quadros de ar comprimido de emergência?',
        Answer: 'white',
      },
      '7-5 c 86': {
        Question:
          'Os supervisores de mergulho são capacitados e treinados no plano de resposta a emergência, abortar o mergulho em andamento, retornar a cesta, sinete ou a superfície com o auxílio de Bellman ou mergulhador de emergência?',
        Answer: 'white',
      },
      '7-6 a 87': {
        Question:
          'Existe procedimento referente a captação dos quadros de ar de emergência (alta pressão) para o mergulho?',
        Answer: 'white',
      },
      '7-6 b 88': {
        Question:
          'Existe controle dos testes hidrostáticos das garrafas do quadro de emergência para o mergulho?',
        Answer: 'white',
      },
      '7-6 c 89': {
        Question:
          'Existe controle dos certificados das mangueiras de alta pressão dos quadros de ar de emergência para o mergulho?',
        Answer: 'white',
      },
      '7-6 d 90': {
        Question:
          'Existe controle de manutenção das válvulas de regulagem dos quadros de ar de emergência para o mergulho?',
        Answer: 'white',
      },
      '7-7 a 91': {
        Question:
          'São garantidos em número suficiente os BIBS overboard, a fim de suprir ao número de mergulhadores na câmara em atendimento as normas vigentes?',
        Answer: 'white',
      },
      '7-7 b 92': {
        Question:
          'Os BIBS overborard são manutenidos todas as vezes em que a câmara esteja na superfície?',
        Answer: 'white',
      },
      '7-7 c 93': {
        Question:
          'São mantidos em estoque BIBS overbord e seus respectivos kits de manutenção?',
        Answer: 'white',
      },
      '7-11 a 103': {
        Question:
          'Existem, ao menos, dois membros na equipe de mergulho capacitados e treinados em suporte básico a vida no atendimento inicial a emergência subaquática atendendo as normas vigentes?',
        Answer: 'white',
      },
      '7-11 b 104': {
        Question:
          'É cobrado eficácia nos simulados dos planos de resposta a emergência com ênfase na comunicação adequada?',
        Answer: 'white',
      },
      '7-11 c 105': {
        Question:
          'O treinamento de reciclagem em atendimento a emergências de primeiros socorros atende as orientações de acordo com as normas vigentes?',
        Answer: 'white',
      },
      '7-11 d 106': {
        Question:
          'São realizados simulados de primeiros socorros com as equipes de mergulho com ênfase na saúde dos mergulhadores?',
        Answer: 'white',
      },
      '7-12 a 107': {
        Question:
          'Há profissional de saúde de nível superior devidamente habilitado na frente / embarcação de mergulho?',
        Answer: 'white',
      },
      '7-12 b 108': {
        Question:
          ' O profissional de saúde é capacitado e treinado em suporte avançado de vida e atendimento a emergência subaquática?',
        Answer: 'white',
      },
      '7-13 a 109': {
        Question:
          'A embarcação possui enfermaria que atenda aos requisitos da Normam-01, da NR-30 e demais legislações pertinentes? ',
        Answer: 'white',
      },
      '7-13 b 110': {
        Question:
          'A enfermaria possui quantidade de equipamentos e medicamentos que atende a lista de itens mínimos de enfermaria padronizada pela Petrobras para embarcações de mergulho? ',
        Answer: 'white',
      },
      '7-14 a 111': {
        Question:
          'Os meios de comunicação com o suporte médico em terra são adequados e tem backup?',
        Answer: 'white',
      },
      '7-14 b 112': {
        Question:
          'No procedimento de contingência está previsto o plano de resposta a emergência médica com os recursos de contato telefônico ou por vídeo?',
        Answer: 'white',
      },
      '7-14 c 113': {
        Question:
          'Existe plano de atendimento médico emergencial na empresa 24 horas para atender as equipes embarcadas no offshore?',
        Answer: 'white',
      },
      '7-15 a 114': {
        Question:
          'Existe procedimento específico para em caso de acidente com necessidade de remoção e transporte para hospital ou clínica em terra?',
        Answer: 'white',
      },
      '7-15 b 115': {
        Question:
          'Os supervisores estão aptos a orientar os responsáveis pelo transporte de acidentados via aérea, de que após a realização de mergulho, o voo deve cumprir um limite de altitude durante o voo em cumprimento da NORMAM?',
        Answer: 'white',
      },
      '7-15 c 116': {
        Question:
          'O Plano de contingência garante acesso a rede hospitalar especializada e a disponibilidade de recursos para um tratamento específico até o pronto reestabelecimento do acidentado?',
        Answer: 'white',
      },
      '7-15 d 117': {
        Question:
          'Existe procedimento da empresa que propicie a remoção do acidentado embarcado até a rede hospitalar?',
        Answer: 'white',
      },
      '7-16 a 118': {
        Question:
          'A empresa através do seu RH tem como procedimento a avaliação médica criteriosa para liberação das atividades laborais do acidentado?',
        Answer: 'white',
      },
      '7-16 b 119': {
        Question:
          'O colaborador acidentado bem como sua família são acompanhados pelo RH no caso de um afastamento prolongado?',
        Answer: 'white',
      },

    },
    'CO-2-Queimadura com lesão, alteração funcional, potencial de fatalidade e dano ao patrimônio': {
      '8-1 a 120': {
        Question:
          'Na frente/embarcação de mergulho existe brigada de incêndio e a mesma esta inserida no plano de resposta a emergência?',
        Answer: 'white',
      },
      '8-1 b 121': {
        Question:
          'Existe treinamento para a equipe de mergulho a bordo da frente/embarcação com ênfase ao plano de resposta a emergência contemplando um cenário de incêndio?',
        Answer: 'white',
      },
      '8-1 c 122': {
        Question:
          'Os mergulhadores são orientados pelos supervisores para estarem sempre atualizados com o plano de emergência para em caso de alguma emergência a bordo da frente/embarcação seguirem o protocolo estabelecido?',
        Answer: 'white',
      },
      '8-1 d 123': {
        Question:
          'São realizados simulados com a equipe de mergulho em cenário de contingência com o objetivo de manter o equilíbrio emocional da equipe?',
        Answer: 'white',
      },
      '8-1 e 124': {
        Question:
          'Existe treinamento especifico das equipes de mergulho para identificação das fontes de calor e fontes de ignição nas proximidades dos equipamentos de mergulho, em especial da câmara hiperbárica e seus acessórios para prevenção de incêndio/explosão de origem externa? ',
        Answer: 'white',
      },
      '8-2 a 125': {
        Question:
          'São mantidos extintores de incêndio na câmara hiperbárica e nas suas proximidades?',
        Answer: 'white',
      },
      '8-2 b 126': {
        Question:
          'Os extintores portáteis do interior da câmara hiperbárica e das proximidades são inspecionados e testados periodicamenete como definido em norma?',
        Answer: 'white',
      },
      '8-2 c 127': {
        Question:
          'Existe controle da data de validade dos extintores que compõem o sistema de combate a incêndio do sistema de mergulho? ',
        Answer: 'white',
      },
      '8-6 a 137': {
        Question:
          'Existem, ao menos, dois membros na equipe de mergulho capacitados e treinados em suporte básico a vida no atendimento inicial a emergência subaquática atendendo as normas vigentes?',
        Answer: 'white',
      },
      '8-6 b 138': {
        Question:
          'É cobrado eficácia nos simulados dos planos de resposta a emergência com ênfase na comunicação adequada?',
        Answer: 'white',
      },
      '8-6 c 139': {
        Question:
          'O treinamento de reciclagem em atendimento a emergências de primeiros socorros atende as orientações de acordo com as normas vigentes?',
        Answer: 'white',
      },
      '8-6 d 140': {
        Question:
          'São realizados simulados de primeiros socorros com as equipes de mergulho com ênfase na saúde dos mergulhadores?',
        Answer: 'white',
      },
      '8-7 a 141': {
        Question:
          'Há profissional de saúde de nível superior devidamente habilitado na frente / embarcação de mergulho?',
        Answer: 'white',
      },
      '8-7 b 142': {
        Question:
          'O profissional de saúde é capacitado e treinado em suporte avançado de vida e atendimento a emergência subaquática?',
        Answer: 'white',
      },
      '8-8 a 143': {
        Question:
          'A embarcação possui enfermaria que atenda aos requisitos da Normam-01, da NR-30 e demais legislações pertinentes? ',
        Answer: 'white',
      },
      '8-8 b 144': {
        Question:
          'A enfermaria possui quantidade de equipamentos e medicamentos que atende a lista de itens mínimos de enfermaria padronizada pela Petrobras para embarcações de mergulho? ',
        Answer: 'white',
      },
      '8-9 a 145': {
        Question:
          'Os meios de comunicação com o suporte médico em terra são adequados e tem backup?',
        Answer: 'white',
      },
      '8-9 b 146': {
        Question:
          'No procedimento de contingência está previsto o plano de resposta a emergência médica com os recursos de contato telefônico ou por vídeo?',
        Answer: 'white',
      },
      '8-9 c 147': {
        Question:
          'Existe plano de atendimento médico emergencial na empresa 24 horas para atender as equipes embarcadas no offshore?',
        Answer: 'white',
      },
      '8-10 a 148': {
        Question:
          'Existe procedimento específico para em caso de acidente com necessidade de remoção e transporte para hospital ou clínica em terra?',
        Answer: 'white',
      },
      '8-10 b 149': {
        Question:
          'Os supervisores estão aptos a orientar os responsáveis pelo transporte de acidentados via aérea, de que após a realização de mergulho, o voo deve cumprir um limite de altitude durante o voo em cumprimento da NORMAM?',
        Answer: 'white',
      },
      '8-10 c 150': {
        Question:
          'O Plano de contingência garante acesso a rede hospitalar especializada e a disponibilidade de recursos para um tratamento específico até o pronto reestabelecimento do acidentado?',
        Answer: 'white',
      },
      '8-10 d 151': {
        Question:
          'Existe procedimento da empresa que propicie a remoção do acidentado embarcado até a rede hospitalar?',
        Answer: 'white',
      },
      '8-11 a 152': {
        Question:
          'A empresa através do seu RH tem como procedimento a avaliação médica criteriosa para liberação das atividades laborais do acidentado?',
        Answer: 'white',
      },
      '8-11 b 153': {
        Question:
          'O colaborador acidentado bem como sua família são acompanhados pelo RH no caso de um afastamento prolongado?',
        Answer: 'white',
      },


    },
    'CO-3-Doença descompressiva': {
      '9-1 a 154': {
        Question:
          'Existe treinamento para a equipe de mergulho a bordo da frente/embarcação com ênfase ao plano de resposta a emergência contemplando um cenário de incêndio?',
        Answer: 'white',
      },
      '9-1 b 155': {
        Question:
          'Os mergulhadores são orientados pelos supervisores para estarem sempre atualizados com o plano de emergência para em caso de alguma emergência a bordo da frente/embarcação seguirem o protocolo estabelecido? ',
        Answer: 'white',
      },
      '9-1 c 156': {
        Question:
          'São realizados simulados com a equipe de mergulho em cenário de contingência com o objetivo de manter o equilíbrio emocional da equipe?',
        Answer: 'white',
      },
      '9-4 a 164': {
        Question:
          'Existem, ao menos, dois membros na equipe de mergulho capacitados e treinados em suporte básico a vida no atendimento inicial a emergência subaquática atendendo as normas vigentes?',
        Answer: 'white',
      },
      '9-4 b 165': {
        Question:
          'É cobrado eficácia nos simulados dos planos de resposta a emergência com ênfase na comunicação adequada?',
        Answer: 'white',
      },
      '9-4 c 166': {
        Question:
          'O treinamento de reciclagem em atendimento a emergências de primeiros socorros atende as orientações de acordo com as normas vigentes?',
        Answer: 'white',
      },
      '9-4 d 167': {
        Question:
          'São realizados simulados de primeiros socorros com as equipes de mergulho com ênfase na saúde dos mergulhadores?',
        Answer: 'white',
      },
      '9-5 a 168': {
        Question:
          'Há profissional de saúde de nível superior devidamente habilitado na frente / embarcação de mergulho?',
        Answer: 'white',
      },
      '9-5 b 169': {
        Question:
          'O profissional de saúde é capacitado e treinado em suporte avançado de vida e atendimento a emergência subaquática? ',
        Answer: 'white',
      },
      '9-6 a 170': {
        Question:
          'A embarcação possui enfermaria que atenda aos requisitos da Normam-01, da NR-30 e demais legislações pertinentes? ',
        Answer: 'white',
      },
      '9-6 b 171': {
        Question:
          'A enfermaria possui quantidade de equipamentos e medicamentos que atende a lista de itens mínimos de enfermaria padronizada pela Petrobras para embarcações de mergulho?',
        Answer: 'white',
      },
      '9-7 a 172': {
        Question:
          'Os meios de comunicação com o suporte médico em terra são adequados e tem backup?',
        Answer: 'white',
      },
      '9-7 b 173': {
        Question:
          'No procedimento de contingência está previsto o plano de resposta a emergência médica com os recursos de contato telefônico ou por vídeo?',
        Answer: 'white',
      },
      '9-7 c 174': {
        Question:
          'Existe plano de atendimento médico emergencial na empresa 24 horas para atender as equipes embarcadas no offshore?',
        Answer: 'white',
      },
      '9-8 a 175': {
        Question:
          'É previsto no procedimento de emergência da empresa, antes de uma operação de mergulho, que haja a verificação de uma unidade mais próxima das operações adequada a receber o mergulhador vitimado com disponibilidade de resgate aeromédico?',
        Answer: 'white',
      },
      '9-8 b 176': {
        Question:
          'Os supervisores estão aptos a orientar os responsáveis pelo transporte de acidentados via aérea, de que após a realização de mergulho, o voo deve cumprir um limite de altitude durante o voo em cumprimento da NORMAM-15?',
        Answer: 'white',
      },
      '9-9 a 177': {
        Question:
          'São realizados treinamentos e capacitação da equipe de mergulho no suporte básico a vida e atendimento inicial a emergência subáquatica?',
        Answer: 'white',
      },
      '9-9 b 178': {
        Question:
          'São realizados simulados de mergulho em cenários de emergência com mergulhador?',
        Answer: 'white',
      },
      '9-9 c 179': {
        Question:
          'Os planos de resposta a emergência e comunicação adequada estão inseridos no plano de contingência?',
        Answer: 'white',
      },
      '9-10 a 180': {
        Question:
          'Existe procedimento específico para em caso de acidente com necessidade de remoção e transporte para hospital ou clínica em terra?',
        Answer: 'white',
      },
      '9-10 b 181': {
        Question:
          ' Os supervisores estão aptos a orientar os responsáveis pelo transporte de acidentados via aérea, de que após a realização de mergulho, o voo deve cumprir um limite de altitude durante o voo em cumprimento da NORMAM?',
        Answer: 'white',
      },
      '9-10 c 182': {
        Question:
          'O Plano de contingência garante acesso a rede hospitalar especializada e a disponibilidade de recursos para um tratamento específico até o pronto reestabelecimento do acidentado?',
        Answer: 'white',
      },
      '9-10 d 183': {
        Question:
          'Existe procedimento da empresa que propicie a remoção do acidentado embarcado até a rede hospitalar?',
        Answer: 'white',
      },
      '9-11 a 184': {
        Question:
          'A empresa através do seu RH tem como procedimento a avaliação médica criteriosa para liberação das atividades laborais do acidentado?',
        Answer: 'white',
      },
      '9-11 b 185': {
        Question:
          'O colaborador acidentado bem como sua família são acompanhados pelo RH no caso de um afastamento prolongado?',
        Answer: 'white',
      },



    },
  };

  let listKeysQuestionIncendioExplosaoCamara=[
    {
      'AM-1 - Elevado percentual de O2 na câmara (>25%)': [
        { '1-1': { Check: [], Answer: 'white' } },
        { '1-2': { Check: [], Answer: 'white' } },
        { '1-3': { Check: [], Answer: 'white' } },
        { '1-4': { Check: [], Answer: 'white' } },
        { '1-5': { Check: [], Answer: 'white' } },
        { '1-6': { Check: [], Answer: 'white' } },
      ].reverse(),
      'AM-2 – Materiais ou substâncias inflamáveis dentro da câmara hiperbárica': [
        { '2-1': { Check: [], Answer: 'white' } },
        { '2-2': { Check: [], Answer: 'white' } },

      ].reverse(),
      'AM-3 - Energia elétrica estática dentro da câmara hiperbárica': [
        { '3-1': { Check: [], Answer: 'white' } },
        { '3-2': { Check: [], Answer: 'white' } },
        { '3-3': { Check: [], Answer: 'white' } },
        { '3-4': { Check: [], Answer: 'white' } },
      ].reverse(),
      'AM-4-Incêndio/explosão de origem externa': [
        { '4-1': { Check: [], Answer: 'white' } },
        { '4-2': { Check: [], Answer: 'white' } },
        { '4-3': { Check: [], Answer: 'white' } },
        { '4-4': { Check: [], Answer: 'white' } },

      ].reverse(),
      'AM-5-Vazamento de Cilindro de O2': [
        { '5-1': { Check: [], Answer: 'white' } },
        { '5-2': { Check: [], Answer: 'white' } },
        { '5-3': { Check: [], Answer: 'white' } },
        { '5-4': { Check: [], Answer: 'white' } },

      ].reverse(),
      'AM-6-Vazamento de tanque de volume': [
        { '6-1': { Check: [], Answer: 'white' } },
        { '6-2': { Check: [], Answer: 'white' } },
        { '6-3': { Check: [], Answer: 'white' } },

      ].reverse(),
      'CO-1-Intoxicação com lesão, alteração funcional e potencial de fatalidade': [
        { '7-1': { Check: [], Answer: 'white' } },
        { '7-2': { Check: [], Answer: 'white' } },
        { '7-3': { Check: [], Answer: 'white' } },
        { '7-4': { Check: [], Answer: 'white' } },
        { '7-5': { Check: [], Answer: 'white' } },
        { '7-6': { Check: [], Answer: 'white' } },
        { '7-7': { Check: [], Answer: 'white' } },
        { '7-8': { Check: [], Answer: 'white' } },
        { '7-9': { Check: [], Answer: 'white' } },
        { '7-10': { Check: [], Answer: 'white' } },
        { '7-11': { Check: [], Answer: 'white' } },
        { '7-12': { Check: [], Answer: 'white' } },
        { '7-13': { Check: [], Answer: 'white' } },
        { '7-14': { Check: [], Answer: 'white' } },
        { '7-15': { Check: [], Answer: 'white' } },
        { '7-16': { Check: [], Answer: 'white' } },

      ],
      'CO-2-Queimadura com lesão, alteração funcional, potencial de fatalidade e dano ao patrimônio': [
        { '8-1': { Check: [], Answer: 'white' } },
        { '8-2': { Check: [], Answer: 'white' } },
        { '8-3': { Check: [], Answer: 'white' } },
        { '8-4': { Check: [], Answer: 'white' } },
        { '8-5': { Check: [], Answer: 'white' } },
        { '8-6': { Check: [], Answer: 'white' } },
        { '8-7': { Check: [], Answer: 'white' } },
        { '8-8': { Check: [], Answer: 'white' } },
        { '8-9': { Check: [], Answer: 'white' } },
        { '8-10': { Check: [], Answer: 'white' } },
        { '8-11': { Check: [], Answer: 'white' } },


      ],

      'CO-3-Doença descompressiva': [
        { '9-1': { Check: [], Answer: 'white' } },
        { '9-2': { Check: [], Answer: 'white' } },
        { '9-3': { Check: [], Answer: 'white' } },
        { '9-4': { Check: [], Answer: 'white' } },
        { '9-5': { Check: [], Answer: 'white' } },
        { '9-6': { Check: [], Answer: 'white' } },
        { '9-7': { Check: [], Answer: 'white' } },
        { '9-8': { Check: [], Answer: 'white' } },
        { '9-9': { Check: [], Answer: 'white' } },
        { '9-10': { Check: [], Answer: 'white' } },
        { '9-11': { Check: [], Answer: 'white' } },


      ]

    }]


  export {TitlesAndQuestionsIncendioExplosaoCamara,listKeysQuestionIncendioExplosaoCamara,titleFormIncendioExplosaoCamara,titleHazardIncendioExplosaoCamara,SecondIdIncendioExplosaoCamara,listAMCOIncendioExplosaoCamara}

import { TitlesAndQuestionsContatoAcidentalMergulhador } from "../formsModel/formContatoAcidentalMergulhador";



const myArrayVerification=(myArray,setArray,groupName,AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm)=>{
  let groupNameSplit = groupName.split(' ');
  myArray.forEach(el => {
    for(let FirstKey of Object.keys(el) ){
      for (let SecondKey of Object.keys(el[FirstKey])){
        let KeySplit = SecondKey.split(' ');
        if (groupNameSplit[0] === KeySplit[0]) {
          if(!questionsSelected.includes(groupName)){
            for(let el of listKeysQuestion[0][FirstKey]){
              if (Object.keys(el)[0] === groupNameSplit[0]) {
                let dict = {};
                dict[groupName] = AnswerCheck;
                AnswerForm.push(dict);
                setAnswerForm([...AnswerForm]);
                AnswerQuestionForm.push({tagQuestion:groupName,question:TitlesAndQuestionsContatoAcidentalMergulhador[FirstKey][groupName] ? TitlesAndQuestionsContatoAcidentalMergulhador[FirstKey][groupName].Question:"Pergunta não encontrada no modelo atual"})
                setAnswerQuestionForm([...AnswerQuestionForm])


                el[groupNameSplit[0]]['Check'].push(AnswerCheck);
                questionsSelected.push(groupName);
                setListKeys([...listKeysQuestion]);
                setquestionsSelected([...questionsSelected]);
                break;
              }
            }
          }else{
            for(let el of listKeysQuestion[0][FirstKey] ){
              if (Object.keys(el)[0] === groupNameSplit[0]) {
                let dict = {};
                dict[groupName] = AnswerCheck;
                AnswerForm[questionsSelected.indexOf(groupName)]=dict;
                setAnswerForm([...AnswerForm]);
               el[groupNameSplit[0]]['Check'][el[groupNameSplit[0]]['Check'].length-1]=AnswerCheck
                setListKeys([...listKeysQuestion]);
                break;
              }
            }
          }

            break;
        }


      }

      for(let el of listKeysQuestion[0][FirstKey] ){
        if (Object.keys(el)[0] === groupNameSplit[0]) {
          if (el[groupNameSplit[0]]['Check'].includes('No') && el[groupNameSplit[0]]['Check'].includes('Yes')) {
            el[groupNameSplit[0]]['Answer'] = 'yellow';
            setListKeys([...listKeysQuestion]);
          } else if(el[groupNameSplit[0]]['Check'].includes('Yes') && !el[groupNameSplit[0]]['Check'].includes('No') ){
            el[groupNameSplit[0]]['Answer'] = 'green';
            setListKeys([...listKeysQuestion]);
          }else if(!el[groupNameSplit[0]]['Check'].includes('Yes') && el[groupNameSplit[0]]['Check'].includes('No') ){
            el[groupNameSplit[0]]['Answer'] = 'red';
            setListKeys([...listKeysQuestion]);
          } else {
            el[groupNameSplit[0]]['Answer'] = 'white';
            setListKeys([...listKeysQuestion]);
          }
        }
      }


    }

  });
      setArray([...myArray]);
}

const VerificationContatoAcidentalMergulhador= (listKeysQuestion, setListKeys,groupName,AnswerCheck,myArray,setArray,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm)=>{

    if(groupName==="1-4 g 22"){
        myArrayVerification(myArray,setArray,'2-3 g 53',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
    }else if(groupName==="1-6 a 28"){
        myArrayVerification(myArray,setArray,'2-3 a 47',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
        myArrayVerification(myArray,setArray,'4-3 a 83',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
        myArrayVerification(myArray,setArray,'5-2 a 98',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
    }else if(groupName==="1-6 b 29"){
        myArrayVerification(myArray,setArray,'2-3 b 48',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
        myArrayVerification(myArray,setArray,'4-3 b 84',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);

    }else if(groupName==="1-6 c 30"){
        myArrayVerification(myArray,setArray,'2-3 c 49',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
        myArrayVerification(myArray,setArray,'4-3 c 85',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
        myArrayVerification(myArray,setArray,'5-2 c 100',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);

    }else if(groupName==="1-6 d 31"){
        myArrayVerification(myArray,setArray,'2-3 d 50',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
        myArrayVerification(myArray,setArray,'4-3 d 86',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
        myArrayVerification(myArray,setArray,'5-2 d 101',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);

    }else if(groupName==="1-6 e 32"){
        myArrayVerification(myArray,setArray,'2-3 e 51',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
        myArrayVerification(myArray,setArray,'4-3 e 87',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
        myArrayVerification(myArray,setArray,'5-2 e 102',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);

    }else if(groupName==="1-6 f 33"){
        myArrayVerification(myArray,setArray,'2-3 f 52',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
        myArrayVerification(myArray,setArray,'4-3 f 88',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);
        myArrayVerification(myArray,setArray,'5-2 f 103',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);

    }else if(groupName==="3-4 a 72"){
        myArrayVerification(myArray,setArray,'4-4 a 89',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);


    }else if(groupName==="3-4 b 73"){
        myArrayVerification(myArray,setArray,'4-4 b 90',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);


    }else if(groupName==="3-4 d 75"){
        myArrayVerification(myArray,setArray,'4-4 d 92',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);


    }else if(groupName==="3-4 c 74"){
        myArrayVerification(myArray,setArray,'4-4 c 91',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);


    }else if(groupName==="3-4 e 76"){
        myArrayVerification(myArray,setArray,'4-4 e 93',AnswerCheck,listKeysQuestion,setListKeys,questionsSelected,setquestionsSelected,AnswerForm,setAnswerForm,AnswerQuestionForm,setAnswerQuestionForm);


    }


}

export default VerificationContatoAcidentalMergulhador;

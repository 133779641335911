
const titleFormExposicaoMergulhadoresContaminantes="Exposição dos Mergulhadores aos Contaminantes"
const titleHazardExposicaoMergulhadoresContaminantes="Produtos Tóxicos";
const SecondIdExposicaoMergulhadoresContaminantes="ExposicaoMergulhadoresContaminantes";
const listAMCOExposicaoMergulhadoresContaminantes=['AM-1 - Presença de gases ou substâncias inadequadas em área de captação de ar atmosférico para suprimento respiratório','CO-1 - Contaminação do mergulhador submerso com alteração funcional'];
let TitlesAndQuestionsExposicaoMergulhadoresContaminantes = {
    'AM-1 - Presença de gases ou substâncias inadequadas em área de captação de ar atmosférico para suprimento respiratório': {
      '1-1 a 1': {
        Question:
          'Na APR, PT, CLM está previsto a possível exposição de mergulhadores a contaminantes?',
        Answer: 'white',
      },
      '1-1 b 2': {
        Question:
          'É realizada uma APR contemplando para cada cenário específico a presença de gases contaminantes?',
        Answer: 'white',
      },
      '1-1 c 3': {
        Question:
          ' Ao realizar a visita técnica em uma UEP para elaboração de APR é avaliado qual será o local mais apropriado para a captação de ar atmosférico?',
        Answer: 'white',
      },
      '1-2 a 4': {
        Question:
          ' Os procedimentos operacionais de mergulho seguem as normas vigentes quanto ao posicionamento correto da captação de ar atmosférico para os mergulhadores? ',
        Answer: 'white',
      },
      '1-2 a 5': {
        Question:
          'Na FUEP é utilizada a boa prática de dispor de um membro da equipe próximo ao ponto de captação do ar atmosférico? ',
        Answer: 'white',
      },
      '1-2 b 6': {
        Question:
          ' É utilizado alarme multigases junto ao ponto de captação do ar atmosférico? ',
        Answer: 'white',
      },
      '1-2 c 7': {
        Question:
          ' No controle de mergulho existe algum tipo de alarme que acuse a presença de contaminantes na captação do ar atmosférico?    ',
        Answer: 'white',
      },
      '1-2 d 8': {
        Question: 'Para garantir a qualidade do ar captado é cumprido os requisitos da ABNT NBR 16401-3? ',
        Answer: 'white',
      },
      '1-2 e 9': {
        Question:
          'Em casos específicos onde haja risco de contaminação do ar captado pelo compressor por fatores externos ao sistema de mergulho, a equipe de mergulho é orientada a utilizar como suprimento de ar um quadro de cilindros de alta pressão composto por no mínimo oito cilindros para mergulhos até 30m e mínimo de doze cilindros para mergulhos de 30m até 50m que atendam aos requisitos da Normam 15? ',
        Answer: 'white',
      },
      '1-2 f 10': {
        Question:
          'A equipe de mergulho possui conhecimento sobre os requisitos das normas vigentes que contemplem a captação do ar atmosférico para o mergulho? ',
        Answer: 'white',
      },
      '1-3 a 11': {
        Question:
          'Os supervisores de mergulho são treinados e têm competência para analisar o ar comprimido enviado aos mergulhadores no painel de controle?',
        Answer: 'white',
      },
      '1-3 b 12': {
        Question:
          'O supervisor de mergulho é capacitado para identificar uma possível falha de funcionalidade do analisador de ar?',
        Answer: 'white',
      },
      '1-3 c 13': {
        Question:
          'Os treinamentos de análise do ar comprimido do painel de controle seguem um cronograma pré-estabelecido?',
        Answer: 'white',
      },
      '1-3 d 14': {
        Question:
          'Os analisadores de ar são calibrados e aferidos conforme as normas vigentes?',
        Answer: 'white',
      },
      '1-4 a 15': {
        Question:
          'No check list pré-operações de mergulho é monitorado presencialmente o ponto de captação do ar atmosférico?',
        Answer: 'white',
      },
      '1-4 b 16': {
        Question:
          'No procedimento de operações de mergulho está previsto o monitoramento presencial do ponto de captação de ar atmosférico durante as operações de mergulho?',
        Answer: 'white',
      },
      '1-4 c 17': {
        Question:
          'É salientado em DDS a importância da atenção que se deve ter com os possíveis lançamentos de contaminantes na atmosfera?',
        Answer: 'white',
      },
      '1-5 a 18': {
        Question:
          'É definido na APR todos os bloqueios que devem ser utilizados para mitigar a ameaça de presença de contaminantes no convés?',
        Answer: 'white',
      },

      '1-5 b 19': {
        Question:
          'Na PT diária é garantido que todos os bloqueios de lançamento de contaminantes na atmosfera estão ativos?',
        Answer: 'white',
      },
      '1-6 a 20': {
        Question:
          'Os cilindros de alta pressão (HP), quadros de ar comprimido e aqualung, são testados e inspecionados quanto a integridade física e operacional no check list pré-operações?',
        Answer: 'white',
      },
      '1-6 b 21': {
        Question:
          'No procedimento de mergulho constam os cuidados no manuseio, transporte e armazenamento dos cilindros de alta pressão (HP) utilizados nas operações de mergulho?',
        Answer: 'white',
      },
      '1-6 c 22': {
        Question:
          'Os cilindros de alta pressão (HP) estão com os seus certificados válidos?',
        Answer: 'white',
      },
      '1-6 d 23': {
        Question:
          'É realizada inspeção visual periódica do estado de conservação dos cilindros de alta pressão (HP)?',
        Answer: 'white',
      },


    },
    'AM-2 - Descarte de gases tóxicos como hidrocarbonetos, CO e H2S nas proximidades das unidades atendidas (emerso)': {
      '2-1 a 24': {
        Question:
          'São seguidos os protocolos de aproximação da embarcação de mergulho em unidades com descarte de gases tóxicos como hidrocarbonetos, CO e H2S?',
        Answer: 'white',
      },
      '2-1 b 25': {
        Question:
          'No teste de deriva da embarcação de mergulho é avaliada a direção do vento em relação a emissão de possíveis gases tóxicos como hidrocarbonetos, CO e H2S?',
        Answer: 'white',
      },
      '2-1 c 26': {
        Question:
          'No protocolo de aproximação é informado se a unidade oferece riscos de descarte de gases tóxicos como hidrocarbonetos, CO e H2S?',
        Answer: 'white',
      },
      '2-1 d 27': {
        Question:
          'Antes do início de uma operação de mergulho é analisada a atmosfera do convés com os analisadores multigases?',
        Answer: 'white',
      },
      '2-2 a 28': {
        Question:
          'Na APR, PT, CLM está previsto a possível exposição de mergulhadores a gases tóxicos como hidrocarbonetos, CO e H2S provenientes das UEPs?',
        Answer: 'white',
      },
      '2-2 b 29': {
        Question:
          'Todas as ações mitigadoras (PT, CLM, APR, DDS) contemplam o risco de exposição dos mergulhadores a gases tóxicos como hidrocarbonetos, CO e H2S?',
        Answer: 'white',
      },
      '2-2 c 30': {
        Question:
          'Na análise do risco de exposição do mergulhador a contaminantes é garantida pelos envolvidos o risco de gases tóxicos como hidrocarbonetos, CO e H2S?',
        Answer: 'white',
      },
      '2-3 a 31': {
        Question:
          'As equipes de mergulho são treinadas, conscientizadas e têm competência para utilizar da maneira correta o protocolo de aproximação da zona de 500m?',
        Answer: 'white',
      },
      '2-3 b 32': {
        Question:
          'Os pilotos da embarcação têm conhecimento e treinamento para cumprir o protocolo de aproximação da zona de 500m?',
        Answer: 'white',
      },
      '2-3 c 33': {
        Question:
          'Os controles de certificação dos detectores de gases utilizados a bordo constam na planilha de certificações dos equipamentos de mergulho?',
        Answer: 'white',
      },
      '2-3 d 34': {
        Question:
          'Os detectores de gases são aferidos conforme instruções do fabricante antes de sua utilização?',
        Answer: 'white',
      },
      '2-3 e 35': {
        Question:
          'Existe um número suficiente de detectores de gases a bordo para atender a abrangência necessária?',
        Answer: 'white',
      },
      '2-4 a 36': {
        Question:
          'Há na embarcação o sistema de duplicidade de portas nas passagens deck / controle de mergulho / SDSV?',
        Answer: 'white',
      },
      '2-4 b 37': {
        Question:
          'Há na embarcação algum sistema que isola de forma estanque a atmosfera externa do deck com o controle de mergulho?',
        Answer: 'white',
      },

    },
    'AM-3 – Presença de hidrocarbonetos e outros produtos tóxicos (submerso) em abertura de flanges e conectores de dutos e inspeção de casco de navio': {
      '3-1 a 38': {
        Question:
          'Em tarefas de abertura de flanges e conectores de dutos é solicitado ao setor responsável a emissão do CLL contemplando o risco de hidrocarbonetos e outros produtos tóxicos?',
        Answer: 'white',
      },
      '3-1 b 39': {
        Question:
          'É verificado o histórico de presença de hidrocarbonetos e outros produtos tóxicos no interior de caixa de mar conforme item especifico do CLM?',
        Answer: 'white',
      },
      '3-1 c 40': {
        Question:
          'É elaborada uma APR contemplando o risco de exposição do mergulhador a hidrocarbonetos e outros produtos tóxicos e a mesma é divulgada em DDS?',
        Answer: 'white',
      },
      '3-2 a 41': {
        Question:
          'A empresa tem disponível roupa seca e EPI adequado para realizar mergulho em ambiente com possível presença de contaminantes, incluindo sobressalentes destes?',
        Answer: 'white',
      },
      '3-2 b 42': {
        Question:
          'Nos procedimentos de mergulho estão previstos o uso de EPI adequado para operações de mergulho em águas potencialmente contaminadas (por exemplo, roupa seca)?',
        Answer: 'white',
      },
      '3-2 c 43': {
        Question:
          'Existe procedimento especifico para a descontaminação do EPI utilizado em operações de mergulho em águas contaminadas?',
        Answer: 'white',
      },
      '3-2 d 44': {
        Question:
          'Os mergulhadores possuem treinamento na utilização de roupa seca?',
        Answer: 'white',
      },
      '3-3 a 45': {
        Question:
          'É previsto inspeção prévia com câmera de vídeo do interior da caixa de mar antes da entrada do mergulhador para verificar a presença de contaminantes?',
        Answer: 'white',
      },
      '3-3 b 46': {
        Question:
          'Existe procedimento especifico que contemple a verificação prévia de contaminantes no interior da caixa de mar antes do início da atividade? ',
        Answer: 'white',
      },
      '3-4 a 47': {
        Question:
          'Existe inspetor qualificado em inspeção visual para verificação de possíveis vazamentos de dutos?',
        Answer: 'white',
      },
      '3-4 b 48': {
        Question:
          'O último relatório de inspeção do duto é consultado no planejamento da operação?',
        Answer: 'white',
      },
      '3-5 a 49': {
        Question:
          'No planejamento de operações de mergulho para a desconexão de dutos está previsto em procedimento a emissão do certificado de liberação de linhas (CLL) pelo setor responsável?',
        Answer: 'white',
      },
      '3-5 b 50': {
        Question:
          'No CLL é verificado se todas as ações de bloqueio foram implementadas?',
        Answer: 'white',
      },
      '3-5 c 51': {
        Question:
          'No procedimento de operações de mergulho consta a necessidade de CLL para operações de desconexão de dutos?',
        Answer: 'white',
      },
      '3-5 d 52': {
        Question:
          'O certificado de liberação de linha é divulgado em DDS antes de operações de mergulho para a desconexão de dutos?',
        Answer: 'white',
      },


    },
    'CO-1 - Contaminação do mergulhador submerso com alteração funcional': {
      '4-1 a 53': {
        Question:
          'Os equipamentos que fazem parte do sistema de comunicação e monitoramento do mergulhador são testados periodicamente? ',
        Answer: 'white',
      },
      '4-1 b 54': {
        Question:
          'Os equipamentos que compõem o sistema de monitoramento do mergulhador estão inseridos no plano de manutenção preventiva? ',
        Answer: 'white',
      },
      '4-1 c 55': {
        Question:
          'A câmera do capacete do mergulhador é testada antes de cada mergulho e é verificado se o conector se encontra em bom estado?',
        Answer: 'white',
      },
      '4-1 d 56': {
        Question:
          'A fonia é testada antes de cada mergulho e é verificado se os seus conectores estão em bom estado de integridade?',
        Answer: 'white',
      },
      '4-1 e 57': {
        Question:
          'Caso a comunicação ou câmera do capacete se tornem inoperantes durante o mergulho, o mesmo é abortado?',
        Answer: 'white',
      },
      '4-1 f 58': {
        Question:
          'Caso positivo na resposta acima, há procedimento contemplando as etapas da interrupção do mergulho?',
        Answer: 'white',
      },
      '4-2 a 59': {
        Question:
          'São realizados testes periódicos de funcionalidade do sistema de suprimento respiratório reserva de ar (secundário da superfície, secundário da cesta de mergulho/sinete e aqualungs)? ',
        Answer: 'white',
      },
      '4-2 b 60': {
        Question:
          'O mergulhador testa o sistema de suprimento respiratório reserva de ar (secundário da superfície, secundário da cesta de mergulho/sinete e aqualungs) a cada mergulho? ',
        Answer: 'white',
      },
      '4-2 c 61': {
        Question:
          'Os mergulhadores são treinados na utilização do sistema de suprimento respiratório reserva de ar (secundário da superfície, secundário da cesta de mergulho/sinete e aqualungs)?  ',
        Answer: 'white',
      },
      '4-2 d 62': {
        Question:
          'Os suprimentos respiratórios reservas de ar são carregados dentro dos parâmetros requeridos (secundário da superfície, secundário da cesta de mergulho/sinete e aqualungs)?',
        Answer: 'white',
      },
      '4-2 e 63': {
        Question:
          'O suprimento respiratório reserva de ar secundário da superfície passa por analisadores de contaminantes no painel de mergulho?',
        Answer: 'white',
      },
      '4-3 a 64': {
        Question:
          'É realizado treinamento e capacitação das equipes de mergulho para orientar a abertura do freeflow em caso de contaminação do ar comprimido fornecido aos mergulhadores?',
        Answer: 'white',
      },
      '4-3 b 65': {
        Question:
          'Após passar a alimentação para o suprimento secundário é orientado ao mergulhador uma ventilação via freeflow e esta ação está contemplada em procedimento?',
        Answer: 'white',
      },
      '4-3 c 66': {
        Question:
          'São realizados treinamentos de intervenção de resgate com mergulhador de emergência contemplando uma contaminação de suprimento respiratório?',
        Answer: 'white',
      },
      '4-4 a 67': {
        Question:
          'No procedimento de operação de mergulho esta contemplado o aborto do mergulho em caso de contaminação do ar comprimido fornecido aos mergulhadores?',
        Answer: 'white',
      },
      '4-4 b 68': {
        Question:
          'Os supervisores e equipe são treinados e capacitados para abortar o mergulho em caso de contaminação do ar comprimido fornecido aos mergulhadores?',
        Answer: 'white',
      },
      '4-4 c 69': {
        Question:
          'As equipes de mergulho são treinadas em percepção de risco e conscientizadas quanto aos riscos das suas atividades?',
        Answer: 'white',
      },
      '4-5 a 70': {
        Question:
          'Os supervisores são capacitados e treinados para orientar a realização do resgate do mergulhador pelo mergulhador de emergência? ',
        Answer: 'white',
      },
      '4-5 b 71': {
        Question:
          'O mergulhador de emergência na superfície, permanece equipado e pronto para intervir no menor tempo possível sendo num simulado ou durante uma intercorrência real? ',
        Answer: 'white',
      },
      '4-5 c 72': {
        Question:
          'Existe periodicidade para realização de simulado de resgate do mergulhador pelo mergulhador de emergência? ',
        Answer: 'white',
      },
      '4-5 d 73': {
        Question:
          'A empresa controla através do seu departamento de segurança o treinamento frequente envolvendo toda a equipe no procedimento de resgate do mergulhador através do mergulhador de emergência? ',
        Answer: 'white',
      },
      '4-5 e 74': {
        Question:
          'As equipes de mergulho são avaliadas quanto as condições físicas e mentais para resposta a emergência subquaticas?',
        Answer: 'white',
      },
      '4-5 f 75': {
        Question:
          'As equipes de mergulho são treinadas para em caso do resgate do mergulhador com alteração funcional devido a contaminação do ar comprimido fornecido?',
        Answer: 'white',
      },
      '4-6 a 76': {
        Question:
          'No procedimento de operação de mergulho esta contemplado o aborto do mergulho em caso de exposição do mergulhador a contaminantes?',
        Answer: 'white',
      },
      '4-6 b 77': {
        Question:
          'Os supervisores de mergulho são capacitados e treinados no plano de resposta a emergência, abortar o mergulho em andamento, retornar a cesta, sinete ou a superfície com o auxílio de bellman ou mergulhador de emergência?',
        Answer: 'white',
      },
      '4-6 c 78': {
        Question:
          'O plano de contingência prevê o passo a passo para abortar o mergulho e retornar a cesta, sinete ou a superfície com a ajuda do bellman ou o mergulhador de emergência?',
        Answer: 'white',
      },
      '4-6 d 79': {
        Question:
          'Na APR está previsto abortar o mergulho em caso de contaminação do ar do mergulhador? ',
        Answer: 'white',
      },
      '4-6 e 80': {
        Question:
          'É realizado treinamento simulando um retorno emergencial, abortar o mergulho e retornar para cesta/sinete ou sino com ajuda do bellman em um tempo menor do que o habitual nas operações rotineiras? ',
        Answer: 'white',
      },'4-6 f 81': {
        Question:
          'Os mergulhadores são orientados durante os DDS a Abortar o mergulho e retornar para cesta/sinete ou sino com ajuda do bellman caso necessário de forma rápida e segura?',
        Answer: 'white',
      },
      '4-7 a 82': {
        Question:
          'Existem, ao menos, dois membros na equipe de mergulho capacitados e treinados em suporte básico a vida, no atendimento inicial a emergências subaquáticas, atendendo assim as normas vigentes (Normam 15)?  ',
        Answer: 'white',
      },
      '4-7 b 83': {
        Question:
          'É cobrado eficácia nos simulados dos planos de resposta a emergência com ênfase na comunicação adequada? ',
        Answer: 'white',
      },
      '4-7 c 84': {
        Question:
          'O treinamento de reciclagem em atendimento a emergências de primeiros socorros atende as orientações de acordo com as normas vigentes? ',
        Answer: 'white',
      },
      '4-7 d 85': {
        Question:
          'São realizados simulados de primeiros socorros com as equipes de mergulho com ênfase na exposição a contaminantes visando a saúde dos mergulhadores? ',
        Answer: 'white',
      },

      '4-8 a 86': {
        Question:
          'Há profissional de saúde de nível superior devidamente habilitado na frente/embarcação de mergulho? ',
        Answer: 'white',
      },
      '4-8 b 87': {
        Question:
          'O profissional de saúde é capacitado e treinado em suporte avançado a vida e atendimento a emergência subaquática? ',
        Answer: 'white',
      },
      '4-9 a 88': {
        Question:
          ' A embarcação possui enfermaria que atenda aos requisitos da Normam-01, da NR-30 e demais legislações pertinentes? ',
        Answer: 'white',
      },
      '4-9 b 89': {
        Question:
          'A enfermaria possui quantidade de equipamentos e medicamentos que atende a lista de itens mínimos de enfermaria padronizada pela Petrobras para embarcações de mergulho? ',
        Answer: 'white',
      },
      '4-10 a 90': {
        Question:
          'Os meios de comunicação com o suporte médico em terra são adequados e tem backup? ',
        Answer: 'white',
      },
      '4-10 b 91': {
        Question:
          'No procedimento de contingência está previsto o plano de resposta a emergência médica com os recursos de contato telefônico ou por vídeo? ',
        Answer: 'white',
      },
      '4-10 c 92': {
        Question:
          'Existe plano de atendimento médico emergencial na empresa 24 horas para atender as equipes embarcadas no offshore? ',
        Answer: 'white',
      },
      '4-11 a 93': {
        Question:
          'Existe procedimento específico para em caso de acidente com necessidade de remoção e transporte para hospital ou clínica em terra? ',
        Answer: 'white',
      },

      '4-11 b 94': {
        Question:
          'Os supervisores estão aptos a orientar os responsáveis pelo transporte de acidentados via aérea, de que após a realização de mergulho, o voo deve cumprir um limite de altitude durante o voo em cumprimento da NORMAM-15? ',
        Answer: 'white',
      },

      '4-11 c 95': {
        Question:
          'O Plano de contingência garante acesso a rede hospitalar especializada e a disponibilidade de recursos para um tratamento específico até o pronto reestabelecimento do acidentado? ',
        Answer: 'white',
      },

      '4-11 d 96': {
        Question:
          'Existe procedimento da empresa que propicie a remoção do acidentado embarcado até a rede hospitalar? ',
        Answer: 'white',
      },
      '4-11 e 97': {
        Question:
          'O RH da empresa está capacitado a proporcionar todo o acompanhamento ao acidentado desde a internação até o seu retorno as atividades? ',
        Answer: 'white',
      },
      '4-12 a 98': {
        Question:
          'É realizada uma criteriosa avaliação médica especializada antes do retorno do mergulhador ao trabalho?  ',
        Answer: 'white',
      },
      '4-12 b 99': {
        Question:
          'O RH da empresa acompanha o processo de retorno do acidentado as suas atividades laborais após finalizado o tratamento? ',
        Answer: 'white',
      },

    },
    'CO-2 - Contaminação do mergulhador ainda emerso com alteração funcional': {
        '5-1 a 100': {
          Question:
            'São disponibilizadas máscaras respiratórias com filtros de proteção contra gases nocivos em quantidade suficiente para toda a equipe de mergulho que está no convés antes das atividades quando estiver dentro da zona de 500m?',
          Answer: 'white',
        },
        '5-1 b 101': {
          Question:
            'As equipes de mergulho são treinadas na utilização correta das máscaras respiratórias com filtros de proteção contra gases nocivos para uso em caso de necessidade?',
          Answer: 'white',
        },
        '5-1 c 102': {
          Question:
            'Existe um controle com registro de treinamento de como utilizar as máscaras respiratórias com filtros de proteção contra gases nocivos?',
          Answer: 'white',
        },
        '5-1 d 103': {
          Question:
            'As equipes de mergulho são treinadas para seguir os protocolos de aproximação de embarcação dentro da zona de 500m portando suas devidas máscaras respiratórias com filtros de proteção contra gases nocivos?',
          Answer: 'white',
        },
        '5-1 e 104': {
          Question:
            'Há um controle de validade e horas de utilização dos filtros das máscaras respiratórias?',
          Answer: 'white',
        },
        '5-2 a 105': {
          Question:
            'São disponibilizadas máscaras de respiração autônoma em todos os pontos onde estão presentes os membros da equipe de mergulho?',
          Answer: 'white',
        },
        '5-2 b 106': {
          Question:
            'Na frente/embarcação de mergulho há máscaras de respiração autônoma disponíveis de acordo com o número de membros da equipe?',
          Answer: 'white',
        },
        '5-3 a 107': {
          Question:
            'Existe procedimento de resposta a emergência contemplando a evacuação em cenário de vazamentos de gases durante as operações de mergulho?',
          Answer: 'white',
        },
        '5-3 b 108': {
          Question:
            'A equipe de mergulho realiza, na periodicidade adequada, o simulado no procedimento de evacuação em cenário de vazamento de gases durante as operações de mergulho?',
          Answer: 'white',
        },
        '5-4 a 109': {
          Question:
            'Existem, ao menos, dois membros na equipe de mergulho capacitados e treinados em suporte básico a vida, no atendimento inicial a emergências subaquáticas, atendendo assim as normas vigentes (Normam 15)? ',
          Answer: 'white',
        },
        '5-4 b 110': {
          Question:
            'É cobrado eficácia nos simulados dos planos de resposta a emergência com ênfase na comunicação adequada? ',
          Answer: 'white',
        },
        '5-4 c 111': {
          Question:
            'O treinamento de reciclagem em atendimento a emergências de primeiros socorros atende as orientações de acordo com as normas vigentes? ',
          Answer: 'white',
        },
        '5-4 d 112': {
          Question:
            'São realizados simulados de primeiros socorros com as equipes de mergulho com ênfase na exposição a contaminantes visando a saúde dos mergulhadores? ',
          Answer: 'white',
        },
        '5-5 a 113': {
          Question:
            'Há profissional de saúde de nível superior devidamente habilitado na frente/embarcação de mergulho? ',
          Answer: 'white',
        },
        '5-5 b 114': {
          Question:
            'O profissional de saúde é capacitado e treinado em suporte avançado a vida e atendimento a emergência subaquática? ',
          Answer: 'white',
        },
        '5-6 a 115': {
          Question:
            'A embarcação possui enfermaria que atenda aos requisitos da Normam-01, da NR-30 e demais legislações pertinentes? ',
          Answer: 'white',
        },
        '5-6 b 116': {
          Question:
            'A enfermaria possui quantidade de equipamentos e medicamentos que atende a lista de itens mínimos de enfermaria padronizada pela Petrobras para embarcações de mergulho? ',
          Answer: 'white',
        },
        '5-7 a 117': {
          Question:
            'Os meios de comunicação com o suporte médico em terra são adequados e tem backup? ',
          Answer: 'white',
        },
        '5-7 b 118': {
          Question:
            'No procedimento de contingência está previsto o plano de resposta a emergência médica com os recursos de contato telefônico ou por vídeo? ',
          Answer: 'white',
        },
        '5-7 c 119': {
          Question:
            'Existe plano de atendimento médico emergencial na empresa 24 horas para atender as equipes embarcadas no offshore? ',
          Answer: 'white',
        },
        '5-8 a 120': {
          Question:
            'Existe procedimento específico para em caso de acidente com necessidade de remoção e transporte para hospital ou clínica em terra? ',
          Answer: 'white',
        },
        '5-8 b 121': {
          Question:
            'Os supervisores estão aptos a orientar os responsáveis pelo transporte de acidentados via aérea, de que após a realização de mergulho, o voo deve cumprir um limite de altitude durante o voo em cumprimento da NORMAM-15? ',
          Answer: 'white',
        },
        '5-8 c 122': {
          Question:
            'O Plano de contingência garante acesso a rede hospitalar especializada e a disponibilidade de recursos para um tratamento específico até o pronto reestabelecimento do acidentado? ',
          Answer: 'white',
        },
        '5-8 d 123': {
          Question:
            'Existe procedimento da empresa que propicie a remoção do acidentado embarcado até a rede hospitalar? ',
          Answer: 'white',
        },
        '5-8 e 124': {
          Question:
            'O RH da empresa está capacitado a proporcionar todo o acompanhamento ao acidentado desde a internação até o seu retorno as atividades? ',
          Answer: 'white',
        },
        '5-9 a 125': {
          Question:
            'É realizada uma criteriosa avaliação médica especializada antes do retorno do mergulhador ao trabalho? ',
          Answer: 'white',
        },
        '5-9 b 126': {
          Question:
            'O RH da empresa acompanha o processo de retorno do acidentado as suas atividades laborais após finalizado o tratamento? ',
          Answer: 'white',
        },

      },
      'CO-3 - Fatalidade': {
        '6-1 a 127': {
          Question:
            'São disponibilizadas máscaras respiratórias com filtros de proteção contra gases nocivos em quantidade suficiente para toda a equipe de mergulho que está no convés antes das atividades quando estiver dentro da zona de 500m?',
          Answer: 'white',
        },
        '6-1 b 128': {
          Question:
            'As equipes de mergulho são treinadas na utilização correta das máscaras respiratórias com filtros de proteção contra gases nocivos para uso em caso de necessidade?',
          Answer: 'white',
        },
        '6-1 c 129': {
          Question:
            'Existe um controle com registro de treinamento de como utilizar as máscaras respiratórias com filtros de proteção contra gases nocivos?',
          Answer: 'white',
        },
        '6-1 d 130': {
          Question:
            'As equipes de mergulho são treinadas para seguir os protocolos de aproximação de embarcação dentro da zona de 500m portando suas devidas máscaras respiratórias com filtros de proteção contra gases nocivos?',
          Answer: 'white',
        },
        '6-1 e 131': {
          Question:
            'Há um controle de validade e horas de utilização dos filtros das máscaras respiratórias?',
          Answer: 'white',
        },
        '6-2 a 132': {
          Question:
            'São disponibilizadas máscaras de respiração autônoma em todos os pontos onde estão presentes os membros da equipe de mergulho?',
          Answer: 'white',
        },
        '6-2 b 133': {
          Question:
            'Na frente/embarcação de mergulho há máscaras de respiração autônoma disponíveis de acordo com o número de membros da equipe?',
          Answer: 'white',
        },
        '6-3 a 134': {
          Question:
            'São realizados testes periódicos de funcionalidade do sistema de suprimento respiratório reserva de ar (secundário da superfície, secundário da cesta de mergulho/sinete e aqualungs)? ',
          Answer: 'white',
        },
        '6-3 b 135': {
          Question:
            'O mergulhador testa o sistema de suprimento respiratório reserva de ar (secundário da superfície, secundário da cesta de mergulho/sinete e aqualungs) a cada mergulho? ',
          Answer: 'white',
        },
        '6-3 c 136': {
          Question:
            'Os mergulhadores são treinados na utilização do sistema de suprimento respiratório reserva de ar (secundário da superfície, secundário da cesta de mergulho/sinete e aqualungs)?  ',
          Answer: 'white',
        },
        '6-3 d 137': {
          Question:
            'Os suprimentos respiratórios reservas de ar são carregados dentro dos parâmetros requeridos (secundário da superfície, secundário da cesta de mergulho/sinete e aqualungs)?',
          Answer: 'white',
        },
        '6-3 e 138': {
          Question:
            'O suprimento respiratório reserva de ar secundário da superfície passa por analisadores de contaminantes no painel de mergulho?',
          Answer: 'white',
        },
        '6-4 a 139': {
          Question:
            'Existe procedimento de resposta a emergência contemplando a evacuação em cenário de vazamentos de gases durante as operações de mergulho?',
          Answer: 'white',
        },
        '6-4 b 140': {
          Question:
            'A equipe de mergulho realiza, na periodicidade adequada, o simulado no procedimento de evacuação em cenário de vazamento de gases durante as operações de mergulho?',
          Answer: 'white',
        },
        '6-5 a 141': {
          Question:
            'No procedimento de operação de mergulho esta contemplado o aborto do mergulho em caso de exposição do mergulhador a contaminantes?',
          Answer: 'white',
        },
        '6-5 b 142': {
          Question:
            'Os supervisores de mergulho são capacitados e treinados no plano de resposta a emergência, abortar o mergulho em andamento, retornar a cesta, sinete ou a superfície com o auxílio de Bellman ou mergulhador de emergência em caso de exposição do mergulhador a contaminantes? ',
          Answer: 'white',
        },
        '6-5 c 143': {
          Question:
            'O plano de contingência prevê o passo a passo para abortar o mergulho e retornar a cesta, sinete ou ao a superfície com a ajuda do bellman ou o mergulhador de emergência? ',
          Answer: 'white',
        },
        '6-5 d 144': {
          Question:
            'Na APR está previsto abortar o mergulho em caso de contaminação do ar do mergulhador? ',
          Answer: 'white',
        },
        '6-5 e 145': {
          Question:
            'É realizado treinamento simulando um retorno emergencial, abortar o mergulho e retornar para cesta/sinete ou sino com ajuda do bellman em um tempo menor do que o habitual nas operações rotineiras?',
          Answer: 'white',
        },
        '6-5 f 146': {
          Question:
            'Os mergulhadores são orientados durante os DDS a Abortar o mergulho e retornar para cesta/sinete ou sino com ajuda do bellman caso necessário de forma rápida e segura?',
          Answer: 'white',
        },
        '6-6 a 147': {
          Question:
            'Existem, ao menos, dois membros na equipe de mergulho capacitados e treinados em suporte básico a vida, no atendimento inicial a emergências subaquáticas, atendendo assim as normas vigentes (Normam 15)? ',
          Answer: 'white',
        },
        '6-6 b 148': {
          Question:
            'É cobrado eficácia nos simulados dos planos de resposta a emergência com ênfase na comunicação adequada? ',
          Answer: 'white',
        },
        '6-6 c 149': {
          Question:
            'O treinamento de reciclagem em atendimento a emergências de primeiros socorros atende as orientações de acordo com as normas vigentes? ',
          Answer: 'white',
        },
        '6-6 d 150': {
          Question:
            'São realizados simulados de primeiros socorros com as equipes de mergulho com ênfase na exposição a contaminantes visando a saúde dos mergulhadores? ',
          Answer: 'white',
        },
        '6-7 a 151': {
          Question:
            'Há profissional de saúde de nível superior devidamente habilitado na frente/embarcação de mergulho? ',
          Answer: 'white',
        },
        '6-7 b 152': {
          Question:
            'O profissional de saúde é capacitado e treinado em suporte avançado a vida e atendimento a emergência subaquática? ',
          Answer: 'white',
        },
        '6-8 a 153': {
          Question:
            'A embarcação possui enfermaria que atenda aos requisitos da Normam-01, da NR-30 e demais legislações pertinentes? ',
          Answer: 'white',
        },
        '6-8 b 154': {
            Question:
              'A enfermaria possui quantidade de equipamentos e medicamentos que atende a lista de itens mínimos de enfermaria padronizada pela Petrobras para embarcações de mergulho? ',
            Answer: 'white',
          },
          '6-9 a 155': {
            Question:
              'Os meios de comunicação com o suporte médico em terra são adequados e tem backup? ',
            Answer: 'white',
          },
          '6-9 b 156': {
            Question:
              'No procedimento de contingência está previsto o plano de resposta a emergência médica com os recursos de contato telefônico ou por vídeo? ',
            Answer: 'white',
          },
          '6-9 c 157': {
            Question:
              'Existe plano de atendimento médico emergencial na empresa 24 horas para atender as equipes embarcadas no offshore?  ',
            Answer: 'white',
          },
          '6-10 a 158': {
            Question:
              'Existe procedimento específico para em caso de acidente com necessidade de remoção e transporte para hospital ou clínica em terra? ',
            Answer: 'white',
          },
          '6-10 b 159': {
            Question:
              'Os supervisores estão aptos a orientar os responsáveis pelo transporte de acidentados via aérea, de que após a realização de mergulho, o voo deve cumprir um limite de altitude durante o voo em cumprimento da NORMAM-15? ',
            Answer: 'white',
          },
          '6-10 c 160': {
            Question:
              'O Plano de contingência garante acesso a rede hospitalar especializada e a disponibilidade de recursos para um tratamento específico até o pronto reestabelecimento do acidentado? ',
            Answer: 'white',
          },
          '6-10 d 161': {
            Question:
              'Existe procedimento da empresa que propicie a remoção do acidentado embarcado até a rede hospitalar? ',
            Answer: 'white',
          },
          '6-10 e 162': {
            Question:
              'O RH da empresa está capacitado a proporcionar todo o acompanhamento ao acidentado desde a internação até o seu retorno as atividades? ',
            Answer: 'white',
          },
      },
  };

  let listKeysQuestionExposicaoMergulhadoresContaminantes=[
    {
      'AM-1 - Presença de gases ou substâncias inadequadas em área de captação de ar atmosférico para suprimento respiratório': [
        { '1-1': { Check: [], Answer: 'white' } },
        { '1-2': { Check: [], Answer: 'white' } },
        { '1-3': { Check: [], Answer: 'white' } },
        { '1-4': { Check: [], Answer: 'white' } },
        { '1-5': { Check: [], Answer: 'white' } },
        { '1-6': { Check: [], Answer: 'white' } },

      ].reverse(),
      'AM-2 - Descarte de gases tóxicos como hidrocarbonetos, CO e H2S nas proximidades das unidades atendidas (emerso)': [
        { '2-1': { Check: [], Answer: 'white' } },
        { '2-2': { Check: [], Answer: 'white' } },
        { '2-3': { Check: [], Answer: 'white' } },
        { '2-4': { Check: [], Answer: 'white' } },


      ].reverse(),
      'AM-3 – Presença de hidrocarbonetos e outros produtos tóxicos (submerso) em abertura de flanges e conectores de dutos e inspeção de casco de navio': [
        { '3-1': { Check: [], Answer: 'white' } },
        { '3-2': { Check: [], Answer: 'white' } },
        { '3-3': { Check: [], Answer: 'white' } },
        { '3-4': { Check: [], Answer: 'white' } },
        { '3-5': { Check: [], Answer: 'white' } },
      ].reverse(),
      'CO-1 - Contaminação do mergulhador submerso com alteração funcional': [
        { '4-1': { Check: [], Answer: 'white' } },
        { '4-2': { Check: [], Answer: 'white' } },
        { '4-3': { Check: [], Answer: 'white' } },
        { '4-4': { Check: [], Answer: 'white' } },
        { '4-5': { Check: [], Answer: 'white' } },
        { '4-6': { Check: [], Answer: 'white' } },
        { '4-7': { Check: [], Answer: 'white' } },
        { '4-8': { Check: [], Answer: 'white' } },
        { '4-9': { Check: [], Answer: 'white' } },
        { '4-10': { Check: [], Answer: 'white' } },
        { '4-11': { Check: [], Answer: 'white' } },
        { '4-12': { Check: [], Answer: 'white' } },

      ],
      'CO-2 - Contaminação do mergulhador ainda emerso com alteração funcional': [
        { '5-1': { Check: [], Answer: 'white' } },
        { '5-2': { Check: [], Answer: 'white' } },
        { '5-3': { Check: [], Answer: 'white' } },
        { '5-4': { Check: [], Answer: 'white' } },
        { '5-5': { Check: [], Answer: 'white' } },
        { '5-6': { Check: [], Answer: 'white' } },
        { '5-7': { Check: [], Answer: 'white' } },
        { '5-8': { Check: [], Answer: 'white' } },
        { '5-9': { Check: [], Answer: 'white' } },


      ],
      'CO-3 - Fatalidade': [
        { '6-1': { Check: [], Answer: 'white' } },
        { '6-2': { Check: [], Answer: 'white' } },
        { '6-3': { Check: [], Answer: 'white' } },
        { '6-4': { Check: [], Answer: 'white' } },
        { '6-5': { Check: [], Answer: 'white' } },
        { '6-6': { Check: [], Answer: 'white' } },
        { '6-7': { Check: [], Answer: 'white' } },
        { '6-8': { Check: [], Answer: 'white' } },
        { '6-9': { Check: [], Answer: 'white' } },
        { '6-10': { Check: [], Answer: 'white' } },

      ],


    }]


  export {TitlesAndQuestionsExposicaoMergulhadoresContaminantes,listKeysQuestionExposicaoMergulhadoresContaminantes,titleFormExposicaoMergulhadoresContaminantes,titleHazardExposicaoMergulhadoresContaminantes,SecondIdExposicaoMergulhadoresContaminantes,listAMCOExposicaoMergulhadoresContaminantes}

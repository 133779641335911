/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-empty */
import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { toast } from 'react-toastify';
import { FaFilePdf, FaShare } from 'react-icons/fa';

import { ContainerComponent } from '../../components/Container';
import {
  DivBox1,
  DivUpload,
  UploadButton,
  DivRegister,
  DivContent,
} from '../AddAuditoria/styles';
import { DivFrame } from './styles';

import api from '../../services/api';

export default function AddDocPetrobras() {
  document.title = 'Auditorias';
  useEffect(() => window.scrollTo(0, 0), []);

  const [title, setTitle] = useState('');
  const [file, setFile] = useState(false);

  const { admin } = useSelector(state => state.auth.groupLevel);

  const handleUploadDocument = async event => {
    try {
      const data = new FormData();
      data.append('file', event.target.files[0]);

      const response = await api.post('/petrobras/files', data);

      if (response.status === 200) {
        setFile(response.data.path);
      }
    } catch (error) {
      toast.error('Erro ao fazer upload do documento');
    }
  };

  const handleSubmit = useCallback(async () => {
    try {
      const validateFields = () => {
        if (title && title !== '' && file && admin) {
          const payload = {
            title,
            file,
          };
          return payload;
        }
        return false;
      };

      const isValidForm = validateFields();

      if (isValidForm) {
        const response = await api.post('petrobras/new', isValidForm);

        if (response.status === 200) {
          setFile(false);
          setTitle('');
          toast.success('Sucesso');
        } else {
          toast.error('Erro ao cadastrar documento');
        }
      } else {
        toast.error('Todos os campos são obrigatórios');
      }
    } catch (error) {}
  }, [admin, file, title]);

  return (
    <ContainerComponent>
      <DivFrame>
        <DivContent>
          <h1>Upload documento Petrobras</h1>
          <DivBox1>
            <label htmlFor="title">Titulo *</label>
            <input
              name="title"
              type="text"
              id="title"
              onChange={e => setTitle(e.target.value)}
              value={title}
            />
          </DivBox1>

          <DivUpload>
            <UploadButton>
              {!file ? (
                <>
                  <label htmlFor="file">
                    <FaFilePdf />
                    Anexar PDF
                    <input
                      type="file"
                      accept=".pdf"
                      onChange={handleUploadDocument}
                      id="file"
                    />
                  </label>
                </>
              ) : (
                <h3>Upload realizado com sucesso</h3>
              )}
            </UploadButton>

            <DivRegister>
              <button
                type="button"
                onClick={() => {
                  handleSubmit();
                }}
              >
                <FaShare />
                Cadastrar
              </button>
            </DivRegister>
          </DivUpload>
        </DivContent>
      </DivFrame>
    </ContainerComponent>
  );
}

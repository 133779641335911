import styled from 'styled-components';
import { lighten } from 'polished';

export const DivTable = styled.div`
  margin-top: 48px;
  position: relative;

  h2 {
    color: #00529b;
    margin-bottom: 16px;
    position: absolute;
    z-index: 5;
    left: 32px;
    top: 16px;

    &:hover {
      color: ${lighten(0.08, '#00529b')};
    }
  }

  button + button {
    margin-right: 48px;
  }
`;

import React from 'react';
import './style.css';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Carousel } from 'react-responsive-carousel';

import { feedBack } from '../../store/modules/auth/actions';

import slide1 from '../../assets/slide/slide-1.jpg';
import slide2 from '../../assets/slide/slide-2.jpg';
import slide3 from '../../assets/slide/slide-3.jpg';

import post1 from '../../assets/card1/main.png';
import post2 from '../../assets/card2/main.jpg';
import post3 from '../../assets/card3/main.jpg';

import { ContainerComponent } from '../../components/Container';
import {
  DivGrid,
  MainPicture,
  SecondPosts,
  PostCard,
  DivTitleSlide,
} from './styles';

export default function Index() {
  document.title = 'Peodive';

  const dispatch = useDispatch();
  dispatch(feedBack());

  return (
    <ContainerComponent>
      <DivGrid>
        <MainPicture>
          <Carousel
            autoPlay
            infiniteLoop
            showThumbs={false}
            interval={6000}
            emulateTouch
          >
            <div>
              <img src={slide1} alt="main-pic1" />
            </div>
            <div>
              <img src={slide2} alt="main-pic2" />
            </div>
            <div>
              <img src={slide3} alt="main-pic3" />
            </div>
          </Carousel>

          <DivTitleSlide>
            <h2>PEODIVE</h2>
            <h3>Programa de Excelência Operacional em Mergulho</h3>

            <p>
              Responsável por promover melhorias na segurança da atividade de
              mergulho, orientando os setores da Petrobras quanto à
              regulamentação dos requisitos técnicos e às melhores práticas da
              indústria para este segmento.
            </p>
          </DivTitleSlide>
        </MainPicture>

        <SecondPosts>
          <PostCard>
            <img src={post1} alt="post-1" />

            <h3>As Regras de Vida do Mergulho</h3>

            <p>
              Para prevenir acidentes, cumprimos normas e adotamos padrões e
              procedimentos operacionais rigorosos. Com isso, foram criadas as
              10 regras de vida do mergulho, uma lista com 10 comportamentos,
              reforçando nossas ações de Segurança, Meio Ambiente e Saúde (SMS)
              com foco na prevenção de acidentes.
            </p>

            <Link to="/card1">Leia mais</Link>
          </PostCard>

          <PostCard>
            <img src={post2} alt="post-2" />

            <h3>Mergulho na Petrobras: Um olhar para o futuro</h3>

            <p>
              Com o advento da exploração de petróleo em águas cada vez mais
              profundas, o mergulho humano deixou de ser opção para as operações
              no fundo do mar. Nesse contexto, tecnologias diverless têm sido
              bastante desenvolvidas nas últimas duas décadas, principalmente
              por meio do uso de ROVs, AUVs e dispositivos similares.
            </p>

            <Link to="/card2">Leia mais</Link>
          </PostCard>

          <PostCard>
            <img src={post3} alt="post-3" />

            <h3>Competências e atribuições do PEODIVE</h3>

            <p>
              O programa atua na orientação técnica, no suporte técnico aos
              padrões e procedimentos, na análise de riscos, na investigação de
              acidentes e na realização de auditorias e avaliações, além de
              acompanhar constantemente a emissão de diretivas internacionais
              relativas à atividade de mergulho.
            </p>

            <Link to="/nossas-atividades">Leia mais</Link>
          </PostCard>
        </SecondPosts>
      </DivGrid>
    </ContainerComponent>
  );
}

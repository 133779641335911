import styled from 'styled-components';
import { lighten } from 'polished';

export const DivAlert = styled.div`
  margin-top: 64px;
  width: 1109px;
  position: relative;
  background: #fbfbfb;
  border-radius: 5px;
  padding: 24px 24px 24px 24px;

  h1 {
    color: #00529b;
    font-size: 24px;
  }

  h2 {
    margin-top: 48px;
    color: #b56a1e;
    padding-left: 24px;
  }

  p {
    max-width: 824px;
    margin-top: 12px;
    text-align: justify;
    padding-left: 32px;
    line-height: 32px;
  }

  li {
    max-width: 824px;
    margin-top: 12px;
    padding-left: 32px;
    text-align: justify;
    line-height: 32px;
  }

  a {
    padding: 12px;
    padding-left: 32px;
    color: #00529b;
    line-height: 32px;

    &:hover {
      color: ${lighten(0.08, '#00529b')};
    }
  }
`;

export const DivImages = styled.div`
  margin-top: 32px;
  display: grid;

  h1 {
    margin-top: 32px;
    color: #00529b;
  }

  img {
    margin-top: 32px;
    max-width: 600px;
  }
`;

export const DownloadButton = styled.button`
  width: 128px;
  height: 32px;
  background: #00529b;
  border: 0;
  border-radius: 5px;
  font-weight: bold;
  color: #fff;
  position: absolute;
  right: 32px;
  top: 80px;

  svg {
    margin-right: 8px;
    width: 16px;
  }

  &:hover {
    background: ${lighten(0.08, '#b56a1e')};
  }
`;

export const DeleteButton = styled.button`
  width: 128px;
  height: 32px;
  background: #d9534f;
  border: 0;
  border-radius: 5px;
  font-weight: bold;
  color: #fff;
  position: absolute;
  right: 32px;
  top: 120px;

  svg {
    margin-right: 8px;
    width: 16px;
  }

  &:hover {
    background: ${lighten(-0.08, '#d9534f')};
  }
`;

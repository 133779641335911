import styled from 'styled-components';

export const Header = styled.div.attrs(props => ({
  active: props.active,
}))`
  background: #051367;
`;

export const Content = styled.div`
  display: flex;
  width: 1109px;
  height: 128px;
  background: #051367;
  margin: auto;
  align-items: center;
  position: relative;
  border-radius: 5px;

  img {
    width: 14.8rem;

    &:hover {
      width: 15.8rem;
      transition: 0.7s;
    }
  }

  nav {
    margin-left: 96px;

    span {
      color: #5d8bf4;
      font-weight: bold;
      font-size: 1.6rem;
    }

    a {
      height: 96px;
      position: relative;
      color: #dff6ff;
      font-size: 1.6rem;
      transition: color 0.3s;
      margin-left: 1em;
      margin-right: 1em;
      text-decoration: none;

      &:hover {
        color: #5d8bf4;
        font-weight: 700;
        font-size: 1.7rem;
        transition: 0.5s;
        width: 120%;
      }
    }
  }

  svg {
    color: #dff6ff !important;

    &:hover {
      background: #5d8bf4;
      cursor: pointer;
      transition: 0.5s;
    }
  }

  @media (max-width: 768px) {
    width:500px;
    height:160px;
    nav {
      display: flex;
      flex-direction: column;

      a {
        height: 0px;
      }
    }
  }
`;

export const UserArea = styled.div`
  display: flex;
  flex-direction: row;
  height: 72px;
  background: #e8eaef;
  margin: 32px auto;
  align-items: center;
  border-radius: 5px;

  a {
    position: absolute;
    right: 3.2rem;
    color: #dff6ff;
    text-decoration: none;

    font-size: 1.6rem;
    transition: color 0.3s;

    &:hover {
      color: #5d8bf4;
      font-weight: 700;
      transition: 0.5s;
    }
  }

  a {
    right: 0;
    align-items: center;
    color: #dff6ff;

    svg {
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }
  }

  @media (max-width: 768px) {
    a{
      position:absolute;
      top:14rem;
      left:1.5rem;
    }
  }

`;

export const DivMenuAccess = styled.div``;

export const DivMenu = styled.div`
  position: absolute;
  right: 0;
  top: 72px;
  z-index: 100;
`;

export const HamburguerButton = styled.button`
  /* Styles for the hamburguer button */
  display: none; /* Hide the button by default on larger screens */
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;

  @media (max-width: 768px) {
    /* Show the button only on smaller screens */
    display: block;
  }
`;

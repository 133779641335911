/* eslint-disable no-unused-expressions */
import produce from 'immer';

const INITIAL_STATE = {
  show: false,
};

export default function menu(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@menu/TOGGLE_MENU':
      return produce(state, draft => {
        draft.show ? (draft.show = false) : (draft.show = true);
      });

    default:
      return state;
  }
}

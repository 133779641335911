/* eslint-disable react/no-unescaped-entities */
import React from 'react';

import { ContainerComponent } from '../../components/Container';
import { StaticView } from './styles';

export default function TermsOfUse() {
  document.title = 'Termos de Uso';

  return (
    <ContainerComponent>
      <StaticView>
        <h1>TERMOS DE USO</h1>

        <h2>EDITORA</h2>

        <p>
          O website peodive.com.br é publicado pela empresa Bureau Veritas do
          Brasil.
        </p>

        <h2>TERMOS DE USO</h2>

        <p>
          Qualquer conexão e/ou uso do website ("Site”) implica na aceitação das
          seguintes condições pelo usuário do Website.
        </p>

        <h2>USO DO WEBSITE</h2>

        <p>
          O usuário (“Usuário”) tem o direito de consultar o Website e se
          compromete a cumprir com os presentes termos de uso.
        </p>

        <h3>Como consequência, o Usuário se compromete a:</h3>

        <p>
          * não realizar qualquer transmissão ou divulgação por meio do Site de
          conteúdo ilegal, contrário às normas públicas ou de informações
          difamatórias;
          <br />
          * não realizar qualquer deterioração, baixar conteúdo não-autorizado,
          ataque de negação de serviço (DoS attack, em inglês), malversação das
          informações e conteúdo do Site, tentativa de contornar ou danificar
          dispositivos de segurança, phishing, introdução fraudulenta na
          infraestrutura do Bureau Veritas, etc.;
          <br />* e, em geral, não realizar qualquer uso do Site que não estiver
          em conformidade com os presentes termos de uso e/ou que possa causar
          danos a sua integridade ou disponibilidade, e/ou aos interesses e
          direitos exclusivos da Bureau Veritas.
        </p>

        <h2>DISPONIBILIDADE</h2>

        <p>
          O Site irá funcionar 24 horas por dia, 7 dias por semana. No entanto,
          caso um evento de força maior ocorra, a conexão esteja lenta , ou o
          Site esteja sobrecarregado ou indisponível devido às redes de
          comunicação eletrônica, o acesso total ou parcial do Site pode ficar
          mais lento ou ser interrompido. Ademais, o Bureau Veritas pode
          realizar manutenção corretiva ou preventiva no Site ou nos locais de
          hospedagem. Assim sendo, o Bureau Veritas não garante a
          disponibilidade ou o acesso contínuo ao Site.
        </p>

        <h2>PROPRIEDADE INTELECTUAL</h2>

        <p>
          O Site e seu conteúdo (código-fonte, textos, imagens, gráficos, dados,
          informações, arquitetura, software e banco de dados, nome do domínio,
          marcas registradas e logotipos) são protegidos pelas leis,
          regulamentações e convenções internacionais sobre propriedade
          intelectual, e pertencem ao Bureau Veritas ou ficam sujeitos à
          autorização de seu uso.
          <br />
          Sob nenhuma circunstância a consulta ao Site deve ser interpretado
          como uma transferência, licença ou autorização para o reuso de seu
          conteúdo. Assim sendo, é estritamente proibida qualquer reprodução,
          representação, extração, modificação, disseminação ou exploração,
          total ou parcial do Site, ou de qualquer elemento contido, salvo se
          anteriormente autorizado por escrito por Bureau Veritas. O Bureau
          Veritas tem o direito de modificar o conteúdo do Site a qualquer
          momento, sem notificação prévia sobre tal ato.
        </p>

        <h2>RESPONSABILIDADE</h2>

        <p>
          Todas as informações acessíveis por meio do Site são fornecidas “no
          estado em que se encontram”. O Bureau Veritas não fornece qualquer
          garantia, explícita ou implícita, e não assume qualquer
          responsabilidade pelo uso dessas informações. Além disso, o Bureau
          Veritas não fornece qualquer tipo de garantia em relação à
          importância, época oportuna, completude ou adequação das informações
          publicadas para as necessidades do Usuário.
          <br />
          <br />
          * Usuário é inteiramente responsável pelo uso das informações
          livremente disponíveis no Site.
          <br />* Bureau Veritas se isenta de toda a responsabilidade por
          quaisquer danos, diretos ou indiretos (especialmente por quaisquer
          danos financeiros, comerciais, por perda de programas ou dados), que
          possa vir a sofrer por conta de um Usuário ou terceiro resultante do
          uso do Site e das informações contidas neste. Além disso, o Bureau
          Veritas se isenta de toda e qualquer responsabilidade no caso de
          vírus, malware ou spam resultantes da consulta ao Site.
          <br />
          <br />
          Websites que não são do Bureau Veritas Group e que possuem um link em
          hipertexto ao Site não estão sob controle do Bureau Veritas, o que,
          portanto, se exime de qualquer responsabilidade por seu conteúdo. O
          Usuário é inteiramente responsável por seu uso.
        </p>

        <h2>FORO E LEGISLAÇÃO APLICÁVEL</h2>

        <p>
          Todas as disputas relacionadas ao Site e ao seu conteúdo deverão ser
          encaminhadas às cortes e tribunais de Nanterre, França e regidos pela
          lei francesa.
        </p>
      </StaticView>
    </ContainerComponent>
  );
}

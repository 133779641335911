export function searchRequest(formattedSearchModel) {
  return {
    type: '@search/SEARCH_REQUEST',
    payload: formattedSearchModel,
  };
}

export function searchResult(alertsFound) {
  return {
    type: '@search/SEARCH_RESULT',
    payload: { alertsFound },
  };
}

export function searchFail() {
  return {
    type: '@search/SEARCH_FAIL',
  };
}

/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
/* eslint-disable no-empty */
import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { toast } from 'react-toastify';

import { FaFilePdf, FaShare } from 'react-icons/fa';
import { BubbleLoader } from 'react-css-loaders';

import { ContainerComponent } from '../../components/Container';
import {
  DivAuditoriaReport,
  DivTitle,
  DivContent,
  DivUpload,
  DivBox1,
  UploadButton,
  DivHead,
  LoadingSearch,
  DivRegister,
} from './styles';

import api from '../../services/api';
import history from '../../services/history';

export default function AddAuditoria({ match }) {
  const { auditor } = useSelector(state => state.auth.groupLevel);

  const [companyId, setCompanyId] = useState(false);
  const [title, setTitle] = useState(' ');
  const [observation, setObservation] = useState(' ');
  const [date, setDate] = useState(new Date());
  const [file, setFile] = useState(false);
  const [generalPontuation, setGeneralPontuation] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getCompanyPontuation() {
      const companyData = await api.get(`company/${companyId}/info`);

      if (companyData.data) {
        setGeneralPontuation(companyData.data);
      }
    }

    const { params } = match;

    if (params.companyId) {
      setCompanyId(params.companyId);

      try {
        getCompanyPontuation();
      } catch (error) {
        toast.error('Não foi possível obber a nota desta empresa');
      }
    }
  }, [companyId, match]);

  const handleUploadDocument = async event => {
    try {
      const data = new FormData();
      data.append('file', event.target.files[0]);

      const response = await api.post(`auditoria/files`, data);

      if (response.status === 200) {
        if (response.data.path) {
          setFile(response.data.path);
        }
      }
    } catch (error) {}
  };

  const handleSubmit = useCallback(async () => {
    setLoading(true);
    try {
      const validateFields = () => {
        if (
          companyId &&
          title &&
          title !== ' ' &&
          file &&
          auditor &&
          generalPontuation
        ) {
          const payload = {
            companyId,
            title,
            observation,
            date,
            generalPontuation,
            file,
          };
          return payload;
        }
        return false;
      };

      const isValidForm = validateFields();

      if (!isValidForm) {
        toast.error('Por favor, verifique os campos');
        setLoading(false);
      }

      if (isValidForm) {
        const response = await api.post('company', isValidForm);

        if (response.status === 200) {
          toast.success('Sucesso');
          setLoading(false);
          history.push('/auditoria');
        } else {
          toast.error('Algo deu errado, por favor, tente novamente');
          setLoading(false);
        }
      }
    } catch (error) {
      setLoading(false);
    }
  }, [auditor, companyId, date, file, generalPontuation, observation, title]);

  return (
    <ContainerComponent>
      <DivAuditoriaReport>
        <DivTitle>
          <h2>Relatório da Auditoria</h2>
        </DivTitle>

        <DivContent>
          <DivHead>
            <div>
              <label htmlFor="alertDate">Data da auditoria</label>
              <input
                name="alertDate"
                type="date"
                id="alertDate"
                size="30"
                onChange={e => setDate(e.target.value)}
              />
            </div>

            <div>
              <label htmlFor="pontuation">Nota geral</label>
              <input
                name="pontuation"
                type="number"
                step={0.1}
                id="pontuation"
                value={generalPontuation}
                onChange={e =>{ setGeneralPontuation(e.target.value)}}
              />
            </div>
          </DivHead>

          <DivBox1>
            <label htmlFor="title">Titulo *</label>
            <input
              name="title"
              type="text"
              id="title"
              onChange={e => setTitle(e.target.value)}
            />
          </DivBox1>

          <DivBox1>
            <label htmlFor="observation">Observação</label>
            <input
              name="observation"
              type="text"
              id="observation"
              onChange={e => setObservation(e.target.value)}
            />
          </DivBox1>

          <DivUpload>
            <UploadButton>
              {!file ? (
                <>
                  <label htmlFor="file">
                    <FaFilePdf />
                    Anexar PDF
                    <input
                      type="file"
                      accept=".pdf"
                      onChange={handleUploadDocument}
                      id="file"
                    />
                  </label>
                </>
              ) : (
                <h3>Upload realizado com sucesso</h3>
              )}
            </UploadButton>

            <DivRegister>
              {!loading ? (
                <button
                  type="button"
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  <FaShare />
                  Cadastrar
                </button>
              ) : (
                <LoadingSearch>
                  <BubbleLoader size={3} />
                </LoadingSearch>
              )}
            </DivRegister>
          </DivUpload>
        </DivContent>
      </DivAuditoriaReport>
    </ContainerComponent>
  );
}

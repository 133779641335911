import styled from 'styled-components';

export const StaticView = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 1109px;
  margin-top: 64px;
  border: solid 2px #00529b;
  border-radius: 5px;
  background: #fbfbfb;
  padding: 64px;

  h1 {
    text-align: center;
    color: #00529b;
    font-size: 32px;
    margin-bottom: 32px;
  }

  h2 {
    text-align: center;
    color: #b56a1e;
    font-size: 32px;
    margin-bottom: 32px;
  }

  p {
    color: #00529b;
    text-align: justify;
    font-size: 16px;
    width: 100%;
    line-height: 32px;
  }

  span {
    color: #b56a1e;
    text-align: justify;

    font-size: 14px;
    line-height: 30px;
    font-weight: bold;
  }

  p + span {
    margin-top: 24px;
  }

  img {
    width: 100%;
    padding: 32px;
  }

  @media only screen and (max-width: 768px) {

    width:340px;
    margin-left:20px;
    margin-right:20px;
  }
`;

import produce from 'immer';

const INITIAL_STATE = {
  token: null,
  signed: false,
  loading: false,
  login: null,
  loginForFeedback: null,
  createdBy: null,
  groupLevel: null,
  name: null,
};

export default function auth(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@auth/SIGN_IN_REQUEST':
      return produce(state, draft => {
        draft.loading = true;
      });

    case '@auth/SIGN_IN_SUCCESS':
      return produce(state, draft => {
        draft.token = action.payload.token;
        draft.login = action.payload.login;
        draft.loginForFeedback = action.payload.login;
        draft.createdBy = action.payload.createdBy;
        draft.groupLevel = action.payload.groupLevel;
        draft.signed = true;
        draft.loading = false;
        draft.name = action.payload.name;
      });

    case '@auth/SIGN_FAILURE':
      return produce(state, draft => {
        draft.loading = false;
      });

    case '@auth/SIGN_OUT': {
      return produce(state, draft => {
        draft.token = null;
        draft.login = null;
        draft.createdBy = null;
        draft.groupLevel = null;
        draft.signed = null;
        draft.loading = null;
        draft.name = null;
      });
    }

    case '@auth/FEEDBACK': {
      return produce(state, draft => {
        if (!draft.signed) {
          draft.loginForFeedback = null;
        }
      });
    }

    default:
      return state;
  }
}

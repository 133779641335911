/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import './style.css';
import { useSelector, useDispatch } from 'react-redux';

import { FaUsers } from 'react-icons/fa';

import { AiOutlineDeploymentUnit } from 'react-icons/ai';

import { GoChecklist } from 'react-icons/go';

import { AiOutlineAudit } from 'react-icons/ai';

import {
  MdAddCircleOutline,
  MdDescription,
  MdLibraryBooks,
} from 'react-icons/md';

import { ContainerComponent } from '../../components/Container';

import history from '../../services/history';

import { Container, Content, DashboardItem } from './styles';

export default function Dashboard() {
  const { groupLevel } = useSelector(state => state.auth);
  const dispatch = useDispatch();

  const [dashboard, setDashboard] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isSudo, setIsSudo] = useState(false);
  const [isCompany, setIsCompany] = useState(false);
  const [isAuditor, setIsAuditor] = useState(false);
  const [isPetrobras, setIsPetrobras] = useState(false);
  const [isNormal, setIsNormal] = useState(false);

  useEffect(() => {
    const { admin, sudo, company, auditor, petrobras, normal } = groupLevel;

    setIsSudo(sudo);
    setIsAdmin(admin);
    setIsCompany(company);
    setIsAuditor(auditor);
    setIsPetrobras(petrobras);
    setIsNormal(normal);
  }, [groupLevel]);

  useEffect(() => {
    const dashboardFunctions = {
      userAdd() {
        return history.push('/usuario/cadastrar');
      },

      userIndexAll() {
        return history.push('/usuarios');
      },

      alertSearch() {
        return history.push('/pesquisa');
      },
      
      bowtie() {
        return history.push('/bowtie');
      },

      alertAdd() {
        return history.push('/alertas/cadastrar');
      },

      lvChecklist() {
        return history.push('/auditoria/checklist');
      },

      companyHistoric() {
        return history.push('/empresa');
      },

      auditoria() {
        return history.push('/auditoria');
      },

      docPetrobras() {
        return history.push('/petrobras/doc');
      },

      docPetrobrasView() {
        return history.push('/petrobras');
      },
    };

    setDashboard(dashboardFunctions);
  }, [dispatch]);

  return (
    // MENU CARDS
    <ContainerComponent>
      <Container>
        <Content>
          <ul>
            {/* MENU CARD USUÁRIOS */}
            {isAdmin || isSudo ? (
              <DashboardItem>
                <button type="button" onClick={() => dashboard.userIndexAll()}>
                  <FaUsers size={56} color="#fff" />
                </button>
                <span>Usuários</span>
                <button
                  id="add"
                  type="button"
                  onClick={() => dashboard.userAdd()}
                >
                  <MdAddCircleOutline size={36} color="#fff" />
                </button>
              </DashboardItem>
            ) : null}

            {/* MENU CARD ALERTAS */}
            <DashboardItem>
              <button type="button" onClick={dashboard.alertSearch}>
                <MdDescription size={56} color="#fff" />
              </button>
              <span>Alertas</span>
              {isAdmin ? (
                <button
                  id="add"
                  type="button"
                  onClick={() => dashboard.alertAdd()}
                >
                  <MdAddCircleOutline size={36} color="#fff" />
                </button>
              ) : null}
            </DashboardItem>

            {/* MENU CARD DOCUMENTOS IMCA */}
            {isAdmin || isPetrobras || isSudo || isAuditor ? (
              <DashboardItem>
                <button
                  type="button"
                  onClick={() => dashboard.docPetrobrasView()}
                >
                  <MdLibraryBooks size={56} color="#fff" />
                </button>
                <span>Documentos IMCA</span>
                {isAdmin ? (
                  <button
                    id="add"
                    type="button"
                    onClick={() => dashboard.docPetrobras()}
                  >
                    <MdAddCircleOutline size={36} color="#fff" />
                  </button>
                ) : null}
              </DashboardItem>
            ) : null}

            {/* MENU CARD CHECKLISTS */}
            {isAdmin || isCompany || isSudo || isAuditor ? (
              <DashboardItem>
                <button type="button" onClick={() => dashboard.lvChecklist()}>
                  <GoChecklist size={56} color="#fff" />
                </button>
                <span>Checklists - LV</span>
              </DashboardItem>
            ) : null}

            {/* MENU CARD AUDITORIA */}
            {!isNormal && !isPetrobras ? (
              isCompany ? (
                <DashboardItem>
                  <button
                    type="button"
                    onClick={() => dashboard.companyHistoric()}
                  >
                    <AiOutlineAudit size={56} color="#fff" />
                  </button>
                  <span>Auditoria</span>
                </DashboardItem>
              ) : (
                <DashboardItem>
                  <button type="button" onClick={() => dashboard.auditoria()}>
                    <AiOutlineAudit size={56} color="#fff" />
                  </button>
                  <span>Auditoria</span>
                </DashboardItem>
              )
            ) : null}
             {/* MENU CARD DIAGRAMA */}
            {isAdmin || isCompany || isSudo || isAuditor || isPetrobras  ? (
            <DashboardItem>
              <button type="button" onClick={dashboard.bowtie}>
                <AiOutlineDeploymentUnit size={56} color="#fff" />
              </button>
              <span>BOWTIE</span>
              
            </DashboardItem>
             ) : null}
          </ul>
        </Content>
      </Container>
    </ContainerComponent>
  );
}
